/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

import styles from "./PaymentResultStyles.module.scss";
import { createKaptahOrder } from "../../services/apiPayments";

import successIcon from "../../images/success_payment.svg";
import errorIcon from "../../images/error_payment.svg";
import pendingIcon from "../../images/processing_icon.svg";

const PaymentResult: React.FC = () => {
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const [orderId, setOrderId] = useState<string | null>(null);
  const [orderError, setOrderError] = useState<string>("");
  const [orderFetching, setOrderFetching] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const getOrderStatus = async (order: string): Promise<void> => {
    setOrderFetching(true);
    const result = await createKaptahOrder(order);
    if (typeof result !== "string") {
      setPaymentStatus(result.statusInDb);
    } else {
      setOrderError(result as string);
    }
    setOrderFetching(false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("orderId")) {
      setOrderId(queryParams.get("orderId"));
    }
  }, [location.search]);

  useEffect(() => {
    if (orderId) {
      getOrderStatus(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    if (orderError) {
      console.log(orderError);
    }
  }, [orderError]);

  return (
    <div className={styles.payment_result_wrap}>
      <div className={styles.container}>
        {orderFetching && (
          <ThreeDots
            height="15"
            width="30"
            color="#D0174F"
            wrapperStyle={{ justifyContent: "center" }}
            visible
            ariaLabel="circles-with-bar-loading"
          />
        )}
        {paymentStatus === "Paid" && (
          <>
            <div className={styles.img_container}>
              <img src={successIcon} alt="paid" />
            </div>
            <div className={styles.title}>
              {t("Your payment has been successfully completed!")}
            </div>
            <div className={styles.description}>
              {t(
                "BOOM casino wishes you successful gaming and a pleasant time on the site"
              )}
              .
            </div>
            <div className={styles.action_block}>
              <button
                className={styles.main_btn}
                type="button"
                onClick={(): void => {
                  navigate("/");
                }}
              >
                {t("Home page")}
              </button>
            </div>
          </>
        )}
        {paymentStatus === "Unpaid" && (
          <>
            <div className={styles.img_container}>
              <img src={errorIcon} alt="unpaid" />
            </div>
            <div className={styles.title}>
              {t("Your payment has not been completed!")}
            </div>
            <div className={styles.description}>
              {t(
                "The problem may be related to your payment profile. Check it out or contact your bank for details."
              )}
              <br />
              {t("You can also try another payment method.")}
            </div>
            <div className={styles.action_block}>
              <button
                className={styles.main_btn}
                type="button"
                onClick={(): void => {
                  navigate("/payments");
                }}
              >
                {t("Return to payments")}
              </button>
              <button
                className={styles.border_btn}
                type="button"
                onClick={(): void => {
                  navigate("/");
                }}
              >
                {t("Home page")}
              </button>
            </div>
          </>
        )}
        {paymentStatus === "Pending" && (
          <>
            <div className={styles.img_container}>
              <img src={pendingIcon} alt="pending" />
            </div>
            <div className={styles.title}>
              {t("Your payment is being processed!")}
            </div>
            <div className={styles.description}>
              {t(
                "Your payment is currently being processed. Please wait a few minutes. Thank you for your patience and understanding."
              )}
              <br />
              {t(
                "If you have any problems or questions, please contact our support team."
              )}
            </div>
            <div className={styles.action_block}>
              <button
                className={styles.main_btn}
                type="button"
                onClick={(): void => {
                  navigate("/payments");
                }}
              >
                {t("Return to payments")}
              </button>
              <button
                className={styles.border_btn}
                type="button"
                onClick={(): void => {
                  navigate("/");
                }}
              >
                {t("Home page")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentResult;
