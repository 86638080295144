import React from "react";
import { Modal, Button } from "react-bootstrap";

interface Props {
  show: boolean;
  promptMessage: string;
  onConfirm: (confirmed: boolean) => void;
  onCancel: (confirmed: boolean) => void;
}
const ConfirmModal: React.FC<Props> = ({
  show,
  promptMessage,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal show={show} onHide={(): void => onCancel(false)}>
      <Modal.Header closeButton />
      <Modal.Body>
        <p>{promptMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={(): void => onCancel(false)}>
          No
        </Button>
        <Button variant="primary" onClick={(): void => onConfirm(true)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
