/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import styles from "./MobileMenuStyles.module.scss";
import ModalTitle from "../../UI/ModalTitle/ModalTitle";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";

import level1 from "../../../images/vip-icons/level1.svg";
import level2 from "../../../images/vip-icons/level2.svg";
import level3 from "../../../images/vip-icons/level3.svg";
import level4 from "../../../images/vip-icons/level4.svg";
import level5 from "../../../images/vip-icons/level5.svg";

import defAvatar from "../../../images/default-avatar-new.svg";

import promoIcon from "../../../images/menu-icons/promocode-icon-menu.svg";
import tournamentsIcon from "../../../images/menu-icons/tournaments-menu-icon.svg";
import vipIcon from "../../../images/menu-icons/vip-menu-icon.svg";
import documentsIcon from "../../../images/menu-icons/documents-menu-icon.svg";

import WelcomeBonusBanner from "../../banners/WelcomeBonusBanner/WelcomeBonusBanner";

import VipBar from "../../listItems/VipBar";

interface MobileMenuModalProps {
  onClose: () => void;
  open: boolean;
}

const MobileMenuModal: React.FC<MobileMenuModalProps> = ({ onClose, open }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(open);

  const user = useSelector((state: RootState) => state.user);
  const vipLevel = useSelector(
    (state: RootState) => state.user.userInfo.vip_club?.level
  );

  useEffect(() => {
    if (open) {
      setIsModalVisible(true);
    } else {
      setTimeout(() => {
        setIsModalVisible(false);
      }, 500);
    }
  }, [open]);

  const getUserAvatar = (): JSX.Element => {
    switch (vipLevel) {
      case 1:
        return (
          <div className={styles.user_avatar}>
            <img src={level1} alt="" className={styles.avatar_img} />
          </div>
        );
      case 2:
        return (
          <div className={styles.user_avatar}>
            <img src={level2} alt="" className={styles.avatar_img} />
          </div>
        );
      case 3:
        return (
          <div className={styles.user_avatar}>
            <img src={level3} alt="" className={styles.avatar_img} />
          </div>
        );
      case 4:
        return (
          <div className={styles.user_avatar}>
            <img src={level4} alt="" className={styles.avatar_img} />
          </div>
        );
      case 5:
        return (
          <div className={styles.user_avatar}>
            <img src={level5} alt="" className={styles.avatar_img} />
          </div>
        );
      case 0:
      default:
        return (
          <div className={`${styles.user_avatar} ${styles.default}`}>
            <img src={defAvatar} alt="" className={styles.avatar_img} />
          </div>
        );
    }
  };

  return isModalVisible ? (
    <div className={`${styles.modal_wrapper} ${open ? "" : styles.hide_modal}`}>
      {/* <div className={styles.modal_wrapper}> */}
      <div
        className={`${styles.wrapper} ${
          user.localeActive?.code === "fa" ? styles.rtl : ""
        }`}
      >
        <div className={styles.profile}>
          <div className={styles.profile_container}>
            {getUserAvatar()}
            <div className={styles.profile_info}>
              <p className={styles.name_email}>
                <b>{user.userInfo.full_name}</b>{" "}
                {user.userInfo.email ? `(${user.userInfo.email})` : null}
              </p>
              <p className={styles.account_id}>
                {t("Account ID")}: {user.userInfo.integer_public_id}
              </p>
            </div>
          </div>
          <ModalTitle onClose={onClose} />
        </div>
        <div className={styles.vipbar_container}>
          <VipBar />
        </div>
        <div className={styles.available_bonuses}>
          <p>{t("Available Bonuses")}</p>
          <div className={styles.bonus_container}>
            <WelcomeBonusBanner isBannerMenu />
          </div>
        </div>
        <div className={styles.mobile_menu}>
          <div className={styles.mobile_menu_column}>
            <div
              className={styles.menu_item}
              onClick={(): void => {
                onClose();
                navigate("/promocode");
              }}
            >
              <img src={promoIcon} alt="" />
              <span>{t("Promocode")}</span>
            </div>
            <div
              className={styles.menu_item}
              onClick={(): void => {
                onClose();
                navigate("/vip-club");
              }}
            >
              <img src={vipIcon} alt="" />
              <span>{t("Vip-club")}</span>
            </div>
            <div
              className={styles.menu_item}
              onClick={(): void => {
                onClose();
                navigate("/tournaments");
              }}
            >
              <img src={tournamentsIcon} alt="" />
              <span>{t("Tournaments")}</span>
            </div>
            <div
              className={styles.menu_item}
              onClick={(): void => {
                onClose();
                navigate("/documents");
              }}
            >
              <img src={documentsIcon} alt="" />
              <span>{t("Documents")}</span>
            </div>
          </div>
        </div>
        <div className={styles.social_btn}>
          <button
            type="button"
            onClick={(): void => {
              window?.open("https://t.me/boomcasinoRu", "_blank")?.focus();
            }}
          >
            {t("Follow social networks")}
          </button>
        </div>
        <div className={styles.footer_space} />
      </div>
    </div>
  ) : null;
};

export default MobileMenuModal;
