export interface CurrencyItem {
  id: number;
  code: string;
  name: string;
  units: number;
}

export enum CurrencyType {
  RUB = "RUB",
  USD = "USD",
  KZT = "KZT",
  IRR = "IRR",
}

export enum SystemType {
  coinsPaid = "coinsPaid",
  aifory = "aifory",
  P2R = "P2R",
  YooMoney = "YooMoney",
  Qiwi = "Qiwi",
  moneygo = "moneygo",
  P2R_KZT = "P2R_KZT",
  Card6 = "Card6",
  streampay = "streampay",
  kaptahPay = "kaptahpay",
}

export enum CryptoCurrencyType {
  BTC = "BTC",
  ETH = "ETH",
  USDTT = "USDTT",
  USDTE = "USDTE",
}
