import React from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  value: string;
  id: number;
  handlePrizeChange: (e: string, id: number) => void;
  handlePrizeRemove: () => void;
  isLast?: boolean;
  disabled?: boolean;
}

const TournamentPrizeItem: React.FC<Props> = ({
  value,
  id,
  handlePrizeChange,
  handlePrizeRemove,
  isLast,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="tournament-prize-item mb-3">
      <div className="tournament-prize-info">
        <p>
          {t("Place")}: {id + 1}
        </p>
      </div>
      <div className="tournament-prize-form">
        <Form.Control
          type="number"
          value={value}
          onChange={(e): void => {
            handlePrizeChange(e.target.value, id);
          }}
          disabled={disabled}
        />
        {isLast && (
          <Button
            variant="danger"
            onClick={handlePrizeRemove}
            disabled={disabled}
          >
            {t("Remove")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TournamentPrizeItem;
