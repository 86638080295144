// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextFieldPasswordStyles_wrapper__BpTZ3 {\n  position: relative;\n}\n\n.TextFieldPasswordStyles_input_password__9MJDd {\n  padding-right: 40px !important;\n}\n\n.TextFieldPasswordStyles_password_show__Q6Ktm {\n  position: absolute;\n  cursor: pointer;\n  right: 15px;\n  top: 13px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/TextFieldPassword/TextFieldPasswordStyles.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;;AAIA;EACE,8BAAA;AADF;;AAIA;EACE,kBAAA;EACA,eAAA;EACA,WAAA;EACA,SAAA;AADF","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.wrapper {\n  position: relative;\n}\n\n.input_password {\n  padding-right: 40px !important;\n}\n\n.password_show {\n  position: absolute;\n  cursor: pointer;\n  right: 15px;\n  top: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TextFieldPasswordStyles_wrapper__BpTZ3",
	"input_password": "TextFieldPasswordStyles_input_password__9MJDd",
	"password_show": "TextFieldPasswordStyles_password_show__Q6Ktm"
};
export default ___CSS_LOADER_EXPORT___;
