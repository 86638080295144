/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import { DocumentTypes, DocumentItem } from "../../../types/documentsTypes";
import styles from "./DocumentInputsStyles.module.scss";

import uploadIcon from "../../../images/upload-icon-new.svg";
import frontIdImg from "../../../images/documents/front.svg";
import backIdImg from "../../../images/documents/back.svg";
import selphiImg from "../../../images/documents/selfi.svg";
import otherImg from "../../../images/documents/front-big.svg";
import bankImg from "../../../images/documents/bank.svg";
import validateLoadFile from "../../../helpers/validate/validateLoadFile";

export enum Statuses {
  pending = "Pending",
  processing = "Processing",
  approved = "Approved",
  declined = "Declined",
}

interface Props {
  type: DocumentTypes;
  size: "large" | "normal" | "small";
  preview: DocumentItem | undefined;
  previewItem?: File;
  handleSelect: (
    e: File | undefined,
    type: DocumentTypes,
    errorMessage: string
  ) => void;
  setGlobalError: (errorMessage: string) => void;
}

const DocumentInputs: React.FC<Props> = ({
  type,
  preview,
  handleSelect,
  previewItem,
  size,
  setGlobalError,
}) => {
  const [previewImg, setPreviewImg] = useState<string>();

  const { t } = useTranslation();

  useEffect(() => {
    if (previewItem) {
      const file = previewItem;
      setPreviewImg(URL.createObjectURL(file));
    } else {
      setPreviewImg(undefined);
    }
  }, [previewItem]);

  const renderImg = (): string => {
    switch (type) {
      case DocumentTypes.id_card_front:
      case DocumentTypes.driver_license:
        return frontIdImg;
      case DocumentTypes.id_card_back:
      case DocumentTypes.tax:
        return backIdImg;
      case DocumentTypes.credit_card:
        return bankImg;
      case DocumentTypes.selphi:
        return selphiImg;
      case DocumentTypes.other:
      default:
        return otherImg;
    }
  };

  return (
    <div
      className={classNames(
        styles.document_input_item_wrap1,
        styles[size as string]
      )}
    >
      {previewImg || preview ? (
        <div
          className={classNames(styles.img_wrap, styles[size as string])}
          data-testid="document-img-front"
          style={{
            backgroundImage: `url( ${
              previewImg || preview?.document_path || ""
            })`,
          }}
        />
      ) : (
        <div
          className={classNames(styles.def_img_wrap, styles[size as string])}
        >
          <img src={renderImg()} alt="" />
        </div>
      )}
      {preview?.status !== Statuses.approved &&
        preview?.status !== Statuses.processing && (
          <>
            <input
              type="file"
              id={`${type}-file`}
              data-testid="document-img-front-input"
              className={classNames(styles.load_document_input)}
              accept=".png, .jpeg, .jpg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                let error = "";
                if (e.target.files) {
                  error = validateLoadFile(e.target.files[0]).message;
                  if (setGlobalError) {
                    setGlobalError(error);
                  }
                  handleSelect(
                    e.target.files ? e.target.files[0] : undefined,
                    type,
                    error
                  );
                }
              }}
            />
            <label htmlFor={`${type}-file`}>
              {previewImg || preview ? (
                <>{t("Update")}</>
              ) : (
                <>
                  {" "}
                  <img src={uploadIcon} alt="" />
                  {t("Upload")}
                </>
              )}
            </label>
          </>
        )}
    </div>
  );
};

export default DocumentInputs;
