import ApiClient from "../ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../../types/requestTypes";
import {
  CashbackPayload,
  CashbackItem,
  PromoPayload,
  PromoItem,
} from "../../types/promoTypes";

export const addCashback = async (
  data: CashbackPayload
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/promo/cashback/create",
      data
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editCashback = async (
  data: CashbackPayload,
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.put<DeafaultSuccesResponse>(
      `/promo/cashback/${id}/update`,
      JSON.stringify(data)
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteCashback = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/promo/cashback/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getCashbacksList = async (
  page: number
): Promise<{ data: CashbackItem[]; meta: { last_page: number } } | string> => {
  try {
    const result = await ApiClient.get<{
      data: CashbackItem[];
      meta: { last_page: number };
    }>(`/promo/cashbacks?page=${page}&per_page=10`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getCashbackItem = async (
  id: number
): Promise<{ cashback: CashbackItem } | string> => {
  try {
    const result = await ApiClient.get<{
      cashback: CashbackItem;
    }>(`/promo/cashback/${id}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const addPromo = async (
  data: PromoPayload
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/promo/code/create",
      data
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editPromo = async (
  data: PromoPayload,
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.put<DeafaultSuccesResponse>(
      `/promo/code/${id}/update`,
      JSON.stringify(data)
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
          Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getPromoList = async (
  page: number
): Promise<{ data: PromoItem[]; meta: { last_page: number } } | string> => {
  try {
    const result = await ApiClient.get<{
      data: PromoItem[];
      meta: { last_page: number };
    }>(`/promo/codes?page=${page}&per_page=10`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deletePromo = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/promo/code/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
          Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getUserPromo = async (
  userId: number
): Promise<PromoPayload[] | string> => {
  try {
    const result = await ApiClient.get<{ list: PromoPayload[] }>(
      `/promo/code/${userId}/list`
    );
    return result.list;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const activateUserPromo = async (
  code: string,
  userId: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const formData = new FormData();
    formData.append("user_id", userId.toString());
    formData.append("code", code);
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/promo/code/${code}/activate`,
      formData
    );
    return result.message;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteUserPromo = async (
  userId: number,
  code: string | undefined
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const formData = new FormData();
    if (code) {
      formData.append("code", code);
    }
    formData.append("user_id", userId.toString());
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/promo/deactivate/code`,
      formData
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
