import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { BonusAttachedItem } from "../../types/bonusesTypes";

import closeIcon from "../../images/close-icon-new.svg";

interface Props {
  closeHandler: () => void;
  bonus: BonusAttachedItem;
}

const BonusModal: React.FC<Props> = ({ closeHandler, bonus }) => {
  const { t } = useTranslation();

  const [hours, setHours] = useState<number>(0);

  useEffect(() => {
    if (bonus?.countdown_in_milliseconds) {
      setHours((bonus.countdown_in_milliseconds as number) / 1000 / 60 / 60);
    }
    // eslint-disable-next-line
  }, [bonus]);

  return (
    <div className="default-modal bonus-modal">
      <div className="overlay" onClick={closeHandler} />
      <div className="modal-content">
        <div className="modal-close-btn" onClick={closeHandler}>
          <img src={closeIcon} alt="close" width={15} />
        </div>
        <div className="modal-title">
          <span>{t("You are credited")}</span>
          {bonus.bonus.title}
        </div>
        <div className="bonus-modal-content">
          <div className="bonus-modal-rewards">
            {bonus.bonus.campaigns.map((el, idx) =>
              el.freespins ? (
                <div className="bonus-modal-reward-item" key={el.id}>
                  <div className="reawrd-title">
                    <span>{el.freespins[0].freespins_quantity}</span>
                    {t("Freespins")}
                  </div>
                  {(idx as number) + 1 === bonus.bonus.campaigns.length &&
                  bonus.countdown_in_milliseconds &&
                  bonus.countdown_in_milliseconds !== 0 ? (
                    <div className="bonus-countdown-wrap">
                      <div className="txt">
                        <span>{t("Attention!")}</span>
                        <span>{t("This promotion is valid for:")}</span>
                      </div>
                      <div className="timer">
                        <div className="progress-wrap">
                          <CircularProgressbar
                            value={Math.ceil(
                              100 - (100 * hours) / bonus.bonus.countdown_hours
                            )}
                            strokeWidth={10}
                          />
                        </div>
                        <Countdown
                          date={
                            Date.now() +
                            (bonus.countdown_in_milliseconds as number)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="bonus-modal-reward-item" key={el.id}>
                  <div className="reawrd-title">
                    <span>{el.settings.percentages_of_deposit}%</span>
                    {t("from the deposit")}
                  </div>
                  {(idx as number) + 1 === bonus.bonus.campaigns.length &&
                  bonus.countdown_in_milliseconds &&
                  bonus.countdown_in_milliseconds !== 0 ? (
                    <div className="bonus-countdown-wrap">
                      <div className="txt">
                        <span>{t("Attention!")}</span>
                        <span>{t("This promotion is valid for:")}</span>
                      </div>
                      <div className="timer">
                        <div className="progress-wrap">
                          <CircularProgressbar
                            value={Math.ceil(
                              100 - (100 * hours) / bonus.bonus.countdown_hours
                            )}
                            strokeWidth={10}
                          />
                        </div>
                        <Countdown
                          date={
                            Date.now() +
                            (bonus.countdown_in_milliseconds as number)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )
            )}
          </div>
          {bonus.bonus?.image && (
            <div className="bonus-modal-img">
              <img src={bonus.bonus?.image} alt="" />
            </div>
          )}
        </div>
        <div className="bonus-modal-footer">
          <div className="txt">
            {t("In order to pick up the bonus, click the Activate button")}
          </div>
          <button
            className="primary-btn"
            type="button"
            onClick={(): void => {
              closeHandler();
            }}
          >
            {t("Activate")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BonusModal;
