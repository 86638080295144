import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AdminDocInputs from "../inputs/AdminDocInputs";
import AdminCreditCardInputs from "../inputs/AdminCreditCardInputs";
import { DocumentTypes, DocumentItem } from "../../../types/documentsTypes";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleUpdateDocPhoto: (
    documentId: number,
    userId: number,
    e: File,
    type: string
  ) => Promise<void>;
  handleUpdateCreditCard: (
    documentId: number,
    userId: number,
    documentType: string,
    withdrawalCard: string,
    withdrawalMM: string,
    withdrawalYY: string,
    cardImg: File | undefined
  ) => Promise<void>;
  preview: DocumentItem;
}

const UpdateDocumentModal: React.FC<Props> = ({
  show,
  handleClose,
  preview,
  handleUpdateDocPhoto,
  handleUpdateCreditCard,
}) => {
  const [cardImg, setCardImg] = useState<File>();
  const [documentType, setDocumentType] = useState<string>("");
  const [userId, setUserId] = useState<number>(0);
  const [withdrawalCard, setWithdrawalCard] = useState<string>("");
  const [withdrawalYY, setWithdrawalYY] = useState<string>("");
  const [withdrawalMM, setWithdrawalMM] = useState<string>("");
  const { t } = useTranslation();

  const determineDocumentType = (
    docType: string,
    docSide: string
  ): DocumentTypes => {
    if (docType === "ID_CARD" && docSide === "Front") {
      return DocumentTypes.id_card_front;
    }
    if (docType === "ID_CARD" && docSide === "Back") {
      return DocumentTypes.id_card_back;
    }
    if (docType === "DRIVER_LICENSE") {
      return DocumentTypes.driver_license;
    }
    if (docType === "TAX_NUMBER") {
      return DocumentTypes.tax;
    }
    if (docType === "SELFIE_IDENTITY") {
      return DocumentTypes.selphi;
    }
    if (docType === "CREDIT_CARD") {
      return DocumentTypes.credit_card;
    }
    if (docType === "OTHER") {
      return DocumentTypes.other;
    }

    return DocumentTypes.other;
  };

  const handleSelectImg = (e: File | undefined, type: string): void => {
    if (!e) {
      return;
    }
    setCardImg(e);
    if (preview.user && type !== DocumentTypes.credit_card) {
      handleUpdateDocPhoto(preview.id, userId, e, type);
    }
  };

  useEffect(() => {
    setDocumentType(determineDocumentType(preview.type, preview.side));
  }, [preview.type, preview.side]);

  useEffect(() => {
    if (preview.type === "CREDIT_CARD") {
      if (preview.credit_card_number) {
        setWithdrawalCard(preview.credit_card_number);
      }
      if (preview.credit_card_month) {
        setWithdrawalMM(preview.credit_card_month);
      }
      if (preview.credit_card_year) {
        setWithdrawalYY(preview.credit_card_year);
      }
    }
  }, [
    preview.type,
    preview.credit_card_number,
    preview.credit_card_month,
    preview.credit_card_year,
  ]);

  useEffect(() => {
    if (preview.user) {
      setUserId(preview.user.id);
    }
  }, [preview.user]);

  return (
    <Modal
      show={show}
      onHide={(): void => {
        return handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Change photo")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {preview.type === "CREDIT_CARD" ? (
          <AdminCreditCardInputs
            withdrawalCard={withdrawalCard}
            setWithdrawalCard={(value): void => setWithdrawalCard(value)}
            withdrawalMM={withdrawalMM}
            setWithdrawalMM={(value): void => setWithdrawalMM(value)}
            withdrawalYY={withdrawalYY}
            setWithdrawalYY={(value): void => setWithdrawalYY(value)}
            handleSelectImg={handleSelectImg}
            sendCreditCard={(): void => {
              handleUpdateCreditCard(
                preview.id,
                userId,
                documentType,
                withdrawalCard,
                withdrawalMM,
                withdrawalYY,
                cardImg
              );
            }}
            cardImg={cardImg}
            currentBankCard={preview}
          />
        ) : (
          <AdminDocInputs
            type={documentType}
            size="normal"
            documentPath={preview.document_path}
            previewItem={cardImg}
            handleSelect={handleSelectImg}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDocumentModal;
