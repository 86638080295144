import { Store } from "react-notifications-component";

export const showSuccessNotif = (msg: string): void => {
  Store.addNotification({
    message: msg,
    type: "success",
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const showErrorNotif = (msg: string): void => {
  Store.addNotification({
    message: msg,
    type: "danger",
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
