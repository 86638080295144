import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";

import defImg from "../../images/bonus-img.png";

interface Props {
  title: string;
  id: number;
  countdown_time?: number;
  image?: string;
}

const DefBonusItem: React.FC<Props> = ({
  title,
  countdown_time,
  image,
  id,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState<number>();

  useEffect(() => {
    if (countdown_time) setCountdown(countdown_time);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="my-bonus-item default-bonus-item">
      <div className="content-wrap">
        <div className="title">{title}</div>
        {countdown && (
          <div className="timer">
            <span className="timer-title">{t("Until the end left")}</span>
            <Countdown date={Date.now() + countdown} />
          </div>
        )}
        <button
          className="primary-btn"
          type="button"
          onClick={(): void => {
            navigate(`/bonus/${id}`);
          }}
        >
          {t("Activate")}
        </button>
      </div>
      <img src={image || defImg} alt="" />
    </div>
  );
};

export default DefBonusItem;
