import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { addCashback, editCashback } from "../../../services/admin/apiPromo";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";
import { CashbackItem } from "../../../types/promoTypes";

interface Props {
  show: boolean;
  itemToEdit?: CashbackItem;
  handleClose?: () => void;
  handleCashbackAdded?: () => void;
  handleCashbackEdited?: () => void;
}

const CashbackInfoModal: React.FC<Props> = ({
  show,
  itemToEdit,
  handleClose,
  handleCashbackAdded,
  handleCashbackEdited,
}) => {
  const { t } = useTranslation();

  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isEditEnable, setEditEnable] = useState<boolean>(false);
  //  Form values
  const [title, setTitle] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [periodDays, setPeriodDays] = useState<string>("");
  const [periodHours, setPeriodHours] = useState<string>("");
  const [periodMinutes, setPeriodMinutes] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [globalError, setGlobalError] = useState<string>("");

  useEffect(() => {
    if (itemToEdit) {
      setTitle(itemToEdit.name as string);
      setAmount(itemToEdit.amount.toString() as string);
      setPeriodDays(itemToEdit.period_in_days.toString() as string);
      setPeriodHours(itemToEdit.period_in_hours.toString() as string);
      setPeriodMinutes(itemToEdit.period_in_minutes.toString() as string);
      setIsActive(!!itemToEdit.is_active);
    }
  }, [itemToEdit]);

  //   Form logic
  const addNewCashbackHandler = async (): Promise<void> => {
    const result = await addCashback({
      name: title,
      amount: parseInt(amount, 10),
      period_in_days: parseInt(periodDays, 10),
      period_in_hours: parseInt(periodHours, 10),
      period_in_minutes: parseInt(periodMinutes, 10),
      is_active: isActive ? 1 : 0,
    });
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data added successfully"));
      if (handleCashbackAdded) handleCashbackAdded();
    }
  };
  const editCashbackHandler = async (): Promise<void> => {
    const result = await editCashback(
      {
        name: title,
        amount: parseInt(amount, 10),
        period_in_days: parseInt(periodDays, 10),
        period_in_hours: parseInt(periodHours, 10),
        period_in_minutes: parseInt(periodMinutes, 10),
        is_active: isActive ? 1 : 0,
      },
      (itemToEdit?.id as number) || 0
    );
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data edited successfully"));
      if (handleCashbackEdited) handleCashbackEdited();
    }
  };
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    if (itemToEdit) {
      await editCashbackHandler();
    } else {
      await addNewCashbackHandler();
    }
    setFetching(false);
  };

  return (
    <Modal show={show} onHide={handleClose} className="bonuses-admin-modal">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add new cashback")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Title") || "Title"}
                value={title}
                onChange={(e): void => {
                  setTitle(e.target.value);
                }}
                disabled={itemToEdit && !isEditEnable}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("loss percentage")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("loss percentage") || "Процент от проигрыша"}
                value={amount}
                onChange={(e): void => {
                  setAmount(e.target.value);
                }}
                disabled={itemToEdit && !isEditEnable}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Period in days")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Period in days") || "Period in days"}
                value={periodDays}
                onChange={(e): void => {
                  setPeriodDays(e.target.value);
                }}
                disabled={itemToEdit && !isEditEnable}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Period in hours")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Period in hours") || "Period in hours"}
                value={periodHours}
                onChange={(e): void => {
                  setPeriodHours(e.target.value);
                }}
                disabled={itemToEdit && !isEditEnable}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Period in minutes")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Period in minutes") || "Period in minutes"}
                value={periodMinutes}
                onChange={(e): void => {
                  setPeriodMinutes(e.target.value);
                }}
                disabled={itemToEdit && !isEditEnable}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Active")}</Form.Label>
              <Form.Check
                checked={isActive}
                onChange={(): void => {
                  setIsActive(!isActive);
                }}
                disabled={itemToEdit && !isEditEnable}
              />
            </Form.Group>
          </Col>
        </Row>
        {globalError && <div className="error-msg">{globalError}</div>}
      </Modal.Body>
      <Modal.Footer>
        {itemToEdit && !isEditEnable ? (
          <Button
            variant="secondary"
            onClick={(): void => {
              setEditEnable(true);
            }}
            data-testid="bonus-admin-modal-close"
          >
            {t("Edit")}
          </Button>
        ) : (
          <>
            <Button
              variant="secondary"
              onClick={handleClose}
              data-testid="bonus-admin-modal-close"
            >
              {t("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={(): void => {
                handleSubmit();
              }}
              disabled={isFetching}
            >
              {t("Confirm")}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CashbackInfoModal;
