import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { PromoCodeData } from "../../../types/promoTypes";

import closeIcon from "../../../images/close-modal-icon.svg";
import exclamationMark from "../../../images/exclamation-mark.svg";
import styles from "./PromoCodeModal.module.scss";

interface Props {
  showPromocodeInfoModal: () => void;
  promocodeData: PromoCodeData[] | null;
}

const PromocodeInfoModal: React.FC<Props> = ({
  showPromocodeInfoModal,
  promocodeData,
}) => {
  const { t } = useTranslation();
  return (
    <div className="promocode-modal-wrap">
      <div className="promocode-modal">
        <div className="promocode-modal-title">
          <div className="close-btn" onClick={showPromocodeInfoModal}>
            <img src={closeIcon} alt="" />
          </div>
        </div>
        {promocodeData &&
          promocodeData.map((promo) => (
            <div className="promocode-modal-main">
              <div
                className={classNames(
                  styles.promocode_modal_content,
                  styles.fancy_border_btm
                )}
              >
                <img src={exclamationMark} alt="exclamation-mark" />
                <h5>{t("promocode details")}</h5>
                <p>{promo.promocode?.description}</p>
              </div>
              <div className="promocode-modal-footer">
                <p>
                  {t("For more info")}{" "}
                  <a href="/bonus-info">{t("Bonuses and Terms")}</a>{" "}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PromocodeInfoModal;
