import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";

import {
  getUserComments,
  addUserComment,
  editUserComment,
  deleteUserComment,
} from "../../../services/admin/apiUsers";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";

import UserCommentItem from "../UserCommentItem";

interface Props {
  name: string;
  id: number;
  show: boolean;
  handleClose: () => void;
}

const UserCommentsModal: React.FC<Props> = ({
  show,
  handleClose,
  name,
  id,
}) => {
  const { t } = useTranslation();

  const [comments, setComments] =
    useState<{ id: number; message: string; created_at: string }[]>();
  const [newComment, setNewComment] = useState<{
    id: number;
    message: string;
    created_at: string;
  }>();
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isItemFetching, setItemFetching] = useState<boolean>(false);

  const loadUserComments = async (page: number): Promise<void> => {
    setFetching(true);
    const result = await getUserComments(page + 1, id);
    if (typeof result !== "string") {
      setComments(
        result.data as { id: number; message: string; created_at: string }[]
      );
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    loadUserComments(0);
    // eslint-disable-next-line
  }, []);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadUserComments(e);
  };

  const handleCommentChange = async (
    e: string,
    comment: number
  ): Promise<void> => {
    setItemFetching(true);
    const result = await editUserComment(e, id, comment);
    if (typeof result !== "string") {
      showSuccessNotif(t("Data edited successfully"));
      loadUserComments(activePage);
    } else {
      showErrorNotif(result as string);
    }
    setItemFetching(false);
  };
  const handleCommentRemove = async (comment: number): Promise<void> => {
    setItemFetching(true);
    const result = await deleteUserComment("", id, comment);
    if (typeof result !== "string") {
      showSuccessNotif(t("Data deleted successfully"));
      setActivePage(0);
      loadUserComments(0);
    } else {
      showErrorNotif(result as string);
    }
    setItemFetching(false);
  };
  const handleCommentAdded = async (e: string): Promise<void> => {
    setItemFetching(true);
    const result = await addUserComment(e, id);
    if (typeof result !== "string") {
      showSuccessNotif(t("Data added successfully"));
      setActivePage(0);
      setNewComment(undefined);
      loadUserComments(0);
    } else {
      showErrorNotif(result as string);
    }
    setItemFetching(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="admin-bets-wrap user-comments-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("User comments")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("Name")}: {name}
        </p>
        {isFetching && (
          <CirclesWithBar
            height="200"
            width="200"
            color="#0d6efd"
            wrapperStyle={{ justifyContent: "center" }}
            visible
            ariaLabel="circles-with-bar-loading"
          />
        )}
        {!isFetching && (
          <>
            {!newComment && (
              <Button
                variant="primary"
                className="mb-2"
                onClick={(): void => {
                  setNewComment({ id: 0, message: "", created_at: "" });
                }}
              >
                + {t("Add new comment")}
              </Button>
            )}
            {newComment && (
              <UserCommentItem
                value={newComment.message}
                handleCommentChange={(e: string, comment: number): void => {
                  handleCommentChange(e, comment);
                }}
                handleCommentRemove={(comment: number): void => {
                  handleCommentRemove(comment);
                }}
                handleCommentAdded={(e: string): void => {
                  handleCommentAdded(e);
                }}
                isNew
                disabled={isItemFetching}
              />
            )}
            {comments?.map((el) => (
              <UserCommentItem
                key={el.id}
                value={el.message}
                date={el.created_at}
                id={el.id}
                handleCommentChange={(e: string, comment: number): void => {
                  handleCommentChange(e, comment);
                }}
                handleCommentRemove={(comment: number): void => {
                  handleCommentRemove(comment);
                }}
                handleCommentAdded={(e: string): void => {
                  handleCommentAdded(e);
                }}
                disabled={isItemFetching}
              />
            ))}
            {totalPages > 1 && (
              <div className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  pageRangeDisplayed={5}
                  pageCount={totalPages}
                  previousLabel="<"
                  forcePage={activePage}
                  onPageChange={(e): void => {
                    handlePaginationChange(e.selected);
                  }}
                />
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UserCommentsModal;
