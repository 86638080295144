import React, { useEffect, useState } from "react";
import { Table, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDetectClickOutside } from "react-detect-click-outside";
import {
  formatDateDay,
  formatDateSecondsApi,
} from "../../../helpers/getCorrectDate";

import { getNewUsers } from "../../../services/admin/apiReporting";
import { UserInfo, CountryItem } from "../../../types/userTypes";
import { getCountries } from "../../../services/apiLogin";

import arrowIcon from "../../../images/dropdown-arrow.svg";

const NewUsers: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [country, setCountry] = useState<CountryItem>();
  //   Countries data
  const [countriesList, setCountriesList] = useState<CountryItem[]>([]);
  const [hasMoreCountry, setHasMoreCountry] = useState<boolean>(true);
  const [activeCountryPage, setActiveCountryPage] = useState<number>(1);
  const [showDrodown, setShowDropdown] = useState<boolean>(false);
  // Users data
  const [users, setUsers] = useState<UserInfo[]>();
  const [isFetching, setFetching] = useState<boolean>(false);

  const { t } = useTranslation();

  //   Users logic
  const loadUsers = async (search?: string): Promise<void> => {
    setFetching(true);
    const result = await getNewUsers(search);
    if (typeof result !== "string") {
      setUsers(result.data.users as UserInfo[]);
    }
    setFetching(false);
  };
  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line
  }, []);

  //   Country logic
  const getCountriesHandle = async (page: number): Promise<void> => {
    const result = await getCountries(page);
    if (typeof result !== "string") {
      setCountriesList([...countriesList, ...(result.data as CountryItem[])]);
      if (result.data.length < 350) {
        setHasMoreCountry(false);
      }
    }
  };
  const handleScroll = (element: HTMLDivElement): void => {
    if (
      element.scrollHeight - element.scrollTop <= element.clientHeight + 5 &&
      hasMoreCountry
    ) {
      setActiveCountryPage(activeCountryPage + 1);
      getCountriesHandle(activeCountryPage + 1);
    }
  };
  const showDropdownHandler = (): void => {
    setShowDropdown(true);
  };
  const hideDropdownHandler = (): void => {
    setShowDropdown(false);
  };
  const ref = useDetectClickOutside({
    onTriggered: hideDropdownHandler,
  });
  useEffect(() => {
    getCountriesHandle(1);
    // eslint-disable-next-line
  }, []);

  const handleClearSearch = (): void => {
    setStartDate(undefined);
    setEndDate(undefined);
    loadUsers();
  };
  const handleSearch = (): void => {
    const searchValue = `${
      startDate ? `&start_date=${formatDateSecondsApi(startDate)}` : ""
    }${endDate ? `&end_date=${formatDateSecondsApi(endDate)}` : ""}${
      country ? `&country_code=${country.code}` : ""
    }`;
    loadUsers(searchValue);
  };

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>{t("Filters")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <>
              <Row className="mb-3">
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Start date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setStartDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={startDate}
                      dropdownMode="select"
                      placeholderText={t("Start date") || "Start date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("End date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setEndDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={endDate}
                      dropdownMode="select"
                      placeholderText={t("End date") || "End date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Country")}</Form.Label>
                    <div className="custom-select-wrap" ref={ref}>
                      <div
                        className="custom-select-item"
                        onClick={showDropdownHandler}
                      >
                        {country?.name || <span>{t("Select country")}</span>}
                        <img src={arrowIcon} alt="" />
                      </div>
                      {showDrodown && (
                        <div
                          className="custom-select-dropdown"
                          onScroll={(e): void => {
                            handleScroll(e.target as HTMLDivElement);
                          }}
                        >
                          {countriesList.map((el) => (
                            <span
                              key={el.id}
                              onClick={(): void => {
                                setCountry(el);
                                hideDropdownHandler();
                              }}
                            >
                              {el.name}
                            </span>
                          ))}
                          {hasMoreCountry && (
                            <CirclesWithBar
                              height="50"
                              width="50"
                              color="#0d6efd"
                              wrapperStyle={{ justifyContent: "center" }}
                              visible
                              ariaLabel="circles-with-bar-loading"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div className="admin-search-btns">
                    <Button
                      variant="danger"
                      onClick={handleClearSearch}
                      disabled={isFetching}
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isFetching}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {users && !isFetching && (
        <Table
          striped
          bordered
          className="mb-5"
          responsive
          data-testid="new-users-table"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("Name")}</th>
              <th>{t("Email address")}</th>
              <th>{t("Phone")}</th>
              <th>{t("Date of birth")}</th>
              <th>{t("Registration date")}</th>
              <th>{t("Country")}</th>
              <th>{t("City")}</th>
              <th>{t("Gender")}</th>
              <th>{t("Blocked")}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>
                  {el.first_name} {el.last_name}
                </td>
                <td>{el.email}</td>
                <td>{el.phone}</td>
                <td>
                  {el.date_of_birth
                    ? formatDateDay(el.date_of_birth as string)
                    : ""}
                </td>
                <td>
                  {el.created_at ? formatDateDay(el.created_at as string) : ""}
                </td>
                <td>{el?.country?.name as string}</td>
                <td>{el.city}</td>
                <td>{el.gender}</td>
                <td>{el.is_blocked ? "+" : "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default NewUsers;
