import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getAllGamesList,
  getGamesCategories,
  getGamesVendors,
} from "../../services/apiGames";
import {
  GameItemType,
  CategoryItemType,
  VendorItemType,
} from "../../types/gameTypes";
import {
  AllGamesListResponse,
  GameListPayload,
} from "../../types/requestTypes";

export interface GamesState {
  allGames: GameItemType[] | null;
  categories: CategoryItemType[] | null;
  vendors: VendorItemType[] | null;
  allFetching: boolean;
  categoryFetching: boolean;
  totalGames: number;
  activePage: number;
  hasMoreGames: boolean;
}

const initialState: GamesState = {
  allGames: null,
  categories: null,
  vendors: null,
  allFetching: false,
  categoryFetching: false,
  totalGames: 0,
  activePage: 1,
  hasMoreGames: true,
};

export const getAllGamesFunc = createAsyncThunk(
  "games/loadAllList",
  async (data: GameListPayload) => {
    let itemsPerPage;
    if (window.innerWidth <= 576) {
      itemsPerPage = 16;
    } else if (window.innerWidth <= 990) {
      itemsPerPage = 32;
    } else {
      itemsPerPage = 64;
    }
    const response = await getAllGamesList(
      data.search as string | null,
      data.category as string | null,
      data.producer as string | null,
      data.page as number | undefined,
      itemsPerPage,
      data.lobby as boolean | undefined,
      data.isLive as boolean | undefined
    );
    if (typeof response !== "string") return response as AllGamesListResponse;
    return { data: [], meta: { total: 0, last_page: 0 } };
  }
);
export const getGamesCategoriesFunc = createAsyncThunk(
  "games/loadCategories",
  async () => {
    const response = await getGamesCategories();
    if (typeof response !== "string")
      return response.data as CategoryItemType[];
    return [];
  }
);
export const getGamesVendorsFunc = createAsyncThunk(
  "games/loadVendors",
  async () => {
    const response = await getGamesVendors();
    if (typeof response !== "string")
      return response.vendors as VendorItemType[];
    return [];
  }
);

export const games = createSlice({
  name: "games",
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<number>) => {
      state.activePage = action.payload;
    },
    clearGames: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.allGames = null;
      } else {
        state.allGames = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllGamesFunc.fulfilled,
      (state: GamesState, action: PayloadAction<AllGamesListResponse>) => {
        state.allGames = state.allGames
          ? [...state.allGames, ...action.payload.data]
          : action.payload.data;
        let itemsPerPage;
        if (window.innerWidth <= 576) {
          itemsPerPage = 16;
        } else if (window.innerWidth <= 990) {
          itemsPerPage = 32;
        } else {
          itemsPerPage = 64;
        }
        if (action.payload.data.length < itemsPerPage) {
          state.hasMoreGames = false;
        } else {
          state.hasMoreGames = true;
        }
        state.totalGames = action.payload.meta.total;
        state.allFetching = false;
      }
    );
    builder.addCase(
      getGamesCategoriesFunc.fulfilled,
      (state: GamesState, action: PayloadAction<CategoryItemType[]>) => {
        state.categories = action.payload;
        state.categoryFetching = false;
      }
    );
    builder.addCase(
      getGamesVendorsFunc.fulfilled,
      (state: GamesState, action: PayloadAction<VendorItemType[]>) => {
        state.vendors = action.payload;
      }
    );
    builder.addCase(getAllGamesFunc.pending, (state: GamesState) => {
      state.allFetching = true;
    });
    builder.addCase(getGamesCategoriesFunc.pending, (state: GamesState) => {
      state.categoryFetching = true;
    });
  },
});

export const { setActivePage, clearGames } = games.actions;

export default games.reducer;
