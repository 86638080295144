import { TournamentRewardItem } from "./tournamentTypes";
import { BonusAttachedItem } from "./bonusesTypes";

export interface UserLoginData {
  password?: string;
  email?: string;
  phone?: string;
  locale?: string;
  click_id?: string;
  pid?: string;
  advertiser_id?: string;
  offer_id?: string;
  action_id?: string;
  goal?: string;
  promocode?: string;
}

export interface UserInfo {
  email: string | null;
  first_name: string | null;
  full_name: string | null;
  last_name: string | null;
  nick_name: string | null;
  permissions: string[];
  phone: string | null;
  integer_public_id: number | null;
  public_id: string;
  id: number;
  role?: UserRoles;
  is_confirmed: boolean;
  date_of_birth: string;
  city: string;
  gender: string;
  country?: CountryItem;
  current_balance: BalanceItem;
  balances: BalanceItem[];
  locale?: LocaleItem;
  tournaments?: TournamentRewardItem[];
  is_blocked?: boolean;
  is_deposit_blocked?: boolean;
  created_at?: string;
  rakebackPercent?: number;
  vip_club?: VipClub;
  welcome_bonuses?: BonusAttachedItem[];
  password_reset?: string;
  ip?: string;
  device_info?: string;
  unconfirmed_withdrawal?: boolean;
  withdrawal_super_rule?: boolean;
  withdrawal_errors?: {
    card_is_not_approved: boolean;
    cards_are_not_equal: boolean;
    deposit_blocked: boolean;
    unconfirmed_withdrawal: boolean;
  };
  active_tournaments_count?: number;
  is_email_confirmed?: boolean;
  is_phone_confirmed?: boolean;
}

export interface BalanceItem {
  amount: number;
  type: string;
  code?: string;
}

export interface CountryItem {
  id: number;
  code: string;
  name: string;
}

export interface LocaleItem {
  id: number;
  code: string;
  name: string;
}

export interface VipClub {
  current_amount: number;
  level: number;
  value_for_complete: number;
  rewards?: any[];
}

export interface UpdateInfoPayload {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  gender?: string;
  country_id?: string;
  city?: string;
  date_of_birth?: string;
  locale_id?: number;
  balance_type?: string;
  role?: UserRoles;
  password?: string;
  is_blocked?: number;
  is_deposit_blocked?: number;
  reason_blocked?: string;
  withdrawal_super_rule?: number;
  ip?: string;
}

export enum UserRoles {
  player = "player",
  admin = "admin",
  trainee = "trainee",
  operator = "operator",
  "senior-operator" = "senior-operator",
  "retention-manager" = "retention-manager",
  "payment-manager" = "payment-manager",
}

export enum UserStatus {
  junior = "Junior",
  master = "Master",
  favorite = "Favorite",
  pro = "Pro",
  champion = "Champion",
}

export interface UserBalanceItem {
  user: UserInfo;
  balance_amount: number;
  balance_type: string;
  currency: {
    code: string;
  };
}

export interface WinnerType {
  game: string;
  gameFullName: string;
  gameIcon: string;
  gameId: string;
  roundId: string;
  currency: string;
  betAmount: number;
  winAmount: number;
  createdAt: number;
  ratio: number;
  winner: string;
}
