import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { deleteCashback } from "../../../services/admin/apiPromo";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";

interface Props {
  name: string;
  id: number;
  show: boolean;
  handleClose: () => void;
  handleCashbackDeleted: () => void;
}

const DeleteCashbackModal: React.FC<Props> = ({
  show,
  handleClose,
  name,
  handleCashbackDeleted,
  id,
}) => {
  const { t } = useTranslation();

  const [isFetching, setFetching] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>("");

  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    const result = await deleteCashback(id);
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      handleCashbackDeleted();
      showSuccessNotif(t("Data deleted successfully"));
    }
    setFetching(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Delete cashback")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("Are you sure you want to cashback")} {name}?
        </p>
        {globalError && <div className="error-msg">{globalError}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
        <Button
          variant="primary"
          onClick={(): void => {
            handleSubmit();
          }}
          disabled={isFetching}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCashbackModal;
