import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MobileMenuModal from "./modals/MobileMenuModal/MobileMenuModal";
import type { RootState } from "../store/store";

import homeIcon from "../images/categories/all-games.svg";
import liveIcon from "../images/categories/live-games.svg";
import menuIcon from "../images/menu-icons/menu-icon.svg";
import depositIcon from "../images/menu-icons/deposit-icon.svg";
import profileIcon from "../images/menu-icons/profile-icon.svg";

interface MobileMenuProps {
  setOpenPersonalDataModal: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  setOpenPersonalDataModal,
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const isConfirmUser = useSelector(
    (state: RootState) => state.user.userInfo.first_name
  );
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  const closeMobileMenuModal = (): void => {
    setOpenMobileMenu(false);
    document.body.classList.remove("no-scroll-menu");
    document.querySelector(".menu-wrap")?.classList.remove("position");
  };

  useEffect(() => {
    closeMobileMenuModal();
  }, [location.pathname]);

  return (
    <>
      <div
        className={
          location.pathname.includes("login") ||
          location.pathname.includes("register") ||
          location.pathname.includes("password") ||
          location.pathname.includes("admin-panel")
            ? "mobile-menu-main-wrap hidden"
            : "mobile-menu-main-wrap"
        }
      >
        <div
          className={
            openMobileMenu ? "menu-item-menu active" : "menu-item-menu"
          }
          onClick={(): void => {
            if (isLogged) {
              if (isConfirmUser) {
                setOpenMobileMenu(!openMobileMenu);
                document.body.classList.toggle("no-scroll-menu");
                document
                  .querySelector(".menu-wrap")
                  ?.classList.toggle("position");
              } else {
                setOpenPersonalDataModal();
              }
            } else {
              navigate("/login");
            }
          }}
        >
          <div className="blur-bcg" />
          <img src={menuIcon} alt="" />
          <span
            className="name"
            style={
              localeActive?.code === "fa"
                ? { padding: 0, textAlign: "center" }
                : {}
            }
          >
            {t("Menu")}
          </span>
        </div>
        <div
          className="mobile-menu-general"
          style={localeActive?.code === "fa" ? { direction: "rtl" } : {}}
        >
          <div className="mobile-menu-row">
            <div
              className={
                location.pathname === "/" &&
                !location.state?.isLive &&
                !openMobileMenu
                  ? "menu-item active"
                  : "menu-item"
              }
              onClick={(): void => {
                if (openMobileMenu) closeMobileMenuModal();
                navigate("/", {
                  state: { isLive: false },
                });
              }}
            >
              <img src={homeIcon} alt="" />
              <span className="name">{t("Casino")}</span>
            </div>
            <div
              className={
                location.pathname === "/" &&
                location.state?.isLive &&
                !openMobileMenu
                  ? "menu-item active"
                  : "menu-item"
              }
              onClick={(): void => {
                if (openMobileMenu) closeMobileMenuModal();
                navigate("/", {
                  state: { isLive: true },
                });
              }}
            >
              <img src={liveIcon} alt="" />
              <span className="name">{t("Live games")}</span>
            </div>
            <div
              className="menu-item-placeholder"
              style={{
                position: "relative",
                visibility: "hidden",
                width: "54px",
                height: "68px",
              }}
            />
            <div
              className={
                location.pathname === "/payments" && !openMobileMenu
                  ? "menu-item active"
                  : "menu-item"
              }
              onClick={(): void => {
                if (openMobileMenu) closeMobileMenuModal();
                if (isLogged) {
                  navigate("/payments");
                } else {
                  navigate("/login");
                }
              }}
            >
              <img src={depositIcon} alt="" />
              <span className="name">{t("Top up")}</span>
            </div>
            <div
              className={
                location.pathname === "/profile" && !openMobileMenu
                  ? "menu-item active"
                  : "menu-item"
              }
              onClick={(): void => {
                if (openMobileMenu) closeMobileMenuModal();
                if (isLogged) {
                  if (!isConfirmUser) {
                    setOpenPersonalDataModal();
                  } else navigate("/profile");
                } else {
                  navigate("/login");
                }
              }}
            >
              <img src={profileIcon} alt="" />
              <span className="name">{t("Profile")}</span>
            </div>
          </div>
        </div>
      </div>
      {/* {openMobileMenu && ( */}
      <MobileMenuModal
        onClose={(): void => {
          setOpenMobileMenu(false);
          closeMobileMenuModal();
        }}
        open={openMobileMenu}
      />
      {/* )} */}
    </>
  );
};

export default MobileMenu;
