import React, { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useTranslation } from "react-i18next";

import arrowIcon from "../../images/arrow-down-new.svg";

interface Props {
  items: { value: string; title: string }[];
  selectedItem?: { value: string; title: string };
  disabled?: boolean;
  placeholder: string;
  handleSelect: (e: { value: string; title: string }) => void;
}

const DarkSelect: React.FC<Props> = ({
  items,
  handleSelect,
  placeholder,
  disabled,
  selectedItem,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const { t } = useTranslation();

  const hideDropdownHandle = (): void => {
    setShowDropdown(false);
  };
  const toggleDropdownHandle = (): void => {
    setShowDropdown(!showDropdown);
  };

  const ref = useDetectClickOutside({
    onTriggered: hideDropdownHandle,
  });

  const handleChange = (e: { value: string; title: string }): void => {
    handleSelect(e);
    toggleDropdownHandle();
  };

  return (
    <div
      className={showDropdown ? "dark-select-wrap shown" : "dark-select-wrap"}
      ref={ref}
    >
      <div
        className={disabled ? "selected-box disabled" : "selected-box"}
        onClick={toggleDropdownHandle}
      >
        {selectedItem ? (
          <span>{t(selectedItem.title)}</span>
        ) : (
          <span>{placeholder}</span>
        )}
        <img src={arrowIcon} alt="" />
      </div>
      {showDropdown && (
        <div className="dark-select-list">
          {items.map((el) => (
            <div
              className="list-item"
              key={el.value}
              data-testid={`default-select-list-item-${el.value}`}
              onClick={(): void => {
                handleChange(el);
              }}
            >
              {t(el.title)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DarkSelect;
