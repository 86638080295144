import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CountryItem, UserInfo } from "../../types/userTypes";
import { loggedAction } from "../../store/slices/userData";
import type { RootState } from "../../store/store";

import PhoneInputWrapper from "../forms/PhoneInputWrapper";
import ErrorModal from "../modals/ErrorModal";
import VipBar from "../listItems/VipBar";

import checkIcon from "../../images/input-confirm-icon.svg";
import TextField from "../UI/TextField/TextField";
import validateEmail from "../../helpers/validate/validateEmail";

enum Genders {
  male = "male",
  female = "female",
}

interface Props {
  showChangePassword: () => void;
  showConfirmContact: (isMail: boolean, value: string) => void;
}

const ProfileInfo: React.FC<Props> = ({
  showChangePassword,
  showConfirmContact,
}) => {
  // Conditions

  const [globalError, setGlobalError] = useState<string | undefined>();
  // Values
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  // const [city, setCity] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [isValidateEmail, setIsValidateEmail] = useState(validateEmail(mail));
  const [phone, setPhone] = useState<string>("");
  const [gender, setGender] = useState<Genders>();
  const [country, setCountry] = useState<CountryItem>();
  const [isShowErrorEmail, setIsShowErrorEmail] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const fillDefValues = (info: UserInfo): void => {
    setFirstName(info.first_name || "");
    setLastName(info.last_name || "");
    // setCity(info.city as string);
    setMail(info.email || "");
    setPhone(info.phone || "");
    setGender(
      info.gender === null || info.gender === "m"
        ? Genders.male
        : Genders.female
    );
    setCountry(info?.country);
  };
  //   Initial data
  useEffect(() => {
    fillDefValues(userInfo);
    if (userInfo.country) {
      setCountry(userInfo.country);
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const logOutHandler = (): void => {
    dispatch(loggedAction(false));
    navigate("/");
  };

  const renderGender = (): string => {
    if (gender === Genders.male) {
      return t("Male");
    }
    if (gender === Genders.female) {
      return t("Female");
    }
    return "";
  };

  const onChangeEmail = (value: string): void => {
    setMail(value);
    setIsValidateEmail(validateEmail(value));
    setIsShowErrorEmail(false);
  };

  return (
    <div className="profile-info-wrap">
      <div className="profile-info-content">
        <VipBar />
        <div className="profile-id-info">
          <span>{t("Account ID")}</span>
          <span>{userInfo.integer_public_id}</span>
        </div>
        <div className="profile-row">
          <div className="profile-item">
            <TextField value={firstName} placeholder="First_name" isReadonly />
          </div>
          <div className="profile-item">
            <TextField value={lastName} placeholder="Last_name" isReadonly />
          </div>
        </div>
        <div className="profile-row">
          <div className="profile-item">
            <TextField
              value={country?.name || ""}
              placeholder="Country"
              isReadonly
            />
          </div>
        </div>
        {/* <div className="profile-row">
          <div className="profile-item">
            <DebounceInput
              debounceTimeout={1000}
              onChange={(event): void => {
                handleProfileChange(
                  {
                    city: event.target.value,
                  },
                  Fields.city
                );
              }}
              value={city}
              disabled={debounceFetching && debounceFetching === Fields.city}
              type="text"
              className="main-input"
              placeholder={t("City") || "City"}
            />
          </div>
        </div> */}
        <div className="profile-row">
          <div className="profile-item">
            <TextField value={renderGender()} isReadonly />
          </div>
          <div className="profile-item">
            <div
              className={
                userInfo.is_phone_confirmed
                  ? "profile-phone-wrap confirmed"
                  : "profile-phone-wrap"
              }
            >
              <PhoneInputWrapper
                containerClass="phone-input"
                country={userInfo.country?.code.toLowerCase() || "ru"}
                value={phone}
                onChange={(e: string): void => {
                  setPhone(e);
                }}
                isReadOnly={userInfo.is_phone_confirmed}
              />
              {userInfo.is_phone_confirmed && (
                <img src={checkIcon} className="check-icon" alt="" />
              )}
              {!userInfo.is_phone_confirmed && (
                <div className="confirm-box">
                  <div className="txt">{t("Phone not confirmed")}</div>
                  <button
                    disabled={phone === ""}
                    className="main-btn"
                    type="button"
                    onClick={(): void => {
                      showConfirmContact(false, phone || "");
                    }}
                  >
                    {t("Confirm")}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="profile-item">
            <div
              className={
                userInfo.is_email_confirmed
                  ? "profile-mail-wrap confirmed"
                  : "profile-mail-wrap"
              }
            >
              <TextField
                className="input_padding_right"
                value={mail}
                onChange={onChangeEmail}
                placeholder="Email address"
                isReadonly={userInfo.is_email_confirmed}
              />
              {isShowErrorEmail && (
                <span
                  style={{
                    position: "absolute",
                    left: "12px",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {t(isValidateEmail.message)}
                </span>
              )}
              {userInfo.is_email_confirmed ? (
                <img src={checkIcon} className="check-icon" alt="" />
              ) : (
                <div className="confirm-box">
                  <div className="txt">{t("Mail not confirmed")}</div>
                  <button
                    disabled={mail === ""}
                    className="main-btn"
                    type="button"
                    onClick={(): void => {
                      if (!isValidateEmail.isValid && mail !== "") {
                        showConfirmContact(true, mail || "");
                      } else {
                        setIsShowErrorEmail(true);
                      }
                    }}
                  >
                    {t("Confirm")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="profile-security">
          <div className="title">{t("Security")}</div>
          <button
            className="main-btn"
            type="button"
            onClick={(): void => {
              showChangePassword();
            }}
          >
            {t("Change Password")}
          </button>
        </div>
        <button
          className="border-btn logout-btn"
          type="button"
          onClick={logOutHandler}
        >
          {t("Log out")}
        </button>
      </div>
      {globalError && (
        <ErrorModal
          btnTxt="OK"
          mainTxt={globalError}
          closeModalHandler={(): void => {
            setGlobalError(undefined);
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("menu-hidden");
          }}
          confirmModalHandler={(): void => {
            setGlobalError(undefined);
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("menu-hidden");
          }}
        />
      )}
    </div>
  );
};

export default ProfileInfo;
