import ApiClient from "../ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../../types/requestTypes";
import {
  FreeSpinItem,
  AddFreeSpinPayload,
  EditFreeSpinPayload,
} from "../../types/gameTypes";

export const getFreeSpinsList = async (
  page: number
): Promise<{ data: FreeSpinItem[]; meta: { last_page: number } } | string> => {
  try {
    const result = await ApiClient.get<{
      data: FreeSpinItem[];
      meta: { last_page: number };
    }>(`/freespins?page=${page}&per_page=20`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getFreeSpinInfo = async (
  id: number
): Promise<{ freespin: FreeSpinItem } | string> => {
  try {
    const result = await ApiClient.get<{
      freespin: FreeSpinItem;
    }>(`/freespins/show/${id}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const createFreeSpin = async (
  data: AddFreeSpinPayload
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/freespins/create",
      data
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editFreeSpin = async (
  data: EditFreeSpinPayload,
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.put<DeafaultSuccesResponse>(
      `/freespins/update/${id}`,
      JSON.stringify(data)
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const addFreeSpinUsers = async (
  data: number[],
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/freespins/${id}/attach-users`,
      { users_ids: data }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const removeFreeSpinUsers = async (
  data: string[],
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/freespins/${id}/detach-users`,
      { issue_ids: data }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteFreeSpin = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/freespins/delete/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
