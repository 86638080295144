import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ModalTitle.Styles.module.scss";
import closeIcon from "../../../images/close-modal-icon.svg";

interface ModalTitleProps {
  onClose: () => void;
  title?: string;
  children?: React.ReactNode;
}

const ModalTitle: React.FC<ModalTitleProps> = ({
  onClose,
  title,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.modal_title}>
      {children && <div>{children}</div>}
      {title && <div className={styles.title}>{t(title)}</div>}
      <div className={styles.close_btn} onClick={onClose}>
        <img src={closeIcon} alt="" />
      </div>
    </div>
  );
};

export default ModalTitle;
