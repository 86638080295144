import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SuccessModal from "../modals/SuccessModal";

interface Props {
  handleModalShow: (e: boolean) => void;
}

const SupportForm: React.FC<Props> = ({ handleModalShow }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState<boolean>(false);

  const toggleShowModal = (): void => {
    setModalShow(!modalShow);
    handleModalShow(!modalShow);
  };

  return (
    <div className="support-form-wrap">
      <div className="support-form-content">
        <div className="title">{t("Support2")}</div>
        <div className="sub-title">
          {t("To contact us - fill out the form below")}
        </div>
        <div className="support-form">
          <div className="form-title">{t("Welcome to BOOM Casino")}</div>
          <input
            type="text"
            className="main-input"
            placeholder={t("Full name") || "Full name"}
          />
          <input
            type="text"
            className="main-input"
            placeholder={t("E-mail address") || "E-mail address"}
          />
          <textarea
            className="main-input"
            rows={6}
            placeholder={
              t("Enter your message here") || "Enter your message here"
            }
          />
          <button className="main-btn" type="button" onClick={toggleShowModal}>
            {t("Send a message")}
          </button>
        </div>
      </div>
      {modalShow && (
        <SuccessModal
          closeModalHandler={toggleShowModal}
          secondBtnHandler={toggleShowModal}
          mainBtnHandler={(): void => {
            navigate("/");
          }}
          title={t("Message sent") || "Message sent"}
          mainTxt={
            t(
              "The answer will be sent within a few hours to the email address specified in your profile."
            ) ||
            "The answer will be sent within a few hours to the email address specified in your profile."
          }
          mainBtnTxt={t("To main") || "To main"}
          secondBtnTxt={t("Back to profile") || "Back to profile"}
        />
      )}
    </div>
  );
};

export default SupportForm;
