// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomDatePickerStyles_custom_date_picker__65rSb {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 12px;\n}", "",{"version":3,"sources":["webpack://./src/components/forms/CustomDatePicker/CustomDatePickerStyles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACF","sourcesContent":[".custom_date_picker {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_date_picker": "CustomDatePickerStyles_custom_date_picker__65rSb"
};
export default ___CSS_LOADER_EXPORT___;
