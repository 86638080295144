import ApiClient from "../ApiClient";
import {
  RequestError,
  UserDataResponse,
  DeafaultSuccesResponse,
} from "../../types/requestTypes";
import {
  UserInfo,
  UpdateInfoPayload,
  BalanceItem,
  UserBalanceItem,
} from "../../types/userTypes";
import { UserBetItem, UserFreeSpinItem } from "../../types/gameTypes";

export const getUsersList = async (
  page: number,
  search: string
): Promise<{ data: UserInfo[]; meta: { last_page: number } } | string> => {
  try {
    const result = await ApiClient.get<{
      data: UserInfo[];
      meta: { last_page: number };
    }>(`/users?page=${page}&per_page=20${search}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getUsersBalanceList = async (
  page: number,
  search: string,
  sort?: string
): Promise<
  { data: UserBalanceItem[]; meta: { last_page: number } } | string
> => {
  try {
    const result = await ApiClient.get<{
      data: UserBalanceItem[];
      meta: { last_page: number };
    }>(`/users/balances/list?page=${page}&per_page=20${search}${sort || ""}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const createUser = async (
  data: UpdateInfoPayload
): Promise<UserDataResponse | string> => {
  try {
    const result = await ApiClient.post<UserDataResponse>(
      "/users/create",
      data
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editUser = async (
  data: UpdateInfoPayload,
  id: number
): Promise<UserDataResponse | string> => {
  try {
    const result = await ApiClient.put<UserDataResponse>(
      `/users/update/${id}`,
      JSON.stringify(data)
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteUser = async (
  id: number
): Promise<UserDataResponse | string> => {
  try {
    const result = await ApiClient.delete<UserDataResponse>(
      `/users/delete/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getUserBalance = async (
  id: number
): Promise<
  { balances: BalanceItem[]; meta: { last_page: number } } | string
> => {
  try {
    const result = await ApiClient.get<{
      balances: BalanceItem[];
      meta: { last_page: number };
    }>(`/users/show/${id}/balance`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editUserBalance = async (
  balance_type: string,
  balance_amount: number,
  id: number
): Promise<UserDataResponse | string> => {
  try {
    const result = await ApiClient.put<UserDataResponse>(
      `users/update/${id}/balance`,
      JSON.stringify({ balance_type, balance_amount })
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const editUserBalanceCurrency = async (
  currency_id: number,
  id: number
): Promise<{ balances: BalanceItem[] } | string> => {
  try {
    const result = await ApiClient.put<{ balances: BalanceItem[] }>(
      `users/update/${id}/balance`,
      JSON.stringify({ currency_id })
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getUserBets = async (
  page: number,
  id: number
): Promise<{ data: UserBetItem[]; meta: { last_page: number } } | string> => {
  try {
    const result = await ApiClient.get<{
      data: UserBetItem[];
      meta: { last_page: number };
    }>(`/games-rounds/user/${id}?page=${page}&per_page=50`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getUserFreeSpins = async (
  page: number,
  id: number
): Promise<
  { freespins: UserFreeSpinItem[]; meta: { last_page: number } } | string
> => {
  try {
    const result = await ApiClient.get<{
      freespins: UserFreeSpinItem[];
      meta: { last_page: number };
    }>(`users/show/${id}/freespins?page=${page}&per_page=20`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getUserComments = async (
  page: number,
  id: number
): Promise<
  | {
      data: { id: number; message: string; created_at: string }[];
      meta: { last_page: number };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: { id: number; message: string; created_at: string }[];
      meta: { last_page: number };
    }>(`/users/${id}/comments/list?page=${page}&per_page=10`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const addUserComment = async (
  message: string,
  user_id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/users/comment/create",
      { message, user_id }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editUserComment = async (
  message: string,
  user_id: number,
  comment_id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.put<DeafaultSuccesResponse>(
      `/users/comment/${comment_id}/update`,
      JSON.stringify({ message, user_id })
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteUserComment = async (
  message: string,
  user_id: number,
  comment_id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/users/comment/${comment_id}/delete`,
      { message, user_id }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
