import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { editUserBalance } from "../../services/admin/apiUsers";
import { BalanceItem } from "../../types/userTypes";

interface Props {
  balance: BalanceItem;
  isDisabled: boolean;
  id: number;
  handleUserBalanceUpdated: () => void;
}

const UserBalanceItem: React.FC<Props> = ({
  balance,
  isDisabled,
  id,
  handleUserBalanceUpdated,
}) => {
  const { t } = useTranslation();

  const [inputValue, setValue] = useState<string>("");
  const [isFetching, setFetching] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>("");
  useEffect(() => {
    setValue(balance.amount.toString());
    // eslint-disable-next-line
  }, [balance]);

  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    const result = await editUserBalance(
      balance.type,
      parseFloat(inputValue),
      id
    );
    if (typeof result === "string") {
      setGlobalError(result as string);
    } else {
      handleUserBalanceUpdated();
    }
    setFetching(false);
  };

  return (
    <>
      <div className="admin-balance-item">
        <div className="admin-balance-info">
          <p>
            {t("Type")}: {balance.type}
          </p>
          <p>
            {t("Currency")}: {balance?.code}
          </p>
        </div>
        <Form.Control
          type="number"
          value={inputValue}
          disabled={isDisabled || isFetching}
          onChange={(e): void => {
            setValue(e.target.value);
          }}
        />
        <Button
          variant="primary"
          onClick={(): void => {
            handleSubmit();
          }}
          disabled={isDisabled || isFetching}
        >
          {t("Confirm")}
        </Button>
      </div>
      {globalError && <div className="error-msg">{globalError}</div>}
    </>
  );
};

export default UserBalanceItem;
