import React from "react";
import { useTranslation } from "react-i18next";

import closeIcon from "../../images/close-modal-icon.svg";
import errorIcon from "../../images/error-icon.svg";

interface Props {
  closeModalHandler: () => void;
  confirmModalHandler: () => void;
  mainTxt: string;
  btnTxt: string;
}

const ErrorModal: React.FC<Props> = ({
  closeModalHandler,
  confirmModalHandler,
  mainTxt,
  btnTxt,
}) => {
  const { t } = useTranslation();

  return (
    <div className="error-modal-wrap">
      <div className="overlay" />
      <div className="error-modal">
        <div className="error-modal-title">
          <img src={errorIcon} alt="icon" />
        </div>
        <div className="close-btn" onClick={closeModalHandler}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="error-modal-content">
          <p>{t("Attention!")}</p>
          <p>{t(mainTxt)}</p>
        </div>
        <button
          className="main-btn"
          type="button"
          onClick={(): void => {
            confirmModalHandler();
          }}
        >
          {btnTxt}
        </button>
      </div>
    </div>
  );
};

export default ErrorModal;
