import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PromoCodeData } from "../../../types/promoTypes";

import successIcon from "../../../images/ok-icon.svg";
import closeIcon from "../../../images/close-modal-icon.svg";
import exclamationMark from "../../../images/exclamation-mark.svg";
import questionMark from "../../../images/question-mark.svg";
import MainButton from "../../UI/MainButton/MainButton";
import styles from "./PromoCodeModal.module.scss";

interface Props {
  showPromocodeStatusModal: () => void;
  handleDeactivatePromo: (code: string | undefined) => void;
  getPromocodesData: () => Promise<void>;
  showPromoStatusAlreadyUsed: boolean;
  showPromoStatusReject: boolean;
  showPromoStatusActivated: boolean;
  showPromoStatusInactive: boolean;
  showPromoStatusDeactivate: boolean;
  promocodeData: PromoCodeData[] | null;
}

const PromocodeInfoModal: React.FC<Props> = ({
  getPromocodesData,
  showPromocodeStatusModal,
  handleDeactivatePromo,
  showPromoStatusAlreadyUsed,
  showPromoStatusReject,
  showPromoStatusActivated,
  showPromoStatusInactive,
  showPromoStatusDeactivate,
  promocodeData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getPromoData = async (): Promise<void> => {
    await getPromocodesData();
  };
  return (
    <div className="promocode-modal-wrap">
      {showPromoStatusActivated && (
        <div className="promocode-modal">
          <div className="promocode-modal-title">
            <div className="close-btn" onClick={showPromocodeStatusModal}>
              <img src={closeIcon} alt="successIcon" />
            </div>
          </div>
          <div className="promocode-modal-main">
            <div className={styles.promocode_modal_content}>
              <img src={successIcon} alt="exclamation-mark" />
              <h5>{t("Promocode activated")}</h5>
              <div className={styles.promocode_status_btn}>
                <MainButton
                  className={styles.promocode_btn}
                  color="primary"
                  variant="contained"
                  onClick={(): void => {
                    navigate("/");
                  }}
                  title={t("Home page")}
                />
                <MainButton
                  className={styles.promocode_btn}
                  color="secondary"
                  variant="outlined"
                  onClick={(): void => {
                    getPromoData();
                    showPromocodeStatusModal();
                  }}
                  title={t("Go back to promo")}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showPromoStatusInactive && (
        <div className="promocode-modal">
          <div className="promocode-modal-title">
            <div className="close-btn" onClick={showPromocodeStatusModal}>
              <img src={closeIcon} alt="successIcon" />
            </div>
          </div>
          <div className="promocode-modal-main">
            <div className={styles.promocode_modal_content}>
              <img src={exclamationMark} alt="exclamation-mark" />
              <h5>{t("Promo No longer valid")}</h5>
              <div className={styles.promocode_status_btn}>
                <MainButton
                  className={styles.promocode_btn}
                  color="secondary"
                  variant="outlined"
                  onClick={(): void => {
                    getPromoData();
                    showPromocodeStatusModal();
                  }}
                  title={t("Go back to promo")}
                />
                <MainButton
                  className={styles.promocode_btn}
                  color="primary"
                  variant="contained"
                  onClick={(): void => {
                    navigate("/");
                  }}
                  title={t("Home page")}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showPromoStatusReject && (
        <div className="promocode-modal">
          <div className="promocode-modal-title">
            <div className="close-btn" onClick={showPromocodeStatusModal}>
              <img src={closeIcon} alt="successIcon" />
            </div>
          </div>
          <div className="promocode-modal-main">
            <div className={styles.promocode_modal_content}>
              <img src={exclamationMark} alt="exclamation-mark" />
              <h5>{t("Promocode activate check")}</h5>
            </div>
          </div>
        </div>
      )}
      {showPromoStatusAlreadyUsed && (
        <div className="promocode-modal">
          <div className="promocode-modal-title">
            <div className="close-btn" onClick={showPromocodeStatusModal}>
              <img src={closeIcon} alt="successIcon" />
            </div>
          </div>
          <div className="promocode-modal-main">
            <div className={styles.promocode_modal_content}>
              <img src={exclamationMark} alt="exclamation-mark" />
              <h5>{t("Already activated promo")}</h5>
            </div>
          </div>
        </div>
      )}
      {showPromoStatusDeactivate && (
        <div className="promocode-modal">
          <div className="promocode-modal-title">
            <div className="close-btn" onClick={showPromocodeStatusModal}>
              <img src={closeIcon} alt="successIcon" />
            </div>
          </div>
          <div className="promocode-modal-main">
            <div className={styles.promocode_modal_content}>
              <img src={questionMark} alt="exclamation-mark" />
              <h5>{t("Deactivate promocode")}</h5>
              <div className={styles.promocode_deactivate_btn}>
                <MainButton
                  className={styles.promocode_btn}
                  onClick={showPromocodeStatusModal}
                  title={t("No")}
                  color="secondary"
                  variant="outlined"
                />
                <MainButton
                  className={styles.promocode_btn}
                  color="primary"
                  variant="contained"
                  onClick={(): void => {
                    if (promocodeData && promocodeData.length > 0) {
                      handleDeactivatePromo(promocodeData[0].promocode.code);
                    }
                  }}
                  title={t("Yes")}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromocodeInfoModal;
