import ApiClient from "./ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../types/requestTypes";
import { TournamentItem } from "../types/tournamentTypes";

export const getAllTornaments = async (
  page: number
): Promise<{ data: TournamentItem[] } | string> => {
  try {
    const result = await ApiClient.getPublic<{ data: TournamentItem[] }>(
      `/tournaments?page=${page}&per_page=5`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getAllTornamentsLogin = async (
  page: number
): Promise<{ data: TournamentItem[] } | string> => {
  try {
    const result = await ApiClient.get<{ data: TournamentItem[] }>(
      `/tournaments/me?page=${page}&per_page=5`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getRewardsList = async (
  id: string
): Promise<
  { data: { place: number; points: number; reward: number } } | string
> => {
  try {
    const result = await ApiClient.get<{
      data: { place: number; points: number; reward: number };
    }>(`/tournaments/me/${id}/reward`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getActiveTornament = async (
  isLoged?: boolean
): Promise<{ data: TournamentItem[] } | string> => {
  try {
    const result = isLoged
      ? await ApiClient.get<{ data: TournamentItem[] }>("/tournaments/latest")
      : await ApiClient.getPublic<{ data: TournamentItem[] }>(
          "/tournaments/latest"
        );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getTornamentInfo = async (
  id: string
): Promise<{ tournament: TournamentItem } | string> => {
  try {
    const result = await ApiClient.get<{ tournament: TournamentItem }>(
      `/tournaments/show/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const startTornament = async (
  id: string
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/tournaments/me/${id}/attach`,
      {}
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const collectTornamentReward = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/tournaments/me/${id}/collect`,
      {}
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getGameTornamentInfo = async (
  id: string
): Promise<{ tournament: TournamentItem } | string> => {
  try {
    const result = await ApiClient.get<{ tournament: TournamentItem }>(
      `/tournaments/for-game/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
