const exceptionBonusesGames = [
  "10 001 Nights",
  "10 Burning Heart",
  "100 Burning Hot",
  "100 Cats",
  "100 Super Hot",
  "1429 Uncharted Seas",
  "2 Dragons",
  "20 Burning Hot",
  "20 Dazzling Hot",
  "20 Diamonds",
  "20 Hot Blast",
  "20 Joker Reels",
  "20 Super Hot",
  "27 Wins",
  "3 Charms Crush",
  "3 Kingdoms - Battle of Red Cliffs",
  "3 Secret Cities",
  "30 Spicy Fruits",
  "4 of a Kind Bonus Poker",
  "40 Almighty Ramses II",
  "40 Burning Hot 6 Reels",
  "40 Lucky King",
  "40 Mega Clover",
  "40 Super Hot",
  "40 Ultra Respin",
  "5 Burning Heart",
  "5 Dazzling Hot",
  "5 Families",
  "5 Great Star",
  "5 Juggle Fruits",
  "5 Lions Gold",
  "5 Star Knockout",
  "50 Horses",
  "7 Monkeys",
  "8 Golden Skulls of the Holly Roger",
  "81 Wins",
  "88 Bingo 88",
  "888 Dragons",
  "9 Lions",
  "Across the Universe",
  "Action Money",
  "Adventure Palace",
  "Adventures of Doubloon Island",
  "African Quest",
  "Age of Troy",
  "Agent Valkyrie",
  "Alchemy Ways",
  "Alchymedes",
  "Alexander The Great World Conqueror",
  "Alkemor's Elements",
  "Aloha Party",
  "Amazing Amazonia",
  "Amber Sterling's Mystic Shrine",
  "Ancient Dynasty",
  "Ancient Eclipse",
  "Ancient Egypt",
  "Andar Nights",
  "Arcade",
  "Arcade Bomb",
  "Astro Legends: Lyra and Erion",
  "Atlantean Gigarise",
  "Atlantis",
  "Avalon",
  "Aztec Blaze",
  "Aztec Emerald",
  "Aztec Glory",
  "Aztec Gold Mines",
  "Aztec Spins",
  "Baccarat",
  "Back To Venus",
  "Baker's Treat",
  "Baron Samedi",
  "Bastet and Cats",
  "Beautiful Bones",
  "Beriched",
  "Big Bass Bonanza",
  "Bigger Bass Bonanza",
  "Bikini Party",
  "Blood Suckers",
  "Blood Suckers II",
  "Blue Heart",
  "Bomb Runner",
  "Bombuster",
  "Bompers",
  "Bonanza",
  "Book of 99",
  "Book of Dead",
  "Book of Magic",
  "Book of Oz",
  "Book of Shadows",
  "Book of Sun Choice",
  "Bookie of Odds",
  "Brave Cat",
  "Break da Bank Again",
  "Bronco Spirit",
  "Buffalo King Megaways",
  "Burning Hot",
  "Burning Hot 6 Reels",
  "Caishen Gold: Dragon Awakes",
  "Candy Dreams",
  "Candy Tower",
  "Caramel Hot",
  "Cash Elevator",
  "Cash Ultimate",
  "Casino Barbut",
  "Casino Mania",
  "Cats Royal",
  "Cauldron",
  "Cazino Cosmos",
  "Cazino Zeppelin",
  "Champions Of Olympus",
  "Champions of Rome",
  "Chicken Drop",
  "Christmas Big Bass Bonanza",
  "Circus Brilliant",
  "Codex Of Fortune",
  "Coin Quest",
  "Coin Vault",
  "Congo Cash",
  "Coral Island",
  "Cowboys Gold",
  "Crazy Bugs II",
  "Crown of Valor",
  "Crypts of Fortune",
  "Crystal Cavern",
  "Crystal Caverns Megaways",
  "Crystal Land",
  "Dark Queen",
  "Dark Vortex",
  "Dawn of Egypt",
  "Dawn Of Olympus",
  "Dead or Alive 2",
  "Dead or Alive 2 Feature Buy",
  "Deep Descent",
  "Deuces Wild",
  "Diamond Blitz",
  "Dice High",
  "Divine Fortune Megaways",
  "Double Dragons",
  "Dragon Dance",
  "Dragon Hot",
  "Dragon Kingdom - Eyes of Fire",
  "Dragon Reborn",
  "Dragon Reels",
  "Dragon Ship",
  "Dwarf Mine",
  "Dwarfs Gone Wild",
  "Dynamite Riches",
  "Dynamite Riches Megaways",
  "Eggomatic",
  "Egypt King",
  "Egypt Megaways",
  "Egypt Sky",
  "Empire Shields",
  "European Roulette",
  "Evil Elf",
  "Exploding Fruits",
  "Extra Chilli",
  "Extra Stars",
  "Extremely Hot",
  "Eye of the Kraken",
  "Fa Fa Babies",
  "Fast Money",
  "Fire Joker",
  "Fire Toad",
  "Flaming Hot",
  "Flaming Hot Extreme",
  "Flower Fortunes Supreme",
  "Flowers",
  "Fluffy Rangers",
  "Fly!",
  "Football",
  "Football Glory",
  "Football Star",
  "Forest Band",
  "Forest Tale",
  "Forgotten Fable",
  "Fortune Circus",
  "Fortune Rush",
  "Fortune Spells",
  "Foxy Wild Heart",
  "Frog Story",
  "Frost Queen Jackpots",
  "Fruit Boost",
  "Fruit Loop",
  "Fruit Mania Deluxe",
  "Fruit Party",
  "Fruit Rush",
  "Fruit Warp",
  "Fruit Zen",
  "Fruitbat Crazy",
  "Fruits Kingdom",
  "Gaelic Gold",
  "Game of Luck",
  "Gates of Olympus",
  "Gem Saviour",
  "Gemix 2",
  "Gems Gone Wild Power Reels",
  "Gems Odyssey",
  "Genius of Leonardo",
  "Gerard's Gambit",
  "Ghost Pirates",
  "Gigantoonz",
  "God of Fire",
  "Gods Of Troy",
  "Gold Digger: Mines",
  "Gold Dust",
  "Gold Rush",
  "Gold Train",
  "Golden Beauty",
  "Golden Legend",
  "Golden Stallion",
  "Golden Tsar",
  "Gonzo's Quest",
  "Gonzo's Quest MegaWays",
  "Grace of Cleopatra",
  "Great 27",
  "Great Adventure",
  "Great Empire",
  "Guardians of Inari",
  "Halloween",
  "Halloween Jack",
  "Happiest Christmas Tree",
  "Happy Halloween",
  "HarleCoin",
  "Harlequin Carnival",
  "Heads and Tails",
  "Hellcatraz",
  "Hi-Lo Switch",
  "High Society",
  "Hold'em Poker 2",
  "Holmes and the Stolen Stones",
  "Hood vs Wolf",
  "Hope Diamond",
  "Hot & Cash",
  "Hot Fiesta",
  "Hot To Burn Extreme",
  "Hotline",
  "Hugo 2",
  "Hustling",
  "Ice Valley",
  "Immortal Monkey King",
  "Immortal Romance",
  "Immortal Romance Video Bingo",
  "Imperial Wars",
  "Inca Gold II",
  "Italia 3X3",
  "Jack and the beanstalk",
  "Jack Hammer",
  "Jackpot 6000",
  "Jackpot Raiders",
  "Jade Butterfly",
  "Jellyfish Flow",
  "Jellyfish Flow Ultra",
  "Jingle Spin",
  "Jogo do Bicho",
  "John Hunter and the Tomb of the Scarab Queen",
  "Johnan Legendarian",
  "Joker Gems",
  "Joker Poker",
  "Joker's Jewels",
  "Jokerizer",
  "Jolly Treasures",
  "Jumbo Joker",
  "Jungle Adventure",
  "Jungle Gorilla",
  "Jungle Spirit: Call of the Wild",
  "Jurassic Park",
  "Jurassic Park: Gold",
  "Kangaroo Land",
  "Kashmir Gold",
  "Keno Universe",
  "King of the Jungle",
  "Knockout Football",
  "Kochbuch",
  "Koi Princess",
  "Lava Gold",
  "Legacy of Dead",
  "Legacy of Egypt",
  "Legend Of The Nile",
  "Legend of the Sword",
  "Legendary Rome",
  "Legion Hot 1",
  "Like a Diamond",
  "Links of Ra",
  "Lion's Hoard",
  "London Hunter",
  "Lucky & Wild",
  "Lucky Buzz",
  "Lucky Fridays",
  "Lucky Hot",
  "Lucky Mermaid",
  "Luxor Relics",
  "Macau High Roller",
  "Magellan",
  "Magic Oak",
  "Magical Reels",
  "Magnify Man",
  "Mahjong 88",
  "Majestic Forest",
  "Mammoth Gold Megaways",
  "Marching Legions",
  "Martian Miner Infinity Reels",
  "Marvelous Furlongs",
  "Master Joker",
  "Match Day",
  "Mayan Spirit",
  "Mega Masks",
  "Might of Zeus",
  "Miner Donkey Trouble",
  "Money Moose",
  "Money Pipe",
  "Money Train",
  "Moon Princess",
  "More Dice & Roll",
  "More Like a Diamond",
  "More Lucky & Wild",
  "MULTIFRUIT 81",
  "Multiplier Riches",
  "Mustang Gold",
  "Mystery Joker 6000",
  "Mythical Treasure",
  "Night Of The Living Tales",
  "Night Roller",
  "Ninja",
  "Ocean Rush",
  "Of Sabers and Monsters",
  "Oil Company II",
  "Olympus Glory",
  "Panda Warrior",
  "Pearl Diver",
  "Pearls of India",
  "Penguin City",
  "Penguin Style",
  "Pets Go Wild",
  "Phantasmic Fortunes",
  "Pimped",
  "Pinocchio",
  "Pirates Quest",
  "Planet Rocks",
  "Playboy",
  "Plinko",
  "Primate King",
  "Prohibition",
  "Pumpkin Patch",
  "Punk Rocker",
  "Queen of Gold",
  "Queen of Rio",
  "Quick Cash Fruits",
  "Rage to Riches",
  "Rainbow Luck",
  "Rainbow Queen",
  "Reactoonz",
  "Red Horde",
  "Red Hot Volcano",
  "Redrose Sanctuary",
  "Reel Gems",
  "Reel Heist",
  "Reel Keeper",
  "Reel Rush",
  "Reel Rush 2",
  "Reel Steal",
  "Release the Kraken",
  "Reptizillions Power Reels",
  "Retro Cabaret",
  "Retro Reels",
  "Retro Style",
  "Return to Paris",
  "Rich Wilde and the Shield of Athena",
  "Rich World",
  "Riches of RA",
  "Rise of Merlin",
  "Rise of Olympus",
  "Rise of RA",
  "Rise of the Genie",
  "Robin Hood: Shifting Riches",
  "Rocket Stars",
  "Romance V",
  "Rome: The Golden Age",
  "Roo Riches",
  "Route of Mexico",
  "Royal Masquerade",
  "Royal Secrets",
  "Santa Pets",
  "Santa's Stack",
  "Santa's Village",
  "Scrooge",
  "Sea Hunter",
  "Sea Raiders",
  "Season sisters",
  "Secret of the Stones",
  "Secrets of Alchemy",
  "Secrets Of The Sorcerer",
  "Serengeti Kings",
  "Seven Books Unlimited",
  "Seven Seven",
  "Shadow Society",
  "Sheriff Colt",
  "Shining Crown",
  "Sisters of Oz Jackpots",
  "Skulls Up!",
  "Slot Vegas",
  "Snakes and Ladders Megadice",
  "Solar King",
  "Solar Queen",
  "Solar Temple",
  "Spanish Passion",
  "Spin Town",
  "Spina Colada",
  "Spinfinity Man",
  "Squealin' Riches",
  "Stardust",
  "Starfang",
  "Starlight Princess",
  "Steam Tower",
  "Street Fighter II: The World Warrior Slot",
  "Sugar Pop",
  "Sugar Pop 2",
  "Summer Bliss",
  "Summer Splash",
  "Sumo Spins",
  "Super 15 Stars",
  "Super 20",
  "Super 7s",
  "Super Striker",
  "Supreme Hot",
  "Sweet Alchemy",
  "Sweet Cheese",
  "Sweet Powernudge",
  "Taco Brothers Derailed",
  "Take Olympus",
  "Take Santa's Shop",
  "Take The Bank",
  "Take The Kingdom",
  "Talismans of Fortune",
  "Tens or Better",
  "Thai Blossoms",
  "The Angler",
  "The Armory",
  "The Big Journey",
  "The Bounty",
  "The Dark Joker Rizes",
  "The Dog House",
  "The Dog House Megaways",
  "The Dog House Multihold",
  "The Explorers",
  "The Great Egypt",
  "The Hand of Midas",
  "The Hot Offer",
  "The Mafiosi",
  "The Royal Family",
  "The Secrets of London",
  "The Story of Alexander",
  "The White Wolf",
  "The Wisecracker Lightning",
  "The Wish Master",
  "Three Card Rummy",
  "Three Musketeers",
  "Thunderstruck II",
  "Thunderstruck Wild Lightning",
  "TNT Tumble",
  "Tower Of Fortuna",
  "Tower Quest",
  "Towering Pays Excalibur",
  "Towering Pays Valhalla",
  "Tree of Riches",
  "Triple Juicy Drops",
  "Tut's Twister",
  "Ultimate Hot",
  "Ultra Burn",
  "Ultra Hold and Spin",
  "Valley of the Gods",
  "Valley of the Gods 2",
  "Vampire Night",
  "Vampire: The Masquerade - Las Vegas",
  "Versailles Gold",
  "Viking Queen",
  "Vikings Go Berzerk",
  "Vikings go to Hell",
  "Vikings Go To Valhalla",
  "Village People Macho Moves",
  "Virtual Roulette",
  "Volcano Wealth",
  "WBC Ring of Riches",
  "Well of Wishes",
  "White Rabbit",
  "Who wants to be a Millionaire",
  "Wicked Circus",
  "Wild Crowns",
  "Wild Expedition",
  "Wild Hot Chilli Reels",
  "Wild o'Clock",
  "Wild Orient",
  "Wild Spartans",
  "Wild Texas",
  "Wild Triads",
  "Wild West Gold",
  "Wild Wild Riches Megaways",
  "Wilderland",
  "Wildlife Riches",
  "Wilds of Wall Street",
  "Win Escalator",
  "Winfall in Paradise",
  "Wolf Gold",
  "Wolf Hiding",
  "Wolf Hiding Bonus Buy",
  "Wolf Hunters",
  "Wonder Tree",
  "Wonderheart",
  "Wukong",
  "Xmas 3X3",
  "Zaida's Fortune",
  "Zillard King",
  "Zodiac Wheel",
  "Zombie Hoard",
  "Zombies at the Door",
];

export default exceptionBonusesGames;
