import React, { useEffect, useState } from "react";
import { Table, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateDayApi } from "../../../helpers/getCorrectDate";

import { getGamesReport } from "../../../services/admin/apiReporting";
import { GameReportItem } from "../../../types/gameTypes";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";

const Games: React.FC = () => {
  // Games data
  const [games, setGames] = useState<GameReportItem[]>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  //   Filters
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(2);
  const [vendor, setVendor] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [title, setTitle] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const { t } = useTranslation();

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  //   Games logic
  const loadGames = async (
    page: number,
    searchValue: string
  ): Promise<void> => {
    setFetching(true);
    const result = await getGamesReport(page + 1, searchValue || "");
    if (typeof result !== "string") {
      setGames(result.data as GameReportItem[]);
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!games) {
      setSearch(`&currency_id=${selectedCurrency}`);
      loadGames(0, `&currency_id=${selectedCurrency}`);
    }
    // eslint-disable-next-line
  }, [games]);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadGames(e, search);
  };

  const handleClearSearch = (): void => {
    setStartDate(undefined);
    setEndDate(undefined);
    setSelectedCurrency(2);
    setTitle("");
    setVendor("");
    setActivePage(0);
    setSearch(`&currency_id=${selectedCurrency}`);
    loadGames(0, `&currency_id=${selectedCurrency}`);
  };
  const handleSearch = (): void => {
    const searchValue = `${
      startDate ? `&start_date=${formatDateDayApi(startDate)}` : ""
    }${endDate ? `&end_date=${formatDateDayApi(endDate)}` : ""}${
      selectedCurrency ? `&currency_id=${selectedCurrency}` : ""
    }${title ? `&game_title=${title}` : ""}${
      vendor ? `&vendor=${vendor}` : ""
    }`;
    setActivePage(0);
    setSearch(searchValue);
    loadGames(activePage, searchValue);
  };

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>{t("Filters")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Start date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setStartDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={startDate}
                      dropdownMode="select"
                      placeholderText={t("Start date") || "Start date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("End date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setEndDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={endDate}
                      dropdownMode="select"
                      placeholderText={t("End date") || "End date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Game name")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Game name") || "Game name"}
                      value={title}
                      onChange={(e): void => {
                        setTitle(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Game vendor")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Game vendor") || "Game vendor"}
                      value={vendor}
                      onChange={(e): void => {
                        setVendor(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Currency")}</Form.Label>
                    <Form.Select
                      aria-label={t("Currency") || "Currency"}
                      value={selectedCurrency}
                      onChange={(e): void => {
                        setSelectedCurrency(
                          parseInt(e.target.value as string, 10)
                        );
                      }}
                      data-testid="bets-reporting-currency"
                    >
                      {currencyList &&
                        currencyList.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.code}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div className="admin-search-btns">
                    <Button
                      variant="danger"
                      onClick={handleClearSearch}
                      disabled={isFetching}
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isFetching}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {games && !isFetching && (
        <Table
          striped
          bordered
          className="mb-5"
          responsive
          data-testid="active-users-table"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("Title")}</th>
              <th>{t("Vendor")}</th>
              <th>{t("Currency")}</th>
              <th>{t("Bets amount")}</th>
              <th>{t("Wins amount")}</th>
              <th>RTP</th>
              <th>GGR</th>
              <th>{t("Users count")}</th>
            </tr>
          </thead>
          <tbody>
            {games.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.title}</td>
                <td>{el.producer}</td>
                <td>{el.currency}</td>
                <td>{el.rounds.bets_sum}</td>
                <td>{el.rounds.wins_sum}</td>
                <td>{el.payout}</td>
                <td>{el.rounds.gross_gaming_revenue}</td>
                <td>{el.rounds.users_count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Games;
