import ApiClient from "../ApiClient";
import {
  RequestError,
  AllGamesListResponse,
  DeafaultSuccesResponse,
} from "../../types/requestTypes";

export const updateLobbyCondition = async (
  id: number,
  lobby: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.put<DeafaultSuccesResponse>(
      `/games/update/${id}`,
      JSON.stringify({ lobby })
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const changeGameStatus = async (
  id: number,
  is_active: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.put<DeafaultSuccesResponse>(
      `/games/update/${id}`,
      JSON.stringify({ is_active })
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getAllGames = async (
  page: number,
  queryString?: string,
  sortString?: string
): Promise<AllGamesListResponse | string> => {
  try {
    const result = await ApiClient.get<AllGamesListResponse>(
      `/games?page=${page}&per_page=20${queryString || ""}${sortString || ""}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
