import React from "react";
import classNames from "classnames";
import styles from "./MainButtonStyle.module.scss";

interface MainButtonProps {
  title: string;
  onClick: () => void;
  variant: "contained" | "outlined";
  color: "primary" | "secondary" | "info";
  className?: string;
  disabled?: boolean;
}

const MainButton: React.FC<MainButtonProps> = ({
  title,
  onClick,
  className,
  disabled = false,
  variant,
  color,
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNames(styles.main_button_styles, className, {
        [styles.bg_primary]: color === "primary",
        [styles.bg_secondary]: color === "secondary",
        [styles.bg_info]: color === "info",
        [styles.contained_button]: variant === "contained",
        [styles.outline_button]: variant === "outlined",
      })}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {title}
    </button>
  );
};

export default MainButton;
