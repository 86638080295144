import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CustomDatePickerStyles.module.scss";
import CustomSelect, { Option } from "../CustomSelect/CustomSelect";

interface Props {
  handleDateChange: (e: string) => void;
}

const monthsObject: { name: string; days: number; numberValue: string }[] = [
  {
    name: "January",
    days: 31,
    numberValue: "01",
  },
  {
    name: "February",
    days: 29,
    numberValue: "02",
  },
  {
    name: "March",
    days: 31,
    numberValue: "03",
  },
  {
    name: "April",
    days: 30,
    numberValue: "04",
  },
  {
    name: "May",
    days: 31,
    numberValue: "05",
  },
  {
    name: "June",
    days: 30,
    numberValue: "06",
  },
  {
    name: "July",
    days: 31,
    numberValue: "07",
  },
  {
    name: "August",
    days: 31,
    numberValue: "08",
  },
  {
    name: "September",
    days: 30,
    numberValue: "09",
  },
  {
    name: "October",
    days: 31,
    numberValue: "10",
  },
  {
    name: "November",
    days: 30,
    numberValue: "11",
  },
  {
    name: "December",
    days: 31,
    numberValue: "12",
  },
];

const CustomDatePicker: React.FC<Props> = ({ handleDateChange }) => {
  const [month, setMonth] = useState<Option | undefined>(undefined);
  const [day, setDay] = useState<Option>();
  const [year, setYear] = useState<Option>();

  const { t } = useTranslation();

  useEffect(() => {
    if (month && day && year) {
      handleDateChange(
        `${year.value}-${month.value}-${
          parseInt(day.value, 10) < 10 ? `${day.value}` : day.value
        }`
      );
    }
  }, [month, day, year, handleDateChange]);

  const renderMonth = (): Option[] => {
    const result = monthsObject.map((item) => {
      return {
        value: item.numberValue,
        title: item.name,
      };
    });
    return result;
  };

  const renderDays = (): Option[] => {
    const result = [];
    const valueDaysInMonth = monthsObject.find((itemMonth) => {
      return month?.title === itemMonth.name;
    });
    if (month && valueDaysInMonth) {
      for (let index = 0; index < valueDaysInMonth.days; index++) {
        result.push({
          value: `${index < 9 ? `0${index + 1}` : index + 1}`,
          title: `${index < 9 ? `0${index + 1}` : index + 1}`,
        });
      }
    } else {
      for (let index = 0; index < 31; index++) {
        result.push({
          value: `${index < 9 ? `0${index + 1}` : index + 1}`,
          title: `${index < 9 ? `0${index + 1}` : index + 1}`,
        });
      }
    }
    return result;
  };
  const renderArrayOfYears = (): Option[] => {
    const max = new Date().getFullYear();
    const min = max - 90;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push({
        value: i.toString(),
        title: i.toString(),
      });
    }
    return years;
  };

  useEffect(() => {
    if (month) {
      const result = monthsObject.filter((itemMonth) => {
        return month?.title === itemMonth.name;
      });
      if (day?.value && parseInt(day.value, 10) > result[0].days) {
        setDay(undefined);
      }
    }
  }, [day, month]);

  return (
    <div className={styles.custom_date_picker}>
      <CustomSelect
        placeholder={t("Day")}
        items={renderDays()}
        handleSelect={(date): void => {
          setDay(date);
        }}
        selectedItem={day}
      />
      <CustomSelect
        placeholder={t("Month")}
        items={renderMonth()}
        handleSelect={(date): void => {
          setMonth(date);
        }}
        selectedItem={month}
      />
      <CustomSelect
        placeholder={t("Year")}
        items={renderArrayOfYears()}
        handleSelect={(date): void => {
          setYear(date);
        }}
        selectedItem={year}
      />
    </div>
  );
};

export default CustomDatePicker;
