import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./WelcomeBonusSlideStyles.module.scss";
import slideBannerWelcomeBonus from "../../../../images/welcomeBonus/img-desktop-welcome-bonus-slide.png";
import slideBannerWelcomeBonusMobile from "../../../../images/welcomeBonus/img-mobile-slide-welcome-bonus.png";
import MainButton from "../../../UI/MainButton/MainButton";
import getValueWinWelcomeBonus from "../../../../helpers/getValueWinWelcomeBonus";
import { RootState } from "../../../../store/store";

interface WelcomeBonusSlideProps {
  typeCurrency?: string;
  isLogged: boolean | undefined;
}

const WelcomeBonusSlide: React.FC<WelcomeBonusSlideProps> = ({
  typeCurrency,
  isLogged,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  return (
    <div
      className={`${styles.wrapper} ${
        localeActive?.code === "fa" ? styles.rtl : ""
      }`}
    >
      <img src={slideBannerWelcomeBonus} alt="" className={styles.desktop} />
      <img
        src={slideBannerWelcomeBonusMobile}
        alt=""
        className={styles.mobile}
      />
      <div className={styles.bg_mobile} />
      <div className={styles.content}>
        <div className={styles.text_title}>Welcome Bonus</div>
        <div className={styles.text_winning}>
          {t(getValueWinWelcomeBonus(typeCurrency))}
        </div>
        <span className={styles.text_free_spin}>
          <div className={styles.text_count}>+50 FS</div>
          <div className={styles.text_description}>{t("in Fortune Craft")}</div>
        </span>
        <MainButton
          title={t("See details")}
          variant="contained"
          color="info"
          onClick={(): void => {
            if (isLogged) {
              navigate("/welcome-bonus");
            } else {
              navigate("/register");
            }
          }}
          className={styles.btn}
        />
      </div>
    </div>
  );
};

export default WelcomeBonusSlide;
