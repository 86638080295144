import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./TextFieldStyles.module.scss";
import type { RootState } from "../../../store/store";

interface TextFieldProps {
  placeholder?: string;
  value: string;
  onChange?: (value: string) => void;
  isReadonly?: boolean;
  isValidate?: boolean;
  helperText?: string;
  className?: string;
  type?: string;
}

const TextField: React.FC<TextFieldProps> = ({
  value,
  onChange,
  placeholder = "",
  isReadonly = false,
  isValidate = false,
  helperText = "",
  className,
  type = "text",
}) => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.value);
    }
  };
  return (
    <div
      className={`${styles.input_wrap} ${
        localeActive?.code === "fa" ? styles.rtl : ""
      }`}
    >
      <input
        onChange={onChangeHandler}
        value={value}
        className={classNames(styles.input, className)}
        type={type}
        placeholder={t(placeholder) || ""}
        readOnly={isReadonly}
      />
      {isValidate && <div className={styles.error}>{t(helperText)}</div>}
    </div>
  );
};

export default TextField;
