import ApiClient from "../ApiClient";
import { RequestError } from "../../types/requestTypes";
import { DepositItem, WithdrawalItemNew } from "../../types/paymentTypes";

export const getOrdersAdmin = async (
  filters: string,
  page: number
): Promise<
  | {
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }>(`/payments/deposits/bettransfer?page=${page}${filters}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getOrdersCoinsAdmin = async (
  filters: string,
  page: number
): Promise<
  | {
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }>(`/payments/deposits/coinspaid?page=${page}${filters}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getWithdrawalsAdmin = async (
  filters: string,
  page: number
): Promise<
  | {
      data: WithdrawalItemNew[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: WithdrawalItemNew[];
      meta: {
        last_page: number;
      };
    }>(`/payments/withdrawals/bettransfer?${filters}&page=${page}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const getAiforyAdmin = async (
  filters: string,
  page: number
): Promise<
  | {
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }>(`/payments/deposits/aifory?page=${page}${filters}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const getMoneygoAdmin = async (
  filters: string,
  page: number
): Promise<
  | {
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }>(`/payments/deposits/moneygo?page=${page}${filters}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const getStreampayAdmin = async (
  filters: string,
  page: number
): Promise<
  | {
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }>(`/payments/deposits/streampay?page=${page}${filters}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const getKaptahpayAdmin = async (
  filters: string,
  page: number
): Promise<
  | {
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: DepositItem[];
      meta: {
        last_page: number;
      };
    }>(`/payments/deposits/kaptahpay?page=${page}${filters}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const getOrderStatuses = async (): Promise<
  | {
      list: string[];
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      list: string[];
    }>(`/payments/rub/deposit/statuses`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getWithdrawalStatuses = async (): Promise<
  | {
      list: { id: number; name: string }[];
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      list: { id: number; name: string }[];
    }>(`/payments/rub/withdrawal/statuses`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const updateOrderStatus = async (
  id: number,
  status: string,
  payment_system: string
): Promise<
  | {
      deposit: DepositItem;
      errorCode: number;
      message: string;
    }
  | string
> => {
  try {
    const result = await ApiClient.put<{
      deposit: DepositItem;
      errorCode: number;
      message: string;
    }>(
      `payments/update-deposit/${id}/bettransfer`,
      JSON.stringify({ status, payment_system })
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const updateWithdrawalStatus = async (
  id: number,
  status: number,
  payment_system: string,
  comment?: string
): Promise<
  | {
      errorCode: number;
    }
  | string
> => {
  try {
    await ApiClient.put<{
      errorCode: number;
    }>(
      `/payments/update-withdrawal/${id}/bettransfer`,
      JSON.stringify({ status, comment, payment_system })
    );
    return { errorCode: 0 };
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
