import ApiClient from "./ApiClient";
import { RequestError } from "../types/requestTypes";
import { CurrencyItem } from "../types/currencyTypes";

const getCurrencyList = async (): Promise<
  { data: CurrencyItem[] } | string
> => {
  try {
    const result = await ApiClient.get<{ data: CurrencyItem[] }>(
      "/currencies?per_page=200"
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

// eslint-disable-next-line
export { getCurrencyList };
