import React, { useEffect, useState } from "react";
import { Table, Dropdown, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDateMinutes } from "../../helpers/getCorrectDate";
import { getFreeSpinsList } from "../../services/admin/apiFreeSpins";
import { AdminPanelTabs } from "../../types/adminTypes";
import { FreeSpinItem } from "../../types/gameTypes";
import type { RootState } from "../../store/store";
import { UserRoles } from "../../types/userTypes";

import AddFreeSpinModal from "./modal/AddFreeSpinModal";
import InfoFreeSpinModal from "./modal/InfoFreeSpinModal";
import FreeSpinDeleteModal from "./modal/FreeSpinDeleteModal";

interface Props {
  activeTab: AdminPanelTabs;
}

const FreeSpinsTab: React.FC<Props> = ({ activeTab }) => {
  const [freeSpins, setFreeSpins] = useState<FreeSpinItem[]>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  //   Modals
  const [addModalShow, setAddModalShow] = useState<boolean>(false);
  const [infoModalShow, setInfoModalShow] = useState<boolean>(false);
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<number>(0);
  const [activeName, setActiveName] = useState<string>("");

  const { t } = useTranslation();

  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const loadFreeSpins = async (page: number): Promise<void> => {
    setFetching(true);
    const result = await getFreeSpinsList(page + 1);
    if (typeof result !== "string") {
      setFreeSpins(result.data as FreeSpinItem[]);
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!freeSpins && activeTab === AdminPanelTabs.spins) {
      loadFreeSpins(0);
    }
    // eslint-disable-next-line
  }, [activeTab, freeSpins]);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadFreeSpins(e);
  };

  //   Modals logic
  const toggleAddModal = (): void => {
    setAddModalShow(!addModalShow);
  };
  const handleSpinAdded = (): void => {
    toggleAddModal();
    loadFreeSpins(0);
    setActivePage(0);
  };
  const showInfoModal = (): void => {
    setInfoModalShow(true);
  };
  const hideInfoModal = (): void => {
    setInfoModalShow(false);
    loadFreeSpins(activePage);
    setActiveId(0);
  };
  const showDeleteModal = (): void => {
    setDeleteModalShow(true);
  };
  const hideDeleteModal = (): void => {
    setDeleteModalShow(false);
    setActiveId(0);
    setActiveName("");
  };
  const handleFreeSpinDeleted = (): void => {
    hideDeleteModal();
    loadFreeSpins(0);
    setActivePage(0);
  };

  return (
    <div className="admin-spins-warp admin-tab-warp">
      <h3>{t("Free spins")}</h3>
      <Button
        variant="primary"
        className="mb-3"
        onClick={toggleAddModal}
        disabled={userRole !== UserRoles.admin}
      >
        + {t("Add new free spin")}
      </Button>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {!isFetching && (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("Title")}</th>
              <th>{t("Description")}</th>
              <th>{t("Quantity")}</th>
              <th>{t("Valid until")}</th>
              <th>{t("Currency")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          {freeSpins && (
            <tbody>
              {freeSpins.map((el) => (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{el.title}</td>
                  <td>{el.description}</td>
                  <td>{el.freespins_quantity}</td>
                  <td>
                    <td>{formatDateMinutes(el.valid_until as string)}</td>
                  </td>
                  <td>
                    {el.currency.code}: {el.currency.units}
                  </td>
                  <td>
                    <div className="admin-actions-wrap">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="primary"
                          id="dropdown-basic"
                          disabled={userRole !== UserRoles.admin}
                        >
                          {t("Actions")}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(): void => {
                              setActiveId(el.id as number);
                              showInfoModal();
                            }}
                          >
                            {t("Info")}
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={(): void => {
                              setActiveId(el.id as number);
                              setActiveName(el.title as string);
                              showDeleteModal();
                            }}
                          >
                            {t("Delete")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}
      {totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      {addModalShow && (
        <AddFreeSpinModal
          show={addModalShow}
          handleClose={toggleAddModal}
          handleSpinAdded={handleSpinAdded}
        />
      )}
      {infoModalShow && (
        <InfoFreeSpinModal
          show={infoModalShow}
          handleClose={hideInfoModal}
          id={activeId}
        />
      )}
      {deleteModalShow && (
        <FreeSpinDeleteModal
          show={deleteModalShow}
          handleClose={hideDeleteModal}
          handleFreeSpinDeleted={handleFreeSpinDeleted}
          id={activeId}
          name={activeName}
        />
      )}
    </div>
  );
};

export default FreeSpinsTab;
