/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDetectClickOutside } from "react-detect-click-outside";
import { DebounceInput } from "react-debounce-input";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CategoryGameType,
  CategoryItemType,
  VendorItemType,
} from "../types/gameTypes";
import { getAllGamesList } from "../services/apiGames";

import allIcon from "../images/categories/all-games.svg";
import lastGamesIcon from "../images/categories/last-games.svg";
import newGamesIcon from "../images/categories/new-games.svg";
import categoryLobby from "../images/categories/lobby-new.svg";
import categorySlots from "../images/categories/slots-new.svg";
import categoryLiveGames from "../images/categories/live-games.svg";
import categoryCard from "../images/categories/card-new.svg";
import categoryRoulette from "../images/categories/roulette-new.svg";
import categoryCrashGames from "../images/categories/crash-games.svg";
import categoryCasual from "../images/categories/casual-new.svg";
// import categoryLottery from "../images/categories/lottery-new.svg";
import categoryPoker from "../images/categories/poker-new.svg";
// import categoryVideoPoker from "../images/categories/video_poker-new.svg";
import arrow from "../images/arrow-right-new.svg";
import filterIcon from "../images/filter-icon-new.svg";
import filterArrow from "../images/arrow-down-new.svg";
import seacrhIcon from "../images/search-icon-new.svg";
import smallGrid from "../images/small-grid.svg";
import bigGrid from "../images/big-grid.svg";

import { RootState } from "../store/store";

interface Props {
  categories: CategoryItemType[];
  vendors: VendorItemType[];
  isFetching: boolean;
  activeCategory: CategoryItemType | null;
  activeVendor: VendorItemType | null;
  toggleCategory: (e: CategoryItemType | null) => void;
  toggleVendor: (e: VendorItemType | null) => void;
  handleSearch: (e: string) => void;
  toggleGrid: (e: boolean) => void;
  searchValue: string;
}

const HomeMenu: React.FC<Props> = ({
  categories,
  isFetching,
  activeCategory,
  toggleCategory,
  handleSearch,
  searchValue,
  vendors,
  toggleVendor,
  toggleGrid,
  activeVendor,
}) => {
  const [currentSlide, setCurrуntSlide] = useState<number>(0);
  const [isDropdownShow, setDrodownShow] = useState<boolean>(false);
  const [isSearchShow, setSearchShow] = useState<boolean>(false);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  const icons: { [key in CategoryGameType]: string } = {
    slots: categorySlots,
    live_games: categoryLiveGames,
    card: categoryCard,
    roulette: categoryRoulette,
    crash: categoryCrashGames,
    casual: categoryCasual,
    poker: categoryPoker,
    All: allIcon,
  };
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleSlide = (isNext?: boolean): void => {
    if (isNext) {
      setCurrуntSlide(currentSlide + 1);
    } else {
      setCurrуntSlide(currentSlide - 1);
    }
  };

  const showDropdown = (): void => {
    setDrodownShow(true);
    document.body.classList.add("no-scroll-vendor");
  };
  const hideDropdown = (): void => {
    setDrodownShow(false);
    document.body.classList.remove("no-scroll-vendor");
  };
  const ref = useDetectClickOutside({
    onTriggered: hideDropdown,
  });
  const toggleSearchShow = (): void => {
    if (window.innerWidth > 990) return;
    setSearchShow(!isSearchShow);
  };
  const hideSearch = (): void => {
    if (window.innerWidth > 990) return;
    setSearchShow(false);
  };
  const refSearch = useDetectClickOutside({
    onTriggered: hideSearch,
  });

  const getArbitraryGame = async (): Promise<void> => {
    const response = await getAllGamesList(
      null,
      null,
      null,
      undefined,
      40,
      true,
      undefined
    );
    if (typeof response !== "string") {
      const randomGame =
        response.data[Math.floor(Math.random() * (response.data.length + 1))];
      navigate(`/game/${randomGame.id}`, {
        state: {
          title: randomGame.title,
          description: randomGame.description,
          bg: randomGame.icons.background,
        },
      });
    }
  };

  const renderCategoryName = (name: string): string => {
    if (name === "video_poker") {
      return "Video poker";
    }
    if (name === "virtual_sports") {
      return "Virtual sports";
    }
    return t(
      `${name.charAt(0).toUpperCase() as string}${name.slice(1) as string}`
    );
  };
  const renderCategories = (): JSX.Element[] => {
    const result: JSX.Element[] = [];
    console.log(categories);
    categories.forEach((el) => {
      result.push(
        <div
          key={el.id}
          className={activeCategory === el ? "tab-item active" : "tab-item"}
          style={{ order: el.name === "slots" ? 1 : 2 }}
          onClick={(): void => {
            toggleCategory(el);
          }}
        >
          <img src={icons[el.name]} alt="" />
          {renderCategoryName(el.name)}
        </div>
      );
    });
    return result;
  };
  const renderVendors = (): JSX.Element[] => {
    const result: JSX.Element[] = [];
    vendors.forEach((el) => {
      result.push(
        <div className="vendor-container">
          <img src={el.logo_small} alt="logo" />
          <span
            // style={{ background: `url(${el.logo} no-repeat center);` }}
            key={el.id}
            onClick={(): void => {
              toggleVendor(el);
              hideDropdown();
            }}
            className={activeVendor === el ? "active" : ""}
          >
            {el.name.charAt(0).toUpperCase() as string}
            {el.name.slice(1) as string}
          </span>
        </div>
      );
    });
    return result;
  };

  return (
    <div
      className={
        localeActive?.code === "fa" ? "home-menu-wrap rtl" : "home-menu-wrap"
      }
    >
      <div className="home-menu-tabs">
        <div
          className={
            currentSlide === 0 ? "tab-btn prev hidden" : "tab-btn prev"
          }
          onClick={(): void => {
            toggleSlide();
          }}
        >
          <img src={arrow} alt="" />
        </div>
        <div className="home-menu-tabs--wrap">
          {isFetching && (
            <ThreeDots
              height="15"
              width="30"
              color="#D0174F"
              wrapperStyle={{ justifyContent: "center" }}
              visible
              ariaLabel="circles-with-bar-loading"
            />
          )}
          {!isFetching && (
            <div
              className="home-menu-tabs--box"
              style={
                localeActive?.code === "fa"
                  ? {
                      transform: `translateX(+${
                        window.innerWidth <= 990
                          ? 120 * currentSlide
                          : 170 * currentSlide
                      }px)`,
                    }
                  : {
                      transform: `translateX(-${
                        window.innerWidth <= 990
                          ? 120 * currentSlide
                          : 170 * currentSlide
                      }px)`,
                    }
              }
            >
              <div
                className={
                  activeCategory?.id === 0 ? "tab-item active" : "tab-item"
                }
                onClick={(): void => {
                  navigate("/", {
                    state: { isLive: false },
                  });
                  // toggleCategory({ id: 0, name: CategoryGameType.All });
                }}
              >
                <img src={allIcon} alt="" />
                {t("All Games")}
              </div>
              <div
                className={!activeCategory ? "tab-item active" : "tab-item"}
                onClick={(): void => {
                  toggleCategory(null);
                }}
              >
                <img src={categoryLobby} alt="" />
                {t("Lobby")}
              </div>
              <div
                className={
                  activeCategory?.id === 10 ? "tab-item active" : "tab-item"
                }
                onClick={(): void => {
                  navigate("/", {
                    state: { isLive: true },
                  });
                  // toggleCategory({ id: 10, name: CategoryGameType.live_games });
                }}
              >
                <img src={categoryLiveGames} alt="" />
                {t("Live games")}
              </div>
              {/* <div
                className={!activeCategory ? "tab-item active" : "tab-item"}
                onClick={(): void => {
                  toggleCategory(null, undefined);
                }}
              >
                <img src={newGamesIcon} alt="" />
                {t("New Games")}
              </div> */}
              {renderCategories()}
            </div>
          )}
        </div>
        <div
          className={
            currentSlide > categories.length - 6
              ? "tab-btn next hidden"
              : "tab-btn next"
          }
          onClick={(): void => {
            toggleSlide(true);
          }}
        >
          <img src={arrow} alt="" />
        </div>
      </div>
      <div
        className={
          isSearchShow ? "home-menu-filters search-shown" : "home-menu-filters"
        }
        ref={ref}
      >
        <div className="right-filters">
          <div className="home-menu-search" ref={refSearch}>
            {/* eslint-disable-next-line */}
            <img
              src={seacrhIcon}
              alt=""
              onClick={(): void => {
                toggleSearchShow();
              }}
            />
            <DebounceInput
              debounceTimeout={1000}
              value={searchValue}
              onChange={(e): void => {
                handleSearch(e.target.value);
              }}
              type="text"
              className="search-input"
              placeholder={
                t("Enter a keyword to search") || "Enter a keyword to search"
              }
            />
            {searchValue.length > 0 && (
              <div
                onClick={(): void => {
                  handleSearch("");
                }}
                className="clear-btn"
              >
                X
              </div>
            )}
          </div>
        </div>
        <div className="try-your-luck">
          <button
            onClick={(): void => {
              getArbitraryGame();
            }}
            type="button"
          >
            {t("Try your luck")}
          </button>
        </div>
        {isDropdownShow && (
          <div className="home-filter-dropdown">
            <div className="home-filter-dropdown-row">
              <div className="dropdown-title" onClick={hideDropdown}>
                <img src={filterIcon} alt="" className="icon" />
                <span>{t("Filter by vendor")}</span>
                <img src={filterArrow} alt="" className="arrow" />
              </div>
            </div>
            <div className="filter-deopdown-wrap">
              <div className="dropdown-list">
                <div className="vendor-container">
                  <span
                    className={!activeVendor ? "active" : ""}
                    onClick={(): void => {
                      toggleVendor(null);
                      hideDropdown();
                    }}
                  >
                    {t("All vendors")}
                  </span>
                </div>
                {renderVendors()}
              </div>
            </div>
          </div>
        )}
        <div
          className={
            isDropdownShow ? "home-filter-item hidden" : "home-filter-item"
          }
        >
          <div className="wrapper" onClick={showDropdown}>
            <img src={filterIcon} alt="" className="icon" />
            <span>{activeVendor?.name || t("All vendors")}</span>
            <img src={filterArrow} alt="" className="arrow" />
          </div>
        </div>
        <div className="grid-btns">
          <div
            className="btn small-btn"
            onClick={(): void => {
              toggleGrid(true);
            }}
          >
            <img src={smallGrid} alt="" />
          </div>
          <div
            className="btn big-btn"
            onClick={(): void => {
              toggleGrid(false);
            }}
          >
            <img src={bigGrid} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMenu;
