import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getAllGamesList,
  // getGamesCategories,
  // getGamesVendors,
} from "../../services/apiGames";
import {
  GameItemType,
  CategoryItemType,
  VendorItemType,
} from "../../types/gameTypes";
import {
  AllGamesListResponse,
  GameListPayload,
} from "../../types/requestTypes";

export interface LiveGamesState {
  liveGames: GameItemType[] | null;
  categories: CategoryItemType[] | null;
  vendors: VendorItemType[] | null;
  allLiveFetching: boolean;
  categoryFetching: boolean;
  totalGames: number;
  liveActivePage: number;
  hasMoreLiveGames: boolean;
}

const initialState: LiveGamesState = {
  liveGames: null,
  categories: null,
  vendors: null,
  allLiveFetching: false,
  categoryFetching: false,
  totalGames: 0,
  liveActivePage: 1,
  hasMoreLiveGames: true,
};

export const getAllLiveGamesFunc = createAsyncThunk(
  "liveGames/loadAllList",
  async (data: GameListPayload) => {
    let itemsPerPage;
    if (window.innerWidth <= 576) {
      itemsPerPage = 9;
    } else if (window.innerWidth <= 990) {
      itemsPerPage = 24;
    } else {
      itemsPerPage = 32;
    }
    const response = await getAllGamesList(
      data.search as string | null,
      data.category as string | null,
      data.producer as string | null,
      data.page as number | undefined,
      itemsPerPage,
      data.lobby as boolean | undefined,
      data.isLive as boolean | undefined
    );
    if (typeof response !== "string") return response as AllGamesListResponse;
    return { data: [], meta: { total: 0, last_page: 0 } };
  }
);

export const liveGames = createSlice({
  name: "liveGames",
  initialState,
  reducers: {
    setLiveActivePage: (state, action: PayloadAction<number>) => {
      state.liveActivePage = action.payload;
    },
    clearLiveGames: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.liveGames = null;
      } else {
        state.liveGames = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllLiveGamesFunc.fulfilled,
      (state: LiveGamesState, action: PayloadAction<AllGamesListResponse>) => {
        state.liveGames = state.liveGames
          ? [...state.liveGames, ...action.payload.data]
          : action.payload.data;
        let itemsPerPage;
        if (window.innerWidth <= 576) {
          itemsPerPage = 9;
        } else if (window.innerWidth <= 990) {
          itemsPerPage = 24;
        } else {
          itemsPerPage = 32;
        }
        if (action.payload.data.length < itemsPerPage) {
          state.hasMoreLiveGames = false;
        } else {
          state.hasMoreLiveGames = true;
        }
        state.totalGames = action.payload.meta.total;
        state.allLiveFetching = false;
      }
    );

    builder.addCase(getAllLiveGamesFunc.pending, (state: LiveGamesState) => {
      state.allLiveFetching = true;
    });
  },
});

export const { setLiveActivePage, clearLiveGames } = liveGames.actions;

export default liveGames.reducer;
