import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useSelector } from "react-redux";
import type { RootState } from "../../../store/store";
import DocumentInputs, { Statuses } from "../DocumentInputs/DocumentInputs";
import { DocumentItem, DocumentTypes } from "../../../types/documentsTypes";
import styles from "./CreditCardInputsStyles.module.scss";
import MainButton from "../../UI/MainButton/MainButton";

interface CreditCardInputsProps {
  withdrawalCard: string;
  withdrawalYY: string;
  withdrawalMM: string;
  setWithdrawalCard: (value: string) => void;
  setWithdrawalMM: (value: string) => void;
  setWithdrawalYY: (value: string) => void;
  isFetching: boolean;
  sendCreditCard: () => void;
  handleSelectImg: (
    e: File | undefined,
    type: DocumentTypes,
    errorMessage: string
  ) => void;
  cardImg: File | undefined;
  currentBankCard: DocumentItem | undefined;
  setGlobalError: (errorMessage: string) => void;
}

const CreditCardInputs: React.FC<CreditCardInputsProps> = ({
  withdrawalCard,
  withdrawalMM,
  withdrawalYY,
  setWithdrawalCard,
  setWithdrawalMM,
  setWithdrawalYY,
  isFetching,
  sendCreditCard,
  handleSelectImg,
  currentBankCard,
  cardImg,
  setGlobalError,
}) => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const isBtnDisable = (): boolean => {
    return (
      !withdrawalCard.match(/^.{16}$/) ||
      !withdrawalMM.match(/^(0[1-9]|1[0-2])$/) ||
      !withdrawalYY.match(/^.{2}$|^.{4}$/) ||
      (!cardImg && !currentBankCard?.document_path)
    );
  };

  return (
    <div
      className={`${styles.documents_bank_wrap} ${
        localeActive?.code === "fa" ? styles.rtl : ""
      } `}
    >
      <div className={styles.row}>
        <div className={styles.form}>
          <input
            type="number"
            min="0"
            className={classNames(styles.main_input, styles.card_number_input)}
            value={withdrawalCard}
            readOnly={currentBankCard?.status === Statuses.approved}
            onKeyDown={(e): void => {
              if (e.key === "-" || e.key === "e") {
                e.preventDefault();
              }
            }}
            onWheel={(e): void => {
              e.currentTarget.blur();
            }}
            onChange={(e): void => {
              if (e.target.value.length <= 16) {
                setWithdrawalCard(e.target.value);
              }
            }}
            placeholder={t("Card number") || "Card number"}
          />
          <div className={styles.card_date_inputs}>
            <input
              type="number"
              min="0"
              className={styles.main_input}
              value={withdrawalMM}
              readOnly={currentBankCard?.status === Statuses.approved}
              onKeyDown={(e): void => {
                if (e.key === "-" || e.key === "e") {
                  e.preventDefault();
                }
              }}
              onWheel={(e): void => {
                e.currentTarget.blur();
              }}
              onChange={(e): void => {
                if (e.target.value.length <= 2) {
                  setWithdrawalMM(e.target.value);
                }
              }}
              placeholder={t("Month") || "Month"}
            />
            <input
              type="number"
              min="0"
              className={styles.main_input}
              value={withdrawalYY}
              readOnly={currentBankCard?.status === Statuses.approved}
              onKeyDown={(e): void => {
                if (e.key === "-" || e.key === "e") {
                  e.preventDefault();
                }
              }}
              onWheel={(e): void => {
                e.currentTarget.blur();
              }}
              onChange={(e): void => {
                if (e.target.value.length <= 4) {
                  setWithdrawalYY(e.target.value);
                }
              }}
              placeholder={t("Year") || "Year"}
            />
          </div>
        </div>
        <DocumentInputs
          handleSelect={handleSelectImg}
          type={DocumentTypes.credit_card}
          previewItem={cardImg}
          preview={currentBankCard}
          size="small"
          setGlobalError={setGlobalError}
        />
      </div>
      {currentBankCard?.status !== Statuses.approved &&
        currentBankCard?.status !== Statuses.processing && (
          <MainButton
            title={t("Confirm")}
            onClick={sendCreditCard}
            disabled={isBtnDisable() || isFetching}
            className={classNames(styles.load_card_btn)}
            variant="contained"
            color="primary"
          />
        )}
    </div>
  );
};

export default CreditCardInputs;
