import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";

import { TournamentStatuses } from "../../types/tournamentTypes";
import type { RootState } from "../../store/store";
import { startTornament } from "../../services/apiTournaments";
import { updateTournametsCount } from "../../store/slices/userData";

import imgDef from "../../images/tournament-banner-big.svg";

interface Props {
  img?: string;
  bg?: string;
  isList?: boolean;
  isHome?: boolean;
  title: string;
  id: number;
  date: string;
  prize: string;
  prizesCount: number;
  spins: number;
  currency: string;
  users: {
    id: number;
    nick_name: string;
    points_in_currency: number;
    rounds_count: number;
    place: number;
  }[];
  places: {
    id: number;
    place: number;
    reward_amount: number;
  }[];
  status: TournamentStatuses;
  isFinished?: boolean;
  showLogin: () => void;
}

const TournamentBanner: React.FC<Props> = ({
  img,
  bg,
  isList,
  title,
  id,
  date,
  prize,
  status,
  prizesCount,
  spins,
  users,
  currency,
  places,
  isFinished,
  isHome,
  showLogin,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const userId = useSelector((state: RootState) => state.user.userInfo.id);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const dispatch = useDispatch();

  const [isMine, setIsMine] = useState<boolean>(false);
  const [isMineFetching, setIsMineFetching] = useState<boolean>(false);
  const [userMemebrIndex, setUserMemebrIndex] = useState<number | null>(null);
  const [userMemeberPlace, setUserMemberPlace] = useState<number | null>(null);

  const shownMembersNum = 5;

  const renderStatus = (): JSX.Element => {
    switch (status) {
      case TournamentStatuses.active:
        return <div className="status active">{t("Active")}</div>;
      case TournamentStatuses.coming:
        return <div className="status waiting">{t("Upcoming")}</div>;
      case TournamentStatuses.expired:
        return <div className="status finished">{t("Finished")}</div>;
      default:
        return <div className="status">{status}</div>;
    }
  };
  const renderBg = (): string => {
    switch (bg) {
      case "blue":
        return "linear-gradient(180deg, #2D0049 0%, #3492E9 100%)";
      case "green":
        return "linear-gradient(180deg, #2D0049 0%, #34E987 100%)";
      case "orange":
        return "linear-gradient(180deg, #2D0049 0%, #E98B34 100%)";
      default:
        return "linear-gradient(180deg, #2D0049 0%, #3492E9 100%)";
    }
  };

  const startTournamentHandler = async (): Promise<void> => {
    if (isLogged) {
      setIsMineFetching(true);
      const result = await startTornament(id.toString());
      if (typeof result !== "string") {
        setIsMine(true);
        dispatch(updateTournametsCount(true));
      }
      setIsMineFetching(false);
    } else {
      showLogin();
    }
  };

  const handleBtnClick = (): void => {
    if (isLogged) {
      navigate(`/tournament/${id}`);
    } else {
      showLogin();
    }
  };

  useEffect(() => {
    const currentUser = users.findIndex((user) => user.id === userId);
    if (currentUser !== -1) {
      setIsMine(true);
      setUserMemebrIndex(currentUser);
      setUserMemberPlace(currentUser + 1);
    }
  }, [users, userId]);

  console.log(isHome);
  return (
    <div
      onClick={handleBtnClick}
      className={isFinished ? "tournament-item finished" : "tournament-item"}
      style={{ background: renderBg() }}
    >
      <img src={img || imgDef} alt="" className="tournament-item-img" />
      <div
        className={
          localeActive?.code === "fa" ? "tournament-row rtl" : "tournament-row"
        }
      >
        <div className="tournament-info">
          {renderStatus()}
          <div className="title">{title}</div>
          <div className="btn-block">
            <button
              className="tournament-btn"
              type="button"
              onClick={handleBtnClick}
            >
              {t("See details")}
            </button>
            {!isMine && !isFinished && (
              <button
                className="tournament-btn participate"
                type="button"
                onClick={(): void => {
                  startTournamentHandler();
                }}
                disabled={isMineFetching}
              >
                {t("Participate")}
              </button>
            )}
          </div>
          <div className="info-block">
            <span>{t("Date holding")}</span>
            <div>{date}</div>
          </div>
          <div className="info-block">
            <span>{t("Prize pool")}</span>
            <div>{prize}</div>
          </div>
        </div>
        <div className={isList ? "tournament-users list" : "tournament-users"}>
          {!isList && (
            <table className="tournament-banner-table">
              <thead>
                <tr>
                  <th># {t("Player")}</th>
                  <th>{t("Points")}</th>
                </tr>
              </thead>
              <tbody>
                {users
                  .slice(
                    0,
                    users.length < shownMembersNum
                      ? users.length
                      : shownMembersNum
                  )
                  .map((el, idx) => (
                    <tr
                      key={el.id}
                      className={el.id === userId ? "my-row" : ""}
                    >
                      <td>
                        <span>{idx + 1}.</span> {el.nick_name}
                      </td>
                      <td>{el.points_in_currency}</td>
                    </tr>
                  ))}
                {isMine &&
                userMemebrIndex &&
                userMemeberPlace !== null &&
                userMemeberPlace > shownMembersNum ? (
                  <>
                    <tr className="separate-row">
                      <td colSpan={3}>...</td>
                    </tr>
                    <tr className="my-row">
                      <td>
                        <span>{userMemeberPlace}.</span>{" "}
                        {users[userMemebrIndex].nick_name}
                      </td>
                      <td>{users[userMemebrIndex].points_in_currency}</td>
                    </tr>
                  </>
                ) : null}
                <tr>
                  <td>{t("Qualification spins")}</td>
                  <td>{spins}</td>
                </tr>
              </tbody>
            </table>
          )}
          {isList && (
            <div className="tournament-prize-list">
              {places.map((el, idx) => (
                <div className="item" key={el.id}>
                  <div className="item-title">
                    <div className="place">{idx + 1}</div>
                    <span>{t("place")}</span>
                  </div>
                  <div className="item-content">
                    {getSymbolFromCurrency(currency)} {el.reward_amount}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="info-block">
            <span>{t("Number of prizes")}</span>
            <div>{prizesCount}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentBanner;
