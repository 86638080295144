import { Validation } from "./type";

const regex = /^[a-zA-Zа-яА-ЯёЁ]+$/;

const validateName = (str: string): Validation => {
  const isValid = !regex.test(str);
  const message = "Only letters can be significant";
  if (str === "") {
    return {
      isValid: false,
      message: "",
    };
  }
  return {
    isValid,
    message,
  };
};

export default validateName;
