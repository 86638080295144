import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import closeIcon from "../../images/close-icon-new.svg";

interface Props {
  closeModal: () => void;
}

const GameErrorModal: React.FC<Props> = ({ closeModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="default-modal game-error-modal">
      <div className="overlay" />
      <div className="modal-content">
        <div
          className="modal-close-btn"
          onClick={(): void => {
            navigate("/");
            closeModal();
          }}
        >
          <img src={closeIcon} alt="close" width={15} />
        </div>
        <div className="modal-title">
          {t("You don`t have enough balance funds. Please top up the balance.")}
        </div>
        <div className="game-error-modal-btns">
          <button
            className="primary-btn"
            type="button"
            onClick={(): void => {
              navigate("/");
              closeModal();
            }}
          >
            {t("Close")}
          </button>
          <button
            className="primary-btn"
            type="button"
            onClick={(): void => {
              navigate("/payments");
              closeModal();
            }}
          >
            {t("Deposit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameErrorModal;
