/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import type { RootState } from "../../store/store";

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bonus-info-page-wrap">
      <div className="content-gradient" />
      <div className="content-page-box">
        <div
          className={
            localeActive?.code === "fa" ? "container rtl" : "container"
          }
        >
          <h3>{t("Registration")}</h3>
          <h4>{t("How does registration work?")}</h4>
          <p>
            {t(
              "To register in your BOOM Casino Account, you need to select the appropriate button on the website. The next step is to fill in the required fields."
            )}
          </p>
          <p>
            {t(
              "Registration can be done based on an email address or phone number as your login. It is important to provide information to which you have direct access during the registration process, with the ability to follow all instructions to successfully complete it."
            )}
          </p>
          <p>
            {t(
              "Also, come up with a secure password of at least 6 characters, using uppercase and lowercase Latin letters (AaBb) and numbers."
            )}
          </p>
          <p>
            {t(
              "After completing the registration and before fully using the services of BOOM Casino, you need to fill in all the information in your Account and go through the verification process."
            )}
          </p>
          <h4>{t("How many accounts can be opened on the site?")}</h4>
          <p>
            {t(
              "Any individual who has reached the age of 21, in accordance with the legislation of a specific jurisdiction, is allowed to create only one Account with one gaming account consisting of two sub-accounts - real and bonus."
            )}
          </p>
          <p>
            {t(
              "Repeated registration of an individual with duplicated accounts and all the necessary information is a gross violation that may result in penalties."
            )}
          </p>
          <p>
            {t(
              "In case of losing your Account or login problems, please contact BOOM Casino Support."
            )}
          </p>
          <h3>{t("Account")}</h3>
          <h4>{t("What data can be changed after registration?")}</h4>
          <ul>
            <span>
              {t(
                "During registration, it's important to provide correct and up-to-date information, as the following data cannot be changed after registration:"
              )}
            </span>
            <li>{t("First and last name;")}</li>
            <li>{t("Date of birth;")}</li>
            <li>{t("Currency of your account;")}</li>
            <li>{t("Place and country of residence.")}</li>
          </ul>
          <p>
            {t(
              "In case of an error in the provided data or documented change of one of the points, it can only be changed through BOOM Casino Support by providing the relevant documents and undergoing re-verification."
            )}
          </p>
          <h4>{t("How to change or recover your password?")}</h4>
          <ul>
            <span>
              {t(
                "You can change your password when necessary in the Account settings:"
              )}
            </span>
            <li>{t("Log in to your Account;")}</li>
            <li>{t("Select the Login and Password section;")}</li>
            <li>{t("Choose the appropriate option - Change Password;")}</li>
            <li>{t("Enter the old password in the first field;")}</li>
            <li>
              {t(
                "Enter a new secure password of at least 6 characters, using uppercase and lowercase Latin letters (AaBb) and numbers, without repeating the old one."
              )}
            </li>
            <li>{t("Click the Confirm button.")}</li>
          </ul>
          <ul>
            <span>
              {t(
                "If you've forgotten your password, you need to select Forgot Password and go through the account recovery process:"
              )}
            </span>
            <li>
              {t(
                "Enter your contact details: phone number or email, to which your Account was linked;"
              )}
            </li>
            <li>
              {t(
                "In the specified communication channel, receive a link and a personal one-time code;"
              )}
            </li>
            <li>
              {t(
                "Enter the code into the corresponding field by following the received link;"
              )}
            </li>
            <li>
              {t(
                "Enter a new secure password of at least 6 characters, using uppercase and lowercase Latin letters (AaBb) and numbers, without repeating the old one."
              )}
            </li>
          </ul>
          <h4>{t("How to confirm your phone number?")}</h4>
          <p>
            {t(
              "When filling out information in your Account, it's necessary to specify an up-to-date phone number and confirm it."
            )}
          </p>
          <ul>
            <span>{t("Confirmation procedure:")}</span>
            <li>{t("Log in to your Account;")}</li>
            <li>{t("Go to the section with contact information;")}</li>
            <li>{t("Click Confirm next to the phone number information;")}</li>
            <li>
              {t(
                "Enter the code that was sent to the specified number in the field that appears;"
              )}
            </li>
          </ul>
          <p>
            {t(
              "If there is a malfunction and you have not received the code, check the specified phone number, the availability of a mobile network, and try again. If necessary, contact Customer Support."
            )}
          </p>
          <h4>{t("How to confirm your email?")}</h4>
          <p>
            {t(
              "When filling out information in your Account, it is necessary to specify a current email address and confirm it."
            )}
          </p>
          <ul>
            <span>{t("Confirmation procedure:")}</span>
            <li>{t("Log in to your Account;")}</li>
            <li>{t("Go to the section with contact information;")}</li>
            <li>{t("Click Confirm next to the email address information;")}</li>
            <li>
              {t(
                "Follow the link provided in the email sent to the specified address;"
              )}
            </li>
            <li>{t("Click to confirm the provided information.")}</li>
          </ul>
          <p>
            {t(
              "If the email hasn't arrived, check your Spam and Promotions folders. Request the email again. If you encounter problems confirming your email address, contact Customer Support."
            )}
          </p>
          <h4>{t("How to change your phone number or email?")}</h4>
          <p>
            {t(
              "To ensure the security of the Account owner and personal information, it is prohibited to change the phone number and email address independently in Casino BOOM. For such changes, contact Customer Support."
            )}
          </p>
          <ul>
            <span>
              {t(
                "To perform the procedure of replacing the phone number or email address, you need to:"
              )}
            </span>
            <li>{t("Specify the old data that needs to be replaced;")}</li>
            <li>{t("The reason for the change, if possible;")}</li>
            <li>
              {t("Answer a series of questions to confirm your identity;")}
            </li>
            <li>{t("Specify the new data;")}</li>
            <li>{t("Confirm them in the Account.")}</li>
          </ul>
          <p>{t("The process of changing data ensures maximum security.")}</p>
          <h4>
            {t("What difficulties can arise when logging into your account?")}
          </h4>
          <p>
            {t(
              "Difficulties logging into your Account can arise for several reasons:"
            )}
          </p>
          <p>
            {t("Incorrect password or username.")}
            <br />
            {t(
              "To resolve this issue, double-check the accuracy of the login details on the website. If login difficulties persist, contact Customer Support."
            )}
          </p>
          <p>
            {t("Try again later.")}
            <br />
            {t(
              "Such difficulties may arise due to multiple login attempts with incorrect password or username. To resolve this issue, you need to clear your browser's cookies and cache. See the How to clear cache in different browsers? instructions in the Frequently Asked Questions category."
            )}
          </p>
          <p>
            {t("Difficulties logging into the Account.")}
            <br />
            {t(
              "If the website is functioning as usual but you are unable to log in, excluding the above-mentioned reasons, try switching to incognito or anonymous mode and attempt again."
            )}
          </p>
          <p>
            {t(
              "If you cannot resolve the problem yourself or encounter any other issues, we strongly recommend not using additional means and contacting Customer Support."
            )}
          </p>
          <h3>{t("Verification")}</h3>
          <h4>{t("What is the verification procedure?")}</h4>
          <ul>
            <span>
              {t(
                "To verify your Account, you need to follow the instructions to confirm your identity. Confirmation is done using documents:"
              )}
            </span>
            <li>{t("passport;")}</li>
            <li>{t("taxpayer identification number;")}</li>
          </ul>
          <ul>
            <span>
              {t(
                "You need to provide photos or high-quality color scans of the documents in .jpg or .png format so that all data is easily readable:"
              )}
            </span>
            <li>{t("passport spread confirming your identity;")}</li>
            <li>{t("taxpayer identification number.")}</li>
          </ul>
          <p>
            {t(
              "To confirm the correspondence of the individual and the documents, as well as their authenticity, you need to take a selfie with one of the specified documents. The entire procedure takes place in the corresponding section of the Account."
            )}
          </p>
          <h4>{t("What is verification for?")}</h4>
          <ul>
            <span>
              {t(
                "Verification is the confirmation of the user's identity. It is necessary to protect the gaming account and confidential data from encroachments by third parties. Successfully passing the procedure helps BOOM Casino establish such data:"
              )}
            </span>
            <li>{t("attainment of 21 years;")}</li>
            <li>{t("absence of a duplicate Account;")}</li>
            <li>{t("matching payment card data with the user's identity;")}</li>
            <li>
              {t(
                "absence of the user in the lists of persons prohibited or restricted from participating in gambling."
              )}
            </li>
          </ul>
          <h4>{t("What is the validity period of verification?")}</h4>
          <p>
            {t(
              "The user independently undergoes the verification procedure only once, excluding the need to update data in the relevant documents."
            )}
          </p>
          <p>
            {t(
              "BOOM Casino reserves the right to request the user to undergo partial or full verification if necessary."
            )}
          </p>
          <h3>{t("Deposit")}</h3>
          <h4>{t("How to fund your gaming account?")}</h4>
          <ul>
            <span>
              {t(
                "To fund your gaming account, select the appropriate button on the website and follow the instructions:"
              )}
            </span>
            <li>
              {t("enter the amount you want to deposit into the account;")}
            </li>
            <li>
              {t(
                "enter your card details, number, expiration date, authenticity code (CVV, etc.), from which the transaction will be made;"
              )}
            </li>
            <li>{t("then follow the prompts of your payment system.")}</li>
          </ul>
          <p>{t("Use payment systems like Visa, MasterCard, and others.")}</p>
          <p>
            {t(
              "If you encounter any difficulties with depositing, or if the money does not appear in your gaming account but has been deducted, please contact Customer Support."
            )}
          </p>
          <h4>{t("What difficulties can arise when depositing funds?")}</h4>
          <ul>
            <span>
              {t(
                "Possible reasons for difficulties when depositing funds into your gaming account from your payment system:"
              )}
            </span>
            <li>{t("Internet payment service is disabled;")}</li>
            <li>{t("Internet payment limit exceeded;")}</li>
            <li>{t("Insufficient funds on the card;")}</li>
            <li>{t("3D-Secure security system is disabled.")}</li>
          </ul>
          <p>
            {t(
              "If you encounter any difficulties with depositing funds into your gaming account, please check the entered data and the functionality of your payment card, and contact Customer Support. BOOM Casino will also check the system for malfunctions."
            )}
          </p>
          <h3>{t("Withdrawal of Funds")}</h3>
          <h4>{t("How to withdraw winnings?")}</h4>
          <ul>
            <span>
              {t(
                "To apply for a payout from your gaming account, you need to:"
              )}
            </span>
            <li>{t("log in to your gaming account;")}</li>
            <li>{t("select the corresponding button;")}</li>
            <li>
              {t(
                "confirm the presence of your taxpayer identification number in the system or provide it if it's not available;"
              )}
            </li>
            <li>
              {t(
                "enter the details of the payment card you previously used when using BOOM Casino services;"
              )}
            </li>
            <li>{t("enter your contact information.")}</li>
          </ul>
          <p>
            {t(
              "Wait for the request to be processed. If you encounter any difficulties, please contact Customer Support."
            )}
          </p>
          <h4>{t("What are the requirements for withdrawing funds?")}</h4>
          <p>
            {t(
              "Payout of winnings occurs only after verification, which takes place in the user's Account. Read about the verification procedure in the corresponding section."
            )}
          </p>
          <p>
            {t(
              "The same payment card that was used to deposit funds is used for withdrawals. BOOM Casino reserves the right to request a photo of the payment card used for transactions from the user."
            )}
          </p>
          <h4>{t("What tax is deducted when withdrawing funds?")}</h4>
          <p>
            {t(
              "Income from winnings, prizes, and gambling, including, is subject to taxation in accordance with the legislation."
            )}
          </p>
          <h3>{t("Bonuses")}</h3>
          <h4>{t("Where can I find information about bonuses?")}</h4>
          <p>
            {t(
              "Information about the bonus account can be found in the general gaming account and in the Account information."
            )}
          </p>
          <h4>{t("How are bonuses activated?")}</h4>
          <p>
            {t(
              "Deposit bonuses can be obtained through gaming processes during BOOM Casino promotions. To activate and start using a bonus, you need to make an initial deposit into your gaming account for a specific amount required by the terms of a specific promotion or offer."
            )}
          </p>
          <p>
            {t(
              "The issuance and receipt of bonuses can be done through the instructions of a specific promotion or by selecting the image of the desired bonus and following the system's prompts."
            )}
          </p>
          <p>
            {t(
              "No deposit bonuses that do not require a deposit into the gaming account can be obtained without waiting by meeting certain requirements."
            )}
          </p>
          <h4>{t("How to get more bonuses and information about them?")}</h4>
          <ul>
            <span>
              {t(
                "Every user receives promotional and informational newsletters through various communication channels, where personalized offers and bonus information are available:"
              )}
            </span>
            <li>{t("email newsletters;")}</li>
            <li>{t("SMS newsletters;")}</li>
            <li>{t("social media and online chats at BOOM Casino;")}</li>
            <li>{t("advertising banners on the BOOM Casino website;")}</li>
            <li>{t("Bonuses and terms section on the website.")}</li>
          </ul>
          <h3>{t("General Questions")}</h3>
          <h4>{t("What are the options for free play?")}</h4>
          <ul>
            <span>
              {t(
                "Free play mode, also known as demo mode, is possible, where bets with the main gaming account are not used. During such play, the corresponding in-game currency and rules apply:"
              )}
            </span>
            <li>
              {t(
                "demo credits, which allow you to play for free, are provided by BOOM Casino and cannot be replenished from the user's gaming account;"
              )}
            </li>
            <li>{t("winnings from demo mode games cannot be withdrawn;")}</li>
            <li>{t("game session and progress are not saved.")}</li>
          </ul>
          <h4>{t("What to do if there are issues on the website?")}</h4>
          <p>
            {t(
              "If the website or game is frozen or takes a long time to load, try refreshing the current page."
            )}
          </p>
          <p>
            {t(
              "Also, make sure you are not using VPN services, have a stable Internet connection, and have cleared your browser's cookies and cache. If everything mentioned is working correctly and reloading did not help, contact Customer Support."
            )}
          </p>
          <ul>
            <span>{t("To report an error, you will need:")}</span>
            <li>{t("a screenshot of the error that occurred;")}</li>
            <li>{t("the name of the slot game;")}</li>
            <li>{t("the name of the game provider;")}</li>
            <li>{t("the exact date and time of the incident;")}</li>
            <li>
              {t(
                "information about the browser you are using and its version;"
              )}
            </li>
            <li>{t("the device type where the error occurred;")}</li>
            <li>
              {t(
                "the IP address from which the game was launched, if possible."
              )}
            </li>
          </ul>
          <p>
            {t(
              "It is important to remember that all game processes work exclusively through the Internet, so a stable connection and a strong Internet signal are required for quality gaming."
            )}
          </p>
          <h4>{t("How to clear the cache in different browsers?")}</h4>
          <ul>
            <span>
              {t("Google Chrome.")}
              <br />
              {t(
                "The instructions are suitable for both laptops and computers, as well as mobile devices:"
              )}
            </span>
            <li>{t("Open the browser;")}</li>
            <li>
              {t(
                "In the upper right corner, click on the three vertical dots;"
              )}
            </li>
            <li>{t("In the pop-up window, select History;")}</li>
            <li>{t("Choose the option Clear browsing data;")}</li>
            <li>
              {t("Select the period for which you want to clear the cache;")}
            </li>
            <li>
              {t(
                "It is recommended to leave a blue checkmark only next to Images and other files stored in the cache;"
              )}
            </li>
            <li>{t("Click the Clear data button.")}</li>
          </ul>
          <ul>
            <span>
              Mozilla Firefox.
              <br />
              {t("Instructions for clearing browser data:")}
            </span>
            <li>{t("Open the browser;")}</li>
            <li>
              {t(
                "Go to the Settings section, located in the upper right corner;"
              )}
            </li>
            <li>{t("Select the Privacy section;")}</li>
            <li>{t("Select the option Delete recent history;")}</li>
            <li>{t("Only check the Cache option;")}</li>
            <li>{t("Click the Clear Now button.")}</li>
          </ul>
          <ul>
            <span>
              Safari.
              <br />
              {t(
                "To clear the browser on macOS and iOS operating systems, you need to:"
              )}
            </span>
            <li>{t("Open the browser;")}</li>
            <li>{t("In the menu bar, find the History section;")}</li>
            <li>{t("Then select Clear History;")}</li>
            <li>
              {t(
                "Select the time period for which you want to clear the history;"
              )}
            </li>
            <li>{t("Click the Clear History button.")}</li>
          </ul>
          <ul>
            <span>
              Opera.
              <br />
              {t(
                "These instructions are suitable for both laptops and computers, as well as mobile devices:"
              )}
            </span>
            <li>{t("Open the browser;")}</li>
            <li>{t("Select the Settings section;")}</li>
            <li>{t("Select the Privacy and Security section;")}</li>
            <li>{t("Select the option Clear Browsing Data;")}</li>
            <li>{t("Only check the Browsing History option;")}</li>
            <li>{t("Click the Clear Data button.")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
