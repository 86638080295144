import React from "react";

import closeIcon from "../../images/close-modal-icon.svg";
import successIcon from "../../images/sucess-icon.svg";

interface Props {
  closeModalHandler: () => void;
  mainTxt: string;
  title?: string;
  mainBtnTxt?: string;
  secondBtnTxt?: string;
  mainBtnHandler?: () => void;
  secondBtnHandler?: () => void;
}

const SuccessModal: React.FC<Props> = ({
  closeModalHandler,
  mainTxt,
  title,
  mainBtnTxt,
  secondBtnTxt,
  mainBtnHandler,
  secondBtnHandler,
}) => {
  return (
    <div className="error-modal-wrap">
      <div className="overlay" />
      <div className="error-modal">
        <div className="error-modal-title">
          <img src={successIcon} alt="icon" />
          {title && <div className="title">{title}</div>}
        </div>
        <div className="close-btn" onClick={closeModalHandler}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="error-modal-content">
          <p>{mainTxt}</p>
        </div>
        {mainBtnTxt && (
          <button
            type="button"
            className="main-btn"
            onClick={(): void => {
              if (mainBtnHandler) {
                mainBtnHandler();
              } else {
                closeModalHandler();
              }
            }}
          >
            {mainBtnTxt}
          </button>
        )}
        {secondBtnTxt && (
          <button
            type="button"
            className="border-btn"
            onClick={(): void => {
              if (secondBtnHandler) {
                secondBtnHandler();
              } else {
                closeModalHandler();
              }
            }}
          >
            {secondBtnTxt}
          </button>
        )}
      </div>
    </div>
  );
};

export default SuccessModal;
