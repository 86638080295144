import React, { useState } from "react";

import classNames from "classnames";
import passwordIcon from "../../../images/show-password-icon.svg";
import styles from "./TextFieldPasswordStyles.module.scss";
import TextField from "../TextField/TextField";

interface TextFieldPasswordProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

const TextFieldPassword: React.FC<TextFieldPasswordProps> = ({
  value,
  onChange,
  placeholder = "",
}) => {
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  return (
    <div className={styles.wrapper}>
      <TextField
        value={value}
        onChange={onChange}
        type={isPasswordShow ? "text" : "password"}
        placeholder={placeholder}
        className={classNames(styles.input_password)}
      />
      <div
        className={styles.password_show}
        onClick={(): void => {
          setIsPasswordShow(!isPasswordShow);
        }}
      >
        <img src={passwordIcon} alt="" />
      </div>
    </div>
  );
};

export default TextFieldPassword;
