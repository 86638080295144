import ApiClient from "./ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../types/requestTypes";
import { PromoCodeData } from "../types/promoTypes";

export const addPromo = async (
  code: string
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/promo/code/activate/me",
      { code }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deactivatePromocode = async ({
  data: { code, user_id },
}: {
  data: {
    code: string;
    user_id: number;
  };
}): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      "promo/deactivate/code",
      { code, user_id }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getPromoData = async (): Promise<{
  list: PromoCodeData[];
}> => {
  try {
    const result = await ApiClient.get<{ list: PromoCodeData[] }>(
      "/promo/me/list"
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
    Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    throw new Error(errors.join(" "));
  }
};
