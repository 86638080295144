import React, { useEffect, useState } from "react";
import { Table, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  formatDateDay,
  formatDateDayApi,
} from "../../../helpers/getCorrectDate";

import { getUsersBalanceList } from "../../../services/admin/apiUsers";
import { UserBalanceItem } from "../../../types/userTypes";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";

const UserBalances: React.FC = () => {
  // Users data
  const [users, setUsers] = useState<UserBalanceItem[]>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  //   Filters
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(2);
  const [type, setType] = useState<string>("All");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<string>("default");

  const { t } = useTranslation();

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  //   Users logic
  const loadUsers = async (
    page: number,
    searchValue: string,
    sortValue: string
  ): Promise<void> => {
    setFetching(true);
    const result = await getUsersBalanceList(
      page + 1,
      searchValue || "",
      sortValue && sortValue !== "default"
        ? `&balance_amount=${sortValue}`
        : undefined
    );
    if (typeof result !== "string") {
      setUsers(result.data as UserBalanceItem[]);
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!users) {
      setSearch(`&currency_id=${selectedCurrency}`);
      loadUsers(0, `&currency_id=${selectedCurrency}`, "");
    }
    // eslint-disable-next-line
  }, [users]);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadUsers(e, search, sort);
  };

  const handleClearSearch = (): void => {
    setStartDate(undefined);
    setEndDate(undefined);
    setSelectedCurrency(2);
    setType("All");
    setActivePage(0);
    setSearch(`&currency_id=${selectedCurrency}`);
    setSort("default");
    loadUsers(0, `&currency_id=${selectedCurrency}`, "");
  };
  const handleSearch = (): void => {
    const searchValue = `${
      startDate ? `&start_date=${formatDateDayApi(startDate)}` : ""
    }${endDate ? `&end_date=${formatDateDayApi(endDate)}` : ""}${
      selectedCurrency ? `&currency_id=${selectedCurrency}` : ""
    }${type && type !== "All" ? `&type=${type}` : ""}`;
    setActivePage(0);
    setSearch(searchValue);
    loadUsers(activePage, searchValue, sort);
  };

  const renderLevel = (value?: number): string => {
    if (value === 1) {
      return "Junior";
    }
    if (value === 2) {
      return "Master";
    }
    if (value === 3) {
      return "Favorite";
    }
    if (value === 4) {
      return "Pro";
    }
    if (value === 5) {
      return "Champion";
    }
    return "None";
  };

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>{t("Filters")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Start date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setStartDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={startDate}
                      dropdownMode="select"
                      placeholderText={t("Start date") || "Start date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("End date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setEndDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={endDate}
                      dropdownMode="select"
                      placeholderText={t("End date") || "End date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Balance")}</Form.Label>
                    <Form.Select
                      value={type}
                      onChange={(e): void => {
                        setType(e.target.value as string);
                      }}
                    >
                      <option value="All">{t("All")}</option>
                      <option value="real">{t("real")}</option>
                      <option value="demo">{t("demo")}</option>
                      <option value="bonus">{t("bonus")}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Currency")}</Form.Label>
                    <Form.Select
                      aria-label={t("Currency") || "Currency"}
                      value={selectedCurrency}
                      onChange={(e): void => {
                        setSelectedCurrency(
                          parseInt(e.target.value as string, 10)
                        );
                      }}
                      data-testid="bets-reporting-currency"
                    >
                      {currencyList &&
                        currencyList.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.code}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div className="admin-search-btns">
                    <Button
                      variant="danger"
                      onClick={handleClearSearch}
                      disabled={isFetching}
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isFetching}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Sort")}</Form.Label>
                    <Form.Select
                      value={sort}
                      onChange={(e): void => {
                        setSort(e.target.value as string);
                        setActivePage(0);
                        loadUsers(0, search, e.target.value as string);
                      }}
                    >
                      <option value="default">{t("Default")}</option>
                      <option value="asc">{t("ascending")}</option>
                      <option value="desc">{t("descending")}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {users && !isFetching && (
        <Table
          striped
          bordered
          className="mb-5"
          responsive
          data-testid="active-users-table"
        >
          <thead>
            <tr>
              <th>{t("Info")}</th>
              <th>{t("Registration date")}</th>
              <th>{t("Balance")}</th>
              <th>{t("Currency")}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((el) => (
              <tr key={`${el.user.id}${el.balance_type}`}>
                <td>
                  <div className="info-row">
                    <span>{t("Name")}: </span>
                    <span>
                      {el.user.first_name} {el.user.last_name}
                    </span>
                  </div>
                  <div className="info-row">
                    <span>{t("Email address")}: </span>
                    <span>{el.user.email}</span>
                  </div>
                  <div className="info-row">
                    <span>{t("Phone")}: </span>
                    <span>{el.user.phone}</span>
                  </div>
                  <div className="info-row">
                    <span>{t("Country")}: </span>
                    <span>{el?.user.country?.name as string}</span>
                  </div>
                  <div className="info-row">
                    <span>{t("City")}: </span>
                    <span>{el.user.city}</span>
                  </div>
                  <div className="info-row">
                    <span>{t("Gender")}: </span>
                    <span>{el.user.gender}</span>
                  </div>
                  <div className="info-row">
                    <span>VIP Level: </span>
                    <span>{renderLevel(el.user.vip_club?.level)}</span>
                  </div>
                </td>
                <td>
                  {el.user.created_at
                    ? formatDateDay(el.user.created_at as string)
                    : ""}
                </td>
                <td>
                  <div>
                    <b>{el.balance_type}: </b>
                    {el.balance_amount}
                  </div>
                </td>
                <td>{el.currency.code}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
    </>
  );
};

export default UserBalances;
