/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SortedGames from "../../components/tables/SortedGames";
import type { RootState } from "../../store/store";

const BonusInfo: React.FC = () => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bonus-info-page-wrap">
      <div className="content-gradient" />
      <div className="content-page-box">
        <div
          className={
            localeActive?.code === "fa"
              ? "bonuses-and-conditions-container rtl"
              : "bonuses-and-conditions-container"
          }
        >
          <h1>{t("Bonuses and Conditions")}</h1>
          <ol>
            <li>
              <span>{t("GENERAL RULES AND CONDITIONS FOR BONUSES")}</span>
              <ol>
                <li>
                  {t(
                    "Each player has two balances - real and bonus. When placing bets, the real balance is used first."
                  )}
                </li>
                <li>
                  {t(
                    "The issuance of bonuses is limited for each player, IP address, computer device, family, residence address, phone number, credit or debit card, e-wallet, email, and public network (e.g., university, public library, work network, etc.). In case of a violation of this rule, the administration has the right to:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "cancel (remove) the bonus and all subsequent winnings from it."
                      )}
                    </li>
                    <li>{t("cancel the player's real balance.")}</li>
                    <li>{t("block the player's account.")}</li>
                  </ul>
                </li>
                <li>
                  {t(
                    "Winnings may be denied to players using disposable email addresses."
                  )}
                </li>
                <li>
                  {t(
                    "To receive a deposit bonus, within the welcome bonus or any other deposit bonus, you must make a minimum deposit of 100 RUB, 1 EUR/USD, 500 KZT, unless otherwise stated."
                  )}
                </li>
                <li>
                  {t(
                    "To withdraw winnings, you must wager any bonus 40 times, unless otherwise stated."
                  )}
                </li>
                <li>
                  {t(
                    "Please note that different games may contribute different amounts to the bonus wagering requirement. When playing slots, 100% of the amount of bets made is taken into account (excluding games listed below)."
                  )}
                </li>
                <b>
                  {t(
                    "Exception: Bonus funds cannot be wagered in the following games:"
                  )}
                </b>
                <SortedGames />
                <li>
                  {t(
                    "Casino bonuses cannot be wagered in card games, roulette, and Live casino."
                  )}
                </li>
                <li>
                  {t(
                    "Also, all games are blocked for wagering at the following providers: ISoftbet, Pragmatic Play Live."
                  )}
                </li>
                <li>
                  {t(
                    "Players can request the withdrawal of the deposited amount before fulfilling the bonus wagering requirements. However, in this case, both the bonus and any winnings obtained while playing with it will be deducted from the account. Please note that when playing with an active bonus, real funds are used first, and then the bonus funds."
                  )}
                </li>
                <li>
                  {t(
                    "The welcome bonus is available for activation within the first 10 days from the moment of registration. Free spins and all other bonuses (unless otherwise stated) are available for 72 hours from the moment of accrual."
                  )}
                </li>
                <li>
                  {t(
                    "After the bonus expires, the bonus amount and any winnings obtained using this bonus will be deducted from the account."
                  )}
                </li>
                <li>
                  {t(
                    "All bets made during the game are deducted from the player's real funds balance. If there are no real funds left on the balance, bets are deducted from the available bonus funds, unless otherwise stated. All winnings from bets made to meet the bonus wagering requirements are credited to the bonus balance, unless otherwise stated."
                  )}
                </li>
                <li>
                  {t(
                    "The maximum allowed bet when playing with an active bonus is 2,000 RUB, 5 EUR/USD, 9,000 KZT. This also applies to the cost of buying bonus games in slots and the double game after the end of the game round, for example, when a player bets the entire win from the game round X on red/black in risk game mode (double game)."
                  )}
                </li>
                <li>
                  {t(
                    "Any bonus can be canceled before fulfilling the wagering requirements. To do this, contact the online support service on the website or use the 'Decline' button in the personal account, 'Bonuses' section."
                  )}
                </li>
                <li>
                  {t(
                    "Only one bonus can be activated at a time. Bonuses related to deposits do not stack. Boom Casino reserves the right to cancel any bonuses or winnings obtained through fraud."
                  )}
                </li>
                <li>
                  {t(
                    "Any winnings from a bonus or free spins obtained after wagering, losing, or canceling the bonus will be deducted from the balance."
                  )}
                </li>
                <li>
                  {t(
                    "Each payout undergoes internal verification before confirmation. Boom Casino reserves the right to cancel bonuses or winnings if such verification is not successful. By accepting these terms and conditions, the player agrees to this rule in advance."
                  )}
                </li>
                <li>
                  {t(
                    "If it is found during verification that the player(s) is using fraudulent strategies, exploiting a technical malfunction or error in the system or program, or engaging in activity that Boom Casino deems illegitimate at its discretion (e.g., 'bonus abuse'), then Boom Casino reserves the right to deprive such players of the right to use the promotion (and/or cancel the winnings obtained in this way), up to and including account blocking. Only fully completed winning and losing bets contribute to bonus wagering."
                  )}
                </li>
                <li>
                  {t(
                    "It is prohibited to have more than one account. Creating more than one account at Boom Casino will be considered bonus abuse and may lead to funds confiscation and account suspension."
                  )}
                </li>
                <li>
                  {t(
                    "According to the rules of game providers, bonus rounds and free spins do not participate in jackpot drawings. Jackpots are only available when betting with real money."
                  )}
                </li>
                <li>
                  {t(
                    "Low-risk betting in any game (e.g., simultaneous bets on red and black in roulette or simultaneous bets on the player and banker in baccarat) does not count towards wagering."
                  )}
                </li>
                <li>
                  {t(
                    "If it is discovered that a group of players is using the same game strategy and is linked (including, but not limited to, the same type of payment system, location, or IP address), Boom Casino reserves the right to, at its discretion, confiscate bonus funds and/or deposits."
                  )}
                </li>
                <li>
                  {t(
                    "If a player wins an amount considered worthy of public disclosure, they agree to participate in any event organized by Boom Casino. In this case, the casino will only use the player's first name and the first letter of their last name in promotional announcements or on the website, keeping the player's personal information securely protected."
                  )}
                </li>
                <li>
                  {t(
                    "Please note that when receiving a bonus, a player can cancel the bonus, choose another bonus, or play with it. When canceling a bonus, both the bonus amount and any winnings obtained while playing with it will be deducted from the player's balance. During bonus wagering, bets made with both real and bonus funds are considered unless stated otherwise."
                  )}
                </li>
                <li>
                  {t(
                    "If, when making a deposit, a player uses a promotional code to receivebonus inaccessible to his account, he will not be able to receivethis bonus."
                  )}
                </li>
                <li>
                  {t(
                    "In case of any disputes, Boom Casino's decision is final."
                  )}
                </li>
                <li>
                  {t(
                    "If, for any reason, the selected deposit bonus was not activated, the player must contact customer support before starting to play. If the deposit bonus was not activated and the player has already spent some or all of the funds, the bonus cannot be added manually."
                  )}
                </li>
                <li>
                  {t(
                    "Boom Casino reserves the right to cancel, modify, or amend these rules and conditions at any time and without prior notice."
                  )}
                </li>
                <li>
                  {t(
                    "To use a deposit bonus, go to your personal account, open the 'Bonuses' tab, and click the 'Activate' button next to the desired bonus. After that, make a deposit, and the bonus will be transferred to the 'Credited Bonuses' section. Now you can use the bonus funds. When canceling the bonus, the player agrees that any winnings obtained using this bonus, if any, and the bonus amount will be deducted from the player's balance."
                  )}
                </li>
              </ol>
              <li />
            </li>
            <li>
              <span>{t("Rules for receiving a birthday bonus.")}</span>
              <ol>
                <li>
                  {t(
                    "The bonus can be received only once a year from 1 day before the date of birth and within 3 days after it, as specified during registration."
                  )}
                </li>
                <li>
                  {t(
                    "If you have not previously been verified, you need to verify your account to participate in the promotion. Please fill in all the fields in the 'Documents' section."
                  )}
                </li>
                <li>
                  {t("The bonus amount depends on your VIP status level.")}
                  <ul>
                    <li>{t("Master level - 20 USD, 2000 RUB, 10 000 KZT.")}</li>
                    <li>
                      {t("Favorite level - 30 USD, 3000 RUB, 15 000 KZT.")}
                    </li>
                    <li>
                      {t("Champion level - 100 USD, 10,000 RUB, 50,000 KZT.")}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "To receive the bonus, contact the support chat on our website for activation. The bonus will be displayed in the 'Bonuses' section, and the bonus funds will be credited to your bonus balance immediately."
                  )}
                  <p>
                    {t(
                      "To wager the bonus, you need to meet the wagering requirements:"
                    )}
                  </p>
                  <ul>
                    <li>{t("Master level - x50 (fifty)")}.</li>
                    <li>{t("Favorite level - x50 (fifty)")}.</li>
                    <li>{t("Champion level - x40 (forty)")}.</li>
                  </ul>
                </li>
                <li>
                  {t(
                    "Wagering of the wager is available from both real and bonus balances. All winnings obtained when meeting the bonus wagering requirements will be credited to the bonus balance."
                  )}
                </li>
                <li>
                  {t(
                    "You are provided with 72 hours from the moment of activation to wager the bonus unless otherwise specified. After this period, the bonus expires."
                  )}
                </li>
              </ol>
            </li>
            <li>
              <span>{t("Rules And Conditions For No Deposit Bonuses")}</span>
              <ol>
                <li>
                  {t("The maximum win from a no deposit bonus is:")}
                  <ul>
                    <li>{t("10,000 RUB.")}</li>
                    <li>{t("100 EUR/USD.")}</li>
                    <li>{t("45,000 KZT.")}</li>
                  </ul>
                </li>
                <p>
                  {t(
                    "Any winnings exceeding the specified limits will be removed from the balance."
                  )}
                </p>
                <p>
                  {t(
                    "Please note that you should withdraw the winnings obtained from a no deposit bonus first and then, if desired, make a new deposit to continue playing without winning limitations."
                  )}
                </p>
                <li>
                  {t(
                    "Withdrawal of winnings from no deposit bonuses and free spins to a real account is possible only after meeting all the wagering requirements of x40 unless otherwise specified. Payout of winnings will be available only to bank cards."
                  )}
                </li>
                <li>
                  {t(
                    "When withdrawing funds from a real account, we may request documents to verify your account. The following documents may be required during verification:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "A government-issued document with a photo ID (passport, driver's license, or identification card)."
                      )}
                    </li>
                    <li>{t("Proof of tax ID (INN).")}</li>
                    <li>{t("A selfie with an identity document in hand.")}</li>
                    <li>
                      {t(
                        "Verification of the bank card, where you need to provide a photo of the card's front side or screenshots of the 'About the Card' and 'Details' tabs."
                      )}
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
          <div className="bonuses-faq">
            <div className="title">FAQ</div>
            <ul>
              <li>
                <p className="dot-mark">
                  {t("Is there a no deposit bonus at Boom Casino?")}
                </p>
                <p>
                  {t(
                    "Yes, Boom Casino offers a no deposit bonus - the Birthday Bonus. This bonus provides free spins for registered online casino members once a year on their birthday."
                  )}
                </p>
              </li>
              <li>
                <p className="dot-mark">
                  {t("How often are promotions held at Boom Casino?")}
                </p>
                <p>
                  {t(
                    "Boom Casino regularly holds various promotions and tournaments for its players. To stay updated on all offers, keep an eye on the casino's website news."
                  )}
                </p>
              </li>
              <li>
                <p className="dot-mark">
                  {t("Can I use multiple bonuses at the same time?")}
                </p>
                <p>
                  {t(
                    "Unfortunately, the casino does not allow the use of multiple bonuses simultaneously. You must use an active bonus before requesting another one."
                  )}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusInfo;
