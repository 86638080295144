import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import type { RootState } from "../store/store";
import { LocaleItem } from "../types/userTypes";
import { updateUserData } from "../services/apiLogin";
import { setActiveLocal } from "../store/slices/userData";

// import menuBonusIcon from "../images/bonus-icon-new.svg";
import logo from "../images/logo.svg";
import arrowIcon from "../images/arrow-down-new.svg";
import level1 from "../images/vip-icons/level1.svg";
import level2 from "../images/vip-icons/level2.svg";
import level3 from "../images/vip-icons/level3.svg";
import level4 from "../images/vip-icons/level4.svg";
import level5 from "../images/vip-icons/level5.svg";
import defAvatar from "../images/default-avatar-new.svg";
import BalanceView from "./UI/BalanceView/BalanceView";

interface MenuProps {
  setOpenPersonalDataModal: () => void;
}

const Menu: React.FC<MenuProps> = ({ setOpenPersonalDataModal }) => {
  const [showLangMenu, setShowLangMenu] = useState<boolean>(false);
  const [activeLang, setActiveLang] = useState<LocaleItem>({
    code: "en",
  } as LocaleItem);

  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const name = useSelector(
    (state: RootState) => state.user.userInfo.first_name
  );
  const lastName = useSelector(
    (state: RootState) => state.user.userInfo.last_name
  );
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const localesList = useSelector((state: RootState) => state.user.localesList);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const vipLevel = useSelector(
    (state: RootState) => state.user.userInfo.vip_club?.level
  );
  const isConfirmUser = useSelector(
    (state: RootState) => state.user.userInfo.first_name
  );

  useEffect(() => {
    if (localeActive && localeActive !== activeLang) {
      setActiveLang(localeActive);
    }
  }, [localeActive, activeLang]);
  useEffect(() => {
    const localLang = localStorage.getItem("localeLang");
    if (!localeActive && localLang) {
      const langObject = localesList.find(
        (locale) => locale.code === localLang
      );
      setActiveLang({ code: localLang } as LocaleItem);
      if (langObject) {
        i18n.changeLanguage(langObject.code as string);
        dispatch(setActiveLocal(langObject));
        if (isLogged) updateUserData({ locale_id: langObject.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeActive]);

  const showLangMenuHandle = (): void => {
    setShowLangMenu(true);
  };
  const refMenuLanf = useDetectClickOutside({
    onTriggered: (): void => setShowLangMenu(false),
  });
  const changleLangHandler = (lang: LocaleItem): void => {
    setActiveLang(lang);
    i18n.changeLanguage(lang.code as string);
    localStorage.setItem("localeLang", lang.code);
    setShowLangMenu(false);
    dispatch(setActiveLocal(lang));
    if (isLogged) updateUserData({ locale_id: lang.id });
  };

  const getUserAvatar = (): JSX.Element => {
    switch (vipLevel) {
      case 1:
        return (
          <div className="user-avatar">
            <img src={level1} alt="" />
          </div>
        );
      case 2:
        return (
          <div className="user-avatar">
            <img src={level2} alt="" />
          </div>
        );
      case 3:
        return (
          <div className="user-avatar">
            <img src={level3} alt="" />
          </div>
        );
      case 4:
        return (
          <div className="user-avatar">
            <img src={level4} alt="" />
          </div>
        );
      case 5:
        return (
          <div className="user-avatar">
            <img src={level5} alt="" />
          </div>
        );
      case 0:
      default:
        return (
          <div className="user-avatar default">
            <img src={defAvatar} alt="" />
          </div>
        );
    }
  };
  const getUserName = (): string => {
    switch (vipLevel) {
      case 1:
        return "Junior";
      case 2:
        return "Master";
      case 3:
        return "Favorite";
      case 4:
        return "Pro";
      case 5:
        return "Champion";
      case 0:
      default:
        return "";
    }
  };

  return (
    <div
      className={
        location.pathname.includes("login") ||
        location.pathname.includes("register") ||
        location.pathname.includes("password") ||
        location.pathname.includes("admin-panel")
          ? "menu-wrap hidden"
          : "menu-wrap"
      }
    >
      <div
        className={
          activeLang?.code === "fa" ? "container menu_rtl" : "container"
        }
      >
        <div className="menu-content">
          <div
            className="menu-logo"
            onClick={(): void => {
              navigate("/");
            }}
          >
            <img src={logo} alt="logo" className="logo" />
          </div>
          <div className="menu-controls">
            {isLogged ? (
              <>
                {/* <div
                  className="menu-bonus-link"
                  onClick={(): void => {
                    navigate("/bonuses");
                  }}
                >
                  <img src={menuBonusIcon} alt="" />
                  {t("Bonus")}
                </div> */}
                <div className="menu-balance">
                  <div
                    className="menu-user"
                    onClick={(): void => {
                      if (!isConfirmUser) {
                        setOpenPersonalDataModal();
                      } else navigate("/profile");
                    }}
                  >
                    {getUserAvatar()}
                    <div className="menu-user--content">
                      <div className="title">{name || lastName || "User"}</div>
                      <div className="status">{getUserName()}</div>
                    </div>
                  </div>
                  <BalanceView />
                </div>
              </>
            ) : (
              <div className="menu-btns">
                <button
                  className="border-btn"
                  type="button"
                  onClick={(): void => {
                    navigate("/login");
                  }}
                >
                  {t("Log in")}
                </button>
                <button
                  className="main-btn"
                  type="button"
                  onClick={(): void => {
                    navigate("/register");
                  }}
                >
                  {t("Registration")}
                </button>
              </div>
            )}
            <div
              className={showLangMenu ? "menu-lang opened" : "menu-lang"}
              ref={refMenuLanf}
              onClick={showLangMenuHandle}
            >
              <span>
                {activeLang.code === "kk"
                  ? "KZ"
                  : activeLang.code.toLocaleUpperCase()}
              </span>
              <img src={arrowIcon} alt="" />
              {showLangMenu && (
                <div className="lang-dropdown">
                  {localesList.map((el) => (
                    <span
                      className={el.code === activeLang.code ? "hidden" : ""}
                      key={el.id}
                      onClick={(): void => {
                        changleLangHandler(el);
                      }}
                    >
                      {el.code === "kk" ? "KZ" : el.code.toUpperCase()}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
