/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CirclesWithBar } from "react-loader-spinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import getSymbolFromCurrency from "currency-symbol-map";
import { DebounceInput } from "react-debounce-input";
import { useNavigate, useLocation } from "react-router-dom";

import type { RootState } from "../store/store";
import {
  DocumentTypes,
  DocumentPayload,
  DocumentItem,
} from "../types/documentsTypes";
import {
  createPayment,
  createKaptahPayment,
  createWidthdrawalRequest,
  getCryptoCoins,
  getPaymentLimits,
  getCurrencyCourse,
  kaptahConvert,
  getKaptahAmounts,
} from "../services/apiPayments";
import {
  getMyDocuments,
  addNewCreditCard,
  addNewDocument,
} from "../services/apiDocuments";
import { PaymentLimitItem, CurrencyCourseItem } from "../types/paymentTypes";
import { KaptahAmountResponse } from "../types/requestTypes";

import TransactionsTable from "../components/tables/TransactionsTable";
import ErrorModal from "../components/modals/ErrorModal";
import SuccessModal from "../components/modals/SuccessModal";

// import arrowIcon from "../images/deposit-arrow.svg";
import okIcon from "../images/ok-icon.svg";
import notOkkIcon from "../images/not-ok-icon.svg";
import btcIcon from "../images/payment/btc.svg";
import usdtIcon from "../images/payment/usdt.svg";
import ethIcon from "../images/payment/eth.svg";
// import mirIcon from "../images/payment/MIR.svg";
import visaIcon from "../images/payment/visa-img.svg";
import masterIcon from "../images/payment/master-img.svg";
import yMoneyIcon from "../images/payment/YooMoney.svg";
import qiwiIcon from "../images/payment/Qiwi.svg";
import p2pIcon from "../images/payment/p2p.svg";
import p2pGroupIcon from "../images/payment/p2p_group.svg";
import streamPay from "../images/payment/streampay.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moneyGo from "../images/payment/moneygo.svg";
import sbpIcon from "../images/payment/sbp-icon.svg";
import cryptoIcon from "../images/payment/crypto-img.png";
import balanceIcon from "../images/balance-icon-new.svg";
import copyIcon from "../images/copy-icon.svg";
import notifIcon from "../images/notification-icon.png";
import arrowBackIcon from "../images/new-arrow.svg";
import validateMinMaxLimit from "../helpers/validate/validateMinMaxLimit";
import CreditCardInputs from "../components/forms/CreditCardInputs/CreditCardInputs";
import StatusDocument from "../components/UI/StatusDocument/StatusDocument";
import { CryptoCurrencyType, SystemType } from "../types/currencyTypes";

enum Tabs {
  deposit = "deposit",
  withdrawal = "withdrawal",
  transactions = "transactions",
}

enum Errors {
  amount = "amount",
  docs = "docs",
  card = "card",
  dep = "dep",
  match = "match",
  pending = "pending",
  block = "block",
}

interface Props {
  depositSuccess?: boolean;
  depositErrorCallbek?: boolean;
  showProfile: () => void;
  handleClearDepositInfo: () => void;
}

const PaymentsPage: React.FC<Props> = ({
  depositSuccess,
  depositErrorCallbek,
  showProfile,
  handleClearDepositInfo,
}) => {
  // Condtitiobs
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.deposit);
  const [isDocumentsApproved, setIsDocumentsApproved] =
    useState<boolean>(false);
  const [depositRequestSent, setDepositRequestSent] = useState<boolean>(false);
  const [showCopied1, setShowCopied1] = useState<boolean>(false);
  const [showCopied2, setShowCopied2] = useState<boolean>(false);
  const [depositFetching, setDepositFetching] = useState<boolean>(false);
  const [depositLimitsFetching, setDepositLimitsFetching] =
    useState<boolean>(false);
  const [depositCoinsFetching, setDepositCoinsFetching] =
    useState<boolean>(false);
  const [depositCourseFetching, setDepositCourseFetching] =
    useState<boolean>(false);
  const [isCardApproved, setIsCardApproved] = useState<boolean>(false);
  const [withdrawalFetching, setWithdrawalFetching] = useState<boolean>(false);
  const [withdrawalSuccess, setWithdrawalSuccess] = useState<boolean>(false);
  const [cardFetching, setCardFetching] = useState<boolean>(false);
  const [moneygoCard, setMoneygoCard] = useState<string>("");
  const [isP2pAiforyPayment, setIsP2pAiforyPayment] = useState<boolean>();
  // Main data
  const [frameUrl, setFrameUrl] = useState<string>("");
  const [balanceAmount, setBalanceAmount] = useState<string>("");
  const [depositHasError, setDepositHasError] = useState<boolean>(false);
  const [coinsList, setCoinsList] = useState<string[]>([]);
  const [limitsList, setLimitsList] = useState<PaymentLimitItem[]>([]);
  const [currencyCourse, setCurrencyCourse] = useState<CurrencyCourseItem>();
  const [kaptahCourse, setKaptahCourse] = useState<{
    amount: number | null;
    amount_in_irr: string;
  }>();
  const [showKaptahAmounts, setShowKaptahAmounts] = useState<boolean>(false);
  const [kaptahAmounts, setKaptahAmounts] = useState<KaptahAmountResponse>();
  const [timerValue, setTimerValue] = useState<number>(60);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  const [recomentedAmounts, setRecomentedAmounts] = useState<string[]>([]);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  // Form data
  const [activeType, setActiveType] = useState<{
    type: SystemType;
    img: string;
  }>();
  const [amountValue, setAmountValue] = useState<string>("");
  const [currency, setCurrency] = useState<string>("USD");
  const [cryptoCurrency, setCryptoCurrency] = useState<{
    type: string;
    img: string;
  }>();
  const [depositError, setDepositError] = useState<string>("");
  const [depositWallet, setDepositWallet] = useState<string>("");
  const [withdrawalError, setWithdrawalError] = useState<string>("");
  const [withdrawalGlobalError, setWithdrawalGlobalError] = useState<Errors>();
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>("");
  const [withdrawalCard, setWithdrawalCard] = useState<string>("");
  const [withdrawalYY, setWithdrawalYY] = useState<string>("");
  const [withdrawalMM, setWithdrawalMM] = useState<string>("");
  const [defaultCard, setDefaultCard] = useState<{
    number: string;
    yy: string;
    mm: string;
  }>();
  const [cardImg, setCardImg] = useState<File>();
  const [isCardImgChanged, setIsCardImgChanged] = useState<boolean>(false);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  // const [isLoadFetching, setIsLoadFetching] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>("");
  const [currentBankCard, setCurrentBankCard] = useState<DocumentItem>();
  // const [currentDocuments, setCurrentDocuments] = useState<DocumentItem[]>();

  const [minMaxLimit, setMinMaxLimit] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const [validateReplenishmentValue, setValidateReplenishmentValue] = useState(
    validateMinMaxLimit(minMaxLimit, amountValue)
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  let showCopied1Timer: NodeJS.Timeout;
  let showCopied2Timer: NodeJS.Timeout;

  const lang = useSelector(
    (state: RootState) => state.user.userInfo?.locale?.code
  );
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const country = useSelector(
    (state: RootState) => state.user.userInfo?.country?.code
  );
  const info = useSelector((state: RootState) => state.user.userInfo);

  const getKaptahCourse = async (
    amountIrr: string,
    displayAmount: string
  ): Promise<void> => {
    setDepositFetching(true);
    const result = await kaptahConvert(amountIrr);
    if (typeof result !== "string") {
      result.amount = Number(result.amount.toFixed(2));
      // eslint-disable-next-line @typescript-eslint/naming-convention
      setKaptahCourse(result);
      setAmountValue(displayAmount);
    } else {
      setDepositError(result as string);
    }
    setDepositFetching(false);
  };

  const getKaptahAmountsList = async (): Promise<void> => {
    const result = await getKaptahAmounts();
    if (typeof result !== "string") {
      setKaptahAmounts(result);
      const defaultAmount = result.data.items[0];

      getKaptahCourse(
        defaultAmount.amount.toString(),
        defaultAmount.displayTitle
      );
    }
  };

  const handleSelectImg = (
    e: File | undefined,
    type: DocumentTypes,
    errorMessage: string
  ): void => {
    if (errorMessage !== "") return;
    switch (type) {
      case DocumentTypes.credit_card:
        setCardImg(e);
        if (isCardImgChanged === false) setIsCardImgChanged(true);
        break;
      default:
        break;
    }
  };

  const checkDocuments = async (): Promise<void> => {
    const result = await getMyDocuments();
    if (typeof result !== "string") {
      if (result.data.is_approved)
        setIsDocumentsApproved(result.data.is_approved);
      if (result.data.docs.length > 0) {
        result.data.docs.forEach((el) => {
          if (el.type === "CREDIT_CARD") {
            setWithdrawalCard(el.credit_card_number || "");
            setWithdrawalYY(el.credit_card_year?.toString() || "");
            setWithdrawalMM(el.credit_card_month?.toString() || "");
            // setIsCardApproved(el.status === "Approved");
            setDefaultCard({
              number: el.credit_card_number || "",
              yy: el.credit_card_year?.toString() || "",
              mm: el.credit_card_month?.toString() || "",
            });
            setCurrentBankCard(el);
          }
        });
      }
    }
  };
  const updateCurrency = (): void => {
    if (info?.current_balance?.code === "RUB") {
      setCurrency("RUB");
      setRecomentedAmounts(["350", "500", "1000", "2000"]);
      setCurrencySymbol("\u20BD");
      return;
    }
    if (info?.current_balance?.code === "KZT") {
      setCurrency("KZT");
      setRecomentedAmounts(["4500", "7000", "10000", "20000"]);
      setCurrencySymbol("\u20B8");
      return;
    }
    if (info?.current_balance?.code === "IRR") {
      setCurrency("IRR");
      setRecomentedAmounts(["250000", "400000", "500000", "1000000"]);
      setCurrencySymbol("\ufdfc");
      return;
    }
    if (info?.current_balance?.code === "UZS") {
      setCurrency("UZS");
      setRecomentedAmounts(["4500", "7000", "10000", "20000"]);
      return;
    }
    setRecomentedAmounts(["12", "50", "200", "500"]);
    setCurrency("USD");
    setCurrencySymbol("\u0024");
  };
  const loadCoins = async (): Promise<void> => {
    const result = await getCryptoCoins();
    if (typeof result !== "string") {
      setCoinsList(result as string[]);
    }
  };

  const loadLimits = async (): Promise<void> => {
    setDepositLimitsFetching(true);
    const result = await getPaymentLimits();
    if (typeof result !== "string") {
      setLimitsList(result.data as PaymentLimitItem[]);
    }
    setDepositLimitsFetching(false);
  };
  const getWithdrawalType = (): string => {
    if (
      country === "RU" ||
      country === "UZ" ||
      country === "KZ" ||
      country === "IR"
    ) {
      return "custom_card";
    }
    return "custom_crypto";
  };
  useEffect(() => {
    if (activeType?.type === SystemType.kaptahPay) {
      getKaptahAmountsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeType?.type]);
  useEffect(() => {
    checkDocuments();
    loadCoins();
    loadLimits();
    return () => {
      handleClearDepositInfo();
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    updateCurrency();
    // eslint-disable-next-line
  }, [info, activeType]);

  useEffect(() => {
    const balance = info.balances.filter((el) => el.type === "real")[0];
    setBalanceAmount(balance?.amount?.toString() || "0");
    // eslint-disable-next-line
  }, [info]);

  useEffect(() => {
    setDepositRequestSent(!!depositSuccess);
  }, [depositSuccess]);
  useEffect(() => {
    setDepositHasError(!!depositErrorCallbek);
  }, [depositErrorCallbek]);

  useEffect(() => {
    if (activeType?.type) {
      const element = limitsList.filter((el) => {
        return el.payment_system === activeType?.type;
      })[0];
      if (element) {
        setMinMaxLimit({ min: element.min, max: element.max });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeType]);

  const toggleTab = (tab: Tabs): void => {
    setActiveTab(tab);
    setAmountValue("");
    setActiveType(undefined);
    setDepositRequestSent(false);
    setWithdrawalSuccess(false);
    setDepositHasError(false);
    setCryptoCurrency(undefined);
    setTimerStarted(false);
    setTimerValue(60);
  };

  const renderError = (error: Errors): JSX.Element | null => {
    switch (error) {
      case Errors.amount:
        return (
          <ErrorModal
            mainTxt={t(
              "There are not enough funds on the main account to withdraw the required amount."
            )}
            btnTxt={t("REFUND YOUR ACCOUNT")}
            closeModalHandler={(): void => {
              setWithdrawalGlobalError(undefined);
              document.body.classList.toggle("no-scroll");
            }}
            confirmModalHandler={(): void => {
              setWithdrawalGlobalError(undefined);
              setActiveTab(Tabs.deposit);
              document.body.classList.toggle("no-scroll");
            }}
          />
        );
      // Blocked temporarily because it was necessary to remove errors when withdrawing money. DO NOT REMOVE CODE
      // case Errors.docs:
      //   return (
      //     <ErrorModal
      //       mainTxt={t("You have not verified your identity.")}
      //       btnTxt={t("Verify identity")}
      //       closeModalHandler={(): void => {
      //         setWithdrawalGlobalError(undefined);
      //         document.body.classList.toggle("no-scroll");
      //       }}
      //       confirmModalHandler={(): void => {
      //         setWithdrawalGlobalError(undefined);
      //         navigate("/");
      //         showProfile();
      //         document.body.classList.toggle("no-scroll");
      //       }}
      //     />
      //   );
      // Blocked temporarily because it was necessary to remove errors when withdrawing money. DO NOT REMOVE CODE
      // case Errors.card:
      //   return (
      //     <ErrorModal
      //       mainTxt={t("You have not verified your bank card.")}
      //       btnTxt={t("Verify card")}
      //       closeModalHandler={(): void => {
      //         setWithdrawalGlobalError(undefined);
      //         document.body.classList.toggle("no-scroll");
      //       }}
      //       confirmModalHandler={(): void => {
      //         setWithdrawalGlobalError(undefined);
      //         navigate("/");
      //         showProfile();
      //         document.body.classList.toggle("no-scroll");
      //       }}
      //     />
      //   );
      case Errors.dep:
        return (
          <ErrorModal
            mainTxt={t("You have not wagered 70% of your deposit amount.")}
            btnTxt={t("Continue playing")}
            closeModalHandler={(): void => {
              setWithdrawalGlobalError(undefined);
              document.body.classList.toggle("no-scroll");
            }}
            confirmModalHandler={(): void => {
              setWithdrawalGlobalError(undefined);
              navigate("/");
              document.body.classList.toggle("no-scroll");
            }}
          />
        );
      // Blocked temporarily because it was necessary to remove errors when withdrawing money. DO NOT REMOVE CODE
      // case Errors.match:
      //   return (
      //     <ErrorModal
      //       mainTxt={t(
      //         "Your bank withdrawal card does not match with the replenishment card."
      //       )}
      //       btnTxt={t("Contact Support")}
      //       closeModalHandler={(): void => {
      //         setWithdrawalGlobalError(undefined);
      //         document.body.classList.toggle("no-scroll");
      //       }}
      //       confirmModalHandler={(): void => {
      //         setWithdrawalGlobalError(undefined);
      //         navigate("/");
      //         document.body.classList.toggle("no-scroll");
      //       }}
      //     />
      //   );
      // Blocked temporarily because it was necessary to remove errors when withdrawing money. DO NOT REMOVE CODE
      // case Errors.pending:
      //   return (
      //     <ErrorModal
      //       mainTxt={t("You have unconfirmed withdrawal at this moment.")}
      //       btnTxt={t("Continue playing")}
      //       closeModalHandler={(): void => {
      //         setWithdrawalGlobalError(undefined);
      //         document.body.classList.toggle("no-scroll");
      //       }}
      //       confirmModalHandler={(): void => {
      //         setWithdrawalGlobalError(undefined);
      //         navigate("/");
      //         document.body.classList.toggle("no-scroll");
      //       }}
      //     />
      //   );
      case Errors.block:
        return (
          <ErrorModal
            mainTxt={t("Withdrawal was blocked for current account.")}
            btnTxt={t("Contact Support")}
            closeModalHandler={(): void => {
              setWithdrawalGlobalError(undefined);
              document.body.classList.toggle("no-scroll");
            }}
            confirmModalHandler={(): void => {
              setWithdrawalGlobalError(undefined);
              navigate("/");
              document.body.classList.toggle("no-scroll");
            }}
          />
        );

      default:
        return null;
    }
  };

  const reloadCardFile = async (
    filePath: string
  ): Promise<File | undefined> => {
    try {
      const response = await fetch(filePath.replace("http", "https"));
      const blob = await response.blob();
      const fileName = `file${filePath.substring(filePath.lastIndexOf("."))}`;
      const file = new File([blob], fileName);
      return file;
    } catch (error) {
      return undefined;
    }
  };

  const handleUpdateCard = async (): Promise<void> => {
    let currentCardImg;
    if (cardImg) currentCardImg = cardImg;
    else if (currentBankCard?.document_path)
      currentCardImg = await reloadCardFile(currentBankCard.document_path);

    const updatedCardInfo: DocumentPayload = {
      credit_card_number: withdrawalCard || "",
      credit_card_month: withdrawalMM,
      credit_card_year: withdrawalYY,
      credit_card: currentCardImg,
    };

    setCardFetching(true);
    const result = await addNewDocument(updatedCardInfo);
    if (typeof result !== "string") {
      checkDocuments();
      setIsCardImgChanged(false);
    }
    setCardFetching(false);
  };
  const isValid = (str: string): boolean => {
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };
  const sendDepositRequest = async (): Promise<void> => {
    setDepositFetching(true);
    let result;
    if (activeType?.type === SystemType.kaptahPay) {
      result = await createKaptahPayment(
        activeType?.type || "",
        kaptahCourse?.amount ? kaptahCourse.amount : null,
        currency,
        kaptahCourse?.amount_in_irr
          ? parseFloat(kaptahCourse?.amount_in_irr)
          : null
      );
    } else {
      const paymentCurrency =
        country === "IR" && activeType?.type === "streampay" ? "IRR" : currency;
      result = await createPayment(
        activeType?.type || "",
        parseFloat(amountValue),
        paymentCurrency,
        lang || "",
        cryptoCurrency?.type || "",
        !moneygoCard || moneygoCard === "" ? undefined : moneygoCard,
        isP2pAiforyPayment
      );
    }
    if (typeof result !== "string") {
      if (activeType?.type === "coinsPaid") {
        setDepositRequestSent(true);
        setDepositWallet((result.data?.wallet as string) || "");
      } else if (activeType?.type === SystemType.kaptahPay) {
        window?.open(result.data.url, "_self");
      } else {
        setFrameUrl(result.data.url as string);
      }
    } else {
      setDepositError(result as string);
    }
    setDepositFetching(false);
  };
  const sendWithdrawalRequest = async (): Promise<void> => {
    if (parseInt(withdrawalAmount, 10) > parseInt(balanceAmount, 10)) {
      document.body.classList.toggle("no-scroll");
      setWithdrawalGlobalError(Errors.amount);
      return;
    }
    // Blocked temporarily because it was necessary to remove errors when withdrawing money. DO NOT REMOVE CODE
    // if (!isDocumentsApproved) {
    //   document.body.classList.toggle("no-scroll");
    //   setWithdrawalGlobalError(Errors.docs);
    //   return;
    // }
    // if (!isCardApproved) {
    //   document.body.classList.toggle("no-scroll");
    //   setWithdrawalGlobalError(Errors.card);
    //   return;
    // }
    setWithdrawalFetching(true);
    if (
      defaultCard &&
      (defaultCard.number !== withdrawalCard ||
        defaultCard.yy !== withdrawalYY ||
        defaultCard.mm !== withdrawalMM ||
        isCardImgChanged)
    ) {
      await handleUpdateCard();
    }
    const result = await createWidthdrawalRequest(
      currency || "",
      getWithdrawalType() || "",
      parseInt(withdrawalAmount, 10),
      withdrawalCard === "" ? undefined : withdrawalCard
      // !cryptoCurrency?.type ? undefined : cryptoCurrency?.type
    );
    if (!result.error) {
      setWithdrawalSuccess(true);
    } else {
      // Blocked temporarily because it was necessary to remove errors when withdrawing money. DO NOT REMOVE CODE
      // if (
      //   (result.errorsKeys && result.errorsKeys.includes("any_real_deposit")) ||
      //   (result.errorsKeys && result.errorsKeys.includes("invalid_card"))
      // ) {
      //   setWithdrawalFetching(false);
      //   document.body.classList.toggle("no-scroll");
      //   setWithdrawalGlobalError(Errors.match);
      //   return;
      // }
      if (result.errorsKeys && result.errorsKeys.includes("low_wagering")) {
        setWithdrawalFetching(false);
        document.body.classList.toggle("no-scroll");
        setWithdrawalGlobalError(Errors.dep);
        return;
      }
      if (
        result.errorsKeys &&
        result.errorsKeys.includes("blocked_withdrawal")
      ) {
        setWithdrawalFetching(false);
        document.body.classList.toggle("no-scroll");
        setWithdrawalGlobalError(Errors.block);
        return;
      }
      // Blocked temporarily because it was necessary to remove errors when withdrawing money. DO NOT REMOVE CODE
      // if (
      //   result.errorsKeys &&
      //   result.errorsKeys.includes("unconfirmed_withdrawal")
      // ) {
      //   setWithdrawalFetching(false);
      //   document.body.classList.toggle("no-scroll");
      //   setWithdrawalGlobalError(Errors.pending);
      //   return;
      // }
      setWithdrawalError(result.error as string);
    }
    setWithdrawalFetching(false);
  };
  const handleSubmit = (): void => {
    const isValueValid = isValid(amountValue);
    if (!isValueValid || amountValue === "0") {
      setDepositError(t("The amount must be a positive integer") || "");
      return;
    }
    if (activeTab === Tabs.deposit) {
      sendDepositRequest();
    }
    if (activeTab === Tabs.withdrawal) {
      sendWithdrawalRequest();
    }
  };

  const getCardLimits = (system: SystemType): JSX.Element | null => {
    const element = limitsList.filter((el) => {
      return el.payment_system === system;
    })[0];
    if (element?.payment_system === SystemType.kaptahPay) {
      return null;
    }
    if (element) {
      return (
        <div className="payment-limits">
          {`
        ${element.min}${
            getSymbolFromCurrency(element?.currency?.code || "") as string
          } - ${element.max}${
            getSymbolFromCurrency(element?.currency?.code || "") as string
          }`}
        </div>
      );
    }
    return <div className="limits-row">-</div>;
  };

  const getCoinCardLimits = (
    system: SystemType,
    currencyString: string
  ): JSX.Element => {
    const element = limitsList.filter(
      (el) =>
        el.payment_system === system && el.currency.code === currencyString
    )[0];
    if (element) {
      return (
        <div className="payment-limits">
          {`
        ${element.min}${getSymbolFromCurrency(currency || "") as string} - ${
            element.max
          }${getSymbolFromCurrency(currency || "") as string}`}
        </div>
      );
    }
    return <div className="limits-row">-</div>;
  };

  const getLimits = (system: SystemType): JSX.Element | null => {
    const element = limitsList.filter((el) => {
      return el.payment_system === system;
    })[0];
    if (element.payment_system === SystemType.kaptahPay) {
      return null;
    }
    if (element) {
      return (
        <div className="limits-row">
          {
            `${t("Min")} ${element.min as number}${
              // eslint-disable-next-line
              getSymbolFromCurrency(element?.currency?.code || "") as string
            } - ${t("Max")} ${element.max as number}${
              // eslint-disable-next-line
              getSymbolFromCurrency(element?.currency?.code || "") as string
            }` as string
          }
        </div>
      );
    }
    return <div className="limits-row">-</div>;
  };

  const getLimitsCoins = (
    system: SystemType,
    currencyString: string
  ): JSX.Element => {
    const element = limitsList.filter(
      (el) =>
        el.payment_system === system && el.currency.code === currencyString
    )[0];
    if (element) {
      return (
        <div className="coins-limits">
          <span>
            {t("Min")}{" "}
            {`${element.min as number}${
              // eslint-disable-next-line
              getSymbolFromCurrency(currency || "") as string
            } - `}
          </span>
          <span>
            {" "}
            {t("Max")}{" "}
            {`${element.max as number}${
              // eslint-disable-next-line
              getSymbolFromCurrency(currency || "") as string
            }`}
          </span>
        </div>
      );
    }
    return <div className="limits-row">-</div>;
  };

  const getCourse = async (
    from: string,
    to: string,
    amount: number
  ): Promise<void> => {
    setDepositCourseFetching(true);
    setTimerStarted(false);
    setTimerValue(60);
    const result = await getCurrencyCourse(from, to, amount);
    if (typeof result !== "string") {
      setCurrencyCourse(result.currencies as CurrencyCourseItem);
      setTimerStarted(true);
    }
    setDepositCourseFetching(false);
  };
  const getCoinsDepositInfo = async (
    cryptoCurrencyArg: string,
    system: SystemType,
    amount: string
  ): Promise<void> => {
    setDepositCoinsFetching(true);
    const result = await createPayment(
      system,
      parseFloat(amount),
      currency,
      lang || "",
      cryptoCurrencyArg
    );
    await getCourse(currency, cryptoCurrencyArg, parseInt(amount, 10));
    if (typeof result !== "string") {
      setDepositWallet((result.data?.wallet as string) || "");
      setDepositError("");
    } else {
      setDepositError(result as string);
    }
    setDepositCoinsFetching(false);
  };

  const determineCurrency = (cardNumber: string): string => {
    const firstLetter = cardNumber.charAt(0).toUpperCase();
    let selectedCurrency = "";

    switch (firstLetter) {
      case "R":
        selectedCurrency = "RUB";
        break;
      case "U":
        selectedCurrency = "USD";
        break;
      case "E":
        selectedCurrency = "EUR";
        break;
      default:
        selectedCurrency = "";
    }

    return selectedCurrency;
  };
  const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setMoneygoCard(value);

    const selectedCurrency = determineCurrency(value);
    setCurrency(selectedCurrency);
  };

  useEffect(() => {
    if (!timerStarted) return;
    if (timerValue === 0) {
      getCourse(
        currency,
        cryptoCurrency?.type || "",
        parseInt(amountValue, 10)
      );
      return;
    }
    const intervalId = setInterval(() => {
      setTimerValue(timerValue - 1);
    }, 1000);
    // eslint-disable-next-line
    return () => {
      clearInterval(intervalId);
    };
  }, [timerValue, timerStarted, currency, cryptoCurrency, amountValue]);

  const clearData = (): void => {
    setActiveType(undefined);
    setFrameUrl("");
    setAmountValue("");
    setTimerStarted(false);
    setTimerValue(60);
    setDepositError("");
    setCryptoCurrency(undefined);
    setDepositWallet("");
    setCurrencyCourse(undefined);
    setKaptahCourse(undefined);
    setIsP2pAiforyPayment(undefined);
    setValidateReplenishmentValue(validateMinMaxLimit(minMaxLimit, ""));
    setMinMaxLimit(null);
  };

  const renderCoins = (): JSX.Element => {
    return (
      <div className="payment-types">
        {coinsList.map((el) => {
          if (el === CryptoCurrencyType.BTC) {
            return (
              <div
                key={el}
                className={
                  cryptoCurrency?.type === el
                    ? "payment-type-item active"
                    : "payment-type-item"
                }
                onClick={(): void => {
                  clearData();
                  const minAmount = limitsList.filter(
                    (item) => item.currency.code === CryptoCurrencyType.BTC
                  )[0];
                  setAmountValue(minAmount.min.toString() || "100");
                  setActiveType({
                    type: SystemType.coinsPaid,
                    img: cryptoIcon,
                  });
                  setCryptoCurrency({ type: el, img: btcIcon });
                  getCoinsDepositInfo(
                    el,
                    SystemType.coinsPaid,
                    minAmount.min.toString() || "100"
                  );
                }}
              >
                <div className="coin-info bitcoin">
                  <div className="coin-title">
                    <img src={btcIcon} alt="" />
                  </div>
                  <div className="coin-description">
                    <div className="name">BITCOIN</div>
                    <div className="symbol">BTC</div>
                  </div>
                </div>
                {getCoinCardLimits(
                  SystemType.coinsPaid,
                  CryptoCurrencyType.BTC
                )}
              </div>
            );
          }
          if (el === CryptoCurrencyType.ETH) {
            return (
              <div
                key={el}
                className={
                  cryptoCurrency?.type === el
                    ? "payment-type-item active"
                    : "payment-type-item"
                }
                onClick={(): void => {
                  clearData();
                  const minAmount = limitsList.filter(
                    (item) => item.currency.code === CryptoCurrencyType.ETH
                  )[0];
                  setAmountValue(minAmount.min.toString() || "100");
                  setActiveType({
                    type: SystemType.coinsPaid,
                    img: cryptoIcon,
                  });
                  setCryptoCurrency({ type: el, img: ethIcon });
                  getCoinsDepositInfo(
                    el,
                    SystemType.coinsPaid,
                    minAmount.min.toString() || "100"
                  );
                }}
              >
                <div className="coin-info ethereum">
                  <div className="coin-title">
                    <img src={ethIcon} alt="" />
                  </div>
                  <div className="coin-description">
                    <div className="name">Ethereum</div>
                    <div className="symbol">ERC20</div>
                  </div>
                </div>
                {getCoinCardLimits(
                  SystemType.coinsPaid,
                  CryptoCurrencyType.ETH
                )}
              </div>
            );
          }
          if (el === CryptoCurrencyType.USDTT) {
            return (
              <div
                key={el}
                className={
                  cryptoCurrency?.type === el
                    ? "payment-type-item active"
                    : "payment-type-item"
                }
                onClick={(): void => {
                  clearData();
                  const minAmount = limitsList.filter(
                    (item) => item.currency.code === CryptoCurrencyType.USDTT
                  )[0];
                  setAmountValue(minAmount.min.toString() || "100");
                  setActiveType({
                    type: SystemType.coinsPaid,
                    img: cryptoIcon,
                  });
                  setCryptoCurrency({ type: el, img: usdtIcon });
                  getCoinsDepositInfo(
                    el,
                    SystemType.coinsPaid,
                    minAmount.min.toString() || "100"
                  );
                }}
              >
                <div className="coin-info tether">
                  <div className="coin-title">
                    <img src={usdtIcon} alt="" />
                  </div>
                  <div className="coin-description">
                    <div className="name">TETHER</div>
                    <div className="symbol">TRC 20</div>
                  </div>
                </div>
                {getCoinCardLimits(
                  SystemType.coinsPaid,
                  CryptoCurrencyType.USDTT
                )}
              </div>
            );
          }
          if (el === CryptoCurrencyType.USDTE) {
            return (
              <div
                key={el}
                className={
                  cryptoCurrency?.type === el
                    ? "payment-type-item active"
                    : "payment-type-item"
                }
                onClick={(): void => {
                  clearData();
                  const minAmount = limitsList.filter(
                    (item) => item.currency.code === CryptoCurrencyType.USDTE
                  )[0];
                  setAmountValue(minAmount.min.toString() || "100");
                  setActiveType({
                    type: SystemType.coinsPaid,
                    img: cryptoIcon,
                  });
                  setCryptoCurrency({ type: el, img: usdtIcon });
                  getCoinsDepositInfo(
                    el,
                    SystemType.coinsPaid,
                    minAmount.min.toString() || "100"
                  );
                }}
              >
                <div className="coin-info tether">
                  <div className="coin-title">
                    <img src={usdtIcon} alt="" />
                  </div>
                  <div className="coin-description">
                    <div className="name">TETHER</div>
                    <div className="symbol">ERC 20</div>
                  </div>
                </div>
                {getCoinCardLimits(
                  SystemType.coinsPaid,
                  CryptoCurrencyType.USDTE
                )}
              </div>
            );
          }
          return (
            <div
              key={el}
              className={
                cryptoCurrency?.type === el
                  ? "payment-type-item active"
                  : "payment-type-item"
              }
              onClick={(): void => {
                clearData();
                const minAmount = limitsList.filter(
                  (item) => item.payment_system === SystemType.coinsPaid
                )[0];
                setAmountValue(minAmount.min.toString() || "100");
                setActiveType({ type: SystemType.coinsPaid, img: cryptoIcon });
                setCryptoCurrency({ type: el, img: cryptoIcon });
                getCoinsDepositInfo(
                  el,
                  SystemType.coinsPaid,
                  minAmount.min.toString() || "100"
                );
              }}
            >
              <div className="coin-title">
                <div className="txt">Custom</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTypes = (): JSX.Element => {
    if (country === "RU") {
      return (
        <>
          <div className="payment-type-wrapper">
            <div className="title">{t("SBP")}</div>
            <div className="payment-types">
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({
                    type: SystemType.streampay,
                    img: sbpIcon,
                  });
                }}
              >
                <img src={sbpIcon} alt="SBP" />
                {getCardLimits(SystemType.streampay)}
              </div>
            </div>
            {/* <div className="payment-types">
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({
                    type: SystemType.aifory,
                    img: sbpIcon,
                  });
                }}
              >
                <img src={sbpIcon} alt="SBP" />
                {getCardLimits(SystemType.aifory)}
              </div>
            </div> */}
          </div>
          <div className="payment-type-wrapper">
            <div className="title">{t("Transfer to card")}</div>
            <div className="payment-types">
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setIsP2pAiforyPayment(true);
                  setActiveType({ type: SystemType.aifory, img: p2pGroupIcon });
                }}
              >
                <img src={p2pGroupIcon} alt="" />
                {getCardLimits(SystemType.aifory)}
              </div>
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.P2R, img: p2pIcon });
                }}
              >
                <img src={p2pIcon} alt="" />
                {getCardLimits(SystemType.P2R)}
              </div>
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.streampay, img: streamPay });
                }}
              >
                <img src={streamPay} alt="" />
                {getCardLimits(SystemType.streampay)}
              </div>
            </div>
          </div>
          <div className="payment-type-wrapper">
            <div className="title">{t("Payment systems")}</div>
            <div className="payment-types">
              {/* <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.YooMoney, img: yMoneyIcon });
                }}
              >
                <img src={yMoneyIcon} alt="" />
                {getCardLimits(SystemType.YooMoney)}
              </div> */}
              {/* <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.Qiwi, img: qiwiIcon });
                }}
              >
                <img src={qiwiIcon} alt="" />
                {getCardLimits(SystemType.Qiwi)}
              </div> */}
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.moneygo, img: moneyGo });
                }}
              >
                <img src={moneyGo} alt="" />
                {getCardLimits(SystemType.moneygo)}
              </div>
            </div>
          </div>
          <div className="payment-type-wrapper">
            <div className="title">{t("Cryptocurrency")}</div>
            {renderCoins()}
          </div>
        </>
      );
    }
    if (country === "KZ") {
      return (
        <>
          <div className="payment-type-wrapper">
            <div className="title">{t("Transfer to card")}</div>
            <div className="payment-types">
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setIsP2pAiforyPayment(true);
                  setActiveType({
                    type: SystemType.aifory,
                    img: p2pGroupIcon,
                  });
                }}
              >
                <img src={p2pGroupIcon} alt="" />
                {getCardLimits(SystemType.aifory)}
              </div>
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.streampay, img: streamPay });
                }}
              >
                <img src={streamPay} alt="" />
                {getCardLimits(SystemType.streampay)}
              </div>
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.P2R_KZT, img: p2pIcon });
                }}
              >
                <img src={p2pIcon} alt="" />
                {getCardLimits(SystemType.P2R_KZT)}
              </div>
            </div>
          </div>
          <div className="payment-type-wrapper">
            <div className="title">{t("Payment systems")}</div>
            <div className="payment-types">
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.moneygo, img: moneyGo });
                }}
              >
                <img src={moneyGo} alt="" />
                {getCardLimits(SystemType.moneygo)}
              </div>
            </div>
          </div>
          <div className="payment-type-wrapper">
            <div className="title">{t("Cryptocurrency")}</div>
            {renderCoins()}
          </div>
        </>
      );
    }
    if (country === "IR") {
      return (
        <>
          <div className="payment-type-wrapper">
            <div className="title">{t("Bank transfer")}</div>
            <div className="payment-types">
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.kaptahPay, img: visaIcon });
                }}
              >
                <img src={visaIcon} alt="" />
                {getCardLimits(SystemType.kaptahPay)}
              </div>
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({
                    type: SystemType.kaptahPay,
                    img: masterIcon,
                  });
                }}
              >
                <img src={masterIcon} alt="" />
                {getCardLimits(SystemType.kaptahPay)}
              </div>
            </div>
          </div>
          {/* <div className="payment-type-wrapper">
            <div className="title">{t("Transfer to card")}</div>
            <div className="payment-types">
              <div
                className="payment-type-item"
                onClick={(): void => {
                  clearData();
                  setActiveType({ type: SystemType.streampay, img: streamPay });
                }}
              >
                <img src={streamPay} alt="" />
                {getCardLimits(SystemType.streampay)}
              </div>
            </div>
          </div> */}
          <div className="payment-type-wrapper">
            <div className="title">{t("Cryptocurrency")}</div>
            {renderCoins()}
          </div>
        </>
      );
    }
    // if (country === "UZ") {
    //   return (
    //     <>
    //       <div className="payment-type-wrapper">
    //         <div className="title">{t("Transfer to card")}</div>
    //         <div className="payment-types">
    //           <div
    //             className="payment-type-item"
    //             onClick={(): void => {
    //               clearData();
    //               setActiveType({ type: SystemType.Card6, img: visaIcon });
    //             }}
    //           >
    //             <img src={visaIcon} alt="" />
    //           </div>
    //           <div
    //             className="payment-type-item"
    //             onClick={(): void => {
    //               clearData();
    //               setActiveType({ type: SystemType.Card6, img: masterIcon });
    //             }}
    //           >
    //             <img src={masterIcon} alt="" />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="payment-type-wrapper">
    //         <div className="title">{t("Payment systems")}</div>
    //         <div className="payment-types">
    //           <div
    //             className="payment-type-item"
    //             onClick={(): void => {
    //               clearData();
    //               setActiveType({ type: SystemType.Card6, img: mirIcon });
    //             }}
    //           >
    //             <img src={mirIcon} alt="" />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="payment-type-wrapper">
    //         <div className="title">{t("Cryptocurrency")}</div>
    //         {renderCoins()}
    //       </div>
    //     </>
    //   );
    // }
    return (
      <>
        <div className="payment-type-wrapper">
          <div className="title">{t("Payment systems")}</div>
          <div className="payment-types">
            <div
              className="payment-type-item"
              onClick={(): void => {
                clearData();
                setActiveType({ type: SystemType.moneygo, img: moneyGo });
              }}
            >
              <img src={moneyGo} alt="" />
              {getCardLimits(SystemType.moneygo)}
            </div>
          </div>
        </div>
        <div className="payment-type-wrapper">
          <div className="title">{t("Cryptocurrency")}</div>
          {renderCoins()}
        </div>
      </>
    );
  };

  const renderCoinType = (): string => {
    if (cryptoCurrency?.type === "USDTE") {
      return "Tether ERC20";
    }
    if (cryptoCurrency?.type === "USDTT") {
      return "Tether TRC20";
    }
    if (cryptoCurrency?.type === "ETH") {
      return "Ethereum";
    }
    if (cryptoCurrency?.type === "BTC") {
      return "Bitcoin";
    }
    if (activeType?.type === "aifory") {
      return t("SBP");
    }
    return "Custom";
  };

  const renderTypeTitle = (): string => {
    if (
      activeType?.type === "P2R" ||
      activeType?.type === "P2R_KZT" ||
      activeType?.type === "Card6"
    ) {
      return t("bank cards");
    }
    if (
      activeType?.type === "YooMoney" ||
      activeType?.type === "Qiwi" ||
      activeType?.type === "moneygo"
    ) {
      return t("payment systems");
    }
    if (activeType?.type === "coinsPaid") {
      return t("cryptocurrency");
    }
    if (activeType?.type === "aifory") {
      return t("Bank card");
    }
    return "";
  };

  const renderCoinTxt = (): string => {
    if (cryptoCurrency?.type === "USDTE") {
      return "The wallet number you entered accepts transfers in the Tron (ERC-20) network. If you use another network, you may lose your money.";
    }
    if (cryptoCurrency?.type === "USDTT") {
      return "The wallet number you entered accepts transfers in the Tron (TRC-20) network. If you use another network, you may lose your money.";
    }
    if (cryptoCurrency?.type === "ETH") {
      return "The wallet number you entered accepts transfers in the Ethereum network. If you use another network, you may lose your money.";
    }
    if (cryptoCurrency?.type === "BTC") {
      return "The wallet number you entered accepts transfers in the Bitcoin network. If you use another network, you may lose your money.";
    }
    return "Custom";
  };

  const handleShowCopied1 = (): void => {
    setShowCopied1(true);
    if (showCopied1Timer) {
      clearTimeout(showCopied1Timer);
    }
    showCopied1Timer = setTimeout((): void => {
      setShowCopied1(false);
    }, 2000);
  };
  const handleShowCopied2 = (): void => {
    setShowCopied2(true);
    if (showCopied2Timer) {
      clearTimeout(showCopied2Timer);
    }
    showCopied2Timer = setTimeout((): void => {
      setShowCopied2(false);
    }, 2000);
  };

  const handleBackClick = (): void => {
    if (!activeType) {
      if (location.key === "default") {
        navigate("/");
      } else {
        navigate(-1);
      }
    } else {
      clearData();
    }
  };

  return (
    <div
      onClick={(e): void => {
        setShowKaptahAmounts(false);
      }}
      className="payments-page"
    >
      <div
        className={
          localeActive?.code === "fa"
            ? "payment-page-content rtl"
            : "payment-page-content"
        }
      >
        <div className="container">
          <div
            className="back-btn"
            onClick={(): void => {
              handleBackClick();
              updateCurrency();
            }}
          >
            <img src={arrowBackIcon} alt="" />
          </div>
          {!depositRequestSent && !depositHasError ? (
            <>
              {frameUrl && (
                <div className="frame-wrap">
                  <iframe
                    src={frameUrl}
                    frameBorder="0"
                    title="deposit"
                    allow="clipboard-read; clipboard-write"
                  />
                </div>
              )}
              <div className="modal-login-tabs">
                <div
                  className={
                    activeTab === Tabs.deposit ? "tab-item active" : "tab-item"
                  }
                  onClick={(): void => {
                    toggleTab(Tabs.deposit);
                  }}
                >
                  {t("Deposit")}
                </div>
                <div
                  className={
                    activeTab === Tabs.withdrawal
                      ? "tab-item active"
                      : "tab-item"
                  }
                  onClick={(): void => {
                    toggleTab(Tabs.withdrawal);
                  }}
                >
                  {t("Withdrawal")}
                </div>
                <div
                  className={
                    activeTab === Tabs.transactions
                      ? "tab-item active"
                      : "tab-item"
                  }
                  onClick={(): void => {
                    toggleTab(Tabs.transactions);
                  }}
                >
                  {t("Transactions")}
                </div>
              </div>
              {activeTab !== Tabs.transactions && (
                <div className="menu-balance-title">
                  <img src={balanceIcon} alt="" />
                  <div className="menu-balance-info">
                    <span>{t("Balance")}</span>
                    <span className="amount">
                      {balanceAmount}{" "}
                      {getSymbolFromCurrency(info.current_balance.code || "")}
                    </span>
                  </div>
                </div>
              )}
              {activeTab === Tabs.deposit &&
                !activeType &&
                (depositLimitsFetching ? (
                  <CirclesWithBar
                    height="150"
                    width="150"
                    color="rgb(248, 176, 0)"
                    wrapperStyle={{ justifyContent: "center" }}
                    visible
                    ariaLabel="circles-with-bar-loading"
                  />
                ) : (
                  <>{renderTypes()}</>
                ))}
              {activeTab === Tabs.deposit &&
                activeType &&
                activeType.type === "coinsPaid" && (
                  <div
                    className={
                      depositCoinsFetching
                        ? "coins-main-wrap fetching"
                        : "coins-main-wrap"
                    }
                  >
                    <div className="coins-header">
                      <div className="txt">
                        {t("Payment method:")} {renderTypeTitle()}
                      </div>
                    </div>
                    <div className="coins-main-info">
                      <div className="info">
                        <img src={cryptoCurrency?.img} alt="" />
                        <span>{renderCoinType()}</span>
                      </div>
                      {getLimitsCoins(
                        SystemType.coinsPaid,
                        cryptoCurrency?.type || ""
                      )}
                    </div>
                    <div className="coins-form">
                      <div className="row">
                        <div className="amount-box">
                          <div className="txt">
                            {t(
                              "Use the converter to calculate the required amount in cryptocurrency."
                            )}
                          </div>
                          <div className="timer">
                            {t("Relevance of the course:")}
                            <span>
                              {" "}
                              {timerValue} {t("sec")}
                            </span>
                          </div>
                          <div className="inputs-row">
                            <div className="deposit-input-row deposit-crypto-input-wrap">
                              <div className="deposit-input-wrap">
                                <DebounceInput
                                  debounceTimeout={1000}
                                  type="number"
                                  min="0"
                                  className="main-input"
                                  value={amountValue}
                                  onKeyDown={(e): void => {
                                    if (e.key === "-" || e.key === "e") {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(
                                    e: React.KeyboardEvent<HTMLInputElement>
                                  ): void => {
                                    e.currentTarget.blur();
                                  }}
                                  onChange={(e): void => {
                                    setAmountValue(e.target.value);
                                    getCoinsDepositInfo(
                                      cryptoCurrency?.type || "",
                                      SystemType.coinsPaid,
                                      e.target.value
                                    );
                                  }}
                                  disabled={depositCourseFetching}
                                />
                                <span>{getSymbolFromCurrency(currency)}</span>
                              </div>
                            </div>

                            <span className="separator">≈</span>
                            <div className="crypto-input-wrap">
                              <input
                                type="text"
                                className="main-input crypto-input"
                                value={`${
                                  (currencyCourse?.crypto_amount as string) ||
                                  ""
                                } ${
                                  (currencyCourse?.crypto_currency as string) ||
                                  ""
                                }`}
                                disabled
                              />
                              {showCopied1 && (
                                <div className="copied-notif">
                                  <img src={notifIcon} alt="" />
                                  <span>{t("Copied")}</span>
                                </div>
                              )}
                              <CopyToClipboard
                                text={currencyCourse?.crypto_amount || ""}
                                onCopy={(): void => {
                                  handleShowCopied1();
                                }}
                              >
                                <div className="copy-btn">
                                  <img src={copyIcon} alt="" />
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="coins-address">
                        <span>{t("Deposit address")}</span>
                        <div className="address-box">
                          <div className="txt">{depositWallet}</div>
                          {showCopied2 && (
                            <div className="copied-notif">
                              <img src={notifIcon} alt="" />
                              <span>{t("Copied")}</span>
                            </div>
                          )}
                          <CopyToClipboard
                            text={depositWallet}
                            onCopy={(): void => {
                              handleShowCopied2();
                            }}
                          >
                            <div className="copy-btn">
                              <img src={copyIcon} alt="" />
                            </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                    <div className="coins-qr">
                      {depositWallet && (
                        <img
                          src={`https://chart.googleapis.com/chart?cht=qr&chl=${depositWallet}&choe=UTF-8&chs=140x140`}
                          alt="qr"
                        />
                      )}
                    </div>
                    {depositError && (
                      <div className="error-msg">{depositError}</div>
                    )}
                    <div className="coins-txt">{t(renderCoinTxt())}</div>
                  </div>
                )}
              {activeTab === Tabs.deposit &&
                activeType &&
                activeType.type === SystemType.kaptahPay &&
                kaptahAmounts !== undefined && (
                  <div className="kaptah-payment-wrap">
                    <div className="payment-body">
                      <div className="payment-types selected">
                        <div className="payment-type-item">
                          <span>
                            {t("Payment method:")} {renderTypeTitle()}
                          </span>
                          <img src={activeType?.img || ""} alt="" />
                          {getLimits(activeType.type)}
                        </div>
                      </div>
                      <div className="kaptah-amount">
                        <p
                          className={`arrow ${
                            showKaptahAmounts ? "rotate" : ""
                          }`}
                          onClick={(e): void => {
                            e.stopPropagation();
                            setShowKaptahAmounts(!showKaptahAmounts);
                          }}
                        >
                          {amountValue}
                        </p>
                        {showKaptahAmounts && (
                          <ul className="kaptah-amounts-list">
                            {kaptahAmounts.data.items.map((item) => {
                              return (
                                <li
                                  key={item.amount}
                                  onClick={(): void => {
                                    getKaptahCourse(
                                      item.amount.toString(),
                                      item.displayTitle
                                    );
                                  }}
                                  value={item.amount}
                                >
                                  {item.displayTitle}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                      {depositError && (
                        <div className="error-msg">{depositError}</div>
                      )}
                      <button
                        type="button"
                        className="main-btn"
                        onClick={(): void => {
                          sendDepositRequest();
                        }}
                      >
                        {t("Confirm")}
                      </button>
                    </div>
                  </div>
                )}
              {activeTab === Tabs.deposit &&
                activeType &&
                activeType.type !== "coinsPaid" &&
                activeType.type !== "kaptahpay" && (
                  <div className="deposit-amount-form">
                    <div className="payment-types selected">
                      <div className="payment-type-item">
                        <span>
                          {t("Payment method:")} {renderTypeTitle()}
                        </span>
                        <img src={activeType?.img || ""} alt="" />
                        {getLimits(activeType.type)}
                      </div>
                    </div>
                    <div className="deposit-input-row">
                      <div className="form-title">
                        {t("Enter the amount you want to top up")}
                      </div>
                      <div className="deposit-input-wrap">
                        <input
                          type="number"
                          min="0"
                          className="main-input"
                          value={amountValue}
                          placeholder={t("Amount") || "Amount"}
                          onKeyDown={(e): void => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e): void => {
                            e.currentTarget.blur();
                          }}
                          onChange={(e): void => {
                            setAmountValue(e.target.value);
                            setDepositError("");
                            if (
                              activeType.type === "moneygo" ||
                              activeType.type === "streampay"
                            ) {
                              setValidateReplenishmentValue(
                                validateMinMaxLimit(minMaxLimit, e.target.value)
                              );
                            }
                          }}
                        />
                        <span className="currency-symbol">
                          {currencySymbol}
                        </span>
                      </div>
                      {(activeType.type === "moneygo" ||
                        activeType.type === "streampay") &&
                        !validateReplenishmentValue.isValid && (
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              fontSize: "12px",
                              left: "10px",
                            }}
                          >
                            {validateReplenishmentValue.message}
                          </span>
                        )}
                    </div>
                    <div className="form-title small">
                      {t("Recommended amounts")}
                    </div>
                    <div className="deposit-amounts">
                      {recomentedAmounts.map((el) => (
                        <div
                          className={
                            amountValue === el
                              ? "deposit-amount-item active"
                              : "deposit-amount-item"
                          }
                          onClick={(): void => {
                            setAmountValue(el);
                            setValidateReplenishmentValue(
                              validateMinMaxLimit(minMaxLimit, el)
                            );
                            setDepositError("");
                          }}
                        >
                          {el}
                        </div>
                      ))}
                    </div>
                    {activeType?.type === "moneygo" && (
                      <div className="payment-card-number">
                        <div>
                          <p>{t("Card Number")} Money Go</p>
                        </div>
                        <input
                          type="text"
                          className="main-input"
                          value={moneygoCard}
                          placeholder={t("Number") || "Card Number"}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => {
                            handleCardNumberChange(e);
                            setDepositError("");
                          }}
                        />
                      </div>
                    )}
                    {depositError && (
                      <div className="error-msg">{depositError}</div>
                    )}
                    <button
                      type="button"
                      className="main-btn"
                      onClick={handleSubmit}
                      disabled={
                        activeType.type === "moneygo" ||
                        activeType.type === "streampay"
                          ? !validateReplenishmentValue.isValid ||
                            depositFetching ||
                            !amountValue
                          : depositFetching
                      }
                    >
                      {t("Confirm")}
                    </button>
                  </div>
                )}
              {activeTab === Tabs.withdrawal && (
                <div className="deposit-amount-form withdrawal-main-wrap">
                  <div>
                    <div className="card-withdrawal-title">
                      {t("Enter bank card details")}
                    </div>
                    <div className="card-withdrawal-info">
                      <StatusDocument preview={currentBankCard} />
                      <CreditCardInputs
                        withdrawalCard={withdrawalCard}
                        setWithdrawalCard={(value): void =>
                          setWithdrawalCard(value)
                        }
                        withdrawalMM={withdrawalMM}
                        setWithdrawalMM={(value): void =>
                          setWithdrawalMM(value)
                        }
                        withdrawalYY={withdrawalYY}
                        setWithdrawalYY={(value): void =>
                          setWithdrawalYY(value)
                        }
                        handleSelectImg={handleSelectImg}
                        isFetching={isFetching}
                        sendCreditCard={(): void => {
                          handleUpdateCard();
                        }}
                        cardImg={cardImg}
                        currentBankCard={currentBankCard}
                        setGlobalError={setGlobalError}
                      />
                    </div>
                  </div>
                  {/* temporarily solutions, do not delete the code */}
                  {/* <div
                    className="withdrawal-card-info"
                  >
                    <div className="title">
                      {t("Specify the card you want to withdraw to")}
                    </div>
                    <input
                      type="text"
                      className="main-input card-numbner"
                      value={withdrawalCard}
                      placeholder={t("Card number") || "Card number"}
                      onChange={(el): void => {
                        setWithdrawalCard(el.target.value);
                      }}
                    />
                    <div className="row">
                      <input
                        type="text"
                        className="main-input"
                        value={withdrawalMM}
                        placeholder={t("Month") || "Month"}
                        onChange={(el): void => {
                          setWithdrawalMM(el.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className="main-input"
                        value={withdrawalYY}
                        placeholder={t("Year") || "Year"}
                        onChange={(el): void => {
                          setWithdrawalYY(el.target.value);
                        }}
                      />
                      <button
                        className="main-btn"
                        type="button"
                        onClick={(): void => {
                          handleUpdateCard();
                        }}
                        disabled={cardFetching || withdrawalFetching}
                      >
                        {defaultCard ? t("Change") : t("Confirm")}
                      </button>
                    </div>
                  </div> */}
                  <div className="withdrawal-amount">
                    <div className="title">
                      {t("Enter the amount you want to withdraw")}
                    </div>
                    <input
                      type="number"
                      min="0"
                      className="main-input"
                      value={withdrawalAmount}
                      placeholder={t("Amount") || "Amount"}
                      onKeyDown={(e): void => {
                        if (e.key === "-" || e.key === "e") {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e): void => {
                        e.currentTarget.blur();
                      }}
                      onChange={(e): void => {
                        setWithdrawalAmount(e.target.value);
                      }}
                    />
                  </div>
                  {withdrawalError && (
                    <div className="error-msg">{withdrawalError}</div>
                  )}
                  <button
                    type="button"
                    className="main-btn withdrawal-form-btn"
                    disabled={
                      !withdrawalAmount ||
                      withdrawalFetching ||
                      !currentBankCard ||
                      currentBankCard.status === "Declined"
                    }
                    onClick={(): void => {
                      sendWithdrawalRequest();
                    }}
                  >
                    {t("WITHDRAW")}
                  </button>
                </div>
              )}
              {activeTab === Tabs.transactions && <TransactionsTable />}
            </>
          ) : (
            <div>
              {depositHasError ? (
                <div className="deposit-success-mesasge deposit-error-mesasge">
                  <img src={notOkkIcon} alt="" />
                  <div className="title">{t("Your payment failed!")}</div>
                  <div className="txt">
                    <p>{t("depError1")}</p>
                    <p>{t("depError2")}</p>
                  </div>
                  <button
                    type="button"
                    className="main-btn"
                    onClick={(): void => {
                      handleClearDepositInfo();
                    }}
                  >
                    {t("Back to payments")}
                  </button>
                  <button
                    type="button"
                    className="border-btn"
                    onClick={(): void => {
                      navigate("/");
                    }}
                  >
                    {t("To main")}
                  </button>
                </div>
              ) : (
                <div className="deposit-success-mesasge">
                  <img src={okIcon} alt="" />
                  <div className="title">
                    {t("Your payment has been successfully completed!")}
                  </div>
                  <div className="txt">
                    {t(
                      "BOOM casino wishes you a successful game and have a good time on the site."
                    )}
                  </div>
                  <button
                    type="button"
                    className="main-btn"
                    onClick={(): void => {
                      navigate("/");
                    }}
                  >
                    {t("To main")}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {withdrawalGlobalError && renderError(withdrawalGlobalError)}
      {withdrawalSuccess && (
        <SuccessModal
          mainTxt={t("Withdrawal request successfully sent")}
          closeModalHandler={(): void => {
            setWithdrawalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default PaymentsPage;

// {
//  <div className="kaptah-payment-wrap">
//   <div className="payment-title">
//     {t("Relevant exchange course")}
//   </div>
//   <div className="payment-body">
//     <div className="payment-types selected">
//       <div className="payment-type-item">
//         <span>
//           {t("Payment method:")} {renderTypeTitle()}
//         </span>
//         <img src={activeType?.img || ""} alt="" />
//         {getLimits(activeType.type)}
//       </div>
//     </div>
//     <div className="inputs-row">
//       <div className="payment-input-wrap">
//         <DebounceInput
//           // debounceTimeout={1000}
//           type="number"
//           min="0"
//           className="main-input"
//           value={amountValue}
//           onKeyDown={(e): void => {
//             if (e.key === "-" || e.key === "e") {
//               e.preventDefault();
//             }
//           }}
//           onWheel={(
//             e: React.KeyboardEvent<HTMLInputElement>
//           ): void => {
//             e.currentTarget.blur();
//           }}
//           onChange={(e): void => {
//             setAmountValue(e.target.value);
//             if (kaptahCourseDelay) {
//               clearTimeout(kaptahCourseDelay);
//             }
//             getKaptahCourse(e.target.value);
//           }}
//           disabled={depositCourseFetching}
//         />
//         <span>{getSymbolFromCurrency("irr")}</span>
//       </div>

//       <span className="separator">=</span>
//       <div className="payment-input-wrap">
//         <input
//           type="text"
//           className="main-input kaptah-got-input"
//           value={`${kaptahCourse?.amount || ""}`}
//           disabled
//         />
//         <span>{getSymbolFromCurrency("usd")}</span>
//       </div>
//     </div>
//     <div className="deposit-amounts">
//       {recomentedAmounts.map((el) => (
//         <div
//           className={
//             amountValue === el
//               ? "deposit-amount-item active"
//               : "deposit-amount-item"
//           }
//           onClick={(): void => {
//             setAmountValue(el);
//             setValidateReplenishmentValue(
//               validateMinMaxLimit(minMaxLimit, el)
//             );
//             getKaptahCourse(el);
//             setDepositError("");
//           }}
//         >
//           {el}
//         </div>
//       ))}
//     </div>
//     {depositError && (
//       <div className="error-msg">{depositError}</div>
//     )}
//     <button
//       type="button"
//       className="main-btn"
//       onClick={handleSubmit}
//       disabled={
//         !validateReplenishmentValue.isValid ||
//         depositFetching ||
//         !amountValue ||
//         !kaptahCourse?.amount
//       }
//     >
//       {t("Confirm")}
//     </button>
//   </div>
// </div>
// }
