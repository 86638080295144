// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainSliderStyles_main_slider_wrap__SB-oN {\n  position: relative;\n  z-index: 3;\n  max-width: 1280px;\n  margin: 0 auto;\n  padding-bottom: 0px;\n  padding-top: 8px;\n  margin-bottom: 10px;\n}\n\n@media (max-width: 560px) {\n  .MainSliderStyles_main_slider_wrap__SB-oN {\n    padding-top: 155px;\n    margin-bottom: 160px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/sliders/MainSlider/MainSliderStyles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,kBAAA;IACA,oBAAA;EACF;AACF","sourcesContent":[".main_slider_wrap {\n  position: relative;\n  z-index: 3;\n  max-width: 1280px;\n  margin: 0 auto;\n  padding-bottom: 0px;\n  padding-top: 8px;\n  margin-bottom: 10px;\n}\n\n@media (max-width: 560px) {\n  .main_slider_wrap {\n    padding-top: 155px;\n    margin-bottom: 160px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_slider_wrap": "MainSliderStyles_main_slider_wrap__SB-oN"
};
export default ___CSS_LOADER_EXPORT___;
