/* eslint-disable */
import React, { useEffect } from "react";

const GoogleTagManagerRU: React.FC = () => {
  useEffect(() => {
    (function (w: any, d: any, s: any, l:any, i: any) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l != "dataLayer" ? `&l=${l}` : "";
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-W6FX6TV");
  }, []);

  return null;
};

export default GoogleTagManagerRU;