import React from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import { NavLink } from "react-router-dom";
import promoIcon1 from "../../../images/profile-icons/promo-code-icon-1.svg";
import promoIcon2 from "../../../images/profile-icons/promo-code-icon-2.svg";
import promoIcon3 from "../../../images/profile-icons/promo-code-icon-3.svg";
import { PromoCodeData } from "../../../types/promoTypes";
import Timer from "../../UI/Timer/Timer";
import styles from "./PromoCodeTabStyles.module.scss";
import MainButton from "../../UI/MainButton/MainButton";

interface DepositPromoCodeType {
  promoCodeData: PromoCodeData;
  onClickDeactivatePromoCode: () => void;
  onClickGetBonus: () => void;
}

const DepositPromoCodeView: React.FC<DepositPromoCodeType> = ({
  promoCodeData,
  onClickDeactivatePromoCode,
  onClickGetBonus,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {promoCodeData.is_used && (
        <p className={styles.active_promo_code}>{t("Active promo code")}</p>
      )}
      <div className={styles.promo_card_main}>
        <h5>{promoCodeData?.promocode?.code}</h5>
        <div className={styles.promo_card_content}>
          {!!promoCodeData?.promocode?.deposit_current_amount && (
            <div
              className={classNames(
                styles.promo_card_elem,
                styles.fancy_border_btm
              )}
            >
              <img
                src={promoIcon1}
                alt="promoIcon1"
                className={styles.promo_card_img}
              />
              <h4>
                {promoCodeData.promocode?.deposit_current_amount}%{" "}
                {t("deposit")}
              </h4>
              <p>{t("replenishing")}</p>
            </div>
          )}
          {!!promoCodeData?.promocode?.cashback?.amount && (
            <div
              className={classNames(
                styles.promo_card_elem,
                styles.fancy_border_btm
              )}
            >
              <img
                src={promoIcon2}
                alt="promoIcon3"
                className={styles.promo_card_img}
              />
              <h4>
                {promoCodeData.promocode?.cashback.amount}% {t("cashback")}
              </h4>
              <p>{t("amount lost")}</p>
            </div>
          )}
          {promoCodeData.promocode?.freespin && (
            <div
              className={classNames(
                styles.promo_card_elem,
                styles.fancy_border_btm
              )}
            >
              <img
                src={promoIcon3}
                alt="promoIcon3"
                className={styles.promo_card_img}
              />
              <h4>
                {promoCodeData.promocode?.freespins_count} {t("Freespins")}
              </h4>
              <p>{promoCodeData.promocode.freespin.description}</p>
            </div>
          )}
        </div>
        {!promoCodeData.is_used && (
          <>
            <div className={styles.info_bonus}>
              {t("Bonus has not yet been received!")}
              <div>{t("Click the button below to receive the bonus.")}</div>
            </div>
            <div className={styles.promo_card_btn}>
              <MainButton
                onClick={onClickGetBonus}
                title={t("Get Bonus")}
                className={styles.promo_control_btn}
                color="primary"
                variant="contained"
              />
            </div>
          </>
        )}
        {promoCodeData.is_used && (
          <div className={styles.info_bonus}>
            {t("Bonus received")}
            <div>{t("Let's start the game")}</div>
          </div>
        )}
        <Timer
          countdownInMilliseconds={promoCodeData.countdown_in_milliseconds || 0}
        />
        <div className={styles.details_promocode}>
          {t("Details promocode")}
          <div className={styles.details_promocode_text}>
            {promoCodeData.promocode.description}
          </div>
        </div>
        <div className={styles.promo_card_btn}>
          <MainButton
            onClick={onClickDeactivatePromoCode}
            title={t("decline promocode")}
            className={styles.promo_control_btn}
            color="secondary"
            variant="outlined"
          />
        </div>

        <div className={styles.promo_card_footer}>
          <p>
            {t("For more info")}{" "}
            <NavLink to="/bonus-info">{t("Bonuses and Terms")}</NavLink>
          </p>
        </div>
      </div>
    </>
  );
};

export default DepositPromoCodeView;
