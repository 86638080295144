import React, { useEffect, useState } from "react";
import { Table, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  formatDateDayApi,
  formatDateMinutes,
} from "../../../helpers/getCorrectDate";

import { getWithdrawals } from "../../../services/admin/apiReporting";
import { CurrencyItem } from "../../../types/currencyTypes";
import { WithdrawalReportingItem } from "../../../types/paymentTypes";
import { getCurrencyList } from "../../../services/apiCurrency";

const withdrawalStatuses: { id: number; name: string }[] = [
  { id: 88, name: "all" },
  { id: 0, name: "PENDING" },
  { id: 1, name: "PROCESSING" },
  { id: 2, name: "COMPLETED" },
  { id: 3, name: "DECLINED" },
  { id: 4, name: "BLOCKED" },
  { id: 5, name: "NOT_ENOUGH_MONEY" },
];

const Withdrawals: React.FC = () => {
  const [activeStatus, setActiveStatus] = useState<number>(88);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  // Deposits data
  const [withdrawals, setWithdrawals] = useState<WithdrawalReportingItem>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(2);

  const { t } = useTranslation();

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  //   Withdrawals logic
  const loadWithdrawals = async (search?: string): Promise<void> => {
    setFetching(true);
    const result = await getWithdrawals(activeStatus, selectedCurrency, search);
    if (typeof result !== "string") {
      setWithdrawals(result.data as WithdrawalReportingItem);
    }
    setFetching(false);
  };
  useEffect(() => {
    loadWithdrawals();
    // eslint-disable-next-line
  }, []);

  const handleClearSearch = (): void => {
    setStartDate(undefined);
    setEndDate(undefined);
    setActiveStatus(88);
    loadWithdrawals();
  };
  const handleSearch = (): void => {
    const searchValue = `${
      startDate ? `&start_date=${formatDateDayApi(startDate)}` : ""
    }${endDate ? `&end_date=${formatDateDayApi(endDate)}` : ""}`;
    loadWithdrawals(searchValue);
  };

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>{t("Filters")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Start date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setStartDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={startDate}
                      dropdownMode="select"
                      placeholderText={t("Start date") || "Start date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("End date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setEndDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={endDate}
                      dropdownMode="select"
                      placeholderText={t("End date") || "End date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Status")}</Form.Label>
                    <Form.Select
                      aria-label={t("Status") || "Status"}
                      value={activeStatus}
                      onChange={(e): void => {
                        setActiveStatus(parseInt(e.target.value as string, 10));
                      }}
                      data-testid="withdrawals-report-status"
                    >
                      {withdrawalStatuses?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Currency")}</Form.Label>
                    <Form.Select
                      aria-label={t("Currency") || "Currency"}
                      value={selectedCurrency}
                      onChange={(e): void => {
                        setSelectedCurrency(
                          parseInt(e.target.value as string, 10)
                        );
                      }}
                      data-testid="bets-reporting-currency"
                    >
                      {currencyList &&
                        currencyList.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.code}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div className="admin-search-btns">
                    <Button
                      variant="danger"
                      onClick={handleClearSearch}
                      disabled={isFetching}
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isFetching}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {!isFetching && withdrawals && (
        <>
          <h5>{t("Withdrawals stats")}</h5>
          <div className="deposits-stats-table">
            <Table
              striped
              bordered
              className="mb-3"
              responsive
              data-testid="withdrawals-stats-table"
            >
              <thead>
                <tr>
                  <th>{t("Quantity")}</th>
                  <th>{t("Total amount")}</th>
                  <th>{t("Min amount")}</th>
                  <th>{t("Max amount")}</th>
                  <th>{t("Avarage amount")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{withdrawals.count}</td>
                  <td>{withdrawals.sum}</td>
                  <td>{withdrawals.min}</td>
                  <td>{withdrawals.max}</td>
                  <td>{withdrawals.avg}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <h5>{t("Withdrawals list")}</h5>
          <Table
            striped
            bordered
            className="mb-5"
            responsive
            data-testid="withdrawals-list-table"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("Date")}</th>
                <th>{t("Amount")}</th>
                <th>{t("Requisites")}</th>
                <th>{t("Status")}</th>
              </tr>
            </thead>
            <tbody>
              {withdrawals.deposits.map((el) => (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{formatDateMinutes(el.created_at as string)}</td>
                  <td>{el.amount}</td>
                  <td>{el.address}</td>
                  <td>
                    {
                      withdrawalStatuses.filter(
                        (item) => item.id === el.status
                      )[0].name
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default Withdrawals;
