import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import useWebSocket from "react-use-websocket";
import { CirclesWithBar } from "react-loader-spinner";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";

import {
  setUserToken,
  loggedAction,
  setUserData,
  updateUserBalance,
  getLocalesList,
  setActiveLocal,
  updateUserTournaments,
  updateAllBalances,
} from "./store/slices/userData";
import {
  getUserData,
  tokenRefresh,
  loginViaToken,
  logout,
  // updateUserData,
} from "./services/apiLogin";
import type { RootState, AppDispatch } from "./store/store";
import { LocaleItem, BalanceItem } from "./types/userTypes";
import { HomeBetItem } from "./types/betsTypes";

// Components
// import HomePage from "./pages/Home";
import GamePage from "./pages/Game";
import AllTournamentsPage from "./pages/AllTournamentsPage";
import TournamentPage from "./pages/TournamentPage";
import RulesPage from "./pages/contentPages/Rules";
import AboutUs from "./pages/contentPages/AboutUs";
import WithdrawalRefundPolicy from "./pages/contentPages/WithdrawalRefundPolicy";
import BonusInfo from "./pages/contentPages/BonusInfo";
import Privacy from "./pages/contentPages/Privacy";
import FAQ from "./pages/contentPages/FAQ";
// import VipClub from "./pages/VipClub";
import MyBonuses from "./pages/MyBonuses";
import BonusPage from "./pages/BonusPage";
import AdminPanel from "./pages/AdminPanel";
import LoginPage from "./pages/LoginPage";
import PaymentResult from "./pages/PaymentResultPage/PaymentResult";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import PaymentsPage from "./pages/PaymentsPage";
import ProfilePage from "./pages/ProfilePage";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import MobileMenu from "./components/MobileMenu";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import WelcomeBonusPage from "./pages/WelcomeBonusPage/WelcomeBonusPage";
// Modals
import GameErrorModal from "./components/modals/GameErrorModal";
import RewardModal from "./components/modals/RewardModal";
import UserBlockedModal from "./components/modals/UserBlockedModal";
import BonusModal from "./components/modals/BonusModal";
// Styles
import "./styles/App.scss";
import "./styles/Responsive.scss";
import Modal from "./components/UI/Modal/Modal";
import ConfirmPersonalDataModal from "./components/modals/ProfilePageModal/ComfirmPersonalDataModal";
import SlotsPage from "./pages/CategoriesPage/SlotsPage";
import CardsPage from "./pages/CategoriesPage/CardsPage";
import HomePageLayout from "./components/HomePage/HomePageLayout/HomePageLayout";

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();

  const [isPageReady, setPageReady] = useState<boolean>(false);
  const [isProfileModalShow, setProfileModalShow] = useState<boolean>(false);
  const [isDepositModalShow, setDepositModalShow] = useState<boolean>(false);
  const [isRewardModalShow, setRewardModalShow] = useState<boolean>(false);
  const [isBonusModalShow, setBonusModalShow] = useState<boolean>(false);
  const [isBlockShow, setBlockShow] = useState<boolean>(false);
  const [hasGameError, setGameError] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [newBets, setNewBets] = useState<HomeBetItem[]>([]);
  const [depositRequestSent, setDepositRequestSent] = useState<boolean>(false);
  const [depositHasError, setDepositHasError] = useState<boolean>(false);
  // Rakeback
  const [currentAmountRakeback, setCurrentAmountRakeback] = useState<number>();
  const [totalAmountRakeback, setTotalAmountRakeback] = useState<number>();
  const [profitRakeback, setProfitRakeback] = useState<number>();
  const [openPersonalDataModal, setOpenPersonalDataModal] =
    useState<boolean>(false);

  const publicId = useSelector(
    (state: RootState) => state.user.userInfo.public_id
  );
  const userId = useSelector((state: RootState) => state.user.userInfo.id);
  const isDataFetched = useSelector(
    (state: RootState) => state.user.isDataFetched
  );
  const tournaments = useSelector(
    (state: RootState) => state.user.userInfo.tournaments
  );
  const bonuses = useSelector(
    (state: RootState) => state.user.userInfo.welcome_bonuses
  );
  // const currentBalanceStore = useSelector(
  //   (state: RootState) => state.user.userInfo.current_balance
  // );

  const { lastMessage, sendMessage } = useWebSocket(
    process.env.REACT_APP_SOCKET_URL as string,
    {
      onClose: () => console.log("Socket close"),
      onOpen: () => {
        console.log("Socket open");
        if (isDataFetched && publicId) {
          sendMessage(
            JSON.stringify({
              type: "AttemptConnection",
              data: { user_id: publicId },
            })
          );
        }
      },
      onError: () => console.log("Socket error"),
      shouldReconnect: () => true,
    },
    !!isDataFetched && !!publicId
  );

  // Balance logic
  // const handleBalanceChange = async (): Promise<void> => {
  //   const result = await updateUserData({ balance_type: "bonus" });
  //   if (typeof result !== "string") {
  //     dispatch(setUserData(result.user));
  //   }
  // };
  // const checkBalance = (balance: number): void => {
  //   if (currentBalanceStore.type === "real") {
  //     if (currentBalanceStore.code === "USD" && balance < 1) {
  //       handleBalanceChange();
  //     }
  //     if (currentBalanceStore.code === "RUB" && balance < 10) {
  //       handleBalanceChange();
  //     }
  //     if (currentBalanceStore.code === "KZT" && balance < 40) {
  //       handleBalanceChange();
  //     }
  //   }
  // };

  // Deposit logic
  const handleClearDepositInfo = (): void => {
    setDepositRequestSent(false);
    setDepositHasError(false);
  };

  // Modals logic
  const toggleProfileModal = (): void => {
    setProfileModalShow(!isProfileModalShow);
    document.body.classList.toggle("no-scroll");
  };
  // const hideProfileModal = (): void => {
  //   setProfileModalShow(false);
  //   document.body.classList.remove("no-scroll");
  // };
  const toggleDepositModal = (): void => {
    setDepositModalShow(!isDepositModalShow);
    document.body.classList.toggle("no-scroll");
  };
  const hideGameError = (): void => {
    setGameError(false);
  };
  const rewardConfirmHandler = (): void => {
    if (!tournaments || tournaments.length === 1) {
      setRewardModalShow(false);
      document.body.classList.remove("no-scroll");
    }
    dispatch(updateUserTournaments());
  };
  const showBlockModal = (): void => {
    setBlockShow(true);
    document.body.classList.add("no-scroll");
  };
  const hideBlockModal = (): void => {
    setBlockShow(false);
    document.body.classList.remove("no-scroll");
    dispatch(loggedAction(false));
    window.location.replace("/");
  };
  // const showBonusModal = (): void => {
  //   setBonusModalShow(true);
  //   document.body.classList.add("no-scroll");
  // };
  const hideBonusModal = (): void => {
    setBonusModalShow(false);
    document.body.classList.remove("no-scroll");
    const userApprovedBonus = localStorage.getItem("userBonusIds");
    localStorage.setItem(
      "userBonusIds",
      userApprovedBonus
        ? JSON.stringify([...JSON.parse(userApprovedBonus), userId])
        : JSON.stringify([userId])
    );
    if (bonuses) {
      window.location.replace(`/bonus/${bonuses[0].bonus.id as number}`);
    }
  };

  // Check welkome bonus
  // useEffect(() => {
  //   const userApprovedBonus = localStorage.getItem("userBonusIds");
  //   if (
  //     bonuses &&
  //     bonuses.length > 0 &&
  //     (!userApprovedBonus || !JSON.parse(userApprovedBonus).includes(userId))
  //   ) {
  //     showBonusModal();
  //   }
  //   // eslint-disable-next-line
  //   }, [bonuses]);

  // Tournaments notif
  useEffect(() => {
    if (tournaments && tournaments.length > 0 && !isRewardModalShow) {
      setRewardModalShow(true);
      document.body.classList.add("no-scroll");
    }
    // eslint-disable-next-line
  }, [tournaments]);

  // Login logic
  const handleLogin = async (): Promise<void> => {
    const result = await getUserData();
    if (typeof result === "string") {
      dispatch(loggedAction(false));
      await logout();
      return;
    }
    dispatch(setUserData(result.user));
    if (result.user.locale) {
      dispatch(setActiveLocal(result.user.locale as LocaleItem));
      i18n.changeLanguage(result.user.locale.code as string);
      localStorage.setItem("localeLang", result.user.locale.code);
    }
    dispatch(loggedAction(true));
  };
  const checkToken = async (
    tokenDate: string,
    token: string,
    tokenExpire: string | null
  ): Promise<void> => {
    if (
      // eslint-disable-next-line
      moment(new Date()).diff(JSON.parse(tokenDate as string), "minutes") >=
      parseInt(tokenExpire || "0", 10) - 10
    ) {
      const result = await tokenRefresh();
      if (typeof result === "string") {
        dispatch(loggedAction(false));
        await logout();
        return;
      }
      dispatch(setUserToken(result.original.access_token as string));
      await handleLogin();
    } else {
      dispatch(setUserToken(token));
      await handleLogin();
    }
  };
  const loginWithToken = async (): Promise<void> => {
    const result = await loginViaToken(window.location.search.split("=")[1]);
    if (typeof result !== "string") {
      dispatch(setUserToken(result.access_token as string));
      await handleLogin();
      if (window.location.pathname.includes("profile")) {
        window.location.replace(`/profile?isMailConfirmed`);
      } else {
        window.location.replace("/");
      }
    }
  };
  // Socket logic
  const listenSocketConnection = (): void => {
    const mesasage = lastMessage?.data
      ? JSON.parse(lastMessage?.data as string)
      : null;
    if (mesasage && mesasage.balance !== undefined) {
      const balanceString: string = mesasage.balance.toString();
      // checkBalance(mesasage.balance as number);
      dispatch(
        updateUserBalance(
          parseFloat(
            `${balanceString.slice(0, -2)}.${balanceString.substr(
              balanceString.length - 2
            )}`
          )
        )
      );
    }
    if (mesasage && mesasage.balances) {
      dispatch(updateAllBalances(mesasage.balances as BalanceItem[]));
    }
    if (mesasage && mesasage.errorCode && mesasage.errorCode === 100) {
      setGameError(true);
    }
    if (mesasage && mesasage.type === "UserBlocking") {
      setReason(mesasage.data.data.reason as string);
      showBlockModal();
    }
    if (Array.isArray(mesasage) && !!mesasage[0].gameFullName) {
      setNewBets(mesasage);
    }
    if (
      mesasage &&
      (mesasage.current_bets_amount || mesasage?.current_bets_amount === 0) &&
      !mesasage.is_win
    ) {
      setCurrentAmountRakeback(mesasage.current_bets_amount as number);
      setProfitRakeback(undefined);
    }
    if (mesasage && mesasage.rakeback_amount && !mesasage.is_win) {
      setTotalAmountRakeback(mesasage.rakeback_amount as number);
      setProfitRakeback(undefined);
    }
    if (mesasage && (mesasage.rakeback_profit || mesasage?.is_win === false)) {
      setProfitRakeback((mesasage?.rakeback_profit as number) || 1);
      setCurrentAmountRakeback(0);
    }
    if (mesasage && mesasage?.deposit?.payment_system === "moneygo") {
      window.location.href = "/";
    }
    if (
      mesasage &&
      mesasage?.deposit?.status &&
      mesasage?.deposit?.status === "ok"
    ) {
      setDepositRequestSent(true);
    }
    if (
      mesasage &&
      mesasage?.deposit?.status &&
      mesasage?.deposit?.status === "failed"
    ) {
      setDepositRequestSent(true);
      setDepositHasError(true);
    }
  };
  // Init data
  useEffect(() => {
    listenSocketConnection();
    // eslint-disable-next-line
  }, [lastMessage]);

  const loadWidget = (): void => {
    // eslint-disable-next-line
    if (!window.hasOwnProperty("novaTalks")) {
      // @ts-ignore
      window.novaTalks = {
        websiteToken: "8QuGkqi4toPNtuuOS0cZfN",
        baseUrl: "https://bet-boom.cloud.novatalks.com.ua",
        isWidgetDynamic: true,
      };
      try {
        const htmlHead = document.getElementsByTagName("head")[0];
        const htmlBody = document.body;
        const ntkWidgetHolder = document.createElement("div");
        ntkWidgetHolder.setAttribute("id", "nova-talks");
        htmlBody.appendChild(ntkWidgetHolder);
        const ntkWidgetBaseURL =
          "https://storage.novatalks.ai/static/widget/v1";
        const ntkWidgetRes = [
          {
            type: "text/css",
            path: "/css/chunk-vendors.css",
          },
          {
            type: "text/css",
            path: "/css/widget.css",
          },
          {
            type: "text/javascript",
            path: "/js/chunk-vendors.js",
          },
          {
            type: "text/javascript",
            path: "/js/widget.js",
          },
        ];
        ntkWidgetRes.forEach((item) => {
          switch (item.type) {
            case "text/css":
              // eslint-disable-next-line no-case-declarations
              const ntkWidgetCSS = document.createElement("link");
              ntkWidgetCSS.setAttribute("type", "text/css");
              ntkWidgetCSS.setAttribute("rel", "stylesheet");
              ntkWidgetCSS.setAttribute(
                "href",
                ntkWidgetBaseURL.concat(item.path)
              );
              htmlHead.appendChild(ntkWidgetCSS);
              break;
            case "text/javascript":
              // eslint-disable-next-line no-case-declarations
              const ntkWidgetJS = document.createElement("script");
              ntkWidgetJS.setAttribute("type", "text/javascript");
              ntkWidgetJS.setAttribute("async", "false");
              ntkWidgetJS.setAttribute(
                "src",
                ntkWidgetBaseURL.concat(item.path)
              );
              htmlBody.appendChild(ntkWidgetJS);
              break;
            default:
              break;
          }
        });
      } catch (e) {
        console.log("Exception: ", e);
      }
    }
  };
  const loadInitData = async (): Promise<void> => {
    loadWidget();
    // eslint-disable-next-line
    const isLogged = await localStorage.getItem("isLogged");
    // eslint-disable-next-line
    const token = await localStorage.getItem("token");
    // eslint-disable-next-line
    const tokenDate = await localStorage.getItem("tokenDate");
    // eslint-disable-next-line
    const localLang = await localStorage.getItem("localeLang");
    // eslint-disable-next-line
    const tokenExpire = await localStorage.getItem("expires_in");
    if (isLogged && JSON.parse(isLogged) && token && tokenDate) {
      checkToken(tokenDate, token, tokenExpire);
    } else {
      dispatch(loggedAction(false));
    }
    if (localLang) {
      i18n.changeLanguage(localLang as string);
    }
    await dispatch(getLocalesList());
    if (
      window.location.search.includes("token") &&
      window.location.pathname !== "/reset-password"
    ) {
      window.location.replace(`/reset-password${window.location.search}`);
    }
    if (window.location.search.includes("clickid")) {
      if (window.location.pathname !== "/register")
        window.location.replace(`/register${window.location.search}`);
    }
    if (window.location.search.includes("oauth")) {
      loginWithToken();
      return;
    }
    setPageReady(true);
  };
  useEffect(() => {
    loadInitData();
    if (window.location.host === "boomcasino.bet") {
      const tagManagerArgs = {
        gtmId: "G-XR1BBHDEDN",
      };
      TagManager.initialize(tagManagerArgs);
      hotjar.initialize(3414577, 6);
    }
    // eslint-disable-next-line
  }, []);

  return isPageReady ? (
    <BrowserRouter>
      {/* Modals start */}
      {openPersonalDataModal && (
        <Modal open={openPersonalDataModal}>
          <ConfirmPersonalDataModal
            onClose={(): void => setOpenPersonalDataModal(false)}
          />
        </Modal>
      )}
      {isRewardModalShow && tournaments && (
        <RewardModal
          clickHandler={rewardConfirmHandler}
          item={tournaments[0]}
        />
      )}
      {isBlockShow && (
        <UserBlockedModal closeHandler={hideBlockModal} reason={reason} />
      )}
      {isBonusModalShow && bonuses && bonuses.length > 0 && (
        <BonusModal closeHandler={hideBonusModal} bonus={bonuses[0]} />
      )}
      {/* Modals end */}
      <Menu
        setOpenPersonalDataModal={(): void => setOpenPersonalDataModal(true)}
      />
      <MobileMenu
        setOpenPersonalDataModal={(): void => setOpenPersonalDataModal(true)}
      />
      <Routes>
        <Route element={<LoginPage withBonus />} path="/register" />
        <Route element={<LoginPage withBonus />} path="/login" />
        <Route element={<ResetPasswordPage />} path="/reset-password" />
        <Route element={<ProfilePage />} path="/profile" />
        <Route element={<ProfilePage />} path="/promocode" />
        <Route element={<ProfilePage />} path="/vip-club" />
        <Route element={<ProfilePage />} path="/documents" />
        <Route element={<ProfilePage />} path="/wallet" />
        <Route element={<PaymentResult />} path="/kaptahpay-callback" />
        <Route
          element={
            <PaymentsPage
              depositSuccess={depositRequestSent}
              depositErrorCallbek={depositHasError}
              showProfile={toggleProfileModal}
              handleClearDepositInfo={handleClearDepositInfo}
            />
          }
          path="/payments"
        />
        <Route element={<AdminPanel />} path="/admin-panel" />
        <Route element={<HomePageLayout newBets={newBets} />} path="/">
          {/* <Route index element={<HomePage />} path="/" /> */}
          <Route index element={<SlotsPage />} path="/slots" />
          <Route index element={<CardsPage />} path="/cards" />
        </Route>
        <Route element={<AllTournamentsPage />} path="/tournaments" />
        <Route element={<TournamentPage />} path="/tournament/:id" />
        <Route element={<RulesPage />} path="/rules" />
        <Route element={<AboutUs />} path="/about-us" />
        <Route
          element={<WithdrawalRefundPolicy />}
          path="/withdrawal-and-refund-policy"
        />
        <Route element={<BonusInfo />} path="/bonus-info" />
        <Route element={<Privacy />} path="/privacy" />
        <Route element={<FAQ />} path="/faq" />
        {/* <Route element={<VipClub />} path="/vip-club" /> */}
        <Route element={<MyBonuses />} path="/bonuses" />
        <Route element={<EmailVerificationPage />} path="/email-verification" />
        <Route element={<WelcomeBonusPage />} path="/welcome-bonus" />
        <Route
          element={<BonusPage showDeposit={toggleDepositModal} />}
          path="/bonus/:id"
        />
        <Route
          element={
            <GamePage
              currentAmount={currentAmountRakeback}
              totalAmount={totalAmountRakeback}
              profit={profitRakeback}
            />
          }
          path="/game/:id"
        />
      </Routes>
      <Footer />
      {hasGameError && <GameErrorModal closeModal={hideGameError} />}
      <ReactNotifications />
    </BrowserRouter>
  ) : (
    <div className="page-loader">
      <CirclesWithBar
        height="200"
        width="200"
        color="#D0174F"
        wrapperStyle={{ justifyContent: "center" }}
        visible
        ariaLabel="circles-with-bar-loading"
      />
    </div>
  );
};

export default App;
