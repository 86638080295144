import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";

import type { RootState } from "../../store/store";
import getBalanceSytring from "../../helpers/getBalanceString";

import bonusIcon from "../../images/wallet-bonus-icon.svg";
// import VipBar from "../listItems/VipBar";

const WalletInfo: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const balances = useSelector(
    (state: RootState) => state.user.userInfo.balances
  );
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  return (
    <div className="wallet-info-wrap">
      <div className="wallet-info-content">
        {/* <VipBar /> */}
        <div className="wallet-wrap">
          <div className="title">{t("Wallet")}</div>
          <div
            className={
              localeActive?.code === "fa" ? "wallet-box rtl" : "wallet-box"
            }
          >
            <div className="name">
              {userInfo.first_name} {userInfo.last_name}
            </div>
            <div className="content">
              <div className="balances-list">
                {balances.map((el) => (
                  <div className="balance-item" key={el.type}>
                    <div className="balance-type">
                      {t(getBalanceSytring(el.type))}
                    </div>
                    <div className="balance-amount">
                      {el.amount} {getSymbolFromCurrency(el.code || "")}
                    </div>
                  </div>
                ))}
              </div>
              <div className="info-block">
                <div
                  className="main-btn"
                  onClick={(): void => {
                    navigate("/payments");
                  }}
                >
                  {t("Top up")}
                </div>
                <div
                  className="link"
                  onClick={(): void => {
                    navigate("/bonus-info");
                  }}
                >
                  <img src={bonusIcon} alt="" />
                  <span>{t("Bonus rules")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletInfo;
