import ApiClient from "../ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../../types/requestTypes";
import { RakebackItem } from "../../types/vipClubTypes";

export const getRakebackList = async (): Promise<
  | {
      rakebacks: RakebackItem[];
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      rakebacks: RakebackItem[];
    }>("/vip/rakebacks/list");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const changeRakebackSteps = async (
  id: number,
  steps: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.put<DeafaultSuccesResponse>(
      `/vip/rakeback/${id}/update`,
      JSON.stringify({ steps })
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
