import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./TimerStyles.module.scss";

interface TimerTypeProps {
  countdownInMilliseconds: number;
}

const Timer: React.FC<TimerTypeProps> = ({ countdownInMilliseconds }) => {
  const { t } = useTranslation();
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  const calculateTimeLeft = (): void => {
    const difference = Math.max(countdownInMilliseconds || 0 - Date.now(), 0);

    const daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutesLeft = Math.floor((difference / (1000 * 60)) % 60);

    setDays(daysLeft);
    setHours(hoursLeft);
    setMinutes(minutesLeft);
  };

  useEffect(() => {
    const timer = setInterval((): void => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timer);
  });

  return (
    <div className={styles.card_clock}>
      <h5>{t("Ends in")}</h5>
      <div
        className={classNames(styles.clock_content, styles.fancy_border_btm)}
      >
        <div className={styles.clock_item}>
          <div className={styles.clock_value}>{days}</div>
          <span className={styles.clock_label}>{t("Days")}</span>
        </div>
        <span>:</span>
        <div className={styles.clock_item}>
          <div className={styles.clock_value}>{hours}</div>
          <span className={styles.clock_label}>{t("Hours")}</span>
        </div>
        <span>:</span>
        <div className={styles.clock_item}>
          <div className={styles.clock_value}>{minutes}</div>
          <span className={styles.clock_label}>{t("Minutes")}</span>
        </div>
      </div>
    </div>
  );
};

export default Timer;
