import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CirclesWithBar } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import getSymbolFromCurrency from "currency-symbol-map";

import { BonusItem } from "../types/bonusesTypes";
import { getBonusInfo } from "../services/apiBonuses";
import type { RootState } from "../store/store";

interface Props {
  showDeposit: () => void;
}

const BonusPage: React.FC<Props> = ({ showDeposit }) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [hasSpins, setHasSpins] = useState<boolean>(false);
  const [bonus, setBonus] = useState<BonusItem>();
  const [hours, setHours] = useState<number>(0);
  const [games, setGames] = useState<{ id: number; title: string }[]>([]);
  const [spins, setSpins] = useState<
    {
      id: number;
      title: string;
      freespins_quantity: number;
      games: {
        id: number;
        title: string;
      }[];
    }[]
  >([]);

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const currentBalance = useSelector(
    (state: RootState) => state.user.userInfo.current_balance
  );

  const loadBonus = async (): Promise<void> => {
    setIsFetching(true);
    const result = await getBonusInfo(parseInt(id || "0", 10));
    if (typeof result !== "string") {
      setBonus(result.bonus as BonusItem);
      result.bonus.campaigns.forEach((el) => {
        if (el.freespins) {
          // @ts-ignore
          setSpins([...spins, ...el.freespins]);
          setHasSpins(true);
        }
      });
    }
    setIsFetching(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (isLogged) {
      loadBonus();
    }
    // eslint-disable-next-line
  }, [isLogged]);
  useEffect(() => {
    if (bonus?.countdown_in_milliseconds) {
      setHours((bonus.countdown_in_milliseconds as number) / 1000 / 60 / 60);
    }
    // eslint-disable-next-line
  }, [bonus]);

  const renderMaxDeposit = (): string => {
    const array: string[] = [];
    if (bonus) {
      bonus.campaigns.forEach((el) => {
        if (el.settings.max_deposit_amount) {
          array.push(
            `${getSymbolFromCurrency(currentBalance?.code || "USD") as string}${
              el.settings.max_deposit_amount as number
            }`
          );
        }
      });
    }
    return array.join(",");
  };

  useEffect(() => {
    if (spins && spins.length > 0) {
      const gamesArray: { id: number; title: string }[] = [];
      spins.forEach((el) => {
        el.games.forEach((game) => {
          gamesArray.push({ id: game.id, title: game.title });
        });
      });
      setGames(gamesArray);
    }
    // eslint-disable-next-line
  }, [spins]);

  return (
    <div className="bonus-page-wrap">
      <div className="content-gradient" />
      <div className="content-page-box">
        <div className="container">
          {isFetching && (
            <CirclesWithBar
              height="150"
              width="150"
              color="#D0174F"
              wrapperStyle={{ justifyContent: "center" }}
              visible
              ariaLabel="circles-with-bar-loading"
            />
          )}
          {!isFetching && bonus && (
            <>
              <h1>{bonus?.title}</h1>
              <div className="bonus-header-wrap">
                <div className="bonus-modal-rewards">
                  {bonus.campaigns.map((el, idx) =>
                    el.freespins ? (
                      <div className="bonus-modal-reward-item" key={el.id}>
                        <div className="reawrd-title">
                          <span>{el.freespins[0].freespins_quantity}</span>
                          {t("Freespins")}
                        </div>
                        {(idx as number) + 1 === bonus.campaigns.length &&
                        bonus.countdown_in_milliseconds &&
                        bonus.countdown_in_milliseconds !== 0 ? (
                          <div className="bonus-countdown-wrap">
                            <div className="txt">
                              <span>{t("Promotion duration")}</span>
                            </div>
                            <div className="timer">
                              <div className="progress-wrap">
                                <CircularProgressbar
                                  value={Math.ceil(
                                    100 - (100 * hours) / bonus.countdown_hours
                                  )}
                                  strokeWidth={20}
                                />
                              </div>
                              <Countdown
                                date={
                                  Date.now() +
                                  (bonus.countdown_in_milliseconds as number)
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="bonus-modal-reward-item" key={el.id}>
                        <div className="reawrd-title">
                          <span>{el.settings.percentages_of_deposit}%</span>
                          {t("from the deposit")}
                        </div>
                        {(idx as number) + 1 === bonus.campaigns.length &&
                        bonus.countdown_in_milliseconds &&
                        bonus.countdown_in_milliseconds !== 0 ? (
                          <div className="bonus-countdown-wrap">
                            <div className="txt">
                              <span>{t("Promotion duration")}</span>
                            </div>
                            <div className="timer">
                              <div className="progress-wrap">
                                <CircularProgressbar
                                  value={Math.ceil(
                                    100 - (100 * hours) / bonus.countdown_hours
                                  )}
                                  strokeWidth={20}
                                />
                              </div>
                              <Countdown
                                date={
                                  Date.now() +
                                  (bonus.countdown_in_milliseconds as number)
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  )}
                </div>
                <div className="bonus-image-wrap">
                  {bonus.image && <img src={bonus.image} alt="" />}
                </div>
              </div>
              {/* {bonus.countdown_in_milliseconds &&
              bonus.countdown_in_milliseconds !== 0 ? (
                <div className="bonus-countdown-wrap">
                  <div className="txt">
                    <span>{t("Attention!")}</span>
                    <span>{t("This promotion is valid for:")}</span>
                  </div>
                  <div className="timer">
                    <div className="progress-wrap">
                      <CircularProgressbar
                        value={Math.ceil(
                          100 - (100 * hours) / bonus.countdown_hours
                        )}
                        strokeWidth={20}
                      />
                    </div>
                    <Countdown
                      date={
                        Date.now() + (bonus.countdown_in_milliseconds as number)
                      }
                    />
                  </div>
                </div>
              ) : (
                ""
              )} */}
              <div className="bonus-btn-wrap">
                <button
                  className="primary-btn"
                  type="button"
                  onClick={showDeposit}
                >
                  {t("Deposit")}
                </button>
                <button
                  className="primary-btn"
                  type="button"
                  onClick={(): void => {
                    navigate("/", { state: { toLobby: true } });
                  }}
                >
                  {t("Play")}
                </button>
              </div>
              {bonus.type === "welcome" && (
                <>
                  <h3>{t("How do I get my Welcome Bonus Pack?")}</h3>
                  <ol>
                    <li>{t("bonusTxt1")}</li>
                    <li>
                      {t("bonusTxt2", {
                        hours: bonus.countdown_hours,
                      })}
                    </li>
                  </ol>
                </>
              )}
              <h3>{t("Promotion details:")}</h3>
              <ol>
                <li>
                  {t("bonusTxt3")} {renderMaxDeposit()}
                </li>
                {hasSpins && (
                  <li>
                    {t("bonusTxt4")}{" "}
                    {games.map((el) => (
                      <NavLink key={el.id} to={`/game/${el.id}`}>
                        {el.title};{" "}
                      </NavLink>
                    ))}
                  </li>
                )}
                <li>
                  {t("bonusTxt5", {
                    wager: bonus.wagering_amount,
                  })}
                </li>
                <li>
                  {t("bonusTxt6")}{" "}
                  {getSymbolFromCurrency(currentBalance?.code || "USD")}
                  {bonus.max_bet_amount}
                </li>
                <li>{t("bonusTxt7")}</li>
                <li>
                  {t("bonusTxt8", {
                    hours: bonus.wagering_hours,
                  })}
                </li>
              </ol>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BonusPage;
