import ApiClient from "./ApiClient";
import { RequestError } from "../types/requestTypes";
import { DocumentPayload, DocumentItem } from "../types/documentsTypes";

export const getMyDocuments = async (): Promise<
  | {
      data: { docs: DocumentItem[]; is_approved?: boolean };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: { docs: DocumentItem[]; is_approved?: boolean };
    }>("/documents/kyc/me");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const checkDocumentsApproved = async (): Promise<
  | {
      is_approved: boolean;
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      is_approved: boolean;
    }>("/documents/kyc/me/status");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const addNewDocument = async (
  data: DocumentPayload
): Promise<{ docs: DocumentItem[] } | string> => {
  const formData = new FormData();
  if (data.credit_card) {
    // eslint-disable-next-line
    formData.append(
      "credit_card",
      // eslint-disable-next-line
      data.credit_card,
      data.credit_card.name as string
    );
    formData.append("credit_card_number", data.credit_card_number as string);
    formData.append("credit_card_month", data.credit_card_month as string);
    formData.append("credit_card_year", data.credit_card_year as string);
    formData.append("card_holder", data.card_holder as string);
  } else {
    Object.keys(data).forEach((el) => {
      // @ts-ignore
      // eslint-disable-next-line
      formData.append(el, data[el], data[el]?.name as string || "");
    });
  }
  try {
    const result = await ApiClient.post<{ docs: DocumentItem[] }>(
      "/documents/kyc/upload",
      formData
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    if (response) {
      const errors: string[] = [];
      // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
        // @ts-ignore
        errors.push(el as string);
      });
      return errors.join(" ") as string;
    }
    return "Request error";
  }
};

export const addNewCreditCard = async (
  data: DocumentPayload
): Promise<{ docs: DocumentItem[] } | string> => {
  try {
    const result = await ApiClient.post<{ docs: DocumentItem[] }>(
      "/documents/kyc/upload",
      { ...data }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    if (response) {
      const errors: string[] = [];
      // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
        // @ts-ignore
        errors.push(el as string);
      });
      return errors.join(" ") as string;
    }
    return "Request error";
  }
};
