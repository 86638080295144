export enum AdminPanelTabs {
  users = "users",
  bets = "bets",
  spins = "spins",
  payments = "payments",
  tournaments = "tournaments",
  docs = "docs",
  reports = "reports",
  games = "games",
  vipClub = "vipClub",
  bonuses = "bonuses",
  promo = "promo",
}

export enum ReportingTabs {
  activeUsers = "activeUsers",
  newUsers = "newUsers",
  bets = "bets",
  deposits = "deposits",
  withdrawals = "withdrawals",
  balances = "balances",
  games = "games",
}

export enum Periods {
  today = "today",
  yesterday = "yesterday",
  this_month = "this_month",
  last_month = "last_month",
}

export enum BalanceTypes {
  real = "real",
  demo = "demo",
  bonus = "bonus",
}

export enum BonusesTabs {
  bonus = "bonus",
  reward = "reward",
}

export enum PromoTabs {
  promo = "promo",
  cashback = "cashback",
}

export enum PromocodeType {
  deposit_promo_code = "deposit_promo_code",
  no_deposit_promo_code = "no_deposit_promo_code",
}
