import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDetectClickOutside } from "react-detect-click-outside";
import { CirclesWithBar } from "react-loader-spinner";

import { FreeSpinItem } from "../../../types/gameTypes";
import { getFreeSpinsList } from "../../../services/admin/apiFreeSpins";
import { addNewReward, editReward } from "../../../services/admin/apiBonuses";
import { showSuccessNotif } from "../../../helpers/renderNotification";
import {
  BonusRewardItem,
  BonusRewardPayload,
} from "../../../types/bonusesTypes";

import arrowIcon from "../../../images/dropdown-arrow.svg";

interface Props {
  show: boolean;
  rewardForEdit?: BonusRewardItem;
  handleClose?: () => void;
  handleRewardAdded?: () => void;
  handleRewardEdited?: () => void;
}

const AddBonusRewardModal: React.FC<Props> = ({
  show,
  rewardForEdit,
  handleClose,
  handleRewardAdded,
  handleRewardEdited,
}) => {
  const { t } = useTranslation();

  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isSpinsFetching, setSpinsFetching] = useState<boolean>(false);
  const [showDrodown, setShowDropdown] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  //   Freespins data
  const [freespinssList, setFreespinssList] = useState<FreeSpinItem[]>([]);
  const [hasMoreSpins, setHasMoreSpins] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);
  //  Form values
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<string>("freespins");
  const [minDeposit, setMinDeposit] = useState<string>("");
  const [maxDeposit, setMaxDeposit] = useState<string>("");
  const [percent, setPercent] = useState<string>("");
  const [globalError, setGlobalError] = useState<string>("");
  const [activeSpin, setActiveSpin] = useState<FreeSpinItem>();
  //   const [depositNumber, setDepositNumber] = useState<string>("1");

  //   Freespins logic
  const getFreespinsHandle = async (page: number): Promise<void> => {
    setSpinsFetching(true);
    const result = await getFreeSpinsList(page);
    if (typeof result !== "string") {
      setFreespinssList([
        ...freespinssList,
        ...(result.data as FreeSpinItem[]),
      ]);
      if (result.data.length < 20) {
        setHasMoreSpins(false);
      }
    }
    setSpinsFetching(false);
  };
  const handleScroll = (element: HTMLDivElement): void => {
    if (
      element.scrollHeight - element.scrollTop - 10 <= element.clientHeight &&
      hasMoreSpins &&
      !isSpinsFetching
    ) {
      setActivePage(activePage + 1);
      getFreespinsHandle(activePage + 1);
    }
  };
  const showDropdownHandler = (): void => {
    setShowDropdown(true);
  };
  const hideDropdownHandler = (): void => {
    setShowDropdown(false);
  };
  const refDropdown = useDetectClickOutside({
    onTriggered: hideDropdownHandler,
  });
  useEffect(() => {
    getFreespinsHandle(1);
    if (rewardForEdit) {
      setIsEditing(true);
      setTitle(rewardForEdit.title as string);
      setType(rewardForEdit.type as string);
      setMinDeposit(
        rewardForEdit.settings.min_deposit_amount.toString() as string
      );
      setMaxDeposit(
        (rewardForEdit?.settings?.max_deposit_amount?.toString() as string) ||
          ""
      );
      setPercent(
        (rewardForEdit?.settings?.percentages_of_deposit?.toString() as string) ||
          ""
      );
      setActiveSpin(
        rewardForEdit.settings.freespin_id && rewardForEdit.freespins
          ? ({
              id: rewardForEdit.settings.freespin_id,
              title: rewardForEdit.freespins[0].title,
            } as FreeSpinItem)
          : undefined
      );
    }
    // eslint-disable-next-line
  }, []);

  //   Form logic
  const editRewardHandler = async (): Promise<void> => {
    const payload: BonusRewardPayload = {
      min_deposit_amount: parseInt(minDeposit, 10),
      type,
    };
    if (title !== rewardForEdit?.title) {
      payload.title = title;
    }
    if (type === "freespins") {
      payload.freespin_id = activeSpin?.id;
    }
    if (type === "deposit") {
      payload.max_deposit_amount = parseInt(maxDeposit, 10);
      payload.percentages_of_deposit = parseInt(percent, 10);
      //   payload.deposit_number = parseInt(depositNumber, 10);
    }
    const result = await editReward(
      (rewardForEdit?.id as number) || 0,
      payload
    );
    if (typeof result === "string") {
      setGlobalError(result as string);
    } else {
      showSuccessNotif(t("Data edited successfully"));
      if (handleRewardEdited) handleRewardEdited();
    }
  };
  const addNewRewardHandler = async (): Promise<void> => {
    const result = await addNewReward(
      type === "freespins"
        ? {
            title,
            min_deposit_amount: parseInt(minDeposit, 10),
            type,
            freespin_id: activeSpin?.id,
          }
        : {
            title,
            type,
            min_deposit_amount: parseInt(minDeposit, 10),
            max_deposit_amount: parseInt(maxDeposit, 10),
            percentages_of_deposit: parseInt(percent, 10),
            // deposit_number: parseInt(depositNumber, 10),
          }
    );
    if (typeof result === "string") {
      setGlobalError(result as string);
    } else {
      showSuccessNotif(t("Data added successfully"));
      if (handleRewardAdded) handleRewardAdded();
    }
  };
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    if (isEditing) {
      await editRewardHandler();
    } else {
      await addNewRewardHandler();
    }
    setFetching(false);
  };

  return (
    <Modal show={show} onHide={handleClose} data-testid="rewards-admin-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? t("Edit reward") : t("Add new reward")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Title") || "Title"}
                value={title}
                data-testid="rewards-admin-title"
                onChange={(e): void => {
                  setTitle(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Type")}</Form.Label>
              <Form.Select
                aria-label={t("Type") || "Type"}
                value={type}
                data-testid="rewards-admin-type"
                onChange={(e): void => {
                  setType(e.target.value as string);
                }}
              >
                <option value="freespins">freespins</option>
                <option value="deposit">deposit</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Min deposit")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Min deposit") || "Min deposit"}
                value={minDeposit}
                data-testid="rewards-admin-min"
                onChange={(e): void => {
                  setMinDeposit(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          {type === "deposit" && (
            <>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Max deposit")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t("Max deposit") || "Max deposit"}
                    value={maxDeposit}
                    data-testid="rewards-admin-max"
                    onChange={(e): void => {
                      setMaxDeposit(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Percentages of deposit")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={
                      t("Percentages of deposit") || "Percentages of deposit"
                    }
                    value={percent}
                    data-testid="rewards-admin-percent"
                    onChange={(e): void => {
                      setPercent(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              {/* <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Deposit number")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t("Deposit number") || "Deposit number"}
                    value={depositNumber}
                    onChange={(e): void => {
                      setDepositNumber(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col> */}
            </>
          )}
          {type === "freespins" && (
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Freespin")}</Form.Label>
                <div className="custom-select-wrap" ref={refDropdown}>
                  <div
                    className="custom-select-item"
                    onClick={showDropdownHandler}
                    data-testid="rewards-admin-spin"
                  >
                    {activeSpin?.title || <span>{t("Select freespin")}</span>}
                    <img src={arrowIcon} alt="" />
                  </div>
                  {showDrodown && (
                    <div
                      className="custom-select-dropdown"
                      onScroll={(e): void => {
                        handleScroll(e.target as HTMLDivElement);
                      }}
                    >
                      {freespinssList.map((el) => (
                        <span
                          key={el.id}
                          onClick={(): void => {
                            setActiveSpin(el);
                            hideDropdownHandler();
                          }}
                        >
                          {el.title}
                        </span>
                      ))}
                      {hasMoreSpins && (
                        <CirclesWithBar
                          height="50"
                          width="50"
                          color="#0d6efd"
                          wrapperStyle={{ justifyContent: "center" }}
                          visible
                          ariaLabel="circles-with-bar-loading"
                        />
                      )}
                    </div>
                  )}
                </div>
              </Form.Group>
            </Col>
          )}
        </Row>
        {globalError && <div className="error-msg">{globalError}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          data-testid="rewards-admin-modal-close"
        >
          {t("Close")}
        </Button>
        <Button
          variant="primary"
          onClick={(): void => {
            handleSubmit();
          }}
          disabled={isFetching}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBonusRewardModal;
