import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ru from "react-phone-input-2/lang/ru.json";

interface PhoneInputWrapperProps {
  onChange: (e: string) => void;
  value: string;
  [key: string]: any;
  isReadOnly?: boolean;
}

const PhoneInputWrapper: React.FC<PhoneInputWrapperProps> = ({
  value,
  onChange,
  isReadOnly = false,
  ...props
}) => {
  const localLang = localStorage.getItem("localeLang");
  return (
    <PhoneInput
      {...props}
      value={value}
      onChange={onChange}
      preferredCountries={["ru", "kz", "ir"]}
      localization={localLang && localLang === "ru" ? ru : undefined}
      inputProps={{ readOnly: isReadOnly, tabIndex: -1 }}
      disableDropdown={isReadOnly}
    />
  );
};

export default PhoneInputWrapper;
