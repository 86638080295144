import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PromoPayload } from "../../../types/promoTypes";
import {
  getUserPromo,
  activateUserPromo,
  deleteUserPromo,
} from "../../../services/admin/apiPromo";

import promoIcon1 from "../../../images/profile-icons/promo-code-icon-1.svg";
import promoIcon2 from "../../../images/profile-icons/promo-code-icon-2.svg";
import promoIcon3 from "../../../images/profile-icons/promo-code-icon-3.svg";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";

import type { RootState } from "../../../store/store";
import { UserRoles } from "../../../types/userTypes";
import ConfirmModal from "./ConfirmModal";
import styles from "./UserPromoCode.module.scss";

interface Props {
  id: number;
  show: boolean;
  handleClose: () => void;
}

const UserPromoCodeModal: React.FC<Props> = ({ show, handleClose, id }) => {
  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [promocodeData, setPromocodeData] = useState<PromoPayload[] | null>(
    null
  );
  const [promoValue, setPromoValue] = useState<string>("");

  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [countdownInMilliseconds, setCountdownInMilliseconds] = useState<
    number | undefined
  >(0);

  const getPromoCodeData = async (): Promise<void> => {
    const result = await getUserPromo(id);
    if (typeof result !== "string" && result.length > 0) {
      setPromocodeData(result as PromoPayload[]);
      if (result[0].promocode) {
        setPromoValue(result[0].promocode.code);
      }
    }
  };

  const handleShowConfirmModal = (): void => {
    setShowConfirmModal(true);
  };

  const handlePromoDelete = async (): Promise<void> => {
    const result = await deleteUserPromo(id, promoValue);
    if (typeof result === "string") {
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data deleted successfully"));
      setPromocodeData(null);
      setPromoValue("");
    }
  };

  const handleCloseConfirmModal = (confirmed: boolean): void => {
    if (confirmed) {
      handlePromoDelete();
    }
    setShowConfirmModal(false);
  };

  const handlePromoSubmit = async (): Promise<void> => {
    if (promocodeData && promocodeData?.length > 0) {
      return;
    }
    const result = await activateUserPromo(promoValue || "", id);
    if (result === "SUCCESS") {
      showSuccessNotif(t("Promocode activated"));
      getPromoCodeData();
    } else {
      showErrorNotif(result as string);
    }
  };

  const fetchData = async (): Promise<void> => {
    await getPromoCodeData();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calculateTimeLeft = (): void => {
      const difference = Math.max(
        countdownInMilliseconds || 0 - new Date().getTime(),
        0
      );

      const daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hoursLeft = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutesLeft = Math.floor((difference / (1000 * 60)) % 60);

      setDays(daysLeft);
      setHours(hoursLeft);
      setMinutes(minutesLeft);
    };

    calculateTimeLeft();

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [countdownInMilliseconds]);

  useEffect(() => {
    if (promocodeData && promocodeData?.length > 0) {
      const { countdown_in_milliseconds: countdownMilliseconds } =
        promocodeData[0];
      setCountdownInMilliseconds(countdownMilliseconds);
    }
  }, [promocodeData]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Activation of promo codes")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.promo_activate}>
          <input
            type="text"
            className={styles.promo_input}
            placeholder={t("Enter promo code") || "Enter promo code"}
            value={promoValue}
            readOnly={
              (!!promocodeData && promocodeData?.length > 0) ||
              userRole === UserRoles.operator
            }
            onChange={(e): void => {
              setPromoValue(e.target.value);
            }}
          />
          <button
            className={styles.promo_main_btn}
            type="button"
            disabled={
              promoValue === "" ||
              userRole === UserRoles.operator ||
              promocodeData !== null
            }
            onClick={(): void => {
              handlePromoSubmit();
            }}
          >
            {t("Activate")}
          </button>
        </div>
        {promocodeData === null || promocodeData.length === 0 ? (
          <div className={styles.no_promo_card_modal}>
            {t("At the moment the user has no active promo codes")}
          </div>
        ) : (
          <>
            {promocodeData.map((promo) => (
              <div className={styles.promo_card_main}>
                <div className={styles.promo_card_content}>
                  {!!promo?.promocode?.deposit_current_amount && (
                    <div
                      className={`${styles.promo_card_elem} ${styles.fancy_border_btm}`}
                    >
                      <img
                        src={promoIcon1}
                        alt="promoIcon1"
                        className={styles.promo_card_img}
                      />
                      <h4>
                        {promo.promocode?.deposit_current_amount}%{t("deposit")}
                      </h4>
                      <p>{t("replenishing")}</p>
                    </div>
                  )}
                  {!!promo?.promocode?.cashback?.amount && (
                    <div
                      className={`${styles.promo_card_elem} ${styles.fancy_border_btm}`}
                    >
                      <img
                        src={promoIcon2}
                        alt="promoIcon3"
                        className={styles.promo_card_img}
                      />
                      <h4>
                        {promo.promocode?.cashback.amount}% {t("cashback")}
                      </h4>
                      <p>{t("amount lost")}</p>
                    </div>
                  )}
                  {promo.promocode?.freespin && (
                    <div
                      className={`${styles.promo_card_elem} ${styles.fancy_border_btm}`}
                    >
                      <img
                        src={promoIcon3}
                        alt="promoIcon3"
                        className={styles.promo_card_img}
                      />
                      <h4>{promo.promocode?.freespins_count}</h4>
                      <p>{t("Freespins")}</p>
                    </div>
                  )}
                </div>
                <div className={styles.promo_card_clock}>
                  <h5>{t("Ends in")}</h5>
                  <div
                    className={`${styles.promo_card_elem} ${styles.fancy_border_btm}`}
                  >
                    <div className={styles.expire_time_display}>
                      <div className={styles.clock_item}>
                        <div className={styles.clock_value}>{days}</div>
                        <span className={styles.clock_label}>{t("Days")}</span>
                      </div>
                      <span>:</span>
                      <div className={styles.clock_item}>
                        <div className={styles.clock_value}>{hours}</div>
                        <span className={styles.clock_label}>{t("Hours")}</span>
                      </div>
                      <span>:</span>
                      <div className={styles.clock_item}>
                        <div className={styles.clock_value}>{minutes}</div>
                        <span className={styles.clock_label}>
                          {t("Minutes")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={userRole === UserRoles.operator || promocodeData === null}
          variant="primary"
          onClick={(): void => {
            handleShowConfirmModal();
          }}
        >
          {t("Delete promo code")}
        </Button>
        <ConfirmModal
          show={showConfirmModal}
          promptMessage={t("Do you really want to remove the user promo code?")}
          onConfirm={handleCloseConfirmModal}
          onCancel={handleCloseConfirmModal}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default UserPromoCodeModal;
