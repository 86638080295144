const sliceText = (str: string, max = 50): string => {
  let strSlice = str.slice(0, max);
  const indexLastSymbol = strSlice.length - 1;
  if (strSlice[indexLastSymbol] === " ") {
    strSlice = strSlice.slice(0, indexLastSymbol);
  }
  let endStr = "";
  if (str.length > max) {
    endStr = "...";
  }
  return `${strSlice}${endStr}`;
};

export default sliceText;
