import ApiClient from "./ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../types/requestTypes";
import {
  BonusItem,
  BonusAttachedItem,
  FreeSpinItem,
} from "../types/bonusesTypes";

export const getMyBonusesList = async (
  page: number
): Promise<
  | {
      data: BonusAttachedItem[];
      meta: { last_page: number };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: BonusAttachedItem[];
      meta: { last_page: number };
    }>(`/bonuses/me/attached?page=${page}&per_page=5`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getMyFreespinsList = async (): Promise<
  | {
      freespins: FreeSpinItem[];
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      freespins: FreeSpinItem[];
    }>("/users/me/freespins");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getAvailableBonusesList = async (
  page: number
): Promise<
  | {
      data: BonusItem[];
      meta: { last_page: number };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: BonusItem[];
      meta: { last_page: number };
    }>(`/bonuses/me/list?page=${page}&per_page=35`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getAllBonusesList = async (): Promise<
  | {
      data: { active: BonusAttachedItem[]; available: BonusItem[] };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: { active: BonusAttachedItem[]; available: BonusItem[] };
    }>("/bonuses/allowed/all");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getBonusInfo = async (
  id: number
): Promise<
  | {
      bonus: BonusItem;
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      bonus: BonusItem;
    }>(`/bonuses/show/${id}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getWelcomeBonus = async (): Promise<
  | {
      bonuses: BonusItem[];
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      bonuses: BonusItem[];
    }>("/bonuses/public/list?type=welcome");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const collectBonus = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/bonuses/me/collect/${id}`,
      {}
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const detachBonus = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/bonuses/me/detach/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
