import React, { useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { UserInfo } from "../../../types/userTypes";
import { editUser } from "../../../services/admin/apiUsers";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";

interface Props {
  show: boolean;
  user?: UserInfo;
  handleClose: () => void;
  handleUserBlocked: () => void;
}

const UserBlockModal: React.FC<Props> = ({
  show,
  handleClose,
  handleUserBlocked,
  user,
}) => {
  const { t } = useTranslation();

  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  //  Form values
  const [reason, setReason] = useState<string>("");
  const [globalError, setGlobalError] = useState<string>("");

  //   Form logic
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);

    const data = {
      is_blocked: 1,
      reason_blocked: reason,
    };
    const result = await editUser(data, user?.id as number);
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      handleUserBlocked();
      showSuccessNotif(t("Data edited successfully"));
    }
    setFetching(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Block user")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Reason")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={t("Reason") || "Reason"}
                value={reason}
                onChange={(e): void => {
                  setReason(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        {globalError && <div className="error-msg">{globalError}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
        <Button
          variant="primary"
          onClick={(): void => {
            handleSubmit();
          }}
          disabled={isFetching}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserBlockModal;
