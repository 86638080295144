import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import styles from "../../forms/DocumentInputs/DocumentInputsStyles.module.scss";

interface Props {
  type: string;
  size?: "large" | "normal" | "small";
  documentPath: string;
  previewItem?: File;
  handleSelect: (e: File | undefined, type: string) => void;
}

const AdminDocInputs: React.FC<Props> = ({
  type,
  size,
  documentPath,
  previewItem,
  handleSelect,
}) => {
  const [previewImg, setPreviewImg] = useState<string>();

  const { t } = useTranslation();

  useEffect(() => {
    if (previewItem) {
      const file = previewItem;
      setPreviewImg(URL.createObjectURL(file));
    } else {
      setPreviewImg(undefined);
    }
  }, [previewItem]);

  return (
    <div
      className={classNames(
        styles.document_input_item_wrap1,
        styles[size as string]
      )}
    >
      {(previewImg || documentPath !== "none") && (
        <div
          className={classNames(styles.img_wrap, styles[size as string])}
          data-testid="document-img-front"
          style={{
            backgroundImage: `url( ${previewImg || documentPath || ""})`,
          }}
        />
      )}
      <input
        type="file"
        id={`${type}-file`}
        data-testid="document-img-front-input"
        className={classNames(styles.load_document_input)}
        accept="image/*"
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          handleSelect(e.target.files ? e.target.files[0] : undefined, type);
        }}
      />
      <label htmlFor={`${type}-file`}>{t("Update")}</label>
    </div>
  );
};

export default AdminDocInputs;
