import moment from "moment";

export const formatDateDay = (data: string | Date): string =>
  moment(data).format("DD/MM/YYYY");
export const formatDateMinutes = (data: string | Date): string =>
  moment(data).format("DD/MM/YYYY HH:mm");
export const formatDateSeconds = (data: string | Date): string =>
  moment(data).format("DD/MM/YYYY HH:mm:ss");

export const formatDateDayApi = (data: string | Date): string =>
  moment.utc(data).format("YYYY-MM-DD");
export const formatDateMinutesApi = (data: string | Date): string =>
  moment.utc(data).format("YYYY-MM-DD HH:mm");
export const formatDateSecondsApi = (data: string | Date): string =>
  moment.utc(data).format("YYYY-MM-DD HH:mm:ss");

export const formatDateDayMomentApi = (): string =>
  moment().format("YYYY-MM-DD");
export const formatDateMinutesMomentApi = (): string =>
  moment.utc().format("YYYY-MM-DD HH:mm");
