const getBalanceSytring = (balance: string): string => {
  if (balance === "real") {
    return "Real balance:";
  }
  if (balance === "bonus") {
    return "Bonus balance:";
  }
  return "Demo balance:";
};

export default getBalanceSytring;
