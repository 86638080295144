import React, { useState, useEffect } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { DebounceInput } from "react-debounce-input";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useTranslation } from "react-i18next";

interface Props {
  selectedItems: { name: string; id: number | string }[];
  searchedItems: { name: string; id: number | string }[];
  hasMore: boolean;
  disabled?: boolean;
  withoutSearch?: boolean;
  searchValue: string;
  handleSearch: (e: string) => void;
  handleSelect: (e: { name: string; id: number | string }) => void;
  handleClear: (e: number | string) => void;
  handlePaginate: () => void;
}

const MultiSelect: React.FC<Props> = ({
  selectedItems,
  searchedItems,
  handleSearch,
  handleSelect,
  handleClear,
  handlePaginate,
  hasMore,
  searchValue,
  disabled,
  withoutSearch,
}) => {
  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [renderItems, setRenderItems] = useState<
    { name: string; id: number | string }[]
  >([]);

  useEffect(() => {
    const selectedIds: any[] = [];
    selectedItems.forEach((el) => {
      selectedIds.push(el.id);
    });
    const result = searchedItems.filter((el) => {
      return !selectedIds.includes(el.id);
    });
    setRenderItems(result);
  }, [selectedItems, searchedItems]);

  const hideDropdownHandle = (): void => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };
  const showDropdownHandle = (): void => {
    setShowDropdown(true);
  };

  const handleScroll = (element: HTMLDivElement): void => {
    if (
      element.scrollHeight - element.scrollTop <= element.clientHeight + 20 &&
      hasMore
    ) {
      handlePaginate();
    }
  };

  const ref = useDetectClickOutside({
    onTriggered: () => null,
  });

  return (
    <div className="multi-select-wrap" ref={ref}>
      <div className="selected-items-row">
        {selectedItems.map((el) => (
          <div className="selected-item" key={el.id}>
            <span>{el.name}</span>
            {!disabled && (
              <div
                className="remove"
                onClick={(): void => {
                  handleClear(el.id);
                }}
              >
                X
              </div>
            )}
          </div>
        ))}
      </div>
      <DebounceInput
        debounceTimeout={1000}
        onChange={(event): void => {
          handleSearch(event.target.value);
        }}
        value={searchValue}
        type="text"
        className="form-control select-search mb-2"
        placeholder={
          withoutSearch
            ? t("Select item") || "Select item"
            : t("Search") || "Search"
        }
        onFocus={showDropdownHandle}
        onBlur={hideDropdownHandle}
        readOnly={withoutSearch}
        disabled={disabled}
      />
      {showDropdown && (
        <div
          className="multi-select-list"
          onScroll={(e): void => {
            handleScroll(e.target as HTMLDivElement);
          }}
        >
          {renderItems.map((el) => (
            <div
              className="list-item"
              key={el.id}
              onClick={(): void => {
                handleSelect(el);
              }}
            >
              {el.name}
            </div>
          ))}
          {hasMore && (
            <CirclesWithBar
              height="50"
              width="50"
              color="#0d6efd"
              wrapperStyle={{ justifyContent: "center" }}
              visible
              ariaLabel="circles-with-bar-loading"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
