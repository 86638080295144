/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./ContentPageStyle.module.scss";
import type { RootState } from "../../store/store";

const WithdrawalRefundPolicy: React.FC = () => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className={styles.content_page_wrap}>
      <div className={styles.content_gradient} />
      <div className={styles.content_page_box}>
        <div
          className={`${styles.container} ${
            localeActive?.code === "fa" ? styles.rtl : ""
          }`}
        >
          <h1>{t("Withdrawal and Refund Policy")}</h1>
          <p>
            {t(
              "Our main goal is to process and withdraw your funds as quickly as possible. In doing so, we will take into account all restrictions applicable to fund withdrawals and strictly adhere to the requirements of Directive 2018/843/EU of the European Parliament and of the Council of the European Union on the prevention of money laundering and terrorist financing."
            )}
          </p>
          <p>
            {t(
              "We will try to accommodate your preference regarding the method of payment and currency for fund withdrawal. However, we cannot guarantee its complete adherence. We may process and withdraw funds using other payment methods, different from those requested by you, such as through other payment service providers, a bank check, or a bank transfer (any fees associated with these payment methods are specified on our website)."
            )}
          </p>
          <p>
            {t(
              "It is also possible that the withdrawal currency may differ from the currency of your deposit or your initial request. If necessary, we will determine the conversion rate of your funds between different currencies at our discretion."
            )}
          </p>
          <p>
            {t(
              "Please note that for the first withdrawal, large amounts, or when changing payment methods, we may apply additional security measures to confirm your legitimate right to receive the funds. In these cases, providing copies of your identification documents may be required before the withdrawal process begins. We implement these security measures to protect your funds and confidentiality, which may result in a slight delay. We apologize for any inconvenience."
            )}
          </p>
          <p>
            {t(
              "In these cases, you may be required to provide copies of your identification documents before the withdrawal process begins. We take these security measures to protect your funds and confidentiality, which may result in a slight delay. We apologize for any inconvenience."
            )}
          </p>
          <div className={styles.content_page_subtitle}>
            {t("Withdrawal Amount and Requests")}
          </div>
          <div className={styles.withdrawal_condtions_block}>
            <div>
              <p>{t("Limits on withdrawals for players:")}</p>
              <p>{t("Players with total deposits less than 1,500 USD:")}</p>
              <p>
                <span>
                  {t(
                    "Maximum withdrawal amount - 50 USD / 25,000 KZT / 5,000 RUB per day"
                  )}
                </span>
                <br />
                <span>{t("350 USD / 175,000 KZT / 35,000 RUB per week")}</span>
                <br />
                <span>
                  {t("1,000 USD / 440,000 KZT / 90,000 RUB per month")}
                </span>
              </p>
            </div>
            <div>
              <p>
                {t(
                  "For players with a total deposit amount of more than 1,500 USD, the maximum withdrawal amount:"
                )}
              </p>
              <p>
                <span>
                  {t(
                    "Maximum withdrawal amount - 300 USD / 130,000 KZT / 25,000 RUB per day"
                  )}
                </span>
                <br />
                <span>
                  {t("1,100 USD / 480,000 KZT / 100,000 RUB per week")}
                </span>
                <br />
                <span>
                  {t("3,000 USD / 1,300,000 KZT / 270,000 RUB per month")}
                </span>
              </p>
              <p>{t("VIP players have individual withdrawal limits.")}</p>
            </div>
          </div>
          <div className={styles.content_page_subtitle}>
            {t("Withdrawal Conditions")}
          </div>
          <p>
            {t("We process fund withdrawals every day, including weekends.")}
          </p>
          <p>
            {t(
              "All fund withdrawal requests are processed in the order they are received within 48 hours if the requested amount does not exceed 1,000 USD or the equivalent amount in another currency."
            )}
          </p>
          <p>
            {t(
              "However, if the requested withdrawal amount or the balance on your account exceeds 1,000 USD, processing the request may take up to 72 hours."
            )}
          </p>
          <p>
            {t(
              "We do not charge a fee for withdrawals unless stated otherwise."
            )}
          </p>
          <div className={styles.content_page_subtitle}>{t("Refund")}</div>
          <p>
            {t(
              "Please note that refunds will only be processed if there is no gaming activity on your account after the corresponding amount has been deposited, which is subject to a refund."
            )}
            .
          </p>
          <p>
            {t(
              "If any gaming activity (e.g., a spin or bet) has occurred on your account after funds have been deposited, a refund is only possible if the balance is wagered three times."
            )}
          </p>
          <p>
            {t(
              "All other withdrawals from your account will be considered as payments of winnings obtained from using the services on boomcasino.bet."
            )}
          </p>
          <p>
            {t(
              "A refund request will only be considered if it is submitted within seven days from the presumed transaction date or within thirty days if you claim that another person (or a minor) has gained access to your account."
            )}
          </p>
          <p>
            {t(
              "We reserve the right to suspend any refund process until your identity is properly verified in accordance with the KYC procedure (Know Your Customer)."
            )}
          </p>
          <p>
            {t(
              "Providing a notarized identity certificate or other forms of identification compliant with the legislation of your country is mandatory for the KYC procedure."
            )}
          </p>
          <p>
            {t(
              "If a notarized identity certificate is not provided within five (5) days from our request, the requested refund will not be processed, and your account will be closed. The refund will be executed within seven (7) days after the completion of the identification process."
            )}
          </p>
          <p>
            {t(
              "To request a refund or any other withdrawal of funds, please contact our support service by email at support@boomcasino.bet and provide all the necessary documents requested by the support service to complete the KYC procedure."
            )}
          </p>
          <p>
            {t(
              "If you require our contact information, you can find it in the 'Contacts' section on our website. A list of the required documents for the KYC procedure will be provided in the email you receive from our support service."
            )}
          </p>
          <p>
            {t(
              "Please note that a refund request must be submitted no later than seven (7) days after the corresponding funds are credited to your account. After this period, all payments will be considered as payouts of winnings obtained through the use of our services on the boomcasino.bet website."
            )}
          </p>
          <div className={styles.content_page_subtitle}>
            {t("Refunds due to Fraudulent Activities")}
          </div>
          <p>
            {t(
              "Refund requests related to unauthorized transactions or other instances of fraudulent use of your credit/debit card on boomcasino.bet will be considered on a case-by-case basis."
            )}
          </p>
          <p>
            {t(
              "If it is determined that such transactions are likely fraudulent and were made using your credit/debit card, we will promptly initiate the refund process."
            )}
          </p>
          <p>
            {t(
              "In some situations, we may request confirmation of measures taken by you to prevent such fraudulent activities in the future. This may include closing the credit/debit card account used in fraudulent transactions or obtaining a new credit/debit card from your financial institution."
            )}
          </p>
          <p>
            {t(
              "In all cases where claims of fraudulent transactions are received, accounts used on boomcasino.bet for such transactions will be temporarily suspended or canceled, and measures may be taken to prevent the opening of new accounts using the same user information or attempts to transact using the same credit/debit card or any other payment method."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalRefundPolicy;
