import React from "react";
import styles from "./ModalStyles.module.scss";

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, open }) => {
  if (!open) return null;

  return <div className={styles.modal_wrapper}>{children}</div>;
};

export default Modal;
