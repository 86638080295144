import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./StatusDocumentStyle.module.scss";
import { DocumentItem } from "../../../types/documentsTypes";

interface StatusDocumentProps {
  preview?: DocumentItem;
}

enum Statuses {
  pending = "Pending",
  processing = "Processing",
  approved = "Approved",
  declined = "Declined",
}

const StatusDocument: React.FC<StatusDocumentProps> = ({ preview }) => {
  const { t } = useTranslation();

  const renderStatus = (doc: DocumentItem | undefined): JSX.Element => {
    switch (doc?.status) {
      case Statuses.approved:
        return (
          <div className={classNames(styles.document_status, styles.approved)}>
            <div className={styles.content}>{t("Approved")}</div>
          </div>
        );
      case Statuses.declined:
        return (
          <div className={classNames(styles.document_status, styles.declined)}>
            <div className={styles.content}>{t("Declined")}</div>
          </div>
        );
      case Statuses.pending:
      case Statuses.processing:
        return (
          <div className={classNames(styles.document_status, styles.pending)}>
            <div className={styles.content}>{t("Pending")}</div>
          </div>
        );
      default:
        return <span />;
    }
  };
  return renderStatus(preview);
};

export default StatusDocument;
