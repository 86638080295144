import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateSecondsApi } from "../../../helpers/getCorrectDate";

import { getAllGamesListTournament } from "../../../services/apiGames";
import { addNewTornament } from "../../../services/admin/apiTournamentsAdmin";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";
import { AddTournamentPayload } from "../../../types/tournamentTypes";

import MultiSelect from "../../MultiSelect";
import TournamentPrizeItem from "../TournamentPrizeItem";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleTournamentAdded: () => void;
}

const perPageGames = 50;

const AddTournamentModal: React.FC<Props> = ({
  show,
  handleClose,
  handleTournamentAdded,
}) => {
  const { t } = useTranslation();

  //   Selects game data
  const [games, setGames] = useState<{ name: string; id: number | string }[]>(
    []
  );
  const [selectedGames, setSelectedGames] = useState<
    { name: string; id: number | string }[]
  >([]);
  const [gameSearch, setGameSearch] = useState<string>("");
  const [gamePage, setGamePage] = useState<number>(1);
  const [hasMoreGames, setHasMoreGames] = useState<boolean>(true);
  //   Selects currency data
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>();
  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  //  Form values
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [rounds, setRounds] = useState<string>("");
  const [pointsCost, setPointsCost] = useState<string>("");
  const [minBet, setMinBet] = useState<string>("");
  const [dateStart, setDateStart] = useState<Date>();
  const [dateEnd, setDateEnd] = useState<Date>();
  const [prizes, setPrizes] = useState<
    { place: number; reward_amount: string }[]
  >([
    {
      place: 1,
      reward_amount: "100",
    },
    {
      place: 2,
      reward_amount: "200",
    },
    {
      place: 3,
      reward_amount: "300",
    },
  ]);
  const [imgFile, setImgFile] = useState<File>();
  const [globalError, setGlobalError] = useState<string>("");
  const [background, setBackground] = useState<string>("blue");
  const [allGames, setAllGames] = useState<boolean>(false);
  const [shouldRepeat, setShouldRepeat] = useState<boolean>(false);
  const [repaetPeriod, setRepaetPeriod] = useState<string>("");

  //   Form logic
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    const gamesIds: number[] = [];
    selectedGames.forEach((el) => {
      gamesIds.push(typeof el.id === "string" ? parseInt(el.id, 10) : el.id);
    });
    const data: AddTournamentPayload = {
      img: imgFile,
      bg_color: background,
      qualification_rounds_count: parseInt(rounds, 10),
      cost_of_point_in_currency: parseFloat(pointsCost),
      min_bet_amount: parseFloat(minBet),
      currency_id: selectedCurrency || 1,
      places: JSON.stringify(prizes),
      started_at: formatDateSecondsApi(dateStart as Date) || "",
      finished_at: formatDateSecondsApi(dateEnd as Date) || "",
      title,
      description,
      games_ids: [],
      all_games: allGames ? 1 : 0,
    };
    if (!allGames) {
      data.games_ids = gamesIds;
    }
    if (shouldRepeat) {
      data.replay_in_days = parseInt(repaetPeriod, 10);
    }
    const result = await addNewTornament(data);
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      handleTournamentAdded();
      showSuccessNotif(t("Data added successfully"));
    }
    setFetching(false);
  };
  const handlePrizeChange = (amount: string, id: number): void => {
    setPrizes((prev) =>
      prev.map((el, i) => (i !== id ? el : { ...el, reward_amount: amount }))
    );
  };
  const addPrize = (): void => {
    setPrizes([
      ...prizes,
      {
        place: prizes.length + 1,
        reward_amount: ((prizes.length + 1) * 100).toString(),
      },
    ]);
  };
  const handlePrizeRemove = (): void => {
    setPrizes(prizes.slice(0, -1));
  };

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  //   Games logic
  const loadGames = async (
    search: string,
    page: number,
    clearRequest?: boolean
  ): Promise<void> => {
    setHasMoreGames(true);
    const response = await getAllGamesListTournament(
      search,
      null,
      null,
      page,
      perPageGames
    );
    if (typeof response !== "string") {
      const result: { name: string; id: number }[] = [];
      response.data.forEach((el) => {
        result.push({ name: el.title, id: el.id });
      });
      setGames(clearRequest ? [...result] : [...games, ...result]);
      if (response.data.length < perPageGames) {
        setHasMoreGames(false);
      }
    }
  };
  useEffect(() => {
    loadGames(gameSearch, gamePage);
    // eslint-disable-next-line
  }, []);
  const handleGameSearch = (e: string): void => {
    setGames([]);
    setGameSearch(e);
    setGamePage(1);
    loadGames(e, 1, true);
  };
  const handleGamePaginate = (): void => {
    loadGames(gameSearch, gamePage + 1);
    setGamePage(gamePage + 1);
  };
  const handleGameSelect = (e: { name: string; id: number | string }): void => {
    setSelectedGames([...selectedGames, e]);
  };
  const handleGameClear = (e: number | string): void => {
    const newArray = selectedGames.filter((el) => {
      return el.id !== e;
    });
    setSelectedGames(newArray);
  };

  return (
    <Modal show={show} onHide={handleClose} className="add-tournament-modal">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add new tournament")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Title") || "Title"}
                value={title}
                onChange={(e): void => {
                  setTitle(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Description")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={t("Description") || "Description"}
                value={description}
                onChange={(e): void => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Qualification rounds")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={
                  t("Qualification rounds") || "Qualification rounds"
                }
                value={rounds}
                onChange={(e): void => {
                  setRounds(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Currency")}</Form.Label>
              <Form.Select
                aria-label={t("Currency") || "Currency"}
                value={selectedCurrency}
                onChange={(e): void => {
                  setSelectedCurrency(parseInt(e.target.value as string, 10));
                }}
              >
                {currencyList &&
                  currencyList.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.code}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Point cost")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Point cost") || "Point cost"}
                value={pointsCost}
                onChange={(e): void => {
                  setPointsCost(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Min bet")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Min bet") || "Min bet"}
                value={minBet}
                onChange={(e): void => {
                  setMinBet(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Auto replay")}</Form.Label>
              <Form.Check
                checked={shouldRepeat}
                onChange={(): void => {
                  setShouldRepeat(!shouldRepeat);
                }}
                data-testid="games-admin-filter-jack"
              />
            </Form.Group>
            {shouldRepeat && (
              <Form.Group className="mb-3">
                <Form.Label>{t("Replay days")}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t("Replay days") || "Replay days"}
                  value={repaetPeriod}
                  onChange={(e): void => {
                    setRepaetPeriod(e.target.value);
                  }}
                />
              </Form.Group>
            )}
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Start date")}</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={(dateEvent: Date): void => {
                  setDateStart(dateEvent);
                }}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
                timeIntervals={5}
                selected={dateStart}
                dropdownMode="select"
                placeholderText={t("Select date") || "Select date"}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("End date")}</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={(dateEvent: Date): void => {
                  setDateEnd(dateEvent);
                }}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
                timeIntervals={5}
                selected={dateEnd}
                dropdownMode="select"
                placeholderText={t("Select date") || "Select date"}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Image")}</Form.Label>
              <Form.Control
                type="file"
                placeholder={t("Image") || "Image"}
                onChange={(e): void =>
                  setImgFile(
                    // @ts-ignore
                    e.target.files[0] as File
                  )
                }
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Background")}</Form.Label>
              <Form.Select
                aria-label={t("Background") || "Background"}
                value={background}
                onChange={(e): void => {
                  setBackground(e.target.value as string);
                }}
              >
                <option value="blue">blue</option>
                <option value="green">green</option>
                <option value="orange">orange</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("All games")}</Form.Label>
              <Form.Check
                checked={allGames}
                onChange={(): void => {
                  setAllGames(!allGames);
                }}
                data-testid="games-admin-filter-jack"
              />
            </Form.Group>
            {!allGames && (
              <Form.Group className="mb-3">
                <Form.Label>{t("Games")}</Form.Label>
                <MultiSelect
                  selectedItems={selectedGames}
                  searchedItems={games}
                  hasMore={hasMoreGames}
                  searchValue={gameSearch}
                  handleSearch={handleGameSearch}
                  handleSelect={handleGameSelect}
                  handleClear={handleGameClear}
                  handlePaginate={handleGamePaginate}
                />
              </Form.Group>
            )}
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Prizes")}</Form.Label>
              {prizes.map((el, idx) => (
                <TournamentPrizeItem
                  key={el.place}
                  id={idx}
                  value={el.reward_amount}
                  handlePrizeChange={handlePrizeChange}
                  handlePrizeRemove={handlePrizeRemove}
                  isLast={el.place > 3 && el.place === prizes.length}
                />
              ))}
              <Button variant="primary" onClick={addPrize}>
                {t("Add")}
              </Button>
            </Form.Group>
          </Col>
        </Row>
        {globalError && <div className="error-msg">{globalError}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
        <Button
          variant="primary"
          onClick={(): void => {
            handleSubmit();
          }}
          disabled={isFetching}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTournamentModal;
