import { GameItemType } from "./gameTypes";

export enum TournamentStatuses {
  active = "active",
  coming = "coming",
  expired = "expired",
}
export interface TournamentItem {
  id: number;
  title: string;
  description: string | null;
  qualification_rounds_count: number;
  cost_of_point_in_currency: number;
  min_bet_amount: number;
  is_vip: number;
  currency: string;
  started_at: string;
  finished_at: string;
  fund: string;
  status: TournamentStatuses;
  places: {
    id: number;
    place: number;
    reward_amount: number;
  }[];
  users: {
    id: number;
    nick_name: string;
    points_in_currency: number;
    rounds_count: number;
    place: number;
  }[];
  games: GameItemType[];
  image?: string;
  bg_color?: string;
  is_mine?: boolean;
  all_games?: number;
  replay_in_days?: number;
}

export interface TournamentRewardItem {
  tournament_id: number;
  place: number;
  points: number;
  reward_amount: number;
  tournament_currency: string;
  tournament_title: string;
}

export interface AddTournamentPayload {
  img?: File | undefined;
  bg_color?: string;
  title?: string;
  description?: string | null;
  qualification_rounds_count?: number;
  cost_of_point_in_currency?: number;
  min_bet_amount?: number;
  currency_id?: number;
  started_at?: string;
  finished_at?: string;
  places?:
    | string
    | {
        place: number;
        reward_amount: number | string;
      }[];
  games_ids?: string | number[];
  users_ids?: number[];
  all_games?: number;
  replay_in_days?: number;
}
