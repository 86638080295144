import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { DebounceInput } from "react-debounce-input";
import { useSelector } from "react-redux";

import {
  getRakebackList,
  changeRakebackSteps,
} from "../../services/admin/apiVipClub";
import { AdminPanelTabs } from "../../types/adminTypes";
import { RakebackItem } from "../../types/vipClubTypes";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../helpers/renderNotification";
import type { RootState } from "../../store/store";
import { UserRoles } from "../../types/userTypes";

interface Props {
  activeTab: AdminPanelTabs;
}

const VipClubTab: React.FC<Props> = ({ activeTab }) => {
  const [rakebackList, setRakebackList] = useState<RakebackItem[]>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [fetchingId, setFetchingId] = useState<number>(0);

  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const loadRakeback = async (): Promise<void> => {
    setFetching(true);
    const result = await getRakebackList();
    if (typeof result !== "string") {
      setRakebackList(result.rakebacks as RakebackItem[]);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!rakebackList && activeTab === AdminPanelTabs.vipClub) {
      loadRakeback();
    }
    // eslint-disable-next-line
  }, [activeTab, rakebackList]);

  const handleChange = async (value: string, id: number): Promise<void> => {
    setFetchingId(id);
    const result = await changeRakebackSteps(id, parseInt(value, 10));
    if (typeof result === "string") {
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data edited successfully"));
    }
    setFetchingId(0);
  };

  return (
    <div className="admin-rakeback-warp admin-tab-warp">
      <h3>{t("Rakeback")}</h3>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {!isFetching && (
        <Table striped bordered className="mb-5" responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("Category")}</th>
              <th>{t("Steps")}</th>
            </tr>
          </thead>
          {rakebackList && (
            <tbody>
              {rakebackList.map((el) => (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{el.category}</td>
                  <td>
                    <DebounceInput
                      debounceTimeout={1000}
                      onChange={(event): void => {
                        handleChange(event.target.value, el.id);
                      }}
                      value={el.steps}
                      type="text"
                      className="form-control"
                      disabled={
                        fetchingId === el.id || userRole !== UserRoles.admin
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}
    </div>
  );
};

export default VipClubTab;
