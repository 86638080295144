import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useSelector, useDispatch } from "react-redux";

import { isMobile } from "react-device-detect";

import type { RootState } from "../store/store";
import { LocaleItem } from "../types/userTypes";
import { updateUserData } from "../services/apiLogin";
import { setActiveLocal, loggedAction } from "../store/slices/userData";

import ProfileInfo from "../components/tabs/ProfileInfo";
import WalletInfo from "../components/tabs/WalletInfo";
import SupportForm from "../components/tabs/SupportForm";
import ProfileDocs from "../components/tabs/ProfileDocs/ProfileDocs";
import ChangePassword from "../components/tabs/ChangePassword";
import ConfirmContacts from "../components/tabs/ConfirmContacts";
import PromocodesTab from "../components/tabs/PromocodesTabs/PromocodesTab";
import VipTab from "./VipClub";

import infoIcon from "../images/profile-icons/info.svg";
import docsIcon from "../images/profile-icons/docs.svg";
import walletIcon from "../images/profile-icons/wallet.svg";
// import supportIcon from "../images/profile-icons/support.svg";
import arrowBackIcon from "../images/new-arrow.svg";
import logoutIcon from "../images/logout-icon.svg";
import arrowIcon from "../images/arrow-down-new.svg";
import crownIcon from "../images/profile-icons/crown.svg";
import giftBoxIcon from "../images/profile-icons/gift.svg";

enum Tabs {
  info = "info",
  wallet = "wallet",
  docs = "docs",
  support = "support",
  promocode = "promocode",
  vip = "vip",
}

enum ConfirmType {
  mail = "mail",
  phone = "phone",
}

const ProfilePage: React.FC = () => {
  // Conditions
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const [confirmContact, setConfirmContact] = useState<
    ConfirmType | undefined
  >();
  const [confirmValue, setConfirmValue] = useState<string>("");
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.info);
  const [activeLang, setActiveLang] = useState<LocaleItem>({
    code: "ru",
  } as LocaleItem);
  const [showLangMenu, setShowLangMenu] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const localesList = useSelector((state: RootState) => state.user.localesList);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const isLogged = useSelector((state: RootState) => {
    return state.user.isLogged;
  });

  useEffect(() => {
    switch (location.pathname) {
      case "/profile":
        setActiveTab(Tabs.info);
        break;
      case "/wallet":
        setActiveTab(Tabs.wallet);
        break;
      case "/promocode":
        setActiveTab(Tabs.promocode);
        break;
      case "/vip-club":
        setActiveTab(Tabs.vip);
        break;
      case "/documents":
        setActiveTab(Tabs.docs);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (window.location.search.includes("token")) {
      setIsChangePassword(true);
    }
    if (window.location.search.includes("isMailConfirmed")) {
      setConfirmContact(ConfirmType.mail);
    }
  }, []);

  useEffect(() => {
    if (localeActive && localeActive !== activeLang) {
      setActiveLang(localeActive);
    }
  }, [localeActive, activeLang]);
  useEffect(() => {
    const localLang = localStorage.getItem("localeLang");
    if (!localeActive && localLang) {
      setActiveLang({ code: localLang } as LocaleItem);
    }
  }, [localeActive]);

  const changleLangHandler = (lang: LocaleItem): void => {
    setActiveLang(lang);
    i18n.changeLanguage(lang.code as string);
    localStorage.setItem("localeLang", lang.code);
    dispatch(setActiveLocal(lang));
    if (isLogged) updateUserData({ locale_id: lang.id });
  };
  const showLangMenuHandle = (): void => {
    setShowLangMenu(true);
  };
  const refMenuLang = useDetectClickOutside({
    onTriggered: (): void => setShowLangMenu(false),
  });

  const logOutHandler = (): void => {
    dispatch(loggedAction(false));
    navigate("/");
  };

  const showChangePassword = (): void => {
    setIsChangePassword(true);
  };

  const handleModalShow = (e: boolean): void => {
    setModalShow(e);
  };

  const showConfirmContact = (isMail: boolean, value: string): void => {
    setConfirmValue(value);
    setConfirmContact(isMail ? ConfirmType.mail : ConfirmType.phone);
  };
  const returnConfirmHandler = (): void => {
    setConfirmValue("");
    setConfirmContact(undefined);
  };

  return (
    <div
      className={modalShow ? "profile-page-wrap modal" : "profile-page-wrap"}
    >
      <div
        className={
          activeLang?.code === "fa" ? "profile-content rtl" : "profile-content"
        }
      >
        <div
          className="back-btn"
          onClick={(): void => {
            if (isChangePassword) {
              setIsChangePassword(false);
              return;
            }
            if (confirmContact) {
              setConfirmContact(undefined);
              return;
            }
            if (location.key === "default") {
              navigate("/");
            } else {
              navigate(-1);
            }
          }}
        >
          <img src={arrowBackIcon} alt="" />
        </div>
        <div className="profile-tabs">
          <div
            className={activeTab === Tabs.info ? "tab-item active" : "tab-item"}
            onClick={(): void => {
              navigate("/profile");
              // setActiveTab(Tabs.info);
              setIsChangePassword(false);
            }}
          >
            <img src={infoIcon} alt="" />
            <span>{t("Data")}</span>
          </div>
          <div
            className={
              activeTab === Tabs.wallet ? "tab-item active" : "tab-item"
            }
            onClick={(): void => {
              navigate("/wallet");
              // setActiveTab(Tabs.wallet);
              setIsChangePassword(false);
            }}
          >
            <img src={walletIcon} alt="" />
            <span>{t("Wallet")}</span>
          </div>
          <div
            className={
              activeTab === Tabs.promocode ? "tab-item active" : "tab-item"
            }
            onClick={(): void => {
              // props?
              navigate("/promocode");
              // setActiveTab(Tabs.promocode);
              setIsChangePassword(false);
            }}
          >
            <img src={giftBoxIcon} alt="" />
            <span>{t("Promocode")}</span>
          </div>
          <div
            className={activeTab === Tabs.vip ? "tab-item active" : "tab-item"}
            onClick={(): void => {
              navigate("/vip-club");
              // setActiveTab(Tabs.vip);
              setIsChangePassword(false);
            }}
          >
            <img src={crownIcon} alt="" />
            <span>{t("Vip-club")}</span>
          </div>
          <div
            className={activeTab === Tabs.docs ? "tab-item active" : "tab-item"}
            onClick={(): void => {
              navigate("/documents");
              // setActiveTab(Tabs.docs);
              setIsChangePassword(false);
            }}
          >
            <img src={docsIcon} alt="" />
            <span>{t("Documents")}</span>
          </div>
          {/* temporary solution!!!!! */}
          {/* <div
            className={
              activeTab === Tabs.support ? "tab-item active" : "tab-item"
            }
            onClick={(): void => {
              setActiveTab(Tabs.support);
              setIsChangePassword(false);
            }}
          >
            <img src={supportIcon} alt="" />
            <span>{t("Support")}</span>
          </div> */}
        </div>
        {activeTab === Tabs.info && !isChangePassword && !confirmContact && (
          <ProfileInfo
            showChangePassword={showChangePassword}
            showConfirmContact={showConfirmContact}
          />
        )}
        {activeTab === Tabs.wallet && <WalletInfo />}
        {activeTab === Tabs.docs && <ProfileDocs />}
        {activeTab === Tabs.support && (
          <SupportForm handleModalShow={handleModalShow} />
        )}
        {activeTab === Tabs.info && isChangePassword && <ChangePassword />}
        {activeTab === Tabs.info && confirmContact && (
          <ConfirmContacts
            isMail={confirmContact === ConfirmType.mail}
            returnHandler={returnConfirmHandler}
            mail={
              confirmContact === ConfirmType.mail ? confirmValue : undefined
            }
            phone={
              confirmContact === ConfirmType.mail ? undefined : confirmValue
            }
          />
        )}
        {activeTab === Tabs.promocode && <PromocodesTab />}
        {activeTab === Tabs.vip && <VipTab />}
      </div>
      {isMobile && activeTab === Tabs.info && (
        <div className="profile-footer">
          <div
            className={showLangMenu ? "menu-lang opened" : "menu-lang"}
            ref={refMenuLang}
            onClick={showLangMenuHandle}
          >
            <span>{activeLang.code.toLocaleUpperCase()}</span>
            <img src={arrowIcon} alt="" />
            {showLangMenu && (
              <div className="lang-dropdown">
                {localesList.map((el) => (
                  <span
                    className={el.code === activeLang.code ? "hidden" : ""}
                    key={el.id}
                    onClick={(): void => {
                      changleLangHandler(el);
                    }}
                  >
                    {el.code === "kk" ? "KZ" : el.code.toUpperCase()}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="logout-box" onClick={logOutHandler}>
            <img src={logoutIcon} alt="" />
            <span>{t("Log out")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
