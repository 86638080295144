import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";
import WelcomeBonusSlide from "./sliderItem/WelcomeBonusSlide";
import apiGeoInfo from "../../../services/apiGeoInfo";

import type { RootState } from "../../../store/store";
// import SliderControls from "./SliderControls/SliderControls";
// import SocialSlide from "./sliderItem/SocialSlide";
import styles from "./MainSliderStyles.module.scss";

const MainSlider: React.FC = () => {
  // const slideLength = 2;
  // const [currentSlide, setCurruntSlide] = useState<number>(0);
  const typeCurrency = useSelector(
    (state: RootState) => state.user.userInfo.country?.code
  );

  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const [currencyType, setCurrencyType] = useState<string | undefined>(
    typeCurrency
  );

  const getGeoInfo = async (): Promise<void> => {
    const result = await apiGeoInfo();
    setCurrencyType(result.country_code as string);
  };

  // const showNext = (): void => {
  //   if (currentSlide + 1 >= slideLength) {
  //     setCurruntSlide(0);
  //   } else {
  //     setCurruntSlide(currentSlide + 1);
  //   }
  // };
  // const showPrev = (): void => {
  //   if (currentSlide - 1 < 0) {
  //     setCurruntSlide(slideLength - 1);
  //   } else {
  //     setCurruntSlide(currentSlide - 1);
  //   }
  // };

  useEffect(() => {
    if (!isLogged) {
      getGeoInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main_slider_wrap}>
      {/* <Carousel
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        infiniteLoop
        className="main-slider"
        selectedItem={currentSlide}
        showArrows={false}
        showIndicators={false}
        showThumbs={false}
        onChange={(e): void => {
          setCurruntSlide(e);
        }}
        autoPlay
        interval={7000}
      > */}
      <WelcomeBonusSlide isLogged={isLogged} typeCurrency={currencyType} />
      {/* <SocialSlide /> */}
      {/* </Carousel>
      <SliderControls
        showNext={showNext}
        showPrev={showPrev}
        slideLength={slideLength}
        currentSlide={currentSlide}
      /> */}
    </div>
  );
};

export default MainSlider;
