import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CirclesWithBar } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";

import {
  BonusItem,
  BonusAttachedItem,
  FreeSpinItem,
} from "../types/bonusesTypes";
import {
  getAllBonusesList,
  collectBonus,
  detachBonus,
  getMyFreespinsList,
} from "../services/apiBonuses";
import type { RootState, AppDispatch } from "../store/store";
import { getUserData } from "../services/apiLogin";
import { setUserData } from "../store/slices/userData";

import MyBonusItem from "../components/listItems/MyBonusItem";
import DefBonusItem from "../components/listItems/BonusItem";
import MySpinItem from "../components/listItems/MySpinItem";

const MyBonuses: React.FC = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [myBonuses, setMyBonuses] = useState<BonusAttachedItem[]>();
  const [mySpins, setMySpins] = useState<FreeSpinItem[]>();
  const [allBonuses, setAllBonuses] = useState<BonusItem[]>();

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const isLogged = useSelector((state: RootState) => state.user.isLogged);

  const loadAllBonuses = async (): Promise<void> => {
    setIsFetching(true);
    const result = await getAllBonusesList();
    if (typeof result !== "string") {
      setMyBonuses(result.data.active as BonusAttachedItem[]);
      setAllBonuses(result.data.available as BonusItem[]);
    }
    setIsFetching(false);
  };
  const loadSpins = async (): Promise<void> => {
    setIsFetching(true);
    const result = await getMyFreespinsList();
    if (typeof result !== "string") {
      setMySpins(result.freespins as FreeSpinItem[]);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (isLogged) {
      loadAllBonuses();
      loadSpins();
    }
    // eslint-disable-next-line
  }, [isLogged]);

  const updateUserData = async (): Promise<void> => {
    const result = await getUserData();
    if (typeof result !== "string") {
      dispatch(setUserData(result.user));
    }
  };
  const collectBonusHandler = async (id: number): Promise<void> => {
    await collectBonus(id);
    loadAllBonuses();
    updateUserData();
  };
  const detachBonusHandler = async (id: number): Promise<void> => {
    await detachBonus(id);
    loadAllBonuses();
  };

  return (
    <div className="my-bonuses-page-wrap">
      <div className="content-page-box">
        <div className="container">
          <h1>{t("My bonuses")}</h1>
          {isFetching && (
            <CirclesWithBar
              height="150"
              width="150"
              color="#D0174F"
              wrapperStyle={{ justifyContent: "center" }}
              visible
              ariaLabel="circles-with-bar-loading"
            />
          )}
          {myBonuses && myBonuses.length > 0 && (
            <div className="my-bonuses-list">
              <div className="list-title">{t("Active Bonuses")}</div>
              {myBonuses.map((el) => (
                <MyBonusItem
                  key={el.id}
                  maxBet={`${el.max_bet_amount as number} ${
                    el.bonus.currency?.code as string
                  }`}
                  title={el.bonus.title}
                  wagering_hours={el?.wagering_in_milliseconds}
                  bet_amount={el.bet_amount}
                  wagering_amount={el?.user_wagering_amount || 0}
                  id={el.bonus.id}
                  collectBonusHandler={(id): void => {
                    collectBonusHandler(id);
                  }}
                  detachBonusHandler={(id): void => {
                    detachBonusHandler(id as number);
                  }}
                  is_confirmed={el.is_confirmed}
                />
              ))}
            </div>
          )}
          {mySpins && mySpins.length > 0 && (
            <div className="my-bonuses-list">
              <div className="list-title">{t("My Freespin")}</div>
              {mySpins.map((el) => (
                <MySpinItem
                  key={el.issue_id}
                  title={el.title}
                  games={el?.games}
                  total_quantity={el.total_quantity}
                  played_amount={el.played_amount}
                />
              ))}
            </div>
          )}

          {allBonuses && allBonuses.length > 0 && (
            <div className="all-bonuses-list">
              <div className="list-title">{t("Available Bonuses")}</div>
              <div className="list-wrap">
                {allBonuses.map((el) => (
                  <DefBonusItem
                    key={el.id}
                    title={el.title}
                    image={el.image}
                    countdown_time={el.countdown_in_milliseconds}
                    id={el.id}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyBonuses;
