import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import ua from "./locales/ua.json";
import ru from "./locales/ru.json";
import fa from "./locales/fa.json";
import kk from "./locales/kz.json";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: {
      translations: en,
    },
    uk: {
      translations: ua,
    },
    ru: {
      translations: ru,
    },
    fa: {
      translations: fa,
    },
    kk: {
      translations: kk,
    },
  },
  fallbackLng: "ru",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
