import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { RootState } from "../../../store/store";

import {
  getUserBalance,
  editUserBalanceCurrency,
} from "../../../services/admin/apiUsers";
import { BalanceItem, UserRoles } from "../../../types/userTypes";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";

import UserBalanceItem from "../UserBalanceItem";

interface Props {
  name: string;
  id: number;
  show: boolean;
  handleClose: () => void;
}

const UserBalanceModal: React.FC<Props> = ({ show, handleClose, name, id }) => {
  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const [balances, setBalances] = useState<BalanceItem[]>();
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>();
  const [isEditingEnable, setEditingEnable] = useState<boolean>(false);
  const [userCurrency, setUserCurrency] = useState<string>("USD");

  const loadUserBalance = async (): Promise<void> => {
    const result = await getUserBalance(id);
    if (typeof result !== "string") {
      setBalances(result.balances as BalanceItem[]);
      setUserCurrency(result.balances[0].code as string);
    }
  };
  const loadCurrencyList = async (): Promise<void> => {
    const result = await getCurrencyList();
    if (typeof result !== "string") {
      setCurrencyList(result.data as CurrencyItem[]);
    }
  };
  useEffect(() => {
    loadUserBalance();
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  const handleUserBalanceUpdated = (): void => {
    setEditingEnable(false);
    showSuccessNotif(t("Data edited successfully"));
  };

  const handleCurrencyChange = async (e: string): Promise<void> => {
    const currencyId = currencyList?.filter((el) => el.code === e)[0].id;
    const result = await editUserBalanceCurrency(currencyId || 0, id);
    setUserCurrency(e);
    if (typeof result !== "string") {
      setBalances(result.balances as BalanceItem[]);
      showSuccessNotif(t("Data edited successfully"));
    } else {
      showErrorNotif(result as string);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("User balance")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("Name")}: {name}
        </p>
        <Form.Group className="mb-3">
          <Form.Label>{t("Currency")}</Form.Label>
          <Form.Select
            disabled={
              userRole === UserRoles.operator ||
              userRole === UserRoles["payment-manager"] ||
              userRole === UserRoles["senior-operator"]
            }
            aria-label={t("Currency") || "Currency"}
            value={userCurrency}
            onChange={(e): void => {
              handleCurrencyChange(e.target.value as string);
            }}
          >
            <option value="USD">USD</option>
            <option value="RUB">RUB</option>
            <option value="KZT">KZT</option>
            <option value="IRR">IRR</option>
          </Form.Select>
        </Form.Group>
        {balances?.map((el) => (
          <UserBalanceItem
            key={el.type}
            isDisabled={!isEditingEnable}
            id={id}
            handleUserBalanceUpdated={handleUserBalanceUpdated}
            balance={el}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
        {!isEditingEnable && (
          <Button
            disabled={
              userRole === UserRoles.operator ||
              userRole === UserRoles["payment-manager"] ||
              userRole === UserRoles["senior-operator"]
            }
            variant="primary"
            onClick={(): void => {
              setEditingEnable(true);
            }}
          >
            {t("Edit")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default UserBalanceModal;
