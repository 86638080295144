import ApiClient from "../ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../../types/requestTypes";
import { AdminDocumentPayload, DocumentItem } from "../../types/documentsTypes";

export const getAllDocuments = async (
  page: number,
  id?: string
): Promise<
  | {
      data: DocumentItem[];
      meta: { last_page: number };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: DocumentItem[];
      meta: { last_page: number };
    }>(
      `/documents/kyc/list?per_page=20&page=${page}${
        id ? `&user_id=${id}` : ""
      }`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const changeDocumentStatus = async (
  id: number,
  status: number,
  user_id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/documents/kyc/update/${id}`,
      { status, user_id }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteDocument = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/documents/kyc/delete/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const updateDocumentPhoto = async (
  data: AdminDocumentPayload
): Promise<{ docs: DocumentItem } | string> => {
  const formData = new FormData();
  formData.append("user_id", data.user_id.toString());
  formData.append("document_id", data.document_id.toString());
  if (data.file && data.file_name) {
    formData.append("file", data.file);
    formData.append("file_name", data.file_name);
  }
  try {
    const result = await ApiClient.post<{ docs: DocumentItem }>(
      "/documents/update-kyc-document",
      formData
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    if (response) {
      const errors: string[] = [];
      // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
        // @ts-ignore
        errors.push(el as string);
      });
      return errors.join(" ") as string;
    }
    return "Request error";
  }
};

export const updateCreditCard = async (
  docs: AdminDocumentPayload
): Promise<{ docs: DocumentItem } | string> => {
  const formData = new FormData();
  formData.append("user_id", docs.user_id.toString());
  formData.append("document_id", docs.document_id.toString());
  if (docs.file) {
    formData.append("file", docs.file);
  }
  if (docs.file_name) {
    formData.append("file_name", docs.file_name);
  }
  if (docs.credit_card_number) {
    formData.append("credit_card_number", docs.credit_card_number);
  }
  if (docs.credit_card_month) {
    formData.append("credit_card_month", docs.credit_card_month);
  }
  if (docs.credit_card_year) {
    formData.append("credit_card_year", docs.credit_card_year);
  }
  try {
    const result = await ApiClient.post<{ docs: DocumentItem }>(
      "/documents/update-kyc-document",
      formData
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    if (response) {
      const errors: string[] = [];
      // eslint-disable-next-line
        Object.values(response?.data?.errors).forEach((el) => {
        // @ts-ignore
        errors.push(el as string);
      });
      return errors.join(" ") as string;
    }
    return "Request error";
  }
};
