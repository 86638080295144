import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";

import {
  forgotPassword,
  recoveryPassword,
  forgotPasswordPhone,
  forgotPasswordCheckCode,
  recoveryPasswordPhone,
} from "../../services/apiLogin";

import PhoneInputWrapper from "../forms/PhoneInputWrapper";
import SuccessModal from "../modals/SuccessModal";

import passwordIcon from "../../images/show-password-icon.svg";

enum Stages {
  default = "default",
  sent = "sent",
  confirmation = "confirmation",
  success = "success",
  code = "code",
}

enum Tabs {
  mail = "mail",
  phone = "phone",
}

const limit = 300;

const ChangePassword: React.FC = () => {
  // Conditions
  const [stage, setStage] = useState<Stages>(Stages.default);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.mail);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  const [isPasswordShow, setPasswordShow] = useState<boolean>(false);
  const [isPasswordConfirmShow, setPasswordConfirmShow] =
    useState<boolean>(false);
  // Values
  const [defCountry, setDefCountry] = useState<string>("ru");
  const [mailValue, setMailValue] = useState<string>("");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [codeValue, setCodeValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [passwordConfirmValue, setPasswordConfirmValue] = useState<string>("");
  const [timerValue, setTimerValue] = useState<number>(limit);
  const [token, setToken] = useState<string>("");
  //   Errors
  const [mailError, setMailError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  //   Initial logic
  const getGeoInfo = async (): Promise<void> => {
    await axios.get("https://ipapi.co/json/").then((response) => {
      const { data } = response;
      setDefCountry(data.country_code.toLowerCase() as string);
    });
  };
  useEffect(() => {
    if (window.location.search.includes("token")) {
      setStage(Stages.confirmation);
      setToken(window.location.search.split("=")[1]);
    }
    getGeoInfo();
  }, []);

  //   Timer logic
  useEffect(() => {
    if (timerValue === 0 || !timerStarted) return;
    const intervalId = setInterval(() => {
      setTimerValue(timerValue - 1);
    }, 1000);
    // eslint-disable-next-line
      return () => {
      clearInterval(intervalId);
    };
  }, [timerValue, timerStarted]);

  //   Toggle tabs handler
  const toggleTab = (tab: Tabs): void => {
    setActiveTab(tab);
    setPhoneError("");
    setMailError("");
    setStage(Stages.default);
    setTimerValue(limit);
    setTimerStarted(false);
  };

  //   Validation logic
  const isFormValid = (): boolean => {
    if (!mailValue) {
      setMailError("This field is required");
      return false;
    }
    if (
      mailValue &&
      !mailValue.toLowerCase().match(
        // eslint-disable-next-line
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setMailError("Please enter valid email");
      return false;
    }
    return true;
  };
  const isFormConfirmationValid = (): boolean => {
    if (!passwordValue || !passwordConfirmValue) {
      setPasswordError("Please enter all values");
      return false;
    }
    if (passwordValue !== passwordConfirmValue) {
      setPasswordError("Passwords values should be the same");
      return false;
    }
    return true;
  };

  //   Form submit logic
  const handleSendCode = async (): Promise<void> => {
    setFetching(true);
    const result = await forgotPasswordCheckCode(codeValue);
    if (typeof result === "string") {
      setPhoneError(result as string);
      setFetching(false);
      return;
    }
    setFetching(false);
    setStage(Stages.confirmation);
  };
  const handleSubmit = async (): Promise<void> => {
    if (activeTab === Tabs.phone) {
      setFetching(true);
      const result = await forgotPasswordPhone(phoneValue);
      if (typeof result === "string") {
        setPhoneError(result as string);
        setFetching(false);
        return;
      }
      setTimerStarted(true);
      setFetching(false);
      setStage(Stages.code);
    } else {
      const isValid = isFormValid();
      if (!isValid) return;
      setFetching(true);
      const result = await forgotPassword(mailValue);
      if (typeof result === "string") {
        setMailError(result as string);
        setFetching(false);
        return;
      }
      setStage(Stages.sent);
      setFetching(false);
    }
  };
  const handleSubmitConfirmation = async (): Promise<void> => {
    const isValid = isFormConfirmationValid();
    if (!isValid) return;
    setFetching(true);

    const result =
      activeTab === Tabs.phone
        ? await recoveryPasswordPhone(
            passwordValue,
            passwordConfirmValue,
            codeValue
          )
        : await recoveryPassword(passwordValue, passwordConfirmValue, token);
    if (typeof result === "string") {
      setPasswordError(result as string);
      setFetching(false);
      return;
    }
    document.body.classList.add("no-scroll");
    document.body.classList.add("menu-hidden");
    setStage(Stages.success);
    setFetching(false);
  };

  return (
    <div className="login-page reset-password-page">
      <div className="login-page-container">
        <div className="page-title">{t("Change password")}</div>
        <div className="login-content-wrap">
          {stage === Stages.default && (
            <div className="login-tabs">
              <div
                className={
                  activeTab === Tabs.mail ? "tab-item active" : "tab-item"
                }
                onClick={(): void => {
                  toggleTab(Tabs.mail);
                }}
              >
                {t("Email address")}
              </div>
              <div
                className={
                  activeTab === Tabs.phone ? "tab-item active" : "tab-item"
                }
                onClick={(): void => {
                  toggleTab(Tabs.phone);
                }}
              >
                {t("Phone")}
              </div>
            </div>
          )}
          {stage === Stages.success && (
            <SuccessModal
              title={t("changePasswordTitle") || ""}
              mainTxt={t(
                "You can now use your new password to log into your account."
              )}
              closeModalHandler={(): void => {
                navigate("/");
                document.body.classList.remove("no-scroll");
                document.body.classList.remove("menu-hidden");
              }}
            />
          )}
          {stage === Stages.confirmation && (
            <>
              <div className="login-input-wrap">
                <div className="password-wrap">
                  <input
                    type={isPasswordShow ? "text" : "password"}
                    className={
                      passwordError ? "main-input error" : "main-input"
                    }
                    placeholder={t("New password") || "New password"}
                    value={passwordValue}
                    onChange={(e): void => {
                      setPasswordValue(e.target.value);
                      setPasswordError("");
                    }}
                  />
                  <div
                    className="password-btn"
                    onClick={(): void => {
                      setPasswordShow(!isPasswordShow);
                    }}
                  >
                    <img src={passwordIcon} alt="" />
                  </div>
                </div>
              </div>
              <div className="login-input-wrap">
                <div className="password-wrap">
                  <input
                    type={isPasswordConfirmShow ? "text" : "password"}
                    className={
                      passwordError ? "main-input error" : "main-input"
                    }
                    placeholder={
                      t("Confirm new password") || "Confirm new password"
                    }
                    value={passwordConfirmValue}
                    onChange={(e): void => {
                      setPasswordConfirmValue(e.target.value);
                      setPasswordError("");
                    }}
                  />
                  <div
                    className="password-btn"
                    onClick={(): void => {
                      setPasswordConfirmShow(!isPasswordConfirmShow);
                    }}
                  >
                    <img src={passwordIcon} alt="" />
                  </div>
                </div>
              </div>
              {passwordError && (
                <span className="error-msg">{passwordError}</span>
              )}
              <button
                className="main-btn"
                type="button"
                disabled={isFetching}
                onClick={(): void => {
                  handleSubmitConfirmation();
                }}
              >
                {t("Confirm")}
              </button>
            </>
          )}
          {activeTab === Tabs.mail && (
            <>
              {stage === Stages.default && (
                <>
                  <div className="password-change-txt">
                    {t(
                      "Enter the email address you used when registering on the site"
                    )}
                  </div>
                  <div className="login-input-wrap">
                    <div className="label">{t("Enter your email address")}</div>
                    <input
                      type="email"
                      className={mailError ? "main-input error" : "main-input"}
                      placeholder={t("Email address") || "Email address"}
                      value={mailValue}
                      onChange={(e): void => {
                        setMailValue(e.target.value);
                        setMailError("");
                      }}
                    />
                    {mailError && (
                      <span className="error-msg">{mailError}</span>
                    )}
                  </div>

                  <button
                    className="main-btn"
                    type="button"
                    disabled={isFetching}
                    onClick={(): void => {
                      handleSubmit();
                    }}
                  >
                    {t("Proceed")}
                  </button>
                </>
              )}
              {stage === Stages.sent && (
                <div className="mail-sent-wrapper">
                  <div className="password-change-txt">
                    {t("mailPassswordTxt1")}
                    <span> {mailValue} </span>
                    {t("mailPassswordTxt2")}
                  </div>
                  <div className="reset-block">
                    <div className="txt">
                      {t("Didn't receive instructions?")}
                    </div>
                    <div
                      className="btn"
                      onClick={(): void => {
                        setStage(Stages.default);
                      }}
                    >
                      {t("Resend")}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {activeTab === Tabs.phone && (
            <>
              {stage === Stages.default && (
                <>
                  <div className="password-change-txt">
                    {t(
                      "Enter the phone number you used when registering on the site"
                    )}
                  </div>
                  <div className="login-input-wrap">
                    <div className="label">{t("Enter your phone number")}</div>
                    <PhoneInputWrapper
                      containerClass={
                        phoneError ? "phone-input error" : "phone-input"
                      }
                      country={defCountry}
                      value={phoneValue}
                      onChange={(e): void => {
                        setPhoneValue(e);
                        setPhoneError("");
                      }}
                    />
                    {phoneError && (
                      <span className="error-msg">{phoneError}</span>
                    )}
                  </div>
                  <button
                    className="main-btn"
                    type="button"
                    disabled={isFetching}
                    onClick={(): void => {
                      handleSubmit();
                    }}
                  >
                    {t("Get SMS")}
                  </button>
                </>
              )}
              {stage === Stages.code && (
                <>
                  <div className="code-sent-txt">
                    <div className="txt">
                      {t("We have sent a verification code.")}
                    </div>
                    <div className="timer">
                      {t("The code will be valid for")}
                      <span>
                        {" "}
                        {timerValue} {t("sec")}
                      </span>
                    </div>
                  </div>
                  <ReactInputVerificationCode
                    length={5}
                    value={codeValue}
                    onChange={(e): void => {
                      setCodeValue(e);
                    }}
                    placeholder="_"
                    passwordMask="_"
                  />
                  {phoneError && (
                    <span className="error-msg">{phoneError}</span>
                  )}
                  <button
                    className="main-btn"
                    type="button"
                    disabled={
                      isFetching || timerValue >= limit || codeValue.length < 5
                    }
                    onClick={(): void => {
                      handleSendCode();
                    }}
                  >
                    {t("Confirm")}
                  </button>
                  {timerValue !== 0 ? (
                    <div className="sms-timer">
                      <span>{t("Resubmission is possible via")}</span>
                      <p>
                        {timerValue} {t("sec")}
                      </p>
                    </div>
                  ) : (
                    <div className="reset-block code">
                      <div className="txt">{t("Didn't receive code?")}</div>
                      <div
                        className="btn"
                        onClick={(): void => {
                          setTimerStarted(false);
                          setTimerValue(limit);
                          setStage(Stages.default);
                        }}
                      >
                        {t("Resend")}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
