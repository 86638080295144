import { MAX_SIZE_FILE } from "../../constants/constants";
import { Validation } from "./type";

const regex = /(jpg|jpeg|png)$/i;

const validateLoadFile = (file: File): Validation => {
  const typeFile = file.type.split("/")[1];
  const isValid = true;
  const message = "";

  if (!regex.test(typeFile)) {
    return {
      isValid: false,
      message: "Valid formats are png, jpeg, jpg.",
    };
  }
  if (file.size > MAX_SIZE_FILE) {
    return {
      isValid: false,
      message: "Valid size 8192 Kb",
    };
  }
  return {
    isValid,
    message,
  };
};

export default validateLoadFile;
