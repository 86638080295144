/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import React, { useState } from "react";
import { t } from "i18next";
import styles from "./ComfirmPersonalDataStyles.module.scss";
import ModalTitle from "../../UI/ModalTitle/ModalTitle";
import TextField from "../../UI/TextField/TextField";
import CustomSelectCountries from "../../forms/CustomSelectCountries/CustomSelectCountries";
import CustomSelect, { Option } from "../../forms/CustomSelect/CustomSelect";
import CustomDatePicker from "../../forms/CustomDatePicker/CustomDatePicker";
import MainButton from "../../UI/MainButton/MainButton";
import { UpdateInfoPayload } from "../../../types/userTypes";
import { updateUserData } from "../../../services/apiLogin";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../store/slices/userData";
import validateName from "../../../helpers/validate/validateName";
import ErrorModal from "../ErrorModal";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";

export enum Genders {
  male = "Male",
  female = "Female",
}

enum Fields {
  first_name = "first_name",
  last_name = "last_name",
  birthday = "birthday",
  // country = "country",
  gender = "gender",
}

interface ConfirmPersonalDataModalProps {
  onClose: () => void;
}

const genders: Option[] = [
  { value: "f", title: Genders.female },
  { value: "m", title: Genders.male },
];

const ConfirmPersonalDataModal: React.FC<ConfirmPersonalDataModalProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string>("");
  const [isValidateFirstName, setIsValidateFirstName] = useState(
    validateName(firstName)
  );
  const [lastName, setLastName] = useState<string>("");
  const [isValidateLastName, setIsValidateLastName] = useState(
    validateName(lastName)
  );
  const [gender, setGender] = useState<Option>();
  const [dateBirth, setDateBirth] = useState<string>("");
  const [errors, setErrors] = useState<string | undefined>();
  // const [country, setCountry] = useState<CountryItem | undefined>();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const dispatch = useDispatch();

  const onChangeFirstName = (value: string): void => {
    setFirstName(value);
    setIsValidateFirstName(validateName(value));
  };

  const onChangeLastName = (value: string): void => {
    setLastName(value);
    setIsValidateLastName(validateName(value));
  };

  const updateProfile = async (data: UpdateInfoPayload): Promise<void> => {
    const result = await updateUserData(data);
    if (typeof result !== "string") {
      dispatch(setUserData(result.user));
      onClose();
      navigate("/profile");
    } else {
      setErrors(result as string);
      document.body.classList.add("no-scroll");
      document.body.classList.add("menu-hidden");
    }
  };

  const handlerSubmit = (): void => {
    if (
      gender &&
      !isValidateFirstName.isValid &&
      !isValidateLastName.isValid &&
      dateBirth &&
      firstName &&
      lastName
      // country
    ) {
      const data: UpdateInfoPayload = {
        gender: gender.value,
        date_of_birth: dateBirth,
        // country_id: country.id.toString(),
        last_name: lastName,
        first_name: firstName,
      };
      updateProfile(data);
    }
  };
  return (
    <div
      className={`${styles.wrapper} ${
        localeActive?.code === "fa" ? styles.rtl : ""
      }`}
    >
      <ModalTitle onClose={onClose} title="Confirmation personal data" />
      <div className={styles.subtitle}>
        {t("To continue, please fill out the form below")}
      </div>
      <div className={styles.form}>
        <TextField
          value={firstName}
          onChange={onChangeFirstName}
          placeholder="Enter a name"
          isValidate={isValidateFirstName.isValid}
          helperText={isValidateFirstName.message}
        />
        <TextField
          value={lastName}
          onChange={onChangeLastName}
          placeholder="Enter a last name"
          isValidate={isValidateLastName.isValid}
          helperText={isValidateLastName.message}
        />
        {/* <CustomSelectCountries
          onChange={(value: CountryItem): void => setCountry(value)}
        /> */}
        <CustomSelect
          items={genders}
          placeholder={t("Choose your gender")}
          handleSelect={(e): void => {
            setGender(e);
          }}
          selectedItem={gender}
        />
        <div className={styles.box_date}>
          <div className={styles.subtitle_date_birth}>{t("Date of birth")}</div>
          <CustomDatePicker
            handleDateChange={(e: string): void => {
              setDateBirth(e);
            }}
          />
        </div>
        <MainButton
          title={t("Confirm")}
          onClick={handlerSubmit}
          className={styles.btn}
          color="primary"
          variant="contained"
          disabled={
            !gender ||
            isValidateFirstName.isValid ||
            isValidateLastName.isValid ||
            !dateBirth ||
            !firstName ||
            !lastName
            // !country
          }
        />
        <div className={styles.info}>
          <div className={styles.notification}>
            {t("We would like to inform you that the personal data")}
            <span>{t("Please enter your personal data only once!")}</span>
          </div>
          <div className={styles.support}>
            {`${t("Further data correction is carried out with the help of")} `}
            <a href="mailto:support@boomcasino.bet">{t("Support Services")}</a>
          </div>
        </div>
      </div>
      {errors && (
        <ErrorModal
          btnTxt="OK"
          mainTxt={errors}
          closeModalHandler={(): void => {
            setErrors(undefined);
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("menu-hidden");
          }}
          confirmModalHandler={(): void => {
            setErrors(undefined);
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("menu-hidden");
          }}
        />
      )}
    </div>
  );
};

export default ConfirmPersonalDataModal;
