import { useTranslation } from "react-i18next";
import { VipClub } from "../types/userTypes";
import level0 from "../images/vip-icons/vip-level-0.svg";
import level1 from "../images/vip-icons/vip-level-1.svg";
import level2 from "../images/vip-icons/vip-level-2.svg";
import level3 from "../images/vip-icons/vip-level-3.svg";
import level4 from "../images/vip-icons/vip-level-4.svg";
import level5 from "../images/vip-icons/vip-level-5.svg";
import defAvatar from "../images/vip-icons/def-icon.svg";

type StylesVipStatusType = {
  imageSource: string;
  bgColorStatus: string;
  nameStatus: string;
  bgColorProgress: string;
  leftPosition: string;
  imagePosition: string;
  progressWidth: string;
  transformIcon: string;
};

const useGetVipStatusStyles = (
  vipClub: VipClub | undefined
): StylesVipStatusType => {
  const progressWidth =
    vipClub && vipClub.current_amount && vipClub.value_for_complete
      ? `${(100 * vipClub.current_amount) / vipClub.value_for_complete}%`
      : "0%";

  const imagePosition = progressWidth !== "0%" ? progressWidth : "0px";
  const { t } = useTranslation();

  let imageSource = "";
  let bgColorStatus = "";
  let nameStatus = "";
  let bgColorProgress = "";
  let leftPosition = "";
  let transformIcon = "";

  switch (vipClub?.level) {
    case 0:
      imageSource = level0;
      bgColorStatus =
        "linear-gradient(180deg, rgba(133, 163, 255, 1) 0%, rgba(255, 255, 255, 1) 90.9%)";
      bgColorProgress =
        "linear-gradient(180deg, rgba(133, 163, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 90.9%)";
      nameStatus = t("To get VIP status");
      break;
    case 1:
      imageSource = level1;
      bgColorStatus =
        "linear-gradient(180deg, rgba(46, 215, 4, 1) 13.63%, rgba(0, 255, 194, 1) 90.9%)";
      bgColorProgress =
        "linear-gradient(180deg, rgba(46, 215, 4, 0.4) 13.63%, rgba(0, 255, 194, 0.4) 90.9%)";
      nameStatus = "Новичок";
      break;
    case 2:
      imageSource = level2;
      bgColorStatus =
        "linear-gradient(33.56deg, rgba(246, 223, 0, 1) 42.64%, rgba(239, 119, 0, 1) 110.74%)";
      bgColorProgress =
        "linear-gradient(33.56deg, rgba(246, 223, 0, 0.4) 42.64%, rgba(239, 119, 0, 0.4) 110.74%)";
      nameStatus = "Мастер";
      break;
    case 3:
      imageSource = level3;
      bgColorStatus =
        "linear-gradient(180deg, rgba(244, 62, 38, 1) 0%, rgba(242, 167, 0, 1) 100%)";
      bgColorProgress =
        "linear-gradient(180deg, rgba(244, 62, 38, 0.4) 0%, rgba(242, 167, 0, 0.4) 100%)";
      nameStatus = "Фаворит";
      break;
    case 4:
      imageSource = level4;
      bgColorStatus =
        "linear-gradient(180deg, rgba(24, 157, 255, 1) 0%, rgba(68, 200, 245, 1) 100%)";
      bgColorProgress =
        "linear-gradient(180deg, rgba(24, 157, 255, 0.4) 0%, rgba(68, 200, 245, 0.4) 100%)";
      nameStatus = "Про";
      break;
    case 5:
      imageSource = level5;
      bgColorStatus =
        "linear-gradient(180deg, rgba(246, 223, 0, 1) 0%, rgba(242, 167, 0, 1) 100%)";
      nameStatus = "Чемпион";
      bgColorProgress =
        "linear-gradient(180deg, rgba(246, 223, 0, 1) 0%, rgba(242, 167, 0, 1) 100%)";
      leftPosition = "50%";
      transformIcon = "translate(-50%, 0)";
      break;
    default:
      imageSource = defAvatar;
      break;
  }
  return {
    imageSource,
    bgColorStatus,
    nameStatus,
    bgColorProgress,
    leftPosition,
    progressWidth,
    imagePosition,
    transformIcon,
  };
};

export default useGetVipStatusStyles;
