import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate } from "react-router-dom";
import { useDetectClickOutside } from "react-detect-click-outside";

import type { RootState } from "../../store/store";

interface Props {
  title: string;
  maxBet: string;
  wagering_hours: number;
  bet_amount: number;
  wagering_amount: number;
  id: number;
  is_confirmed: number;
  collectBonusHandler: (id: number) => void;
  detachBonusHandler: (id: number) => void;
}

const MyBonusItem: React.FC<Props> = ({
  title,
  maxBet,
  wagering_hours,
  bet_amount,
  wagering_amount,
  id,
  is_confirmed,
  collectBonusHandler,
  detachBonusHandler,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentBalance = useSelector(
    (state: RootState) => state.user.userInfo.current_balance
  );

  const [wagering, setWagering] = useState<number>();
  const [tooltipShown, setTooltipShown] = useState<boolean>(false);

  useEffect(() => {
    if (wagering_hours) setWagering(wagering_hours);
    // eslint-disable-next-line
  }, []);

  const handleShow = (): void => {
    setTooltipShown(true);
  };
  const handleHide = (): void => {
    setTooltipShown(false);
  };

  const ref = useDetectClickOutside({
    onTriggered: handleHide,
  });

  return (
    <div className={is_confirmed ? "my-bonus-item collect" : "my-bonus-item"}>
      <div className="tooltip-wrap" ref={ref}>
        <div
          className="tooltip-btn"
          onClick={(): void => {
            if (tooltipShown) {
              handleHide();
            } else {
              handleShow();
            }
          }}
        >
          i
        </div>
        {tooltipShown && (
          <div className="tooltip-content">
            {t("bonusTooltip", {
              amount: maxBet,
            })}
          </div>
        )}
      </div>
      <div
        className="title"
        onClick={(): void => {
          navigate(`/bonus/${id}`);
        }}
      >
        {title}
      </div>
      <div className="wagering-amount">
        <span>{t("Wagering")}</span>
        <span>{`${
          getSymbolFromCurrency(currentBalance?.code || "USD") as string
        }${bet_amount} / ${
          getSymbolFromCurrency(currentBalance?.code || "USD") as string
        }${wagering_amount}`}</span>
      </div>
      <div className="progress-bar">
        <div
          className="bar"
          style={{
            width: `${(100 * bet_amount) / wagering_amount}%`,
          }}
        />
      </div>
      <div className="footer-item">
        {!is_confirmed && (
          <div className="timer">
            <span className="timer-title">{t("Until the end left")}</span>
            {wagering && <Countdown date={Date.now() + wagering} />}
          </div>
        )}
        {is_confirmed ? (
          <button
            className="primary-btn"
            type="button"
            onClick={(): void => {
              collectBonusHandler(id);
            }}
          >
            {t("Collect bonus")}
          </button>
        ) : (
          <button
            className="light-btn"
            type="button"
            onClick={(): void => {
              detachBonusHandler(id);
            }}
          >
            {t("Deactivate")}
          </button>
        )}
      </div>
    </div>
  );
};

export default MyBonusItem;
