import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userData";
import gamesReducer from "./slices/games";
import cardGamesReducer from "./slices/cardGames";
import liveGamesReducer from "./slices/liveGames";
import slotGamesReducer from "./slices/slotGames";
import lobbyGamesReducer from "./slices/lobbyGames";

export const store = configureStore({
  reducer: {
    user: userReducer,
    games: gamesReducer,
    cardGames: cardGamesReducer,
    slotGames: slotGamesReducer,
    liveGames: liveGamesReducer,
    lobbyGames: lobbyGamesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
