import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatDateMinutes } from "../../helpers/getCorrectDate";

interface Props {
  value?: string;
  date?: string;
  id?: number;
  handleCommentChange: (e: string, id: number) => void;
  handleCommentRemove: (id: number) => void;
  handleCommentAdded: (e: string) => void;
  isNew?: boolean;
  disabled?: boolean;
}

const UserCommentItem: React.FC<Props> = ({
  value,
  date,
  id,
  handleCommentChange,
  handleCommentRemove,
  handleCommentAdded,
  isNew,
  disabled,
}) => {
  const { t } = useTranslation();

  const [formValue, setFormValue] = useState<string>("");
  const [editEnable, setEditEnable] = useState<boolean>(false);

  useEffect(() => {
    if (value) setFormValue(value);
  }, [value]);

  return (
    <div className="user-comment-item">
      <div className="user-comment-info">{date && formatDateMinutes(date)}</div>
      <div className="user-comment-form">
        <Form.Control
          as="textarea"
          rows={3}
          value={formValue}
          onChange={(e): void => {
            setFormValue(e.target.value);
          }}
          disabled={!editEnable && !isNew}
        />
        {isNew ? (
          <Button
            variant="primary"
            onClick={(): void => {
              handleCommentAdded(formValue);
            }}
            disabled={disabled}
          >
            {t("Add")}
          </Button>
        ) : (
          <>
            <Button
              variant="primary"
              disabled={disabled}
              onClick={(): void => {
                if (!editEnable) {
                  setEditEnable(true);
                } else {
                  handleCommentChange(formValue, id || 0);
                }
              }}
            >
              {t("Edit")}
            </Button>
            <Button
              variant="danger"
              onClick={(): void => {
                handleCommentRemove(id || 0);
              }}
              disabled={disabled}
            >
              {t("Remove")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default UserCommentItem;
