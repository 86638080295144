import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { formatDateDay } from "../../../helpers/getCorrectDate";

import { getActiveUsers } from "../../../services/admin/apiReporting";
import { UserInfo } from "../../../types/userTypes";

const ActiveUsers: React.FC = () => {
  // Users data
  const [users, setUsers] = useState<UserInfo[]>();
  const [isFetching, setFetching] = useState<boolean>(false);

  const { t } = useTranslation();

  //   Users logic
  const loadUsers = async (): Promise<void> => {
    setFetching(true);
    const result = await getActiveUsers();
    if (typeof result !== "string") {
      setUsers(result.data.users as UserInfo[]);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!users) {
      loadUsers();
    }
    // eslint-disable-next-line
  }, [users]);

  return (
    <>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {users && !isFetching && (
        <Table
          striped
          bordered
          className="mb-5"
          responsive
          data-testid="active-users-table"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("Name")}</th>
              <th>{t("Email address")}</th>
              <th>{t("Phone")}</th>
              <th>{t("Date of birth")}</th>
              <th>{t("Registration date")}</th>
              <th>{t("Country")}</th>
              <th>{t("City")}</th>
              <th>{t("Gender")}</th>
              <th>{t("Blocked")}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>
                  {el.first_name} {el.last_name}
                </td>
                <td>{el.email}</td>
                <td>{el.phone}</td>
                <td>
                  {el.date_of_birth
                    ? formatDateDay(el.date_of_birth as string)
                    : ""}
                </td>
                <td>
                  {el.created_at ? formatDateDay(el.created_at as string) : ""}
                </td>
                <td>{el?.country?.name as string}</td>
                <td>{el.city}</td>
                <td>{el.gender}</td>
                <td>{el.is_blocked ? "+" : "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ActiveUsers;
