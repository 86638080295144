/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./ContentPageStyle.module.scss";
import type { RootState } from "../../store/store";

const AboutUs: React.FC = () => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className={styles.content_page_wrap}>
      <div className={styles.content_gradient} />
      <div className={styles.content_page_box}>
        <div
          className={`${styles.container} ${
            localeActive?.code === "fa" ? styles.rtl : ""
          }`}
        >
          <h1>{t("About Us")}</h1>
          <div className={styles.content_page_block}>
            <p className={styles.subtitle_aside}>{t("Our Team")}</p>
            <span>
              {t(
                "Boom Casino is a team of wonderful people who strive to provide our users with enjoyment and fair play. We know exactly how to create unforgettable entertainment! Our top priority is maintaining an impeccable reputation. The mission of our company is to guarantee reliability and transparency for every one of our users. We show maximum loyalty in resolving any issues and guarantee the payout of all winnings, regardless of their size and frequency!"
              )}
            </span>
          </div>
          <div className={styles.content_page_block}>
            <p className={styles.subtitle_aside}>
              {t("Who Are We - Boom Casino?")}
            </p>
            <span>
              {t(
                "Boom Casino offers a vast selection of exclusive bonuses, loyalty programs, and special offers for both our regular and new users. We guarantee that you have made an excellent choice by joining the world of gambling at Boom Casino. Test your luck and win big sums in various games and slots!"
              )}
            </span>
          </div>
          <div className={styles.content_page_block}>
            <p className={styles.subtitle_aside}>{t("Support")}</p>
            <span>
              {t(
                "Boom Casino provides round-the-clock support to its clients and guests. Our experienced support team is always ready to help you resolve any issues in the shortest time possible - we are available 24/7! In addition, our online chat is always available for your convenience."
              )}
            </span>
          </div>
          <div className={styles.content_page_block}>
            <p className={styles.subtitle_aside}>{t("Contact Us:")}</p>
            <span>{t("Company: LEON")}</span>
            <br />
            <span>
              {t("Address: Vilnius m. sav. Vilniaus m. A. Vienuollo g. 4")}
            </span>
            <br />
            <span>{t("Email: support@boomcasino.bet")}</span>
            <br />
            <span>{t("Phone: +77273506190 | +79651437286")}</span>
            <br />
          </div>
          <div className={styles.content_page_block}>
            <span>{t("Good luck!")}</span>
            <br />
            <span>{t("With love, your Boom Casino.")}</span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
