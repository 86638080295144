import star1 from "../images/welcomeBonus/starsIcons/star1.svg";
import star2 from "../images/welcomeBonus/starsIcons/star2.svg";
import star3 from "../images/welcomeBonus/starsIcons/star3.svg";
import star4 from "../images/welcomeBonus/starsIcons/star4.svg";
import star5 from "../images/welcomeBonus/starsIcons/star5.svg";
import star6 from "../images/welcomeBonus/starsIcons/star6.svg";

type ConditionType = {
  img: string;
  title: string;
  description: string;
};

const WELCOME_BONUS_CONDITIONS: ConditionType[] = [
  {
    img: star1,
    title: "120% of your deposit sum",
    description:
      // eslint-disable-next-line max-len
      "If you deposit 100 units, you will receive an additional 120 units to your gaming account, making your balance 220 units.",
  },
  {
    img: star2,
    title: "50 Free Spins",
    description:
      "You will also get 50 free spins on the popular Fortune Craft game.",
  },
  {
    img: star3,
    title: "Bonus activation within 10 days from the moment of registration",
    description:
      // eslint-disable-next-line max-len
      "You can receive a bonus within the first 10 days from the moment of registration.",
  },
  {
    img: star4,
    title: "The bonus is valid for 3 days from the moment of activation",
    description:
      "You must complete all requirements related to the bonus within this period.",
  },
  {
    img: star5,
    title: "Wager - X40",
    description:
      // eslint-disable-next-line max-len
      "In order to withdraw your winnings, you must bet 40 times the bonus amount before you can withdraw your winnings.",
  },
  {
    img: star6,
    title: "Cashout - X1",
    description:
      "For example, when wagering 1000 units and x5 cashout, the player can withdraw a maximum of 5000 if all conditions are met",
  },
];

export default WELCOME_BONUS_CONDITIONS;
