import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./welcomeBonusDescriptionStyle.module.scss";
import WELCOME_BONUS_CONDITIONS from "../../constants/welcomeBonusConditions";

import { RootState } from "../../store/store";

const WelcomeBonusDescription: React.FC = () => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  return (
    <div
      className={`${styles.conditions_container} ${
        localeActive?.code === "fa" ? styles.rtl : ""
      }`}
    >
      {WELCOME_BONUS_CONDITIONS.map((condition) => {
        return (
          <div className={styles.condition} key={condition.title}>
            <div className={styles.condition_title}>
              <div className={styles.image_container}>
                <img src={condition.img} alt={condition.title} />
              </div>
              <div className={styles.title}>{t(condition.title)}</div>
            </div>
            <div className={styles.condition_description}>
              {t(condition.description)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WelcomeBonusDescription;
