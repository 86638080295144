import React, { useState, useEffect } from "react";
import { Form, Button, Col, Nav, Row, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import type { RootState } from "../store/store";
import { LocaleItem, UserRoles } from "../types/userTypes";
import { updateUserData, login, getUserData } from "../services/apiLogin";
import {
  setActiveLocal,
  setUserToken,
  setUserData,
  loggedAction,
} from "../store/slices/userData";
import { AdminPanelTabs } from "../types/adminTypes";

import "../styles/AdminPanel.scss";
import UsersTab from "../components/adminPanel/UsersTab";
import BetsTab from "../components/adminPanel/BetsTab";
import FreeSpinsTab from "../components/adminPanel/FreeSpinsTab";
import PaymentsTab from "../components/adminPanel/PaymentsTab";
import TournamentsTab from "../components/adminPanel/TournamentsTab";
import DocumentsTab from "../components/adminPanel/DocumentsTab";
import ReportingTab from "../components/adminPanel/ReportingTab";
import GamesTab from "../components/adminPanel/GamesTab";
import VipClubTab from "../components/adminPanel/VipClubTab";
import BonusesTab from "../components/adminPanel/BonusesTab";
import PromoTab from "../components/adminPanel/PromoTab";

const AdminPanel: React.FC = () => {
  const [activeLang, setActiveLang] = useState<LocaleItem>({
    code: "en",
  } as LocaleItem);
  const [globalError, setGlobalError] = useState<string>("");
  const [mailValue, setMailValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [isFetching, setFetching] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<AdminPanelTabs>(
    AdminPanelTabs.users
  );

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localesList = useSelector((state: RootState) => state.user.localesList);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  useEffect(() => {
    if (localeActive && localeActive !== activeLang) {
      setActiveLang(localeActive);
    }
  }, [localeActive, activeLang]);

  const changleLangHandler = (lang: LocaleItem): void => {
    setActiveLang(lang);
    i18n.changeLanguage(lang.code as string);
    localStorage.setItem("localeLang", lang.code);
    dispatch(setActiveLocal(lang));
    if (isLogged) updateUserData({ locale_id: lang.id });
  };

  const isFormValid = (): boolean => {
    if (!mailValue || !passwordValue) {
      setGlobalError("All fields is required");
      return false;
    }
    if (
      mailValue &&
      !mailValue.toLowerCase().match(
        // eslint-disable-next-line
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setGlobalError("Please enter valid email");
      return false;
    }
    return true;
  };
  const handleLogin = async (): Promise<void> => {
    const result = await getUserData();
    if (typeof result === "string") {
      setGlobalError(result as string);
      return;
    }
    if (result.user.locale) {
      dispatch(setActiveLocal(result.user.locale));
      i18n.changeLanguage(result.user.locale.code as string);
      localStorage.setItem("localeLang", result.user.locale.code);
    }
    dispatch(setUserData(result.user));
    dispatch(loggedAction(true));
  };
  const handleSubmit = async (): Promise<void> => {
    const isValid = isFormValid();
    // Login logic
    if (isValid) {
      setFetching(true);
      const result = await login({ email: mailValue, password: passwordValue });
      if (typeof result === "string") {
        setGlobalError(result as string);
        setFetching(false);
        return;
      }
      dispatch(setUserToken(result.access_token as string));
      await handleLogin();
      setFetching(false);
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body?.classList.add("admin-panel-wrapper");
    return () => {
      body?.classList.remove("admin-panel-wrapper");
    };
  }, []);

  useEffect(() => {
    if (userRole === UserRoles.player) {
      navigate("/");
    }
  }, [userRole, navigate]);

  return (
    <div className="admin-page">
      <div className="admin-page-menu">
        <div
          className="admin-logo"
          onClick={(): void => {
            navigate("/");
          }}
        >
          Home
        </div>
        <div className="admin-langs">
          {localesList.length > 0 &&
            localesList.map((el) => (
              <span
                className={el.code === activeLang.code ? "active" : ""}
                key={el.id}
                onClick={(): void => {
                  changleLangHandler(el);
                }}
              >
                {el.code === "kk" ? "KZ" : el?.code?.toUpperCase()}
              </span>
            ))}
        </div>
      </div>
      {isLogged && userRole !== UserRoles.player ? (
        <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
          <Row className="admin-tabs-wrap">
            <Col md={2}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.users}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.users);
                    }}
                  >
                    {t("Users")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.games}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.games);
                    }}
                  >
                    {t("Games")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.bets}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.bets);
                    }}
                  >
                    {t("Bets")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.spins}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.spins);
                    }}
                  >
                    {t("Free spins")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.payments}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.payments);
                    }}
                  >
                    {t("Payments")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.tournaments}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.tournaments);
                    }}
                  >
                    {t("Tournaments")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.docs}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.docs);
                    }}
                  >
                    {t("Documents")}
                  </Nav.Link>
                </Nav.Item>
                {(userRole === UserRoles.admin ||
                  userRole === UserRoles["retention-manager"]) && (
                  <Nav.Item>
                    <Nav.Link
                      eventKey={AdminPanelTabs.reports}
                      onClick={(): void => {
                        setActiveTab(AdminPanelTabs.reports);
                      }}
                    >
                      {t("Reporting")}
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.vipClub}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.vipClub);
                    }}
                  >
                    {t("Rakeback")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={AdminPanelTabs.bonuses}
                    onClick={(): void => {
                      setActiveTab(AdminPanelTabs.bonuses);
                    }}
                  >
                    {t("Promotions")}
                  </Nav.Link>
                </Nav.Item>
                {(userRole === UserRoles.admin ||
                  userRole === UserRoles["retention-manager"]) && (
                  <Nav.Item>
                    <Nav.Link
                      eventKey={AdminPanelTabs.promo}
                      onClick={(): void => {
                        setActiveTab(AdminPanelTabs.promo);
                      }}
                    >
                      {t("Promo codes")}
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Col>
            <Col md={10}>
              <Tab.Content>
                <Tab.Pane eventKey={AdminPanelTabs.users}>
                  <UsersTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.games}>
                  <GamesTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.bets}>
                  <BetsTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.spins}>
                  <FreeSpinsTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.payments}>
                  <PaymentsTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.tournaments}>
                  <TournamentsTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.docs}>
                  <DocumentsTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.reports}>
                  <ReportingTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.vipClub}>
                  <VipClubTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.bonuses}>
                  <BonusesTab activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey={AdminPanelTabs.promo}>
                  <PromoTab activeTab={activeTab} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      ) : (
        <div className="admin-page-login">
          <div className="admin-login-wrap">
            <h3>{t("Log in")}</h3>
            <Form.Group className="mb-3">
              <Form.Label>{t("Email address")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("Email address") || "Email address"}
                value={mailValue}
                onChange={(e): void => {
                  setMailValue(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Password") || "Password"}
                value={passwordValue}
                onChange={(e): void => {
                  setPasswordValue(e.target.value);
                }}
              />
            </Form.Group>
            {globalError && <span className="error-msg">{globalError}</span>}
            <Button
              disabled={isFetching}
              variant="primary"
              onClick={(): void => {
                handleSubmit();
              }}
            >
              {t("Confirm")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
