import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store'
import { Provider } from 'react-redux'
import "./i18n";
import FacebookPixel from './helpers/FacebookPixel';
import GoogleTagManagerRU from './helpers/metrics/GoogleTagManagerRU';
import GoogleTagManagerKZ from './helpers/metrics/GoogleTagManagerKZ';
import GoogleTagManagerIR from './helpers/metrics/GoogleTagManagerIR';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <FacebookPixel/>
    <GoogleTagManagerRU/>
    <GoogleTagManagerKZ/>
    <GoogleTagManagerIR/>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
