import React, { useState, useEffect } from "react";
import { Tabs, Tab, Table, Button, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CirclesWithBar } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { formatDateMinutes } from "../../helpers/getCorrectDate";
import { AdminPanelTabs, BonusesTabs } from "../../types/adminTypes";
import { BonusItem, BonusRewardItem } from "../../types/bonusesTypes";
import {
  getBonusesList,
  getBonusesRewardList,
} from "../../services/admin/apiBonuses";
import type { RootState } from "../../store/store";
import { UserRoles } from "../../types/userTypes";

import AddBonusRewardModal from "./modal/AddBonusRewardModal";
import AddBonusModal from "./modal/AddBonusModal";
import DeleteRewardModal from "./modal/DeleteRewardModal";
import DeleteBonusModal from "./modal/DeleteBonusModal";

interface Props {
  activeTab: AdminPanelTabs;
}

const BonusesTab: React.FC<Props> = ({ activeTab }) => {
  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const [activeBonusTab, setActiveBonusTab] = useState<BonusesTabs>(
    BonusesTabs.bonus
  );
  const [showRewardModal, setShowRewardModal] = useState<boolean>(false);
  const [showBonusModal, setShowBonusModal] = useState<boolean>(false);
  const [showRewardModalDelete, setShowRewardModalDelete] =
    useState<boolean>(false);
  const [showBonusModalDelete, setShowBonusModalDelete] =
    useState<boolean>(false);
  //   Bonuses data
  const [isBonusFetching, setIsBonusFetching] = useState<boolean>(false);
  const [bonusesList, setBonusesList] = useState<BonusItem[]>();
  const [bonusForEdit, setBonusForEdit] = useState<BonusItem>();
  const [activeBonusPage, setActiveBonusPage] = useState<number>(0);
  const [totalBonusPages, setTotalBonusPages] = useState<number>(1);
  //   Rewards data
  const [bonusesRewardsList, setBonusesRewardsList] =
    useState<BonusRewardItem[]>();
  const [rewardForEdit, setRewardForEdit] = useState<BonusRewardItem>();
  const [isBonusRewardFetching, setIsBonusRewardFetching] =
    useState<boolean>(false);
  const [activeBonusRewardPage, setActiveBonusRewardPage] = useState<number>(0);
  const [totalBonusRewardPages, setTotalBonusRewardPages] = useState<number>(1);

  const loadBonuses = async (page: number): Promise<void> => {
    setIsBonusFetching(true);
    const result = await getBonusesList(page + 1);
    if (typeof result !== "string") {
      setBonusesList(result.data as BonusItem[]);
      setTotalBonusPages(result.meta.last_page as number);
    }
    setIsBonusFetching(false);
  };
  const loadRewards = async (page: number): Promise<void> => {
    setIsBonusRewardFetching(true);
    const result = await getBonusesRewardList(page + 1);
    if (typeof result !== "string") {
      setBonusesRewardsList(result.data as BonusRewardItem[]);
      setTotalBonusRewardPages(result.meta.last_page as number);
    }
    setIsBonusRewardFetching(false);
  };
  useEffect(() => {
    if (
      !bonusesList &&
      activeTab === AdminPanelTabs.bonuses &&
      activeBonusTab === BonusesTabs.bonus
    ) {
      loadBonuses(0);
    }
    if (
      !bonusesRewardsList &&
      activeTab === AdminPanelTabs.bonuses &&
      activeBonusTab === BonusesTabs.reward
    ) {
      loadRewards(0);
    }
    // eslint-disable-next-line
  }, [activeTab, bonusesList, activeBonusTab, bonusesRewardsList]);

  const handlePaginationBonusChange = (e: number): void => {
    setActiveBonusPage(e);
    loadBonuses(e);
  };
  const handlePaginationRewardChange = (e: number): void => {
    setActiveBonusRewardPage(e);
    loadRewards(e);
  };

  //   Modals logic
  const showReward = (): void => {
    setShowRewardModal(true);
  };
  const hideReward = (): void => {
    setShowRewardModal(false);
    setRewardForEdit(undefined);
  };
  const showBonus = (): void => {
    setShowBonusModal(true);
  };
  const hideBonus = (): void => {
    setShowBonusModal(false);
    setBonusForEdit(undefined);
  };
  const hideRewardDelete = (): void => {
    setShowRewardModalDelete(false);
    setRewardForEdit(undefined);
  };
  const hideBonusDelete = (): void => {
    setShowBonusModalDelete(false);
    setBonusForEdit(undefined);
  };

  const handleRewardAdded = (): void => {
    setShowRewardModal(false);
    handlePaginationRewardChange(0);
  };
  const handleRewardEdited = (): void => {
    setShowRewardModal(false);
    handlePaginationRewardChange(activeBonusRewardPage);
    setRewardForEdit(undefined);
  };
  const handleBonusAdded = (): void => {
    setShowBonusModal(false);
    handlePaginationBonusChange(0);
  };
  const handleBonusEdited = (): void => {
    setShowBonusModal(false);
    handlePaginationBonusChange(activeBonusPage);
    setBonusForEdit(undefined);
  };

  const handleRewardDeleted = (): void => {
    setShowRewardModalDelete(false);
    handlePaginationRewardChange(0);
    setRewardForEdit(undefined);
  };
  const handleBonusDeleted = (): void => {
    setShowBonusModalDelete(false);
    handlePaginationBonusChange(0);
    setBonusForEdit(undefined);
  };

  return (
    <div className="bonuses-admin-wrap admin-tab-warp">
      <h3>{t("Promotions")}</h3>
      <Tabs
        defaultActiveKey={activeBonusTab}
        className="mb-3"
        data-testid="bonuses-tabs"
        onSelect={(e): void => setActiveBonusTab(e as BonusesTabs)}
      >
        <Tab eventKey={BonusesTabs.bonus} title={t("Promotions")}>
          <Button
            variant="primary"
            className="mb-3"
            onClick={showBonus}
            data-testid="bonus-admin-add"
            disabled={userRole !== UserRoles.admin}
          >
            + {t("Add new promotion")}
          </Button>
          {activeBonusTab === BonusesTabs.bonus && (
            <>
              {isBonusFetching && (
                <CirclesWithBar
                  height="200"
                  width="200"
                  color="#0d6efd"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              )}
              {!isBonusFetching && (
                <>
                  <Table striped bordered className="mb-5" responsive>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>{t("Title")}</th>
                        <th>{t("Image")}</th>
                        <th>{t("Type")}</th>
                        <th>{t("Currency")}</th>
                        <th>{t("Active")}</th>
                        <th>{t("Wagering days")}</th>
                        <th>{t("Wagering hours")}</th>
                        <th>{t("Wagering coefficient")}</th>
                        <th>{t("Start date")}</th>
                        <th>{t("End date")}</th>
                        <th>{t("Max bet")}</th>
                        <th>{t("Rewards")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    {bonusesList && (
                      <tbody data-testid="bonuses-admin-table">
                        {bonusesList.map((el) => (
                          <tr key={el.id}>
                            <td>{el.id}</td>
                            <td>{el.title}</td>
                            <td>
                              {el?.image ? (
                                <img
                                  src={el.image}
                                  alt="img"
                                  style={{ maxWidth: "50px" }}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{el.type}</td>
                            <td>{el.currency?.code}</td>
                            <td>{el.is_active ? "+" : "-"}</td>
                            <td>{el.wagering_days}</td>
                            <td>{el.wagering_hours}</td>
                            <td>{el.wagering_amount}</td>
                            <td className="no-wrap">
                              {formatDateMinutes(el.started_at)}
                            </td>
                            <td className="no-wrap">
                              {formatDateMinutes(el.finished_at)}
                            </td>
                            <td>{el.max_bet_amount}</td>
                            <td>
                              <div className="admin-bonus-list">
                                {el.campaigns.map((rewardItem) => (
                                  <span key={rewardItem.id}>
                                    {rewardItem.title};
                                  </span>
                                ))}
                              </div>
                            </td>
                            <td>
                              <div className="admin-actions-wrap">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    id="dropdown-basic"
                                    disabled={userRole !== UserRoles.admin}
                                  >
                                    {t("Actions")}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={(): void => {
                                        setBonusForEdit(el);
                                        setShowBonusModal(true);
                                      }}
                                    >
                                      {t("Edit")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(): void => {
                                        setBonusForEdit(el);
                                        setShowBonusModalDelete(true);
                                      }}
                                    >
                                      {t("Delete")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                  {totalBonusPages > 1 && (
                    <div className="pagination-wrap">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        pageRangeDisplayed={5}
                        pageCount={totalBonusPages}
                        previousLabel="<"
                        forcePage={activeBonusPage}
                        onPageChange={(e): void => {
                          handlePaginationBonusChange(e.selected);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Tab>
        <Tab eventKey={BonusesTabs.reward} title={t("Rewards")}>
          <Button
            variant="primary"
            className="mb-3"
            onClick={showReward}
            data-testid="rewards-admin-add"
            disabled={userRole !== UserRoles.admin}
          >
            + {t("Add new reward")}
          </Button>
          {activeBonusTab === BonusesTabs.reward && (
            <>
              {isBonusRewardFetching && (
                <CirclesWithBar
                  height="200"
                  width="200"
                  color="#0d6efd"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              )}
              {!isBonusRewardFetching && (
                <>
                  <Table striped bordered className="mb-5" responsive>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>{t("Title")}</th>
                        <th>{t("Type")}</th>
                        <th>{t("Settings")}</th>
                        <th>{t("Promotions")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    {bonusesRewardsList && (
                      <tbody data-testid="rewards-admin-table">
                        {bonusesRewardsList.map((el) => (
                          <tr key={el.id}>
                            <td>{el.id}</td>
                            <td>{el.title}</td>
                            <td>{el.type}</td>
                            <td>
                              <div className="admin-bonus-list">
                                <span>
                                  {t("Min deposit")}:{" "}
                                  {el.settings.min_deposit_amount}
                                </span>
                                {el.settings.freespin_id && (
                                  <span>
                                    {t("Freespin ID")}:{" "}
                                    {el.settings.freespin_id}
                                  </span>
                                )}
                                {el.settings.max_deposit_amount && (
                                  <span>
                                    {t("Max deposit")}:{" "}
                                    {el.settings.max_deposit_amount}
                                  </span>
                                )}
                                {el.settings.percentages_of_deposit && (
                                  <span>
                                    {t("Percentages of deposit")}:{" "}
                                    {el.settings.percentages_of_deposit}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="admin-bonus-list">
                                {el.bonuses.map((bonusItem) => (
                                  <span key={bonusItem.id}>
                                    {bonusItem.title};
                                  </span>
                                ))}
                              </div>
                            </td>
                            <td>
                              <div className="admin-actions-wrap">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    id="dropdown-basic"
                                    disabled={userRole !== UserRoles.admin}
                                  >
                                    {t("Actions")}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={(): void => {
                                        setRewardForEdit(el);
                                        setShowRewardModal(true);
                                      }}
                                    >
                                      {t("Edit")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(): void => {
                                        setRewardForEdit(el);
                                        setShowRewardModalDelete(true);
                                      }}
                                    >
                                      {t("Delete")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                  {totalBonusRewardPages > 1 && (
                    <div className="pagination-wrap">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        pageRangeDisplayed={5}
                        pageCount={totalBonusRewardPages}
                        previousLabel="<"
                        forcePage={activeBonusRewardPage}
                        onPageChange={(e): void => {
                          handlePaginationRewardChange(e.selected);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Tab>
      </Tabs>
      {showRewardModal && (
        <AddBonusRewardModal
          show={showRewardModal}
          handleClose={hideReward}
          handleRewardAdded={handleRewardAdded}
          handleRewardEdited={handleRewardEdited}
          rewardForEdit={rewardForEdit}
        />
      )}
      {showBonusModal && (
        <AddBonusModal
          show={showBonusModal}
          handleClose={hideBonus}
          handleBonusAdded={handleBonusAdded}
          handleBonusEdited={handleBonusEdited}
          bonusForEdit={bonusForEdit}
        />
      )}
      {showBonusModalDelete && (
        <DeleteBonusModal
          show={showBonusModalDelete}
          handleClose={hideBonusDelete}
          handleBonusDeleted={handleBonusDeleted}
          name={bonusForEdit?.title || ""}
          id={bonusForEdit?.id || 0}
        />
      )}
      {showRewardModalDelete && (
        <DeleteRewardModal
          show={showRewardModalDelete}
          handleClose={hideRewardDelete}
          handleRewardDeleted={handleRewardDeleted}
          name={rewardForEdit?.title || ""}
          id={rewardForEdit?.id || 0}
        />
      )}
    </div>
  );
};

export default BonusesTab;
