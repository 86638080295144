import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { CirclesWithBar } from "react-loader-spinner";
import { formatDateSecondsApi } from "../../../helpers/getCorrectDate";

import {
  addPromo,
  editPromo,
  getCashbackItem,
} from "../../../services/admin/apiPromo";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";
import {
  PromoItem,
  CashbackItem,
  PromoPayload,
} from "../../../types/promoTypes";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";
import { FreeSpinItem } from "../../../types/gameTypes";
import { getFreeSpinInfo } from "../../../services/admin/apiFreeSpins";

import { PromocodeType } from "../../../types/adminTypes";
import SelectFreeSpins from "../components/SelectFreeSpins/SelectFreeSpins";
import SelectCashBack from "../components/SelectCashBack/SelectCashBack";

interface Props {
  show: boolean;
  itemToEdit?: PromoItem;
  handleClose?: () => void;
  handlePromoAdded?: () => void;
  handlePromoEdited?: () => void;
}

const PromoInfoModal: React.FC<Props> = ({
  show,
  itemToEdit,
  handleClose,
  handlePromoAdded,
  handlePromoEdited,
}) => {
  const { t } = useTranslation();

  //   Selects currency data
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>();

  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isDataLoading, setDataLoading] = useState<boolean>(false);
  const [isEditEnable, setEditEnable] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>("");
  //  Form values
  const [code, setCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [maxCountUsing, setMaxCountUsing] = useState<string | null>(null);
  const [freespinsCount, setFreespinsCount] = useState<string | null>(null);
  const [activeSpin, setActiveSpin] = useState<FreeSpinItem | null>(null);
  const [activeCashback, setActiveCashback] = useState<CashbackItem | null>(
    null
  );
  const [rewardType, setRewardType] = useState<string>("none");
  const [depositAmount, setDepositAmount] = useState<string | null>(null);
  const [depositAmountMax, setDepositAmountMax] = useState<string | null>(null);
  const [depositAmountMin, setDepositAmountMin] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [periodInDays, setPeriodInDays] = useState<string>("");
  const [periodInHours, setPeriodInHours] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<PromocodeType>(
    PromocodeType.deposit_promo_code
  );

  const loadInitialData = async (): Promise<void> => {
    setDataLoading(true);
    if (itemToEdit) {
      setActiveTab(
        itemToEdit.is_deposit
          ? PromocodeType.deposit_promo_code
          : PromocodeType.no_deposit_promo_code
      );
      setCode(itemToEdit.code as string);
      setDescription(itemToEdit.description as string);
      setMaxCountUsing(itemToEdit.max_count_using.toString() as string);
      setStartDate(moment(itemToEdit.started_at as string).toDate());
      setEndDate(moment(itemToEdit.finished_at as string).toDate());
      setIsActive(!!itemToEdit.is_active);
      setPeriodInDays(itemToEdit.period_of_live_in_days?.toString() || "0");
      setPeriodInHours(itemToEdit.period_of_live_in_hours?.toString() || "0");
      if (itemToEdit.currency.id) {
        setSelectedCurrency(itemToEdit.currency.id as number);
      }
      if (itemToEdit.freespin) {
        const result = await getFreeSpinInfo(itemToEdit.freespin.id as number);
        if (typeof result !== "string")
          setActiveSpin(result.freespin as FreeSpinItem);
      }
      if (itemToEdit.cashback) {
        const result = await getCashbackItem(itemToEdit.cashback.id as number);
        if (typeof result !== "string")
          setActiveCashback(result.cashback as CashbackItem);
      }
      if (itemToEdit.freespins_count) {
        setFreespinsCount(itemToEdit.freespins_count.toString() as string);
      }
      if (itemToEdit.deposit_reward_type) {
        setRewardType(itemToEdit.deposit_reward_type as string);
      }
      if (itemToEdit.deposit_current_amount) {
        setDepositAmount(
          itemToEdit.deposit_current_amount.toString() as string
        );
      }
      if (itemToEdit.deposit_max_amount) {
        setDepositAmountMax(itemToEdit.deposit_max_amount.toString() as string);
      }
      if (itemToEdit.deposit_min_amount) {
        setDepositAmountMin(itemToEdit.deposit_min_amount.toString() as string);
      }
    }
    setDataLoading(false);
  };
  useEffect(() => {
    loadInitialData();
    // eslint-disable-next-line
  }, [itemToEdit]);

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  //   Form logic
  const addNewPromoHandler = async (): Promise<void> => {
    const data: PromoPayload = {
      code,
      description,
      currency_id: selectedCurrency || 0,
      max_count_using: parseInt(maxCountUsing || "", 10),
      started_at: formatDateSecondsApi(startDate as Date) || "",
      finished_at: formatDateSecondsApi(endDate as Date) || "",
      period_of_live_in_days: parseInt(periodInDays, 10),
      period_of_live_in_hours: parseInt(periodInHours, 10),
      is_active: isActive ? 1 : 0,
      is_deposit: activeTab === PromocodeType.deposit_promo_code,
      ...(depositAmountMin && {
        deposit_min_amount: parseInt(depositAmountMin, 10),
      }),
      ...(activeSpin && { freespin_id: activeSpin.id }),
      ...(freespinsCount && { freespins_count: parseInt(freespinsCount, 10) }),
      ...(activeCashback && { cashback_id: activeCashback.id }),
      ...(rewardType !== "none" && { deposit_reward_type: rewardType }),
      ...(depositAmount && {
        deposit_current_amount: parseInt(depositAmount, 10),
      }),
      ...(depositAmountMax && {
        deposit_max_amount: parseInt(depositAmountMax, 10),
      }),
    };
    const result = await addPromo(data);
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data added successfully"));
      if (handlePromoAdded) handlePromoAdded();
    }
  };
  const editPromoHandler = async (): Promise<void> => {
    const data: PromoPayload = {
      description,
      currency_id: selectedCurrency || 0,
      max_count_using: parseInt(maxCountUsing || "", 10),
      started_at: formatDateSecondsApi(startDate as Date) || "",
      finished_at: formatDateSecondsApi(endDate as Date) || "",
      period_of_live_in_days: parseInt(periodInDays, 10),
      period_of_live_in_hours: parseInt(periodInHours, 10),
      is_active: isActive ? 1 : 0,
      is_deposit: activeTab === PromocodeType.deposit_promo_code,
      deposit_reward_type: rewardType,
      ...(depositAmount && {
        deposit_current_amount: parseInt(depositAmount, 10),
      }),
      ...(depositAmountMax && {
        deposit_max_amount: parseInt(depositAmountMax, 10),
      }),
      ...(depositAmountMin && {
        deposit_min_amount: parseInt(depositAmountMin, 10),
      }),
      freespin_id: activeSpin?.id || undefined,
      freespins_count: parseInt(freespinsCount || "0", 10),
      cashback_id: activeCashback?.id || undefined,
    };
    if (!activeSpin) {
      data.freespin_force_delete = true;
      data.freespins_count = undefined;
    }
    if (!activeCashback) {
      data.cashback_force_delete = true;
    }
    if (itemToEdit?.code !== code) {
      data.code = code;
    }
    const result = await editPromo(data, (itemToEdit?.id as number) || 0);
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data edited successfully"));
      if (handlePromoEdited) handlePromoEdited();
    }
  };
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    if (itemToEdit) {
      await editPromoHandler();
    } else {
      await addNewPromoHandler();
    }
    setFetching(false);
  };

  return (
    <Modal show={show} onHide={handleClose} className="bonuses-admin-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {itemToEdit ? t("Edit promo code") : t("Add new promo code")}
        </Modal.Title>
      </Modal.Header>
      {isDataLoading ? (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      ) : (
        <>
          <Tabs
            defaultActiveKey={activeTab}
            onSelect={(e): void => {
              setActiveTab(e as PromocodeType);
              setDepositAmountMax("");
              setDepositAmountMin("");
              setActiveSpin(null);
              setFreespinsCount("");
              setActiveCashback(null);
              setRewardType("none");
              setDepositAmount("");
            }}
            className="mb-3"
          >
            <Tab
              eventKey={PromocodeType.deposit_promo_code}
              title={t("Deposit promo code")}
              disabled={!!itemToEdit}
            />
            <Tab
              eventKey={PromocodeType.no_deposit_promo_code}
              title={t("No deposit promo code")}
              disabled={!!itemToEdit}
            />
          </Tabs>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Code")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Code") || "Code"}
                    value={code}
                    onChange={(e): void => {
                      setCode(e.target.value);
                    }}
                    disabled={itemToEdit && !isEditEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Description")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t("Description") || "Description"}
                    value={description}
                    onChange={(e): void => {
                      setDescription(e.target.value);
                    }}
                    disabled={itemToEdit && !isEditEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Currency")}</Form.Label>
                  <Form.Select
                    aria-label={t("Currency") || "Currency"}
                    value={selectedCurrency}
                    onChange={(e): void => {
                      setSelectedCurrency(
                        parseInt(e.target.value as string, 10)
                      );
                    }}
                    disabled={itemToEdit && !isEditEnable}
                  >
                    {currencyList &&
                      currencyList.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.code}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Start date")}</Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    onChange={(dateEvent: Date): void => {
                      setStartDate(dateEvent);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    timeIntervals={5}
                    selected={startDate}
                    dropdownMode="select"
                    placeholderText={t("Select date") || "Select date"}
                    className="form-control"
                    disabled={itemToEdit && !isEditEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("End date")}</Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    onChange={(dateEvent: Date): void => {
                      setEndDate(dateEvent);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    timeIntervals={5}
                    selected={endDate}
                    dropdownMode="select"
                    placeholderText={t("Select date") || "Select date"}
                    className="form-control"
                    disabled={itemToEdit && !isEditEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Count usage")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t("Count usage") || "Count usage"}
                    value={maxCountUsing || ""}
                    onChange={(e): void => {
                      setMaxCountUsing(e.target.value);
                    }}
                    disabled={itemToEdit && !isEditEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Period of live in days")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={
                      t("Period of live in days") || "Period of live in days"
                    }
                    value={periodInDays}
                    onChange={(e): void => {
                      setPeriodInDays(e.target.value);
                    }}
                    disabled={itemToEdit && !isEditEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Period of live in hours")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={
                      t("Period of live in hours") || "Period of live in hours"
                    }
                    value={periodInHours}
                    onChange={(e): void => {
                      setPeriodInHours(e.target.value);
                    }}
                    disabled={itemToEdit && !isEditEnable}
                  />
                </Form.Group>
              </Col>
              {activeTab === PromocodeType.deposit_promo_code && (
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Deposit amount min")}</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={
                        t("Deposit amount min") || "Deposit amount min"
                      }
                      value={depositAmountMin || ""}
                      onChange={(e): void => {
                        setDepositAmountMin(e.target.value);
                      }}
                      disabled={itemToEdit && !isEditEnable}
                    />
                  </Form.Group>
                </Col>
              )}

              {activeTab === PromocodeType.deposit_promo_code && (
                <>
                  <Col sm={12}>
                    <h5>{t("Rewards")}</h5>
                    <SelectFreeSpins
                      value={activeSpin}
                      onChange={(value): void => setActiveSpin(value)}
                      disabled={!!itemToEdit && !isEditEnable}
                    />
                  </Col>
                  {activeSpin && (
                    <Col sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Freespins count")}</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={
                            t("Freespins count") || "Freespins count"
                          }
                          value={freespinsCount || ""}
                          onChange={(e): void => {
                            setFreespinsCount(e.target.value);
                          }}
                          disabled={itemToEdit && !isEditEnable}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm={12}>
                    <hr />
                    <SelectCashBack
                      value={activeCashback}
                      onChange={(value): void => setActiveCashback(value)}
                      disabled={!!itemToEdit && !isEditEnable}
                    />
                  </Col>
                  <Col sm={12}>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Deposit type")}</Form.Label>
                      <Form.Select
                        aria-label={t("Deposit type") || "Deposit type"}
                        value={rewardType}
                        onChange={(e): void => {
                          setRewardType(e.target.value as string);
                        }}
                        disabled={itemToEdit && !isEditEnable}
                      >
                        <option value="none">none</option>
                        <option value="absolute">absolute</option>
                        <option value="percentages">percentages</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {rewardType !== "none" && (
                    <>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Deposit amount")}</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder={
                              t("Deposit amount") || "Deposit amount"
                            }
                            value={depositAmount || ""}
                            onChange={(e): void => {
                              setDepositAmount(e.target.value);
                            }}
                            disabled={itemToEdit && !isEditEnable}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Deposit amount max")}</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder={
                              t("Deposit amount max") || "Deposit amount max"
                            }
                            value={depositAmountMax || ""}
                            onChange={(e): void => {
                              setDepositAmountMax(e.target.value);
                            }}
                            disabled={itemToEdit && !isEditEnable}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </>
              )}
              {activeTab === PromocodeType.no_deposit_promo_code && (
                <>
                  <Col sm={12}>
                    <h5>{t("Rewards")}</h5>
                    <SelectFreeSpins
                      value={activeSpin}
                      onChange={(value): void => setActiveSpin(value)}
                      disabled={!!itemToEdit && !isEditEnable}
                    />
                  </Col>
                  {activeSpin && (
                    <Col sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Freespins count")}</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={
                            t("Freespins count") || "Freespins count"
                          }
                          value={freespinsCount || ""}
                          onChange={(e): void => {
                            setFreespinsCount(e.target.value);
                          }}
                          disabled={itemToEdit && !isEditEnable}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </>
              )}
            </Row>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Active")}</Form.Label>
                <Form.Check
                  checked={isActive}
                  onChange={(): void => {
                    setIsActive(!isActive);
                  }}
                  disabled={itemToEdit && !isEditEnable}
                />
              </Form.Group>
            </Col>
            {globalError && (
              <div className="error-msg promoInfo-modal">{globalError}</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {itemToEdit && !isEditEnable ? (
              <Button
                variant="secondary"
                onClick={(): void => {
                  setEditEnable(true);
                }}
                data-testid="bonus-admin-modal-close"
              >
                {t("Edit")}
              </Button>
            ) : (
              <>
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  data-testid="bonus-admin-modal-close"
                >
                  {t("Close")}
                </Button>
                <Button
                  variant="primary"
                  onClick={(): void => {
                    handleSubmit();
                  }}
                  disabled={isFetching}
                >
                  {t("Confirm")}
                </Button>
              </>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default PromoInfoModal;
