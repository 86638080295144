import ApiClient from "./ApiClient";
import { UserLoginData, UpdateInfoPayload } from "../types/userTypes";
import {
  RequestError,
  LoginResponse,
  RegisterResponse,
  UserDataResponse,
  RefreshTokenResponse,
  CountryResponse,
  LocalesResponse,
  ForgotPasswordResponse,
  DeafaultSuccesResponse,
} from "../types/requestTypes";

export const registration = async (
  data: UserLoginData,
  terms_and_conditions: boolean
): Promise<RegisterResponse | string> => {
  try {
    const result = await ApiClient.publicPost<RegisterResponse>(
      "/auth/register",
      {
        ...data,
        terms_and_conditions,
      }
    );
    localStorage.setItem("expires_in", result.expires_in as string);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const login = async (
  data: UserLoginData
): Promise<LoginResponse | string> => {
  try {
    const result = await ApiClient.publicPost<LoginResponse>(
      "/auth/login",
      data
    );
    localStorage.setItem("expires_in", result.expires_in as string);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const logout = async (): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/auth/logout",
      {}
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const loginViaToken = async (
  oauth: string
): Promise<LoginResponse | string> => {
  try {
    const result = await ApiClient.publicPost<LoginResponse>("/auth/login", {
      oauth,
    });
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getUserData = async (): Promise<UserDataResponse | string> => {
  try {
    const result = await ApiClient.get<UserDataResponse>("/users/me");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getUserVipClub = async (): Promise<
  | {
      data: {
        level: number;
        current_amount: number;
        value_for_complete: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: {
        level: number;
        current_amount: number;
        value_for_complete: number;
      };
    }>("/vip/info/me");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const updateUserData = async (
  data: UpdateInfoPayload
): Promise<UserDataResponse | string> => {
  try {
    const result = await ApiClient.put<UserDataResponse>(
      "/users/me/update",
      JSON.stringify(data)
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getCountries = async (
  page: number
): Promise<CountryResponse | string> => {
  try {
    const result = await ApiClient.get<CountryResponse>(
      `/countries?page=${page}&per_page=10`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const getLocales = async (): Promise<LocalesResponse | string> => {
  try {
    const result = await ApiClient.getPublic<LocalesResponse>(`/locales`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const tokenRefresh = async (): Promise<
  RefreshTokenResponse | string
> => {
  try {
    const result = await ApiClient.post<RefreshTokenResponse>(
      "/auth/refresh-token",
      {}
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const forgotPassword = async (
  email: string
): Promise<ForgotPasswordResponse | string> => {
  try {
    const result = await ApiClient.post<ForgotPasswordResponse>(
      "/recovery/forgot-password",
      {
        email,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const recoveryPassword = async (
  password: string,
  password_confirmation: string,
  token: string
): Promise<ForgotPasswordResponse | string> => {
  try {
    const result = await ApiClient.post<ForgotPasswordResponse>(
      "/recovery/reset-password",
      {
        password,
        password_confirmation,
        token,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const forgotPasswordPhone = async (
  phone: string
): Promise<ForgotPasswordResponse | string> => {
  try {
    const result = await ApiClient.post<ForgotPasswordResponse>(
      "/recovery/forgot-password",
      {
        phone,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const forgotPasswordCheckCode = async (
  code: string
): Promise<ForgotPasswordResponse | string> => {
  try {
    const result = await ApiClient.post<ForgotPasswordResponse>(
      "/recovery/check-phone-code",
      {
        code,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
export const recoveryPasswordPhone = async (
  password: string,
  password_confirmation: string,
  code: string
): Promise<ForgotPasswordResponse | string> => {
  try {
    const result = await ApiClient.post<ForgotPasswordResponse>(
      "/recovery/reset-password-with-phone",
      {
        password,
        password_confirmation,
        code,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const confirmMail = async (
  email: string,
  origin: string
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/notification/verification-attempt",
      {
        email,
        origin,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const confirmPhone = async (
  phone: string
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/notification/verification-attempt",
      {
        phone,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const confirmPhoneCode = async (
  code: string
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/notification/verify-by-sms",
      {
        code,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
