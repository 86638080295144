import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Button, Accordion } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateSecondsApi } from "../../../helpers/getCorrectDate";

import { getBets } from "../../../services/admin/apiReporting";
import { CurrencyItem } from "../../../types/currencyTypes";
import { BalanceTypes } from "../../../types/adminTypes";
import { getCurrencyList } from "../../../services/apiCurrency";
import { BetsReportingItem } from "../../../types/betsTypes";

const Bets: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  // Bets data
  const [bets, setBets] = useState<BetsReportingItem>();
  const [isFetching, setFetching] = useState<boolean>(false);
  //   Selects data
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(2);
  const [balanceType, setBalanceType] = useState<BalanceTypes>(
    BalanceTypes.real
  );

  const { t } = useTranslation();

  //   Bets logic
  const loadBets = async (search?: string): Promise<void> => {
    setFetching(true);
    const result = await getBets(selectedCurrency, balanceType, search);
    if (typeof result !== "string") {
      setBets(result.data as BetsReportingItem);
    }
    setFetching(false);
  };
  useEffect(() => {
    loadBets();
    // eslint-disable-next-line
  }, []);

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  const handleClearSearch = (): void => {
    setStartDate(undefined);
    setEndDate(undefined);
    setSelectedCurrency(2);
    setBalanceType(BalanceTypes.real);
    loadBets();
  };
  const handleSearch = (): void => {
    const searchValue = `${
      startDate ? `&start_date=${formatDateSecondsApi(startDate)}` : ""
    }${endDate ? `&end_date=${formatDateSecondsApi(endDate)}` : ""}`;
    loadBets(searchValue);
  };

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>{t("Filters")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Start date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setStartDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={startDate}
                      dropdownMode="select"
                      placeholderText={t("Start date") || "Start date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("End date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setEndDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={endDate}
                      dropdownMode="select"
                      placeholderText={t("End date") || "End date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Currency")}</Form.Label>
                    <Form.Select
                      aria-label={t("Currency") || "Currency"}
                      value={selectedCurrency}
                      onChange={(e): void => {
                        setSelectedCurrency(
                          parseInt(e.target.value as string, 10)
                        );
                      }}
                      data-testid="bets-reporting-currency"
                    >
                      {currencyList &&
                        currencyList.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.code}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Balance")}</Form.Label>
                    <Form.Select
                      aria-label={t("Balance") || "Balance"}
                      value={balanceType}
                      onChange={(e): void => {
                        setBalanceType(e.target.value as BalanceTypes);
                      }}
                      data-testid="bets-reporting-balance"
                    >
                      <option value={BalanceTypes.real}>
                        {BalanceTypes.real}
                      </option>
                      <option value={BalanceTypes.demo}>
                        {BalanceTypes.demo}
                      </option>
                      <option value={BalanceTypes.bonus}>
                        {BalanceTypes.bonus}
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div className="admin-search-btns">
                    <Button
                      variant="danger"
                      onClick={handleClearSearch}
                      disabled={isFetching}
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isFetching}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {bets && bets.length > 0 && !isFetching && (
        <>
          <h5>{t("All bets")}</h5>
          <Table
            striped
            bordered
            className="mb-3"
            responsive
            data-testid="all-bets-table"
          >
            <thead>
              <tr>
                <th>{t("Quantity")}</th>
                <th>{t("Total amount")}</th>
                <th>{t("Min amount")}</th>
                <th>{t("Max amount")}</th>
                <th>{t("Avarage amount")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{bets[0].count_bet}</td>
                <td>{bets[0].sum_bet}</td>
                <td>{bets[0].min_bet}</td>
                <td>{bets[0].max_bet}</td>
                <td>{bets[0].avg_bet}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      {bets && bets.length > 1 && !isFetching && (
        <>
          <h5>{t("Wins bets")}</h5>
          <Table
            striped
            bordered
            className="mb-3"
            responsive
            data-testid="win-bets-table"
          >
            <thead>
              <tr>
                <th>{t("Quantity")}</th>
                <th>{t("Total amount")}</th>
                <th>{t("Min amount")}</th>
                <th>{t("Max amount")}</th>
                <th>{t("Avarage amount")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{bets[1].count_win}</td>
                <td>{bets[1].sum_win}</td>
                <td>{bets[1].min_win}</td>
                <td>{bets[1].max_win}</td>
                <td>{bets[1].avg_win}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default Bets;
