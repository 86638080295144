/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import type { RootState } from "../../store/store";

const RulesPage: React.FC = () => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="rules-page-wrap">
      <div className="content-gradient" />
      <div className="content-page-box">
        <div
          className={
            localeActive?.code === "fa" ? "container rtl" : "container"
          }
        >
          <h1>{t("Organization rules")}</h1>
          <ol>
            <li className="main-item">
              <span>{t("Introduction")}</span>
              <ol>
                <li>
                  {t(
                    "This set of Rules, Privacy Policy, and other information materials of the Website are the rules of the gambling organizer, operating in strict accordance with, within the framework of, and based on the legislation of a specific jurisdiction on gambling."
                  )}
                </li>
                <li>
                  {t(
                    "The website is a licensed platform for the provision and organization of gambling within the framework of the legislation of a specific jurisdiction."
                  )}
                </li>
                <li>
                  {t(
                    "The website conducts its activities on the Internet through the owned web page with the domain name boomcasino.bet, as well as through mobile applications on various operating systems, taking into account the norms and laws."
                  )}
                </li>
                <li>
                  {t(
                    "Visiting or using any section of the Website, as well as registering, followed by creating an Account, opening an account, and entering into an agreement to participate in gambling, after making the first bet on the conditions set forth in these Rules, the user:"
                  )}
                  <ol>
                    <li>
                      {t(
                        "Confirms that they have reached 18 years of age and have no restrictions on participating in gambling;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Agrees to these Rules, Terms of Use, Public Offer, Game Rules, and other documents posted on the website;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Confirms that they are the owner of a bank account/accounts (including card/card accounts) from which payments will be credited to the gaming account and to which payments will be transferred, and also confirms that they have the legal right to dispose of the funds in this account/these accounts;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Agrees to the processing of their personal data and undertakes to provide them, if necessary, to the relevant financial institutions to make payments;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Consents to receive advertising materials (bonuses, promotions, information) distributed via email, SMS, messengers, social networks, and also agrees to the terms of advertising campaigns conducted by the Website, bonuses, and individual offers."
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {t(
                    "Carefully reviewed the Rules, and in case of categorical disagreement with them in whole or in part, will refuse registration on the Website. Further use of the resource is considered as agreement with the points below."
                  )}
                  <ol>
                    <li>
                      {t(
                        "The Website's Rules are valid from the moment of their publication unless otherwise indicated separately."
                      )}
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Glossary")}</span>
              <p>
                {t(
                  "The Website provides a brief glossary to denote the terms used."
                )}
              </p>
              <ol>
                <li>
                  {t(
                    "Account - this is the user's personal account, available only to them and authorized employees of the Website, which contains personal data with financial information, settings, messages, verification, bonuses, and more."
                  )}
                </li>
                <li>
                  {t(
                    "Gambling - any game where a player's bet gives them the right to receive a prize, the probability and amount of which depend entirely or partially on chance, as well as the player's knowledge and skill. Any game in which at least one of the following signs is absent is not considered gambling:"
                  )}
                  <ul>
                    <li>{t("Presence of a bet;")}</li>
                    <li>
                      {t(
                        "The possibility of receiving or not receiving a prize;"
                      )}
                    </li>
                    <li>
                      {t(
                        "The probability of winning (prize) and its amount depend entirely or partially on chance."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "Verification (identification, data establishment) - confirmation of the player's identity, their age, and the right to participate in gambling."
                  )}
                </li>
                <li>
                  {t(
                    "Winnings (prize) - the amount of funds to be paid (issued) to the user in case of their win in a gambling game in accordance with the rules of such a gambling game posted on the Website, exceeding the total amount of funds deposited into the gaming account, reduced by the total amount of all refunds/payouts to the user."
                  )}
                </li>
                <li>
                  {t(
                    "Refund of funds deposited by the user for participation in gambling games - the amount of funds that, at the user's request, is returned to them by transferring to a bank account and does not exceed the total amount of funds deposited by the player into the gaming account, reduced by the total amount of all refunds/payouts."
                  )}
                </li>
                <li>
                  {t(
                    "Payout - the operation of transferring, at the user's request, the amount of funds they specified for withdrawal in their Account to a bank account. Within one Payout, part of the amount may be a refund of funds deposited by the user for participation in gambling games, and part may be Winnings."
                  )}
                </li>
                <li>
                  {t(
                    "Random Number Generator - a component (including remote) of slot machines and online services of the Website. When conducting gambling games on the Website, a sequence of unrelated numbers is generated, ensuring the random nature of winnings (prizes)."
                  )}
                </li>
                <li>
                  {t(
                    "Jackpot - the maximum prize provided by the rules of gambling games, the amount of which is determined by the gambling game organizer or is determined by them depending on the total number of bets made by players."
                  )}
                </li>
                <li>
                  {t(
                    "Gaming account (deposit) - the user's client account, the balance - a part of the online system that provides the user with complete information about their financial transactions (deposits, payouts, bets, winnings), as well as contains information necessary for player identification."
                  )}
                </li>
                <li>
                  {t(
                    "Game substitute for money - a material means of playing in gambling games, an electronic monetary substitute that allows making a bet and is presented on the balance of the launched game in an amount multiple to that on the player's gaming account, with a coefficient equal to the chosen denomination. The game substitute is acquired in exchange for money and, at the user's request, is subject to exchange for the corresponding monetary equivalent."
                  )}
                </li>
                <li>
                  {t("Player/User - is a natural person who:")}
                  <ul>
                    <li>
                      {t(
                        "at the time of participation in a gambling game, has reached the age of 18;"
                      )}
                    </li>
                    <li>{t("is legally capable;")}</li>
                    <li>
                      {t(
                        "is not listed in the Register of persons restricted from visiting gambling establishments and/or participating in gambling games;"
                      )}
                    </li>
                    <li>
                      {t(
                        "has registered on the Club's website voluntarily, passed verification, and is participating in a gambling game."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "Responsible Gaming - a basic principle of the Organization and conduct of gambling games, provides a set of measures to prevent and minimize the negative consequences of a natural person's participation in gambling, as well as activities aimed at organizing self-restrictions and self-control for players."
                  )}
                </li>
                <li>
                  {t(
                    "Return to Player Percentage (RTP) - the average percentage of the amount received from players' bets that is returned to the user during one million launches of a specific slot machine."
                  )}
                </li>
                <li>
                  {t(
                    "Registration - self-identification of the user on the Website with subsequent automatic creation of their account, as well as opening a gaming account after verification and the first deposit."
                  )}
                </li>
                <li>
                  {t(
                    "Register of persons restricted from visiting gambling establishments and participating in gambling games - a closed database containing information about natural persons for whom, in accordance with submitted applications or by court decision, access to gambling establishments and participation in gambling games is restricted."
                  )}
                </li>
                <li>
                  {t(
                    "Bet - money or a game substitute for money transferred by the player to the gambling game organizer, is a condition for participating in a gambling game, and based on its size, the winnings are determined in accordance with the rules of such a gambling game."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Principles of Casino Operations")}</span>
              <ol>
                <li>
                  {t("The main principles of the Website's operation are:")}
                  <ul>
                    <li>
                      {t(
                        "protection of the rights, legal interests, life, and health of players;"
                      )}
                    </li>
                    <li>
                      {t(
                        "compliance with established rules and equal conditions for all players of the gambling game;"
                      )}
                    </li>
                    <li>
                      {t(
                        "compliance with the principles of responsible gaming established by the competent authority;"
                      )}
                    </li>
                    <li>
                      {t(
                        "ensuring compliance with the principles of transparency, stability, openness, equality, fairness, and objectivity in conducting gambling games;"
                      )}
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Casino Responsibilities")}</span>
              <ol>
                <li>
                  {t("The responsibilities of the Website include:")}
                  <ol>
                    <li>
                      {t(
                        "Taking measures to prevent the participation of individuals under the age of 18 and individuals subject to restrictions under the legislation of a specific jurisdiction in gambling games."
                      )}
                    </li>
                    <li>
                      {t(
                        "Taking measures to combat gambling addiction (pathological gambling), including by posting information materials on gambling addiction and responsible gaming on the Website, particularly regarding age restrictions for players, chances of winning, principles of responsible gaming, signs of pathological and problem gambling, and places to seek help for gambling addiction. Also, placing clocks reflecting real-time on each page of the website, except for the actual gambling games physically hosted by game providers."
                      )}
                    </li>
                    <li>
                      {t(
                        "Handling user complaints and complaints from other individuals, including in electronic form, and providing responses to them within the timeframes defined by these Terms and rules of conducting gambling games."
                      )}
                    </li>
                    <li>
                      {t(
                        "Preventing the acceptance of bets on credit (with deferred payment) or with subsequent payment by players, except for the payment of bets with credit or debit cards with payment authorization. Prohibiting the provision of loans to players for participation in the game."
                      )}
                    </li>
                    <li>
                      {t(
                        "Not taking actions that could influence the results of gambling games, excluding the possibility of external influence on such results."
                      )}
                    </li>
                    <li>
                      {t(
                        "Conducting only permitted types of gambling games on the Website within the scope of the obtained license."
                      )}
                    </li>
                    <li>
                      {t(
                        "Using online gaming services in organizing and conducting gambling games that prevent unauthorized interference in their operation or the creation of conditions for a predetermined game outcome."
                      )}
                    </li>
                    <li>
                      {t(
                        "Recording in the online service, through communication channels (in real-time), each operation involving the acceptance of a bet, each payout of winnings, and other operations as determined by the operation of the State Online Monitoring System (after its launch)."
                      )}
                    </li>
                    <li>
                      {t(
                        "Timely making payments in full for winnings in non-cash form, in accordance with user requests and in accordance with the rules of conducting gambling games and within the timeframes set out in these Rules, except for cases determined by the legislation of a specific jurisdiction 'On Prevention and Counteraction to Legalization (Laundering) of Criminally Obtained Incomes, Financing of Terrorism and Financing of Mass Destruction Weapons Proliferation'."
                      )}
                    </li>
                    <li>
                      {t(
                        "Taking measures to prevent the payment of winnings to any third parties, also in favor of which the player tries to make the payment or refund. Except for cases of refund of contributions made by minors, incapacitated persons, persons whose legal capacity is restricted, or persons included in the Register of persons restricted from visiting gambling establishments or participating in gambling games. If the person applying for the return is the legal representative of such a person."
                      )}
                    </li>
                    <li>
                      {t(
                        "Keeping records of winnings (prizes) whose amounts constantly increase and depend on the number of bets placed (cumulative or progressive jackpot) (if such winnings exist)."
                      )}
                    </li>
                    <li>{t("Protection of customers' personal data.")}</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Player Requirements")}</span>
              <ol>
                <li>
                  {t(
                    "Only individuals who have reached the age of 18 at the time of participation in the game may be users. The player must, at any time, provide documents confirming his age and identity. The website has the right to close the account, suspend the operation of the gaming account, and block participation in gambling if the user does not provide such documents."
                  )}
                </li>
                <li>
                  {t("The following persons also cannot be users:")}
                  <ul>
                    <li>{t("incapacitated and limited capacity persons;")}</li>
                    <li>
                      {t(
                        "those who, according to the law, have corresponding restrictions;"
                      )}
                    </li>
                    <li>
                      {t(
                        "those recognized by the organization as undesirable;"
                      )}
                    </li>
                    <li>
                      {t(
                        "those entered in the Register of persons restricted from visiting gambling establishments and participating in gambling."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t("The user must:")}
                  <ul>
                    <li>
                      {t(
                        "comply with the requirements provided by these Rules and the rules of gambling, as well as the legislation of a specific jurisdiction;"
                      )}
                    </li>
                    <li>
                      {t(
                        "provide, upon request of the website employee, a document confirming identity and containing information about age;"
                      )}
                    </li>
                    <li>
                      {t(
                        "provide truthful information, upon the request of the website employee, to establish the legality of his participation in gambling."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t("Participation in gambling is prohibited for:")}
                  <ul>
                    <li>
                      {t(
                        "founders (participants, shareholders), managers of the Organization;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Pepresentatives of the founders (participants, shareholders), managers of the Organization;"
                      )}
                    </li>
                    <li>
                      {t(
                        "persons who may have information about the outcome of gambling;"
                      )}
                    </li>
                    <li>
                      {t(
                        "persons who may influence the outcome of gambling or the amount of winnings;"
                      )}
                    </li>
                    <li>
                      {t(
                        "the chairman, members, and officials of the Commission for the Regulation of Gambling and Lotteries, except for conducting checks by control purchases;"
                      )}
                    </li>
                    <li>
                      {t(
                        "persons recognized as undesirable by the Organization;"
                      )}
                    </li>
                    <li>
                      {t("persons residing in the territory of countries...")}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "The following persons are not allowed to participate in gambling on the website:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "those who, at the request of the website employee, if he has doubts about a person reaching the age of 18, did not provide an identity document for inspection containing information about the person's age;"
                      )}
                    </li>
                    <li>
                      {t(
                        "those who, at the request of the website representative, provided false information about themselves (last name, first name, patronymic, information about age, address of registration of residence or location);"
                      )}
                    </li>
                    <li>
                      {t(
                        "other persons determined by the legislation of a specific jurisdiction;"
                      )}
                    </li>
                    <li>
                      {t(
                        "persons with limited capacity and incapacitated persons;"
                      )}
                    </li>
                    <li>
                      {t(
                        "persons entered in the Register of persons restricted from visiting gambling establishments and/or participating in gambling."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "If a person, despite the prohibition established by these Rules, participates in gambling, the contract for participation in gambling, default concluded between the Website and such person, is void."
                  )}
                </li>
                <li>
                  {t(
                    "In case a prize is paid to this person, such prize payment shall be returned to the Website in full and within the time limits set for such person."
                  )}
                </li>
                <li>
                  {t(
                    "The website has the right to increase the minimum age of the player for participation in gambling."
                  )}
                </li>
                <li>
                  {t(
                    "In some jurisdictions, participating in gambling on the website may be considered illegal. The website does not provide consultations on this matter and does not guarantee that betting for participation in gambling fully complies with the legislation of the user's country. By registering on the website and placing a bet, the user thereby agrees that he accepts the Rules and assumes full responsibility for his actions, even if participation in gambling is not legal in the country of his residence or location."
                  )}
                </li>
                <li>
                  {t(
                    "The website does not intend to provide services that contradict the legislation of the country where a specific user lives or is located. Participation in gambling on the website is indirect confirmation that it complies with the legislation of the country of residence or location. The organization is not responsible for the illegal participation of citizens of foreign states in gambling."
                  )}
                </li>
                <li>
                  {t(
                    "Each user is personally responsible for paying taxes and fees on payments received by him, in accordance with the tax legislation of his country. At the same time, the amount that remains on his gaming account (balance) is not taken into account when calculating taxes and fees to be paid."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>
                {t("Registration, Account Creation, Account Opening")}
              </span>
              <ol>
                <li>
                  {t(
                    "Registration using an email address and phone number requires creating a personal password for personalized access to the website."
                  )}
                </li>
                <li>
                  {t(
                    "During registration, it is necessary to provide accurate information, a valid email address, and phone number."
                  )}
                </li>
                <li>
                  {t(
                    "A registered user is assigned an individual ID, as well as a nickname (name on the site) similar to the specified email address or random (when registering by phone number)."
                  )}
                </li>
                <li>
                  {t(
                    "All funds on the gaming account, as well as monetary substitutes, belong to the user on legal grounds, except for cases separately stipulated by the legislation of specific jurisdictions and in these Rules."
                  )}
                </li>
                <li>
                  {t(
                    "By registering on the website, the user confirms that he/she provides true information that corresponds to reality and is accurate. In case of changes in personal information, the user is obliged to enter updated information into the Account and undergo re-verification. Failure to comply with these requirements may result in the suspension of winnings, the accrual of bonuses, and other actions, and the user's Account may be blocked."
                  )}
                </li>
                <li>
                  {t(
                    "The user is responsible for ensuring that all information provided to the website during registration and subsequently (when depositing funds and placing bets) is true. The name, surname, and patronymic (if any) in the verified documents strictly correspond to the name, surname, and patronymic (if any) of the owner of payment cards, bank accounts, and payment wallets used for transactions."
                  )}
                </li>
                <li>
                  {t(
                    "Each user agrees to periodic checks of his personal and contact information by the website, as well as by third parties authorized to do so under the applicable law."
                  )}
                </li>
                <li>
                  {t(
                    "Withdrawal of funds from the gaming account may be restricted during the verification process."
                  )}
                </li>
                <li>
                  {t(
                    "If there are any questions or problems during registration, the website user should contact the Support Service at support@boomcasino.bet, or through online chat, or by phone, the number of which is indicated on the website."
                  )}
                </li>
                <li>
                  {t(
                    "By registering on the website and participating in gambling solely at their own will and desire, at their own risk, each user fully realizes and accepts the existence of the probability of losing a bet expressed in monetary terms and the absence of any legal grounds for making claims to the website in this regard."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Account Security")}</span>
              <ol>
                <li>
                  {t(
                    "Disclosure of the password to third parties (intentional or unintentional), providing them with access to the email address or phone number used during registration, can lead to unauthorized access to the Account with subsequent theft of funds on the gaming account."
                  )}
                </li>
                <li>
                  {t(
                    "The responsibility for storing the password and for all actions performed using the gaming account lies with the user."
                  )}
                </li>
                <li>
                  {t(
                    "Compensation to the organization for damage caused as a result of hacking of their account, occurring due to the fault of the user, is not provided."
                  )}
                </li>
                <li>
                  {t(
                    "In case of detecting information leakage that has occurred or may lead to unauthorized access to the user's Account and access to their gaming account, they must immediately report this to the website's support service. This also applies to any other security breach."
                  )}
                </li>
                <li>
                  {t(
                    "At the request of the website, the user must provide evidence of unauthorized access as soon as possible. The organization is not responsible for the damage that occurs as a result of violations of the rules for handling personal data that led to the possibility of third parties accessing the player's account. It does not matter how access to the Account was obtained: with the player's assistance or without their knowledge."
                  )}
                </li>
                <li>
                  {t(
                    "In case of loss of a password or access to their own resources used during registration, the player's access to their own Account on the website is restored using the 'Forgot your password?' link in the login menu or through the support service."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Verification")}</span>
              <ol>
                <li>
                  {t(
                    "User verification involves collecting the following information:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "Last name, first name, patronymic (if available) of the person;"
                      )}
                    </li>
                    <li>{t("Date of birth, age;")}</li>
                    <li>
                      {t(
                        "The presence or absence of a person in the Register of persons whose access to gambling establishments and/or participation in gambling is restricted."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "Standard verification on the website involves uploading the requested data to the user's Account and confirming their authenticity:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "A government-recognized identity document with a photo (passport, driver's license, or identification card);"
                      )}
                    </li>
                    <li>{t("Additional identity document;")}</li>
                    <li>{t("A selfie with an identity document in hand;")}</li>
                    <li>
                      -{" "}
                      {t(
                        "Document confirming the taxpayer identification number (TIN);"
                      )}
                    </li>
                    <li>
                      {t(
                        "Verification of a bank card, where it is necessary to provide a photo of the card with the front side."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "The website has the right at any time to demand from the player additional documents confirming their identity, in particular, via video communication. These may include:"
                  )}
                  <ul>
                    <li>{t("Additional passport pages;")}</li>
                    <li>
                      {t(
                        "Payment bank cards from which the replenishment of the gaming account was made;"
                      )}
                    </li>
                    <li>{t("Other documents.")}</li>
                  </ul>
                </li>
                <li>
                  {t(
                    "When verifying the user through various verification methods, measures and requirements of data protection legislation are maintained."
                  )}
                </li>
                <li>
                  {t(
                    "The user is responsible for the accuracy of the documents and information provided in accordance with the law."
                  )}
                </li>
                <li>
                  {t(
                    "If the user does not provide the necessary documents for review, their Account and gaming account may be temporarily blocked until the requested documents are provided. If such documents are not provided without valid reasons within the specified time frames or if the information provided by the player is incorrect or misleading, not entirely accurate, and does not fully correspond to reality, in this case, the player is prohibited from participating in gambling, and the contract for participation in gambling concluded between the organization and the user is void in accordance with the Rules."
                  )}
                </li>
                <li>
                  {t(
                    "Payment (issuance) of winnings (prizes) under void contracts is not made. The website reserves the right to delete the user's Account and close their gaming account. Already paid amounts of winnings are subject to refund within the timeframes specified to the user."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Deposits, Adding Funds, Withdrawals")}</span>
              <ol>
                <li>
                  {t(
                    "To participate in gambling games, the user must have funds in their gaming account."
                  )}
                </li>
                <li>
                  {t(
                    "Replenishment of the gaming account is carried out using payment bank cards Visa, MasterCard, and others."
                  )}
                </li>
                <li>
                  {t(
                    "The minimum amount of a one-time deposit to the gaming account on the website is 100 RUB, 1 EUR/USD, 500 KZT."
                  )}
                </li>
                <li>
                  {t(
                    "By replenishing the gaming account and using funds to place bets on the website, the user confirms and accepts:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "The deposited funds are not obtained in compliance with tax, criminal, or other legislation;"
                      )}
                    </li>
                    <li>
                      {t(
                        "The website may refuse the player in the withdrawal or return of funds deposited to their account or in canceling transactions related to the gaming account replenishment process."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "On the website, replenishment of the gaming account cannot be carried out by third parties: relatives, friends, spouses, business partners, and others. The player deposits funds into their gaming account using personal Visa, MasterCard, and other payment cards. In case of revealing the fact of replenishment of the gaming account from the accounts of third parties, all winnings of such a user may be annulled."
                  )}
                </li>
                <li>
                  {t(
                    "When requesting a refund of funds to the rightful holder of the gaming account, all expenses for paying commissions and other expenses may be imposed on the recipient."
                  )}
                </li>
                <li>
                  {t(
                    "Cash payments are not accepted. The website has the right to use the technical capabilities of third-party organizations to process payments made by the user. Also, payments to the player may be processed through third-party systems."
                  )}
                </li>
                <li>
                  {t(
                    "The user does not have the right to refuse already completed transactions. They cannot be annulled or canceled in any way. In the event of such a situation, if previously deposited funds have already been used to place bets on the website, the player must compensate for the losses incurred by the website."
                  )}
                </li>
                <li>
                  {t(
                    "The website has the right to block and annul any transactions made from the gaming account, as well as to annul winnings in the following cases:"
                  )}
                  <ul>
                    <li>{t("fraudulent or suspicious account top-ups;")}</li>
                    <li>{t("use of others' or stolen payment cards;")}</li>
                    <li>
                      {t(
                        "exchange between payment systems when topping up the account."
                      )}
                    </li>
                  </ul>
                  <p>
                    {t(
                      "The website has the right to inform relevant organizations and law enforcement agencies about any form of fraud. It also has the right to use the services of collection agencies to recover unlawfully appropriated funds."
                    )}
                  </p>
                  <p>
                    {t(
                      "The website is not responsible for any unauthorized use of cards. This includes whether a statement has been made by the user regarding their loss or theft."
                    )}
                  </p>
                </li>
                <li>
                  {t(
                    "Withdrawals from the gaming account are made based on an application specifying the desired amount, taking into account the current balance. The following are taken into account:"
                  )}
                  <ol>
                    <li>
                      {t(
                        "To receive the first payout, it is necessary to confirm your email address and phone number, as well as verify an identity document confirming reaching the age of 18, a taxpayer card confirming the RNUKP, and which is the tax identification number."
                      )}
                    </li>
                    <li>
                      {t(
                        "Payments are made only to the payment cards from which the gaming account (deposit) was replenished."
                      )}
                    </li>
                    <li>
                      {t(
                        "The website may require verification of the payment card to which the payout is made."
                      )}
                    </li>
                    <li>
                      {t(
                        "The minimum withdrawal limit is 1000 RUB, 10 EUR/USD, 5000 KZT. Requests for withdrawals of amounts less than the established limit are not accepted."
                      )}
                    </li>
                    <li>
                      {t(
                        "When transferring the payout to the player's payment card, all bonuses activated by the player are canceled."
                      )}
                    </li>
                    <li>
                      {t(
                        "The website reserves the right to conduct additional checks on any transactions. Their duration cannot exceed two days."
                      )}
                    </li>
                    <li>
                      {t(
                        "The organization is obliged to conduct an additional check with a delay of no more than 30 days if there is a suspicion of a violation of the law 'On Combating Money Laundering, Financing of Terrorism and the Financing of Proliferation of Weapons of Mass Destruction'."
                      )}
                    </li>
                    <li>
                      {t(
                        "In the presence of substantial evidence of fraudulent activities by the user, the Website reserves the right to deny payment with subsequent account suspension."
                      )}
                    </li>
                    <li>
                      {t(
                        "To verify additional documents, apart from those required in the account, the Support Service can be contacted via email and also through the chat on the main page."
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {t(
                    "A gaming account cannot be considered or used in the same way as a bank account. Therefore, insurance, guarantees, and other tools common to financial institutions do not apply to a gaming account. Also, no interest is accrued on the funds placed in the gaming account, including those related to changes in currency rates, cryptocurrencies, etc."
                  )}
                  {t("")}
                </li>
                <li>
                  {t(
                    "Payouts to users are made in non-cash form through the online system of the Website, as an organizer of gambling activities on the Internet, in accordance with the provisions of the laws on 'State Regulation of Gambling Activities' and 'Prevention and Combating of Money Laundering, Terrorism Financing, and Financing the Proliferation of Weapons of Mass Destruction'."
                  )}
                </li>
                <li>
                  {t(
                    "The Website will make all efforts within its control to ensure that the maximum processing time for withdrawal requests is 48 hours. However, when the required documents are verified, everything happens much faster. The exact withdrawal times depend on the following factors:"
                  )}
                  {t("")}
                  <ul>
                    <li>{t("The amount to be paid")}</li>
                    <li>{t("Date and time of payment;")}</li>
                    <li>{t("Bank rules")}</li>
                    <li>{t("Payment system used.")}</li>
                  </ul>
                </li>
                <li>
                  {t(
                    "Since in some provider's machines, one coin may not correspond to the value of real money, the amount won in coins may differ from the actual winnings after conversion. Detailed information on this issue can be found in the slot instructions provided by the developers."
                  )}
                </li>
                <li>
                  {t(
                    "To withdraw funds from their gaming account, the player must use (place for the purpose of participating in the game) at least 70 (seventy) percent of the funds from the deposit amount made by the player at the time of the withdrawal request from the gaming account."
                  )}
                  {t("")}
                </li>
                <li>
                  {t(
                    "The Website may withhold a commission of up to 10% from the withdrawn amount."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Bets")}</span>
              <ol>
                <li>
                  {t(
                    "Since the game takes place on the provider's side, the website cannot cancel or return a bet to the user."
                  )}
                </li>
                <li>
                  {t(
                    "Information about the winning percentage is provided in the description of each specific slot machine and ranges from 92% to 98% when one million spins are made."
                  )}
                </li>
                <li>
                  {t(
                    "In case of a malfunction of the slot machine, the bet can be refunded to the player's balance directly by the slot machine."
                  )}
                </li>
                <li>
                  {t(
                    "After selecting the bet size and clicking the SPIN (or START, PLAY, etc.) button, the bet is considered placed."
                  )}
                </li>
                <li>
                  {t(
                    "The website has the right to refuse the player to conduct a transaction due to a violation of one of the provisions of these Rules. The transaction to replenish the gaming account is considered completed only after the corresponding amount appears on the player's balance. If the user is sure that everything is done correctly but the replenishment amount has not appeared on the balance, they must contact Support."
                  )}
                </li>
                <li>
                  {t(
                    "By clicking on the gaming account tab on the website, the user enters a section where they can replenish the gaming account, withdraw funds, review the history of financial transactions, and more."
                  )}
                </li>
                <li>
                  {t(
                    "Before placing a bet in the game, it is necessary to ensure that the gaming account balance is replenished. The user is personally responsible for each transaction."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Accounts, Bonuses, Wagering")}</span>
              <ol>
                <li>
                  {t(
                    "After registering on the website, the user gains access to two different accounts (sub-accounts) - real and bonus, which are combined into one overall gaming account."
                  )}
                </li>
                <li>
                  {t(
                    "Real money, winnings, and played bonuses are credited to the real account (deposit). This account is also debited when making bets and receiving payouts. Thus, this account contains real money, which can be played or withdrawn at any time."
                  )}
                </li>
                <li>
                  {t("Real account.")}
                  <ol>
                    <li>
                      {t(
                        "Accessible only if there are sufficient funds on its balance to place bets."
                      )}
                    </li>
                    <li>
                      {t(
                        "After receiving a payout from the real account, active bonuses on the bonus account, as well as all activated bonuses, are canceled."
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {t("Bonus account. Wagering.")}
                  <ol>
                    <li>
                      {t(
                        "The bonus account is not available for placing bets as long as there are funds on the real account. Bets can be made from the bonus account only after the funds on the real account have been used. Bets from the bonus account are made using the bonus wagering rule with a specified wager and cashout."
                      )}
                    </li>
                    <li>
                      {t(
                        "Wagering is a coefficient, a multiplier by which the bonus amount must be multiplied to calculate the amount of its wagering. Bets from the bonus account must be placed to transfer bonus funds to the real account. The current wagering amount is indicated in the gaming account tab."
                      )}
                    </li>
                    <li>
                      {t(
                        "Payouts directly from the bonus account are not made."
                      )}
                    </li>
                    <li>
                      {t(
                        "If, during wagering, the bonus account has an amount less than €1, the wagering of the active bonus is considered complete."
                      )}
                    </li>
                    <li>
                      {t(
                        "The period during which the bonus must be wagered is indicated in the terms of the promotion. After the promotion period expires, the bonus is canceled."
                      )}
                    </li>
                    <li>
                      {t(
                        "If a player does not log in to the website within 30 days, all their bonuses are canceled."
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {t("Bonus Terms")}.
                  <ol>
                    <li>
                      {t(
                        "Bonus Awarded. It is present in the Bonuses section. The conditions for receiving it are described there. The Apply button is the starting point for fulfilling these conditions. If it's a no-deposit bonus, after fulfilling the necessary conditions, the Apply button immediately moves the bonus to the queue of activated ones."
                      )}
                    </li>
                    <li>
                      {t(
                        "Bonus Activated. All conditions for receiving the bonus have been met within the specified time frame. It is present in the balance."
                      )}
                    </li>
                    <li>
                      {t(
                        "Active Bonus. The first activated bonus, the top one in the queue, is the active bonus. It is credited to the bonus account and awaits wagering (if it's cash) or awaits use in the game (if it's free spins), but only after all funds in the real account have been played. Wagering other bonuses in the queue of activated bonuses is possible only after wagering the active bonus or in case of its cancellation or deactivation."
                      )}
                    </li>
                    <li>
                      {t(
                        "Bonus Canceled. By clicking the Cancel button at any time next to the corresponding bonus in the queue of activated ones, you remove it, and its place is taken by the bonus below it."
                      )}
                    </li>
                    <li>
                      {t(
                        "Bonus Deactivated. This is a bonus automatically removed from the queue of activated ones because the time allocated for its use or wagering according to the promotion's terms has expired."
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {t("Bonus Terms")}.
                  <ol>
                    <li>
                      {t(
                        "Under Deposit. Is credited when a deposit is made in an amount greater than or equal to that specified in the terms of the promotion."
                      )}
                    </li>
                    <li>
                      {t(
                        "No deposit. Is credited when certain actions are taken (e.g., for document verification) or as a gift, congratulations, reward, and so on."
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {t("Bonus Type")}.
                  <ol>
                    <li>
                      {t(
                        "Fixed amount. Is credited to the bonus account and transfers to the real account after wagering with the specified wager."
                      )}
                    </li>
                    <li>
                      {t(
                        "Percentage of deposit. Depends on the deposited amount. The terms of the promotion specify the minimum required deposit amount, as well as the maximum possible amount that can be credited, regardless of the deposit amount."
                      )}
                    </li>
                    <li>
                      {t(
                        "Free Spins. A bonus in the form of a certain number of spins of the reels in a specific slot with a specific bet. According to the terms of the promotion, the number of free spins or the bet with which they are launched may depend on the required deposit amount or other conditions. After all bonus free spins are launched, the winnings are credited to the bonus account, to which the wagering rule with a specified wager is then applied."
                      )}
                    </li>
                    <li>
                      {t(
                        "Combined Bonus. The player receives a fixed amount or a percentage of the deposit simultaneously with free spins. In this case, the cash goes to the bonus account immediately, and then the amount won as a result of using free spins is added. Then, wagering with the specified wager is applied to the total amount."
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {t(
                    "There are no restrictions on the number of bonuses credited to the user."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Criminal Activity and Other Unlawful Actions")}</span>
              <ol>
                <li>
                  {t(
                    "The following actions are prohibited as they are considered a material violation of these Rules:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "providing any contact, identification, and financial information contained in the user's account to third parties;"
                      )}
                    </li>
                    <li>
                      {t(
                        "fraud in any form, including the use of malicious applications and programs, the use of bots, actions that allow obtaining data of payment instruments, hacking of other players' gaming accounts, using software errors of the Website to gain illegal profit, etc.;"
                      )}
                    </li>
                    <li>
                      {t(
                        "money laundering of illegally obtained funds, as well as participation in other unlawful activities that entail criminal legal consequences;"
                      )}
                    </li>
                    <li>
                      {t(
                        "conspiracy or attempt to conspire (intentional or unconscious) with other individuals for the purpose of committing illegal operations and actions that result in illegal enrichment at the expense of the Website or other players."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "In case of well-founded suspicion that issued bonuses, rewards, or incentives have been obtained illegally and are being abused, the Website has the right at any time to suspend or cancel planned payouts for the associated winnings."
                  )}
                </li>
                <li>
                  {t(
                    "The Website takes all necessary measures to detect and prevent collusion, as well as to identify individuals involved in it. In case of harm to a user who has been noticed in fraudulent actions, the Website disclaims all responsibility for what has happened and directs its actions to protect itself and other players using all lawful methods."
                  )}
                </li>
                <li>
                  {t(
                    "A user who notices that illegal or fraudulent actions are taking place on the Website must immediately report this through the Support Service email, online chat, messengers, or by phone."
                  )}
                </li>
                <li>
                  {t(
                    "In case of suspicion of committing or intending to commit fraud, the Organization may at any time block the violator's gaming account and prohibit access to the account. In this case, the funds of the blocked client will not be returned but will be directed to compensate for the damage caused to the Website or other players. In addition, the Website has the right to report illegal actions to law enforcement authorities. If the user is sure that fraud occurred not through their fault, they must fully cooperate in investigating the incident."
                  )}
                </li>
                <li>
                  {t(
                    "Responsibility for any fraudulent actions towards the Website or other users is determined by the legislation or other applicable norms."
                  )}
                </li>
                <li>
                  {t(
                    "It is prohibited to use any software that serves to illegally obtain money. If such software is detected, the Website has the right to block the user's account and withhold the funds on it as compensation for the damage caused."
                  )}
                  .
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Rules of Conduct on the Website")}</span>
              <ol>
                <li>
                  {t(
                    "Aggressive and offensive communication, the use of profanity, threats, blackmail, attempts to demean the dignity, or threats of violent actions towards other players or Website staff are prohibited on the Website."
                  )}
                </li>
                <li>
                  {t(
                    "Uploading information to the Website in a volume that could cause a resource malfunction is prohibited. In addition, any actions that may cause malfunctions in the functioning of the site are not allowed. This includes the use of malicious programs, viruses, trojans, etc. Mass mailing is also prohibited. Distorting or deleting information on the Website is also prohibited."
                  )}
                </li>
                <li>
                  {t(
                    "Users are obligated to use the services of the Website solely for participating in gambling, entertainment, and communication. Copying materials, parts of the Website, or the entire resource is prohibited without obtaining the corresponding written permission from the Organization."
                  )}
                </li>
                <li>
                  {t(
                    "Users do not have the right to hack the Website or attempt to do so. Bypassing the site's security system, etc., is not allowed. Access to the services will be blocked if the Website detects an attempt to hack or other actions that are aimed at bypassing the security system or gaining access to the software or accounts of other users. Law enforcement agencies will also be informed of the user's illegal actions."
                  )}
                </li>
                <li>
                  {t(
                    "In case of malfunctions on the Website caused by viruses, hacker attacks, or DDoS attacks, the Website will make every effort to resolve the issues. However, no responsibility is assumed for the actions of third parties, and this also applies to the placement of illegal materials and links by third-party users on the site."
                  )}
                </li>
                <li>
                  {t(
                    "User accounts are prohibited from being sold or transferred to third parties."
                  )}
                </li>
                <li>
                  {t(
                    "Any user bets may be audited. The audit is carried out by the Website. In case of fraudulent actions, including obtaining advantages by players who exploited the site's errors or vulnerabilities, their accounts will be temporarily blocked. The blocking is temporary, and at the end of the investigation, or permanently if it is determined that the actions were intentional and malicious. The decision regarding the return or non-return of funds to the blocked user is made based on the circumstances."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Term of Agreement and Termination")}</span>
              <ol>
                <li>
                  {t(
                    "The user is responsible for all actions taken with their account until they receive confirmation from the Website that the account has been closed. This includes the period between the submission of the account closure request and the actions taken by the Website to execute the request."
                  )}
                </li>
                <li>
                  {t(
                    "The Website has the right to charge a commission fee and the amount of the debt until the user's account is closed. In the event of an account blockage or deletion, the funds stored in it are not subject to refund. This also applies to bonuses and other rewards. After the account is closed, access to it becomes impossible."
                  )}
                </li>
                <li>
                  {t(
                    "After the annulment of a user's account, neither party has or can have any obligations towards each other."
                  )}
                </li>
                <li>
                  {t(
                    "The Website may delete or block an account without notifying the player in the following cases:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "cessation of the Website's activities in organizing gambling games;"
                      )}
                    </li>
                    <li>
                      {t(
                        "if the account is linked to a previously deleted account;"
                      )}
                    </li>
                    <li>
                      {t(
                        "if the account is linked to a previously blocked account, with the exception of cases provided for in these Rules, the remaining funds will be returned upon request;"
                      )}
                    </li>
                    <li>
                      {t(
                        "in case of attempted site hacking or participation in a conspiracy;"
                      )}
                    </li>
                    <li>
                      {t(
                        "in case of interference with software or attempts to manipulate it;"
                      )}
                    </li>
                    <li>
                      {t(
                        "in case of illegal use of the account, such as attempts to participate in gambling games on the Website in jurisdictions where it is prohibited;"
                      )}
                    </li>
                    <li>
                      {t(
                        "in case of publication of offensive and humiliating information on the Website."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "In case of no activity on the account for 6 months or more, the Website has the right to close it without notification. In this case, these Rules lose their effect from the date of account closure."
                  )}
                </li>
                <li>
                  {t(
                    "The Website has the right to close an account and notify the user of this in an email sent to the email address provided by the user during registration. The remaining funds in the account will be returned, except in cases specified in these Rules related to fraudulent actions, illegal conspiracy, and other unlawful actions. In case it is impossible to transfer the funds to their lawful owner, they will be transferred to the supervisory authority."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("System Errors and Shortcomings")}</span>
              <ol>
                <li>
                  {t(
                    "The Website attempts to correct any errors or malfunctions as quickly as possible but is not responsible for faulty equipment and information technology tools used by the user to access the Website and participate in gambling games. It also does not assume responsibility for the operation of Internet providers providing access to the network."
                  )}
                </li>
                <li>
                  {t(
                    "According to the rules of slot machine developers, in case of malfunctions and malfunctions, all results and winnings of the current gaming session are canceled."
                  )}
                </li>
                <li>
                  {t(
                    "During the stay on the Website, circumstances may arise in which a bet was placed erroneously. For example, incorrect betting conditions that were not corrected or a malfunction of the software occurred. Errors can also be in the calculation of the winnings due to the user, and so on. In these cases, the money paid to the players must be returned to the Website."
                  )}
                </li>
                <li>
                  {t(
                    "The Website and the game provider have the right to cancel or limit any bets placed."
                  )}
                </li>
                <li>
                  {t(
                    "When using funds that have been credited to the user's balance erroneously or in violation of these Rules or the law, the Website has the right to cancel the bets and winnings obtained by the user. In case winnings have already been paid in error, it is considered that the amount has been transferred to the user in trust. The Website undertakes to return all funds that have been received in error within the established timeframes."
                  )}
                </li>
                <li>
                  {t(
                    "In case the information transmitted over the Internet is intercepted by intruders or third parties, the Website, as well as its branches and employees, are not liable for possible losses to users. All responsibility for such lies with the user, who did not fully utilize the recommended means to protect Internet traffic and allowed such interceptions."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Limitation of Liability")}</span>
              <ol>
                <li>
                  {t(
                    "Each user, by registering on the Website, automatically agrees to participate in gambling games, makes an informed choice, acknowledges and accepts all possible risks associated with such games, and is fully responsible for their actions and choices."
                  )}
                </li>
                <li>
                  {t(
                    "The operation of the Website is carried out in accordance with the Law and these Rules. The Website is not obliged to provide or offer any additional guarantees and assurances. Any activity not covered by the provisions of these Rules is excluded. All claims are accepted in writing to the email address."
                  )}
                </li>
                <li>
                  {t(
                    "The Website is not responsible for any violations, damages, negligence, losses, loss of personal data, reputation, prestige, and other possible losses that may occur. This also applies to other losses that are not listed and cannot be foreseen at the moment. The Website also does not assume responsibility for the content of any resource that can be accessed through the website. Claims in case of the above-mentioned situations are not accepted."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Violation of Agreements")}</span>
              <ol>
                <li>
                  {t(
                    "In case the user violates these Rules, they undertake to fully compensate for possible damage, which may include expenses and costs, lawsuits, and other expenses."
                  )}
                </li>
                <li>
                  {t(
                    "The user agrees to compensate for losses and to protect the interests of the website, partners, and all employees from claims, liability, expenses, damage, legal costs, and any other expenses arising from the following reasons and as a result of:"
                  )}
                  <ul>
                    <li>{t("User's violation of these Rules;")}</li>
                    <li>
                      {t(
                        "User's violation of the law, as well as the rights of third parties;"
                      )}
                    </li>
                    <li>
                      {t(
                        "User providing access to their account on the website to third parties through their personal data, regardless of whether consent was given for such actions or not;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Crediting any winnings obtained by the user by illegal/fraudulent means."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "If the user violates these Rules, the website reserves the right (but is not obligated) to take the following actions:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "Send a notification using the client's contact information that they have violated the Rules with a demand to stop the violations;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Temporarily or permanently block the account, which implies blocking the user's participation in gambling games on the website;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Refuse to refund funds except in cases provided for in these Terms;"
                      )}
                    </li>
                    <li>
                      {t(
                        "Revoke part or all funds from the player's account if serious violations mentioned in these Rules have been committed."
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "In case of non-compliance with any of the provisions of these Rules, the website has the right to cancel the user's account and gaming account, as well as terminate the contract for participation in gambling games concluded with the user."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>
                {t(
                  "Rights to Various Objects of Intellectual and Other Property"
                )}
              </span>
              <ol>
                <li>
                  {t(
                    "The software and all content presented on the Website are objects of copyright and other property rights. They belong to the Website or are used based on agreements and licenses issued by the right holders. Any materials of a printed and non-printed nature posted on the Website may be downloaded exclusively to a personal computer, laptop, smartphone, or tablet. At the same time, their printing or other use is allowed only for personal non-commercial use."
                  )}
                </li>
                <li>
                  {t(
                    "Free access to the materials of the Website does not entitle the user to intellectual property rights, which include copyright, trademarks, logos, content, scripts, and types of actions conducted on the Website, marketing techniques."
                  )}
                </li>
                <li>
                  {t(
                    "Any use, as well as reproduction of trademarks, logos, and other creative materials and content presented on the Website, is prohibited, according to the legislation."
                  )}
                </li>
                <li>
                  {t(
                    "In case of any prohibited actions, the user bears full responsibility and must compensate for the damage and cover the costs incurred through their fault. In addition, the user must immediately inform the Website's Support Service of everything they know about the commission of any prohibited actions by third parties. The user must actively assist in rectifying the situation, assisting in conducting investigations, and providing other feasible assistance if the aforementioned actions were carried out using their account."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("User's Personal Information")}</span>
              <ol>
                <li>
                  {t(
                    "The Website treats the confidentiality of users' personal information with the utmost importance, prioritizing the storage and use of personal information over other obligations. Any personal information provided by users is processed in strict accordance with the Privacy Policy, and all necessary measures and means of protection are used."
                  )}
                </li>
                <li>
                  {t(
                    "After the user provides personal information, they automatically agree that the Website has the right to process their personal data. This is necessary to comply with and fulfill the requirements of the legislation of a specific jurisdiction, the Rules, and other Website documentation."
                  )}
                </li>
                <li>
                  {t(
                    "The Website does not disclose users' personal information to third parties. The exception is employees who need access to personal data to perform their job duties."
                  )}
                </li>
                <li>
                  {t(
                    "All information received from the user, including personal data, appeals, statements, and correspondence, is stored in the form of electronic copies."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Cookies on the Website")}</span>
              <ol>
                <li>
                  {t(
                    "Cookies - for the uninterrupted operation and fast functioning of the Website. These are small text files stored on the user's computer or mobile device. When revisiting the site, cookies help recognize the user, allowing faster access to all services and features."
                  )}
                </li>
                <li>
                  {t(
                    "Cookies are used to record any actions performed on the Website, allowing for the improvement of the site's functionality after analyzing user traffic."
                  )}
                </li>
                <li>
                  {t(
                    "Without cookies, full participation in gambling on the Website is impossible."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Complaints, Requests, and Notifications")}</span>
              <ol>
                <li>
                  {t(
                    "To submit inquiries, complaints, and other requests, you must contact the Support Service and clearly and concisely state the essence of the request."
                  )}
                </li>
                <li>
                  {t(
                    "The Website copies and stores all requests on its server. In the future, they may be needed as conclusive evidence for resolving a conflict situation."
                  )}
                </li>
                <li>
                  {t(
                    "The results of games on the site are determined solely by chance, so the final result must be accepted as it is. If there is a discrepancy between the results of games on the user's computer or mobile device and on the Website's server, the latter should be considered more accurate and not subject to dispute. This applies to the player's account balance as well. Funds erroneously credited due to errors or human factors are subject to refund to the Website."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Transfer of Rights and Responsibilities")}</span>
              <ol>
                <li>
                  {t(
                    "The website has the right to transfer data to other companies (in whole or in part). In this case, user consent is not required. This will be considered fair in cases where the client can use the website's services on the same terms that are no less favorable to them."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Force Majeure Circumstances")}</span>
              <ol>
                <li>
                  {t(
                    "In case of force majeure circumstances, the website is not responsible for non-performance or delayed performance of the rules and obligations towards users. Force majeure circumstances include: wars, natural disasters, epidemics, pandemics, civil unrest, disruptions in the operation of public communication networks, DDoS attacks, and anything else that can have adverse consequences and can be termed 'force majeure'."
                  )}
                </li>
                <li>
                  {t(
                    "During a force majeure situation The website may be considered stopped. During the same period the deferred performance of the obligation of the Website to users. To solve problems arising from force majeure, as quickly as possible, all possibilities of technical and energy resources."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Waiver of Obligations")}</span>
              <ol>
                <li>
                  {t(
                    "Users must fulfill all their obligations. Non-performance of obligations is possible only in case of force majeure circumstances. The same applies to the website's obligations to players."
                  )}
                </li>
                <li>
                  {t(
                    "The website's waiver of its obligations is legally valid only if it is provided to the user in writing. In this case, waiver of obligations is possible only in cases of force majeure or exceptional circumstances mentioned in these Rules."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Third-Party Links")}</span>
              <ol>
                <li>
                  {t(
                    "All links on the website that lead to third-party resources are beyond the control of the website, so it is not responsible for the content of third-party websites, including the advertising, goods, or services presented on them. All hyperlinks to third-party resources are presented on the website as advertising. Whether to follow them or not is the user's personal choice. By clicking on third-party websites, clients do so at their own risk."
                  )}
                </li>
              </ol>
            </li>
            <li className="main-item">
              <span>{t("Changes in Terms")}</span>
              <ol>
                <li>
                  {t(
                    "The website has the right to make amendments, edit, change, and update these Rules and Conditions in accordance with new commercial and legal conditions, as well as user service conditions."
                  )}
                </li>
                <li>
                  {t(
                    "Any changes and additions, including updates to software, become available to users immediately after their implementation on the website. The website informs users about the changes that have occurred."
                  )}
                </li>
                <li>
                  {t(
                    "Any changes, additions, and updates on the website are made strictly in accordance with the current legislative and regulatory acts related to gambling and ensure their full compliance."
                  )}
                </li>
                <li>
                  {t(
                    "In case of disagreement with the changes made, the user has the right to stop using the Website, by closing own account and game account."
                  )}
                </li>
                <li>
                  {t(
                    "Continued use of the resource after changes have occurred automatically means acceptance of the new Terms and Conditions confidentiality, regardless of whether the user a message about the changes made and whether he has read is it modified or not."
                  )}
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default RulesPage;
