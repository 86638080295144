import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { CirclesWithBar } from "react-loader-spinner";

import {
  editFreeSpin,
  getFreeSpinInfo,
  addFreeSpinUsers,
  removeFreeSpinUsers,
} from "../../../services/admin/apiFreeSpins";
import { FreeSpinItem } from "../../../types/gameTypes";
import { getUsersList } from "../../../services/admin/apiUsers";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";

import MultiSelect from "../../MultiSelect";

interface Props {
  show: boolean;
  id: number;
  handleClose: () => void;
}

const perPageUsers = 20;

const InfoFreeSpinModal: React.FC<Props> = ({ show, id, handleClose }) => {
  const { t } = useTranslation();

  //   Selects currency data
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(2);
  //   Initial data
  const [info, setInfo] = useState<FreeSpinItem>();
  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isUsersFetching, setUsersFetching] = useState<boolean>(false);
  const [isInfoFetching, setInfoFetching] = useState<boolean>(false);
  const [isEditEnable, setEditEnable] = useState<boolean>(false);
  const [isUsersEditEnable, setUsersEditEnable] = useState<boolean>(false);
  //   Selects user data
  const [users, setUsers] = useState<{ name: string; id: string }[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<
    { name: string; id: string }[]
  >([]);
  const [usersSearch, setUsersSearch] = useState<string>("");
  const [userPage, setUserPage] = useState<number>(1);
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(true);
  //  Form values
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [date, setDate] = useState<Date>();
  const [editError, setEditError] = useState<string>("");
  const [editUsersError, setEditUsersError] = useState<string>("");
  const [addUsers, setAddUsers] = useState<string[]>([]);
  const [removeUsers, setRemoveUsers] = useState<string[]>([]);

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
      const initCurrency = response.data.filter(
        (el) => el.id === info?.currency.id
      )[0];
      if (initCurrency) {
        setSelectedCurrency(initCurrency.id);
      }
    }
  };
  useEffect(() => {
    if (info) loadCurrencyList();
    // eslint-disable-next-line
  }, [info]);
  //   Initial logic
  const loadInfo = async (): Promise<void> => {
    setInfoFetching(true);
    const result = await getFreeSpinInfo(id);
    if (typeof result !== "string") {
      const usersInitial: { name: string; id: string }[] = [];
      setTitle(result.freespin.title as string);
      setDescription(result.freespin.description as string);
      setQuantity(result.freespin.freespins_quantity.toString() as string);
      setDate(moment(result.freespin.valid_until as string).toDate());
      setInfo(result.freespin as FreeSpinItem);
      result.freespin.users.forEach((el) => {
        usersInitial.push({
          name: el.first_name,
          id: el.issue_id,
        });
      });
      setSelectedUsers(usersInitial);
    }
    setInfoFetching(false);
  };
  useEffect(() => {
    loadInfo();
    // eslint-disable-next-line
  }, []);

  //   Form logic
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    const data = {
      freespins_quantity: quantity,
      valid_until: moment(date as Date).format("YYYY-MM-DDTHH:mm:ss") || "",
      title,
      description,
      currency_id: selectedCurrency,
    };
    const result = await editFreeSpin(data, id);
    if (typeof result === "string") {
      setEditError(result as string);
      showErrorNotif(result as string);
    } else {
      setEditEnable(false);
      showSuccessNotif(t("Data edited successfully"));
    }
    setFetching(false);
  };
  const handleUsersSubmit = async (): Promise<void> => {
    setUsersFetching(true);
    setEditUsersError("");
    if (addUsers.length > 0) {
      const usersArray: number[] = [];
      addUsers.forEach((el) => {
        usersArray.push(parseInt(el, 10));
      });
      const result = await addFreeSpinUsers(usersArray, id);
      if (typeof result === "string") {
        setEditUsersError(`${editUsersError} ${result as string}`);
      } else {
        setAddUsers([]);
      }
    }
    if (removeUsers.length > 0) {
      const result = await removeFreeSpinUsers(removeUsers, id);
      if (typeof result === "string") {
        setEditUsersError(`${editUsersError} ${result as string}`);
      } else {
        setRemoveUsers([]);
      }
    }
    setUsersFetching(false);
    setUsersEditEnable(false);
  };

  //   Users logic
  const loadUsers = async (
    search: string,
    page: number,
    clearRequest?: boolean
  ): Promise<void> => {
    setHasMoreUsers(true);
    const response = await getUsersList(
      page,
      search ? `&first_name=${search || ""}` : ""
    );
    if (typeof response !== "string") {
      const result: { name: string; id: string }[] = [];
      response.data.forEach((el) => {
        result.push({
          name: `${el.first_name || ""} ${el.last_name || ""}`,
          id: el.id.toString(),
        });
      });
      setUsers(clearRequest ? [...result] : [...users, ...result]);
      if (response.data.length < perPageUsers) {
        setHasMoreUsers(false);
      }
    }
  };
  useEffect(() => {
    loadUsers(usersSearch, userPage);
    // eslint-disable-next-line
  }, []);
  const handleUsersSearch = (e: string): void => {
    setUsers([]);
    setUsersSearch(e);
    setUserPage(1);
    loadUsers(e, 1, true);
  };
  const handleUsersPaginate = (): void => {
    loadUsers(usersSearch, userPage + 1);
    setUserPage(userPage + 1);
  };
  const handleUsersSelect = (e: { name: string; id: string }): void => {
    setSelectedUsers([...selectedUsers, e]);
    setAddUsers([...addUsers, e.id]);
    setRemoveUsers(
      removeUsers.filter((el) => {
        return el !== e.id;
      })
    );
  };
  const handleUsersClear = (e: string): void => {
    const newArray = selectedUsers.filter((el) => {
      return el.id !== e;
    });
    setSelectedUsers(newArray);
    setRemoveUsers([...removeUsers, e]);
    setAddUsers(
      addUsers.filter((el) => {
        return el !== e;
      })
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Free spin info")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isInfoFetching && (
          <CirclesWithBar
            height="200"
            width="200"
            color="#0d6efd"
            wrapperStyle={{ justifyContent: "center" }}
            visible
            ariaLabel="circles-with-bar-loading"
          />
        )}
        {!isInfoFetching && (
          <>
            <div className="spin-info-item">
              <h5>{t("Common")}</h5>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Title")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Title") || "Title"}
                      value={title}
                      onChange={(e): void => {
                        setTitle(e.target.value);
                      }}
                      disabled={!isEditEnable}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Description")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder={t("Description") || "Description"}
                      value={description}
                      onChange={(e): void => {
                        setDescription(e.target.value);
                      }}
                      disabled={!isEditEnable}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Quantity")}</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={t("Quantity") || "Quantity"}
                      value={quantity}
                      onChange={(e): void => {
                        setQuantity(e.target.value);
                      }}
                      disabled={!isEditEnable}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Valid until")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(dateEvent: Date): void => {
                        setDate(dateEvent);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={date}
                      dropdownMode="select"
                      placeholderText={t("Select date") || "Select date"}
                      className="form-control"
                      disabled={!isEditEnable}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Currency")}</Form.Label>
                    <Form.Select
                      aria-label={t("Currency") || "Currency"}
                      value={selectedCurrency}
                      disabled={!isEditEnable}
                      onChange={(e): void => {
                        setSelectedCurrency(
                          parseInt(e.target.value as string, 10)
                        );
                      }}
                    >
                      {currencyList &&
                        currencyList.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.code}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              {editError && <div className="error-msg">{editError}</div>}
              <div className="spin-info-btns">
                {isEditEnable ? (
                  <Button
                    variant="primary"
                    onClick={(): void => {
                      handleSubmit();
                    }}
                    disabled={isFetching}
                  >
                    {t("Confirm")}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={(): void => {
                      setEditEnable(true);
                    }}
                  >
                    {t("Edit")}
                  </Button>
                )}
              </div>
            </div>
            <div className="spin-info-item">
              <h5>{t("Users")}</h5>
              <MultiSelect
                selectedItems={selectedUsers}
                searchedItems={users}
                hasMore={hasMoreUsers}
                searchValue={usersSearch}
                handleSearch={handleUsersSearch}
                // @ts-ignore
                handleSelect={handleUsersSelect}
                // @ts-ignore
                handleClear={handleUsersClear}
                handlePaginate={handleUsersPaginate}
                disabled={!isUsersEditEnable}
              />
              {editUsersError && (
                <div className="error-msg">{editUsersError}</div>
              )}
              <div className="spin-info-btns">
                {isUsersEditEnable ? (
                  <Button
                    variant="primary"
                    onClick={(): void => {
                      handleUsersSubmit();
                    }}
                    disabled={isUsersFetching}
                  >
                    {t("Confirm")}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={(): void => {
                      setUsersEditEnable(true);
                    }}
                  >
                    {t("Edit")}
                  </Button>
                )}
              </div>
            </div>
            <div className="spin-info-item">
              <h5>{t("Games")}</h5>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Game name")}</th>
                    <th>{t("Game vendor")}</th>
                    <th>{t("Game category")}</th>
                  </tr>
                </thead>
                {info && (
                  <tbody>
                    {info.games.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{el.title}</td>
                        <td>{el.producer}</td>
                        <td>{el.category}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoFreeSpinModal;
