import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserInfo, LocaleItem, BalanceItem } from "../../types/userTypes";
import ApiClient from "../../services/ApiClient";
import { getLocales, logout } from "../../services/apiLogin";

export interface UserDataState {
  isLogged?: boolean;
  isDataFetched: boolean;
  localesList: LocaleItem[];
  localeActive?: LocaleItem;
  userInfo: UserInfo;
  userToken: string;
}

const initialState: UserDataState = {
  isLogged: undefined,
  isDataFetched: false,
  localesList: [],
  localeActive: undefined,
  userInfo: {
    email: null,
    first_name: null,
    full_name: null,
    last_name: null,
    nick_name: null,
    permissions: [],
    phone: null,
    public_id: "",
    id: 0,
    integer_public_id: null,
    role: undefined,
    rakebackPercent: undefined,
    current_balance: {
      amount: 0,
      type: "",
    },
    balances: [],
    is_confirmed: false,
    date_of_birth: "",
    city: "",
    gender: "",
    vip_club: undefined,
    is_email_confirmed: false,
    is_phone_confirmed: false,
  },
  userToken: "",
};

export const getLocalesList = createAsyncThunk(
  "games/loadTestList",
  async () => {
    const response = await getLocales();
    if (typeof response !== "string") return response.data as LocaleItem[];
    return [];
  }
);

export const userData = createSlice({
  name: "user",
  initialState,
  reducers: {
    loggedAction: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload;
      if (action.payload === false) {
        state.userInfo = {
          email: null,
          first_name: null,
          full_name: null,
          last_name: null,
          nick_name: null,
          permissions: [],
          phone: null,
          public_id: "",
          id: 0,
          integer_public_id: null,
          role: undefined,
          rakebackPercent: undefined,
          current_balance: {
            amount: 0,
            type: "",
          },
          balances: [],
          is_confirmed: false,
          date_of_birth: "",
          city: "",
          gender: "",
          vip_club: undefined,
        };
        if (state.userToken) {
          logout();
        }
      }
      localStorage.setItem("isLogged", JSON.stringify(action.payload));
    },
    setUserData: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = { ...state.userInfo, ...action.payload };
      if (action.payload?.vip_club?.rewards) {
        action.payload?.vip_club?.rewards.forEach((el) => {
          if (el.type === "rakeback" && el.profit_in_percentages) {
            state.userInfo = {
              ...action.payload,
              rakebackPercent: el.profit_in_percentages,
            };
          }
        });
      }
      state.isDataFetched = true;
    },
    setActiveLocal: (state, action: PayloadAction<LocaleItem>) => {
      state.localeActive = action.payload;
    },
    updateTournametsCount: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        if (state.userInfo.active_tournaments_count) {
          const init: number = state.userInfo.active_tournaments_count;
          state.userInfo.active_tournaments_count = init + 1;
        } else {
          state.userInfo.active_tournaments_count = 1;
        }
      } else {
        const init: number | undefined =
          state.userInfo.active_tournaments_count;
        state.userInfo.active_tournaments_count = init ? init - 1 : 0;
      }
    },
    updateUserBalance: (state, action: PayloadAction<number>) => {
      const filterdBalances = state.userInfo.balances.filter(
        (el) => el.type !== state.userInfo.current_balance.type
      );
      state.userInfo = {
        ...state.userInfo,
        current_balance: {
          amount: action.payload,
          type: state.userInfo.current_balance.type,
          code: state.userInfo.current_balance.code,
        },
        balances: [
          ...filterdBalances,
          {
            amount: action.payload,
            type: state.userInfo.current_balance.type,
            code: state.userInfo.current_balance.code,
          },
        ],
      };
    },
    updateAllBalances: (state, action: PayloadAction<BalanceItem[]>) => {
      state.userInfo = {
        ...state.userInfo,
        balances: action.payload,
      };
    },
    updateUserTournaments: (state) => {
      if (state.userInfo.tournaments) {
        const newArray = state.userInfo.tournaments;
        newArray.shift();
        state.userInfo = {
          ...state.userInfo,
          tournaments: newArray,
        };
      }
    },
    setUserToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
      localStorage.setItem("token", action.payload);
      ApiClient.setToken(action.payload);
      localStorage.setItem("tokenDate", JSON.stringify(new Date()));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getLocalesList.fulfilled,
      (state: UserDataState, action: PayloadAction<LocaleItem[]>) => {
        state.localesList = action.payload;
      }
    );
  },
});

export const {
  loggedAction,
  setUserData,
  setUserToken,
  updateUserBalance,
  setActiveLocal,
  updateUserTournaments,
  updateAllBalances,
  updateTournametsCount,
} = userData.actions;

export default userData.reducer;
