import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getAllGamesList } from "../../services/apiGames";
import {
  GameItemType,
  CategoryItemType,
  VendorItemType,
} from "../../types/gameTypes";
import {
  AllGamesListResponse,
  GameListPayload,
} from "../../types/requestTypes";

export interface CardGamesState {
  cardGames: GameItemType[] | null;
  categories: CategoryItemType[] | null;
  vendors: VendorItemType[] | null;
  allCardFetching: boolean;
  categoryFetching: boolean;
  totalGames: number;
  cardActivePage: number;
  hasMoreCardGames: boolean;
}

const initialState: CardGamesState = {
  cardGames: null,
  categories: null,
  vendors: null,
  allCardFetching: false,
  categoryFetching: false,
  totalGames: 0,
  cardActivePage: 1,
  hasMoreCardGames: true,
};

export const getAllCardGamesFunc = createAsyncThunk(
  "cardGames/loadAllList",
  async (data: GameListPayload) => {
    let itemsPerPage;
    if (window.innerWidth <= 576) {
      itemsPerPage = 9;
    } else if (window.innerWidth <= 990) {
      itemsPerPage = 24;
    } else {
      itemsPerPage = 32;
    }
    const response = await getAllGamesList(
      data.search as string | null,
      data.category as string | null,
      data.producer as string | null,
      data.page as number | undefined,
      itemsPerPage,
      data.lobby as boolean | undefined,
      data.isLive as boolean | undefined
    );
    if (typeof response !== "string") return response as AllGamesListResponse;
    return { data: [], meta: { total: 0, last_page: 0 } };
  }
);

export const cardGames = createSlice({
  name: "cardGames",
  initialState,
  reducers: {
    setCardActivePage: (state, action: PayloadAction<number>) => {
      state.cardActivePage = action.payload;
    },
    clearCardGames: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.cardGames = null;
      } else {
        state.cardGames = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllCardGamesFunc.fulfilled,
      (state: CardGamesState, action: PayloadAction<AllGamesListResponse>) => {
        state.cardGames = state.cardGames
          ? [...state.cardGames, ...action.payload.data]
          : action.payload.data;
        let itemsPerPage;
        if (window.innerWidth <= 576) {
          itemsPerPage = 9;
        } else if (window.innerWidth <= 990) {
          itemsPerPage = 24;
        } else {
          itemsPerPage = 32;
        }
        if (action.payload.data.length < itemsPerPage) {
          state.hasMoreCardGames = false;
        } else {
          state.hasMoreCardGames = true;
        }
        state.totalGames = action.payload.meta.total;
        state.allCardFetching = false;
      }
    );
    builder.addCase(getAllCardGamesFunc.pending, (state: CardGamesState) => {
      state.allCardFetching = true;
    });
  },
});

export const { setCardActivePage, clearCardGames } = cardGames.actions;

export default cardGames.reducer;
