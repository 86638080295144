import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import type { RootState } from "../../../../store/store";
// import playIcon from "../../images/play-icon.svg";

interface Props {
  name: string;
  description: string;
  bg: string;
  bgGame: string;
  id: number;
  // isDemo?: boolean;
  // isPlay?: boolean;
}

const GameItem: React.FC<Props> = ({
  name,
  description,
  bg,
  bgGame,
  id,
  // isDemo = true,
  // isPlay = true,
}) => {
  const navigate = useNavigate();
  const isLogged = useSelector((state: RootState) => state.user.isLogged);

  return (
    <div
      className="game-item"
      onClick={(): void => {
        if (isLogged) {
          navigate(`/game/${id}`, {
            state: { title: name, description, bg: bgGame },
          });
        } else {
          navigate("/register");
        }
      }}
    >
      <div className="game-item-img">
        <div
          className="game-item-bg"
          style={{ backgroundImage: `url(${bg})` }}
        />
      </div>
      {/* {isPlay && (
        <div className="game-item-icon">
          <img src={playIcon} alt="icon" width={25} />
          Play
        </div>
      )}
      {isDemo && <span>Demo</span>} */}
      {/* <div className="game-item-name">{name}</div> */}
    </div>
  );
};

export default GameItem;
