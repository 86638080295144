import ApiClient from "../ApiClient";
import { RequestError } from "../../types/requestTypes";
import { UserInfo } from "../../types/userTypes";
import { BetsReportingItem } from "../../types/betsTypes";
import {
  OrderReportingItem,
  WithdrawalReportingItem,
} from "../../types/paymentTypes";
import { GameReportItem } from "../../types/gameTypes";

export const getActiveUsers = async (): Promise<
  { data: { users: UserInfo[] } } | string
> => {
  try {
    const result = await ApiClient.get<{
      data: { users: UserInfo[] };
    }>("/reports/players-online");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getNewUsers = async (
  periodString?: string
): Promise<{ data: { users: UserInfo[] } } | string> => {
  try {
    const result = await ApiClient.get<{
      data: { users: UserInfo[] };
    }>(`/reports/players-new?${periodString || ""}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getBets = async (
  currency_id: number,
  balance_type: string,
  periodString?: string
): Promise<{ data: BetsReportingItem } | string> => {
  try {
    const result = await ApiClient.get<{
      data: BetsReportingItem;
    }>(
      `/reports/bets?currency_id=${currency_id}&balance_type=${balance_type}${
        periodString || ""
      }`,
      {
        params: { actions: ["bet", "win"] },
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getDeposits = async (
  operation_status: string,
  currency: number,
  periodString?: string
): Promise<{ data: OrderReportingItem } | string> => {
  try {
    const result = await ApiClient.get<{
      data: OrderReportingItem;
    }>(
      `/reports/deposits?${
        operation_status === "all" ? "" : `status=${operation_status}`
      }${periodString || ""}&currency_id=${currency}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getWithdrawals = async (
  operation_status: string | number,
  currency: number,
  periodString?: string
): Promise<{ data: WithdrawalReportingItem } | string> => {
  try {
    const result = await ApiClient.get<{
      data: WithdrawalReportingItem;
    }>(
      `/reports/withdrawals?${
        operation_status === 88 ? "" : `status=${operation_status}`
      }${periodString || ""}&currency_id=${currency}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getGamesReport = async (
  page: number,
  searchString?: string
): Promise<
  | {
      data: GameReportItem[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: GameReportItem[];
      meta: {
        last_page: number;
      };
    }>(`/reports/games/rounds?page=${page}&per_page=20${searchString || ""}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
