import React, { useEffect, useState } from "react";
import { Table, Form, Button, Dropdown, Accordion } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import UpdateDocumentModal from "./modal/UpdateDocumentModal";

import { formatDateMinutes, formatDateDay } from "../../helpers/getCorrectDate";
import {
  getAllDocuments,
  changeDocumentStatus,
  deleteDocument,
  updateDocumentPhoto,
  updateCreditCard,
} from "../../services/admin/apiDocumentsAdmin";
import { AdminPanelTabs } from "../../types/adminTypes";
import { DocumentItem } from "../../types/documentsTypes";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../helpers/renderNotification";
import type { RootState } from "../../store/store";
import { UserRoles } from "../../types/userTypes";

import DocumentShowModal from "./modal/DocumentShowModal";

enum Statuses {
  pending = "Pending",
  processing = "Processing",
  approved = "Approved",
  declined = "Declined",
}

interface Props {
  activeTab: AdminPanelTabs;
}

const DocumentsTab: React.FC<Props> = ({ activeTab }) => {
  const [documents, setDocuments] = useState<DocumentItem[]>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [preview, setPreview] = useState<DocumentItem>({} as DocumentItem);
  //   Modals
  const [previewModalShow, setPreviewModalShow] = useState<boolean>(false);
  const [updateDocModalShow, setUpdateDocModalShow] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>("");

  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const loadDocuments = async (page: number, id?: string): Promise<void> => {
    setFetching(true);
    const result = await getAllDocuments(page + 1, id);
    if (typeof result !== "string") {
      setDocuments(result.data as DocumentItem[]);
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!documents && activeTab === AdminPanelTabs.docs) {
      loadDocuments(0);
    }
    // eslint-disable-next-line
  }, [activeTab, documents]);

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadDocuments(e);
  };

  const showModal = (img: string): void => {
    setPreviewModalShow(true);
    setImgUrl(img);
  };
  const hideModal = (): void => {
    setPreviewModalShow(false);
    setImgUrl("");
  };

  const handleChangeStatus = async (
    id: number,
    status: string,
    user_id: number
  ): Promise<void> => {
    const result = await changeDocumentStatus(
      id,
      parseInt(status, 10),
      user_id
    );
    if (typeof result !== "string") {
      loadDocuments(activePage, search);
    }
  };

  const handleDelete = async (id: number): Promise<void> => {
    const result = await deleteDocument(id);
    if (typeof result !== "string") {
      loadDocuments(activePage, search);
      showSuccessNotif(t("Data deleted successfully"));
    } else {
      showErrorNotif(result as string);
    }
  };

  const handleUpdateCreditCard = async (
    documentId: number,
    userId: number,
    documentType: string,
    withdrawalCard: string,
    withdrawalMM: string,
    withdrawalYY: string,
    cardImg: File | undefined
  ): Promise<void> => {
    const result = await updateCreditCard({
      document_id: documentId,
      user_id: userId,
      file_name: documentType,
      credit_card_number: withdrawalCard,
      credit_card_month: withdrawalMM,
      credit_card_year: withdrawalYY,
      file: cardImg,
    });
    if (typeof result === "string") {
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Document updated successfully"));
      loadDocuments(activePage);
      setUpdateDocModalShow(false);
    }
  };

  const handleUpdateDocPhoto = async (
    documentId: number,
    userId: number,
    e: File,
    type: string
  ): Promise<void> => {
    const result = await updateDocumentPhoto({
      user_id: userId,
      document_id: documentId,
      file_name: type,
      file: e,
    });
    if (typeof result === "string") {
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Document updated successfully"));
      loadDocuments(activePage);
      setUpdateDocModalShow(false);
    }
  };

  // Search logic
  const handleSearch = (): void => {
    setActivePage(0);
    loadDocuments(0, search);
  };
  const handleClearSearch = (): void => {
    setActivePage(0);
    setSearch("");
    loadDocuments(0);
  };

  const renderStatus = (status: Statuses): string => {
    switch (status) {
      case Statuses.approved:
        return "2";
      case Statuses.declined:
        return "3";
      case Statuses.pending:
      case Statuses.processing:
      default:
        return "1";
    }
  };

  const renderType = (type: string): string => {
    switch (type) {
      case "CREDIT_CARD":
        return t("Bank Сard Photo");
      case "ID_CARD":
        return t("ID Сard");
      case "DRIVER_LICENSE":
        return t("Driver's License");
      case "OTHER":
        return t("Another identity document");
      case "TAX_NUMBER":
        return t("Tax Number");
      case "SELFIE_IDENTITY":
        return t("Selfie with an identity document");
      default:
        return "";
    }
  };

  return (
    <div className="admin-documents-wrap admin-tab-warp">
      <h3>{t("Documents")}</h3>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {!isFetching && (
        <>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h4>{t("Filters")}</h4>
              </Accordion.Header>
              <Accordion.Body>
                <div className="admin-search-wrap">
                  <h4>{t("Search")}</h4>
                  <div className="admin-search-row mb-3">
                    <Form.Group style={{ width: "225px" }}>
                      <Form.Label>{t("Search by")}</Form.Label>
                      <Form.Select
                        aria-label={t("Search by") || "Search by"}
                        value="id"
                        onChange={(): void => {
                          console.log("change");
                        }}
                      >
                        <option value="id">{t("User")} ID</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={t("Search") || "Search"}
                      value={search}
                      onChange={(e): void => {
                        if (e.target.value === "") handleClearSearch();
                        setSearch(e.target.value);
                      }}
                      disabled={isFetching}
                      data-testid="documents-search-input"
                    />
                    <div className="admin-search-btns">
                      <Button
                        variant="danger"
                        onClick={handleClearSearch}
                        disabled={isFetching || !search}
                        data-testid="documents-search-clear"
                      >
                        {t("Clear")}
                      </Button>
                      <Button
                        variant="primary"
                        onClick={handleSearch}
                        disabled={isFetching || !search}
                        data-testid="documents-search-confirm"
                      >
                        {t("Confirm")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Table striped bordered className="mb-5" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("Date")}</th>
                <th>{t("Document")}</th>
                <th>{t("Type")}</th>
                <th>{t("Side")}</th>
                <th>{t("User")}</th>
                <th>{t("Status")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            {documents && (
              <tbody data-testid="documents-admin-table">
                {documents.map((el) => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>
                      <td> {formatDateMinutes(el.created_at as string)}</td>
                    </td>
                    {/* eslint-disable-next-line */}
                    <td
                      className="doc-img-cell"
                      onClick={(): void => {
                        showModal(el.document_path as string);
                      }}
                      data-testid="documents-img-cell"
                    >
                      <img src={el.document_path} alt="" />
                    </td>
                    <td>
                      {renderType(el.type)}
                      {el.type === "CREDIT_CARD" && (
                        <div className="doc-user-cell">
                          <b>{t("Card address")}:</b> {el.credit_card_number}
                          <br />
                          <b>YY:</b> {el.credit_card_year}
                          <br />
                          <b>MM:</b> {el.credit_card_month}
                        </div>
                      )}
                    </td>
                    <td>{t(`${el.side as string} Side`)}</td>
                    <td>
                      <div className="doc-user-cell">
                        <b>{t("Name")}:</b> {el.user?.first_name}{" "}
                        {el.user?.last_name}
                        <br />
                        <b>{t("Email address")}:</b> {el.user?.email}
                        <br />
                        <b>ID:</b> {el.user?.id}
                        <br />
                        <b>{t("Date of birth")}: </b>
                        {el.user?.date_of_birth
                          ? formatDateDay(el.user.date_of_birth as string)
                          : null}
                      </div>
                    </td>
                    <td>
                      <Form.Select
                        aria-label={t("Status") || "Status"}
                        value={renderStatus(el.status as Statuses)}
                        disabled={
                          userRole !== UserRoles.admin &&
                          userRole !== UserRoles["senior-operator"]
                        }
                        onChange={(e): void => {
                          handleChangeStatus(
                            el.id as number,
                            e.target.value as string,
                            (el.user?.id as number) || 0
                          );
                        }}
                        data-testid="documents-status-select"
                      >
                        <option value="1">{t("Pending")}</option>
                        <option
                          value="2"
                          data-testid="documents-status-select-item"
                        >
                          {t("Approved")}
                        </option>
                        <option value="3">{t("Declined")}</option>
                      </Form.Select>
                    </td>
                    <td>
                      <div className="admin-actions-wrap">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            disabled={
                              userRole !== UserRoles.admin &&
                              userRole !== UserRoles["senior-operator"] &&
                              userRole !== UserRoles["payment-manager"]
                            }
                          >
                            {t("Actions")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {(userRole === UserRoles.admin ||
                              userRole === UserRoles["senior-operator"] ||
                              userRole === UserRoles["payment-manager"]) && (
                              <Dropdown.Item
                                onClick={(): void => {
                                  handleDelete(el.id);
                                }}
                              >
                                {t("Delete")}
                              </Dropdown.Item>
                            )}
                            {(userRole === UserRoles.admin ||
                              userRole === UserRoles["senior-operator"] ||
                              userRole === UserRoles["payment-manager"]) && (
                              <Dropdown.Item
                                onClick={(): void => {
                                  setPreview(el);
                                  setUpdateDocModalShow(true);
                                }}
                              >
                                {t("Edit")}
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </>
      )}
      {totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      {previewModalShow && (
        <DocumentShowModal
          show={previewModalShow}
          handleClose={hideModal}
          img={imgUrl}
        />
      )}
      {updateDocModalShow && (
        <UpdateDocumentModal
          show={updateDocModalShow}
          handleClose={(): void => {
            setUpdateDocModalShow(false);
          }}
          handleUpdateDocPhoto={handleUpdateDocPhoto}
          handleUpdateCreditCard={handleUpdateCreditCard}
          preview={preview}
        />
      )}
    </div>
  );
};

export default DocumentsTab;
