import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { TournamentRewardItem } from "../../types/tournamentTypes";
import { collectTornamentReward } from "../../services/apiTournaments";
import { updateTournametsCount } from "../../store/slices/userData";

interface Props {
  clickHandler: () => void;
  item: TournamentRewardItem;
}

const RewardModal: React.FC<Props> = ({ clickHandler, item }) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleConfirm = async (): Promise<void> => {
    setIsFetching(true);
    await collectTornamentReward(item.tournament_id);
    dispatch(updateTournametsCount(false));
    setIsFetching(false);
    clickHandler();
  };
  return (
    <div className="default-modal reward-modal">
      <div className="overlay" />
      <div className="modal-content">
        <div className={isFetching ? "" : "reward-modal--wrapper"}>
          <div className="modal-title">
            {item.reward_amount === 0
              ? t("no_reward_msg", {
                  tournament: item.tournament_title,
                  points: item.points,
                  reward_place: item.place,
                })
              : t("reward_msg", {
                  tournament: item.tournament_title,
                  points: item.points,
                  reward_place: item.place,
                  reward: `${item.tournament_currency} ${item.reward_amount}`,
                })}
          </div>
          <button
            className="primary-btn"
            type="button"
            onClick={(): void => {
              handleConfirm();
            }}
            disabled={isFetching}
          >
            {t("Confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RewardModal;
