import ApiClient from "../ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../../types/requestTypes";
import {
  BonusItem,
  BonusRewardItem,
  BonusRewardPayload,
  BonusPayload,
} from "../../types/bonusesTypes";

export const getBonusesList = async (
  page: number
): Promise<
  | {
      data: BonusItem[];
      meta: { last_page: number };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: BonusItem[];
      meta: { last_page: number };
    }>(`/bonuses/list?page=${page}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getBonusesRewardList = async (
  page: number,
  search?: string
): Promise<
  | {
      data: BonusRewardItem[];
      meta: { last_page: number };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: BonusRewardItem[];
      meta: { last_page: number };
    }>(
      `/bonuses/campaigns/list?page=${page}&per_page=10${
        search ? `title=${search}` : ""
      }`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const addNewBonus = async (
  data: BonusPayload
): Promise<DeafaultSuccesResponse | string> => {
  const formData = new FormData();
  if (data.img) {
    // eslint-disable-next-line
    formData.append("image", data.img, data.img.name as string);
  }
  // eslint-disable-next-line
  Object.keys(data).forEach((el) => {
    if (el !== "img") {
      // @ts-ignore
      // eslint-disable-next-line
        formData.append(el, data[el]);
    }
  });
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/bonuses/create",
      formData
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteBonus = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/bonuses/delete/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editBonusInfo = async (
  id: number,
  data: BonusPayload
): Promise<DeafaultSuccesResponse | string> => {
  const formData = new FormData();
  if (data.img) {
    // eslint-disable-next-line
    formData.append("image", data.img, data.img.name as string);
  }
  // eslint-disable-next-line
  Object.keys(data).forEach((el) => {
    if (el !== "img") {
      // @ts-ignore
      // eslint-disable-next-line
          formData.append(el, data[el]);
    }
  });
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/bonuses/update/${id}`,
      formData
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const addNewReward = async (
  payload: BonusRewardPayload
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `bonuses/campaign/create`,
      { ...payload }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteReward = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/bonuses/campaign/delete/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editReward = async (
  id: number,
  payload: BonusRewardPayload
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `bonuses/campaign/update/${id}`,
      { ...payload }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
