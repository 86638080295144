import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { AdminPanelTabs, ReportingTabs } from "../../types/adminTypes";

import ActiveUsers from "./reporting/ActiveUsers";
import NewUsers from "./reporting/NewUsers";
import Bets from "./reporting/Bets";
import Deposits from "./reporting/Deposits";
import Withdrawals from "./reporting/Withdrawals";
import UserBalances from "./reporting/UserBalances";
import Games from "./reporting/Games";

interface Props {
  activeTab: AdminPanelTabs;
}

const ReportingTab: React.FC<Props> = ({ activeTab }) => {
  const { t } = useTranslation();

  const [activeReportingTab, setActiveReportingTab] = useState<ReportingTabs>(
    ReportingTabs.activeUsers
  );

  const renderTitle = (): string => {
    switch (activeReportingTab) {
      case ReportingTabs.activeUsers:
        return t("Active users");
      case ReportingTabs.newUsers:
        return t("New users");
      case ReportingTabs.bets:
        return t("Bets");
      case ReportingTabs.deposits:
        return t("Deposit");
      case ReportingTabs.withdrawals:
        return t("Withdrawal");
      case ReportingTabs.balances:
        return t("Users balances");
      case ReportingTabs.games:
        return t("Games");

      default:
        return t("Reporting");
    }
  };

  return (
    <div className="reporting-wrap admin-tab-warp">
      {activeTab === AdminPanelTabs.reports && (
        <>
          <h3>{renderTitle()}</h3>
          <Tabs
            defaultActiveKey={activeReportingTab}
            className="mb-3"
            data-testid="reporting-tabs"
            onSelect={(e): void => setActiveReportingTab(e as ReportingTabs)}
          >
            <Tab eventKey={ReportingTabs.activeUsers} title={t("Active users")}>
              {activeReportingTab === ReportingTabs.activeUsers && (
                <ActiveUsers />
              )}
            </Tab>
            <Tab eventKey={ReportingTabs.newUsers} title={t("New users")}>
              {activeReportingTab === ReportingTabs.newUsers && <NewUsers />}
            </Tab>
            <Tab eventKey={ReportingTabs.balances} title={t("Users balances")}>
              {activeReportingTab === ReportingTabs.balances && (
                <UserBalances />
              )}
            </Tab>
            <Tab eventKey={ReportingTabs.bets} title={t("Bets")}>
              {activeReportingTab === ReportingTabs.bets && <Bets />}
            </Tab>
            <Tab eventKey={ReportingTabs.deposits} title={t("Deposit")}>
              {activeReportingTab === ReportingTabs.deposits && <Deposits />}
            </Tab>
            <Tab eventKey={ReportingTabs.withdrawals} title={t("Withdrawal")}>
              {activeReportingTab === ReportingTabs.withdrawals && (
                <Withdrawals />
              )}
            </Tab>
            <Tab eventKey={ReportingTabs.games} title={t("Games")}>
              {activeReportingTab === ReportingTabs.games && <Games />}
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default ReportingTab;
