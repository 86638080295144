import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Tab,
  Tabs,
  Table,
  Row,
  Col,
  Form,
  Button,
  Accordion,
} from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

import {
  formatDateMinutes,
  formatDateSecondsApi,
} from "../../helpers/getCorrectDate";

import { AdminPanelTabs } from "../../types/adminTypes";
import { DepositItem, WithdrawalItemNew } from "../../types/paymentTypes";
import {
  getOrdersAdmin,
  getOrdersCoinsAdmin,
  getWithdrawalsAdmin,
  getAiforyAdmin,
  getMoneygoAdmin,
  getStreampayAdmin,
  getKaptahpayAdmin,
  updateOrderStatus,
  updateWithdrawalStatus,
} from "../../services/admin/apiPaymentsAdmin";
import type { RootState } from "../../store/store";
import { UserRoles } from "../../types/userTypes";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../helpers/renderNotification";

import PayoutCommentModal from "./modal/PayoutCommentModal";

enum PaymentTabs {
  deposit = "deposit",
  depositCoins = "depositCoins",
  withdrawal = "withdrawal",
  aifory = "aifory",
  moneygo = "moneygo",
  streampay = "streampay",
  kaptahpay = "kaptahpay",
}

interface Props {
  activeTab: AdminPanelTabs;
}

const depositStatuses = [
  "success",
  "cancel",
  "processing",
  "error",
  "pending",
  "checkPayment",
  "not_paid",
  "not_paid_timeout",
  "not_paid_unavailable_country",
  "hold_payment",
  "new",
  "entered_card",
  "partial_payment",
  "awaiting_confirmation",
  "awaiting_payment",
];

const kaptahDepositStatuses = ["pending", "paid", "unpaid"];

const PaymentsTab: React.FC<Props> = ({ activeTab }) => {
  const [key, setKey] = useState<PaymentTabs>(PaymentTabs.deposit);
  const [orders, setOrders] = useState<DepositItem[]>();
  const [ordersCoins, setOrdersCoins] = useState<DepositItem[]>();
  const [withdrawals, setWithdrawals] = useState<WithdrawalItemNew[]>();
  const [aifory, setAifory] = useState<DepositItem[]>();
  const [moneygo, setMoneygo] = useState<DepositItem[]>();
  const [streampay, setStreampay] = useState<DepositItem[]>();
  const [kaptahpay, setKaptahpay] = useState<DepositItem[]>();
  const [isOrdersFetching, setOrdersFetching] = useState<boolean>(false);
  const [isOrdersCoinsFetching, setOrdersCoinsFetching] =
    useState<boolean>(false);
  const [isAiforyFetching, setAiforyFetching] = useState<boolean>(false);
  const [isMoneygoFetching, setMoneygoFetching] = useState<boolean>(false);
  const [isStreampayFetching, setStreampayFetching] = useState<boolean>(false);
  const [isKaptahpayFetching, setKaptahpayFetching] = useState<boolean>(false);
  const [isModalShow, setModalShow] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<WithdrawalItemNew>();
  const [isWithdrawalsFetching, setWithdrawalsFetching] =
    useState<boolean>(false);
  const [activePageOrders, setActivePageOrders] = useState<number>(0);
  const [activePageOrdersCoins, setActivePageOrdersCoins] = useState<number>(0);
  const [totalPagesOrders, setTotalPagesOrders] = useState<number>(1);
  const [totalPagesOrdersCoins, setTotalPagesOrdersCoins] = useState<number>(1);
  const [activePageWithdrawals, setActivePageWithdrawals] = useState<number>(0);
  const [totalPagesWithdrawals, setTotalPagesWithdrawals] = useState<number>(1);
  const [activePageAifory, setActivePageAifory] = useState<number>(0);
  const [totalPagesAifory, setTotalPagesAifory] = useState<number>(1);
  const [activePageMoneygo, setActivePageMoneygo] = useState<number>(0);
  const [totalPagesMoneygo, setTotalPagesMoneygo] = useState<number>(1);
  const [activePageStreampay, setActivePageStreampay] = useState<number>(0);
  const [totalPagesStreampay, setTotalPagesStreampay] = useState<number>(1);
  const [activePageKaptahpay, setActivePageKaptahpay] = useState<number>(0);
  const [totalPagesKaptahpay, setTotalPagesKaptahpay] = useState<number>(1);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [userId, setUserId] = useState<string>("");
  const [orderHash, setOrderHash] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("All");
  const [searchString, setSearchString] = useState<string>("");
  const [withdrawalStatuses] = useState<{ id: number; name: string }[]>([
    {
      id: 0,
      name: "PENDING",
    },
    {
      id: 2,
      name: "STATUS_COMPLETED",
    },
    {
      id: 3,
      name: "STATUS_DECLINED",
    },
  ]);

  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const handleOrderStatus = async (
    id: number,
    status: string,
    payment_system: string
  ): Promise<void> => {
    setOrdersFetching(true);
    const result = await updateOrderStatus(id, status, payment_system);
    if (typeof result !== "string") {
      if (key === PaymentTabs.deposit) {
        const arrayCopy = orders || [];
        const changedItem = arrayCopy?.filter((el) => el.id === id);
        const index = arrayCopy.indexOf(changedItem[0]);
        if (index !== -1) {
          arrayCopy[index] = { ...changedItem[0], status };
        }
        setOrders(arrayCopy);
      } else {
        const arrayCopy = ordersCoins || [];
        const changedItem = arrayCopy?.filter((el) => el.id === id);
        const index = arrayCopy.indexOf(changedItem[0]);
        if (index !== -1) {
          arrayCopy[index] = { ...changedItem[0], status };
        }
        setOrdersCoins(arrayCopy);
      }
      showSuccessNotif(t("Data edited successfully"));
    } else {
      showErrorNotif(result as string);
    }
    setOrdersFetching(false);
  };
  const handleWithdrawalStatus = async (
    id: number,
    status: string,
    payment_system: string
  ): Promise<void> => {
    setWithdrawalsFetching(true);
    const newStatus = withdrawalStatuses?.filter(
      (el) => el.id === parseInt(status, 10)
    );
    const result = await updateWithdrawalStatus(
      id,
      newStatus[0].id,
      payment_system
    );
    if (typeof result !== "string") {
      const arrayCopy = withdrawals || [];
      const changedItem = arrayCopy?.filter((el) => el.id === id);
      const index = arrayCopy.indexOf(changedItem[0]);
      if (index !== -1) {
        arrayCopy[index] = {
          ...changedItem[0],
          status: newStatus[0]?.id || 0,
        };
      }
      setWithdrawals(arrayCopy);
      showSuccessNotif(t("Data edited successfully"));
    } else {
      showErrorNotif(result as string);
    }
    setWithdrawalsFetching(false);
  };

  const loadOrders = async (page: number, search?: string): Promise<void> => {
    setOrdersFetching(true);
    const result = await getOrdersAdmin(search || "", page);
    if (typeof result !== "string") {
      setOrders(result.data as DepositItem[]);
      setTotalPagesOrders(result.meta.last_page as number);
    }
    setOrdersFetching(false);
  };
  const loadOrdersCoins = async (
    page: number,
    search?: string
  ): Promise<void> => {
    setOrdersCoinsFetching(true);
    let modifiedSearch = search;
    if (modifiedSearch && orderHash) {
      modifiedSearch = modifiedSearch.replace("internal_order_id", "order_id");
    }
    const result = await getOrdersCoinsAdmin(modifiedSearch || "", page);
    if (typeof result !== "string") {
      setOrdersCoins(result.data as DepositItem[]);
      setTotalPagesOrdersCoins(result.meta.last_page as number);
    }
    setOrdersCoinsFetching(false);
  };
  const loadWithdrawal = async (
    page: number,
    search?: string
  ): Promise<void> => {
    if (orderHash) {
      setWithdrawals([] as WithdrawalItemNew[]);
      setTotalPagesWithdrawals(0);
    } else {
      setWithdrawalsFetching(true);
      const result = await getWithdrawalsAdmin(search || "", page);
      if (typeof result !== "string") {
        setWithdrawals(result.data as WithdrawalItemNew[]);
        setTotalPagesWithdrawals(result.meta.last_page as number);
      }
      setWithdrawalsFetching(false);
    }
  };
  const loadAifory = async (page: number, search?: string): Promise<void> => {
    setAiforyFetching(true);
    const result = await getAiforyAdmin(search || "", page);
    if (typeof result !== "string") {
      setAifory(result.data as DepositItem[]);
      setTotalPagesAifory(result.meta.last_page as number);
    }
    setAiforyFetching(false);
  };
  const loadMoneygo = async (page: number, search?: string): Promise<void> => {
    setMoneygoFetching(true);
    const result = await getMoneygoAdmin(search || "", page);
    if (typeof result !== "string") {
      setMoneygo(result.data as DepositItem[]);
      setTotalPagesMoneygo(result.meta.last_page as number);
    }
    setMoneygoFetching(false);
  };
  const loadStreampay = async (
    page: number,
    search?: string
  ): Promise<void> => {
    setStreampayFetching(true);
    const result = await getStreampayAdmin(search || "", page);
    if (typeof result !== "string") {
      setStreampay(result.data as DepositItem[]);
      setTotalPagesStreampay(result.meta.last_page as number);
    }
    setStreampayFetching(false);
  };

  const loadKaptahpay = async (
    page: number,
    search?: string
  ): Promise<void> => {
    setKaptahpayFetching(true);
    const result = await getKaptahpayAdmin(search || "", page);
    if (typeof result !== "string") {
      setKaptahpay(result.data as DepositItem[]);
      setTotalPagesKaptahpay(result.meta.last_page as number);
    }
    setKaptahpayFetching(false);
  };

  useEffect(() => {
    if (
      activeTab === AdminPanelTabs.payments &&
      key === PaymentTabs.depositCoins &&
      !ordersCoins
    ) {
      loadOrdersCoins(1, searchString);
    }
    if (
      activeTab === AdminPanelTabs.payments &&
      key === PaymentTabs.deposit &&
      !orders
    ) {
      loadOrders(1, searchString);
    }
    if (
      activeTab === AdminPanelTabs.payments &&
      key === PaymentTabs.withdrawal &&
      !withdrawals
    ) {
      loadWithdrawal(1, searchString);
    }
    if (
      activeTab === AdminPanelTabs.payments &&
      key === PaymentTabs.aifory &&
      !aifory
    ) {
      loadAifory(1, searchString);
    }
    if (
      activeTab === AdminPanelTabs.payments &&
      key === PaymentTabs.moneygo &&
      !moneygo
    ) {
      loadMoneygo(1, searchString);
    }
    if (
      activeTab === AdminPanelTabs.payments &&
      key === PaymentTabs.streampay &&
      !streampay
    ) {
      loadStreampay(1, searchString);
    }
    if (
      activeTab === AdminPanelTabs.payments &&
      key === PaymentTabs.kaptahpay &&
      !kaptahpay
    ) {
      loadKaptahpay(1, searchString);
    }
    // eslint-disable-next-line
  }, [activeTab, orders, withdrawals, key, searchString, aifory]);

  const handlePaginationChangeOrders = (e: number): void => {
    setActivePageOrders(e);
    loadOrders(e + 1, searchString);
  };
  const handlePaginationChangeOrdersCoins = (e: number): void => {
    setActivePageOrdersCoins(e);
    loadOrdersCoins(e + 1, searchString);
  };
  const handlePaginationChangeWithdrawals = (e: number): void => {
    setActivePageWithdrawals(e);
    loadWithdrawal(e + 1, searchString);
  };
  const handlePaginationChangeAifory = (e: number): void => {
    setActivePageAifory(e);
    loadAifory(e + 1, searchString);
  };
  const handlePaginationChangeMoneygo = (e: number): void => {
    setActivePageMoneygo(e);
    loadMoneygo(e + 1, searchString);
  };
  const handlePaginationChangeStreampay = (e: number): void => {
    setActivePageStreampay(e);
    loadStreampay(e + 1, searchString);
  };
  const handlePaginationChangeKaptahpay = (e: number): void => {
    setActivePageKaptahpay(e);
    loadKaptahpay(e + 1, searchString);
  };

  const handleClearSearch = (): void => {
    setOrders(undefined);
    setOrdersCoins(undefined);
    setWithdrawals(undefined);
    setAifory(undefined);
    setMoneygo(undefined);
    setStreampay(undefined);
    setKaptahpay(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setUserId("");
    setOrderHash("");
    setFilterStatus("All");
    setSearchString("");
    setActivePageOrders(0);
    setActivePageOrdersCoins(0);
    setActivePageWithdrawals(0);
    setActivePageAifory(0);
    setActivePageMoneygo(0);
    setActivePageStreampay(0);
    setActivePageKaptahpay(0);
    setKey(PaymentTabs.deposit);
  };
  const handleSearch = (): void => {
    const searchValue = `${
      startDate ? `&start_date=${formatDateSecondsApi(startDate)}` : ""
    }${endDate ? `&end_date=${formatDateSecondsApi(endDate)}` : ""}${
      userId ? `&user_id=${userId}` : ""
    }${
      filterStatus && filterStatus !== "All" ? `&status=${filterStatus}` : ""
    }${orderHash ? `&internal_order_id=${orderHash}` : ""}`;
    setSearchString(searchValue);
    if (key === PaymentTabs.deposit) {
      setActivePageOrders(0);
      loadOrders(1, searchValue);
      setActivePageWithdrawals(0);
      setWithdrawals(undefined);
      setActivePageOrdersCoins(0);
      setOrdersCoins(undefined);
      setActivePageAifory(0);
      setAifory(undefined);
      setActivePageMoneygo(0);
      setMoneygo(undefined);
      setActivePageStreampay(0);
      setStreampay(undefined);
      setActivePageKaptahpay(0);
      setKaptahpay(undefined);
    }
    if (key === PaymentTabs.depositCoins) {
      setActivePageOrdersCoins(0);
      loadOrdersCoins(1, searchValue);
      setActivePageWithdrawals(0);
      setWithdrawals(undefined);
      setActivePageOrders(0);
      setOrders(undefined);
      setActivePageAifory(0);
      setAifory(undefined);
      setActivePageMoneygo(0);
      setMoneygo(undefined);
      setActivePageStreampay(0);
      setStreampay(undefined);
      setActivePageKaptahpay(0);
      setKaptahpay(undefined);
    }
    if (key === PaymentTabs.withdrawal) {
      setActivePageWithdrawals(0);
      loadWithdrawal(1, searchValue);
      setActivePageOrders(0);
      setOrders(undefined);
      setActivePageOrdersCoins(0);
      setOrdersCoins(undefined);
      setActivePageAifory(0);
      setAifory(undefined);
      setActivePageMoneygo(0);
      setMoneygo(undefined);
      setActivePageStreampay(0);
      setStreampay(undefined);
      setActivePageKaptahpay(0);
      setKaptahpay(undefined);
    }
    if (key === PaymentTabs.aifory) {
      setActivePageAifory(0);
      loadAifory(1, searchValue);
      setActivePageOrders(0);
      setOrders(undefined);
      setActivePageOrdersCoins(0);
      setOrdersCoins(undefined);
      setActivePageWithdrawals(0);
      setWithdrawals(undefined);
      setActivePageMoneygo(0);
      setMoneygo(undefined);
      setActivePageStreampay(0);
      setStreampay(undefined);
      setActivePageKaptahpay(0);
      setKaptahpay(undefined);
    }
    if (key === PaymentTabs.moneygo) {
      setActivePageMoneygo(0);
      loadMoneygo(1, searchValue);
      setActivePageOrders(0);
      setOrders(undefined);
      setActivePageOrdersCoins(0);
      setOrdersCoins(undefined);
      setActivePageWithdrawals(0);
      setWithdrawals(undefined);
      setActivePageAifory(0);
      setAifory(undefined);
      setActivePageStreampay(0);
      setStreampay(undefined);
      setActivePageKaptahpay(0);
      setKaptahpay(undefined);
    }
    if (key === PaymentTabs.streampay) {
      setActivePageStreampay(0);
      loadStreampay(1, searchValue);
      setActivePageOrders(0);
      setOrders(undefined);
      setActivePageOrdersCoins(0);
      setOrdersCoins(undefined);
      setActivePageWithdrawals(0);
      setWithdrawals(undefined);
      setActivePageAifory(0);
      setAifory(undefined);
      setActivePageMoneygo(0);
      setMoneygo(undefined);
      setActivePageKaptahpay(0);
      setKaptahpay(undefined);
    }
    if (key === PaymentTabs.kaptahpay) {
      setActivePageKaptahpay(0);
      loadKaptahpay(1, searchValue);
      setActivePageOrders(0);
      setOrders(undefined);
      setActivePageOrdersCoins(0);
      setOrdersCoins(undefined);
      setActivePageWithdrawals(0);
      setWithdrawals(undefined);
      setActivePageAifory(0);
      setAifory(undefined);
      setActivePageMoneygo(0);
      setMoneygo(undefined);
      setActivePageStreampay(0);
      setStreampay(undefined);
    }
  };

  return (
    <div className="admin-payments-warp admin-tab-warp">
      <h3>{t("Payments")}</h3>
      <div className="admin-payment-wrapper">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h4>{t("Filters")}</h4>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Start date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setStartDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={startDate}
                      dropdownMode="select"
                      placeholderText={t("Start date") || "Start date"}
                      className="form-control mb-2"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("End date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setEndDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={endDate}
                      dropdownMode="select"
                      placeholderText={t("End date") || "End date"}
                      className="form-control mb-2"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("User")} ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`${t("User") || "User"} ID`}
                      value={userId}
                      onChange={(e): void => {
                        setUserId(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Status")}</Form.Label>
                    <Form.Select
                      aria-label={t("Status") || "Status"}
                      value={filterStatus}
                      onChange={(e): void => {
                        setFilterStatus(e.target.value as string);
                      }}
                    >
                      <option value="All">{t("All")}</option>
                      <option value="success">Success</option>
                      <option value="processing">Processing</option>
                      <option value="pending">Pending</option>
                      <option value="cancel">Cancel</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Order hash")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Order hash") || "Order hash"}
                      value={orderHash}
                      onChange={(e): void => {
                        setOrderHash(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <div className="admin-search-btns mb-3">
                    {searchString && (
                      <Button
                        variant="danger"
                        onClick={handleClearSearch}
                        disabled={isOrdersFetching || isWithdrawalsFetching}
                      >
                        {t("Clear")}
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isOrdersFetching || isWithdrawalsFetching}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Tabs
          activeKey={key}
          onSelect={(k): void => setKey(k as PaymentTabs)}
          className="mb-3"
        >
          <Tab eventKey={PaymentTabs.deposit} title={t("Deposit")}>
            {isOrdersFetching && (
              <CirclesWithBar
                height="200"
                width="200"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {!isOrdersFetching && (
              <Table striped bordered className="mb-5" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Date")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Card number")}</th>
                    <th>{t("Order hash")}</th>
                    <th>{t("Currency")}</th>
                    <th>IP</th>
                    <th>{t("User")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {orders && (
                  <tbody>
                    {orders.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{formatDateMinutes(el.created_at as string)}</td>
                        <td>{el.order_amount}</td>
                        <td>{el.payment_system}</td>
                        <td>{el.payment_card || ""}</td>
                        <td>{el.order_id || ""}</td>
                        <td>{el.currency.code}</td>
                        <td>{el.ip || ""}</td>
                        <td className="doc-user-cell">
                          <div>
                            <b>ID:</b> {el.user?.id}
                            <br />
                            <b>{t("Name")}:</b> {el.user?.first_name}{" "}
                            {el.user?.last_name}
                            <br />
                            <b>{t("Email address")}:</b> {el.user?.email}
                            <br />
                            <b>{t("Blocked")}:</b>{" "}
                            {el.user?.is_blocked === 1 ? "+" : "-"}
                            <br />
                            <b>{t("Deposit blocked")}:</b>{" "}
                            {el.user?.is_deposit_blocked === "1" ? "+" : "-"}
                            <br />
                          </div>
                        </td>
                        <td>
                          <Form.Select
                            value={el.status}
                            disabled={
                              userRole !== UserRoles.admin &&
                              userRole !== UserRoles["payment-manager"]
                            }
                            onChange={(e): void => {
                              handleOrderStatus(
                                el.id as number,
                                e.target.value as string,
                                el.payment_system as string
                              );
                            }}
                          >
                            {depositStatuses?.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            )}
            {totalPagesOrders > 1 && (
              <div className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  pageRangeDisplayed={5}
                  pageCount={totalPagesOrders}
                  previousLabel="<"
                  forcePage={activePageOrders}
                  onPageChange={(e): void => {
                    handlePaginationChangeOrders(e.selected);
                  }}
                />
              </div>
            )}
          </Tab>
          <Tab
            eventKey={PaymentTabs.depositCoins}
            title={`${t("Deposit")} (CoinsPaid)`}
          >
            {isOrdersCoinsFetching && (
              <CirclesWithBar
                height="200"
                width="200"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {!isOrdersCoinsFetching && (
              <Table striped bordered className="mb-5" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Date")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Card number")}</th>
                    <th>{t("Order hash")}</th>
                    <th>{t("Currency")}</th>
                    <th>IP</th>
                    <th>{t("User")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {ordersCoins && (
                  <tbody>
                    {ordersCoins.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{formatDateMinutes(el.created_at as string)}</td>
                        <td>{el.converted_full_amount}</td>
                        <td>{el.payment_system}</td>
                        <td>{el.payment_card || ""}</td>
                        <td>{el.order_id || ""}</td>
                        <td>{el.currency_final?.code}</td>
                        <td>{el.ip || ""}</td>
                        <td className="doc-user-cell">
                          <div>
                            <b>ID:</b> {el.user?.id}
                            <br />
                            <b>{t("Name")}:</b> {el.user?.first_name}{" "}
                            {el.user?.last_name}
                            <br />
                            <b>{t("Email address")}:</b> {el.user?.email}
                            <br />
                            <b>{t("Blocked")}:</b>{" "}
                            {el.user?.is_blocked === 1 ? "+" : "-"}
                            <br />
                            <b>{t("Deposit blocked")}:</b>{" "}
                            {el.user?.is_deposit_blocked === "1" ? "+" : "-"}
                            <br />
                          </div>
                        </td>
                        <td>
                          <Form.Select
                            value={el.status}
                            disabled={
                              userRole !== UserRoles.admin &&
                              userRole !== UserRoles["payment-manager"]
                            }
                            onChange={(e): void => {
                              handleOrderStatus(
                                el.id as number,
                                e.target.value as string,
                                el.payment_system as string
                              );
                            }}
                          >
                            {depositStatuses?.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            )}
            {totalPagesOrders > 1 && (
              <div className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  pageRangeDisplayed={5}
                  pageCount={totalPagesOrdersCoins}
                  previousLabel="<"
                  forcePage={activePageOrdersCoins}
                  onPageChange={(e): void => {
                    handlePaginationChangeOrdersCoins(e.selected);
                  }}
                />
              </div>
            )}
          </Tab>
          <Tab eventKey={PaymentTabs.withdrawal} title={t("Withdrawal")}>
            {isWithdrawalsFetching && (
              <CirclesWithBar
                height="200"
                width="200"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {!isWithdrawalsFetching && (
              <Table striped bordered className="mb-5" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Date")}</th>
                    <th>{t("User")}</th>
                    {/* <th>{t("Blocked")}</th> */}
                    <th>{t("Withdrawal errors")}</th>
                    <th>{t("Payment card")}</th>
                    <th>{t("Current balance")}</th>
                    <th>{t("Withdrawal amount")}</th>
                    <th>{t("Currency")}</th>
                    <th>{t("Comment")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {withdrawals && (
                  <tbody>
                    {withdrawals.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{formatDateMinutes(el.created_at as string)}</td>
                        <td className="doc-user-cell">
                          <div>
                            <b>{t("Name")}:</b> {el.user?.first_name}{" "}
                            {el.user?.last_name}
                            <br />
                            <b>{t("Email address")}:</b> {el.user?.email}
                            <br />
                            <b>{t("Phone")}:</b> {el.user?.phone}
                            <br />
                            <b>{t("Country")}:</b> {el.user?.country.name}
                            <br />
                            <b>{t("City")}:</b> {el.user?.city}
                            <br />
                            <b>{t("Gender")}:</b> {el.user?.gender}
                            <br />
                          </div>
                        </td>
                        {/* <td>{el.user?.is_deposit_blocked === "1" ? "+" : "-"}</td> */}
                        <td>
                          {el.user?.balance.amount < el.amount && (
                            <span>
                              {t(
                                "The user's balance is less than the withdrawal amount."
                              )}
                            </span>
                          )}
                        </td>
                        <td className="doc-user-cell">
                          <div>
                            <b>{t("Card address")}:</b> {el.address}
                            <br />
                            <b>{t("Card holder")}:</b> {el.card_holder}
                            <br />
                            <b>{t("Payment system")}:</b> {el.payment_system}
                          </div>
                        </td>
                        <td>{el.user.balance.amount}</td>
                        <td>{el.amount}</td>
                        <td>{el.currency.code}</td>
                        <td>{el.comment}</td>
                        <td>
                          <Form.Select
                            value={el.status}
                            disabled={
                              userRole !== UserRoles.admin &&
                              userRole !== UserRoles["payment-manager"]
                            }
                            onChange={(e): void => {
                              if (e.target.value === "3") {
                                e.preventDefault();
                                setModalShow(true);
                                setActiveItem(el);
                              } else {
                                handleWithdrawalStatus(
                                  el.id as number,
                                  e.target.value as string,
                                  el.payment_system as string
                                );
                              }
                            }}
                          >
                            {withdrawalStatuses?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {t(item.name)}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            )}
            {totalPagesWithdrawals > 1 && (
              <div className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  pageRangeDisplayed={5}
                  pageCount={totalPagesWithdrawals}
                  previousLabel="<"
                  forcePage={activePageWithdrawals}
                  onPageChange={(e): void => {
                    handlePaginationChangeWithdrawals(e.selected);
                  }}
                />
              </div>
            )}
          </Tab>
          <Tab eventKey={PaymentTabs.aifory} title="Депозит СПБ(Aifory)">
            {isAiforyFetching && (
              <CirclesWithBar
                height="200"
                width="200"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {!isAiforyFetching && (
              <Table striped bordered className="mb-5" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Date")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Card number")}</th>
                    <th>{t("Order hash")}</th>
                    <th>{t("Currency")}</th>
                    <th>IP</th>
                    <th>{t("User")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {aifory && (
                  <tbody>
                    {aifory.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{formatDateMinutes(el.created_at as string)}</td>
                        <td>{el.order_amount}</td>
                        <td>{el.payment_system}</td>
                        <td>{el.payment_card || ""}</td>
                        <td>{el.order_id || ""}</td>
                        <td>{el.currency.code}</td>
                        <td>{el.ip || ""}</td>
                        <td className="doc-user-cell">
                          <div>
                            <b>ID:</b> {el.user?.id}
                            <br />
                            <b>{t("Name")}:</b> {el.user?.first_name}{" "}
                            {el.user?.last_name}
                            <br />
                            <b>{t("Email address")}:</b> {el.user?.email}
                            <br />
                            <b>{t("Blocked")}:</b>{" "}
                            {el.user?.is_blocked === 1 ? "+" : "-"}
                            <br />
                            <b>{t("Deposit blocked")}:</b>{" "}
                            {el.user?.is_deposit_blocked === "1" ? "+" : "-"}
                            <br />
                          </div>
                        </td>
                        <td>
                          <Form.Select
                            value={el.status}
                            disabled={
                              userRole !== UserRoles.admin &&
                              userRole !== UserRoles["payment-manager"]
                            }
                            onChange={(e): void => {
                              handleOrderStatus(
                                el.id as number,
                                e.target.value as string,
                                el.payment_system as string
                              );
                            }}
                          >
                            {depositStatuses?.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            )}
            {totalPagesAifory > 1 && (
              <div className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  pageRangeDisplayed={5}
                  pageCount={totalPagesAifory}
                  previousLabel="<"
                  forcePage={activePageAifory}
                  onPageChange={(e): void => {
                    handlePaginationChangeAifory(e.selected);
                  }}
                />
              </div>
            )}
          </Tab>
          <Tab eventKey={PaymentTabs.moneygo} title="Депозит MoneyGo">
            {isMoneygoFetching && (
              <CirclesWithBar
                height="200"
                width="200"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {!isMoneygoFetching && (
              <Table striped bordered className="mb-5" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Date")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Card number")}</th>
                    <th>{t("Order hash")}</th>
                    <th>{t("Currency")}</th>
                    <th>IP</th>
                    <th>{t("User")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {moneygo && (
                  <tbody>
                    {moneygo.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{formatDateMinutes(el.created_at as string)}</td>
                        <td>{el.order_amount}</td>
                        <td>{el.payment_system}</td>
                        <td>{el.payment_card || ""}</td>
                        <td>{el.order_id || ""}</td>
                        <td>{el.to_currency.code}</td>
                        <td>{el.ip || ""}</td>
                        <td className="doc-user-cell">
                          <div>
                            <b>ID:</b> {el.user?.id}
                            <br />
                            <b>{t("Name")}:</b> {el.user?.first_name}{" "}
                            {el.user?.last_name}
                            <br />
                            <b>{t("Email address")}:</b> {el.user?.email}
                            <br />
                            <b>{t("Blocked")}:</b>{" "}
                            {el.user?.is_blocked === 1 ? "+" : "-"}
                            <br />
                            <b>{t("Deposit blocked")}:</b>{" "}
                            {el.user?.is_deposit_blocked === "1" ? "+" : "-"}
                            <br />
                          </div>
                        </td>
                        <td>
                          <Form.Select
                            value={el.status}
                            disabled={
                              userRole !== UserRoles.admin &&
                              userRole !== UserRoles["payment-manager"]
                            }
                            onChange={(e): void => {
                              handleOrderStatus(
                                el.id as number,
                                e.target.value as string,
                                el.payment_system as string
                              );
                            }}
                          >
                            {depositStatuses?.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            )}
            {totalPagesMoneygo > 1 && (
              <div className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  pageRangeDisplayed={5}
                  pageCount={totalPagesMoneygo}
                  previousLabel="<"
                  forcePage={activePageMoneygo}
                  onPageChange={(e): void => {
                    handlePaginationChangeMoneygo(e.selected);
                  }}
                />
              </div>
            )}
          </Tab>
          <Tab eventKey={PaymentTabs.streampay} title="Депозит StreamPay">
            {isStreampayFetching && (
              <CirclesWithBar
                height="200"
                width="200"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {!isStreampayFetching && (
              <Table striped bordered className="mb-5" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Date")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Card number")}</th>
                    <th>{t("Order hash")}</th>
                    <th>{t("Currency")}</th>
                    <th>IP</th>
                    <th>{t("User")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {streampay && (
                  <tbody>
                    {streampay.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{formatDateMinutes(el.created_at as string)}</td>
                        <td>{el.order_amount}</td>
                        <td>{el.payment_system}</td>
                        <td>{el.payment_card || ""}</td>
                        <td>{el.order_id || ""}</td>
                        <td>{el.to_currency.code}</td>
                        <td>{el.ip || ""}</td>
                        <td className="doc-user-cell">
                          <div>
                            <b>ID:</b> {el.user?.id}
                            <br />
                            <b>{t("Name")}:</b> {el.user?.first_name}{" "}
                            {el.user?.last_name}
                            <br />
                            <b>{t("Email address")}:</b> {el.user?.email}
                            <br />
                            <b>{t("Blocked")}:</b>{" "}
                            {el.user?.is_blocked === 1 ? "+" : "-"}
                            <br />
                            <b>{t("Deposit blocked")}:</b>{" "}
                            {el.user?.is_deposit_blocked === "1" ? "+" : "-"}
                            <br />
                          </div>
                        </td>
                        <td>
                          <Form.Select
                            value={el.status}
                            disabled={
                              userRole !== UserRoles.admin &&
                              userRole !== UserRoles["payment-manager"]
                            }
                            onChange={(e): void => {
                              handleOrderStatus(
                                el.id as number,
                                e.target.value as string,
                                el.payment_system as string
                              );
                            }}
                          >
                            {depositStatuses?.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            )}
            {totalPagesStreampay > 1 && (
              <div className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  pageRangeDisplayed={5}
                  pageCount={totalPagesStreampay}
                  previousLabel="<"
                  forcePage={activePageStreampay}
                  onPageChange={(e): void => {
                    handlePaginationChangeStreampay(e.selected);
                  }}
                />
              </div>
            )}
          </Tab>
          <Tab eventKey={PaymentTabs.kaptahpay} title="Депозит KaptahPay">
            {isKaptahpayFetching && (
              <CirclesWithBar
                height="200"
                width="200"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {!isKaptahpayFetching && (
              <Table striped bordered className="mb-5" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Date")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Card number")}</th>
                    <th>{t("Order hash")}</th>
                    <th>{t("Currency")}</th>
                    <th>IP</th>
                    <th>{t("User")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                {kaptahpay && (
                  <tbody>
                    {kaptahpay.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{formatDateMinutes(el.created_at as string)}</td>
                        <td>{el.order_amount}</td>
                        <td>{el.payment_system}</td>
                        <td>{el.payment_card || ""}</td>
                        <td>{el.order_id || ""}</td>
                        <td>{el.to_currency.code}</td>
                        <td>{el.ip || ""}</td>
                        <td className="doc-user-cell">
                          <div>
                            <b>ID:</b> {el.user?.id}
                            <br />
                            <b>{t("Name")}:</b> {el.user?.first_name}{" "}
                            {el.user?.last_name}
                            <br />
                            <b>{t("Email address")}:</b> {el.user?.email}
                            <br />
                            <b>{t("Blocked")}:</b>{" "}
                            {el.user?.is_blocked === 1 ? "+" : "-"}
                            <br />
                            <b>{t("Deposit blocked")}:</b>{" "}
                            {el.user?.is_deposit_blocked === "1" ? "+" : "-"}
                            <br />
                          </div>
                        </td>
                        <td>
                          <Form.Select
                            value={el.status}
                            disabled={
                              userRole !== UserRoles.admin &&
                              userRole !== UserRoles["payment-manager"]
                            }
                            onChange={(e): void => {
                              handleOrderStatus(
                                el.id as number,
                                e.target.value as string,
                                el.payment_system as string
                              );
                            }}
                          >
                            {kaptahDepositStatuses?.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            )}
            {totalPagesKaptahpay > 1 && (
              <div className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  pageRangeDisplayed={5}
                  pageCount={totalPagesKaptahpay}
                  previousLabel="<"
                  forcePage={activePageKaptahpay}
                  onPageChange={(e): void => {
                    handlePaginationChangeKaptahpay(e.selected);
                  }}
                />
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
      {isModalShow && (
        <PayoutCommentModal
          id={activeItem?.id || 0}
          payment_system={activeItem?.payment_system || ""}
          show={isModalShow}
          handleClose={(e?: boolean): void => {
            setModalShow(false);
            setActiveItem(undefined);
            if (e) {
              loadWithdrawal(activePageWithdrawals + 1, searchString);
            }
          }}
        />
      )}
    </div>
  );
};

export default PaymentsTab;
