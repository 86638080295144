import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

import { login, registration, getUserData } from "../services/apiLogin";
import {
  setUserToken,
  setUserData,
  loggedAction,
  setActiveLocal,
} from "../store/slices/userData";
import type { RootState } from "../store/store";
import { BonusItem } from "../types/bonusesTypes";
import { getWelcomeBonus } from "../services/apiBonuses";
import Modal from "../components/UI/Modal/Modal";
import WelcomeBonusModal from "../components/modals/WelcomeBonusModal/WelcomeBonusModal";
import apiGeoInfo from "../services/apiGeoInfo";

import PhoneInputWrapper from "../components/forms/PhoneInputWrapper";

import logoImg from "../images/logo.svg";
// import twitchIcon from "../images/social-icons/twitch-new2.svg";
// import vkIcon from "../images/social-icons/vk-new2.svg";
// import fbIcon from "../images/social-icons/fb-new2.svg";
// import googleIcon from "../images/social-icons/google-new2.svg";
import checkIcon from "../images/check-icon-new.svg";
import arrowIcon from "../images/new-arrow.svg";
import TextFieldPassword from "../components/UI/TextFieldPassword/TextFieldPassword";

enum Tabs {
  mail = "mail",
  phone = "phone",
  social = "social",
}

interface Props {
  withBonus?: boolean;
}

const LoginPage: React.FC<Props> = ({ withBonus }) => {
  // Conditions
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.mail);
  const [isPartner, setPartner] = useState<boolean>(false);
  const [isLoginStage, setLoginStage] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isShowWelcomeBonusModal, setIsShowWelcomeBonusModal] =
    useState<boolean>(false);

  // Values
  const [bonusInfo, setBonusInfo] = useState<BonusItem>();
  const [defCountry, setDefCountry] = useState<string>("ru");
  const [mailValue, setMailValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [promoValue, setPromoValue] = useState<string>("");
  const [termsAgreed, setTermsAfreed] = useState<boolean>(true);
  const [pushAgreed, setPushAgreed] = useState<boolean>(false);
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  // Errors
  const [mailError, setMailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [globalError, setGlobalError] = useState<string>("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { search } = window.location;
  const queryParams = queryString.parse(search);

  //   Initial logic
  const loadBonus = async (): Promise<void> => {
    const result = await getWelcomeBonus();
    if (typeof result !== "string") {
      setBonusInfo(
        result.bonuses.length > 0 ? (result.bonuses[0] as BonusItem) : undefined
      );
    }
  };
  const getGeoInfo = async (): Promise<void> => {
    const result = await apiGeoInfo();
    setDefCountry(result.country_code.toLowerCase() as string);
  };
  useEffect(() => {
    loadBonus();
    getGeoInfo();
    if (window.location.search.includes("clickid")) {
      setPartner(true);
      setLoginStage(false);
    }
    if (location?.pathname === "/login") {
      setLoginStage(true);
    } else {
      setLoginStage(false);
    }
    if (isLogged && location?.pathname === "/login") {
      navigate("/");
    }
    if (isLogged && location?.pathname === "/register") {
      setIsShowWelcomeBonusModal(true);
    }
  }, [location, isLogged, navigate]);

  //   Toggle conditions logic
  const toggleTab = (tab: Tabs): void => {
    setActiveTab(tab);
    setGlobalError("");
  };
  const toggleLoginStage = (): void => {
    if (isLoginStage) {
      navigate("/register");
    } else {
      navigate("/login");
    }
  };
  //   Validation logic
  const isFormValid = (): boolean => {
    if (activeTab === Tabs.mail && !mailValue) {
      setMailError("This field is required");
      return false;
    }
    if (
      activeTab === Tabs.mail &&
      mailValue &&
      !mailValue.toLowerCase().match(
        // eslint-disable-next-line
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      )
    ) {
      setMailError("Invalid email");
      return false;
    }
    if (activeTab === Tabs.phone && (!phoneValue || phoneValue.length < 10)) {
      setPhoneError("This field is required");
      return false;
    }
    if (!passwordValue && activeTab === Tabs.mail) {
      setPasswordError("This field is required");
      return false;
    }
    if (passwordValue.length < 6 && activeTab === Tabs.mail) {
      setPasswordError("Password must be at least 6 characters long");
      return false;
    }
    if (!termsAgreed && !isLoginStage) {
      setGlobalError("You should accept Terms and Public offer");
      return false;
    }
    return true;
  };

  //   Form submit logic
  const handleLogin = async (): Promise<void> => {
    const result = await getUserData();
    if (typeof result === "string") {
      setGlobalError(result as string);
      return;
    }
    if (result.user.locale) {
      dispatch(setActiveLocal(result.user.locale));
      i18n.changeLanguage(result.user.locale.code as string);
      localStorage.setItem("localeLang", result.user.locale.code);
    }
    dispatch(setUserData(result.user));
    dispatch(loggedAction(true));
    if (isPartner) {
      window.location.replace("/");
    }
    setIsShowWelcomeBonusModal(true);
  };
  const handleSubmit = async (): Promise<void> => {
    const isValid = isFormValid();
    // Login logic
    if (isValid && isLoginStage) {
      setFetching(true);
      const result = await login(
        activeTab === Tabs.mail
          ? { email: mailValue, password: passwordValue }
          : { phone: phoneValue, password: passwordValue }
      );
      if (typeof result === "string") {
        setGlobalError(result as string);
        setFetching(false);
        return;
      }
      dispatch(setUserToken(result.access_token as string));
      await handleLogin();
      setFetching(false);
    }
    // Registratiom logic
    if (isValid && !isLoginStage) {
      setFetching(true);
      const localLang = localStorage.getItem("localeLang");
      const result = await registration(
        activeTab === Tabs.mail
          ? {
              email: mailValue,
              password: passwordValue,
              locale: localLang || "ru",
              promocode: promoValue || undefined,
              ...queryParams,
            }
          : {
              phone: phoneValue,
              password: passwordValue,
              locale: localLang || "ru",
              promocode: promoValue || undefined,
              ...queryParams,
            },
        termsAgreed
      );
      if (typeof result === "string") {
        setGlobalError(result as string);
        setFetching(false);
        return;
      }
      dispatch(setUserToken(result.token as string));
      await handleLogin();
      setFetching(false);
    }
  };

  return (
    <div className={`login-page ${activeTab}`}>
      <div
        className={
          localeActive?.code === "fa"
            ? "login-page-container rtl"
            : "login-page-container"
        }
      >
        <div className="login-header">
          <div
            className="back-btn"
            onClick={(): void => {
              if (location.key === "default") {
                navigate("/");
              } else {
                navigate(-1);
              }
            }}
          >
            <img src={arrowIcon} alt="" />
          </div>
        </div>
        <img src={logoImg} alt="logo" className="logo-img" />
        {withBonus && bonusInfo && (
          <div className="bonus-info">
            {t("TO THE FIRST DEPOSIT")}
            {bonusInfo.campaigns.map((el) =>
              el.settings.max_deposit_amount ? (
                <span key={el.id}>{`${
                  el.settings.percentages_of_deposit as number
                }%`}</span>
              ) : (
                ""
              )
            )}
            <br />
            {bonusInfo.campaigns.map((el) =>
              el.freespins ? (
                <Fragment key={el.id}>
                  {t("and")}
                  <span>
                    {`${el.freespins[0].freespins_quantity as number}fs`}{" "}
                  </span>
                  {t("NEW PLAYERS")}
                </Fragment>
              ) : (
                ""
              )
            )}
          </div>
        )}
        <div className="login-tabs">
          <div
            className={activeTab === Tabs.mail ? "tab-item active" : "tab-item"}
            onClick={(): void => {
              toggleTab(Tabs.mail);
            }}
          >
            {t("Email address")}
          </div>
          <div
            className={
              activeTab === Tabs.phone ? "tab-item active" : "tab-item"
            }
            onClick={(): void => {
              toggleTab(Tabs.phone);
            }}
          >
            {t("Phone")}
          </div>
          {/* {!isLoginStage && (
            <div
              className={
                activeTab === Tabs.social ? "tab-item active" : "tab-item"
              }
              onClick={(): void => {
                toggleTab(Tabs.social);
              }}
            >
              {t("Social networks")}
            </div>
          )} */}
        </div>
        <div
          className={
            isLoginStage
              ? "login-content-wrap login-stage"
              : "login-content-wrap"
          }
        >
          {activeTab === Tabs.mail && (
            <>
              <div
                className="login-input-wrap"
                style={{ marginBottom: "15px" }}
              >
                <div className="label">{t("Enter your email address")}</div>
                <input
                  type="email"
                  className={mailError ? "main-input error" : "main-input"}
                  placeholder={t("Email address") || "Email address"}
                  value={mailValue}
                  onChange={(e): void => {
                    setMailValue(e.target.value);
                    setMailError("");
                    setGlobalError("");
                  }}
                />
                {mailError && <span className="error-msg">{t(mailError)}</span>}
              </div>
              <div
                className="login-input-wrap"
                style={{ marginBottom: "15px" }}
              >
                <div className="label">{t("Enter password")}</div>
                <TextFieldPassword
                  value={passwordValue}
                  onChange={(value: string): void => {
                    setPasswordValue(value);
                    setPasswordError("");
                    setGlobalError("");
                  }}
                  placeholder="Password"
                />
                {passwordError && (
                  <span className="error-msg">{t(passwordError)}</span>
                )}
              </div>
              {globalError && <span className="error-msg">{globalError}</span>}
              {isLoginStage && (
                <div
                  className="login-link forgot-link"
                  onClick={(): void => {
                    navigate("/reset-password");
                  }}
                >
                  {t("Forgot your password?")}
                </div>
              )}
              {!isLoginStage && (
                <div className="login-footer">
                  <div className="login-check-item">
                    <label className="default-checkbox" htmlFor="terms">
                      <input
                        type="checkbox"
                        id="terms"
                        checked={termsAgreed}
                        onChange={(): void => {
                          setTermsAfreed(!termsAgreed);
                          setGlobalError("");
                        }}
                      />
                      <img src={checkIcon} alt="" />
                    </label>
                    <div className="login-check-item--text">
                      {t(
                        "I confirm that I am at least 21 years of age, legally capable, am not a gambling addict and that I agree to the"
                      )}
                      <NavLink to="/privacy" className="modal-link">
                        {t("Terms")}
                      </NavLink>
                      {t(
                        "of the site. I am aware of the fact that users who do not fit the aforementioned criteria are forbidden from gambling."
                      )}
                    </div>
                  </div>
                  <div className="login-check-item">
                    <label className="default-checkbox" htmlFor="push">
                      <input
                        type="checkbox"
                        id="push"
                        checked={pushAgreed}
                        onChange={(): void => {
                          setPushAgreed(!pushAgreed);
                          setGlobalError("");
                        }}
                      />
                      <img src={checkIcon} alt="" />
                    </label>
                    <div className="login-check-item--text">
                      {t(
                        "I want to receive promotional messages, casino news, bonuses and exclusive offers."
                      )}
                    </div>
                  </div>
                </div>
              )}
              <button
                className="main-btn"
                type="button"
                disabled={isFetching}
                onClick={(): void => {
                  handleSubmit();
                }}
              >
                {isLoginStage ? t("Log in") : t("Sign Up")}
              </button>
            </>
          )}
          {activeTab === Tabs.phone && (
            <>
              <div className="login-input-wrap">
                <div className="label">{t("Enter your phone number")}</div>
                <PhoneInputWrapper
                  containerClass={
                    phoneError ? "phone-input error" : "phone-input"
                  }
                  country={defCountry}
                  value={phoneValue}
                  // countryCodeEditable={false}
                  onChange={(e): void => {
                    setPhoneValue(e);
                    setPhoneError("");
                    setGlobalError("");
                  }}
                />
                {phoneError && (
                  <span className="error-msg">{t(phoneError)}</span>
                )}
              </div>
              <div
                className="login-input-wrap"
                style={{ marginBottom: "15px" }}
              >
                <div className="label">{t("Enter password")}</div>
                <TextFieldPassword
                  value={passwordValue}
                  onChange={(value: string): void => {
                    setPasswordValue(value);
                    setPasswordError("");
                    setGlobalError("");
                  }}
                  placeholder="Password"
                />
                {passwordError && (
                  <span className="error-msg">{t(passwordError)}</span>
                )}
              </div>
              {globalError && <span className="error-msg">{globalError}</span>}
              {isLoginStage && (
                <div
                  className="login-link forgot-link"
                  onClick={(): void => {
                    navigate("/reset-password");
                  }}
                >
                  {t("Forgot your password?")}
                </div>
              )}
              {!isLoginStage && (
                <div className="login-footer">
                  <div className="login-check-item">
                    <label className="default-checkbox" htmlFor="terms">
                      <input
                        type="checkbox"
                        id="terms"
                        checked={termsAgreed}
                        onChange={(): void => {
                          setTermsAfreed(!termsAgreed);
                          setGlobalError("");
                        }}
                      />
                      <img src={checkIcon} alt="" />
                    </label>
                    <div className="login-check-item--text">
                      {t(
                        "I confirm that I am at least 21 years of age, legally capable, am not a gambling addict and that I agree to the"
                      )}
                      <NavLink to="/privacy" className="modal-link">
                        {t("Terms")}
                      </NavLink>
                      {t(
                        "of the site. I am aware of the fact that users who do not fit the aforementioned criteria are forbidden from gambling."
                      )}
                    </div>
                  </div>
                  <div className="login-check-item">
                    <label className="default-checkbox" htmlFor="push">
                      <input
                        type="checkbox"
                        id="push"
                        checked={pushAgreed}
                        onChange={(): void => {
                          setPushAgreed(!pushAgreed);
                          setGlobalError("");
                        }}
                      />
                      <img src={checkIcon} alt="" />
                    </label>
                    <div className="login-check-item--text">
                      {t(
                        "I want to receive promotional messages, casino news, bonuses and exclusive offers."
                      )}
                    </div>
                  </div>
                </div>
              )}
              <button
                className="main-btn"
                type="button"
                disabled={isFetching}
                onClick={(): void => {
                  handleSubmit();
                }}
              >
                {isLoginStage ? t("Log in") : t("Sign Up")}
              </button>
            </>
          )}
          <div className="login-link change-state-link">
            {isLoginStage
              ? t("Don't have an account?")
              : t("Already have an account?")}
            <span onClick={toggleLoginStage}>{` ${
              isLoginStage ? t("Sign up here") : t("Log in")
            }`}</span>
          </div>
          {!isLoginStage && (
            <input
              type="text"
              className="main-input promo-input"
              placeholder={t("Have a promo code?") || "Have a promo code?"}
              value={promoValue}
              onChange={(e): void => {
                setPromoValue(e.target.value);
              }}
            />
          )}
        </div>
        {/* <div className="login-social login-social-bottom">
          <div className="txt">{t("or continue with")}</div>
          <div className="login-social-row">
            <a
              href={`${
                process.env.REACT_APP_API_URL as string
              }/social/twitch/login`}
              className="login-social-item"
            >
              <img src={twitchIcon} alt="" />
            </a>
            <a
              href={`${
                process.env.REACT_APP_API_URL as string
              }/social/vkontakte/login`}
              className="login-social-item"
            >
              <img src={vkIcon} alt="" />
            </a>
            <a
              href={`${
                process.env.REACT_APP_API_URL as string
              }/social/facebook/login`}
              className="login-social-item"
            >
              <img src={fbIcon} alt="" />
            </a>
            <a
              href={`${
                process.env.REACT_APP_API_URL as string
              }/social/google/login`}
              className="login-social-item"
            >
              <img src={googleIcon} alt="" />
            </a>
          </div>
        </div> */}
      </div>
      <Modal open={isShowWelcomeBonusModal}>
        <WelcomeBonusModal
          onClose={(): void => {
            setIsShowWelcomeBonusModal(false);
            navigate("/");
          }}
        />
      </Modal>
    </div>
  );
};

export default LoginPage;
