import ApiClient from "./ApiClient";
import {
  RequestError,
  GamesListResponse,
  GameSession,
  AllGamesListResponse,
  GategoryListResponse,
  VendorsListResponse,
  WinnersListResponse,
} from "../types/requestTypes";
import { HomeBetItem } from "../types/betsTypes";

export const startGame = async (
  game_id: number,
  user_id: number,
  client_type: string
): Promise<GameSession | string> => {
  try {
    const result = await ApiClient.post<GameSession>(
      "/games/session-start-attempt",
      {
        game_id,
        user_id,
        client_type,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getGamesList = async (): Promise<GamesListResponse | string> => {
  try {
    const result = await ApiClient.get<GamesListResponse>("/games/list-test");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getAllGamesList = async (
  search: string | null,
  category: string | null,
  producer: string | null,
  page?: number,
  perPage?: number,
  lobby?: boolean,
  isLive?: boolean
): Promise<AllGamesListResponse | string> => {
  try {
    const result = await ApiClient.get<AllGamesListResponse>(
      `/games?page=${page || 1}${category ? `&category=${category}` : ""}${
        search ? `&title=${search}` : ""
      }${producer ? `&producer=${producer}` : ""}${
        perPage ? `&per_page=${perPage}` : ""
      }${lobby ? "&lobby=1" : ""}${isLive ? "&has_live=1" : ""}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getAllGamesListTournament = async (
  search: string | null,
  category: string | null,
  producer: string | null,
  page?: number,
  perPage?: number,
  lobby?: boolean
): Promise<AllGamesListResponse | string> => {
  try {
    const result = await ApiClient.get<AllGamesListResponse>(
      `/games/tournaments/available?page=${page || 1}${
        category ? `&category=${category}` : ""
      }${search ? `&title=${search}` : ""}${
        producer ? `&producer=${producer}` : ""
      }${perPage ? `&per_page=${perPage}` : ""}${lobby ? "&lobby=1" : ""}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getGamesCategories = async (): Promise<
  GategoryListResponse | string
> => {
  try {
    const result = await ApiClient.getPublic<GategoryListResponse>(
      "games/categories"
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getGamesVendors = async (): Promise<
  VendorsListResponse | string
> => {
  try {
    const result = await ApiClient.getPublic<VendorsListResponse>(
      "games/vendors"
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getGameInfo = async (
  id: string
): Promise<{ game: { title: string; description: string } } | string> => {
  try {
    const result = await ApiClient.get<{
      game: { title: string; description: string };
    }>(`/games/${id}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getHomeBets = async (): Promise<
  { data: HomeBetItem[] } | string
> => {
  try {
    const result = await ApiClient.getPublic<{
      data: HomeBetItem[];
    }>("/games/last-wins");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getWinnersList = async (): Promise<
  WinnersListResponse | string
> => {
  try {
    const result = await ApiClient.get<WinnersListResponse>("/games/last-wins");
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
