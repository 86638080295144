/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import type { RootState } from "../../store/store";

const Privacy: React.FC = () => {
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bonus-info-page-wrap">
      <div className="content-gradient" />
      <div className="content-page-box">
        <div
          className={
            localeActive?.code === "fa" ? "container rtl" : "container"
          }
        >
          <h1>{t("Privacy policy")}</h1>
          <p>
            {t(
              "The website of this organization, hereinafter referred to as the Website or the Organization, which is engaged in online gambling, complies with the established laws and regulations in this field of activity. It also undertakes to ensure the right of users to privacy and the security of personal data. To fulfill this obligation, there is a Privacy Policy, which is based on the regulatory framework."
            )}
          </p>
          <p>
            {t(
              "By using the services of the Website, you agree to the rules and conditions described in the Privacy Policy section presented accordingly. This section is mandatory to read."
            )}
          </p>
          <ul>
            <span>
              {t(
                "The Privacy Policy terms of the Organization are based on the requirements of:"
              )}
            </span>
            <li>
              {t(
                "the General Data Protection Regulation (GDPR) of the European Union;"
              )}
            </li>
            <li>{t("the jurisdiction on 'Personal Data Protection';")}</li>
            <li>
              {t(
                "the jurisdiction on 'State regulation of activities for the organization and conduct of gambling'."
              )}
            </li>
          </ul>
          <h4>{t("Age")}</h4>
          <p>
            {t(
              "According to current legislation, the Website is not allowed to provide services and serve individuals who have not reached 21 years of age. This allows us to reserve the right to verify and process any data provided by the user in accordance with the Privacy Policy."
            )}
          </p>
          <p>
            {t(
              "In the event that during the verification or processing of entered data, users under the age of 21 are detected, appropriate measures will be taken: data deletion and blocking of the user's account."
            )}
          </p>
          <p>
            {t(
              "To maintain the principles of responsible gaming, users have the right to share information about suspicions of a violation of the above rule by other users using the Website's Customer Support Service. Accordingly, additional verification may be conducted to confirm the user's age."
            )}
          </p>
          <h4>{t("What information is collected")}</h4>
          <ul>
            <span>
              {t(
                "The website collects data for the identification of the person who registers an account, such as"
              )}
            </span>
            <li>{t("name and surname;")}</li>
            <li>{t("date of birth;")}</li>
            <li>{t("home or other physical address;")}</li>
            <li>{t("email address.")}</li>
          </ul>
          <ul>
            <span>
              {t(
                "Additional personal information is also required for the correct use of the website and the services provided:"
              )}
            </span>
            <li>{t("contact information (including phone number);")}</li>
            <li>{t("payment information;")}</li>
            <li>{t("transaction history;")}</li>
            <li>{t("preferences regarding the use of the website;")}</li>
            <li>
              {t("assessment of the performance of the services provided.")}
            </li>
          </ul>
          <p>
            {t(
              "This information is provided consciously and voluntarily by the user and is necessary for the provision and quality of services."
            )}
          </p>
          <ul>
            <span>
              {t(
                "For full interaction with all possible aspects of the website, short-term storage of user activity data is carried out, which refers to non-personalized information that cannot be used to identify the user. The activity log of each user includes the following information:"
              )}
            </span>
            <li>{t("source IP address;")}</li>
            <li>{t("device technical data;")}</li>
            <li>{t("access time and date;")}</li>
            <li>{t("visited web pages;")}</li>
            <li>{t("connection status;")}</li>
            <li>{t("gaming process history;")}</li>
            <li>{t("language usage;")}</li>
            <li>{t("software failure reports;")}</li>
            <li>{t("type of browser used.")}</li>
          </ul>
          <h4>{t("Information Security")}</h4>
          <ul>
            <span>
              {t(
                "To maintain the confidentiality of user information and prevent unauthorized disclosure of such information, the website takes appropriate measures (the list may not be exhaustive):"
              )}
            </span>
            <li>{t("strict data access control;")}</li>
            <li>
              {t(
                "signing of appropriate nondisclosure agreements with all employees and contractors who, due to their employment or contractual obligations, will have access to a certain amount of confidential information;"
              )}
            </li>
            <li>{t("audit tests;")}</li>
            <li>{t("physical information security;")}</li>
            <li>
              {t(
                "topologically protected network with intrusion detection system;"
              )}
            </li>
            <li>
              {t(
                "compliance with all jurisdictional norms on the protection of personal data."
              )}
            </li>
          </ul>
          <p>
            {t(
              "there is a possibility of unauthorized access to personal data or other information, despite compliance with security measures. In such cases, the Website is not responsible."
            )}
          </p>
          <p>
            {t(
              "Also, there can be no guarantee that such actions will necessarily be prevented. In the event of such an incident, the user should immediately contact the Support Service to report such unauthorized access."
            )}
          </p>
          <h4>{t("Storage, Processing, and Transfer of Personal Data")}</h4>
          <p>
            {t("Storage.")}
            <br />
            {t(
              "The website has the right to store and process, within three years from the termination of relations, personal information for the entire duration of the user's interaction with the website, to resolve possible disputes."
            )}
          </p>
          <p>
            {t("Processing.")}
            <br />
            {t(
              "The website processes users' personal data for their identification in accordance with legal requirements. Also, information about the user's payment methods, provided by the user, is processed for financial transactions."
            )}
          </p>
          <ul>
            <span>
              {t("Transfer.")}
              <br />
              {t(
                "The transfer of personal data to third parties is possible and is carried out only in accordance with the norms of jurisdiction on the protection of personal data. The transfer or disclosure of personal data is possible:"
              )}
            </span>
            <li>
              {t(
                "To an affiliated company or business partner of the Organization, in accordance with all security measures and under the control of both parties;"
              )}
            </li>
            <li>
              {t(
                "To third parties, including, but not limited to, for assistance in providing you with products and services that you need;"
              )}
            </li>
            <li>
              {t(
                "To government authorities, in accordance with legislative or regulatory obligations;"
              )}
            </li>
            <li>
              {t(
                "If necessary, to fulfill the conditions of these terms or other agreements;"
              )}
            </li>
            <li>
              {t(
                "In case it is discovered that you have committed fraud or attempted to deceive us or other users of the service in any way, including, but not limited to, game manipulation or payment fraud;"
              )}
            </li>
            <li>
              {t(
                "Anonymous data transfer to prevent and warn against addiction;"
              )}
            </li>
            <li>
              {t(
                "To protect the rights, loyalty, or safety of the Website, other users, and other persons."
              )}
            </li>
          </ul>
          <ul>
            <span>
              {t(
                "When transferring data to subsidiaries, suppliers, and agents in other countries, the Organization takes all measures for a secure procedure, at the same level of data transmission and processing protection required by the respective jurisdiction. These measures include, but are not limited to, the following:"
              )}
            </span>
            <li>{t("Company Binding Rules;")}</li>
            <li>{t("Standard Contractual Clauses;")}</li>
            <li>{t("U.S./EU Privacy Shield.")}</li>
          </ul>
          <h4>{t("Cookies")}</h4>
          <p>
            {t(
              "Cookie - small files stored on the user's device. Cookie technology is used to display website content correctly. These files contain information about various viewing parameters: interface language, previously viewed site pages, and more. Users can configure the storage or blocking of cookies themselves. Restricting cookie acceptance may limit site functionality."
            )}
          </p>
          <h4>{t("Advertising, Personal Offers, and Promotions")}</h4>
          <p>
            {t(
              "The website has the right to use user's personal information to provide advertising materials directly or indirectly related to the activities, products, offers, applications, and services of the organization, its subsidiaries, or business partners."
            )}
          </p>
          <ul>
            <span>
              {t(
                "Users may receive personal offers and advertisements through:"
              )}
            </span>
            <li>{t("SMS notifications;")}</li>
            <li>{t("Email newsletters;")}</li>
            <li>{t("Social media posts;")}</li>
            <li>{t("Messengers.")}</li>
          </ul>
          <p>
            {t(
              "Users have the opportunity, through the Support Service or third-party resources, to restrict or opt-out of marketing offers. However, current offers will still be presented to the user on the Website."
            )}
          </p>
          <h4>{t("User Rights")}</h4>
          <p>
            {t(
              "Users have the right to change some data in their personal information through Account settings. Other information can only be changed with the help of the Website's Support Service, after re-verification of data."
            )}
          </p>
          <p>
            {t(
              "Users can also request a copy of their personal data collected by the Website. To do this, they should contact the Support Service or email support@boomcasino.bet, where the manager will provide information about the necessary procedure: filling out a form, confirming identity, and waiting time for the request to be fulfilled."
            )}
          </p>
          <h4>{t("Changes to Privacy Terms")}</h4>
          <p>
            {t(
              "The Website reserves the right to make changes to the Privacy Terms and publish a new version on the website."
            )}
          </p>
          <p>
            {t(
              "The Website's obligation is to inform users about significant changes through various communication means available at the time of publication."
            )}
          </p>
          <p>
            {t(
              "Changes that do not affect the interests and rights of users come into force from the date of the last information update."
            )}
          </p>
          <p>
            {t(
              "In the case of global changes, the document comes into force 14 calendar days after notifying users of the publication of innovations on the website."
            )}
          </p>
          <p>
            {t(
              "By using the Website's offers and services, the user expresses their consent to the changes."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
