import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { formatDateSecondsApi } from "../../../helpers/getCorrectDate";

import {
  editCommonInfo,
  editGamesInfo,
  editPrizesInfo,
  addTournamentUsers,
  removeTournamentUsers,
} from "../../../services/admin/apiTournamentsAdmin";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";
import {
  TournamentItem,
  AddTournamentPayload,
} from "../../../types/tournamentTypes";
import { getAllGamesListTournament } from "../../../services/apiGames";
import { getUsersList } from "../../../services/admin/apiUsers";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";

import MultiSelect from "../../MultiSelect";
import TournamentPrizeItem from "../TournamentPrizeItem";

interface Props {
  show: boolean;
  tournament: TournamentItem;
  handleClose: () => void;
}

const perPageGames = 50;
const perPageUsers = 20;

const InfoTournamentModal: React.FC<Props> = ({
  show,
  tournament,
  handleClose,
}) => {
  const { t } = useTranslation();

  //   Selects currency data
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>();
  //   Selects game data
  const [games, setGames] = useState<{ name: string; id: number | string }[]>(
    []
  );
  const [selectedGames, setSelectedGames] = useState<
    { name: string; id: number | string }[]
  >([]);
  const [gameSearch, setGameSearch] = useState<string>("");
  const [gamePage, setGamePage] = useState<number>(1);
  const [hasMoreGames, setHasMoreGames] = useState<boolean>(true);
  //   Selects user data
  const [users, setUsers] = useState<{ name: string; id: number }[]>([]);
  const [selectedUsers, setSelectedUsers] =
    useState<{ name: string; id: number }[]>();
  const [usersSearch, setUsersSearch] = useState<string>("");
  const [userPage, setUserPage] = useState<number>(1);
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(true);
  //   Conditions
  const [isCommonFetching, setCommonFetching] = useState<boolean>(false);
  const [isCommonEdit, setCommonEdit] = useState<boolean>(false);
  const [isGamesFetching, setGamesFetching] = useState<boolean>(false);
  const [isGamesEdit, setGamesEdit] = useState<boolean>(false);
  const [isPrizesFetching, setPrizesFetching] = useState<boolean>(false);
  const [isPrizesEdit, setPrizesEdit] = useState<boolean>(false);
  const [isUsersFetching, setUsersFetching] = useState<boolean>(false);
  const [isUsersEdit, setUsersEdit] = useState<boolean>(false);
  //  Form values
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [rounds, setRounds] = useState<string>("");
  const [pointsCost, setPointsCost] = useState<string>("");
  const [minBet, setMinBet] = useState<string>("");
  const [dateStart, setDateStart] = useState<Date>();
  const [dateEnd, setDateEnd] = useState<Date>();
  const [imgFile, setImgFile] = useState<File>();
  const [imgUrl, setImgUrl] = useState<string>("");
  const [background, setBackground] = useState<string>("blue");
  const [prizes, setPrizes] = useState<
    { id: number; place: number; reward_amount: number | string }[]
  >([]);
  const [addUsers, setAddUsers] = useState<string[]>([]);
  const [removeUsers, setRemoveUsers] = useState<string[]>([]);
  const [allGames, setAllGames] = useState<boolean>(false);
  const [shouldRepeat, setShouldRepeat] = useState<boolean>(false);
  const [repaetPeriod, setRepaetPeriod] = useState<string>("");
  //   Errors
  const [commomError, setCommomError] = useState<string>("");
  const [gamesError, setGamesError] = useState<string>("");
  const [prizesError, setPrizesError] = useState<string>("");
  const [usersError, setUsersError] = useState<string>("");

  //   Init data
  useEffect(() => {
    const gamesInitial: { name: string; id: string | number }[] = [];
    const usersInitial: { name: string; id: number }[] = [];
    setTitle(tournament.title);
    setDescription(tournament.description || "");
    setRounds(tournament.qualification_rounds_count.toString());
    setPointsCost(tournament.cost_of_point_in_currency.toString());
    setMinBet(tournament.min_bet_amount.toString());
    setDateStart(moment(tournament.started_at).toDate());
    setDateEnd(moment(tournament.finished_at).toDate());
    setBackground(tournament.bg_color || "blue");
    if (tournament.image) {
      setImgUrl(tournament.image);
    }
    tournament.games.forEach((el) => {
      gamesInitial.push({
        name: el.title,
        id: el.id,
      });
    });
    setSelectedGames(gamesInitial);
    setPrizes(tournament.places);
    tournament.users.forEach((el) => {
      usersInitial.push({
        name: el.nick_name,
        id: el.id,
      });
    });
    setSelectedUsers(usersInitial);
    if (tournament.all_games) {
      setAllGames(true);
    }
    if (tournament?.replay_in_days && tournament?.replay_in_days > 0) {
      setShouldRepeat(true);
      setRepaetPeriod(tournament.replay_in_days?.toString());
    }
  }, [tournament]);

  //   Form logic
  const handleSubmitCommon = async (): Promise<void> => {
    setCommonFetching(true);
    const data: AddTournamentPayload = {
      img: imgFile,
      bg_color: background,
      qualification_rounds_count: parseInt(rounds, 10),
      cost_of_point_in_currency: parseFloat(pointsCost),
      min_bet_amount: parseFloat(minBet),
      currency_id: selectedCurrency || 1,
      started_at: formatDateSecondsApi(dateStart as Date) || "",
      finished_at: formatDateSecondsApi(dateEnd as Date) || "",
      title,
      description,
    };
    if (shouldRepeat) {
      data.replay_in_days = parseInt(repaetPeriod, 10);
    }
    const result = await editCommonInfo(data, tournament.id);
    if (typeof result === "string") {
      setCommomError(result as string);
      showErrorNotif(result as string);
    } else {
      setCommonEdit(false);
      showSuccessNotif(t("Data edited successfully"));
    }
    setCommonFetching(false);
  };
  const handleSubmitGames = async (): Promise<void> => {
    setGamesFetching(true);
    const gamesIds: number[] = [];
    selectedGames.forEach((el) => {
      gamesIds.push(typeof el.id === "string" ? parseInt(el.id, 10) : el.id);
    });
    const data: AddTournamentPayload = {
      games_ids: [],
      all_games: allGames ? 1 : 0,
    };
    if (!allGames) {
      data.games_ids = gamesIds;
    }
    const result = await editGamesInfo(data, tournament.id);
    if (typeof result === "string") {
      setGamesError(result as string);
      showErrorNotif(result as string);
    } else {
      setGamesEdit(false);
      showSuccessNotif(t("Data edited successfully"));
    }
    setGamesFetching(false);
  };
  const handleSubmitPrizes = async (): Promise<void> => {
    setPrizesFetching(true);
    const data = {
      places: prizes,
    };
    const result = await editPrizesInfo(data, tournament.id);
    if (typeof result === "string") {
      setPrizesError(result as string);
      showErrorNotif(result as string);
    } else {
      setPrizesEdit(false);
      showSuccessNotif(t("Data edited successfully"));
    }
    setPrizesFetching(false);
  };
  const handleSubmitUsers = async (): Promise<void> => {
    setUsersFetching(true);
    setUsersError("");
    if (addUsers.length > 0) {
      const usersArray: number[] = [];
      addUsers.forEach((el) => {
        usersArray.push(parseInt(el, 10));
      });
      const result = await addTournamentUsers(usersArray, tournament.id);
      if (typeof result === "string") {
        setUsersError(`${usersError} ${result as string}`);
      } else {
        setAddUsers([]);
      }
    }
    if (removeUsers.length > 0) {
      const result = await removeTournamentUsers(removeUsers, tournament.id);
      if (typeof result === "string") {
        setUsersError(`${usersError} ${result as string}`);
      } else {
        setRemoveUsers([]);
      }
    }
    setUsersFetching(false);
    setUsersEdit(false);
  };

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
      if (tournament.currency) {
        const item = response.data.filter(
          (el) => el.code === tournament.currency
        )[0];
        setSelectedCurrency(item.id);
      }
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  //   Games logic
  const loadGames = async (
    search: string,
    page: number,
    clearRequest?: boolean
  ): Promise<void> => {
    setHasMoreGames(true);
    const response = await getAllGamesListTournament(
      search,
      null,
      null,
      page,
      perPageGames
    );
    if (typeof response !== "string") {
      const result: { name: string; id: number }[] = [];
      response.data.forEach((el) => {
        result.push({ name: el.title, id: el.id });
      });
      setGames(clearRequest ? [...result] : [...games, ...result]);
      if (response.data.length < perPageGames) {
        setHasMoreGames(false);
      }
    }
  };
  useEffect(() => {
    loadGames(gameSearch, gamePage);
    // eslint-disable-next-line
  }, []);
  const handleGameSearch = (e: string): void => {
    setGames([]);
    setGameSearch(e);
    setGamePage(1);
    loadGames(e, 1, true);
  };
  const handleGamePaginate = (): void => {
    loadGames(gameSearch, gamePage + 1);
    setGamePage(gamePage + 1);
  };
  const handleGameSelect = (e: { name: string; id: number | string }): void => {
    setSelectedGames([...selectedGames, e]);
  };
  const handleGameClear = (e: number | string): void => {
    const newArray = selectedGames.filter((el) => {
      return el.id !== e;
    });
    setSelectedGames(newArray);
  };

  //   Prizes logic
  const handlePrizeChange = (amount: string, id: number): void => {
    setPrizes((prev) =>
      prev.map((el, i) => (i !== id ? el : { ...el, reward_amount: amount }))
    );
  };
  const addPrize = (): void => {
    setPrizes([
      ...prizes,
      {
        place: prizes.length + 1,
        reward_amount: ((prizes.length + 1) * 100).toString(),
        id: prizes.length + 1,
      },
    ]);
  };
  const handlePrizeRemove = (): void => {
    setPrizes(prizes.slice(0, -1));
  };

  //   Users logic
  const loadUsers = async (
    search: string,
    page: number,
    clearRequest?: boolean
  ): Promise<void> => {
    setHasMoreUsers(true);
    const response = await getUsersList(
      page,
      search ? `&first_name=${search || ""}` : ""
    );
    if (typeof response !== "string") {
      const result: { name: string; id: number }[] = [];
      response.data.forEach((el) => {
        result.push({
          name: `${el.first_name || ""} ${el.last_name || ""}`,
          id: el.id,
        });
      });
      setUsers(clearRequest ? [...result] : [...users, ...result]);
      if (response.data.length < perPageUsers) {
        setHasMoreUsers(false);
      }
    }
  };
  const renderUsersList = (): { name: string; id: number }[] => {
    const selectedIds: number[] = [];
    selectedUsers?.forEach((el) => {
      selectedIds.push(el.id);
    });
    const filteredUsers = users.filter((el) => !selectedIds.includes(el.id));
    return filteredUsers;
  };
  useEffect(() => {
    loadUsers(usersSearch, userPage);
    // eslint-disable-next-line
  }, []);
  const handleUsersSearch = (e: string): void => {
    setUsers([]);
    setUsersSearch(e);
    setUserPage(1);
    loadUsers(e, 1, true);
  };
  const handleUsersPaginate = (): void => {
    loadUsers(usersSearch, userPage + 1);
    setUserPage(userPage + 1);
  };
  const handleUsersSelect = (e: { name: string; id: number }): void => {
    setSelectedUsers(selectedUsers ? [...selectedUsers, e] : [e]);
    // @ts-ignore
    setAddUsers([...addUsers, e.id]);
    setRemoveUsers(
      removeUsers.filter((el) => {
        // @ts-ignore
        return el !== e.id;
      })
    );
  };
  const handleUsersClear = (e: number): void => {
    const newArray = selectedUsers?.filter((el) => {
      return el.id !== e;
    });
    setSelectedUsers(newArray);
    // @ts-ignore
    setRemoveUsers([...removeUsers, e]);
    setAddUsers(
      addUsers.filter((el) => {
        // @ts-ignore
        return el !== e;
      })
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Tournament info")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="spin-info-item">
          <h5>{t("Common")}</h5>
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Title")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Title") || "Title"}
                  value={title}
                  onChange={(e): void => {
                    setTitle(e.target.value);
                  }}
                  disabled={!isCommonEdit}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Description")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder={t("Description") || "Description"}
                  value={description}
                  onChange={(e): void => {
                    setDescription(e.target.value);
                  }}
                  disabled={!isCommonEdit}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Qualification rounds")}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={
                    t("Qualification rounds") || "Qualification rounds"
                  }
                  value={rounds}
                  onChange={(e): void => {
                    setRounds(e.target.value);
                  }}
                  disabled={!isCommonEdit}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Currency")}</Form.Label>
                <Form.Select
                  aria-label={t("Currency") || "Currency"}
                  value={selectedCurrency}
                  onChange={(e): void => {
                    setSelectedCurrency(parseInt(e.target.value as string, 10));
                  }}
                  disabled={!isCommonEdit}
                >
                  {currencyList &&
                    currencyList.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.code}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Point cost")}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t("Point cost") || "Point cost"}
                  value={pointsCost}
                  onChange={(e): void => {
                    setPointsCost(e.target.value);
                  }}
                  disabled={!isCommonEdit}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Min bet")}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t("Min bet") || "Min bet"}
                  value={minBet}
                  onChange={(e): void => {
                    setMinBet(e.target.value);
                  }}
                  disabled={!isCommonEdit}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Auto replay")}</Form.Label>
                <Form.Check
                  checked={shouldRepeat}
                  onChange={(): void => {
                    setShouldRepeat(!shouldRepeat);
                  }}
                  disabled={!isCommonEdit}
                  data-testid="games-admin-filter-jack"
                />
              </Form.Group>
              {shouldRepeat && (
                <Form.Group className="mb-3">
                  <Form.Label>{t("Replay days")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t("Replay days") || "Replay days"}
                    value={repaetPeriod}
                    onChange={(e): void => {
                      setRepaetPeriod(e.target.value);
                    }}
                    disabled={!isCommonEdit}
                  />
                </Form.Group>
              )}
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Start date")}</Form.Label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  onChange={(dateEvent: Date): void => {
                    setDateStart(dateEvent);
                  }}
                  showMonthDropdown
                  showYearDropdown
                  showTimeSelect
                  selected={dateStart}
                  dropdownMode="select"
                  placeholderText={t("Select date") || "Select date"}
                  className="form-control"
                  disabled={!isCommonEdit}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("End date")}</Form.Label>
                <DatePicker
                  onChange={(dateEvent: Date): void => {
                    setDateEnd(dateEvent);
                  }}
                  showMonthDropdown
                  showYearDropdown
                  showTimeSelect
                  selected={dateEnd}
                  dropdownMode="select"
                  placeholderText={t("Select date") || "Select date"}
                  className="form-control"
                  disabled={!isCommonEdit}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3">
                {imgUrl && (
                  <>
                    <Form.Label>{t("Image")}</Form.Label>
                    <div className="tournament-img-preview">
                      <img src={imgUrl} alt="" />
                    </div>
                  </>
                )}
                <Form.Label>{t("Update image")}</Form.Label>
                <Form.Control
                  type="file"
                  placeholder={t("Update image") || "Update image"}
                  disabled={!isCommonEdit}
                  onChange={(e): void =>
                    setImgFile(
                      // @ts-ignore
                      e.target.files[0] as File
                    )
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Background")}</Form.Label>
                <Form.Select
                  aria-label={t("Background") || "Background"}
                  value={background}
                  onChange={(e): void => {
                    setBackground(e.target.value as string);
                  }}
                  disabled={!isCommonEdit}
                >
                  <option value="blue">blue</option>
                  <option value="green">green</option>
                  <option value="orange">orange</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {commomError && <div className="error-msg">{commomError}</div>}
          {isCommonEdit ? (
            <Button
              variant="primary"
              onClick={(): void => {
                handleSubmitCommon();
              }}
              disabled={isCommonFetching}
            >
              {t("Confirm")}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={(): void => {
                setCommonEdit(true);
              }}
            >
              {t("Edit")}
            </Button>
          )}
        </div>
        <div className="spin-info-item">
          <h5>{t("Games")}</h5>
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("All games")}</Form.Label>
                <Form.Check
                  checked={allGames}
                  onChange={(): void => {
                    setAllGames(!allGames);
                  }}
                  data-testid="games-admin-filter-jack"
                  disabled={!isGamesEdit}
                />
              </Form.Group>
              {!allGames && (
                <MultiSelect
                  selectedItems={selectedGames}
                  searchedItems={games}
                  hasMore={hasMoreGames}
                  searchValue={gameSearch}
                  handleSearch={handleGameSearch}
                  handleSelect={handleGameSelect}
                  handleClear={handleGameClear}
                  handlePaginate={handleGamePaginate}
                  disabled={!isGamesEdit}
                />
              )}
            </Col>
          </Row>
          {gamesError && <div className="error-msg">{gamesError}</div>}
          {isGamesEdit ? (
            <Button
              variant="primary"
              onClick={(): void => {
                handleSubmitGames();
              }}
              disabled={isGamesFetching}
            >
              {t("Confirm")}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={(): void => {
                setGamesEdit(true);
              }}
            >
              {t("Edit")}
            </Button>
          )}
        </div>
        <div className="spin-info-item">
          <h5>{t("Prizes")}</h5>
          <Row>
            <Col sm={12}>
              {prizes.map((el, idx) => (
                <TournamentPrizeItem
                  key={el.place}
                  id={idx}
                  value={el.reward_amount.toString()}
                  handlePrizeChange={handlePrizeChange}
                  handlePrizeRemove={handlePrizeRemove}
                  isLast={el.place > 3 && el.place === prizes.length}
                  disabled={!isPrizesEdit}
                />
              ))}
              <Button
                variant="primary"
                onClick={addPrize}
                disabled={!isPrizesEdit}
                className="mb-2"
              >
                {t("Add")}
              </Button>
            </Col>
          </Row>
          {prizesError && <div className="error-msg">{prizesError}</div>}
          {isPrizesEdit ? (
            <Button
              variant="primary"
              onClick={(): void => {
                handleSubmitPrizes();
              }}
              disabled={isPrizesFetching}
            >
              {t("Confirm")}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={(): void => {
                setPrizesEdit(true);
              }}
            >
              {t("Edit")}
            </Button>
          )}
        </div>
        <div className="spin-info-item">
          <h5>{t("Users")}</h5>
          <Row>
            <Col sm={12}>
              {selectedUsers && (
                <MultiSelect
                  selectedItems={selectedUsers}
                  searchedItems={renderUsersList()}
                  hasMore={hasMoreUsers}
                  searchValue={usersSearch}
                  handleSearch={handleUsersSearch}
                  // @ts-ignore
                  handleSelect={handleUsersSelect}
                  // @ts-ignore
                  handleClear={handleUsersClear}
                  handlePaginate={handleUsersPaginate}
                  disabled={!isUsersEdit}
                />
              )}
            </Col>
          </Row>
          {usersError && <div className="error-msg">{usersError}</div>}
          {isUsersEdit ? (
            <Button
              variant="primary"
              onClick={(): void => {
                handleSubmitUsers();
              }}
              disabled={isUsersFetching}
            >
              {t("Confirm")}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={(): void => {
                setUsersEdit(true);
              }}
            >
              {t("Edit")}
            </Button>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoTournamentModal;
