import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CirclesWithBar, ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate } from "react-router-dom";

import {
  getAllTornaments,
  getAllTornamentsLogin,
} from "../services/apiTournaments";
import { TournamentItem, TournamentStatuses } from "../types/tournamentTypes";
import type { RootState, AppDispatch } from "../store/store";
import {
  getAllGamesFunc,
  setActivePage,
  clearGames,
} from "../store/slices/games";

import GamesTitle from "../components/HomePage/GamesTitle/GamesTitle";
import TournamentBanner from "../components/banners/TournamentBanner";
import GameItem from "../components/HomePage/GamesView/GameItem/GameItem";

const AllTournamentsPage: React.FC = () => {
  // Tournaments data
  const [tournametsList, setTournametsList] = useState<TournamentItem[]>([]);
  const [tournametsActiveList, setTournametsActiveList] = useState<
    TournamentItem[]
  >([]);
  const [tournametsMineList, setTournametsMineList] = useState<
    TournamentItem[]
  >([]);
  const [tournametsComingList, setTournametsComingList] = useState<
    TournamentItem[]
  >([]);
  const [tournametsFinishedList, setTournametsFinishedList] = useState<
    TournamentItem[]
  >([]);
  const [tournamentFetching, setTournamentFetching] = useState<boolean>(false);
  const [paginationFetching, setPaginationFetching] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [activePage, setActiveTournamentsPage] = useState<number>(1);

  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  // Games data
  const allGames = useSelector((state: RootState) => state.games.allGames);
  const totalGames = useSelector((state: RootState) => state.games.totalGames);
  const activePageGames = useSelector(
    (state: RootState) => state.games.activePage
  );
  const hasMoreGames = useSelector(
    (state: RootState) => state.games.hasMoreGames
  );
  const allFetching = useSelector(
    (state: RootState) => state.games.allFetching
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Games logic
  useEffect(() => {
    if (!allGames) {
      dispatch(
        getAllGamesFunc({
          search: null,
          category: null,
          producer: null,
        })
      );
    }
  }, [dispatch, allGames]);
  const loadMoreGamesHandler = (): void => {
    dispatch(
      getAllGamesFunc({
        search: null,
        category: null,
        producer: null,
        page: activePageGames + 1,
      })
    );
    dispatch(setActivePage(activePageGames + 1));
  };
  useEffect(() => {
    return () => {
      dispatch(clearGames(true));
    };
  }, [dispatch]);

  // Tournaments logic
  const renderList = (items: TournamentItem[]): void => {
    const active: TournamentItem[] = [];
    const mine: TournamentItem[] = [];
    const coming: TournamentItem[] = [];
    const finished: TournamentItem[] = [];
    items.forEach((el) => {
      if (el.is_mine) mine.push(el);
      if (el.status === TournamentStatuses.active && !el.is_mine)
        active.push(el);
      if (el.status === TournamentStatuses.coming && !el.is_mine)
        coming.push(el);
      if (el.status === TournamentStatuses.expired && !el.is_mine)
        finished.push(el);
    });
    if (tournametsActiveList !== active)
      setTournametsActiveList([...tournametsActiveList, ...active]);
    if (tournametsMineList !== mine)
      setTournametsMineList([...tournametsMineList, ...mine]);
    if (tournametsComingList !== coming)
      setTournametsComingList([...tournametsComingList, ...coming]);
    if (tournametsFinishedList !== finished) {
      setTournametsFinishedList([...tournametsFinishedList, ...finished]);
    }
  };
  const loadTournaments = async (page: number): Promise<void> => {
    const result = isLogged
      ? await getAllTornamentsLogin(page)
      : await getAllTornaments(page);
    if (typeof result !== "string") {
      setTournametsList([
        ...tournametsList,
        ...(result.data as TournamentItem[]),
      ]);
      renderList(result.data as TournamentItem[]);
      if (result?.data?.length < 5) {
        setHasMore(false);
      }
    }
    setTournamentFetching(false);
    setPaginationFetching(false);
  };
  useEffect(() => {
    setTournamentFetching(true);
    if (typeof isLogged !== "undefined") {
      loadTournaments(1);
    }
    // eslint-disable-next-line
  }, [isLogged]);
  const loadMoreHandler = (): void => {
    setPaginationFetching(true);
    loadTournaments(activePage + 1);
    setActiveTournamentsPage(activePage + 1);
  };

  return (
    <div className="tournaments-all-page">
      <div className="tournaments-all-bg" />
      <div className="container">
        <div className="tournaments-list-wrap">
          {tournamentFetching && (
            <CirclesWithBar
              height="100"
              width="100"
              color="#D0174F"
              wrapperStyle={{ justifyContent: "center" }}
              visible
              ariaLabel="circles-with-bar-loading"
            />
          )}
          {!tournamentFetching && tournametsMineList?.length > 0 && (
            <>
              <GamesTitle txt={t("My Tournaments")} />
              {tournametsMineList.map((el) => (
                <TournamentBanner
                  title={el.title}
                  prize={`${getSymbolFromCurrency(el.currency) || ""} ${
                    el.fund
                  }`}
                  date={`${moment(el.started_at).format("DD.MM")} - ${moment(
                    el.finished_at
                  ).format("DD.MM")}`}
                  status={el.status}
                  prizesCount={el.places.length}
                  currency={el.currency}
                  spins={el.qualification_rounds_count}
                  users={el.users}
                  places={el.places.slice(0, 3)}
                  isList
                  key={el.id}
                  showLogin={(): void => navigate("/login")}
                  id={el.id}
                  img={el.image}
                  bg={el.bg_color}
                />
              ))}
            </>
          )}
          {!tournamentFetching && tournametsActiveList?.length > 0 && (
            <>
              <GamesTitle txt={t("Active Tournaments")} />
              {tournametsActiveList.map((el) => (
                <TournamentBanner
                  title={el.title}
                  prize={`${getSymbolFromCurrency(el.currency) || ""} ${
                    el.fund
                  }`}
                  date={`${moment(el.started_at).format("DD.MM")} - ${moment(
                    el.finished_at
                  ).format("DD.MM")}`}
                  status={el.status}
                  prizesCount={el.places.length}
                  currency={el.currency}
                  spins={el.qualification_rounds_count}
                  users={el.users}
                  places={el.places.slice(0, 3)}
                  isList
                  key={el.id}
                  showLogin={(): void => navigate("/login")}
                  id={el.id}
                  img={el.image}
                  bg={el.bg_color}
                />
              ))}
            </>
          )}
          {!tournamentFetching && tournametsComingList?.length > 0 && (
            <>
              <GamesTitle txt={t("Upcoming Tournaments")} />
              {tournametsComingList.map((el) => (
                <TournamentBanner
                  title={el.title}
                  prize={`${getSymbolFromCurrency(el.currency) || ""} ${
                    el.fund
                  }`}
                  date={`${moment(el.started_at).format("DD.MM")} - ${moment(
                    el.finished_at
                  ).format("DD.MM")}`}
                  status={el.status}
                  prizesCount={el.places.length}
                  currency={el.currency}
                  spins={el.qualification_rounds_count}
                  users={el.users}
                  places={el.places.slice(0, 3)}
                  isList
                  key={el.id}
                  showLogin={(): void => navigate("/login")}
                  id={el.id}
                  img={el.image}
                  bg={el.bg_color}
                />
              ))}
            </>
          )}
          {!tournamentFetching && tournametsFinishedList?.length > 0 && (
            <>
              <GamesTitle txt={t("Finished Tournaments")} />
              {tournametsFinishedList.map((el) => (
                <TournamentBanner
                  title={el.title}
                  prize={`${getSymbolFromCurrency(el.currency) || ""} ${
                    el.fund
                  }`}
                  date={`${moment(el.started_at).format("DD.MM")} - ${moment(
                    el.finished_at
                  ).format("DD.MM")}`}
                  status={el.status}
                  prizesCount={el.places.length}
                  currency={el.currency}
                  spins={el.qualification_rounds_count}
                  users={el.users}
                  places={el.places.slice(0, 3)}
                  isList
                  key={el.id}
                  isFinished
                  showLogin={(): void => navigate("/login")}
                  id={el.id}
                  img={el.image}
                  bg={el.bg_color}
                />
              ))}
            </>
          )}
          {hasMore && tournametsList?.length > 0 && (
            <button
              className="secondary-btn centered"
              type="button"
              disabled={paginationFetching}
              onClick={loadMoreHandler}
            >
              {paginationFetching ? (
                <ThreeDots
                  height="15"
                  width="30"
                  color="#D0174F"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              ) : (
                t("Load more")
              )}
            </button>
          )}
          <div className="popular-games-wrap">
            <GamesTitle
              txt={t("Popular Games")}
              badge={totalGames.toString()}
            />
            {allGames && (
              <div className="games-row">
                {allGames.map((el) => (
                  <GameItem
                    key={el.id}
                    name={el.title}
                    bg={el.icons["380x380"]}
                    id={el.id}
                    description={el.description}
                    bgGame={el.icons ? el.icons.background : ""}
                  />
                ))}
              </div>
            )}
            {allFetching && !allGames && (
              <CirclesWithBar
                height="100"
                width="100"
                color="#D0174F"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {hasMoreGames && allGames && (
              <button
                className="secondary-btn centered"
                type="button"
                disabled={allFetching}
                onClick={loadMoreGamesHandler}
              >
                {allFetching ? (
                  <ThreeDots
                    height="15"
                    width="30"
                    color="#D0174F"
                    wrapperStyle={{ justifyContent: "center" }}
                    visible
                    ariaLabel="circles-with-bar-loading"
                  />
                ) : (
                  t("Load more")
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTournamentsPage;
