import ApiClient from "./ApiClient";
import { RequestError, KaptahAmountResponse } from "../types/requestTypes";
import {
  WithdrawalItemNew,
  DepositItem,
  PaymentLimitItem,
  CurrencyCourseItem,
} from "../types/paymentTypes";

export const createOrder = async (
  payment_type: string,
  rub_sum: string
): Promise<
  { order: { payment_requisites: string; rub_sum: string } } | string
> => {
  try {
    const result = await ApiClient.post<{
      order: { payment_requisites: string; rub_sum: string };
    }>("/payments/rub/create-order", {
      payment_type,
      rub_sum,
    });
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const createPayment = async (
  paymentSystem: string,
  amount: number,
  currency: string,
  language: string,
  crypto_currency?: string,
  wallet_from?: string,
  p2pAifory?: boolean
): Promise<
  { data: { url: string; amount: number; wallet: string } } | string
> => {
  try {
    const result = await ApiClient.post<{
      data: { url: string; amount: number; wallet: string };
    }>("/payments/create-payment", {
      paymentSystem,
      amount,
      currency,
      language,
      crypto_currency,
      wallet_from,
      p2pAifory,
    });
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const createWidthdrawal = async (
  payment_type: string,
  rub_sum: string,
  owner_bank_card: string
): Promise<
  { order: { owner_bank_card: string; rub_sum: string } } | string
> => {
  try {
    const result = await ApiClient.post<{
      order: { owner_bank_card: string; rub_sum: string };
    }>("/payments/rub/withdrawal-order", {
      payment_type,
      rub_sum,
      owner_bank_card,
    });
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const createWidthdrawalRequest = async (
  currency: string,
  paymentSystem: string,
  amount: number,
  address?: string,
  crypto_currency?: string
): Promise<any> => {
  try {
    const result = await ApiClient.post<any>("/payments/withdrawal", {
      paymentSystem,
      currency,
      amount,
      address,
      crypto_currency,
    });
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    const errorsKeys: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    // eslint-disable-next-line
        Object.keys(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errorsKeys.push(el as string);
    });
    return { error: errors.join(" "), errorsKeys: errorsKeys.join(" ") } as {
      error: string;
      errorsKeys: string;
    };
  }
};

export const getPaymentsTypesList = async (): Promise<
  { types: { [key: string]: string } } | string
> => {
  try {
    const result = await ApiClient.get<{ types: { [key: string]: string } }>(
      "/payments/rub/client-types"
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getOrders = async (
  filters: string
): Promise<DepositItem[] | string> => {
  try {
    const result = await ApiClient.get<DepositItem[]>(
      `/payments/deposits/bettransfer/me?${filters}&per_page=5000`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getWithdrawals = async (
  filters: string
): Promise<{ data: WithdrawalItemNew[] } | string> => {
  try {
    const result = await ApiClient.get<{ data: WithdrawalItemNew[] }>(
      `/payments/withdrawals/bettransfer/me?${filters}&per_page=5000`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getCryptoCoins = async (): Promise<string[] | string> => {
  try {
    const result = await ApiClient.get<string[]>(
      "/payments/crypto/available/list"
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getPaymentLimits = async (): Promise<
  { data: PaymentLimitItem[] } | string
> => {
  try {
    const result = await ApiClient.get<{ data: PaymentLimitItem[] }>(
      "/payments/limits"
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getCurrencyCourse = async (
  from: string,
  to: string,
  amount: number
): Promise<{ currencies: CurrencyCourseItem } | string> => {
  try {
    const result = await ApiClient.get<{ currencies: CurrencyCourseItem }>(
      `/payments/crypto/convert/from-fiat?from=${from}&to=${to}&amount=${amount}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const createKaptahOrder = async (
  order_id: string
): Promise<{ statusInDb: string } | string> => {
  try {
    const result = await ApiClient.post<{ statusInDb: string }>(
      "/kaptahpay-callback",
      {
        order_id,
      }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const kaptahConvert = async (
  amount: string
): Promise<{ amount: number; amount_in_irr: string } | string> => {
  try {
    const result: { amount: number; amount_in_irr: string } =
      await ApiClient.post("/kaptahpay-convert", {
        amount,
      });
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
    Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const createKaptahPayment = async (
  paymentSystem: string,
  amount: number | null,
  currency: string,
  amount_in_irr: number | null
): Promise<
  | { data: { url: string; amount: number; wallet: string; message: string } }
  | string
> => {
  try {
    const result = await ApiClient.post<{
      data: { url: string; amount: number; wallet: string; message: string };
    }>("/payments/create-payment", {
      paymentSystem,
      amount,
      currency,
      amount_in_irr,
    });
    return result?.data.message ? result?.data.message : result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const getKaptahAmounts = async (): Promise<
  KaptahAmountResponse | string
> => {
  try {
    const result = await ApiClient.get<KaptahAmountResponse>(
      "/kaptahpay-getAmounts"
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
