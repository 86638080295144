import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CirclesWithBar } from "react-loader-spinner";

import classNames from "classnames";
import { addNewDocument, getMyDocuments } from "../../../services/apiDocuments";
import {
  DocumentItem,
  DocumentTypes,
  DocumentPayload,
} from "../../../types/documentsTypes";

import ErrorModal from "../../modals/ErrorModal";
import styles from "./ProfileDocsStyles.module.scss";
import CreditCardInputs from "../../forms/CreditCardInputs/CreditCardInputs";
import StatusDocument from "../../UI/StatusDocument/StatusDocument";
import DocumentInputs from "../../forms/DocumentInputs/DocumentInputs";
import { RootState } from "../../../store/store";

const documentsList = [
  {
    value: "id_card",
    title: "ID Сard",
  },
  {
    value: "license",
    title: "Driver's License",
  },
];

const ProfileDocs: React.FC = () => {
  const { t } = useTranslation();

  const [withdrawalCard, setWithdrawalCard] = useState<string>("");
  const [withdrawalYY, setWithdrawalYY] = useState<string>("");
  const [withdrawalMM, setWithdrawalMM] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<{
    value: string;
    title: string;
  }>({
    value: "id_card",
    title: "ID Сard",
  });
  const [cardImg, setCardImg] = useState<File>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isLoadFetching, setIsLoadFetching] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>("");
  const [currentDocuments, setCurrentDocuments] = useState<DocumentItem[]>();
  const [currentIdFront, setCurrentIdFront] = useState<DocumentItem>();
  const [currentIdBack, setCurrentIdBack] = useState<DocumentItem>();
  const [currentDriver, setCurrentDriver] = useState<DocumentItem>();
  const [currentTax, setCurrentTax] = useState<DocumentItem>();
  const [currentSelfie, setCurrentSelfie] = useState<DocumentItem>();
  const [currentOther, setCurrentOther] = useState<DocumentItem>();
  const [currentBankCard, setCurrentBankCard] = useState<DocumentItem>();

  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  const getDocuments = async (): Promise<void> => {
    setIsLoadFetching(true);
    const result = await getMyDocuments();
    if (typeof result !== "string" && result.data.docs.length > 0) {
      const docs: DocumentItem[] = [];
      result.data.docs.forEach((el) => {
        if (el.credit_card_month) {
          setWithdrawalMM(el.credit_card_month);
        }
        if (el.credit_card_year) {
          setWithdrawalYY(el.credit_card_year);
        }
        if (el.credit_card_number) {
          setWithdrawalCard(el.credit_card_number);
        }
        docs.push(el);
      });
      setCurrentDocuments(docs as DocumentItem[]);
    }
    setIsLoadFetching(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    currentDocuments?.forEach((el): void => {
      if (el.type === "ID_CARD" && el.side === "Front") {
        setCurrentIdFront(el);
        setSelectedItem({
          value: "id_card",
          title: "ID Сard",
        });
      }
      if (el.type === "ID_CARD" && el.side === "Back") {
        setCurrentIdBack(el);
        setSelectedItem({
          value: "id_card",
          title: "ID Сard",
        });
      }
      if (el.type === "DRIVER_LICENSE") {
        setCurrentDriver(el);
        setSelectedItem({
          value: "license",
          title: "Driver's License",
        });
      }
      if (el.type === "TAX_NUMBER") {
        setCurrentTax(el);
      }
      if (el.type === "SELFIE_IDENTITY") {
        setCurrentSelfie(el);
      }
      if (el.type === "OTHER") {
        setCurrentOther(el);
      }
      if (el.type === "CREDIT_CARD") {
        setCurrentBankCard(el);
      }
    });
    // eslint-disable-next-line
  }, [currentDocuments]);

  const handleSelect = (e: { value: string; title: string }): void => {
    setSelectedItem(e);
  };

  const sendDocuments = async (payload: DocumentPayload): Promise<void> => {
    setIsFetching(true);
    const result = await addNewDocument(payload);
    if (typeof result === "string") {
      setGlobalError(result as string);
      document.body.classList.add("no-scroll");
      document.body.classList.add("menu-hidden");
    } else {
      await getDocuments();
    }
    setIsFetching(false);
  };

  const sendCreditCard = async (): Promise<void> => {
    setIsFetching(true);
    const result = await addNewDocument({
      credit_card: cardImg,
      credit_card_number: withdrawalCard,
      credit_card_month: withdrawalMM,
      credit_card_year: withdrawalYY,
      card_holder: "User",
    });
    if (typeof result === "string") {
      setGlobalError(result as string);
      document.body.classList.add("no-scroll");
      document.body.classList.add("menu-hidden");
    } else {
      const docsArray: DocumentItem[] = [];
      result.docs.forEach((el) => {
        docsArray.push(el);
        setGlobalError("");
      });
      setCurrentDocuments(docsArray as DocumentItem[]);
    }
    setIsFetching(false);
  };
  const handleSelectImg = (
    e: File | undefined,
    type: DocumentTypes,
    errorMessage: string
  ): void => {
    if (errorMessage !== "") return;
    switch (type) {
      case DocumentTypes.id_card_front:
        sendDocuments({ id_card_front: e });
        break;
      case DocumentTypes.id_card_back:
        sendDocuments({ id_card_back: e });
        break;
      case DocumentTypes.driver_license:
        sendDocuments({ driver_license: e });
        break;
      case DocumentTypes.tax:
        sendDocuments({ tax_number: e });
        break;
      case DocumentTypes.selphi:
        sendDocuments({ selfie_identity: e });
        break;
      case DocumentTypes.other:
        sendDocuments({ other: e });
        break;
      case DocumentTypes.credit_card:
        setCardImg(e);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.profile_docs_wrap}>
      {/* <div className="profile-docs-content"> */}
      {isLoadFetching ? (
        <CirclesWithBar
          height="150"
          width="150"
          color="rgb(248, 176, 0)"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      ) : (
        <div
          className={`${styles.documents_main_wrap} ${
            localeActive?.code === "fa" ? styles.rtl : ""
          }`}
        >
          <div className={styles.main_title}>{t("Key Documents")}</div>
          <div className={styles.document_tabs}>
            {documentsList.map((el) => (
              <div
                className={classNames(styles.item, {
                  [styles.active]: selectedItem?.value === el.value,
                })}
                key={el.value}
                onClick={(): void => {
                  if (currentIdFront) return;
                  if (currentDriver) return;
                  handleSelect(el);
                }}
              >
                {t(el.title)}
              </div>
            ))}
          </div>
          <div className={styles.documents_box}>
            <div className={styles.documents_row}>
              <div className={styles.left_block}>
                {selectedItem?.value === "id_card" ? (
                  <div className={styles.document_item_wrap}>
                    <div className={styles.title}>{t("ID Сard")}</div>
                    <div className={styles.row}>
                      <div style={{ position: "relative" }}>
                        <StatusDocument preview={currentIdFront} />
                        <DocumentInputs
                          handleSelect={handleSelectImg}
                          type={DocumentTypes.id_card_front}
                          preview={currentIdFront}
                          size="normal"
                          setGlobalError={setGlobalError}
                        />
                        <div className={styles.subtitle}>{t("Front Side")}</div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <StatusDocument preview={currentIdBack} />
                        <DocumentInputs
                          handleSelect={handleSelectImg}
                          type={DocumentTypes.id_card_back}
                          preview={currentIdBack}
                          size="normal"
                          setGlobalError={setGlobalError}
                        />
                        <div className={styles.subtitle}>{t("Back Side")}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.document_item_wrap}>
                    <div className={styles.title}>
                      {t("Driver's License (DL)")}
                    </div>
                    <div style={{ position: "relative" }}>
                      <StatusDocument preview={currentDriver} />
                      <DocumentInputs
                        handleSelect={handleSelectImg}
                        type={DocumentTypes.driver_license}
                        preview={currentDriver}
                        size="normal"
                        setGlobalError={setGlobalError}
                      />
                      <div className={styles.subtitle}>{t("Front Side")}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.right_block}>
                <div className={styles.document_item_wrap}>
                  <div className={styles.title}>{t("Tax Number")}</div>
                  <div style={{ position: "relative" }}>
                    <StatusDocument preview={currentTax} />
                    <DocumentInputs
                      handleSelect={handleSelectImg}
                      type={DocumentTypes.tax}
                      preview={currentTax}
                      size="normal"
                      setGlobalError={setGlobalError}
                    />
                    <div className={styles.subtitle}>{t("Front Side")}</div>
                  </div>
                </div>
                <div className={styles.document_item_wrap}>
                  <div className={styles.title}>
                    {t(
                      selectedItem?.value === "id_card"
                        ? "Selfie with an ID Card"
                        : "Selfie with a DL"
                    )}
                  </div>
                  <div style={{ position: "relative" }}>
                    <StatusDocument preview={currentSelfie} />
                    <DocumentInputs
                      handleSelect={handleSelectImg}
                      type={DocumentTypes.selphi}
                      preview={currentSelfie}
                      size="normal"
                      setGlobalError={setGlobalError}
                    />
                    <div className={styles.subtitle}>{t("Front Side")}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.documents_row}>
              <div className={styles.document_item_wrap}>
                <div className={styles.title}>
                  {t("Add another identity document")}
                </div>
                <div style={{ position: "relative" }}>
                  <StatusDocument preview={currentOther} />
                  <DocumentInputs
                    handleSelect={handleSelectImg}
                    type={DocumentTypes.other}
                    preview={currentOther}
                    size="large"
                    setGlobalError={setGlobalError}
                  />
                  <div className={styles.subtitle}>{t("Front Side")}</div>
                </div>
              </div>
              <div className={styles.document_item_wrap}>
                <div className={styles.title}>
                  {t("Bank card verification")}
                </div>
                <div style={{ position: "relative" }}>
                  <StatusDocument preview={currentBankCard} />
                  <CreditCardInputs
                    withdrawalCard={withdrawalCard}
                    setWithdrawalCard={(value): void =>
                      setWithdrawalCard(value)
                    }
                    withdrawalMM={withdrawalMM}
                    setWithdrawalMM={(value): void => setWithdrawalMM(value)}
                    withdrawalYY={withdrawalYY}
                    setWithdrawalYY={(value): void => setWithdrawalYY(value)}
                    handleSelectImg={handleSelectImg}
                    isFetching={isFetching}
                    sendCreditCard={(): void => {
                      sendCreditCard();
                    }}
                    cardImg={cardImg}
                    currentBankCard={currentBankCard}
                    setGlobalError={setGlobalError}
                  />
                  <div className={styles.subtitle}>
                    {t("Enter bank card details")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {globalError && (
        <ErrorModal
          btnTxt="OK"
          mainTxt={globalError}
          closeModalHandler={(): void => {
            setGlobalError("");
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("menu-hidden");
          }}
          confirmModalHandler={(): void => {
            setGlobalError("");
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("menu-hidden");
          }}
        />
      )}
    </div>
  );
};

export default ProfileDocs;
