import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getAllGamesList,
  // getGamesCategories,
  // getGamesVendors,
} from "../../services/apiGames";
import {
  GameItemType,
  CategoryItemType,
  VendorItemType,
} from "../../types/gameTypes";
import {
  AllGamesListResponse,
  GameListPayload,
} from "../../types/requestTypes";

export interface SlotGamesState {
  slotGames: GameItemType[] | null;
  categories: CategoryItemType[] | null;
  vendors: VendorItemType[] | null;
  allSlotFetching: boolean;
  categoryFetching: boolean;
  totalGames: number;
  slotActivePage: number;
  hasMoreSlotGames: boolean;
}

const initialState: SlotGamesState = {
  slotGames: null,
  categories: null,
  vendors: null,
  allSlotFetching: false,
  categoryFetching: false,
  totalGames: 0,
  slotActivePage: 1,
  hasMoreSlotGames: true,
};

export const getAllSlotGamesFunc = createAsyncThunk(
  "slotGames/loadAllList",
  async (data: GameListPayload) => {
    let itemsPerPage;
    if (window.innerWidth <= 576) {
      itemsPerPage = 9;
    } else if (window.innerWidth <= 990) {
      itemsPerPage = 24;
    } else {
      itemsPerPage = 32;
    }
    const response = await getAllGamesList(
      data.search as string | null,
      data.category as string | null,
      data.producer as string | null,
      data.page as number | undefined,
      itemsPerPage,
      data.lobby as boolean | undefined,
      data.isLive as boolean | undefined
    );
    if (typeof response !== "string") return response as AllGamesListResponse;
    return { data: [], meta: { total: 0, last_page: 0 } };
  }
);

export const slotGames = createSlice({
  name: "slotGames",
  initialState,
  reducers: {
    setSlotActivePage: (state, action: PayloadAction<number>) => {
      state.slotActivePage = action.payload;
    },
    clearSlotGames: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.slotGames = null;
      } else {
        state.slotGames = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllSlotGamesFunc.fulfilled,
      (state: SlotGamesState, action: PayloadAction<AllGamesListResponse>) => {
        state.slotGames = state.slotGames
          ? [...state.slotGames, ...action.payload.data]
          : action.payload.data;
        let itemsPerPage;
        if (window.innerWidth <= 576) {
          itemsPerPage = 9;
        } else if (window.innerWidth <= 990) {
          itemsPerPage = 24;
        } else {
          itemsPerPage = 32;
        }
        if (action.payload.data.length < itemsPerPage) {
          state.hasMoreSlotGames = false;
        } else {
          state.hasMoreSlotGames = true;
        }
        state.totalGames = action.payload.meta.total;
        state.allSlotFetching = false;
      }
    );

    builder.addCase(getAllSlotGamesFunc.pending, (state: SlotGamesState) => {
      state.allSlotFetching = true;
    });
  },
});

export const { setSlotActivePage, clearSlotGames } = slotGames.actions;

export default slotGames.reducer;
