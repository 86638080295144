import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./WelcomeBonusStyles.module.scss";
import ModalTitle from "../../UI/ModalTitle/ModalTitle";
import MainButton from "../../UI/MainButton/MainButton";
import { RootState } from "../../../store/store";
import getValueWinWelcomeBonus from "../../../helpers/getValueWinWelcomeBonus";

interface WelcomeBonusModalProps {
  onClose: () => void;
}

const WelcomeBonusModal: React.FC<WelcomeBonusModalProps> = ({ onClose }) => {
  const typeCurrency = useSelector(
    (state: RootState) => state.user.userInfo.country?.code
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.welcome_bonus_modal}>
      <ModalTitle onClose={onClose} />
      <div className={styles.content}>
        <div className={styles.text_title}>Welcome Bonus</div>
        <div className={styles.text_winning}>
          {t(getValueWinWelcomeBonus(typeCurrency))}
        </div>
        <span className={styles.text_free_spin}>
          <div className={styles.text_count}>+50 FS</div>
          <div className={styles.text_description}>в Fortune Craft</div>
        </span>
        <MainButton
          title={t("See details")}
          onClick={(): void => {
            navigate("/welcome-bonus");
          }}
          variant="contained"
          color="info"
          className={styles.btn}
        />
      </div>
    </div>
  );
};

export default WelcomeBonusModal;
