import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import type { RootState } from "../../store/store";
import useGetVipStatusStyles from "../../helpers/useGetVipStatus";
import { LAST_VIP_LEVEL } from "../../constants/constants";

const VipBar: React.FC = () => {
  const vipClub = useSelector(
    (state: RootState) => state.user.userInfo.vip_club
  );
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  const { t } = useTranslation();
  const {
    imageSource,
    bgColorStatus,
    nameStatus,
    bgColorProgress,
    leftPosition,
    progressWidth,
    imagePosition,
    transformIcon,
  } = useGetVipStatusStyles(vipClub);

  return (
    <div>
      {vipClub && (
        <div
          className={
            localeActive?.code === "fa"
              ? "balance-modal-vip rtl"
              : "balance-modal-vip"
          }
        >
          <div className="title">
            {vipClub.level !== 0 ? t("VIP level - ") : null}
            <span className="vip-status">{nameStatus}</span>
          </div>
          <div className="content">
            <div className="wrapper-level-progress">
              <div
                className="level-progress"
                style={{ background: bgColorProgress }}
              >
                <div
                  className="bar"
                  style={{
                    width: progressWidth,
                    background: bgColorStatus,
                  }}
                />
                <img
                  className="progress-status-img"
                  style={{
                    left: leftPosition || `calc(${imagePosition} - 10px)`,
                    transform: transformIcon,
                  }}
                  src={imageSource}
                  alt=""
                />
                {/* <img src={imageSource} alt="" />
                </img> */}
              </div>
            </div>
            {vipClub.level !== LAST_VIP_LEVEL && (
              <div className="progress-text">
                <p>
                  {vipClub?.current_amount} / {vipClub?.value_for_complete}{" "}
                  {t("Scores")}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VipBar;
