import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CirclesWithBar } from "react-loader-spinner";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useSelector } from "react-redux";
import moment from "moment";
import { formatDateDayApi } from "../../../helpers/getCorrectDate";

import { getCountries } from "../../../services/apiLogin";
import {
  CountryItem,
  UserRoles,
  UserInfo,
  UpdateInfoPayload,
} from "../../../types/userTypes";
import type { RootState } from "../../../store/store";
import { createUser, editUser } from "../../../services/admin/apiUsers";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";

import arrowIcon from "../../../images/dropdown-arrow.svg";
import { Genders } from "../../modals/ProfilePageModal/ComfirmPersonalDataModal";

interface Props {
  show: boolean;
  isEdit?: boolean;
  user?: UserInfo;
  handleClose: () => void;
  handleUserAdded: () => void;
}

const UsersInfoModal: React.FC<Props> = ({
  show,
  handleClose,
  isEdit,
  handleUserAdded,
  user,
}) => {
  const { t } = useTranslation();
  const localesList = useSelector((state: RootState) => state.user.localesList);
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isEditingEnable, setEditingEnable] = useState<boolean>(false);
  const [showDrodown, setShowDropdown] = useState<boolean>(false);
  //   Countries data
  const [countriesList, setCountriesList] = useState<CountryItem[]>([]);
  const [hasMoreCountry, setHasMoreCountry] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);
  //  Form values
  const [ip, setIp] = useState<string>("");
  const [firsName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Date>();
  const [gender, setGender] = useState<Genders>(Genders.female);
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<CountryItem>();
  const [role, setRole] = useState<UserRoles>(UserRoles.player);
  const [activeLang, setActiveLang] = useState<string>("en");
  const [globalError, setGlobalError] = useState<string>("");

  //   Country logic
  const getCountriesHandle = async (page: number): Promise<void> => {
    const result = await getCountries(page);
    if (typeof result !== "string") {
      setCountriesList([...countriesList, ...(result.data as CountryItem[])]);
      if (result.data.length === 0) {
        setHasMoreCountry(false);
      }
    }
  };
  const handleScroll = (element: HTMLDivElement): void => {
    if (
      element.scrollHeight - element.scrollTop <= element.clientHeight + 5 &&
      hasMoreCountry
    ) {
      setActivePage(activePage + 1);
      getCountriesHandle(activePage + 1);
    }
  };
  const showDropdownHandler = (): void => {
    if (isEdit && !isEditingEnable) return;
    setShowDropdown(true);
  };
  const hideDropdownHandler = (): void => {
    setShowDropdown(false);
  };
  const ref = useDetectClickOutside({
    onTriggered: hideDropdownHandler,
  });
  useEffect(() => {
    getCountriesHandle(1);
    // eslint-disable-next-line
  }, []);

  //   Edit logic
  useEffect(() => {
    if (user && isEdit) {
      setFirstName(user.first_name || "");
      setLastName(user.last_name || "");
      setCity(user.city as string);
      setEmail(user.email || "");
      setPhone(user.phone || "");
      setGender(user.gender === "m" ? Genders.male : Genders.female);
      if (user.date_of_birth)
        setBirthDate(moment(user.date_of_birth as string).toDate());
      setCountry(user?.country);
      setRole(user?.role || UserRoles.player);
      setActiveLang(user?.locale?.code || "en");
      if (user.ip) {
        setIp(user.ip);
      }
    }
    // eslint-disable-next-line
  }, [user, isEdit]);

  //   Form logic
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    const locale = localesList.filter((el) => {
      return el.code === activeLang;
    });
    const data = {
      first_name: firsName,
      last_name: lastName,
      gender: gender === Genders.female ? "f" : "m",
      country_id: country?.id.toString(),
      city,
      date_of_birth: formatDateDayApi(birthDate as Date),
      locale_id: locale[0].id,
      role,
    };
    const editData: UpdateInfoPayload = {};
    if (firsName && firsName !== user?.first_name) {
      editData.first_name = firsName;
    }
    if (lastName && lastName !== user?.last_name) {
      editData.last_name = lastName;
    }
    if (gender && gender !== user?.gender) {
      editData.gender = gender === Genders.female ? "f" : "m";
    }
    if (country && country !== user?.country) {
      editData.country_id = country?.id.toString();
    }
    if (city && city !== user?.city) {
      editData.city = city;
    }
    if (
      birthDate &&
      formatDateDayApi(birthDate as Date) !== user?.date_of_birth
    ) {
      editData.date_of_birth = formatDateDayApi(birthDate as Date);
    }
    if (locale && locale[0] !== user?.locale) {
      editData.locale_id = locale[0].id;
    }
    if (role && role !== user?.role) {
      editData.role = role;
    }
    if (email && email !== user?.email) {
      editData.email = email;
    }
    if (phone && phone !== user?.phone) {
      editData.phone = phone;
    }
    if (ip && ip !== user?.ip) {
      editData.ip = ip;
    }
    const result = isEdit
      ? await editUser(editData, user?.id as number)
      : await createUser({ ...data, email, phone, password });
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      handleUserAdded();
      showSuccessNotif(
        t(isEdit ? "Data edited successfully" : "Data added successfully")
      );
    }
    setFetching(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? t("User info") : t("Add new user")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {(userRole === UserRoles.admin ||
            userRole === UserRoles["senior-operator"]) && (
            <>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("First name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("First name") || "First name"}
                    value={firsName}
                    onChange={(e): void => {
                      setFirstName(e.target.value);
                    }}
                    disabled={isEdit && !isEditingEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Last name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Last name") || "Last name"}
                    value={lastName}
                    onChange={(e): void => {
                      setLastName(e.target.value);
                    }}
                    disabled={isEdit && !isEditingEnable}
                  />
                </Form.Group>
              </Col>
            </>
          )}
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Email address")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Email address") || "Email address"}
                value={email}
                onChange={(e): void => {
                  setEmail(e.target.value);
                }}
                disabled={isEdit && !isEditingEnable}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Phone")}</Form.Label>
              <PhoneInput
                inputClass="form-control"
                country="ua"
                value={phone}
                placeholder={t("Phone") || "Phone"}
                onChange={(e): void => {
                  setPhone(e);
                }}
                disabled={isEdit && !isEditingEnable}
              />
            </Form.Group>
          </Col>
          {!isEdit && (
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Password")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Password") || "Password"}
                  value={password}
                  onChange={(e): void => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          )}
          {(userRole === UserRoles.admin ||
            userRole === UserRoles["senior-operator"]) && (
            <>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("City")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("City") || "City"}
                    value={city}
                    onChange={(e): void => {
                      setCity(e.target.value);
                    }}
                    disabled={isEdit && !isEditingEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Country")}</Form.Label>
                  <div className="custom-select-wrap" ref={ref}>
                    <div
                      className={
                        isEdit && !isEditingEnable
                          ? "custom-select-item disabled"
                          : "custom-select-item"
                      }
                      onClick={showDropdownHandler}
                    >
                      {country?.name || <span>{t("Select country")}</span>}
                      <img src={arrowIcon} alt="" />
                    </div>
                    {showDrodown && (
                      <div
                        className="custom-select-dropdown"
                        onScroll={(e): void => {
                          handleScroll(e.target as HTMLDivElement);
                        }}
                      >
                        {countriesList.map((el) => (
                          <span
                            key={el.id}
                            onClick={(): void => {
                              setCountry(el);
                              hideDropdownHandler();
                            }}
                          >
                            {el.name}
                          </span>
                        ))}
                        {hasMoreCountry && (
                          <CirclesWithBar
                            height="50"
                            width="50"
                            color="#0d6efd"
                            wrapperStyle={{ justifyContent: "center" }}
                            visible
                            ariaLabel="circles-with-bar-loading"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Date of birth")}</Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: Date): void => {
                      setBirthDate(date);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    selected={birthDate}
                    dropdownMode="select"
                    placeholderText={t("Select date") || "Select date"}
                    className="form-control"
                    disabled={isEdit && !isEditingEnable}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Gender")}</Form.Label>
                  <Form.Select
                    aria-label={t("Gender") || "Gender"}
                    value={gender}
                    onChange={(e): void => {
                      setGender(e.target.value as Genders);
                    }}
                    disabled={isEdit && !isEditingEnable}
                  >
                    <option value={Genders.male}>{t("Male")}</option>
                    <option value={Genders.female}>{t("Female")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {userRole === UserRoles.admin && (
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Role")}</Form.Label>
                    <Form.Select
                      aria-label={t("Role") || "Role"}
                      value={role}
                      onChange={(e): void => {
                        setRole(e.target.value as UserRoles);
                      }}
                      disabled={isEdit && !isEditingEnable}
                    >
                      <option value={UserRoles.admin}>
                        {t(UserRoles.admin)}
                      </option>
                      <option value={UserRoles.player}>
                        {t(UserRoles.player)}
                      </option>
                      <option value={UserRoles["retention-manager"]}>
                        {t(UserRoles["retention-manager"])}
                      </option>
                      <option value={UserRoles.operator}>
                        {t(UserRoles.operator)}
                      </option>
                      <option value={UserRoles["senior-operator"]}>
                        {t(UserRoles["senior-operator"])}
                      </option>
                      <option value={UserRoles.trainee}>
                        {t(UserRoles.trainee)}
                      </option>
                      <option value={UserRoles["payment-manager"]}>
                        {t(UserRoles["payment-manager"])}
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Language")}</Form.Label>
                  <Form.Select
                    aria-label={t("Language") || "Language"}
                    value={activeLang}
                    onChange={(e): void => {
                      setActiveLang(e.target.value as string);
                    }}
                    disabled={isEdit && !isEditingEnable}
                  >
                    {localesList.map((el) => (
                      <option key={el.id} value={el.code}>
                        {el.code === "kk" ? "kz" : el.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {isEdit && (
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>IP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="IP"
                      value={ip}
                      onChange={(e): void => {
                        setIp(e.target.value);
                      }}
                      disabled={isEdit && !isEditingEnable}
                    />
                  </Form.Group>
                </Col>
              )}
            </>
          )}
        </Row>
        {globalError && <div className="error-msg">{globalError}</div>}
      </Modal.Body>
      <Modal.Footer>
        {isEdit && !isEditingEnable ? (
          <Button
            variant="primary"
            onClick={(): void => {
              setEditingEnable(true);
            }}
          >
            {t("Edit")}
          </Button>
        ) : (
          <>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={(): void => {
                handleSubmit();
              }}
              disabled={isFetching}
            >
              {t("Confirm")}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default UsersInfoModal;
