import React, { useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import { useDetectClickOutside } from "react-detect-click-outside";

import type { RootState } from "../store/store";
import { LocaleItem } from "../types/userTypes";
import { updateUserData } from "../services/apiLogin";
import { setActiveLocal } from "../store/slices/userData";

import logo from "../images/logo.svg";
import arrowIcon from "../images/arrow-down-new.svg";
// Vendor imgs
import vendor1 from "../images/vendors/vendor1-new.svg";
import vendor2 from "../images/vendors/vendor2-new.svg";
import vendor3 from "../images/vendors/vendor3-new.svg";
import vendor4 from "../images/vendors/vendor4-new.svg";
import vendor5 from "../images/vendors/vendor5-new.svg";
import vendor6 from "../images/vendors/vendor6-new.svg";
import vendor7 from "../images/vendors/vendor7-new.svg";
import vendor8 from "../images/vendors/vendor8-new.svg";
// Icons
// import mailIcon from "../images/mail-icon-new.svg";
// import phoneIcon from "../images/phone-icon-new.svg";

// Social icons
// import vkIcon from "../images/social-icons/vk-new2.svg";
// import telegramIcon from "../images/social-icons/telegram-new2.svg";
// import instagramIcon from "../images/social-icons/ins-new2.svg";

// Payment systems icons
import visaIcon from "../images/payment/footer-visa.svg";
import masterCardIcon from "../images/payment/footer-mastercard.svg";
import qiwiIcon from "../images/payment/footer-qiwi.svg";
import moneygoIcon from "../images/payment/footer-moneygo.svg";
import bitcoinIcon from "../images/payment/footer-bitcoin.svg";
import tetherIcon from "../images/payment/footer-tether.svg";

import beGambleAwareIcon from "../images/BeGambleAware.svg";
import age18Icon from "../images/18plusAge.svg";

const Footer: React.FC = () => {
  const [activeLang, setActiveLang] = useState<LocaleItem>({
    code: "ru",
  } as LocaleItem);
  const [showLangMenu, setShowLangMenu] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const localesList = useSelector((state: RootState) => state.user.localesList);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  // const country = useSelector(
  //   (state: RootState) => state.user.userInfo?.country?.code
  // );

  const isLoginPage = location.pathname.includes("login");
  const isRegisterPage = location.pathname.includes("register");
  const isProfilePage = location.pathname.includes("profile");
  const isPaymentsPage = location.pathname.includes("payments");
  const isPasswordPage = location.pathname.includes("password");
  const isAdminPanelPage = location.pathname.includes("admin-panel");

  // let footerClass = "footer-wrap";

  const getFooterClasses = (): string => {
    if (
      isLoginPage ||
      isRegisterPage ||
      isProfilePage ||
      isPaymentsPage ||
      isPasswordPage ||
      isAdminPanelPage
    ) {
      if (activeLang?.code === "fa") {
        return "footer-wrap rtl hidden";
      }
      return "footer-wrap hidden";
    }
    if (activeLang?.code === "fa") {
      return "footer-wrap rtl";
    }
    return "footer-wrap";
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (localeActive && localeActive !== activeLang) {
      setActiveLang(localeActive);
    }
  }, [localeActive, activeLang]);
  useEffect(() => {
    const localLang = localStorage.getItem("localeLang");
    if (!localeActive && localLang) {
      setActiveLang({ code: localLang } as LocaleItem);
    }
  }, [localeActive]);

  const changleLangHandler = (lang: LocaleItem): void => {
    setActiveLang(lang);
    i18n.changeLanguage(lang.code as string);
    localStorage.setItem("localeLang", lang.code);
    dispatch(setActiveLocal(lang));
    if (isLogged) updateUserData({ locale_id: lang.id });
  };
  const showLangMenuHandle = (): void => {
    setShowLangMenu(true);
  };
  const refMenuLanf = useDetectClickOutside({
    onTriggered: (): void => setShowLangMenu(false),
  });
  // let phoneNumber;
  // if (country === "RU") {
  //   phoneNumber = "+79651437286";
  // } else if (country === "KZ") {
  //   phoneNumber = "+77273506190";
  // } else {
  //   phoneNumber = "+79651437286";
  // }

  return (
    <div className={getFooterClasses()}>
      <div className="vendor-slider-wrap">
        <div className="container">
          <Swiper
            slidesPerView={window.innerWidth > 990 ? 8 : 4}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Navigation]}
            spaceBetween={15}
          >
            <SwiperSlide className="slide">
              <img src={vendor1} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor2} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor3} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor4} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor5} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor6} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor7} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor8} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor1} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor2} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor3} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor4} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor5} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor6} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor7} alt="vendor" />
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={vendor8} alt="vendor" />
            </SwiperSlide>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </div>
      <div className="footer-content">
        <div className="container">
          <div className="footer-content-row">
            <div
              className="footer-content-col"
              onClick={(): void => {
                if (location.pathname === "/") {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                } else {
                  navigate("/");
                }
              }}
            >
              <img src={logo} alt="" className="logo-img" />
              {/* <div className="footer-social-media">
                <p className="title">{t("Follow us")}</p>
                <div className="footer-social-icons">
                  <a
                    className="social-icons__icon"
                    href="https://vk.ru/boomcasinoru"
                  >
                    <img src={vkIcon} alt="Вконтакте" />
                  </a>
                  <a
                    className="social-icons__icon"
                    href="https://t.me/boomcasinoRu"
                  >
                    <img src={telegramIcon} alt="Telegram" />
                  </a>
                  <a
                    className="social-icons__icon"
                    href="https://www.instagram.com/boomcasinoru"
                  >
                    <img src={instagramIcon} alt="Instagram" />
                  </a>
                </div>
              </div> */}
            </div>
            <div className="footer-content-col">
              <div className="footer-boom-menu">
                <p className="title">BOOM CASINO</p>
              </div>
              <div className="footer-links">
                <NavLink to="/about-us">{t("About us")}</NavLink>
                <NavLink to="/rules">{t("Casino rules")}</NavLink>
                <NavLink to="/privacy">{t("Privacy Policy")}</NavLink>
                <NavLink to="/withdrawal-and-refund-policy">
                  {t("Withdrawal and refund policy")}
                </NavLink>
                {/* <NavLink to="/bonus-info">
                    {t("Bonuses and conditions")}
                  </NavLink>
                  <NavLink to="/withdrawal-and-refund-policy">
                    {t("Withdrawal and refund policy")}
                  </NavLink> */}
                <NavLink to="/faq">FAQ</NavLink>
              </div>
            </div>
            <div className="footer-content-col">
              <div className="footer-boom-menu">
                <p className="title">{t("Loyalty program")}</p>
              </div>
              <div className="footer-links">
                <NavLink to="/welcome-bonus">{t("Welcome bonus")}</NavLink>
                {/* <NavLink to="#">{t("Promo code and promotions")}</NavLink> */}
                <NavLink to="/bonus-info">
                  {t("Bonuses and conditions")}
                </NavLink>
                {/* <NavLink to="#">{t("VIP club")}</NavLink> */}
              </div>
            </div>
            <div className="footer-content-col">
              <div className="footer-boom-menu">
                <p className="title">{t("Tournaments")}</p>
              </div>
              <div className="footer-links">
                <NavLink to="/tournaments">{t("Current tournaments")}</NavLink>
                <NavLink to="/tournaments">{t("Upcoming tournaments")}</NavLink>
                <NavLink to="/tournaments">{t("Past tournaments")}</NavLink>
              </div>
            </div>
            <div className="footer-content-col">
              <div className="footer-boom-menu">
                <p className="title">{t("Contact us")}</p>
              </div>
              <div className="footer-contacts-link">
                {/* <a href={`tel:${phoneNumber}`} className="phone-link">
                  {phoneNumber}
                </a> */}
                <a href="mailto:support@boomcasino.bet">
                  support@boomcasino.bet
                </a>
                {/* <a href={`tel:${phoneNumber}`} className="phone-link">
                  {t("Telegram chat-bot")}
                </a> */}
              </div>
            </div>
            <div className="footer-content-col">
              <div className="footer-boom-menu">
                {/* <p className="title">{t("Casino")}</p> */}
              </div>
              <div className="footer-links">
                {/* <NavLink to="#">{t("Online casino")}</NavLink>
                <NavLink to="#">{t("Live games")}</NavLink>
                <NavLink to="#">{t("Roulette")}</NavLink>
                <NavLink to="#">{t("Slots")}</NavLink>
                <NavLink to="#">{t("Vendors")}</NavLink> */}
              </div>
            </div>
            <div
              className={showLangMenu ? "menu-lang opened" : "menu-lang"}
              ref={refMenuLanf}
              onClick={showLangMenuHandle}
            >
              <span>
                {activeLang.code === "kk"
                  ? "KZ"
                  : activeLang.code.toLocaleUpperCase()}
              </span>
              <img src={arrowIcon} alt="" />
              {showLangMenu && (
                <div className="lang-dropdown">
                  {localesList.map((el) => (
                    <span
                      className={el.code === activeLang.code ? "hidden" : ""}
                      key={el.id}
                      onClick={(): void => {
                        changleLangHandler(el);
                      }}
                    >
                      {el.code === "kk" ? "KZ" : el.code.toUpperCase()}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-content">
        <div className="bottom-container">
          <div className="footer-copy">
            <span>{`© 2023 "BOOM Casino". ${t("All rights reserved")}.`}</span>
            <br />
            <span>{`${t("Enjoy the game and big prizes!")}`}</span>
          </div>
          <div className="payment-systems">
            <img src={visaIcon} alt="Visa" />
            <img src={masterCardIcon} alt="Master-card" />
            <img src={moneygoIcon} alt="moneygo" />
            <img src={qiwiIcon} alt="qiwi" />
            <img src={bitcoinIcon} alt="bitcoin" />
            <img src={tetherIcon} alt="tether" />
          </div>
        </div>
      </div>
      <div className="aware-block">
        <img src={age18Icon} alt="age-18" />
        <img src={beGambleAwareIcon} alt="gamble-aware" />
      </div>
    </div>
  );
};

export default Footer;
