/* eslint-disable */
import React, { useEffect } from "react";

declare global {
  interface Window {
    fbq: any;
    _fbq: any;
  }
}

const FacebookPixel: React.FC= () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const customParam = queryParams.get('suda');

    if (customParam && window.location.host === 'boomcasino.bet') {
      (function (f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s?.parentNode?.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js', undefined, undefined, undefined);

      window.fbq('init', customParam);
      window.fbq('track', 'PageView');
    }
  }, []);

  return null;
};


export default FacebookPixel;

