import React, { useEffect, useState } from "react";
import {
  Table,
  Dropdown,
  Button,
  Form,
  Accordion,
  Row,
  Col,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDetectClickOutside } from "react-detect-click-outside";
import {
  formatDateDay,
  formatDateSeconds,
  formatDateDayApi,
} from "../../helpers/getCorrectDate";
import { getUsersList, editUser } from "../../services/admin/apiUsers";
import { AdminPanelTabs } from "../../types/adminTypes";
import { UserInfo, UserRoles, CountryItem } from "../../types/userTypes";
import type { RootState } from "../../store/store";
import { showErrorNotif } from "../../helpers/renderNotification";
import { getCountries } from "../../services/apiLogin";

import UserInfoModal from "./modal/UserInfoModal";
import UserDeleteModal from "./modal/UserDeleteModal";
import UserBalanceModal from "./modal/UserBalanceModal";
import UserBetsModal from "./modal/UserBetsModal";
import UserFreeSpinsModal from "./modal/UserFreeSpinsModal";
import UserCommentsModal from "./modal/UserCommentsModal";
import UserBlockModal from "./modal/UserBlockModal";
import PhoneInputWrapper from "../forms/PhoneInputWrapper";
import UserPromoCodeModal from "./modal/UserPromoCodeModal";
import arrowIcon from "../../images/dropdown-arrow.svg";

enum UserModals {
  delete = "delete",
  balance = "balance",
  bets = "bets",
  spins = "spins",
  block = "block",
  comments = "comments",
  promo_codes = "promo_codes",
}

interface Props {
  activeTab: AdminPanelTabs;
}

const UsersTab: React.FC<Props> = ({ activeTab }) => {
  //   Countries data
  const [countriesList, setCountriesList] = useState<CountryItem[]>([]);
  const [hasMoreCountry, setHasMoreCountry] = useState<boolean>(true);
  const [activeCountryPage, setActiveCountryPage] = useState<number>(1);
  const [showDrodown, setShowDropdown] = useState<boolean>(false);
  // Filters data
  const [registerDate, setRegisterDate] = useState<Date>();
  const [birthDate, setBirthDate] = useState<Date>();
  const [userId, setUserId] = useState<string>("");
  const [accountId, setAccountId] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [country, setCountry] = useState<CountryItem>({} as CountryItem);
  const [role, setRole] = useState<UserRoles>(UserRoles.player);
  const [vipLevel, setVipLevel] = useState<number>(0);
  const [phoneValue, setPhoneValue] = useState<string>("");
  // Users data
  const [users, setUsers] = useState<UserInfo[]>();
  const [activeUser, setActiveUser] = useState<UserInfo>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  //   Modals conditions
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showBalanceModal, setShowBalanceModal] = useState<boolean>(false);
  const [showBetsModal, setShowBetsModal] = useState<boolean>(false);
  const [showFreeSpinsModal, setShowFreeSpinsModal] = useState<boolean>(false);
  const [showBlockModal, setShowBlockModal] = useState<boolean>(false);
  const [showCommentsModal, setShowCommentsModal] = useState<boolean>(false);
  const [promoCodesModal, setShowPromoCodesModal] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);

  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const { t } = useTranslation();

  //   Users logic
  const loadUsers = async (
    page: number,
    searchValue?: string
  ): Promise<void> => {
    setFetching(true);
    const result = await getUsersList(page + 1, searchValue || "");
    if (typeof result !== "string") {
      setUsers(result.data as UserInfo[]);
      setTotalPages(result.meta.last_page as number);
    } else {
      showErrorNotif(result as string);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!users && activeTab === AdminPanelTabs.users) {
      loadUsers(0);
    }
    // eslint-disable-next-line
  }, [activeTab, users]);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadUsers(e);
  };

  //   Country logic
  const getCountriesHandle = async (page: number): Promise<void> => {
    const result = await getCountries(page);
    if (typeof result !== "string") {
      setCountriesList([...countriesList, ...(result.data as CountryItem[])]);
      if (result.data.length < 350) {
        setHasMoreCountry(false);
      }
    }
  };
  const handleScroll = (element: HTMLDivElement): void => {
    if (
      element.scrollHeight - element.scrollTop <= element.clientHeight + 5 &&
      hasMoreCountry
    ) {
      setActiveCountryPage(activeCountryPage + 1);
      getCountriesHandle(activeCountryPage + 1);
    }
  };
  const showDropdownHandler = (): void => {
    setShowDropdown(true);
  };
  const hideDropdownHandler = (): void => {
    setShowDropdown(false);
  };
  const ref = useDetectClickOutside({
    onTriggered: hideDropdownHandler,
  });
  useEffect(() => {
    getCountriesHandle(1);
    // eslint-disable-next-line
  }, []);

  //   Modals logic
  const handleOpenModal = (type: UserModals, user: UserInfo): void => {
    setActiveUser(user);
    switch (type) {
      case UserModals.delete:
        setShowDeleteModal(true);
        break;
      case UserModals.balance:
        setShowBalanceModal(true);
        break;
      case UserModals.bets:
        setShowBetsModal(true);
        break;
      case UserModals.spins:
        setShowFreeSpinsModal(true);
        break;
      case UserModals.block:
        setShowBlockModal(true);
        break;
      case UserModals.comments:
        setShowCommentsModal(true);
        break;
      case UserModals.promo_codes:
        setShowPromoCodesModal(true);
        break;

      default:
        break;
    }
  };
  const handleClosenModal = (type: UserModals): void => {
    setActiveUser(undefined);
    switch (type) {
      case UserModals.delete:
        setShowDeleteModal(false);
        break;
      case UserModals.balance:
        setShowBalanceModal(false);
        loadUsers(activePage);
        break;
      case UserModals.bets:
        setShowBetsModal(false);
        break;
      case UserModals.spins:
        setShowFreeSpinsModal(false);
        break;
      case UserModals.block:
        setShowBlockModal(false);
        break;
      case UserModals.comments:
        setShowCommentsModal(false);
        break;
      case UserModals.promo_codes:
        setShowPromoCodesModal(false);
        break;

      default:
        break;
    }
  };
  const handleInfoSwow = (): void => {
    setShowInfoModal(true);
  };
  const handleInfoHide = (): void => {
    setShowInfoModal(false);
    setIsEditModal(false);
    setActiveUser(undefined);
  };
  const handleUserAdded = (): void => {
    setShowInfoModal(false);
    handlePaginationChange(0);
    setIsEditModal(false);
    setActiveUser(undefined);
  };
  const handleUserDeleted = (): void => {
    setShowDeleteModal(false);
    handlePaginationChange(0);
  };
  const handleUserBlocked = (): void => {
    setShowBlockModal(false);
    loadUsers(activePage, search || undefined);
    setActiveUser(undefined);
  };
  const handleUnblock = async (id: number): Promise<void> => {
    await editUser({ is_blocked: 0 }, id);
    loadUsers(activePage, search || undefined);
  };
  const handleBlockDeposit = async (id: number): Promise<void> => {
    await editUser({ is_deposit_blocked: 1 }, id);
    loadUsers(activePage, search || undefined);
  };
  const handleUnblockDeposit = async (id: number): Promise<void> => {
    await editUser({ is_deposit_blocked: 0 }, id);
    loadUsers(activePage, search || undefined);
  };
  const handleUnblockVerification = async (id: number): Promise<void> => {
    await editUser({ withdrawal_super_rule: 0 }, id);
    loadUsers(activePage, search || undefined);
  };
  const handleBlockVerification = async (id: number): Promise<void> => {
    await editUser({ withdrawal_super_rule: 1 }, id);
    loadUsers(activePage, search || undefined);
  };

  // Search logic
  // const handleSearch = (): void => {
  //   setActivePage(0);
  //   loadUsers(0, `&${searchType}=${search}`);
  // };
  // const handleClearSearch = (): void => {
  //   setActivePage(0);
  //   setSearchType("first_name");
  //   setSearch("");
  //   loadUsers(0);
  // };
  const handleClearSearch = (): void => {
    setAccountId("");
    setUserId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setCountry({} as CountryItem);
    setBirthDate(undefined);
    setRegisterDate(undefined);
    setRole(UserRoles.player);
    setVipLevel(0);
    setPhoneValue("");
    setSearch("");
    setActivePage(0);
    loadUsers(0);
  };
  const handleSearch = (): void => {
    const searchValue = `${accountId ? `&public_id=${accountId}` : ""}${
      userId ? `&id=${userId}` : ""
    }${firstName ? `&first_name=${firstName}` : ""}${
      lastName ? `&last_name=${lastName}` : ""
    }${email ? `&email=${email}` : ""}${
      country.code ? `&country_code=${country.code}` : ""
    }${role ? `&role=${role}` : ""}${
      birthDate ? `&date_of_birth=${formatDateDayApi(birthDate)}` : ""
    }${registerDate ? `&created_at=${formatDateDayApi(registerDate)}` : ""}${
      vipLevel ? `&vip_club_level=${vipLevel}` : ""
    }${phoneValue ? `&phone=${phoneValue}` : ""}`;
    setActivePage(0);
    setSearch(searchValue);
    loadUsers(0, searchValue);
  };

  const renderLevel = (value?: number): string => {
    if (value === 1) {
      return "Junior";
    }
    if (value === 2) {
      return "Master";
    }
    if (value === 3) {
      return "Favorite";
    }
    if (value === 4) {
      return "Pro";
    }
    if (value === 5) {
      return "Champion";
    }
    return "None";
  };

  return (
    <div className="admin-users-warp admin-tab-warp">
      <h3>{t("Users")}</h3>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      <Button
        variant="primary"
        className="mb-3"
        onClick={handleInfoSwow}
        disabled={userRole !== UserRoles.admin}
      >
        + {t("Add new user")}
      </Button>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>{t("Filters")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <div className="admin-filters-wrap mb-3">
              <Row>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ID"
                      value={userId}
                      onChange={(e): void => {
                        setUserId(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Account ID")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Account ID") || "Account ID"}
                      value={accountId}
                      onChange={(e): void => {
                        setAccountId(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("First name")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("First name") || "First name"}
                      value={firstName}
                      onChange={(e): void => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Last name")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Last name") || "Last name"}
                      value={lastName}
                      onChange={(e): void => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Email address")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Email address") || "Email address"}
                      value={email}
                      onChange={(e): void => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Date of birth")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setBirthDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      selected={birthDate}
                      dropdownMode="select"
                      placeholderText={t("Date of birth") || "Date of birth"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Registration date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setRegisterDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      selected={registerDate}
                      dropdownMode="select"
                      placeholderText={
                        t("Registration date") || "Registration date"
                      }
                      className="form-control"
                    />
                  </Form.Group>
                </Col>

                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Country")}</Form.Label>
                    <div className="custom-select-wrap" ref={ref}>
                      <div
                        className="custom-select-item"
                        onClick={showDropdownHandler}
                      >
                        {country?.name || <span>{t("Select country")}</span>}
                        <img src={arrowIcon} alt="" />
                      </div>
                      {showDrodown && (
                        <div
                          className="custom-select-dropdown"
                          onScroll={(e): void => {
                            handleScroll(e.target as HTMLDivElement);
                          }}
                        >
                          {countriesList.map((el) => (
                            <span
                              key={el.id}
                              onClick={(): void => {
                                setCountry(el);
                                hideDropdownHandler();
                              }}
                            >
                              {el.name}
                            </span>
                          ))}
                          {hasMoreCountry && (
                            <CirclesWithBar
                              height="50"
                              width="50"
                              color="#0d6efd"
                              wrapperStyle={{ justifyContent: "center" }}
                              visible
                              ariaLabel="circles-with-bar-loading"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Role")}</Form.Label>
                    <Form.Select
                      aria-label={t("Role") || "Role"}
                      value={role}
                      onChange={(e): void => {
                        setRole(e.target.value as UserRoles);
                      }}
                    >
                      <option value={UserRoles.admin}>
                        {t(UserRoles.admin)}
                      </option>
                      <option value={UserRoles.player}>
                        {t(UserRoles.player)}
                      </option>
                      <option value={UserRoles["retention-manager"]}>
                        {t(UserRoles["retention-manager"])}
                      </option>
                      <option value={UserRoles.operator}>
                        {t(UserRoles.operator)}
                      </option>
                      <option value={UserRoles["senior-operator"]}>
                        {t(UserRoles["senior-operator"])}
                      </option>
                      <option value={UserRoles.trainee}>
                        {t(UserRoles.trainee)}
                      </option>
                      <option value={UserRoles["payment-manager"]}>
                        {t(UserRoles["payment-manager"])}
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>Vip Club</Form.Label>
                    <Form.Select
                      aria-label="Vip Club"
                      value={vipLevel}
                      onChange={(e): void => {
                        setVipLevel(parseInt(e.target.value as string, 10));
                      }}
                    >
                      <option value="0">None</option>
                      <option value="1">Junior</option>
                      <option value="2">Master</option>
                      <option value="3">Favorite</option>
                      <option value="4">Pro</option>
                      <option value="5">Champion</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Group>
                    <Form.Label>{t("Phone")}</Form.Label>
                    <PhoneInputWrapper
                      containerClass="phone-input"
                      value={phoneValue}
                      onChange={(e): void => {
                        setPhoneValue(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="admin-search-btns">
                    <Button
                      variant="danger"
                      onClick={handleClearSearch}
                      disabled={isFetching}
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isFetching}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {users && !isFetching && (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Public user ID</th>
              <th>{t("Info")}</th>
              <th>{t("Date of birth")}</th>
              <th>{t("Registration date")}</th>
              <th>{t("Blocked")}</th>
              <th>{t("Password reset")}</th>
              <th>IP</th>
              <th>{t("Device info")}</th>
              <th>{t("Withdrawal conditions")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.integer_public_id}</td>
                <td>
                  <div className="info-row">
                    <span>{t("Name")}: </span>
                    <span>
                      {el.first_name} {el.last_name}
                    </span>
                  </div>
                  <div className="info-row">
                    <span>{t("Email address")}: </span>
                    <span>{el.email}</span>
                  </div>
                  <div className="info-row">
                    <span>{t("Phone")}: </span>
                    <span>{el.phone}</span>
                  </div>
                  <div className="info-row">
                    <span>{t("Country")}: </span>
                    <span>{el?.country?.name as string}</span>
                  </div>
                  <div className="info-row">
                    <span>{t("City")}: </span>
                    <span>{el.city}</span>
                  </div>
                  <div className="info-row">
                    <span>{t("Gender")}: </span>
                    <span>{el.gender}</span>
                  </div>
                  <div className="info-row">
                    <span>VIP Level: </span>
                    <span>{renderLevel(el.vip_club?.level)}</span>
                  </div>
                </td>
                <td>
                  {el.date_of_birth
                    ? formatDateDay(el.date_of_birth as string)
                    : ""}
                </td>
                <td>
                  {el.created_at ? formatDateDay(el.created_at as string) : ""}
                </td>
                <td>{el.is_blocked ? "+" : "-"}</td>
                <td>
                  {el.password_reset
                    ? formatDateSeconds(el.password_reset as string)
                    : ""}
                </td>
                <td>{el.ip || ""}</td>
                <td>{el.device_info || ""}</td>
                <td className="withdrawal-errors">
                  {el.withdrawal_super_rule && t("Verification is disabled")}
                  {el.withdrawal_errors && !el.withdrawal_super_rule ? (
                    <>
                      <span
                        className={
                          !el.withdrawal_errors.card_is_not_approved
                            ? "approved"
                            : "not-approved"
                        }
                      >{`${t("Card approved")} ${
                        !el.withdrawal_errors.card_is_not_approved ? "+" : "-"
                      }`}</span>
                      <span
                        className={
                          !el.withdrawal_errors.cards_are_not_equal
                            ? "approved"
                            : "not-approved"
                        }
                      >{`${t("Has approved deposit")} ${
                        !el.withdrawal_errors.cards_are_not_equal ? "+" : "-"
                      }`}</span>
                      <span
                        className={
                          !el.withdrawal_errors.deposit_blocked
                            ? "approved"
                            : "not-approved"
                        }
                      >{`${t("Deposit is not blocked")} ${
                        !el.withdrawal_errors.deposit_blocked ? "+" : "-"
                      }`}</span>
                      <span
                        className={
                          !el.withdrawal_errors.unconfirmed_withdrawal
                            ? "approved"
                            : "not-approved"
                        }
                      >{`${t("Has not pending withdrawal")} ${
                        !el.withdrawal_errors.unconfirmed_withdrawal ? "+" : "-"
                      }`}</span>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <div className="admin-actions-wrap">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        disabled={
                          userRole === UserRoles.trainee ||
                          userRole === UserRoles["retention-manager"]
                        }
                      >
                        {t("Actions")}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(): void => {
                            handleInfoSwow();
                            setIsEditModal(true);
                            setActiveUser(el);
                          }}
                        >
                          {t("Info")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(): void => {
                            handleOpenModal(UserModals.comments, el);
                          }}
                        >
                          {t("Comments")}
                        </Dropdown.Item>
                        {(userRole === UserRoles.admin ||
                          userRole === UserRoles.operator ||
                          userRole === UserRoles["payment-manager"] ||
                          userRole === UserRoles["senior-operator"]) && (
                          <Dropdown.Item
                            onClick={(): void => {
                              handleOpenModal(UserModals.balance, el);
                            }}
                          >
                            {t("Balance")}
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={(): void => {
                            handleOpenModal(UserModals.bets, el);
                          }}
                        >
                          {t("Bets")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(): void => {
                            handleOpenModal(UserModals.spins, el);
                          }}
                        >
                          {t("Free spins")}
                        </Dropdown.Item>
                        {(userRole === UserRoles.admin ||
                          userRole === UserRoles["senior-operator"] ||
                          userRole === UserRoles["payment-manager"]) && (
                          <>
                            {el.is_blocked ? (
                              <Dropdown.Item
                                onClick={(): void => {
                                  handleUnblock(el.id);
                                }}
                              >
                                {t("Unblock")}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={(): void => {
                                  handleOpenModal(UserModals.block, el);
                                }}
                              >
                                {t("Block")}
                              </Dropdown.Item>
                            )}
                            {el.is_deposit_blocked ? (
                              <Dropdown.Item
                                onClick={(): void => {
                                  handleUnblockDeposit(el.id);
                                }}
                              >
                                {t("Unblock deposit")}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={(): void => {
                                  handleBlockDeposit(el.id);
                                }}
                              >
                                {t("Block deposit")}
                              </Dropdown.Item>
                            )}

                            {el.withdrawal_super_rule ? (
                              <Dropdown.Item
                                onClick={(): void => {
                                  handleUnblockVerification(el.id);
                                }}
                              >
                                {t("Enable withdrawal verification")}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={(): void => {
                                  handleBlockVerification(el.id);
                                }}
                              >
                                {t("Disable withdrawal verification")}
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                        {userRole === UserRoles.admin && (
                          <Dropdown.Item
                            onClick={(): void => {
                              handleOpenModal(UserModals.delete, el);
                            }}
                          >
                            {t("Delete")}
                          </Dropdown.Item>
                        )}
                        {(userRole === UserRoles.operator ||
                          userRole === UserRoles["senior-operator"] ||
                          userRole === UserRoles.admin) && (
                          <Dropdown.Item
                            onClick={(): void => {
                              handleOpenModal(UserModals.promo_codes, el);
                            }}
                          >
                            {t("Activation of promo codes")}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      {showInfoModal && (
        <UserInfoModal
          show={showInfoModal}
          handleClose={handleInfoHide}
          handleUserAdded={handleUserAdded}
          isEdit={isEditModal}
          user={activeUser}
        />
      )}
      {showDeleteModal && (
        <UserDeleteModal
          show={showDeleteModal}
          handleClose={(): void => {
            handleClosenModal(UserModals.delete);
          }}
          handleUserDeleted={handleUserDeleted}
          id={activeUser?.id || 0}
          name={`${activeUser?.first_name || ""} ${
            activeUser?.last_name || ""
          }`}
        />
      )}
      {showBalanceModal && (
        <UserBalanceModal
          show={showBalanceModal}
          handleClose={(): void => {
            handleClosenModal(UserModals.balance);
          }}
          id={activeUser?.id || 0}
          name={`${activeUser?.first_name || ""} ${
            activeUser?.last_name || ""
          }`}
        />
      )}
      {showBetsModal && (
        <UserBetsModal
          show={showBetsModal}
          handleClose={(): void => {
            handleClosenModal(UserModals.bets);
          }}
          id={activeUser?.id || 0}
          name={`${activeUser?.first_name || ""} ${
            activeUser?.last_name || ""
          }`}
        />
      )}
      {showFreeSpinsModal && (
        <UserFreeSpinsModal
          show={showFreeSpinsModal}
          handleClose={(): void => {
            handleClosenModal(UserModals.spins);
          }}
          id={activeUser?.id || 0}
          name={`${activeUser?.first_name || ""} ${
            activeUser?.last_name || ""
          }`}
        />
      )}
      {showBlockModal && (
        <UserBlockModal
          show={showBlockModal}
          handleClose={(): void => {
            handleClosenModal(UserModals.block);
          }}
          user={activeUser}
          handleUserBlocked={handleUserBlocked}
        />
      )}
      {showCommentsModal && (
        <UserCommentsModal
          show={showCommentsModal}
          handleClose={(): void => {
            handleClosenModal(UserModals.comments);
          }}
          name={`${activeUser?.first_name as string} ${
            activeUser?.last_name as string
          }`}
          id={activeUser?.id || 0}
        />
      )}
      {promoCodesModal && (
        <UserPromoCodeModal
          show={promoCodesModal}
          handleClose={(): void => {
            handleClosenModal(UserModals.promo_codes);
          }}
          // name={`${activeUser?.first_name as string} ${
          //   activeUser?.last_name as string
          // }`}
          id={activeUser?.id || 0}
        />
      )}
    </div>
  );
};

export default UsersTab;
