import { t } from "i18next";
import { Validation } from "./type";

export type MinMaxLimitType = {
  min: number;
  max: number;
};

const validateMinMaxLimit = (
  limit: MinMaxLimitType | null,
  value: string
): Validation => {
  const isValid = true;
  const message = "";
  if (limit === null) {
    return {
      isValid,
      message,
    };
  }
  if (value !== "" && limit.min > Number(value)) {
    return {
      isValid: false,
      message: t("Minimum value", { limit }),
    };
  }
  if (value !== "" && limit.max < Number(value)) {
    return {
      isValid: false,
      message: t("Maximum value", { limit }),
    };
  }
  if (value === "") {
    return {
      isValid: true,
      message: "",
    };
  }
  return {
    isValid,
    message,
  };
};

export default validateMinMaxLimit;
