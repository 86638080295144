import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import styles from "./WinnersBoxStyles.module.scss";
import SliderWinners from "./SliderWinners/SliderWinners";

const WinnersBox: React.FC = () => {
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  const { t } = useTranslation();
  return (
    <div
      className={`${styles.winners_box} ${
        localeActive?.code === "fa" ? styles.rtl : ""
      }`}
    >
      <div className={styles.title}>{t("Recent winners")}</div>
      <SliderWinners />
    </div>
  );
};

export default WinnersBox;
