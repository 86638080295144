import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import AdminDocInputs from "./AdminDocInputs";
import { DocumentItem, DocumentTypes } from "../../../types/documentsTypes";
import styles from "./AdminCreditCardInputsStyles.module.scss";
import MainButton from "../../UI/MainButton/MainButton";
import { UserRoles } from "../../../types/userTypes";

interface CreditCardInputsProps {
  withdrawalCard: string;
  withdrawalYY: string;
  withdrawalMM: string;
  setWithdrawalCard: (value: string) => void;
  setWithdrawalMM: (value: string) => void;
  setWithdrawalYY: (value: string) => void;
  sendCreditCard: () => void;
  handleSelectImg: (e: File | undefined, type: string) => void;
  cardImg: File | undefined;
  currentBankCard: DocumentItem;
}

const CreditCardInputs: React.FC<CreditCardInputsProps> = ({
  withdrawalCard,
  withdrawalMM,
  withdrawalYY,
  setWithdrawalCard,
  setWithdrawalMM,
  setWithdrawalYY,
  sendCreditCard,
  handleSelectImg,
  currentBankCard,
  cardImg,
}) => {
  const { t } = useTranslation();
  const isBtnDisable = (): boolean => {
    return (
      !withdrawalCard.match(/^.{16}$/) ||
      !withdrawalMM.match(/^(0[1-9]|1[0-2])$/) ||
      !withdrawalYY.match(/^.{2}$|^.{4}$/) ||
      (!cardImg && !currentBankCard?.document_path)
    );
  };

  return (
    <div className={styles.documents_bank_wrap}>
      <div className={styles.row}>
        <div className={styles.form}>
          <input
            type="number"
            className={classNames(styles.main_input, styles.card_number_input)}
            value={withdrawalCard}
            readOnly={
              !UserRoles.admin &&
              !UserRoles["senior-operator"] &&
              !UserRoles["payment-manager"]
            }
            onChange={(e): void => {
              setWithdrawalCard(e.target.value);
            }}
            placeholder={t("Card number") || "Card number"}
          />
          <div className={styles.card_date_inputs}>
            <input
              type="number"
              className={styles.main_input}
              value={withdrawalMM}
              readOnly={
                !UserRoles.admin &&
                !UserRoles["senior-operator"] &&
                !UserRoles["payment-manager"]
              }
              onChange={(e): void => {
                setWithdrawalMM(e.target.value);
              }}
              placeholder={t("Month") || "Month"}
            />
            <input
              type="number"
              className={styles.main_input}
              value={withdrawalYY}
              readOnly={
                !UserRoles.admin &&
                !UserRoles["senior-operator"] &&
                !UserRoles["payment-manager"]
              }
              onChange={(e): void => {
                setWithdrawalYY(e.target.value);
              }}
              placeholder={t("Year") || "Year"}
            />
          </div>
        </div>
        <AdminDocInputs
          type={DocumentTypes.credit_card}
          size="small"
          documentPath={currentBankCard.document_path}
          previewItem={cardImg}
          handleSelect={handleSelectImg}
        />
      </div>
      {(UserRoles.admin ||
        UserRoles["senior-operator"] ||
        UserRoles["payment-manager"]) && (
        <MainButton
          title={t("Confirm")}
          onClick={sendCreditCard}
          disabled={isBtnDisable()}
          className={classNames(styles.load_card_btn)}
          variant="contained"
          color="primary"
        />
      )}
    </div>
  );
};

export default CreditCardInputs;
