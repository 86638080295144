import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import bg from "../../../images/banners/welcome-bonus-banner.png";
import styles from "./WeclomeBonusBannerStyle.module.scss";
import getValueWinWelcomeBonus from "../../../helpers/getValueWinWelcomeBonus";
import { RootState } from "../../../store/store";

interface Props {
  isBannerMenu?: boolean;
}

const WelcomeBonusBanner: React.FC<Props> = ({ isBannerMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const typeCurrency = useSelector(
    (state: RootState) => state.user.userInfo.country?.code
  );
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  return (
    <div
      className={`${styles.banner_container} ${
        !isBannerMenu ? styles.page : ""
      }`}
    >
      <div
        className={`${styles.description_container} ${
          localeActive?.code === "fa" ? styles.rtl : ""
        }`}
      >
        <div className={styles.welcome_bonus_title}>Welcome Bonus</div>
        <div className={styles.welcome_bonus_money_gift}>
          {t(getValueWinWelcomeBonus(typeCurrency))}
        </div>
        <div className={styles.welcome_bonus_spins_gift}>
          + <span>50FS</span>
          {t("in Fortune Craft")}
        </div>
        <button
          type="button"
          className={styles.welcome_bonus_action}
          onClick={(): void => {
            navigate("/payments");
          }}
        >
          {t("Get")}
        </button>
      </div>
      <div className={styles.banner}>
        <img src={bg} alt="welcome-bonus" />
      </div>
    </div>
  );
};

export default WelcomeBonusBanner;
