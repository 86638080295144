import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GameItemType } from "../../types/gameTypes";

interface Props {
  title: string;
  played_amount: number;
  total_quantity: number;
  games: GameItemType[];
}

const MySpinItem: React.FC<Props> = ({
  title,
  games,
  played_amount,
  total_quantity,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="my-bonus-item freespin-item">
      <div className="title">{title}</div>
      <div className="wagering-amount">
        <span>{t("Wagering")}</span>
        <span>
          {played_amount} / {total_quantity}
        </span>
      </div>
      <div className="progress-bar">
        <div
          className="bar"
          style={{
            width: `${(100 * played_amount) / total_quantity}%`,
          }}
        />
      </div>
      <div className="games-row">
        {games.map((el) => (
          <div
            className="game-spin-item"
            key={el.id}
            onClick={(): void => {
              navigate(`/game/${el.id}`);
            }}
          >
            <img src={el.icons["380x380"]} alt="" />
            <span>{el.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MySpinItem;
