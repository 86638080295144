import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDetectClickOutside } from "react-detect-click-outside";
import { CirclesWithBar } from "react-loader-spinner";

import arrowIcon from "../../../../images/dropdown-arrow.svg";
import { getCashbacksList } from "../../../../services/admin/apiPromo";
import { CashbackItem } from "../../../../types/promoTypes";

interface SelectCashBackType {
  onChange: (value: CashbackItem | null) => void;
  value: CashbackItem | null;
  disabled?: boolean;
}

const SelectCashBack: React.FC<SelectCashBackType> = ({
  disabled = false,
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const [showCashbackDrodown, setShowCashbackDropdown] =
    useState<boolean>(false);
  const [activeCashbackPage, setActiveCashbackPage] = useState<number>(1);
  const [hasMoreCashbacks, setHasMoreCashbacks] = useState<boolean>(true);
  const [isCashbackFetching, setCashbackFetching] = useState<boolean>(false);
  const [cashbacksList, setCashbacksList] = useState<CashbackItem[]>([]);

  const loadCashbacksListHandler = async (page: number): Promise<void> => {
    setCashbackFetching(true);
    const result = await getCashbacksList(page);
    if (typeof result !== "string") {
      setCashbacksList([...cashbacksList, ...(result.data as CashbackItem[])]);
      if (result.data.length < 10) {
        setHasMoreCashbacks(false);
      }
    }
    setCashbackFetching(false);
  };

  const handleCashbackScroll = (element: HTMLDivElement): void => {
    if (
      element.scrollHeight - element.scrollTop - 10 <= element.clientHeight &&
      hasMoreCashbacks &&
      !isCashbackFetching
    ) {
      setActiveCashbackPage(activeCashbackPage + 1);
      loadCashbacksListHandler(activeCashbackPage + 1);
    }
  };
  const showDropdownCashbackHandler = (): void => {
    setShowCashbackDropdown(true);
  };
  const hideDropdownCashbackHandler = (): void => {
    setShowCashbackDropdown(false);
  };
  const refDropdownCashback = useDetectClickOutside({
    onTriggered: hideDropdownCashbackHandler,
  });

  useEffect(() => {
    loadCashbacksListHandler(1);
    // eslint-disable-next-line
  }, []);

  return (
    <Form.Group className="mb-3">
      <Form.Label>{t("Cashback")}</Form.Label>
      <div
        className={
          disabled ? "custom-select-wrap disabled" : "custom-select-wrap"
        }
        ref={refDropdownCashback}
      >
        <div
          className="custom-select-item"
          onClick={showDropdownCashbackHandler}
        >
          {value?.name || <span>{t("Select cashback")}</span>}
          <img src={arrowIcon} alt="" />
        </div>
        {showCashbackDrodown && (
          <div
            className="custom-select-dropdown"
            onScroll={(e): void => {
              handleCashbackScroll(e.target as HTMLDivElement);
            }}
          >
            <span
              onClick={(): void => {
                onChange(null);
                hideDropdownCashbackHandler();
              }}
            >
              none
            </span>
            {cashbacksList.map((el) => (
              <span
                key={el.id}
                onClick={(): void => {
                  onChange(el);
                  hideDropdownCashbackHandler();
                }}
              >
                {el.name}
              </span>
            ))}
            {hasMoreCashbacks && (
              <CirclesWithBar
                height="50"
                width="50"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
          </div>
        )}
      </div>
    </Form.Group>
  );
};

export default SelectCashBack;
