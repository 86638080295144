import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";
import { formatDateMinutes } from "../../../helpers/getCorrectDate";

import { getUserBets } from "../../../services/admin/apiUsers";
import { UserBetItem } from "../../../types/gameTypes";

interface Props {
  name: string;
  id: number;
  show: boolean;
  handleClose: () => void;
}

const UserBetsModal: React.FC<Props> = ({ show, handleClose, name, id }) => {
  const { t } = useTranslation();

  const [bets, setBets] = useState<UserBetItem[]>();
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isFetching, setFetching] = useState<boolean>(false);

  const loadUserBalance = async (page: number): Promise<void> => {
    setFetching(true);
    const result = await getUserBets(page + 1, id);
    if (typeof result !== "string") {
      setBets(result.data as UserBetItem[]);
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    loadUserBalance(0);
    // eslint-disable-next-line
  }, []);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadUserBalance(e);
  };

  return (
    <Modal show={show} onHide={handleClose} className="admin-bets-wrap">
      <Modal.Header closeButton>
        <Modal.Title>{t("User bets")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("Name")}: {name}
        </p>
        {isFetching && (
          <CirclesWithBar
            height="200"
            width="200"
            color="#0d6efd"
            wrapperStyle={{ justifyContent: "center" }}
            visible
            ariaLabel="circles-with-bar-loading"
          />
        )}
        {!isFetching && (
          <Table striped bordered className="mb-5" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Action ID</th>
                <th>TX ID</th>
                <th>{t("Action")}</th>
                <th>{t("Amount")}</th>
                <th>{t("Date")}</th>
                <th>{t("Game name")}</th>
                <th>{t("Game vendor")}</th>
                <th>{t("Game category")}</th>
              </tr>
            </thead>
            {bets && (
              <tbody>
                {bets.map((el) => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>{el.action_id}</td>
                    <td>{el.tx_id}</td>
                    <td>{el.action}</td>
                    <td>{el.amount}</td>
                    <td>
                      <td>{formatDateMinutes(el.processed_at as string)}</td>
                    </td>
                    <td>{el.game.title}</td>
                    <td>{el.game.producer}</td>
                    <td>{el.game.category}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        )}
        {totalPages > 1 && (
          <div className="pagination-wrap">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="<"
              forcePage={activePage}
              onPageChange={(e): void => {
                handlePaginationChange(e.selected);
              }}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UserBetsModal;
