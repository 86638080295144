import { Validation } from "./type";

const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validateEmail = (str: string): Validation => {
  const isValid = !regex.test(str);
  const message = "Invalid email";
  if (str === "") {
    return {
      isValid: false,
      message: "",
    };
  }
  return {
    isValid,
    message,
  };
};

export default validateEmail;
