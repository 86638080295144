import React, { useEffect } from "react";
import { t } from "i18next";
import WelcomeBonusBanner from "../../components/banners/WelcomeBonusBanner/WelcomeBonusBanner";
import styles from "./WelcomeBonusPageStyles.module.scss";
import WelcomeBonusDescription from "../../components/welcomeBonus/WelcomeBonusDiscription";

const WelcomeBonusPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className={styles.welcome_bonus_page_wrap}>
      <div className={styles.content_gradient} />
      <div className="content-page-box">
        <div className={styles.container}>
          <WelcomeBonusBanner />
          <div className={styles.greeting_container}>
            <p className={styles.greeting}>{t("Boom Casino welcomes you!")}</p>
            <p className={styles.greeting_description}>
              {t(
                // eslint-disable-next-line max-len
                `We are glad to see you in our online casino and we want to please you a unique offer from Boom Casino - Welcome Bonus, which fill the beginning of your gaming journey with bright emotions and big prizes!`
              )}
            </p>
          </div>
          <div className={styles.container_bonus}>
            <p className={styles.bonus_title}>{t("Bonus description")}</p>
            <p className={styles.bonus_description}>
              {t(
                // eslint-disable-next-line max-len
                "This welcome bonus offers you the opportunity to receive additional funds on your deposit, as well as additional free play in Fortune Craft game."
              )}
            </p>
          </div>
          <p className={styles.how_to_win}>{t("How to win?")}</p>
          <WelcomeBonusDescription />
          <p className={styles.attention}>
            {t(
              "Please note that these requirements and restrictions are standard and may vary depending on the rules and conditions online casino."
            )}
          </p>
          <p className={styles.activation_agreement}>
            {t(
              "Please make sure you read the full rules and bonus terms before activating it."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBonusPage;
