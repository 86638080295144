import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { formatDateSecondsApi } from "../../../helpers/getCorrectDate";

import {
  addNewBonus,
  editBonusInfo,
  getBonusesRewardList,
} from "../../../services/admin/apiBonuses";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";
import { BonusItem, BonusPayload } from "../../../types/bonusesTypes";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";

import MultiSelect from "../../MultiSelect";

interface Props {
  show: boolean;
  bonusForEdit?: BonusItem;
  handleClose?: () => void;
  handleBonusAdded?: () => void;
  handleBonusEdited?: () => void;
}

const AddBonusModal: React.FC<Props> = ({
  show,
  bonusForEdit,
  handleClose,
  handleBonusAdded,
  handleBonusEdited,
}) => {
  const { t } = useTranslation();

  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isRewardFetching, setRewardFetching] = useState<boolean>(false);
  const [isEditing, setEditing] = useState<boolean>(false);
  //   Rewards data
  const [bonusesRewardsList, setBonusesRewardsList] = useState<
    { name: string; id: number | string }[]
  >([]);
  const [selectedRewards, setSelectedRewards] = useState<
    { name: string; id: number | string }[]
  >([]);
  const [rewardsSearch, setRewardsSearch] = useState<string>("");
  const [rewardsPage, setRewardsPage] = useState<number>(1);
  const [hasMoreRewards, setHasMoreRewards] = useState<boolean>(true);
  //   Selects currency data
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>();
  //  Form values
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<string>("direct");
  const [globalError, setGlobalError] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [wageringDays, setWageringDays] = useState<string>("");
  const [wageringHours, setWageringHours] = useState<string>("");
  const [wageringAmount, setWageringAmount] = useState<string>("");
  const [countdownDays, setCountdownDays] = useState<string>("");
  const [countdownHours, setCountdownHours] = useState<string>("");
  const [countdownMinutes, setCountdownMinutes] = useState<string>("");
  const [imgFile, setImgFile] = useState<File>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [imgUrl, setImgUrl] = useState<string>("");
  const [maxBet, setMaxBet] = useState<string>("");

  //   Rewards logic
  const getRewardsHandle = async (
    search: string,
    page: number,
    clearRequest?: boolean
  ): Promise<void> => {
    setRewardFetching(true);
    const response = await getBonusesRewardList(page, search);
    if (typeof response !== "string") {
      const result: { name: string; id: number }[] = [];
      response.data.forEach((el) => {
        result.push({ name: el.title, id: el.id });
      });
      setBonusesRewardsList(
        clearRequest ? [...result] : [...bonusesRewardsList, ...result]
      );
      if (response.data.length < 10) {
        setHasMoreRewards(false);
      }
    }
    setRewardFetching(false);
  };
  useEffect(() => {
    getRewardsHandle(rewardsSearch, 1);
    if (bonusForEdit) {
      const selectedRewardsArray: { name: string; id: number }[] = [];
      setEditing(true);
      setTitle(bonusForEdit.title as string);
      setStartDate(moment(bonusForEdit.started_at as string).toDate());
      setEndDate(moment(bonusForEdit.finished_at as string).toDate());
      setWageringDays((bonusForEdit.wagering_days as number).toString());
      setWageringHours((bonusForEdit.wagering_hours as number).toString());
      setWageringAmount((bonusForEdit.wagering_amount as number).toString());
      setCountdownDays((bonusForEdit?.countdown_days as number)?.toString());
      setCountdownHours((bonusForEdit?.countdown_hours as number)?.toString());
      setCountdownMinutes(
        (bonusForEdit?.countdown_minutes as number)?.toString()
      );
      setMaxBet((bonusForEdit?.max_bet_amount as number)?.toString());
      setIsActive(!!bonusForEdit.is_active);
      if (bonusForEdit.image) {
        setImgUrl(bonusForEdit.image as string);
      }
      setType(bonusForEdit.type as string);
      bonusForEdit.campaigns.forEach((el) => {
        selectedRewardsArray.push({
          name: el.title,
          id: el.id,
        });
      });
      setSelectedRewards(selectedRewardsArray);
    }
    // eslint-disable-next-line
  }, []);
  const handleRewardsSearch = (e: string): void => {
    setBonusesRewardsList([]);
    setRewardsSearch(e);
    setRewardsPage(1);
    getRewardsHandle(e, 1, true);
  };
  const handleRewardsPaginate = (): void => {
    if (!isRewardFetching) {
      getRewardsHandle(rewardsSearch, rewardsPage + 1);
      setRewardsPage(rewardsPage + 1);
    }
  };
  const handleRewardsSelect = (e: {
    name: string;
    id: number | string;
  }): void => {
    setSelectedRewards([...selectedRewards, e]);
  };
  const handleRewardsClear = (e: number | string): void => {
    const newArray = selectedRewards.filter((el) => {
      return el.id !== e;
    });
    setSelectedRewards(newArray);
  };

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
      if (bonusForEdit && bonusForEdit.currency) {
        const item = response.data.filter(
          (el) => el.code === bonusForEdit.currency?.code
        )[0];
        setSelectedCurrency(item.id);
      }
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  //   Form logic
  const editBonusInfoHandler = async (): Promise<void> => {
    const rewardsIds: number[] = [];
    selectedRewards.forEach((el) => {
      rewardsIds.push(typeof el.id === "string" ? parseInt(el.id, 10) : el.id);
    });
    const payload: BonusPayload = {
      img: imgFile,
      type,
      started_at: formatDateSecondsApi(startDate as Date) || "",
      finished_at: formatDateSecondsApi(endDate as Date) || "",
      is_active: isActive ? 1 : 0,
      wagering_days: parseInt(wageringDays, 10),
      wagering_hours: parseInt(wageringHours, 10),
      wagering_amount: parseInt(wageringAmount, 10),
      campaigns_ids: rewardsIds,
      countdown_days: parseInt(countdownDays, 10),
      countdown_hours: parseInt(countdownHours, 10),
      countdown_minutes: parseInt(countdownMinutes, 10),
      max_bet_amount: parseInt(maxBet, 10),
      currency_id: selectedCurrency,
    };
    if (title !== bonusForEdit?.title) {
      payload.title = title;
    }
    const result = await editBonusInfo(
      (bonusForEdit?.id as number) || 0,
      payload
    );
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data edited successfully"));
      if (handleBonusEdited) handleBonusEdited();
    }
  };
  const addNewBonusHandler = async (): Promise<void> => {
    const rewardsIds: number[] = [];
    selectedRewards.forEach((el) => {
      rewardsIds.push(typeof el.id === "string" ? parseInt(el.id, 10) : el.id);
    });
    const result = await addNewBonus({
      img: imgFile,
      title,
      type,
      started_at: formatDateSecondsApi(startDate as Date) || "",
      finished_at: formatDateSecondsApi(endDate as Date) || "",
      is_active: isActive ? 1 : 0,
      wagering_days: parseInt(wageringDays, 10),
      wagering_hours: parseInt(wageringHours, 10),
      wagering_amount: parseInt(wageringAmount, 10),
      campaigns_ids: rewardsIds,
      countdown_days: parseInt(countdownDays, 10),
      countdown_hours: parseInt(countdownHours, 10),
      countdown_minutes: parseInt(countdownMinutes, 10),
      max_bet_amount: parseInt(maxBet, 10),
      currency_id: selectedCurrency,
    });
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data added successfully"));
      if (handleBonusAdded) handleBonusAdded();
    }
  };
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    if (isEditing) {
      await editBonusInfoHandler();
    } else {
      await addNewBonusHandler();
    }
    setFetching(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      data-testid="bonus-admin-modal"
      className="bonuses-admin-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? t("Edit bonus") : t("Add new bonus")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Title") || "Title"}
                value={title}
                data-testid="bonus-admin-title"
                onChange={(e): void => {
                  setTitle(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Type")}</Form.Label>
              <Form.Select
                aria-label={t("Type") || "Type"}
                value={type}
                data-testid="bonus-admin-type"
                onChange={(e): void => {
                  setType(e.target.value as string);
                }}
              >
                <option value="direct">direct</option>
                <option value="welcome">welcome</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Currency")}</Form.Label>
              <Form.Select
                aria-label={t("Currency") || "Currency"}
                value={selectedCurrency}
                onChange={(e): void => {
                  setSelectedCurrency(parseInt(e.target.value as string, 10));
                }}
              >
                {currencyList &&
                  currencyList.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.code}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Start date")}</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={(dateEvent: Date): void => {
                  setStartDate(dateEvent);
                }}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
                selected={startDate}
                dropdownMode="select"
                placeholderText={t("Select date") || "Select date"}
                className="form-control"
                data-testid="bonus-admin-start"
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("End date")}</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={(dateEvent: Date): void => {
                  setEndDate(dateEvent);
                }}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
                selected={endDate}
                dropdownMode="select"
                placeholderText={t("Select date") || "Select date"}
                className="form-control"
                data-testid="bonus-admin-end"
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Wagering days")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Wagering days") || "Wagering days"}
                value={wageringDays}
                data-testid="bonus-admin-wdays"
                onChange={(e): void => {
                  setWageringDays(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Wagering hours")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Wagering hours") || "Wagering hours"}
                value={wageringHours}
                data-testid="bonus-admin-whours"
                onChange={(e): void => {
                  setWageringHours(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Wagering coefficient")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={
                  t("Wagering coefficient") || "Wagering coefficient"
                }
                value={wageringAmount}
                data-testid="bonus-admin-wamount"
                onChange={(e): void => {
                  setWageringAmount(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Countdown days")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Countdown days") || "Countdown days"}
                value={countdownDays}
                data-testid="bonus-admin-cdays"
                onChange={(e): void => {
                  setCountdownDays(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Countdown hours")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Countdown hours") || "Countdown hours"}
                value={countdownHours}
                data-testid="bonus-admin-chours"
                onChange={(e): void => {
                  setCountdownHours(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Countdown minutes")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Countdown minutes") || "Countdown minutes"}
                value={countdownMinutes}
                data-testid="bonus-admin-cminutes"
                onChange={(e): void => {
                  setCountdownMinutes(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Max bet")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Max bet") || "Max bet"}
                value={maxBet}
                onChange={(e): void => {
                  setMaxBet(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Active")}</Form.Label>
              <Form.Check
                checked={isActive}
                data-testid="bonus-admin-active"
                onChange={(): void => {
                  setIsActive(!isActive);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Image")}</Form.Label>
              {imgUrl && (
                <div className="tournament-img-preview">
                  <img src={imgUrl} alt="" />
                </div>
              )}
              <Form.Control
                type="file"
                placeholder={t("Image") || "Image"}
                data-testid="bonus-admin-img"
                onChange={(e): void => {
                  setImgFile(
                    // @ts-ignore
                    e.target.files[0] as File
                  );
                  setImgUrl("");
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Rewards")}</Form.Label>
              <MultiSelect
                selectedItems={selectedRewards}
                searchedItems={bonusesRewardsList}
                hasMore={hasMoreRewards}
                searchValue={rewardsSearch}
                handleSearch={handleRewardsSearch}
                handleSelect={handleRewardsSelect}
                handleClear={handleRewardsClear}
                handlePaginate={handleRewardsPaginate}
                withoutSearch
              />
            </Form.Group>
          </Col>
        </Row>
        {globalError && <div className="error-msg">{globalError}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          data-testid="bonus-admin-modal-close"
        >
          {t("Close")}
        </Button>
        <Button
          variant="primary"
          onClick={(): void => {
            handleSubmit();
          }}
          disabled={isFetching}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBonusModal;
