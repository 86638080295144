import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  img: string;
  show: boolean;
  handleClose: () => void;
}

const DocumentShowModal: React.FC<Props> = ({ show, handleClose, img }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="document-preview-modal"
      data-testid="document-preview-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Document preview")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={img} alt="" />
      </Modal.Body>
    </Modal>
  );
};

export default DocumentShowModal;
