import React from "react";
import { useTranslation } from "react-i18next";

import closeIcon from "../../images/close-icon-new.svg";

interface Props {
  closeHandler: () => void;
  reason: string;
}

const UserBlockedModal: React.FC<Props> = ({ closeHandler, reason }) => {
  const { t } = useTranslation();
  return (
    <div className="default-modal blocked-modal">
      <div className="overlay" onClick={closeHandler} />
      <div className="modal-content">
        <div className="modal-close-btn" onClick={closeHandler}>
          <img src={closeIcon} alt="close" width={15} />
        </div>
        <div className="modal-title">
          {t("Your profile has been blocked due to:")} {reason}
        </div>
        <button
          className="primary-btn"
          type="button"
          onClick={(): void => {
            closeHandler();
          }}
        >
          {t("Confirm")}
        </button>
      </div>
    </div>
  );
};

export default UserBlockedModal;
