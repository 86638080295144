import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  formatDateMinutes,
  formatDateSecondsApi,
} from "../../helpers/getCorrectDate";

import type { RootState, AppDispatch } from "../../store/store";
import getAllBets from "../../services/admin/apiBets";
import { AdminPanelTabs, BalanceTypes } from "../../types/adminTypes";
import { UserBetItem } from "../../types/gameTypes";
import { getGamesCategoriesFunc } from "../../store/slices/games";

interface Props {
  activeTab: AdminPanelTabs;
}

const BetsTab: React.FC<Props> = ({ activeTab }) => {
  const [bets, setBets] = useState<UserBetItem[]>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");
  //   Filters
  const [action, setAction] = useState<string>("All");
  const [gameTitle, setGameTitle] = useState<string>("");
  const [gameProducer, setGameProducer] = useState<string>("");
  const [gameCategory, setGameCategory] = useState<string>("All");
  const [userFirstName, setUserFirstName] = useState<string>("");
  const [userLastName, setUserLastName] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [userPublicId, setUserPublicId] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [balanceType, setBalanceType] = useState<BalanceTypes | string>("All");

  const categories = useSelector((state: RootState) => state.games.categories);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!categories && activeTab === AdminPanelTabs.bets) {
      dispatch(getGamesCategoriesFunc());
    }
  }, [dispatch, categories, activeTab]);

  const loadBets = async (page: number, search?: string): Promise<void> => {
    setFetching(true);
    const result = await getAllBets(page + 1, search || "");
    if (typeof result !== "string") {
      setBets(result.data as UserBetItem[]);
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!bets && activeTab === AdminPanelTabs.bets) {
      loadBets(0);
    }
    // eslint-disable-next-line
  }, [activeTab, bets]);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadBets(e, searchString);
  };

  const handleClearSearch = (): void => {
    setAction("All");
    setGameProducer("");
    setGameCategory("");
    setUserFirstName("");
    setUserLastName("");
    setUserEmail("");
    setGameTitle("");
    setStartDate(undefined);
    setEndDate(undefined);
    setUserId("");
    setUserPublicId("");
    setSearchString("");
    setActivePage(0);
    loadBets(0);
    setBalanceType("All");
  };
  const handleSearch = (): void => {
    const searchValue = `${
      action && action !== "All" ? `&action=${action}` : ""
    }${gameTitle ? `&title=${gameTitle}` : ""}${
      gameProducer ? `&producer=${gameProducer}` : ""
    }${
      gameCategory && gameCategory !== "All" ? `&category=${gameCategory}` : ""
    }${userFirstName ? `&first_name=${userFirstName}` : ""}${
      userLastName ? `&last_name=${userLastName}` : ""
    }${userEmail ? `&email=${userEmail}` : ""}${
      userId ? `&user_id=${userId}` : ""
    }${userPublicId ? `&public_id=${userPublicId}` : ""}${
      startDate ? `&start_date=${formatDateSecondsApi(startDate)}` : ""
    }${endDate ? `&end_date=${formatDateSecondsApi(endDate)}` : ""}${
      balanceType && balanceType !== "All" ? `&balance_type=${balanceType}` : ""
    }`;
    setActivePage(0);
    setSearchString(searchValue);
    loadBets(0, searchValue);
  };

  return (
    <div className="admin-bets-warp admin-tab-warp">
      <h3>{t("Bets")}</h3>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>{t("Filters")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <div className="admin-filters-wrap mb-3">
              <Row>
                <Col md="4">
                  <h5>{t("Common")}:</h5>
                  <Form.Group>
                    <Form.Label>{t("Action")}</Form.Label>
                    <Form.Select
                      aria-label={t("Action") || "Action"}
                      value={action === "All" ? t("All") || "All" : action}
                      onChange={(e): void => {
                        setAction(e.target.value as string);
                      }}
                      disabled={isFetching}
                    >
                      <option value="All">{t("All")}</option>
                      <option value="bet">bet</option>
                      <option value="win">win</option>
                      <option value="rollback">rollback</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("Start date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setStartDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={startDate}
                      dropdownMode="select"
                      placeholderText={t("Start date") || "Start date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>{t("End date")}</Form.Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date): void => {
                        setEndDate(date);
                      }}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      selected={endDate}
                      dropdownMode="select"
                      placeholderText={t("End date") || "End date"}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>{t("Balance")}</Form.Label>
                    <Form.Select
                      aria-label={t("Balance") || "Balance"}
                      value={balanceType}
                      onChange={(e): void => {
                        setBalanceType(e.target.value as BalanceTypes);
                      }}
                      data-testid="bets-reporting-balance"
                    >
                      <option value="All">{t("All")}</option>
                      <option value={BalanceTypes.real}>
                        {BalanceTypes.real}
                      </option>
                      <option value={BalanceTypes.demo}>
                        {BalanceTypes.demo}
                      </option>
                      <option value={BalanceTypes.bonus}>
                        {BalanceTypes.bonus}
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <h5>{t("Games")}:</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Game name")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Game name") || "Game name"}
                      value={gameTitle}
                      onChange={(e): void => {
                        setGameTitle(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Game vendor")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Game vendor") || "Game vendor"}
                      value={gameProducer}
                      onChange={(e): void => {
                        setGameProducer(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Game category")}</Form.Label>
                    <Form.Select
                      aria-label={t("Game category") || "Game category"}
                      value={
                        gameCategory === "All"
                          ? t("All") || "All"
                          : gameCategory
                      }
                      onChange={(e): void => {
                        setGameCategory(e.target.value as string);
                      }}
                      disabled={isFetching}
                    >
                      <option value="All">{t("All")}</option>
                      {categories &&
                        categories.map((el) => (
                          <option key={el.id} value={el.name}>
                            {el.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <h5>{t("User")}:</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("First name")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("First name") || "First name"}
                      value={userFirstName}
                      onChange={(e): void => {
                        setUserFirstName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Last name")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Last name") || "Last name"}
                      value={userLastName}
                      onChange={(e): void => {
                        setUserLastName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Email address")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Email address") || "Email address"}
                      value={userEmail}
                      onChange={(e): void => {
                        setUserEmail(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ID"
                      value={userId}
                      onChange={(e): void => {
                        setUserId(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Account ID")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Account ID") || "Account ID"}
                      value={userPublicId}
                      onChange={(e): void => {
                        setUserPublicId(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="admin-search-btns">
                    <Button
                      variant="danger"
                      onClick={handleClearSearch}
                      disabled={isFetching}
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isFetching}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {!isFetching && (
        <Table striped bordered className="mb-5" responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Action ID</th>
              <th>TX ID</th>
              <th>{t("Action")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Date")}</th>
              <th>{t("Game")}</th>
              <th>{t("User")}</th>
            </tr>
          </thead>
          {bets && (
            <tbody>
              {bets.map((el) => (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{el.action_id}</td>
                  <td>{el.tx_id}</td>
                  <td>{el.action}</td>
                  <td>
                    {el.amount} {el.currency}
                  </td>
                  <td>{formatDateMinutes(el.processed_at as string)}</td>
                  <td>
                    <div className="admin-game-item">
                      <div>
                        <span>
                          <b>{t("Game name")}: </b>
                        </span>
                        <span>{el.game.title}</span>
                      </div>
                      <div>
                        <span>
                          <b>{t("Game vendor")}: </b>
                        </span>
                        <span>{el.game.producer}</span>
                      </div>
                      <div>
                        <span>
                          <b>{t("Game category")}: </b>
                        </span>
                        <span>{el.game.category}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="admin-game-item">
                      <div>
                        <span>
                          <b>ID: </b>
                        </span>
                        <span>{el.user.id}</span>
                      </div>
                      <div>
                        <span>
                          <b>{t("First name")}: </b>
                        </span>
                        <span>{el.user.first_name}</span>
                      </div>
                      <div>
                        <span>
                          <b>{t("Last name")}: </b>
                        </span>
                        <span>{el.user.last_name}</span>
                      </div>
                      <div>
                        <span>
                          <b>{t("Email address")}: </b>
                        </span>
                        <span>{el.user.email}</span>
                      </div>
                      <div>
                        <span>
                          <b>{t("Balance")}: </b>
                        </span>
                        <span>
                          {el?.balance_amount} {el.currency}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}
      {totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BetsTab;
