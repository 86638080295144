import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../images/logo.svg";
import cardImage from "../images/email-verification-img.png";

import telegramLogo from "../images/social-icons/telegram-logo-small.svg";
import vkLogo from "../images/social-icons/vk-logo-small.svg";
import instagramLogo from "../images/social-icons/instagram-logo-small.svg";

const EmailVerificationPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="email-verification-wrap">
      <div className="content-gradient" />
      <div className="content-page-box email-v-box">
        <div className="container">
          <p>
            Если картинка не отображается откройте письмо в{" "}
            <a href="/email-verification">браузере</a>
          </p>
          <div
            className="logo-img email-v-logo"
            onClick={(): void => {
              navigate("/");
            }}
          >
            <img src={logo} alt="logo" className="logo" />
          </div>
          <div className="email-v-card">
            <div
              className="email-card-img"
              onClick={(): void => {
                navigate("/");
              }}
            >
              <img src={cardImage} alt="logo" className="logo" />
            </div>
            <div className="email-v-content">
              <h4>Подтверждение адреса электронной почты</h4>
              <h4>
                Для подтверждения адреса электронной почты нажмите, <br />{" "}
                пожалуйста, на кнопку ниже.
              </h4>
              <button
                className="main-btn "
                type="button"
                onClick={(): void => {
                  navigate("/");
                }}
              >
                Подтвердить
              </button>
              <div className="social-icons">
                <a
                  className="social-icons__icon"
                  href="https://t.me/boomcasinoRu"
                >
                  <img src={telegramLogo} alt="Telegram" />
                </a>
                <a
                  className="social-icons__icon"
                  href="https://vk.ru/boomcasinoru"
                >
                  <img src={vkLogo} alt="ВКонтакте" />
                </a>
                <a
                  className="social-icons__icon"
                  href="https://www.instagram.com/boomcasinoru"
                >
                  <img src={instagramLogo} alt="Instagram" />
                </a>
              </div>

              <p className="email-v-footer">© 2023 All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
