import React, { useEffect, useState } from "react";
import { Table, Row, Col, Button, Form, Accordion } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";

import type { RootState, AppDispatch } from "../../store/store";
import {
  getAllGames,
  updateLobbyCondition,
  changeGameStatus,
} from "../../services/admin/apiGamesAdmin";
import { AdminPanelTabs } from "../../types/adminTypes";
import { GameItemType } from "../../types/gameTypes";
import { getGamesCategoriesFunc } from "../../store/slices/games";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../helpers/renderNotification";
import { UserRoles } from "../../types/userTypes";

interface Props {
  activeTab: AdminPanelTabs;
}

const DocumentsTab: React.FC<Props> = ({ activeTab }) => {
  const [games, setGames] = useState<GameItemType[]>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [lobbyFetchingId, setLobbyFetchingId] = useState<number>(0);
  const [statusFetchingId, setStatusFetchingId] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");
  //   Filters
  const [gameTitle, setGameTitle] = useState<string>("");
  const [gameProducer, setGameProducer] = useState<string>("");
  const [gameCategory, setGameCategory] = useState<string>("All");
  const [sortValue, setSortValue] = useState<string>("Default");
  const [sortString, setSortString] = useState<string>("");
  const [hasJackpot, setHasJackpot] = useState<boolean>(false);
  const [inLobby, setInLobby] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const categories = useSelector((state: RootState) => state.games.categories);
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  useEffect(() => {
    if (!categories && activeTab === AdminPanelTabs.games) {
      dispatch(getGamesCategoriesFunc());
    }
  }, [dispatch, categories, activeTab]);

  const loadGames = async (
    page: number,
    queryString?: string,
    sort?: string
  ): Promise<void> => {
    setFetching(true);
    const result = await getAllGames(page + 1, queryString, sort);
    if (typeof result !== "string") {
      setGames(result.data as GameItemType[]);
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!games && activeTab === AdminPanelTabs.games) {
      loadGames(0);
    }
    // eslint-disable-next-line
  }, [activeTab, games]);

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadGames(e, searchString, sortString);
  };

  const handleClearSearch = (): void => {
    setGameProducer("");
    setGameCategory("");
    setGameTitle("");
    setSearchString("");
    setActivePage(0);
    loadGames(0, "", sortString);
  };
  const handleSearch = (): void => {
    const searchValue = `${gameTitle ? `&title=${gameTitle}` : ""}${
      gameProducer ? `&producer=${gameProducer}` : ""
    }${
      gameCategory && gameCategory !== "All" ? `&category=${gameCategory}` : ""
    }${hasJackpot ? `&has_jackpot=1` : ""}${inLobby ? `&lobby=1` : ""}`;
    setActivePage(0);
    setSearchString(searchValue);
    loadGames(0, searchValue, sortString);
  };
  const handleSort = (e: string): void => {
    setSortValue(e);
    if (e === "Payout") {
      loadGames(0, searchString, "&payout=desc");
      setSortString("&payout=desc");
      return;
    }
    if (e === "Multiplier") {
      loadGames(0, searchString, "&multiplier=desc");
      setSortString("&multiplier=desc");
      return;
    }
    setSortString("");
    loadGames(0, searchString, "");
  };

  const handleUpdateLobby = async (
    id: number,
    lobby: number
  ): Promise<void> => {
    setLobbyFetchingId(id);
    const result = await updateLobbyCondition(id, lobby);
    if (typeof result === "string") {
      showErrorNotif(result as string);
    } else {
      const newArray: GameItemType[] = [];
      games?.forEach((el): void => {
        if (el.id === id) {
          newArray.push({ ...el, lobby });
        } else {
          newArray.push(el);
        }
      });
      setGames(newArray);
      showSuccessNotif(t("Data edited successfully"));
    }
    setLobbyFetchingId(0);
  };
  const handleUpdateStatus = async (
    id: number,
    is_active: number
  ): Promise<void> => {
    setStatusFetchingId(id);
    const result = await changeGameStatus(id, is_active);
    if (typeof result === "string") {
      showErrorNotif(result as string);
    } else {
      const newArray: GameItemType[] = [];
      games?.forEach((el): void => {
        if (el.id === id) {
          newArray.push({ ...el, is_active });
        } else {
          newArray.push(el);
        }
      });
      setGames(newArray);
      showSuccessNotif(t("Data edited successfully"));
    }
    setStatusFetchingId(0);
  };

  return (
    <div className="admin-games-wrap admin-tab-warp">
      <h3>{t("Games")}</h3>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
          data-testid="games-admin-loader"
        />
      )}
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>{t("Filters")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <div className="admin-filters-wrap mb-3">
              <Row>
                <Col md="3">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Game name")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Game name") || "Game name"}
                      value={gameTitle}
                      onChange={(e): void => {
                        setGameTitle(e.target.value);
                      }}
                      data-testid="games-admin-filter-name"
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Game vendor")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Game vendor") || "Game vendor"}
                      value={gameProducer}
                      onChange={(e): void => {
                        setGameProducer(e.target.value);
                      }}
                      data-testid="games-admin-filter-vendor"
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Game category")}</Form.Label>
                    <Form.Select
                      aria-label={t("Game category") || "Game category"}
                      value={
                        gameCategory === "All"
                          ? t("All") || "All"
                          : gameCategory
                      }
                      onChange={(e): void => {
                        setGameCategory(e.target.value as string);
                      }}
                      disabled={isFetching}
                      data-testid="games-admin-filter-category"
                    >
                      <option value="All">{t("All")}</option>
                      {categories &&
                        categories.map((el) => (
                          <option key={el.id} value={el.name}>
                            {el.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Has jackpot")}</Form.Label>
                    <Form.Check
                      checked={hasJackpot}
                      onChange={(): void => {
                        setHasJackpot(!hasJackpot);
                      }}
                      data-testid="games-admin-filter-jack"
                    />
                  </Form.Group>
                </Col>
                <Col md="1">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Lobby")}</Form.Label>
                    <Form.Check
                      checked={inLobby}
                      onChange={(): void => {
                        setInLobby(!inLobby);
                      }}
                      data-testid="games-admin-filter-jack"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="admin-search-btns">
                    <Button
                      variant="danger"
                      onClick={handleClearSearch}
                      disabled={isFetching}
                      data-testid="games-admin-clear-btn"
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSearch}
                      disabled={isFetching}
                      data-testid="games-admin-confirm-btn"
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </Col>
              </Row>
              <h4>{t("Sort")}</h4>
              <Row>
                <Col md="3">
                  <Form.Group className="mb-3">
                    <Form.Select
                      aria-label={t("Sort") || "Sort"}
                      value={sortValue}
                      onChange={(e): void => {
                        handleSort(e.target.value as string);
                      }}
                      disabled={isFetching}
                      data-testid="games-admin-sort"
                    >
                      <option value="Default">{t("Default")}</option>
                      <option value="Payout">{t("Payout")}</option>
                      <option value="Multiplier">{t("Multiplier")}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {!isFetching && (
        <Table striped bordered className="mb-5" responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("Title")}</th>
              <th>{t("Image")}</th>
              <th>{t("Description")}</th>
              <th>{t("Provider")}</th>
              <th>{t("Producer")}</th>
              <th>{t("Category")}</th>
              <th>{t("Has freespins")}</th>
              <th>{t("Has jackpot")}</th>
              <th>{t("Lobby")}</th>
              <th>{t("Active")}</th>
              <th>{t("Payout")}</th>
              <th>{t("Multiplier")}</th>
            </tr>
          </thead>
          {games && (
            <tbody data-testid="games-admin-table">
              {games.map((el) => (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{el.title}</td>
                  <td>
                    <img src={el.icons["380x380"] || ""} alt="" width={50} />
                  </td>
                  <td className="game-desc-cell">{el.description}</td>
                  <td>{el.provider}</td>
                  <td>{el.producer}</td>
                  <td>{el.category}</td>
                  <td>{el.has_freespins ? "+" : "-"}</td>
                  <td>{el.has_jackpot ? "+" : "-"}</td>
                  <td>
                    <Form.Check
                      checked={!!el.lobby}
                      onChange={(): void => {
                        handleUpdateLobby(el.id, el.lobby === 1 ? 0 : 1);
                      }}
                      disabled={
                        lobbyFetchingId === el.id ||
                        userRole !== UserRoles.admin
                      }
                    />
                  </td>
                  <td>
                    <Form.Check
                      checked={!!el.is_active}
                      onChange={(): void => {
                        handleUpdateStatus(el.id, el.is_active === 1 ? 0 : 1);
                      }}
                      disabled={
                        statusFetchingId === el.id ||
                        userRole !== UserRoles.admin
                      }
                    />
                  </td>
                  <td>{el.payout}</td>
                  <td>{el.multiplier}</td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}
      {totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DocumentsTab;
