import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import type { RootState, AppDispatch } from "../store/store";
import { getUserVipClub } from "../services/apiLogin";
import { setUserData } from "../store/slices/userData";

import level1 from "../images/vip-icons/level1.svg";
import level2 from "../images/vip-icons/level2.svg";
import level3 from "../images/vip-icons/level3.svg";
import level4 from "../images/vip-icons/level4.svg";
import level5 from "../images/vip-icons/level5.svg";

const VipClub: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const vipClub = useSelector(
    (state: RootState) => state.user.userInfo.vip_club
  );
  const userData = useSelector((state: RootState) => state.user.userInfo);
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  const loadClubInfo = async (): Promise<void> => {
    const result = await getUserVipClub();
    if (typeof result !== "string") {
      dispatch(
        setUserData({
          ...userData,
          vip_club: { ...userData.vip_club, ...result.data },
        })
      );
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isLogged) {
      loadClubInfo();
    }
    // eslint-disable-next-line
  }, [isLogged]);
  return (
    <div className="vip-club-page-wrap">
      <div
        className={
          localeActive?.code === "fa" ? "vip-club-box rtl" : "vip-club-box"
        }
      >
        <div className="container">
          <div className="vip-club-txt">{t("VipTop1")}</div>
          <div className="vip-club-txt">{t("VipTop2")}</div>
          <div className="vip-club-txt">{t("VipTop3")}</div>
          <h1>{t("Status ladder")}</h1>
          <div className="vip-club-level-box">
            <div className="vip-club-level-item">
              <div className="level-top">
                <img src={level1} alt="" />
                <div className="level-top-content">
                  <div className="level-title">Junior</div>
                  <div className="level-points">100 {t("Points2")}</div>
                </div>
              </div>
              <div
                className={
                  vipClub && vipClub.level >= 1
                    ? "level-progres active"
                    : "level-progres"
                }
              >
                {vipClub && vipClub.level === 1 && (
                  <span>{t("Your level")}</span>
                )}
                {vipClub && vipClub.level === 0 && (
                  <div
                    className="bar"
                    style={{
                      width: `${
                        (100 * vipClub.current_amount) /
                        vipClub.value_for_complete
                      }%`,
                    }}
                  />
                )}
                {vipClub && vipClub.level === 0 && (
                  <span>
                    {vipClub.current_amount}/{vipClub.value_for_complete}
                  </span>
                )}
              </div>
              <div className="level-features">
                <div className="item">
                  {t("Cashback")}
                  <span>4%</span>
                </div>
                <div className="item">{t("TgChannel")}</div>
                <div className="item">
                  {t("Monthly bonus")}
                  <span>3% {t("from the deposit")}</span>
                </div>
              </div>
            </div>
            <div className="vip-club-level-item">
              <div className="level-top">
                <img src={level2} alt="" />
                <div className="level-top-content">
                  <div className="level-title">Master</div>
                  <div className="level-points">500 {t("Points2")}</div>
                </div>
              </div>
              <div
                className={
                  vipClub && vipClub.level >= 2
                    ? "level-progres active"
                    : "level-progres"
                }
              >
                {vipClub && vipClub.level === 2 && (
                  <span>{t("Your level")}</span>
                )}
                {vipClub && vipClub.level === 1 && (
                  <div
                    className="bar"
                    style={{
                      width: `${
                        (100 * vipClub.current_amount) /
                        vipClub.value_for_complete
                      }%`,
                    }}
                  />
                )}
                {vipClub && vipClub.level === 1 && (
                  <span>
                    {vipClub.current_amount}/{vipClub.value_for_complete}
                  </span>
                )}
              </div>
              <div className="level-features">
                <div className="item">
                  {t("Cashback")}
                  <span>5%</span>
                </div>
                <div className="item">{t("TgChannel")}</div>
                <div className="item">
                  {t("Monthly bonus")}
                  <span>4% {t("from the deposit")}</span>
                </div>
              </div>
            </div>
            <div className="vip-club-level-item">
              <div className="level-top">
                <img src={level3} alt="" />
                <div className="level-top-content">
                  <div className="level-title">Favorite</div>
                  <div className="level-points">1000 {t("Points2")}</div>
                </div>
              </div>
              <div
                className={
                  vipClub && vipClub.level >= 3
                    ? "level-progres active"
                    : "level-progres"
                }
              >
                {vipClub && vipClub.level === 3 && (
                  <span>{t("Your level")}</span>
                )}
                {vipClub && vipClub.level === 2 && (
                  <div
                    className="bar"
                    style={{
                      width: `${
                        (100 * vipClub.current_amount) /
                        vipClub.value_for_complete
                      }%`,
                    }}
                  />
                )}
                {vipClub && vipClub.level === 2 && (
                  <span>
                    {vipClub.current_amount}/{vipClub.value_for_complete}
                  </span>
                )}
              </div>
              <div className="level-features">
                <div className="item">
                  {t("Cashback")}
                  <span>6%</span>
                </div>
                <div className="item">{t("TgChannel")}</div>
                <div className="item">
                  {t("Monthly bonus")}
                  <span>5% {t("from the deposit")}</span>
                </div>
              </div>
            </div>
            <div className="vip-club-level-item">
              <div className="level-top">
                <img src={level4} alt="" />
                <div className="level-top-content">
                  <div className="level-title">Pro</div>
                  <div className="level-points">3000 {t("Points2")}</div>
                </div>
              </div>
              <div
                className={
                  vipClub && vipClub.level >= 4
                    ? "level-progres active"
                    : "level-progres"
                }
              >
                {vipClub && vipClub.level === 4 && (
                  <span>{t("Your level")}</span>
                )}
                {vipClub && vipClub.level === 3 && (
                  <div
                    className="bar"
                    style={{
                      width: `${
                        (100 * vipClub.current_amount) /
                        vipClub.value_for_complete
                      }%`,
                    }}
                  />
                )}
                {vipClub && vipClub.level === 3 && (
                  <span>
                    {vipClub.current_amount}/{vipClub.value_for_complete}
                  </span>
                )}
              </div>
              <div className="level-features">
                <div className="item">{t("VipManagerBonus")}</div>
                <div className="item">
                  {t("Cashback")}
                  <span>8%</span>
                </div>
                <div className="item">{t("TgChannel")}</div>
                <div className="item">
                  {t("Monthly bonus")}
                  <span>6% {t("from the deposit")}</span>
                </div>
              </div>
            </div>
            <div className="vip-club-level-item">
              <div className="level-top">
                <img src={level5} alt="" />
                <div className="level-top-content">
                  <div className="level-title">Champion</div>
                  <div className="level-points">5000 {t("Points2")}</div>
                </div>
              </div>
              <div
                className={
                  vipClub && vipClub.level >= 5
                    ? "level-progres active"
                    : "level-progres"
                }
              >
                {vipClub && vipClub.level === 5 && (
                  <span>{t("Your level")}</span>
                )}
                {vipClub && vipClub.level === 4 && (
                  <div
                    className="bar"
                    style={{
                      width: `${
                        (100 * vipClub.current_amount) /
                        vipClub.value_for_complete
                      }%`,
                    }}
                  />
                )}
                {vipClub && vipClub.level === 4 && (
                  <span>
                    {vipClub.current_amount}/{vipClub.value_for_complete}
                  </span>
                )}
              </div>
              <div className="level-features">
                <div className="item">{t("VipFeatureMain")}</div>
                <div className="item">{t("VipManagerBonus")}</div>
                <div className="item">
                  {t("Cashback")}
                  <span>10%</span>
                </div>
                <div className="item">{t("TgChannel")}</div>
                <div className="item">
                  {t("Monthly bonus")}
                  <span>7% {t("from the deposit")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="vip-club-txt">{t("VipBottom1")}</div>
          <div className="vip-club-txt">
            <span>{t("VipBottom2.1")}</span>
            <b> Junior </b>
            <span>{t("VipBottom2.2")}</span>
            <b> Pro </b>
            <span>{t("VipBottom2.2")}</span>
          </div>
          <div className="vip-club-txt">{t("VipBottom3")}</div>
        </div>
      </div>
    </div>
  );
};

export default VipClub;
