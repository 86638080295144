import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CirclesWithBar } from "react-loader-spinner";
import { isMobile, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
// import getSymbolFromCurrency from "currency-symbol-map";

import type { RootState } from "../store/store";
import { startGame } from "../services/apiGames";
// import { getGameTornamentInfo } from "../services/apiTournaments";
// import { TournamentItem } from "../types/tournamentTypes";
// import { getAllBonusesList } from "../services/apiBonuses";
// import { BonusAttachedItem } from "../types/bonusesTypes";
// import { HomeBetItem } from "../types/betsTypes";
// import getBalanceSytring from "../helpers/getBalanceString";

// import GameTabs from "../components/tabs/GameTabs";

// import backIcon from "../images/back-icon.svg";
// import mainBg from "../images/game-page-bg.png";
import rakeBackImg from "../images/rakeback-img.png";

interface Props {
  currentAmount?: number;
  totalAmount?: number;
  profit?: number;
}

const GamePage: React.FC<Props> = ({ currentAmount, totalAmount, profit }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();

  const userId = useSelector((state: RootState) => state.user.userInfo.id);
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const userBalance = useSelector(
    (state: RootState) => state.user.userInfo.current_balance
  );
  const balances = useSelector(
    (state: RootState) => state.user.userInfo.balances
  );
  const rakebackPercent = useSelector(
    (state: RootState) => state.user.userInfo.rakebackPercent
  );

  const [gameUrl, setGameUrl] = useState<string>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(true);
  // const [tournament, setTournament] = useState<TournamentItem>();
  // const [myBonuses, setMyBonuses] = useState<BonusAttachedItem[]>();
  // const [homeBets, setHomeBets] = useState<HomeBetItem[]>([]);

  // const loadTournament = async (): Promise<void> => {
  //   const result = await getGameTornamentInfo(id as string);
  //   if (typeof result !== "string") {
  //     setTournament(result.tournament as TournamentItem);
  //   }
  // };
  const loadGame = async (): Promise<void> => {
    setIsFetching(true);
    const result = await startGame(
      parseInt(id as string, 10),
      userId,
      isMobile ? "mobile" : "desktop"
    );
    if (typeof result !== "string") {
      setGameUrl(result.data.launch_options.game_url as string);
    } else {
      setHasError(true);
    }
    setIsFetching(false);
  };
  // const loadAllBonuses = async (): Promise<void> => {
  //   setIsFetching(true);
  //   const result = await getAllBonusesList();
  //   if (typeof result !== "string") {
  //     setMyBonuses(result.data.active as BonusAttachedItem[]);
  //   }
  //   setIsFetching(false);
  // };
  // const loadBets = async (): Promise<void> => {
  //   const result = await getHomeBets();
  //   if (typeof result !== "string") {
  //     setHomeBets(result.data as HomeBetItem[]);
  //   }
  // };
  // useEffect(() => {
  //   if (id && userId && isLogged) {
  //     loadGame();
  //     // loadTournament();
  //     // loadAllBonuses();
  //     // loadBets();
  //   }
  //   return () => {
  //     setGameUrl(undefined);
  //   };
  //   // eslint-disable-next-line
  // }, [id, userId, isLogged]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const demoBalance = balances.filter((el) => el.type === "demo")[0];
    if (id && userId && isLogged && balances) {
      if (demoBalance && demoBalance.amount > 0) {
        // setIsReady(false);
        setIsReady(true);
      } else {
        setIsReady(true);
        loadGame();
      }
    }
    return () => {
      setGameUrl(undefined);
    };
    // eslint-disable-next-line
  }, [id, userId, isLogged]);

  const loadGameFunc = (): void => {
    setGameUrl(undefined);
    loadGame();
  };

  useEffect(() => {
    if (isReady) loadGameFunc();
    // eslint-disable-next-line
  }, [isReady, userBalance.type]);

  return (
    <div className={isReady ? "game-page ready" : "game-page"}>
      {/* <div className="game-menu">
        <div
          className="back-btn"
          onClick={(): void => {
            if (location.key === "default") {
              navigate("/");
            } else {
              navigate(-1);
            }
          }}
        >
          <img src={backIcon} alt="back" />
        </div>
        <div className="game-balance">
          <div className="game-balance-main">
            <span>{t("My balance")}</span>
            <span>
              {getSymbolFromCurrency(userBalance.code || "")} {balanceSumm()}
            </span>
          </div>
          {balances.map((el) => (
            <div
              className={
                el.type === userBalance.type
                  ? "game-balance-item active"
                  : "game-balance-item"
              }
              key={el.type}
            >
              <span>{t(getBalanceSytring(el.type))}</span>
              <span>
                {getSymbolFromCurrency(el.code || "")} {el.amount}
              </span>
            </div>
          ))}
        </div>
        {isLogged && (
          <button className="primary-btn" type="button" onClick={showDeposit}>
            {t("Deposit")}
          </button>
        )}
        <GameTabs
          tournament={tournament}
          gameId={id as string}
          loadBonuses={loadAllBonuses}
          bonuses={myBonuses}
          bets={homeBets}
        />
      </div> */}
      <div
        className={
          isMobile && isSafari ? "game-content safari" : "game-content"
        }
      >
        {/* <div
          className="back-btn"
          onClick={(): void => {
            navigate(-1);
          }}
        >
          <img src={backIcon} alt="back" />
        </div> */}
        <div className="game-wrap">
          <div className="container">
            {isFetching && (
              <CirclesWithBar
                height="200"
                width="200"
                color="rgb(248, 176, 0)"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {gameUrl && isReady && <iframe src={gameUrl} title="game" />}
            {hasError && (
              <p className="game-error">{t("Game loading error")}</p>
            )}
            {!isLogged && (
              <div
                className="game-box"
                style={{
                  backgroundImage: `url(${
                    (location?.state?.bg as string) || ""
                  })`,
                }}
              >
                <button
                  className="primary-btn"
                  type="button"
                  onClick={(): void => {
                    navigate("/login");
                  }}
                >
                  {t("Play")}
                </button>
              </div>
            )}
          </div>
          <div className="game-footer">
            {(currentAmount ||
              currentAmount === 0 ||
              totalAmount ||
              profit) && (
              <div className="rakeback-box">
                <div className="rakeback-txt">
                  {(currentAmount as number) + 1 === totalAmount ? (
                    <div className="rakeback-reward">
                      <div className="row">
                        <img src={rakeBackImg} alt="" />
                        <div className="content">
                          <div className="title">{t("Congratulations!")}</div>
                          <div className="main-txt">
                            {t(
                              "Your chance of winning on the next bet increases by"
                            )}
                          </div>
                          <div className="percent">{rakebackPercent}%</div>
                          <div className="txt">
                            {t("Don't miss this opportunity!")}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    t("Before receiving a rakeback, it remains to do:")
                  )}
                </div>
                {(currentAmount || currentAmount === 0) && totalAmount && (
                  <>
                    <div className="rakeback-progres">
                      <div
                        className="bar"
                        style={{
                          width: `${
                            (100 * (currentAmount + 1)) / totalAmount
                          }%`,
                        }}
                      />
                    </div>
                    <div className="rakeback-bets">
                      <span>{currentAmount + 1 || 0} </span>
                      {t("bets from")}
                      <span> {totalAmount || 0}</span>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamePage;
