import React, { useState, useEffect } from "react";
import { Tabs, Tab, Button, Table, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";
import { formatDateMinutes } from "../../helpers/getCorrectDate";

import { AdminPanelTabs, PromoTabs } from "../../types/adminTypes";
import type { RootState } from "../../store/store";
import { UserRoles } from "../../types/userTypes";
import { getCashbacksList, getPromoList } from "../../services/admin/apiPromo";
import { CashbackItem, PromoItem } from "../../types/promoTypes";

import CashbackInfoModal from "./modal/CashbackInfoModal";
import PromoInfoModal from "./modal/PromoInfoModal";
import DeleteCashbackModal from "./modal/DeleteCashbackModal";
import DeletePromoModal from "./modal/DeletePromoModal";

interface Props {
  activeTab: AdminPanelTabs;
}

const PromoTab: React.FC<Props> = ({ activeTab }) => {
  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const [activePromoTab, setActivePromoTab] = useState<PromoTabs>(
    PromoTabs.promo
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showInfoPromoModal, setShowInfoPromoModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showDeletePromoModal, setShowDeletePromoModal] =
    useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<CashbackItem>();
  const [itemToEditPromo, setItemToEditPromo] = useState<PromoItem>();
  const [cashbacksList, setCashbacksList] = useState<CashbackItem[]>([]);
  const [promosList, setPromosList] = useState<PromoItem[]>([]);
  const [activeCashbackPage, setActiveCashbackPage] = useState<number>(0);
  const [totalCashbackPages, setTotalCashbackPages] = useState<number>(1);
  const [activePromoPage, setActivePromoPage] = useState<number>(0);
  const [totalPromoPages, setTotalPromoPages] = useState<number>(1);

  //   Load promos logic
  const loadPromoListHandler = async (page: number): Promise<void> => {
    setIsFetching(true);
    const result = await getPromoList(page + 1);
    if (typeof result !== "string") {
      setTotalPromoPages(result.meta.last_page as number);
      setPromosList(result.data as PromoItem[]);
    }
    setIsFetching(false);
  };
  useEffect(() => {
    if (
      activeTab === AdminPanelTabs.promo &&
      activePromoTab === PromoTabs.promo
    ) {
      loadPromoListHandler(0);
    }
  }, [activeTab, activePromoTab]);
  const handlePaginationPromoChange = (e: number): void => {
    setActivePromoPage(e);
    loadPromoListHandler(e);
  };
  //   Load cashbacks logic
  const loadCashbacksListHandler = async (page: number): Promise<void> => {
    setIsFetching(true);
    const result = await getCashbacksList(page + 1);
    if (typeof result !== "string") {
      setTotalCashbackPages(result.meta.last_page as number);
      setCashbacksList(result.data as CashbackItem[]);
    }
    setIsFetching(false);
  };
  useEffect(() => {
    if (
      activeTab === AdminPanelTabs.promo &&
      activePromoTab === PromoTabs.cashback
    ) {
      loadCashbacksListHandler(0);
    }
  }, [activeTab, activePromoTab]);
  const handlePaginationCashbackChange = (e: number): void => {
    setActiveCashbackPage(e);
    loadCashbacksListHandler(e);
  };

  //   Modal handlers
  const handleCashbackAdded = (): void => {
    setShowInfoModal(false);
    loadCashbacksListHandler(0);
    setActiveCashbackPage(0);
  };
  const handleCashbackEdited = (): void => {
    setShowInfoModal(false);
    loadCashbacksListHandler(activeCashbackPage);
    setItemToEdit(undefined);
  };
  const handleCashbackDeleted = (): void => {
    setShowDeleteModal(false);
    loadCashbacksListHandler(0);
    setActiveCashbackPage(0);
    setItemToEdit(undefined);
  };
  const handlePromoAdded = (): void => {
    setShowInfoPromoModal(false);
    loadPromoListHandler(0);
    setActivePromoPage(0);
  };
  const handlePromoEdited = (): void => {
    setShowInfoPromoModal(false);
    loadPromoListHandler(activePromoPage);
    setItemToEditPromo(undefined);
  };
  const handlePromoDeleted = (): void => {
    setShowDeletePromoModal(false);
    loadPromoListHandler(0);
    setActivePromoPage(0);
    setItemToEditPromo(undefined);
  };

  return (
    <div className="bonuses-admin-wrap admin-tab-warp">
      <h3>{t("Promo codes")}</h3>
      <Tabs
        defaultActiveKey={activePromoTab}
        className="mb-3"
        onSelect={(e): void => setActivePromoTab(e as PromoTabs)}
      >
        <Tab eventKey={PromoTabs.promo} title={t("Promo codes")}>
          <Button
            variant="primary"
            className="mb-3"
            disabled={userRole !== UserRoles.admin}
            onClick={(): void => {
              setItemToEditPromo(undefined);
              setShowInfoPromoModal(true);
            }}
          >
            + {t("Add new promo code")}
          </Button>
        </Tab>
        <Tab eventKey={PromoTabs.cashback} title={t("Cashback")}>
          <Button
            variant="primary"
            className="mb-3"
            disabled={userRole !== UserRoles.admin}
            onClick={(): void => {
              setShowInfoModal(true);
            }}
          >
            + {t("Add new cashback")}
          </Button>
        </Tab>
      </Tabs>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {!isFetching && activePromoTab === PromoTabs.cashback && (
        <>
          <Table striped bordered className="mb-5" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("Title")}</th>
                <th>{t("Date")}</th>
                <th>{t("Amount")}</th>
                <th>{t("Active")}</th>
                <th>{t("Period in days")}</th>
                <th>{t("Period in hours")}</th>
                <th>{t("Period in minutes")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            {cashbacksList && (
              <tbody>
                {cashbacksList.map((el) => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>{el.name}</td>
                    <td className="no-wrap">
                      {formatDateMinutes(el.created_at as string)}
                    </td>
                    <td>{el.amount}</td>
                    <td>{el.is_active ? "+" : "-"}</td>
                    <td>{el.period_in_days}</td>
                    <td>{el.period_in_hours}</td>
                    <td>{el.period_in_minutes}</td>
                    <td>
                      <div className="admin-actions-wrap">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            disabled={
                              userRole === UserRoles["retention-manager"]
                            }
                          >
                            {t("Actions")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(): void => {
                                setItemToEdit(el);
                                setShowInfoModal(true);
                              }}
                            >
                              {t("Info")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(): void => {
                                setItemToEdit(el);
                                setShowDeleteModal(true);
                              }}
                            >
                              {t("Delete")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          {totalCashbackPages > 1 && (
            <div className="pagination-wrap">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                pageRangeDisplayed={5}
                pageCount={totalCashbackPages}
                previousLabel="<"
                forcePage={activeCashbackPage}
                onPageChange={(e): void => {
                  handlePaginationCashbackChange(e.selected);
                }}
              />
            </div>
          )}
        </>
      )}
      {!isFetching && activePromoTab === PromoTabs.promo && (
        <>
          <Table striped bordered className="mb-5" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("Code")}</th>
                <th>{t("Date")}</th>
                <th>{t("Description")}</th>
                <th>{t("Max count usage")}</th>
                <th>{t("Count used")}</th>
                <th>{t("Currency")}</th>
                <th>{t("Start date")}</th>
                <th>{t("End date")}</th>
                <th>{t("Active")}</th>
                <th>{t("Rewards")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            {promosList && (
              <tbody>
                {promosList.map((el) => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>{el.code}</td>
                    <td className="no-wrap">
                      {formatDateMinutes(el.created_at as string)}
                    </td>
                    <td>{el.description}</td>
                    <td>{el.max_count_using}</td>
                    <td>{el.count_used}</td>
                    <td>{el.currency.code}</td>
                    <td className="no-wrap">
                      {formatDateMinutes(el.started_at as string)}
                    </td>
                    <td className="no-wrap">
                      {formatDateMinutes(el.finished_at as string)}
                    </td>
                    <td>{el.is_active ? "+" : "-"}</td>
                    <td>
                      {el.cashback && (
                        <>
                          <b>{t("Cashback")}: </b>
                          <span>{el.cashback.name}</span>
                          <br />
                          <hr />
                        </>
                      )}
                      {el.freespin && (
                        <>
                          <b>{t("Freespin")}: </b>
                          <span>{el.freespin.title}</span>
                          <br />
                          <hr />
                        </>
                      )}
                      {el.deposit_reward_type &&
                        el.deposit_reward_type !== "none" && (
                          <>
                            <b>{t("Deposit type")}: </b>
                            <span>{el.deposit_reward_type}</span>
                            <br />
                            <b>{t("Deposit amount")}: </b>
                            <span>{el.deposit_current_amount}</span>
                            <br />
                            <b>{t("Deposit amount max")}: </b>
                            <span>{el.deposit_max_amount}</span>
                            <hr />
                          </>
                        )}
                    </td>
                    <td>
                      <div className="admin-actions-wrap">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            disabled={
                              userRole === UserRoles["retention-manager"]
                            }
                          >
                            {t("Actions")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(): void => {
                                setItemToEditPromo(el);
                                setShowInfoPromoModal(true);
                              }}
                            >
                              {t("Info")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(): void => {
                                setItemToEditPromo(el);
                                setShowDeletePromoModal(true);
                              }}
                            >
                              {t("Delete")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          {totalPromoPages > 1 && (
            <div className="pagination-wrap">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                pageRangeDisplayed={5}
                pageCount={totalPromoPages}
                previousLabel="<"
                forcePage={activePromoPage}
                onPageChange={(e): void => {
                  handlePaginationPromoChange(e.selected);
                }}
              />
            </div>
          )}
        </>
      )}
      {showInfoModal && (
        <CashbackInfoModal
          handleCashbackAdded={handleCashbackAdded}
          handleCashbackEdited={handleCashbackEdited}
          show={showInfoModal}
          handleClose={(): void => {
            setShowInfoModal(false);
            setItemToEdit(undefined);
          }}
          itemToEdit={itemToEdit}
        />
      )}
      {showDeleteModal && (
        <DeleteCashbackModal
          handleCashbackDeleted={handleCashbackDeleted}
          show={showDeleteModal}
          handleClose={(): void => {
            setShowDeleteModal(false);
            setItemToEdit(undefined);
          }}
          id={itemToEdit?.id || 0}
          name={itemToEdit?.name || ""}
        />
      )}
      {showInfoPromoModal && (
        <PromoInfoModal
          handlePromoAdded={handlePromoAdded}
          handlePromoEdited={handlePromoEdited}
          show={showInfoPromoModal}
          itemToEdit={itemToEditPromo}
          handleClose={(): void => {
            setShowInfoPromoModal(false);
          }}
        />
      )}
      {showDeletePromoModal && (
        <DeletePromoModal
          handlePromoDeleted={handlePromoDeleted}
          show={showDeletePromoModal}
          handleClose={(): void => {
            setShowDeletePromoModal(false);
            setItemToEditPromo(undefined);
          }}
          id={itemToEditPromo?.id || 0}
        />
      )}
    </div>
  );
};

export default PromoTab;
