import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";
import { formatDateMinutes } from "../../../helpers/getCorrectDate";

import { getUserFreeSpins } from "../../../services/admin/apiUsers";
import { UserFreeSpinItem } from "../../../types/gameTypes";

interface Props {
  name: string;
  id: number;
  show: boolean;
  handleClose: () => void;
}

const UserFreeSpinsModal: React.FC<Props> = ({
  show,
  handleClose,
  name,
  id,
}) => {
  const { t } = useTranslation();

  const [freeSpins, setFreeSpins] = useState<UserFreeSpinItem[]>();
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isFetching, setFetching] = useState<boolean>(false);

  const loadUserBalance = async (page: number): Promise<void> => {
    setFetching(true);
    const result = await getUserFreeSpins(page + 1, id);
    if (typeof result !== "string") {
      setFreeSpins(result?.freespins as UserFreeSpinItem[]);
      setTotalPages(result?.meta?.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    loadUserBalance(0);
    // eslint-disable-next-line
  }, []);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadUserBalance(e);
  };

  return (
    <Modal show={show} onHide={handleClose} className="admin-bets-wrap">
      <Modal.Header closeButton>
        <Modal.Title>{t("User free spins")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("Name")}: {name}
        </p>
        {isFetching && (
          <CirclesWithBar
            height="200"
            width="200"
            color="#0d6efd"
            wrapperStyle={{ justifyContent: "center" }}
            visible
            ariaLabel="circles-with-bar-loading"
          />
        )}
        {!isFetching && (
          <Table striped bordered className="mb-5" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("Title")}</th>
                <th>{t("Desription")}</th>
                <th>{t("Currency")}</th>
                <th>{t("Quantity")}</th>
                <th>{t("Valid until")}</th>
                <th>{t("Amount")}</th>
                <th>{t("Status")}</th>
                <th>{t("Games")}</th>
              </tr>
            </thead>
            {freeSpins && (
              <tbody>
                {freeSpins.map((el) => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>{el.title}</td>
                    <td>{el.description}</td>
                    <td>{el.currency}</td>
                    <td>{el.freespins_quantity}</td>
                    <td>
                      <td>{formatDateMinutes(el.valid_until as string)}</td>
                    </td>
                    <td>{el.total_amount}</td>
                    <td>{el.status}</td>
                    <td className="admin-game-cell">
                      {el.games.map((item) => (
                        <div className="admin-game-item" key={item.title}>
                          <div>
                            <span>
                              <b>{t("Game name")}: </b>
                            </span>
                            <span>{item.title}</span>
                          </div>
                          <div>
                            <span>
                              <b>{t("Game vendor")}: </b>
                            </span>
                            <span>{item.producer}</span>
                          </div>
                          <div>
                            <span>
                              <b>{t("Game category")}: </b>
                            </span>
                            <span>{item.category}</span>
                          </div>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        )}
        {totalPages > 1 && (
          <div className="pagination-wrap">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="<"
              forcePage={activePage}
              onPageChange={(e): void => {
                handlePaginationChange(e.selected);
              }}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UserFreeSpinsModal;
