import axios from "axios";

interface GeoInfoData {
  country_code: string;
  currency: string;
}

const apiGeoInfo = async (): Promise<GeoInfoData> => {
  try {
    const { data } = await axios.get<GeoInfoData>("https://ipapi.co/json/");
    return {
      country_code: data.country_code,
      currency: data.currency,
    };
  } catch (error) {
    return {
      country_code: "",
      currency: "",
    };
  }
};

export default apiGeoInfo;
