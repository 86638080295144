/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { Autoplay } from "swiper";
import "swiper/css";
import { NavLink } from "react-router-dom";
import styles from "./SliderWinnersStyles.module.scss";
import sliceText from "../../../../helpers/sliceText";
import { WinnerType } from "../../../../types/userTypes";
import { getWinnersList } from "../../../../services/apiGames";
import { RootState } from "../../../../store/store";

const SliderWinners: React.FC = () => {
  const [winners, setWinners] = useState<WinnerType[]>([]);
  const [isWinnersFetching, setIsWinnersFetching] = useState<boolean>(false);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  // const winnerSlideRtlStyles: React.CSSProperties = {
  //   flexDirection: "row-reverse",
  // };
  // const infoWinnerRtlStyles: React.CSSProperties = {
  //   alignItems: "end",
  // };

  const loadLastWinners = async (): Promise<void> => {
    setIsWinnersFetching(true);
    const result = await getWinnersList();
    if (typeof result !== "string") setWinners(result.data as WinnerType[]);
    setIsWinnersFetching(false);
  };

  useEffect(() => {
    loadLastWinners();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isWinnersFetching && winners?.length > 0 && (
        <Swiper
          slidesPerView="auto"
          modules={[Autoplay]}
          autoplay={{
            delay: 1000,
          }}
          speed={1000}
          spaceBetween={0}
          loop
        >
          {winners.map((winner) => {
            return (
              <SwiperSlide
                key={winner.ratio}
                className={`${styles.winner_slide} ${
                  localeActive?.code === "fa" ? styles.rtl : ""
                }`}
              >
                <NavLink
                  to={`/game/${winner.gameId}`}
                  className={styles.winner_slide}
                >
                  <img
                    src={winner.gameIcon}
                    alt="winner"
                    className={styles.logo_game}
                  />
                  <div className={styles.info_winner}>
                    <div className={styles.text}>
                      {sliceText(winner.winner, 15)}
                    </div>
                    <div className={styles.win_amount}>
                      <span>{sliceText(winner.winAmount.toString(), 12)} </span>
                      <span>{getSymbolFromCurrency(winner.currency)}</span>
                    </div>
                    <div className={styles.text}>
                      {sliceText(winner.gameFullName, 15)}
                    </div>
                  </div>
                </NavLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
};
export default SliderWinners;
