import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { getAllGamesList } from "../../../services/apiGames";
import { getUsersList } from "../../../services/admin/apiUsers";
import { createFreeSpin } from "../../../services/admin/apiFreeSpins";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";
import { CurrencyItem } from "../../../types/currencyTypes";
import { getCurrencyList } from "../../../services/apiCurrency";

import MultiSelect from "../../MultiSelect";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleSpinAdded: () => void;
}

const perPageGames = 50;
const perPageUsers = 20;

const AddFreeSpinModal: React.FC<Props> = ({
  show,
  handleClose,
  handleSpinAdded,
}) => {
  const { t } = useTranslation();

  //   Selects currency data
  const [currencyList, setCurrencyList] = useState<CurrencyItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<number>(2);
  //   Selects game data
  const [games, setGames] = useState<{ name: string; id: number | string }[]>(
    []
  );
  const [selectedGames, setSelectedGames] = useState<
    { name: string; id: number | string }[]
  >([]);
  const [gameSearch, setGameSearch] = useState<string>("");
  const [gamePage, setGamePage] = useState<number>(1);
  const [hasMoreGames, setHasMoreGames] = useState<boolean>(true);
  //   Selects user data
  const [users, setUsers] = useState<{ name: string; id: number | string }[]>(
    []
  );
  const [selectedUsers, setSelectedUsers] = useState<
    { name: string; id: number | string }[]
  >([]);
  const [usersSearch, setUsersSearch] = useState<string>("");
  const [userPage, setUserPage] = useState<number>(1);
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(true);
  //   Conditions
  const [isFetching, setFetching] = useState<boolean>(false);
  //  Form values
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [date, setDate] = useState<Date>();
  const [globalError, setGlobalError] = useState<string>("");

  //   Form logic
  const handleSubmit = async (): Promise<void> => {
    setFetching(true);
    const usersIds: number[] = [];
    const gamesIds: number[] = [];
    selectedGames.forEach((el) => {
      gamesIds.push(typeof el.id === "string" ? parseInt(el.id, 10) : el.id);
    });
    selectedUsers.forEach((el) => {
      usersIds.push(typeof el.id === "string" ? parseInt(el.id, 10) : el.id);
    });
    const data = {
      freespins_quantity: quantity,
      valid_until: moment(date as Date).format("YYYY-MM-DDTHH:mm:ss") || "",
      games_ids: gamesIds,
      title,
      description,
      users_ids: usersIds,
      currency_id: selectedCurrency,
    };
    const result = await createFreeSpin(data);
    if (typeof result === "string") {
      setGlobalError(result as string);
      showErrorNotif(result as string);
    } else {
      showSuccessNotif(t("Data added successfully"));
      handleSpinAdded();
    }
    setFetching(false);
  };

  //   Currency logic
  const loadCurrencyList = async (): Promise<void> => {
    const response = await getCurrencyList();
    if (typeof response !== "string") {
      setCurrencyList(response.data as CurrencyItem[]);
    }
  };
  useEffect(() => {
    loadCurrencyList();
    // eslint-disable-next-line
  }, []);

  //   Games logic
  const loadGames = async (
    search: string,
    page: number,
    clearRequest?: boolean
  ): Promise<void> => {
    setHasMoreGames(true);
    const response = await getAllGamesList(
      search,
      null,
      null,
      page,
      perPageGames
    );
    if (typeof response !== "string") {
      const result: { name: string; id: number }[] = [];
      response.data.forEach((el) => {
        result.push({ name: el.title, id: el.id });
      });
      setGames(clearRequest ? [...result] : [...games, ...result]);
      if (response.data.length < perPageGames) {
        setHasMoreGames(false);
      }
    }
  };
  useEffect(() => {
    loadGames(gameSearch, gamePage);
    // eslint-disable-next-line
  }, []);
  const handleGameSearch = (e: string): void => {
    setGames([]);
    setGameSearch(e);
    setGamePage(1);
    loadGames(e, 1, true);
  };
  const handleGamePaginate = (): void => {
    loadGames(gameSearch, gamePage + 1);
    setGamePage(gamePage + 1);
  };
  const handleGameSelect = (e: { name: string; id: number | string }): void => {
    setSelectedGames([...selectedGames, e]);
  };
  const handleGameClear = (e: number | string): void => {
    const newArray = selectedGames.filter((el) => {
      return el.id !== e;
    });
    setSelectedGames(newArray);
  };
  //   Users logic
  const loadUsers = async (
    search: string,
    page: number,
    clearRequest?: boolean
  ): Promise<void> => {
    setHasMoreUsers(true);
    const response = await getUsersList(
      page,
      search ? `&first_name=${search || ""}` : ""
    );
    if (typeof response !== "string") {
      const result: { name: string; id: number }[] = [];
      response.data.forEach((el) => {
        result.push({
          name: `${el.first_name || ""} ${el.last_name || ""}`,
          id: el.id,
        });
      });
      setUsers(clearRequest ? [...result] : [...users, ...result]);
      if (response.data.length < perPageUsers) {
        setHasMoreUsers(false);
      }
    }
  };
  useEffect(() => {
    loadUsers(usersSearch, userPage);
    // eslint-disable-next-line
  }, []);
  const handleUsersSearch = (e: string): void => {
    setUsers([]);
    setUsersSearch(e);
    setUserPage(1);
    loadUsers(e, 1, true);
  };
  const handleUsersPaginate = (): void => {
    loadUsers(usersSearch, userPage + 1);
    setUserPage(userPage + 1);
  };
  const handleUsersSelect = (e: {
    name: string;
    id: number | string;
  }): void => {
    setSelectedUsers([...selectedUsers, e]);
  };
  const handleUsersClear = (e: number | string): void => {
    const newArray = selectedUsers.filter((el) => {
      return el.id !== e;
    });
    setSelectedUsers(newArray);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Add new free spin")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Title") || "Title"}
                value={title}
                onChange={(e): void => {
                  setTitle(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Description")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={t("Description") || "Description"}
                value={description}
                onChange={(e): void => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Quantity")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Quantity") || "Quantity"}
                value={quantity}
                onChange={(e): void => {
                  setQuantity(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Valid until")}</Form.Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={(dateEvent: Date): void => {
                  setDate(dateEvent);
                }}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
                selected={date}
                dropdownMode="select"
                placeholderText={t("Select date") || "Select date"}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Currency")}</Form.Label>
              <Form.Select
                aria-label={t("Currency") || "Currency"}
                value={selectedCurrency}
                onChange={(e): void => {
                  setSelectedCurrency(parseInt(e.target.value as string, 10));
                }}
              >
                {currencyList &&
                  currencyList.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.code}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Games")}</Form.Label>
              <MultiSelect
                selectedItems={selectedGames}
                searchedItems={games}
                hasMore={hasMoreGames}
                searchValue={gameSearch}
                handleSearch={handleGameSearch}
                handleSelect={handleGameSelect}
                handleClear={handleGameClear}
                handlePaginate={handleGamePaginate}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Users")}</Form.Label>
              <MultiSelect
                selectedItems={selectedUsers}
                searchedItems={users}
                hasMore={hasMoreUsers}
                searchValue={usersSearch}
                handleSearch={handleUsersSearch}
                handleSelect={handleUsersSelect}
                handleClear={handleUsersClear}
                handlePaginate={handleUsersPaginate}
              />
            </Form.Group>
          </Col>
        </Row>
        {globalError && <div className="error-msg">{globalError}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
        <Button
          variant="primary"
          onClick={(): void => {
            handleSubmit();
          }}
          disabled={isFetching}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFreeSpinModal;
