const getValueWinWelcomeBonus = (value: string | undefined): string => {
  switch (value) {
    case "RU":
      return "Up to RUB 125,000";
    case "KZ":
      return "Up to KTZ 700,000 tenge";
    case "IR":
      return "Up to IRR 64,000,000 ﷼";
    default:
      return "Up to $1500";
  }
};

export default getValueWinWelcomeBonus;
