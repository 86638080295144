import React, { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./CustomSelectStyles.module.scss";

import arrowIcon from "../../../images/arrow-down-black.svg";

export type Option = {
  value: string;
  title: string;
};

interface Props {
  items: Option[];
  selectedItem?: { value: string; title: string };
  placeholder: string;
  handleSelect: (e: Option) => void;
}

const CustomSelect: React.FC<Props> = ({
  items,
  handleSelect,
  placeholder,
  selectedItem,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const { t } = useTranslation();

  const hideDropdownHandle = (): void => {
    setShowDropdown(false);
  };
  const showDropdownHandle = (): void => {
    setShowDropdown(true);
  };

  const ref = useDetectClickOutside({
    onTriggered: hideDropdownHandle,
  });

  const handleChange = (e: { value: string; title: string }): void => {
    handleSelect(e);
    showDropdownHandle();
  };
  return (
    <div
      className={classNames(styles.select_wrap, {
        [styles.open]: showDropdown,
      })}
      ref={ref}
    >
      <div
        className={classNames(styles.selected_box)}
        onClick={showDropdownHandle}
      >
        {t(`${selectedItem?.title || ""}`) || (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
        <div
          onClick={(e): void => {
            e.preventDefault();
            e.stopPropagation();
            if (showDropdown) {
              hideDropdownHandle();
            } else {
              showDropdownHandle();
            }
          }}
        >
          <img
            className={classNames(styles.arrow_down, {
              [styles.arrow_up]: showDropdown,
            })}
            src={arrowIcon}
            alt=""
          />
        </div>
      </div>
      {showDropdown && (
        <div className={styles.select_list} data-testid="default-select-list">
          {items.map((el) => (
            <div
              className={styles.list_item}
              key={el.value}
              data-testid={`default-select-list-item-${el.value}`}
              onClick={(): void => {
                handleChange(el);
                hideDropdownHandle();
              }}
            >
              {t(el.title)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
