import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDetectClickOutside } from "react-detect-click-outside";
import { CirclesWithBar } from "react-loader-spinner";
import { getFreeSpinsList } from "../../../../services/admin/apiFreeSpins";
import { FreeSpinItem } from "../../../../types/gameTypes";

import arrowIcon from "../../../../images/dropdown-arrow.svg";

interface SelectFreeSpinsType {
  onChange: (value: FreeSpinItem | null) => void;
  value: FreeSpinItem | null;
  disabled?: boolean;
}

const SelectFreeSpins: React.FC<SelectFreeSpinsType> = ({
  disabled = false,
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const [hasMoreSpins, setHasMoreSpins] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);
  const [showDropDown, setShowDropdown] = useState<boolean>(false);
  const [isSpinsFetching, setSpinsFetching] = useState<boolean>(false);
  const [freeSpinssList, setFreeSpinssList] = useState<FreeSpinItem[]>([]);

  const getFreespinsHandle = async (page: number): Promise<void> => {
    setSpinsFetching(true);
    const result = await getFreeSpinsList(page);
    if (typeof result !== "string") {
      setFreeSpinssList([
        ...freeSpinssList,
        ...(result.data as FreeSpinItem[]),
      ]);
      if (result.data.length < 20) {
        setHasMoreSpins(false);
      }
    }
    setSpinsFetching(false);
  };

  const handleScroll = (element: HTMLDivElement): void => {
    if (
      element.scrollHeight - element.scrollTop - 10 <= element.clientHeight &&
      hasMoreSpins &&
      !isSpinsFetching
    ) {
      setActivePage(activePage + 1);
      getFreespinsHandle(activePage + 1);
    }
  };
  const showDropdownHandler = (): void => {
    setShowDropdown(true);
  };
  const hideDropdownHandler = (): void => {
    setShowDropdown(false);
  };
  const refDropdown = useDetectClickOutside({
    onTriggered: hideDropdownHandler,
  });

  useEffect(() => {
    getFreespinsHandle(1);
    // eslint-disable-next-line
  }, []);

  return (
    <Form.Group className="mb-3">
      <Form.Label>{t("Freespin")}</Form.Label>
      <div
        className={
          disabled ? "custom-select-wrap disabled" : "custom-select-wrap"
        }
        ref={refDropdown}
      >
        <div
          className="custom-select-item"
          onClick={showDropdownHandler}
          data-testid="rewards-admin-spin"
        >
          {value?.title || <span>{t("Select freespin")}</span>}
          <img src={arrowIcon} alt="" />
        </div>
        {showDropDown && (
          <div
            className="custom-select-dropdown"
            onScroll={(e): void => {
              handleScroll(e.target as HTMLDivElement);
            }}
          >
            <span
              onClick={(): void => {
                onChange(null);
                hideDropdownHandler();
              }}
            >
              none
            </span>
            {freeSpinssList.map((el) => (
              <span
                key={el.id}
                onClick={(): void => {
                  onChange(el);
                  hideDropdownHandler();
                }}
              >
                {el.title}
              </span>
            ))}
            {hasMoreSpins && (
              <CirclesWithBar
                height="50"
                width="50"
                color="#0d6efd"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
          </div>
        )}
      </div>
    </Form.Group>
  );
};

export default SelectFreeSpins;
