export interface GameItemType {
  icons: {
    background: string;
    default_logo_color: string;
    small_logo_color: string;
    "380x380": string;
    "190x190": string;
  };
  id: number;
  title: string;
  description: string;
  provider: string;
  producer: string;
  category: string;
  has_freespins: number;
  has_jackpot: number;
  payout: number;
  multiplier: number;
  lobby: number;
  is_active: number;
}

export interface CategoryItemType {
  id: number;
  name: CategoryGameType;
}

export interface VendorItemType {
  id: number;
  name: string;
  provider: string;
  logo: string;
  logo_small?: string;
}

export interface UserBetItem {
  id: number;
  action_id: string;
  tx_id: string;
  action: string;
  currency: string;
  amount: number;
  balance_amount?: number;
  processed_at: string;
  game: {
    title: string;
    producer: string;
    provider: string;
    category: string;
    volatility: string;
  };
  user: {
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
}

export interface UserFreeSpinItem {
  id: number;
  title: string;
  description: string;
  currency: string;
  freespins_quantity: number;
  valid_until: string;
  total_amount: number;
  status: string;
  games: {
    title: string;
    producer: string;
    provider: string;
    category: string;
    volatility: string;
    multiplier: number;
  }[];
}

export interface FreeSpinItem {
  id: number;
  title: string;
  description: string;
  currency: {
    code: string;
    id: number;
    name: string;
    units: number;
  };
  freespins_quantity: number;
  valid_until: string;
  total_amount: number;
  games: {
    title: string;
    producer: string;
    provider: string;
    category: string;
    volatility: string;
    multiplier: number;
    id: number;
  }[];
  users: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    issue_id: string;
  }[];
}

export interface AddFreeSpinPayload {
  freespins_quantity: string;
  valid_until: string;
  games_ids: number[];
  title: string;
  description: string;
  users_ids: number[];
  currency_id?: number;
}

export interface EditFreeSpinPayload {
  freespins_quantity: string;
  valid_until: string;
  title: string;
  description: string;
  currency_id?: number;
}

export interface GameReportItem {
  id: number;
  title: string;
  producer: string;
  payout: number;
  currency: string;
  rounds: {
    bets_sum: number;
    wins_sum: number;
    gross_gaming_revenue: number;
    users_count: number;
  };
  icons: {
    background: string;
    default_logo_color: string;
    small_logo_color: string;
    "380x380": string;
  };
}

export enum CategoryGameType {
  slots = "slots",
  live_games = "live_games",
  card = "card",
  roulette = "roulette",
  crash = "crash",
  casual = "casual",
  poker = "poker",
  // craps = "craps",
  // fishing = "fishing",
  // lottery = "lottery",
  // video_poker = "video_poker",
  // virtual_sports = "virtual_sports",
  All = "All",
}
