export interface DocumentPayload {
  id_card_front?: File;
  id_card_back?: File;
  driver_license?: File;
  credit_card?: File;
  credit_card_number?: string;
  card_holder?: string;
  credit_card_month?: string;
  credit_card_year?: string;
  selfie_identity?: File;
  tax_number?: File;
  other?: File;
}

export interface AdminDocumentPayload {
  user_id: number;
  document_id: number;
  file_name?: string;
  file?: File;
  credit_card_number?: string;
  credit_card_month?: string;
  credit_card_year?: string;
}

export interface DocumentItem {
  created_at: string;
  document_path: string;
  id: number;
  name: string;
  side: string;
  status: string;
  type: string;
  user?: {
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    date_of_birth: string;
  };
  credit_card_number?: string;
  credit_card_month?: string;
  credit_card_year?: string;
}

export enum DocumentTypes {
  id_card_front = "id_card_front",
  id_card_back = "id_card_back",
  driver_license = "driver_license",
  credit_card = "credit_card",
  tax = "tax",
  selphi = "selphi",
  other = "other",
}
