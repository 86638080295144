import React, { useEffect, useState } from "react";
import { Table, Dropdown, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDateMinutes } from "../../helpers/getCorrectDate";

import { getAllTornaments } from "../../services/admin/apiTournamentsAdmin";
import { AdminPanelTabs } from "../../types/adminTypes";
import { TournamentItem } from "../../types/tournamentTypes";
import type { RootState } from "../../store/store";
import { UserRoles } from "../../types/userTypes";

import AddTournamentModal from "./modal/AddTournamentModal";
import InfoTournamentModal from "./modal/InfoTournamentModal";
import DeleteTournamentModal from "./modal/DeleteTournamentModal";

interface Props {
  activeTab: AdminPanelTabs;
}

const TournamentsTab: React.FC<Props> = ({ activeTab }) => {
  const [tournaments, setTournaments] = useState<TournamentItem[]>();
  const [currentTournament, setCurrentTournament] = useState<TournamentItem>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  //   Modals
  const [addModalShow, setAddModalShow] = useState<boolean>(false);
  const [infoModalShow, setInfoModalShow] = useState<boolean>(false);
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);

  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.userInfo.role);

  const loadTournaments = async (page: number): Promise<void> => {
    setFetching(true);
    const result = await getAllTornaments(page + 1);
    if (typeof result !== "string") {
      setTournaments(result.data as TournamentItem[]);
      setTotalPages(result.meta.last_page as number);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (!tournaments && activeTab === AdminPanelTabs.tournaments) {
      loadTournaments(0);
    }
    // eslint-disable-next-line
  }, [activeTab, tournaments]);
  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    loadTournaments(e);
  };

  //   Modals logic
  const toggleAddModal = (): void => {
    setAddModalShow(!addModalShow);
  };
  const handleTournamentAdded = (): void => {
    toggleAddModal();
    loadTournaments(0);
    setActivePage(0);
  };
  const showInfoModal = (item: TournamentItem): void => {
    setInfoModalShow(true);
    setCurrentTournament(item);
  };
  const hideInfoModal = (): void => {
    setInfoModalShow(true);
    setCurrentTournament(undefined);
    loadTournaments(activePage);
  };
  const showDeleteModal = (): void => {
    setDeleteModalShow(true);
  };
  const hideDeleteModal = (): void => {
    setDeleteModalShow(false);
    setCurrentTournament(undefined);
  };
  const handleFreeSpinDeleted = (): void => {
    hideDeleteModal();
    loadTournaments(0);
    setActivePage(0);
    setCurrentTournament(undefined);
  };

  return (
    <div className="admin-spins-warp admin-tab-warp">
      <h3>{t("Tournaments")}</h3>
      <Button
        variant="primary"
        className="mb-3"
        onClick={toggleAddModal}
        disabled={userRole !== UserRoles.admin}
      >
        + {t("Add new tournament")}
      </Button>
      {isFetching && (
        <CirclesWithBar
          height="200"
          width="200"
          color="#0d6efd"
          wrapperStyle={{ justifyContent: "center" }}
          visible
          ariaLabel="circles-with-bar-loading"
        />
      )}
      {!isFetching && (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("Title")}</th>
              <th>{t("Description")}</th>
              <th>{t("Status")}</th>
              <th>{t("Start date")}</th>
              <th>{t("End date")}</th>
              <th>{t("Currency")}</th>
              <th>{t("Fund")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          {tournaments && (
            <tbody>
              {tournaments.map((el) => (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{el.title}</td>
                  <td>{el.description}</td>
                  <td>{el.status}</td>
                  <td>{formatDateMinutes(el.started_at as string)}</td>
                  <td>{formatDateMinutes(el.finished_at as string)}</td>
                  <td>{el.currency}</td>
                  <td>{el.fund}</td>
                  <td>
                    <div className="admin-actions-wrap">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="primary"
                          id="dropdown-basic"
                          disabled={userRole !== UserRoles.admin}
                        >
                          {t("Actions")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(): void => {
                              showInfoModal(el);
                            }}
                          >
                            {t("Info")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(): void => {
                              showDeleteModal();
                              setCurrentTournament(el);
                            }}
                          >
                            {t("Delete")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}
      {totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      {addModalShow && (
        <AddTournamentModal
          show={addModalShow}
          handleClose={toggleAddModal}
          handleTournamentAdded={handleTournamentAdded}
        />
      )}
      {infoModalShow && currentTournament && (
        <InfoTournamentModal
          show={infoModalShow}
          handleClose={hideInfoModal}
          tournament={currentTournament}
        />
      )}
      {deleteModalShow && currentTournament && (
        <DeleteTournamentModal
          show={deleteModalShow}
          handleClose={hideDeleteModal}
          id={currentTournament.id}
          name={currentTournament.title}
          handleTournamentDeleted={handleFreeSpinDeleted}
        />
      )}
    </div>
  );
};

export default TournamentsTab;
