import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { updateWithdrawalStatus } from "../../../services/admin/apiPaymentsAdmin";
import {
  showSuccessNotif,
  showErrorNotif,
} from "../../../helpers/renderNotification";

interface Props {
  id: number;
  payment_system: string;
  show: boolean;
  handleClose: (e?: boolean) => void;
}

const PayoutCommentModal: React.FC<Props> = ({
  show,
  handleClose,
  id,
  payment_system,
}) => {
  const { t } = useTranslation();

  const [formValue, setFormValue] = useState<string>("");
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const handleCommentAdded = async (e: string): Promise<void> => {
    setIsFetching(true);
    const result = await updateWithdrawalStatus(id, 3, payment_system, e);
    if (typeof result !== "string") {
      showSuccessNotif(t("Data added successfully"));
    } else {
      showErrorNotif(result as string);
    }
    setIsFetching(false);
    handleClose(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="admin-bets-wrap user-comments-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Add comment")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-comment-form">
          <Form.Control
            as="textarea"
            className="mb-3"
            rows={3}
            value={formValue}
            onChange={(e): void => {
              setFormValue(e.target.value);
            }}
          />
          <Button
            variant="primary"
            onClick={(): void => {
              handleCommentAdded(formValue);
            }}
            disabled={isFetching}
          >
            {t("Confirm")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PayoutCommentModal;
