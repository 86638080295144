import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { CirclesWithBar } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { PromoCodeData } from "../../../types/promoTypes";
import {
  getPromoData,
  addPromo,
  deactivatePromocode,
} from "../../../services/apiPromo";
import type { RootState } from "../../../store/store";
import styles from "./PromoCodeTabStyles.module.scss";

import VipBar from "../../listItems/VipBar";
import PromocodeInfoModal from "../../modals/promocode/PromocodeInfoModal";
import PromocodeStatusModal from "../../modals/promocode/PromocodeStatusModal";
import DepositPromoCodeView from "./DepositPromocodeView";
import TextField from "../../UI/TextField/TextField";
import MainButton from "../../UI/MainButton/MainButton";
import NoDepositPromoCodeView from "./NoDepositPromoCodeView";

const PromocodesTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [promoValue, setPromoValue] = useState<string>("");
  const [isLoadFetching, setIsLoadFetching] = useState<boolean>(false);
  const [promocodeData, setPromocodeData] = useState<PromoCodeData[] | null>(
    null
  );
  const [showPromoInfoModal, setShowPromoInfoModal] = useState<boolean>(false);
  const [showPromoStatusModal, setShowPromoStatusModal] =
    useState<boolean>(false);
  const [showPromoStatusActivated, setShowPromoStatusActivated] =
    useState<boolean>(false);
  const [showPromoStatusInactive, setShowPromoStatusInactive] =
    useState<boolean>(false);
  const [showPromoStatusReject, setShowPromoStatusReject] =
    useState<boolean>(false);
  const [showPromoStatusAlreadyUsed, setShowPromoStatusAlreadyUsed] =
    useState<boolean>(false);
  const [showPromoStatusDeactivate, setShowPromoStatusDeactivate] =
    useState<boolean>(false);
  const [isPromoFetching, setIsPromoFetching] = useState<boolean>(false);
  const [isPromoSend, setIsPromoSend] = useState<boolean>(false);

  const userId = useSelector((state: RootState) => state.user.userInfo.id);
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  const getPromocodesData = async (): Promise<void> => {
    setIsLoadFetching(true);
    const result = await getPromoData();
    setPromocodeData(result.list as PromoCodeData[]);
    setIsLoadFetching(false);
  };
  const togglePromocodeInfoModal = (): void => {
    setShowPromoInfoModal(!showPromoInfoModal);
    document.body.classList.toggle("no-scroll");
  };

  const togglePromocodeStatusModal = (): void => {
    setShowPromoStatusModal(!showPromoStatusModal);
    setShowPromoStatusActivated(false);
    setShowPromoStatusInactive(false);
    setShowPromoStatusReject(false);
    setShowPromoStatusAlreadyUsed(false);
    setShowPromoStatusDeactivate(false);
    document.body.classList.toggle("no-scroll");
  };

  const toggleShowDeactivateModal = (): void => {
    setShowPromoStatusModal(true);
    setShowPromoStatusDeactivate(true);
  };

  const handlePromoSubmit = async (): Promise<void> => {
    if (promocodeData && promocodeData?.length > 0) {
      setShowPromoStatusReject(true);
      setShowPromoStatusModal(true);
      return;
    }
    setIsPromoFetching(true);
    const result = await addPromo(promoValue || "");
    if (typeof result === "object" && result.message === "SUCCESS") {
      setShowPromoStatusModal(true);
      setShowPromoStatusActivated(true); // Промокод успешно активирован
    } else if (result === "INACTIVE") {
      setShowPromoStatusModal(true);
      setShowPromoStatusInactive(true); // Промокод уже неактивен
    } else if (result === "REJECT") {
      if (promocodeData && promocodeData.length > 0) {
        setShowPromoStatusModal(true);
        setShowPromoStatusReject(true); // У вас уже есть активный промокод
      } else {
        setShowPromoStatusModal(true);
        setShowPromoStatusAlreadyUsed(true); // Вы уже использовали данный промокод
      }
    } else {
      setIsPromoSend(true);
      setTimeout(() => {
        setIsPromoSend(false);
      }, 3000);
    }
    setIsPromoFetching(false);
  };

  const handleDeactivatePromo = (code: string | undefined): void => {
    if (code !== undefined) {
      deactivatePromocode({ data: { code, user_id: userId } });
      setShowPromoStatusModal(false);
      setShowPromoStatusDeactivate(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const renderView = (): JSX.Element => {
    if (promocodeData === null || promocodeData.length === 0) {
      return (
        <div className={styles.no_promo_card}>
          <h5 className={styles.fancy_border_btm}>
            {t("No active promo code")} <br /> {t("Activate promo code")}
          </h5>
          <p>
            {t("Don't have a promo code?")} <br /> {t("Bonus Program")} <br />
            {t("Bonus Program new line")}{" "}
            <div
              className="our-social-networks"
              onClick={(): void => {
                window
                  ?.open("https://taplink.cc/boom_social", "_blank")
                  ?.focus();
              }}
            >
              {t("Our Social Networks")}
            </div>
          </p>
        </div>
      );
    }
    return promocodeData[0].promocode?.is_deposit ? (
      <DepositPromoCodeView
        promoCodeData={promocodeData[0]}
        onClickDeactivatePromoCode={toggleShowDeactivateModal}
        onClickGetBonus={(): void => navigate("/payments")}
      />
    ) : (
      <NoDepositPromoCodeView
        promoCodeData={promocodeData[0]}
        onClickDeactivatePromoCode={toggleShowDeactivateModal}
      />
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getPromocodesData();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={styles.promocode_info_page}>
      <div className={styles.promocode_info_content}>
        <div className={styles.title}>{t("Promo Code")}</div>
        <div className={styles.vip_content}>
          <VipBar />
        </div>
        {showPromoInfoModal && (
          <PromocodeInfoModal
            showPromocodeInfoModal={togglePromocodeInfoModal}
            promocodeData={promocodeData}
          />
        )}
        {showPromoStatusModal && (
          <PromocodeStatusModal
            showPromocodeStatusModal={togglePromocodeStatusModal}
            handleDeactivatePromo={handleDeactivatePromo}
            showPromoStatusReject={showPromoStatusReject}
            showPromoStatusAlreadyUsed={showPromoStatusAlreadyUsed}
            showPromoStatusActivated={showPromoStatusActivated}
            showPromoStatusInactive={showPromoStatusInactive}
            showPromoStatusDeactivate={showPromoStatusDeactivate}
            promocodeData={promocodeData}
            // eslint-disable-next-line
            getPromocodesData={getPromocodesData}
          />
        )}
        <div
          className={`${styles.main_content} ${
            localeActive?.code === "fa" ? styles.rtl : ""
          }`}
        >
          <div className={styles.promocode_input_content}>
            <p>{t("Have a promo code?") || "Have a promo code?"}</p>
            {isPromoSend && (
              <div className={styles.copied_notif}>
                <span>{t("Promo code succesfully activate")}</span>
              </div>
            )}
            <div className={styles.promo_activate}>
              <TextField
                className={classNames(styles.promo_input)}
                value={promoValue}
                onChange={(value): void => {
                  setPromoValue(value);
                }}
                placeholder="Enter promo code"
              />
              <MainButton
                className={styles.promo_main_btn}
                variant="contained"
                color="primary"
                disabled={isPromoFetching || !promoValue}
                onClick={(): void => {
                  handlePromoSubmit();
                }}
                title={t("Activate")}
              />
            </div>
          </div>
          {/* eslint-disable-next-line */}
          {isLoadFetching ? (
            <CirclesWithBar
              height="150"
              width="150"
              color="rgb(248, 176, 0)"
              wrapperStyle={{ justifyContent: "center" }}
              visible
              ariaLabel="circles-with-bar-loading"
            />
          ) : (
            renderView()
          )}
        </div>
      </div>
    </div>
  );
};

export default PromocodesTab;
