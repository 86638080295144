import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { CirclesWithBar } from "react-loader-spinner";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";

import {
  getTornamentInfo,
  startTornament,
  getRewardsList,
} from "../services/apiTournaments";
import { TournamentItem, TournamentStatuses } from "../types/tournamentTypes";
import type { RootState } from "../store/store";
import { updateTournametsCount } from "../store/slices/userData";

import GameItem from "../components/HomePage/GamesView/GameItem/GameItem";

import imgDef from "../images/tournament-banner-big.svg";
import imgGames from "../images/all-games.png";
// import arrowIcon from "../images/arrow-down-new.svg";

const TournamentPage: React.FC = () => {
  const [tournametInfo, setTournametInfo] = useState<TournamentItem>();
  const [myReward, setMyReward] = useState<{
    place: number;
    points: number;
    reward: number;
  }>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isMine, setIsMine] = useState<boolean>(false);
  const [isMineFetching, setIsMineFetching] = useState<boolean>(false);
  // const [shownMembers, setShownMembers] = useState<
  //   {
  //     id: number;
  //     nick_name: string;
  //     points_in_currency: number;
  //     rounds_count: number;
  //     place: number;
  //   }[]
  // >([]);
  // const [shownCount, setShownCount] = useState<number>(20);
  // const nextStepShownMembers = 10;
  const myInfo = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleLoadMore = (): void => {
  //   setShownCount((prevCount) => prevCount + nextStepShownMembers);
  // };

  const loadInfo = async (): Promise<void> => {
    setIsFetching(true);
    const result = await getTornamentInfo(id as string);
    if (typeof result !== "string") {
      setTournametInfo(result.tournament as TournamentItem);
      if (result.tournament.is_mine) setIsMine(true);
    }
    const reward = await getRewardsList(id as string);
    if (typeof reward !== "string") {
      setMyReward(reward.data);
    }
    setIsFetching(false);
  };

  // useEffect(() => {
  //   if (tournametInfo?.users) {
  //     setShownMembers(tournametInfo.users.slice(0, shownCount));
  //   }
  // }, [tournametInfo, shownCount]);

  useEffect(() => {
    loadInfo();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line
  }, []);

  const startTournamentHandler = async (): Promise<void> => {
    setIsMineFetching(true);
    const result = await startTornament(id || "");
    if (typeof result !== "string") {
      setIsMine(true);
      dispatch(updateTournametsCount(true));
      window.location.reload();
      // window.location.reload();
    }
    setIsMineFetching(false);
  };

  const renderStatus = (): JSX.Element => {
    switch (tournametInfo?.status) {
      case TournamentStatuses.active:
        return <div className="status active">{t("Active")}</div>;
      case TournamentStatuses.coming:
        return <div className="status waiting">{t("Upcoming")}</div>;
      case TournamentStatuses.expired:
        return <div className="status finished">{t("Finished")}</div>;
      default:
        return <div className="status">{tournametInfo?.status}</div>;
    }
  };
  const renderBg = (): string => {
    switch (tournametInfo?.bg_color) {
      case "blue":
        return "linear-gradient(180deg, #2D0049 0%, #3492E9 50%, rgba(208, 23, 178, 0) 100%)";
      case "green":
        return "linear-gradient(180deg, #2D0049 0%, #34E987 50%, rgba(208, 23, 178, 0) 100%)";
      case "orange":
        return "linear-gradient(180deg, #2D0049 0%, #E98B34 50%, rgba(208, 23, 178, 0) 100%)";
      default:
        return "linear-gradient(180deg, #2D0049 0%, #3492E9 50%, rgba(208, 23, 178, 0) 100%)";
    }
  };
  return (
    <div className="tournament-page">
      <div className="tournament-page-bg" style={{ background: renderBg() }} />
      <div className="container">
        {isFetching && (
          <CirclesWithBar
            height="100"
            width="100"
            color="#D0174F"
            wrapperStyle={{
              justifyContent: "center",
              position: "relative",
              zIndex: "3",
              paddingTop: "20px",
            }}
            visible
            ariaLabel="circles-with-bar-loading"
          />
        )}
        {!isFetching && tournametInfo && (
          <>
            <div className="tournament-page-banner">
              <img
                className="tournament-page-banner--img"
                src={tournametInfo.image || imgDef}
                alt=""
              />
              <div className="tournament-page-banner--content">
                {renderStatus()}
                <div className="title">{tournametInfo.title}</div>
                {!isMine &&
                  tournametInfo?.status !== TournamentStatuses.expired && (
                    <button
                      className="primary-btn"
                      type="button"
                      onClick={(): void => {
                        startTournamentHandler();
                      }}
                      disabled={isMineFetching}
                    >
                      {t("Participate")}
                    </button>
                  )}
                <div className="info-block">
                  <span>{t("Prize pool")}</span>
                  <div>
                    {getSymbolFromCurrency(tournametInfo.currency)}{" "}
                    {tournametInfo.fund}
                  </div>
                </div>
                <div className="info-block">
                  <span>{t("Date holding")}</span>
                  <div>{`${moment(tournametInfo.started_at).format(
                    "DD.MM"
                  )} - ${moment(tournametInfo.finished_at).format(
                    "DD.MM"
                  )}`}</div>
                </div>
                <div className="info-block">
                  <span>{t("Number of prizes")}</span>
                  <div>{tournametInfo.places.length}</div>
                </div>
              </div>
              <div className="tournament-prize-list">
                {tournametInfo.places.map((el, idx) => (
                  <div className="item" key={el.id}>
                    <div className="item-title">
                      <div className="place">{idx + 1}</div>
                      <span>{t("place")}</span>
                    </div>
                    <div className="item-content">
                      {getSymbolFromCurrency(tournametInfo.currency)}{" "}
                      {el.reward_amount}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="tournament-page-info">
              <div className="title">{t("About the tournament")}</div>
              <div className="description">
                {tournametInfo.description || ""}
              </div>
              <div className="title">{t("How to win?")}</div>
              <ul className="tournament-page-info--list">
                <li>
                  {t(
                    "Click on Participate (the button is available only for registered users)."
                  )}
                </li>
                <li>
                  {t("how_to_wint1", {
                    var: tournametInfo.qualification_rounds_count,
                  })}
                </li>
                <li>
                  {t("how_to_wint2", {
                    var1: tournametInfo.cost_of_point_in_currency,
                    var2: tournametInfo.currency,
                  })}
                </li>
                <li>
                  {t("how_to_wint3", {
                    var1: tournametInfo.min_bet_amount,
                    var2: tournametInfo.currency,
                  })}
                </li>
                <li>
                  {t("how_to_wint4", {
                    var: tournametInfo.places.length,
                  })}
                </li>
                {/* <li>
                  {t(
                    `The tournament is held every Monday (Start/Finish at 12 AM, Kyiv time).`
                  )}
                </li> */}
              </ul>
              <span>{t("Good luck!")}</span>
            </div>
            {tournametInfo?.games && tournametInfo?.games.length > 0 && (
              <>
                <div className="tournament-page--title">
                  {t("Tournament games")}
                </div>
                <div className="games-row">
                  {tournametInfo?.games?.map((el) => (
                    <GameItem
                      key={el.id}
                      name={el.title}
                      bg={el.icons["380x380"]}
                      id={el.id}
                      description={el.description}
                      bgGame={el.icons ? el.icons.background : ""}
                    />
                  ))}
                </div>
              </>
            )}
            {(!tournametInfo?.games || tournametInfo.all_games) && (
              <div className="all-games-wrap">
                <div className="title">
                  {t("All games")}
                  <span>{t("are included in the tournament")}</span>
                </div>
                <img src={imgGames} alt="" />
                <button
                  className="secondary-btn"
                  type="button"
                  onClick={(): void => {
                    navigate("/");
                  }}
                >
                  {t("See games")}
                </button>
              </div>
            )}
            <div className="tournament-page--title">{t("Leaders")}</div>
            <div className="tournament-page-leaders">
              <table className="tournament-page-leaders--table">
                <thead>
                  <tr>
                    <th># {t("Player")}</th>
                    <th>{t("Points")}</th>
                    <th>{t("Prize")}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {tournametInfo.users
                    .slice(
                      0,
                      tournametInfo.users.length < 20
                        ? tournametInfo.users.length
                        : 20
                    ) */}
                  {/* {shownMembers.map((el, idx) => ( */}
                  {tournametInfo.users
                    .slice(0, tournametInfo.places.length)
                    .map((el, idx) => (
                      <tr
                        key={el.id}
                        className={
                          // myReward && myReward?.place === idx + 1 ? "my-row" : ""
                          myReward && myReward?.place === idx + 1
                            ? "my-row"
                            : ""
                        }
                      >
                        <td className="player-cell">
                          <span>{idx + 1}</span> {el.nick_name}
                        </td>
                        <td>{el.points_in_currency}</td>
                        <td>{tournametInfo.places[idx]?.reward_amount}</td>
                      </tr>
                    ))}
                  {/* {isMine && myReward && myReward.place > shownMembers.length && ( */}
                  {myReward && myReward.place > tournametInfo.places.length && (
                    <>
                      <tr className="separate-row">
                        <td colSpan={3}>...</td>
                      </tr>
                      <tr className="my-row">
                        <td className="player-cell">
                          <span>{myReward.place}</span>{" "}
                          {myInfo.userInfo.first_name}{" "}
                          {myInfo.userInfo.last_name}
                        </td>
                        <td>{myReward.points}</td>
                        <td>{myReward.reward}</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td colSpan={2}>{t("Qualification spins")}</td>
                    <td>{tournametInfo.qualification_rounds_count}</td>
                  </tr>
                </tbody>
              </table>
              {!isMine && tournametInfo?.status !== TournamentStatuses.expired && (
                <button
                  className="primary-btn"
                  type="button"
                  onClick={(): void => {
                    startTournamentHandler();
                  }}
                  disabled={isMineFetching}
                >
                  {t("Participate")}
                </button>
              )}
              {/* {shownCount < shownMembers.length ? (
                <button
                  className="border-btn centered"
                  type="button"
                  onClick={handleLoadMore}
                >
                  {t("More games")}
                  <img src={arrowIcon} alt="" />
                </button>
              ) : null} */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TournamentPage;
