import React, { useEffect, useRef, useState } from "react";
// import Carousel from "react-bootstrap/Carousel";
import { ErrorBoundary } from "react-error-boundary";
import { CirclesWithBar, ThreeDots } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import GameItem from "../GameItem/GameItem";
import arrowIcon from "../../../../images/arrow-down-new.svg";
import arrowRight from "../../../../images/arrow-right-new.svg";
import notFound from "../../../../images/categories/not-found.svg";
import { AppDispatch, RootState } from "../../../../store/store";
import {
  clearGames,
  getAllGamesFunc,
  getGamesCategoriesFunc,
  getGamesVendorsFunc,
  setActivePage,
} from "../../../../store/slices/games";
import {
  clearCardGames,
  getAllCardGamesFunc,
  // getGamesCategoriesFunc,
  // getGamesVendorsFunc,
  setCardActivePage,
} from "../../../../store/slices/cardGames";
import {
  clearSlotGames,
  getAllSlotGamesFunc,
  // getGamesCategoriesFunc,
  // getGamesVendorsFunc,
  setSlotActivePage,
} from "../../../../store/slices/slotGames";
import {
  clearLiveGames,
  getAllLiveGamesFunc,
  // getGamesCategoriesFunc,
  // getGamesVendorsFunc,
  setLiveActivePage,
} from "../../../../store/slices/liveGames";
import {
  clearLobbyGames,
  getAllLobbyGamesFunc,
  // getGamesCategoriesFunc,
  // getGamesVendorsFunc,
  setLobbyActivePage,
} from "../../../../store/slices/lobbyGames";
import HomeMenu from "../../../HomeMenu";
import {
  CategoryGameType,
  CategoryItemType,
  VendorItemType,
  // GameItemType,
} from "../../../../types/gameTypes";
import { TournamentItem } from "../../../../types/tournamentTypes";
import { getActiveTornament } from "../../../../services/apiTournaments";
import GamesTitle from "../../GamesTitle/GamesTitle";
import TournamentBanner from "../../../banners/TournamentBanner";

const GameView: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const lobbyRef = useRef(null);
  const tournamentBannerRef = useRef<HTMLDivElement>(null);
  const hasMoreGames = useSelector(
    (state: RootState) => state.games.hasMoreGames
  );
  const hasMoreSlotGames = useSelector(
    (state: RootState) => state.slotGames.hasMoreSlotGames
  );
  const hasMoreCardGames = useSelector(
    (state: RootState) => state.cardGames.hasMoreCardGames
  );
  const hasMoreLiveGames = useSelector(
    (state: RootState) => state.liveGames.hasMoreLiveGames
  );
  const hasMoreLobbyGames = useSelector(
    (state: RootState) => state.lobbyGames.hasMoreLobbyGames
  );
  const categoryFetching = useSelector(
    (state: RootState) => state.games.categoryFetching
  );
  const activePage = useSelector((state: RootState) => state.games.activePage);
  const allFetching = useSelector(
    (state: RootState) => state.games.allFetching
  );
  const cardActivePage = useSelector(
    (state: RootState) => state.cardGames.cardActivePage
  );
  const allCardFetching = useSelector(
    (state: RootState) => state.cardGames.allCardFetching
  );
  const liveActivePage = useSelector(
    (state: RootState) => state.liveGames.liveActivePage
  );
  const allLiveFetching = useSelector(
    (state: RootState) => state.liveGames.allLiveFetching
  );
  const slotActivePage = useSelector(
    (state: RootState) => state.slotGames.slotActivePage
  );
  const allSlotFetching = useSelector(
    (state: RootState) => state.slotGames.allSlotFetching
  );
  const lobbyActivePage = useSelector(
    (state: RootState) => state.lobbyGames.lobbyActivePage
  );
  const allLobbyFetching = useSelector(
    (state: RootState) => state.lobbyGames.allLobbyFetching
  );
  const allGames = useSelector((state: RootState) => state.games.allGames);
  const categories = useSelector((state: RootState) => state.games.categories);
  const vendors = useSelector((state: RootState) => state.games.vendors);

  const cardGames = useSelector(
    (state: RootState) => state.cardGames.cardGames
  );
  const slotGames = useSelector(
    (state: RootState) => state.slotGames.slotGames
  );
  const liveGames = useSelector(
    (state: RootState) => state.liveGames.liveGames
  );
  const lobbyGames = useSelector(
    (state: RootState) => state.lobbyGames.lobbyGames
  );

  const [isSmallGrid, setIsSmallGrid] = useState<boolean>(true);
  const [activeCategory, setCategory] = useState<CategoryItemType | null>({
    id: 0,
    name: CategoryGameType.All,
  });
  const [activeVendor, setVendor] = useState<VendorItemType | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLive, setIsLive] = useState<boolean>();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [activeTournaments, setActiveTournaments] = useState<TournamentItem[]>(
    []
  );
  const [tournamentFetching, setTournamentFetching] = useState<boolean>(false);
  const [tournamentSent, setTournamentSent] = useState<boolean>(false);
  const [tournamentCurrentSlide, setTournamentCurrentSlide] =
    useState<number>(0);
  const [tournamentBannerWidth, setTournamentBannerWidth] = useState<number>(0);
  const totalGames = useSelector((state: RootState) => state.games.totalGames);
  const totalLobbyGames = useSelector(
    (state: RootState) => state.lobbyGames.totalGames
  );
  const totalSlotGames = useSelector(
    (state: RootState) => state.slotGames.totalGames
  );
  const totalLiveGames = useSelector(
    (state: RootState) => state.liveGames.totalGames
  );
  const totalCardGames = useSelector(
    (state: RootState) => state.cardGames.totalGames
  );
  const isLogged = useSelector((state: RootState) => state.user.isLogged);

  const handleSearch = (e: string): void => {
    setSearchValue(e);
    if (
      lobbyGames?.length &&
      slotGames?.length &&
      cardGames?.length &&
      liveGames?.length
    ) {
      dispatch(clearLobbyGames(false));
      dispatch(clearSlotGames(false));
      dispatch(clearLiveGames(false));
      dispatch(clearCardGames(false));
    } else {
      dispatch(clearGames(false));
    }
    if (e === "" && !activeVendor && activeCategory?.id === 0) {
      dispatch(
        getAllLobbyGamesFunc({
          search: null,
          category: null,
          producer: null,
          lobby: true,
          isLive,
        })
      );
      dispatch(setLobbyActivePage(1));
      dispatch(
        getAllSlotGamesFunc({
          search: null,
          category: CategoryGameType.slots,
          producer: null,
          lobby: !activeCategory,
          isLive,
        })
      );
      dispatch(setSlotActivePage(1));
      dispatch(
        getAllLiveGamesFunc({
          search: null,
          category: null,
          producer: null,
          lobby: !activeCategory,
          isLive: true,
        })
      );
      dispatch(setLiveActivePage(1));
      dispatch(
        getAllCardGamesFunc({
          search: null,
          category: CategoryGameType.card,
          producer: null,
          lobby: !activeCategory,
          isLive,
        })
      );
    } else {
      dispatch(
        getAllGamesFunc({
          search: e || null,
          category:
            activeCategory &&
            activeCategory.id !== 0 &&
            activeCategory.name !== CategoryGameType.live_games
              ? activeCategory?.name
              : null,
          producer: activeVendor?.name || null,
          lobby: !activeCategory,
          isLive,
        })
      );
    }
  };

  const loadMoreHandler = (): void => {
    dispatch(
      getAllGamesFunc({
        search: searchValue || null,
        category:
          activeCategory &&
          activeCategory.id !== 0 &&
          activeCategory.name !== CategoryGameType.live_games
            ? activeCategory?.name
            : null,
        producer: activeVendor?.name || null,
        page: activePage + 1,
        lobby: !activeCategory,
        isLive,
      })
    );
    dispatch(setActivePage(activePage + 1));
  };
  const loadMoreLobbyHandler = (): void => {
    dispatch(
      getAllLobbyGamesFunc({
        search: searchValue || null,
        category: null,
        producer: activeVendor?.name || null,
        page: lobbyActivePage + 1,
        lobby: true,
        isLive,
      })
    );
    dispatch(setLobbyActivePage(lobbyActivePage + 1));
  };
  const loadMoreSlotHandler = (): void => {
    dispatch(
      getAllSlotGamesFunc({
        search: searchValue || null,
        category: CategoryGameType.slots,
        producer: activeVendor?.name || null,
        page: slotActivePage + 1,
        lobby: !activeCategory,
        isLive,
      })
    );
    dispatch(setSlotActivePage(slotActivePage + 1));
  };
  const loadMoreLiveHandler = (): void => {
    dispatch(
      getAllLiveGamesFunc({
        search: searchValue || null,
        category: null,
        producer: activeVendor?.name || null,
        page: liveActivePage + 1,
        lobby: !activeCategory,
        isLive: true,
      })
    );
    dispatch(setLiveActivePage(liveActivePage + 1));
  };
  const loadMoreCardHandler = (): void => {
    dispatch(
      getAllCardGamesFunc({
        search: searchValue || null,
        category: CategoryGameType.card,
        producer: activeVendor?.name || null,
        page: cardActivePage + 1,
        lobby: !activeCategory,
        isLive,
      })
    );
    dispatch(setCardActivePage(cardActivePage + 1));
  };

  const toggleCategory = (e: CategoryItemType | null): void => {
    if (activeCategory?.id === 0) {
      dispatch(clearLobbyGames(false));
      dispatch(clearSlotGames(false));
      dispatch(clearLiveGames(false));
      dispatch(clearCardGames(false));
    }
    if (activeCategory?.name === CategoryGameType.live_games) {
      setIsLive(false);
    }

    dispatch(clearGames(false));
    setCategory(e);
    if (searchValue || activeVendor || !e || e?.id !== 0) {
      let isLiveCategory;
      if (e?.name === CategoryGameType.live_games) {
        isLiveCategory = true;
        setIsLive(true);
      }
      dispatch(
        getAllGamesFunc({
          search: searchValue || null,
          category: e && e.id !== 0 && !isLiveCategory ? e?.name : null,
          producer: activeVendor?.name || null,
          lobby: !e,
          isLive: isLiveCategory,
        })
      );
      dispatch(setActivePage(1));
    } else {
      dispatch(
        getAllLobbyGamesFunc({
          search: null,
          category: null,
          producer: null,
          lobby: true,
          isLive: false,
        })
      );
      dispatch(setLobbyActivePage(1));
      dispatch(
        getAllSlotGamesFunc({
          search: null,
          category: CategoryGameType.slots,
          producer: null,
          lobby: !activeCategory,
          isLive: false,
        })
      );
      dispatch(setSlotActivePage(1));
      dispatch(
        getAllLiveGamesFunc({
          search: null,
          category: null,
          producer: null,
          lobby: !activeCategory,
          isLive: true,
        })
      );
      dispatch(setLiveActivePage(1));
      dispatch(
        getAllCardGamesFunc({
          search: null,
          category: CategoryGameType.card,
          producer: null,
          lobby: !activeCategory,
          isLive: false,
        })
      );
      dispatch(setCardActivePage(1));
    }
  };

  const toggleVendor = (e: VendorItemType | null): void => {
    setVendor(e);
    if (
      lobbyGames?.length &&
      slotGames?.length &&
      cardGames?.length &&
      liveGames?.length
    ) {
      dispatch(clearLobbyGames(false));
      dispatch(clearSlotGames(false));
      dispatch(clearLiveGames(false));
      dispatch(clearCardGames(false));
    } else {
      dispatch(clearGames(false));
    }
    if (e || searchValue || activeCategory?.id !== 0) {
      dispatch(
        getAllGamesFunc({
          search: searchValue || null,
          category:
            activeCategory &&
            activeCategory.id !== 0 &&
            activeCategory.name !== CategoryGameType.live_games
              ? activeCategory?.name
              : null,
          producer: e?.name || null,
          lobby: !activeCategory,
          isLive,
        })
      );
      dispatch(setActivePage(1));
    } else {
      dispatch(
        getAllLobbyGamesFunc({
          search: null,
          category: null,
          producer: null,
          lobby: true,
          isLive,
        })
      );
      dispatch(setLobbyActivePage(1));
      dispatch(
        getAllSlotGamesFunc({
          search: null,
          category: CategoryGameType.slots,
          producer: null,
          lobby: !activeCategory,
          isLive,
        })
      );
      dispatch(setSlotActivePage(1));
      dispatch(
        getAllLiveGamesFunc({
          search: null,
          category: null,
          producer: null,
          lobby: !activeCategory,
          isLive: true,
        })
      );
      dispatch(setLiveActivePage(1));
      dispatch(
        getAllCardGamesFunc({
          search: null,
          category: CategoryGameType.card,
          producer: null,
          lobby: !activeCategory,
          isLive,
        })
      );
      dispatch(setCardActivePage(1));
    }
  };

  const toggleGrid = (e: boolean): void => {
    setIsSmallGrid(e);
  };

  useEffect(() => {
    if (
      !lobbyGames &&
      !slotGames &&
      !liveGames &&
      !cardGames &&
      !allLobbyFetching &&
      !allSlotFetching &&
      !allLiveFetching &&
      !allCardFetching &&
      typeof isLive !== "undefined" &&
      isReady
    ) {
      dispatch(
        getAllLobbyGamesFunc({
          search: null,
          category: null,
          producer: null,
          lobby: true,
          isLive: false,
        })
      );
      dispatch(
        getAllSlotGamesFunc({
          search: null,
          category: CategoryGameType.slots,
          producer: null,
          lobby: false,
          isLive: false,
        })
      );
      dispatch(
        getAllLiveGamesFunc({
          search: null,
          category: null,
          producer: null,
          lobby: false,
          isLive: true,
        })
      );
      dispatch(
        getAllCardGamesFunc({
          search: null,
          category: CategoryGameType.card,
          producer: null,
          lobby: false,
          isLive: false,
        })
      );
    }
  });

  useEffect(() => {
    setIsReady(false);
    if (location?.state?.toLobby && lobbyRef.current) {
      setTimeout(() => {
        // @ts-ignore
        lobbyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 200);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    if (location?.state?.isLive) {
      toggleCategory({ id: 10, name: CategoryGameType.live_games });
    } else {
      toggleCategory({ id: 0, name: CategoryGameType.All });
    }

    // setTimeout(() => {
    //   dispatch(clearGames(true));
    //   dispatch(setActivePage(1));
    //   setIsReady(true);
    // }, 500);

    setIsReady(true);
    // eslint-disable-next-line
  }, [location.state]);

  useEffect(() => {
    if (!categories) {
      dispatch(getGamesCategoriesFunc());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (!vendors) {
      dispatch(getGamesVendorsFunc());
    }
  }, [dispatch, vendors]);

  const renderTitle = (): string => {
    if (activeCategory?.id === 0) {
      return t("Lobby");
    }
    if (isLive) {
      return "Live games";
    }
    if (activeCategory) {
      return `${activeCategory.name.charAt(0).toUpperCase() as string}${
        activeCategory.name.slice(1) as string
      }`;
    }
    return t("Lobby");
  };

  const loadTournaments = async (): Promise<void> => {
    setTournamentSent(true);
    setTournamentFetching(true);
    const result = await getActiveTornament(isLogged);
    if (typeof result !== "string" && result?.data?.length > 0) {
      setActiveTournaments(result.data as TournamentItem[]);
    }
    setTournamentFetching(false);
  };

  const toggleTournamentSlide = (isNext?: boolean): void => {
    if (isNext) {
      setTournamentCurrentSlide(tournamentCurrentSlide + 1);
    } else {
      setTournamentCurrentSlide(tournamentCurrentSlide - 1);
    }
  };

  // useEffect(() => {
  //   if (typeof isLogged !== "undefined" && !tournamentSent) loadTournaments();
  //   // eslint-disable-next-line
  // }, [isLogged, tournamentSent]);

  useEffect(() => {
    if (!tournamentSent) loadTournaments();
  });

  useEffect(() => {
    if (
      tournamentBannerRef.current &&
      tournamentBannerRef.current.offsetWidth > tournamentBannerWidth
    ) {
      setTournamentBannerWidth(tournamentBannerRef.current.offsetWidth);
    }
  }, [tournamentBannerRef.current?.offsetWidth, tournamentBannerWidth]);

  // HELLO
  console.log(activeCategory);
  console.log(renderTitle());
  return (
    <div className="container">
      <HomeMenu
        categories={categories || []}
        isFetching={categoryFetching}
        activeCategory={activeCategory}
        toggleCategory={toggleCategory}
        searchValue={searchValue}
        handleSearch={handleSearch}
        toggleVendor={toggleVendor}
        vendors={vendors || []}
        activeVendor={activeVendor}
        toggleGrid={toggleGrid}
      />
      {activeCategory?.id === 0 && !searchValue && !activeVendor ? (
        <>
          <GamesTitle
            txt={t("Lobby")}
            badge={totalLobbyGames.toString()}
            myRef={lobbyRef}
          />
          {/* -------- Category games block -------- */}
          <>
            <div
              className={
                !hasMoreLobbyGames ? "games-main-wrap all" : "games-main-wrap"
              }
            >
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <div className={isSmallGrid ? "games-row" : "games-row big"}>
                  {lobbyGames?.map((el) => (
                    <GameItem
                      key={el.id}
                      name={el?.title || ""}
                      bg={el?.icons["380x380"] || ""}
                      id={el?.id || 0}
                      description={el?.description || ""}
                      bgGame={el?.icons ? el.icons?.background : ""}
                    />
                  ))}
                  {/* {hasMoreLobbyGames && <div className="games-gradient" />} */}
                </div>
              </ErrorBoundary>
              {allLobbyFetching && !lobbyGames && (
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="rgb(248, 176, 0)"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              )}
            </div>
            {hasMoreLobbyGames && lobbyGames && (
              <button
                className="border-btn centered"
                type="button"
                disabled={allLobbyFetching}
                onClick={loadMoreLobbyHandler}
              >
                {allLobbyFetching ? (
                  <ThreeDots
                    height="15"
                    width="30"
                    color="rgb(248, 176, 0)"
                    wrapperStyle={{ justifyContent: "center" }}
                    visible
                    ariaLabel="circles-with-bar-loading"
                  />
                ) : (
                  <>
                    {t("More games")}
                    <img src={arrowIcon} alt="" />
                  </>
                )}
              </button>
            )}
          </>
          <GamesTitle
            txt={t("Slots")}
            badge={totalSlotGames.toString()}
            myRef={lobbyRef}
          />
          {/* -------- Category games block -------- */}
          <>
            <div
              className={
                !hasMoreSlotGames ? "games-main-wrap all" : "games-main-wrap"
              }
            >
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <div className={isSmallGrid ? "games-row" : "games-row big"}>
                  {slotGames?.map((el) => (
                    <GameItem
                      key={el.id}
                      name={el?.title || ""}
                      bg={el?.icons["380x380"] || ""}
                      id={el?.id || 0}
                      description={el?.description || ""}
                      bgGame={el?.icons ? el.icons?.background : ""}
                    />
                  ))}
                  {/* {hasMoreSlotGames && <div className="games-gradient" />} */}
                </div>
              </ErrorBoundary>
              {allSlotFetching && !slotGames && (
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="rgb(248, 176, 0)"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              )}
            </div>
            {hasMoreSlotGames && slotGames && (
              <button
                className="border-btn centered"
                type="button"
                disabled={allSlotFetching}
                onClick={loadMoreSlotHandler}
              >
                {allSlotFetching ? (
                  <ThreeDots
                    height="15"
                    width="30"
                    color="rgb(248, 176, 0)"
                    wrapperStyle={{ justifyContent: "center" }}
                    visible
                    ariaLabel="circles-with-bar-loading"
                  />
                ) : (
                  <>
                    {t("More games")}
                    <img src={arrowIcon} alt="" />
                  </>
                )}
              </button>
            )}
          </>
          <GamesTitle
            txt={t("Live")}
            badge={totalLiveGames.toString()}
            myRef={lobbyRef}
          />
          {/* -------- Category games block -------- */}
          <>
            <div
              className={
                !hasMoreLiveGames ? "games-main-wrap all" : "games-main-wrap"
              }
            >
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <div className={isSmallGrid ? "games-row" : "games-row big"}>
                  {liveGames?.map((el) => (
                    <GameItem
                      key={el.id}
                      name={el?.title || ""}
                      bg={el?.icons["380x380"] || ""}
                      id={el?.id || 0}
                      description={el?.description || ""}
                      bgGame={el?.icons ? el.icons?.background : ""}
                    />
                  ))}
                  {/* {hasMoreLiveGames && <div className="games-gradient" />} */}
                </div>
              </ErrorBoundary>
              {allLiveFetching && !liveGames && (
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="rgb(248, 176, 0)"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              )}
            </div>
            {hasMoreLiveGames && liveGames && (
              <button
                className="border-btn centered"
                type="button"
                disabled={allLiveFetching}
                onClick={loadMoreLiveHandler}
              >
                {allLiveFetching ? (
                  <ThreeDots
                    height="15"
                    width="30"
                    color="rgb(248, 176, 0)"
                    wrapperStyle={{ justifyContent: "center" }}
                    visible
                    ariaLabel="circles-with-bar-loading"
                  />
                ) : (
                  <>
                    {t("More games")}
                    <img src={arrowIcon} alt="" />
                  </>
                )}
              </button>
            )}
          </>
          <div className="home-tournament-wrap">
            {activeTournaments.length > 0 && (
              <GamesTitle
                txt={t("Active tournament")}
                badge={activeTournaments.length.toString()}
              />
            )}
            {tournamentFetching && (
              <CirclesWithBar
                height="100"
                width="100"
                color="rgb(248, 176, 0)"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            )}
            {!tournamentFetching && activeTournaments.length > 0 && (
              <div className="home-menu-tournaments-tabs">
                <div
                  className={
                    tournamentCurrentSlide === 0
                      ? "tab-btn prev hidden"
                      : "tab-btn prev"
                  }
                  onClick={(): void => {
                    toggleTournamentSlide();
                  }}
                >
                  <img src={arrowRight} alt="" />
                </div>
                {tournamentCurrentSlide > 0 && (
                  <div className="tournament-gradient-left" />
                )}
                <div className="home-menu-tournaments-tabs--wrap">
                  <div
                    className="home-menu-tournaments-tabs--box"
                    style={{
                      transform: `translateX(-${
                        tournamentBannerWidth * tournamentCurrentSlide
                      }px)`,
                    }}
                  >
                    {activeTournaments.map((tournament, index) => (
                      <div
                        ref={tournamentBannerRef}
                        className={
                          index === tournamentCurrentSlide
                            ? "tab-item active"
                            : "tab-item"
                        }
                        key={tournament.id}
                      >
                        <TournamentBanner
                          // elementRef={tournamentBannerRef}
                          title={tournament.title}
                          prize={`${
                            getSymbolFromCurrency(tournament.currency) || ""
                          } ${tournament.fund}`}
                          date={`${moment(tournament.started_at).format(
                            "DD.MM"
                          )} - ${moment(tournament.finished_at).format(
                            "DD.MM"
                          )}`}
                          status={tournament.status}
                          prizesCount={tournament.places.length}
                          currency={tournament.currency}
                          spins={tournament.qualification_rounds_count}
                          users={tournament.users}
                          places={tournament.places}
                          showLogin={(): void => navigate("/login")}
                          id={tournament.id}
                          img={tournament.image}
                          bg={tournament.bg_color}
                          isHome
                        />
                        {tournament?.games && tournament?.games.length > 0 && (
                          <>
                            <GamesTitle txt={t("Tournament games")} />
                            <div className="games-row">
                              {tournament?.games?.map((el) => (
                                <GameItem
                                  key={el.id}
                                  name={el.title}
                                  bg={el.icons["380x380"]}
                                  id={el.id}
                                  description={el.description}
                                  bgGame={el.icons ? el.icons.background : ""}
                                />
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {tournamentCurrentSlide < activeTournaments.length - 1 && (
                  <div className="tournament-gradient-right" />
                )}
                <div
                  className={
                    tournamentCurrentSlide === activeTournaments.length - 1
                      ? "tab-btn next hidden"
                      : "tab-btn next"
                  }
                  onClick={(): void => {
                    toggleTournamentSlide(true);
                  }}
                >
                  <img src={arrowRight} alt="" />
                </div>
              </div>
            )}
          </div>
          <GamesTitle
            txt={t("Card")}
            badge={totalCardGames.toString()}
            myRef={lobbyRef}
          />
          {/* -------- Category games block -------- */}
          <>
            <div
              className={
                !hasMoreCardGames ? "games-main-wrap all" : "games-main-wrap"
              }
            >
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <div className={isSmallGrid ? "games-row" : "games-row big"}>
                  {cardGames?.map((el) => (
                    <GameItem
                      key={el.id}
                      name={el?.title || ""}
                      bg={el?.icons["380x380"] || ""}
                      id={el?.id || 0}
                      description={el?.description || ""}
                      bgGame={el?.icons ? el.icons?.background : ""}
                    />
                  ))}
                  {/* {hasMoreCardGames && <div className="games-gradient" />} */}
                </div>
              </ErrorBoundary>
              {allCardFetching && !cardGames && (
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="rgb(248, 176, 0)"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              )}
            </div>
            {hasMoreCardGames && cardGames && (
              <button
                className="border-btn centered"
                type="button"
                disabled={allCardFetching}
                onClick={loadMoreCardHandler}
              >
                {allCardFetching ? (
                  <ThreeDots
                    height="15"
                    width="30"
                    color="rgb(248, 176, 0)"
                    wrapperStyle={{ justifyContent: "center" }}
                    visible
                    ariaLabel="circles-with-bar-loading"
                  />
                ) : (
                  <>
                    {t("More games")}
                    <img src={arrowIcon} alt="" />
                  </>
                )}
              </button>
            )}
          </>
        </>
      ) : (
        <>
          {activeCategory?.id !== 0 || (!searchValue && !activeVendor) ? (
            <GamesTitle
              txt={renderTitle()}
              badge={totalGames.toString()}
              myRef={lobbyRef}
            />
          ) : null}
          {allGames?.length === 0 && !allFetching ? (
            <div className="not-found-container">
              <div className="icon">
                <img src={notFound} alt="" />
              </div>
              {activeVendor ? (
                <div className="info">
                  <div className="title">
                    {t(
                      "No games from this provider were found in this category!"
                    )}
                  </div>
                  <div className="subtitle">
                    {t(
                      "To get the result, select another game provider or change the game category."
                    )}
                  </div>
                </div>
              ) : (
                <div className="info">
                  <div className="title">{t("No games found!")}</div>
                  <div className="subtitle">
                    {t("You can try a different keyword.")}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <div
                className={
                  !hasMoreGames ? "games-main-wrap all" : "games-main-wrap"
                }
              >
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                  {allGames !== null && (
                    <div
                      className={isSmallGrid ? "games-row" : "games-row big"}
                    >
                      {allGames.map((el) => (
                        <GameItem
                          key={el.id}
                          name={el?.title || ""}
                          bg={el?.icons["380x380"] || ""}
                          id={el?.id || 0}
                          description={el?.description || ""}
                          bgGame={el?.icons ? el.icons?.background : ""}
                        />
                      ))}
                      {/* {hasMoreGames && <div className="games-gradient" />} */}
                    </div>
                  )}
                </ErrorBoundary>
                {allFetching && !allGames && (
                  <CirclesWithBar
                    height="100"
                    width="100"
                    color="rgb(248, 176, 0)"
                    wrapperStyle={{ justifyContent: "center" }}
                    visible
                    ariaLabel="circles-with-bar-loading"
                  />
                )}
              </div>
              {hasMoreGames && allGames && (
                <button
                  className="border-btn centered"
                  type="button"
                  disabled={allFetching}
                  onClick={loadMoreHandler}
                >
                  {allFetching ? (
                    <ThreeDots
                      height="15"
                      width="30"
                      color="rgb(248, 176, 0)"
                      wrapperStyle={{ justifyContent: "center" }}
                      visible
                      ariaLabel="circles-with-bar-loading"
                    />
                  ) : (
                    <>
                      {t("More games")}
                      <img src={arrowIcon} alt="" />
                    </>
                  )}
                </button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GameView;
