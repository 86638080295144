import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";

import { Outlet } from "react-router-dom";
import { HomeBetItem } from "../../../types/betsTypes";
// import { AppDispatch } from "../../../store/store";
// import { clearGames, setActivePage } from "../../../store/slices/games";
import MainSlider from "../../sliders/MainSlider/MainSlider";
import WinnersBox from "../WinnersBox/WinnersBox";
import GameView from "../GamesView/GameView/GameView";

interface Props {
  newBets?: HomeBetItem[];
}
const HomePageLayout: React.FC<Props> = ({ newBets }) => {
  const [, setHomeBets] = useState<HomeBetItem[]>([]);

  // const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (newBets && newBets.length > 0) {
      setHomeBets(newBets);
    }
  }, [newBets]);

  console.log("newBets", newBets);

  // useEffect(() => {
  //   const widget = document.getElementsByClassName(
  //     "widget-wrap"
  //   )[0] as HTMLElement;
  //   widget?.classList?.add("visible");
  //   return () => {
  //     dispatch(clearGames(true));
  //     dispatch(setActivePage(1));
  //     widget?.classList?.remove("visible");
  //   };
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className="home-page">
      <MainSlider />
      <WinnersBox />
      <GameView />
      <Outlet />
    </div>
  );
};

export default HomePageLayout;
