import ApiClient from "../ApiClient";
import { RequestError, DeafaultSuccesResponse } from "../../types/requestTypes";
import {
  TournamentItem,
  AddTournamentPayload,
} from "../../types/tournamentTypes";

export const getAllTornaments = async (
  page: number
): Promise<
  | {
      data: TournamentItem[];
      meta: {
        last_page: number;
      };
    }
  | string
> => {
  try {
    const result = await ApiClient.get<{
      data: TournamentItem[];
      meta: {
        last_page: number;
      };
    }>(`/tournaments/list?page=${page}&per_page=20`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const addNewTornament = async (
  data: AddTournamentPayload
): Promise<DeafaultSuccesResponse | string> => {
  const formData = new FormData();
  if (data.img) {
    formData.append("image", data.img, data.img.name as string);
  }
  Object.keys(data).forEach((el) => {
    if (el !== "img") {
      // @ts-ignore
      // eslint-disable-next-line
      formData.append(el, data[el]);
    }
  });
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      "/tournaments/create",
      formData
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editCommonInfo = async (
  data: AddTournamentPayload,
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  const formData = new FormData();
  if (data.img) {
    formData.append("image", data.img, data.img.name as string);
  }
  Object.keys(data).forEach((el) => {
    if (el !== "img") {
      // @ts-ignore
      // eslint-disable-next-line
        formData.append(el, data[el]);
    }
  });
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/tournaments/update/${id}`,
      formData
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editGamesInfo = async (
  data: AddTournamentPayload,
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/tournaments/update/${id}`,
      data
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const editPrizesInfo = async (
  data: AddTournamentPayload,
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/tournaments/update/${id}`,
      data
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const addTournamentUsers = async (
  data: number[],
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.post<DeafaultSuccesResponse>(
      `/tournaments/users/${id}/attach`,
      { users_ids: data }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const removeTournamentUsers = async (
  data: string[],
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/tournaments/users/${id}/detach`,
      { users_ids: data }
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export const deleteTournament = async (
  id: number
): Promise<DeafaultSuccesResponse | string> => {
  try {
    const result = await ApiClient.delete<DeafaultSuccesResponse>(
      `/tournaments/delete/${id}`
    );
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};
