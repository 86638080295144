import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import exceptionBonusesGames from "../../constants/ExceptionBonusesGames";

enum SortGamesTabs {
  numbers = "0-9",
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  I = "I",
  J = "J",
  K = "K",
  L = "L",
  M = "M",
  N = "N",
  O = "O",
  P = "P",
  Q = "Q",
  R = "R",
  S = "S",
  T = "T",
  U = "U",
  V = "V",
  W = "W",
  X = "X",
  Z = "Z",
  ALL = "ALL",
}

const SortedGames = (): JSX.Element => {
  const [key, setKey] = useState<string>(SortGamesTabs.numbers);
  const [selectedTab, setSelectedTab] = useState<string[]>([]);

  const filterAndSortGames = (tab: string): string[] => {
    if (tab === "ALL") {
      return exceptionBonusesGames;
    }
    const filteredArray = exceptionBonusesGames.filter((str) => {
      if (tab === "0-9") {
        return /^[0-9]/.test(str);
      }
      return str.toLocaleLowerCase().startsWith(tab.toLocaleLowerCase());
    });
    return filteredArray.sort();
  };

  useEffect(() => {
    setSelectedTab(filterAndSortGames(key));
  }, [key]);

  return (
    <div className="accessible-games-wrapper">
      <Tabs
        activeKey={key}
        onSelect={(tabKey): void => {
          setKey(tabKey as SortGamesTabs);
        }}
        className="tabs-list"
      >
        {Object.values(SortGamesTabs).map((tab) => {
          return (
            <Tab eventKey={tab} title={tab} key={tab}>
              {tab === key && (
                <ul>
                  {selectedTab.map((game) => {
                    return <li key={game}>{game}</li>;
                  })}
                </ul>
              )}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default SortedGames;
