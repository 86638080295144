import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CirclesWithBar } from "react-loader-spinner";
import moment from "moment";
import {
  formatDateDay,
  formatDateDayMomentApi,
} from "../../helpers/getCorrectDate";

import { getOrders, getWithdrawals } from "../../services/apiPayments";
import { DepositItem, WithdrawalItemNew } from "../../types/paymentTypes";

import DarkSelect from "../forms/DarkSelect";

enum Types {
  deposit = "deposit",
  withdrawal = "withdrawal",
}
enum Periods {
  all = "all",
  today = "today",
  yesterday = "yesterday",
  this_month = "this_month",
  last_month = "last_month",
}

const TransactionsTable: React.FC = () => {
  const [activeType, setActiveType] = useState<{
    value: Types;
    title: string;
  }>({ value: Types.deposit, title: "Deposit" });
  const [activePeriod, setActivePeriod] = useState<{
    value: Periods;
    title: string;
  }>({ value: Periods.today, title: "Today" });
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [list, setList] = useState<DepositItem[]>([]);
  const [listWithdrawal, setListWithdrawal] = useState<WithdrawalItemNew[]>([]);

  const { t } = useTranslation();

  const getFilters = (): string => {
    switch (activePeriod.value) {
      case Periods.today:
        return `start_date=${formatDateDayMomentApi()} 00:00&end_date=${formatDateDayMomentApi()} 23:59`;
      case Periods.yesterday:
        return `start_date=${moment()
          .utc()
          .subtract(1, "days")
          .format("YYYY-MM-DD")} 00:00&end_date=${moment()
          .utc()
          .subtract(1, "days")
          .format("YYYY-MM-DD")} 23:59`;
      case Periods.this_month:
        return `start_date=${moment
          .utc()
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm")}&end_date=${moment
          .utc()
          .clone()
          .endOf("month")
          .format("YYYY-MM-DD HH:mm")}`;
      case Periods.last_month:
        return `start_date=${moment
          .utc()
          .subtract(1, "month")
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm")}&end_date=${moment
          .utc()
          .subtract(1, "month")
          .clone()
          .endOf("month")
          .format("YYYY-MM-DD HH:mm")}`;

      case Periods.all:
      default:
        return "";
    }
  };
  const loadOrders = async (): Promise<void> => {
    setIsFetching(true);
    const filter = getFilters();
    const result =
      activeType.value === Types.deposit
        ? await getOrders(filter)
        : await getWithdrawals(filter);
    if (typeof result !== "string" && activeType.value === Types.deposit) {
      setList(result as DepositItem[]);
    }
    if (typeof result !== "string" && activeType.value === Types.withdrawal) {
      // @ts-ignore
      setListWithdrawal(result.data as WithdrawalItemNew[]);
    }
    setIsFetching(false);
  };
  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line
  }, [activePeriod, activeType]);

  return (
    <div className="transactions-table-wrap">
      <div className="filters-row">
        <DarkSelect
          items={[
            {
              value: Periods.today,
              title: "Today",
            },
            {
              value: Periods.yesterday,
              title: "Yesterday",
            },
            {
              value: Periods.this_month,
              title: "This month",
            },
            {
              value: Periods.last_month,
              title: "Last month",
            },
            {
              value: Periods.all,
              title: "All the time",
            },
          ]}
          handleSelect={(e): void => {
            setActivePeriod(e as { value: Periods; title: string });
          }}
          selectedItem={activePeriod}
          placeholder="Select date"
        />
        <DarkSelect
          items={[
            {
              value: Types.deposit,
              title: "Deposit",
            },
            {
              value: Types.withdrawal,
              title: "Withdrawal",
            },
          ]}
          handleSelect={(e): void => {
            setActiveType(e as { value: Types; title: string });
          }}
          selectedItem={activeType}
          placeholder="Select type"
        />
      </div>
      <div className="transactions-table">
        {isFetching && (
          <tr>
            <td colSpan={3}>
              <CirclesWithBar
                height="100"
                width="100"
                color="rgb(248, 176, 0)"
                wrapperStyle={{ justifyContent: "center" }}
                visible
                ariaLabel="circles-with-bar-loading"
              />
            </td>
          </tr>
        )}
        {!isFetching && activeType.value === Types.deposit && (
          <>
            <div className="header">
              <div className="col">{t("Date")}</div>
              <div className="col">{t("Amount")}</div>
              <div className="col">{t("Type")}</div>
              <div className="col">{t("Payment system")}</div>
            </div>
            {list.map((el) => (
              <div className="row" key={el.id}>
                <div className="col">
                  {formatDateDay(el.created_at as string)}
                </div>
                <div className="col">{el.converted_amount}</div>
                <div className="col">{t(activeType.title)}</div>
                <div className="col">{el.payment_system}</div>
              </div>
            ))}
          </>
        )}
        {!isFetching && activeType.value === Types.withdrawal && (
          <>
            <div className="header">
              <div className="col">{t("Date")}</div>
              <div className="col">{t("Amount")}</div>
              <div className="col">{t("Type")}</div>
              <div className="col">{t("Payment system")}</div>
            </div>
            {listWithdrawal.map((el) => (
              <div className="row" key={el.id}>
                <div className="col">
                  {formatDateDay(el.created_at as string)}
                </div>
                <div className="col">{el.amount}</div>
                <div className="col">{t(activeType.title)}</div>
                <div className="col">{el.payment_system}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionsTable;
