import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactInputVerificationCode from "react-input-verification-code";
import { useSelector } from "react-redux";

import {
  confirmMail,
  confirmPhone,
  confirmPhoneCode,
} from "../../services/apiLogin";
import type { RootState } from "../../store/store";

import SuccessModal from "../modals/SuccessModal";
import ErrorModal from "../modals/ErrorModal";

enum Stages {
  sent = "sent",
  success = "success",
  code = "code",
}

enum Tabs {
  mail = "mail",
  phone = "phone",
}

interface Props {
  mail?: string;
  phone?: string;
  isMail: boolean;
  returnHandler: () => void;
}

const limit = 300;

const ConfirmContacts: React.FC<Props> = ({
  mail,
  phone,
  isMail,
  returnHandler,
}) => {
  // Conditions
  const [stage, setStage] = useState<Stages>();
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.mail);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  // Values
  const [codeValue, setCodeValue] = useState<string>("");
  const [timerValue, setTimerValue] = useState<number>(limit);
  //   Errors
  const [globalError, setGlobalError] = useState<string>("");

  const { t } = useTranslation();

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  //   Form submit logic
  const handleSendCode = async (): Promise<void> => {
    setFetching(true);
    const result = await confirmPhoneCode(codeValue);
    if (typeof result === "string") {
      setGlobalError(result as string);
      setFetching(false);
      document.body.classList.add("no-scroll");
      document.body.classList.add("menu-hidden");
      return;
    }
    setFetching(false);
    setStage(Stages.success);
  };
  const handleSendMail = async (): Promise<void> => {
    setFetching(true);
    const result = await confirmMail(mail || "", "profile");
    if (typeof result === "string") {
      setGlobalError(result as string);
      setFetching(false);
      document.body.classList.add("no-scroll");
      document.body.classList.add("menu-hidden");
      return;
    }
    setFetching(false);
  };
  const handleSendPhone = async (): Promise<void> => {
    setFetching(true);
    const result = await confirmPhone(phone || "");
    if (typeof result === "string") {
      setGlobalError(result as string);
      setFetching(false);
      document.body.classList.add("no-scroll");
      document.body.classList.add("menu-hidden");
      return;
    }
    setStage(Stages.code);
    setFetching(false);
    setTimerStarted(true);
  };

  //   Initial logic
  useEffect(() => {
    if (window.location.search.includes("isMailConfirmed")) {
      setStage(Stages.success);
    }
  }, [userInfo]);
  useEffect(() => {
    if (isMail) {
      setActiveTab(Tabs.mail);
      if (window.location.search.includes("isMailConfirmed")) {
        setStage(Stages.success);
        document.body.classList.add("no-scroll");
        document.body.classList.add("menu-hidden");
      } else {
        setStage(Stages.sent);
        handleSendMail();
      }
    } else {
      setActiveTab(Tabs.phone);
      handleSendPhone();
    }
    // eslint-disable-next-line
  }, [isMail]);

  //   Timer logic
  useEffect(() => {
    if (timerValue === 0 || !timerStarted) return;
    const intervalId = setInterval(() => {
      setTimerValue(timerValue - 1);
    }, 1000);
    // eslint-disable-next-line
      return () => {
      clearInterval(intervalId);
    };
  }, [timerValue, timerStarted]);

  return (
    <div className="login-page reset-password-page">
      <div className="login-page-container">
        <div className="page-title">
          {isMail ? t("Email confirmation") : t("Phone Verification")}
        </div>
        <div className="login-content-wrap">
          {stage === Stages.success && (
            <SuccessModal
              title={
                isMail ? t("Mail confirmed") || "" : t("Phone confirmed") || ""
              }
              mainTxt={
                isMail
                  ? t(
                      "Now you can use the specified mail to log into your account."
                    )
                  : t(
                      "You can now use the provided phone number to log into your account."
                    )
              }
              closeModalHandler={(): void => {
                window.location.replace("/profile");
              }}
              secondBtnHandler={(): void => {
                window.location.replace("/profile");
              }}
              mainBtnHandler={(): void => {
                window.location.replace("/");
              }}
              mainBtnTxt={t("To main") || "To main"}
              secondBtnTxt={t("Back to profile") || "Back to profile"}
            />
          )}
          {activeTab === Tabs.mail && (
            <>
              <span />
              {stage === Stages.sent && (
                <div className="mail-sent-wrapper">
                  <div className="password-change-txt">
                    {t("mailPassswordTxt1")}
                    <span> {mail || ""} </span>
                    {t("mailPassswordTxt3")}
                  </div>
                  <div className="reset-block">
                    <div className="txt">
                      {t("Didn't receive instructions?")}
                    </div>
                    <div
                      className="btn"
                      onClick={(): void => {
                        returnHandler();
                      }}
                    >
                      {t("Resend")}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {activeTab === Tabs.phone && (
            <>
              <span />
              {stage === Stages.code && (
                <>
                  <div className="code-sent-txt">
                    <div className="txt">
                      {t("We have sent a verification code.")}
                    </div>
                    <div className="timer">
                      {t("The code will be valid for")}
                      <span>
                        {" "}
                        {timerValue} {t("sec")}
                      </span>
                    </div>
                  </div>
                  <ReactInputVerificationCode
                    length={5}
                    value={codeValue}
                    onChange={(e): void => {
                      setCodeValue(e);
                    }}
                    placeholder="_"
                    passwordMask="_"
                  />
                  <button
                    className="main-btn"
                    type="button"
                    disabled={
                      isFetching || timerValue >= limit || codeValue.length < 5
                    }
                    onClick={(): void => {
                      handleSendCode();
                    }}
                  >
                    {t("Confirm")}
                  </button>
                  {timerValue !== 0 ? (
                    <div className="sms-timer">
                      <span>{t("Resubmission is possible via")}</span>
                      <p>
                        {timerValue} {t("sec")}
                      </p>
                    </div>
                  ) : (
                    <div className="reset-block code">
                      <div className="txt">{t("Didn't receive code?")}</div>
                      <div
                        className="btn"
                        onClick={(): void => {
                          returnHandler();
                        }}
                      >
                        {t("Resend")}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {globalError && (
        <ErrorModal
          btnTxt="OK"
          mainTxt={globalError}
          closeModalHandler={(): void => {
            setGlobalError("");
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("menu-hidden");
          }}
          confirmModalHandler={(): void => {
            setGlobalError("");
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("menu-hidden");
          }}
        />
      )}
    </div>
  );
};

export default ConfirmContacts;
