import ApiClient from "../ApiClient";
import { RequestError } from "../../types/requestTypes";
import { UserBetItem } from "../../types/gameTypes";

const getAllBets = async (
  page: number,
  search: string
): Promise<{ data: UserBetItem[]; meta: { last_page: number } } | string> => {
  try {
    const result = await ApiClient.get<{
      data: UserBetItem[];
      meta: { last_page: number };
    }>(`/games-rounds/?page=${page}&per_page=50${search}`);
    return result;
  } catch (err) {
    const { response } = err as RequestError;
    const errors: string[] = [];
    // eslint-disable-next-line
      Object.values(response?.data?.errors).forEach((el) => {
      // @ts-ignore
      errors.push(el as string);
    });
    return errors.join(" ") as string;
  }
};

export default getAllBets;
