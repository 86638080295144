import axios from "axios";
import moment from "moment";

class ApiClient {
  private token = "";

  private localeLang: string | null = localStorage.getItem("localeLang");

  readonly baseURL: string = "";

  constructor() {
    this.baseURL = this.getBaseUrl();
  }

  setToken(token: string): void {
    this.token = token;
  }

  getToken(): string {
    return this.token;
  }

  async checkToken(): Promise<void> {
    const tokenDate = localStorage.getItem("tokenDate");
    const tokenExpire = localStorage.getItem("expires_in");
    const token = localStorage.getItem("token");
    const isLogged = localStorage.getItem("isLogged");
    if (!token || !isLogged) return;
    if (
      // eslint-disable-next-line
      (moment(new Date()).diff(JSON.parse(tokenDate as string), "minutes") >= parseInt(tokenExpire || "0", 10) - 10) && isLogged && JSON.parse(isLogged)
    ) {
      try {
        const result = await axios.post(
          `${this.baseURL}/auth/refresh-token`,
          {},
          {
            headers: {
              "Accept-Language": this.localeLang,
              Authorization: `Bearer ${token || ""}`,
            },
            baseURL: this.baseURL,
          }
        );
        if (typeof result !== "string") {
          // @ts-ignore
          this.setToken(result.data.original.access_token as string);
          // @ts-ignore
          localStorage.setItem(
            "token",
            result.data.original.access_token as string
          );
          localStorage.setItem("tokenDate", JSON.stringify(new Date()));
        } else {
          try {
            await axios.post(
              `${this.baseURL}/auth/logout`,
              {},
              {
                headers: {
                  "Accept-Language": this.localeLang,
                  Authorization: `Bearer ${token || ""}`,
                },
                baseURL: this.baseURL,
              }
            );
          } finally {
            localStorage.setItem("isLogged", JSON.stringify(false));
            window.location.replace("/");
          }
        }
      } catch {
        try {
          await axios.post(
            `${this.baseURL}/auth/logout`,
            {},
            {
              headers: {
                "Accept-Language": this.localeLang,
                Authorization: `Bearer ${token || ""}`,
              },
              baseURL: this.baseURL,
            }
          );
        } finally {
          localStorage.setItem("isLogged", JSON.stringify(false));
          window.location.replace("/");
        }
      }
    }
  }

  async post<T>(
    url: string,
    dataProp: unknown,
    props: Record<string, unknown> = {}
  ): Promise<T> {
    await this.checkToken();
    const { data } = await axios.post<T>(url, dataProp, {
      headers: {
        "Accept-Language": this.localeLang,
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      ...props,
    });

    return data;
  }

  async patch<T>(
    url: string,
    dataProp: unknown,
    props: Record<string, unknown> = {}
  ): Promise<T> {
    await this.checkToken();
    const { data } = await axios.patch<T>(url, dataProp, {
      headers: {
        "Accept-Language": this.localeLang,
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      ...props,
    });

    return data;
  }

  async publicPost<T>(
    url: string,
    dataProp: unknown,
    props: Record<string, unknown> = {}
  ): Promise<T> {
    const { data } = await axios.post<T>(url, dataProp, {
      headers: {
        "Accept-Language": this.localeLang,
        Authorization: "",
      },
      baseURL: this.baseURL,
      ...props,
    });

    return data;
  }

  async get<T>(url: string, props?: Record<string, unknown>): Promise<T> {
    await this.checkToken();
    const { data } = await axios.get<T>(url, {
      headers: {
        "Accept-Language": this.localeLang,
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      ...props,
    });

    return data;
  }

  async delete<T>(url: string, dataProp?: unknown): Promise<T> {
    await this.checkToken();
    const { data } = await axios({
      method: "DELETE",
      url,
      headers: {
        "Accept-Language": this.localeLang,
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      data: dataProp,
    });

    return data;
  }

  async put<T>(url: string, body: string | FormData): Promise<T> {
    await this.checkToken();
    const { data } = await axios({
      method: "PUT",
      url,
      headers: {
        "Accept-Language": this.localeLang,
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      data: typeof body === "string" ? JSON.parse(body) : body,
    });
    return data;
  }

  async getPublic<T>(url: string, props?: Record<string, unknown>): Promise<T> {
    const { data } = await axios.get<T>(url, {
      ...props,
      baseURL: this.baseURL,
    });

    return data;
  }

  private getBaseUrl(): string {
    return process.env.REACT_APP_API_URL as string;
  }
}

export default new ApiClient();
