import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDetectClickOutside } from "react-detect-click-outside";
import styles from "./BalanceViewStyles.module.scss";
import { RootState } from "../../../store/store";

import balanceIcon from "../../../images/balance-icon-new.svg";
import arrowIcon from "../../../images/arrow-down-new.svg";
import MainButton from "../MainButton/MainButton";
import getBalanceSytring from "../../../helpers/getBalanceString";

const BalanceView: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const balances = useSelector((state: RootState) => {
    return state.user.userInfo.balances;
  });
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );
  const currentBalanceStore = useSelector((state: RootState) => {
    return state.user.userInfo.current_balance;
  });

  const getAllBalance = (): number => {
    let value = 0;
    balances.forEach((item) => {
      value += +item.amount;
    });
    return +value;
  };
  const [isShowBalance, setIsShowBalance] = useState<boolean>(false);

  const showBalance = (): void => {
    setIsShowBalance(!isShowBalance);
  };

  const hideDropdownHandler = (): void => {
    setIsShowBalance(false);
  };

  const ref = useDetectClickOutside({
    onTriggered: hideDropdownHandler,
  });
  return (
    <div
      className={classNames(
        `${styles.container_balance} ${
          localeActive?.code === "fa" ? styles.rtl : ""
        }`
      )}
      ref={ref}
    >
      <div className={styles.balance_menu}>
        <div className={styles.amount} onClick={showBalance}>
          <img src={balanceIcon} alt="balance-icon" />
          <div className={styles.current_amount}>
            <div className={styles.balance}>{t("Balance")}</div>
            <div className={styles.data_amount}>
              <div>{getAllBalance()}</div>
              <div>{getSymbolFromCurrency(currentBalanceStore.code || "")}</div>
            </div>
          </div>
          <img
            className={classNames(styles.iconArrow, {
              [styles.arrow_up]: isShowBalance,
            })}
            src={arrowIcon}
            alt=""
          />
        </div>
        <MainButton
          className={styles.main_btn}
          onClick={(): void => {
            if (!location.pathname.includes("payments")) navigate("/payments");
            setIsShowBalance(false);
          }}
          title={t("Top up")}
          variant="contained"
          color="primary"
        />
      </div>
      {isShowBalance && (
        <div className={styles.dropdown}>
          {balances.map((item) => (
            <div
              className={classNames(styles.type_invoice, {
                [styles.active_balance]: item.type === currentBalanceStore.type,
              })}
              key={item.type}
            >
              {t(getBalanceSytring(item.type))}{" "}
              <span>
                {item.amount}
                {getSymbolFromCurrency(item.code || "")}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BalanceView;
