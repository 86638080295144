import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./GamesTitleStyles.module.scss";
import categoryLobby from "../../../images/categories/lobby-new.svg";
import categorySlots from "../../../images/categories/slots-new.svg";
import categoryLive from "../../../images/categories/live-games.svg";
import categoryCard from "../../../images/categories/card-new.svg";
import tournamentsImg from "../../../images/categories/tournament.svg";
import { RootState } from "../../../store/store";

interface Props {
  txt: string;
  myRef?: any;
  badge?: string;
  title?: string;
}

const GamesTitle: React.FC<Props> = ({ txt, badge, myRef, title }) => {
  const [categoryImg, setCategoryImg] = useState<string>("");
  const { t } = useTranslation();
  const localeActive = useSelector(
    (state: RootState) => state.user.localeActive
  );

  useEffect(() => {
    switch (txt) {
      case "Slots":
        setCategoryImg(categorySlots);
        break;
      // case "Live":
      //   setCategoryImg(categoryLive);
      //   break;
      case "Card":
        setCategoryImg(categoryCard);
        break;
      case "Active tournament":
        setCategoryImg(tournamentsImg);
        break;
      case "Live games":
        setCategoryImg(categoryLive);
        break;
      default:
        setCategoryImg(categoryLobby);
    }
  }, [txt]);

  return (
    <div
      className={`${styles.games_title} ${
        localeActive?.code === "fa" ? styles.rtl : ""
      }`}
      ref={myRef}
    >
      <div className={styles.logo}>
        <img src={categoryImg} alt="logo" />
        <div>
          {t(txt)} {title}
        </div>
      </div>
      <div className={styles.center_block} />
      {badge && (
        <div className={styles.games_title_badge}>
          {`${t("All Games")} (${badge})`}
        </div>
      )}
    </div>
  );
};

export default GamesTitle;
